import React, { useEffect } from 'react'
import { InviteActionButtons } from 'components'
import fiveStar from 'images/fiveStar.svg'
import itemKnote from 'images/itemKnote.svg'
import memberItemKnote from 'images/memberItemKnote.svg'
import { useHistory, useLocation } from 'react-router'
import onDemandBadge from 'images/OnDemand.svg'
import { getUtcOffsetDate } from 'utils'
import './index.styl'

const SpecialCard = ({ item, tag = '', guest = false, onClick, setShowModal, onSelectInvite = () => { } }) => {
  const history = useHistory()
  const isInvite = item?.inviteType !== undefined
  const { id, eventID, onDemand = false, bgColor, ticketType, ticketPrice, ticketsCount, locations, location, eventName, imageUrl, eventType, timeZone, ticketPasses, eventVenue, presentedBy, eventStart, eventEnd, eventRainDate, ticketName } = item
  const { pathname } = useLocation()
  const ticketData = {
    guest,
    isInvite,
    id: id,
    ticketName,
    eventName: eventName,
    address: location,
    locations: locations,
    eventType: eventType,
    timeZone: timeZone,
    venue: eventVenue,
    presentedBy: presentedBy,
    startDate: eventStart,
    rainDate: getUtcOffsetDate(eventRainDate, 'MMMM DD, YYYY', timeZone),
    endDate: eventEnd,
    image: imageUrl?.thumbnail1,
    imageUrl: imageUrl,
    backgroundColor: bgColor,
    quantity: isInvite ? item.quantity : ticketsCount,
    originalPrice: isInvite ? item.price : ticketPrice,
    resellPrice: isInvite && item.price,
    ticketPasses: ticketPasses || [],
    inviteID: isInvite && item._id,
    inviteStatus: item?.inviteStatus,
    ticketType: ticketType,
    eventID: eventID,
  }

  useEffect(() => {
    setShowModal(false)
    onClick(ticketData)
  }, [ticketsCount])

  const handleClick = () => {
    if (ticketType.includes('MEMBERSHIP')) {
      history.push({
        pathname: `/detail-membership/${eventID}`,
        state: { ticketData },
      })
    } else {
      setShowModal(true)
      onClick(ticketData)
    }
  }

  return (
    <div
      onClick={handleClick} className='wrapperSpecialCard'
    >
      <div className='imgWrap'>
        {onDemand && <img src={onDemandBadge} alt=' ' className='onDemandBadge' draggable={false} />}
      </div>
      <div className='top' />
      <div className='titleCategoryWrapper'>
        {ticketType === 'CHARITY_RAFFLE' ? (
          <>
            <img src={fiveStar} alt='five star' />
            <div className='titleCategory'><span className='firstText'>CHARITY</span><span className='secondText'>RAFFLE</span></div>
            <img src={fiveStar} alt='five star' className='lastStar' />
          </>
        ) : (
          <>
            <img src={pathname?.includes('member') ? memberItemKnote : itemKnote} alt='item' />
            <div className='titleCategoryItem'>ITEM TICKET</div>
          </>
        )}
      </div>
      <div className='center' />
      <div className='circleWrapper'>
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
        <div className='circle' />
      </div>
      <div className='bottomWrapper'>
        <div className='bottomTitle'>
          {ticketType}
        </div>
        <div className='bottomSubtitle'>at "{eventName}"</div>
        <div className='bottomQuantityWrapper'>
          <div className='quantity'>{ticketsCount}</div>
          <div className='quantitySubtitle'>Tickets</div>
        </div>
      </div>
      <div className='bottom' />
      {isInvite && item.inviteStatus !== 'ACCEPTED' && item.inviteStatus !== 'REJECTED' &&
        <div className='bottom'>
          <InviteActionButtons inviteData={ticketData} onSelectInvite={onSelectInvite} />
        </div>}
    </div>
  )
}

export default SpecialCard
