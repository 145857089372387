import React, { useState } from 'react'
import { eventsV2 } from 'apiv2'
import {
  dereserveSeats,
  guestReserveSeats,
  guestDereserveSeats,
  eventsUnreserveSeat,
} from 'modules/events/events-actions'
import moment from 'moment'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { notification } from 'antd'
const TableSeatsReservation = ({ seat, selectedSeat, sectionName, group, isUser, sectionID, sectionType, selectSeatArray }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { currentEvent } = useSelector(state => state.events)
  const [guestReservationToken, setGuestReservationToken] = useState(null)
  const isEarlyBirdActive = seat.category?.isEarlyBird && (moment() <= moment(seat?.earlyBirdDate))
  const currentPrice = isEarlyBirdActive ? seat.category?.earlyBirdPrice : group.category.price
  const isSeatSelected = !!selectedSeat.find(item => item.seatId === seat.id)
  function removeObjectWithId (arr, id) {
    return arr.filter(obj => obj.seatId !== id)
  }
  const handleClickBuySeat = seat => {
    const obj = { eventID: id || currentEvent?.id, seatID: seat.id }
    const dereserveGuestObj = { eventID: id, seatID: seat.id, guestReservationToken }
    if (isUser) {
      if (isSeatSelected) {
        dispatch(dereserveSeats(obj)).then(() => {
          dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seat.id }))
          selectSeatArray(removeObjectWithId(selectedSeat, seat.id))
        })
      } else {
        eventsV2.reserveSeats(obj).then(() => {
          notification.success({ message: 'Your seat will be reserved for you for 5 minutes' })
          selectSeatArray([...selectedSeat, { seatId: seat.id, sectionName, rowName: group.groupName, seatName: seat.seatName, sectionType, price: currentPrice, priceWithoutEarlyBird: group.category.price, seatCategoryID: group.category.ticketCategoryID, isActive: true, earlyBirdDate: seat.category?.earlyBirdDate, isEarlyBird: seat.category?.isEarlyBird, earlyBirdPrice: seat.category?.earlyBirdPrice }])
        }).catch(error => notification.warning({ message: error?.response?.data?.message || error?.response?.data?.error }))
      }
    } else {
      if (isSeatSelected) {
        dispatch(guestDereserveSeats(dereserveGuestObj)).then(() => {
          dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seat.id }))
          selectSeatArray(removeObjectWithId(selectedSeat, seat.id))
        })
      } else {
        dispatch(guestReserveSeats(obj)).then(res => {
          setGuestReservationToken(res?.data?.guestReservationToken)
          notification.success({ message: 'Your seat will be reserved for you for 5 minutes' })
          selectSeatArray([...selectedSeat, { seatId: seat.id, guestReservationToken: res?.data?.guestReservationToken, sectionName, rowName: group.groupName, seatName: seat.seatName, sectionType, price: currentPrice, priceWithoutEarlyBird: group.category.price, seatCategoryID: group.category.ticketCategoryID, isActive: true, earlyBirdDate: seat.category?.earlyBirdDate, isEarlyBird: seat.category?.isEarlyBird, earlyBirdPrice: seat.category?.earlyBirdPrice }])
        })
      }
    }
  }
  return (
    <div key={seat.index}>
      <div className='rectangleBuySeat'>
        {seat.isReserved && !isSeatSelected
          ? (<div className='orderSeat' style={seat?.category.color === '#FFFFFF' && !isSeatSelected || seat.category.color === '#F2F2F2' && !isSeatSelected || seat.category.color === '#FFFF00' && !isSeatSelected || seat.category.color === '#FFCC00' && !isSeatSelected || seat.category.color === '#FF9900' && !isSeatSelected || seat.category.color === '#FF6600' && !isSeatSelected || seat.category.color === '#99CC00' && !isSeatSelected || seat.category.color === '#99FF00' || seat.category.color === '#33FF00' && !isSeatSelected || seat.category.color === '#33CC00' && !isSeatSelected ? { background: seat.category.color, color: 'black' } : { background: seat.category.color }}>{seat.seatName}</div>
          )
          : seat.isDisabled
            ? <div className='disableSeat' /> : (
              <div
                key={seat.id}
                onClick={() => {
                  handleClickBuySeat(seat)
                }}
                className={!isSeatSelected ? 'rectangleItem' : 'rectangleItemActive'} style={seat?.category.color === '#FFFFFF' && !isSeatSelected || seat.category.color === '#F2F2F2' && !isSeatSelected || seat.category.color === '#FFFF00' && !isSeatSelected || seat.category.color === '#FFCC00' && !isSeatSelected || seat.category.color === '#FF9900' && !isSeatSelected || seat.category.color === '#FF6600' && !isSeatSelected || seat.category.color === '#99CC00' && !isSeatSelected || seat.category.color === '#99FF00' || seat.category.color === '#33FF00' && !isSeatSelected || seat.category.color === '#33CC00' && !isSeatSelected ? { background: seat.category.color, color: 'black' } : isSeatSelected ? { background: '#fff', color: 'black' } : { background: seat.category.color }}
              >
                {seat.seatName}
              </div>
            )}
      </div>
    </div>
  )
}

export default TableSeatsReservation
