import React from 'react'
import './index.styl'

const MainButton = ({ text = '', className = '', onClick = () => {} }) => {
  return (
    <div className={'mainButton ' + className} onClick={onClick}>
      {text}
    </div>
  )
}

export default MainButton
