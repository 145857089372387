import React from 'react'
import { LayoutSwitcher } from 'components'
import { BtnSignIn, Search, NavigationMemberLyfe } from '../components'
import './index.styl'

const Unauthorized = () => {
  return (
    <div className='unauthorizedHeader'>
      <span className='logo'>
        <LayoutSwitcher type='member' />
      </span>
      <NavigationMemberLyfe />
      <Search />
      <BtnSignIn />
    </div>
  )
}

export default Unauthorized
