import React, { useEffect, useState, useContext } from 'react'
import { Skeleton, Tooltip, Pagination } from 'antd'
import Row from './Row'
import { MyTemplatesContext } from '../index'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { useQuery, handleConfirmModal, getMyTemplateURL, notify } from 'utils'
import { eventsV2 } from 'apiv2'
import { useHistory } from 'react-router'
import './index.styl'

const paginationDefault = {
  current: 1,
  total: 0,
  limit: 5,
}

const TableTemplates = () => {
  const { searchTag, loading, setLoading } = useContext(MyTemplatesContext)
  const query = useQuery()
  const [pagination, setPagination] = useState(paginationDefault)
  const [templates, getTemplates] = useState(null)
  const [prevName, setPrevName] = useState('')
  const history = useHistory()
  useEffect(() => {
    updateEvents(+query.get('page') || 1)
  }, [searchTag, prevName])
  const catchError = error => {
    notify(error?.response?.data?.error || error?.response?.data?.message || error?.message)
    setLoading(false)
  }

  const updateEvents = (current = 1) => {
    const params = {
      limit: paginationDefault.limit,
      skip: (current * pagination.limit) - pagination.limit,
      search: searchTag,
    }
    setLoading(true)
    eventsV2.getTemplates(params).then(response => {
      getTemplates(response.data.templates)
      setPagination({ ...pagination, total: response.data.total, current })
    })
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  const onDeleteEvent = id => {
    const onOk = () => {
      setLoading(true)
      eventsV2.deleteTemplate(id)
        .then(() => onChange(pagination.current, pagination.limit))
        .catch(catchError)
    }
    handleConfirmModal('Are you sure you want to delete this template?', onOk)
  }

  const onChange = (current, pageSize) => {
    const skip = (current * pageSize) - pagination.limit
    const params = {
      limit: pagination.limit,
      search: searchTag,
      skip,
    }
    history.push(getMyTemplateURL(current))
    setLoading(true)
    eventsV2.getTemplates(params)
      .then(response => {
        getTemplates(response.data.templates)
        setPagination({ ...pagination, total: response.data.total, current })
      })
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  return (
    <div className='wrapperTableEvents'>
      <Skeleton loading={loading} active paragraph={{ rows: 15 }}>
        {templates?.length ? templates?.map((template, index) => {
          return (
            <Row
              key={index}
              prevName={prevName}
              setPageRefresh={setPrevName}
              onDeleteEvent={onDeleteEvent}
              {...template}
            />
          )
        })
          : (
            <div className='wrapperBodyEmpty'>
              <Tooltip title='No Events available'>
                <img src={NoEventsInformation} alt='Information Logo' />
              </Tooltip>
              <div className='informationDescription'>No Templates available yet</div>
            </div>)}
      </Skeleton>
      {!!templates?.length && !!pagination.total && (
        <Pagination
          current={pagination.current}
          pageSize={pagination.limit}
          total={pagination.total}
          showSizeChanger={false}
          className='pagination'
          onChange={onChange}
          disabled={loading}
        />
      )}
    </div>

  )
}

export default TableTemplates
