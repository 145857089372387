import React from 'react'
import PreviousButton from 'images/prevArrow.svg'
import NextButton from 'images/nextArrow.svg'
import './index.styl'
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function DateInputControl (props) {
  return (
    <>
      <div className='date-control'>
        <img src={PreviousButton} onClick={props.navigateToPrevMonth} />
        <div className='currentMonthYearWrapper'>
          <div className='month'>{monthNames[props.currentMonth]}</div>
          <div className='year'>{props.currentYear}</div>
        </div>
        <img src={NextButton} onClick={props.navigateToNextMonth} />
      </div>
    </>

  )
}

export default DateInputControl
