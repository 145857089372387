import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import { Layout } from 'components'
// import { events } from 'api'
import { getUtcOffsetDate } from 'utils'
import TicketOrder from 'components/TicketOrder'
import { eventsV2 } from '../../apiv2'
import './index.styl'

const Order = () => {
  const [ticketsOrder, setTicketsOrder] = useState([])
  const { id } = useParams()

  const transformTickets = tickets => {
    return tickets.map(t => {
      const { event, ticket, barcode, isScanned, eventTableName, sectionName, randomDrawWinner, table, row, seat, section, quantity } = t || {}
      const ticketPasses = barcode ? [{ barcode, isScanned, randomDrawWinner, eventTable: table, eventRow: row, eventSeat: seat, seatSectionName: section }] : []
      const _ticket = {
        ...ticket,
        isVirtualTicket: event?.isVirtualTicket,
        attachments: event?.attachments,
        description: event?.description,
      }
      return {
        ...event,
        rainDate: event?.rainDate ? getUtcOffsetDate(event?.rainDate, 'MMMM DD, YYYY', event?.timeZone) : '',
        zoom_password: event?.virtualEvent?.password,
        image: event?.imageUrl?.thumbnail1,
        imageUrl: event?.imageUrl,
        zoom_url: event?.virtualEvent?.url,
        isStreaming: ticket?.isStreaming,
        ticketType: ticket?.ticketType,
        ticketColor: ticket?.bgColor,
        originalPrice: ticket?.price,
        address: event?.address,
        eventID: event?._id,
        quantity,
        ticket: _ticket,
        eventTableName,
        ticketPasses,
        sectionName,
        simpleTicket: true,
        isInvite: false,
        guest: false,
      }
    })
  }

  const fetchData = () => {
    eventsV2.getTicketsByOrderV2(id)
      .then(({ data: { data } }) => {
        const _ticketsOrder = transformTickets(data?.ticketPasses)
        setTicketsOrder(_ticketsOrder)
      })
      .catch(console.error)
  }

  useEffect(fetchData, [])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 5000, min: 1800 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <Layout>
      <div className='Order'>
        {!!ticketsOrder.length && (
          <div className='ticketsCarousel'>
            <Carousel
              className='ticketsCarousel'
              responsive={responsive}
              showDots={false}
            >
              {ticketsOrder.map(t => (
                <TicketOrder key={t?.eventID} ticketData={t} orderId={id} tag='Ticket' updateOrder={fetchData} />
              ))}
            </Carousel>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Order
