import React, { useState } from 'react'
// import { QrScanner } from '@yudiel/react-qr-scanner'
// import { QrReader } from 'react-qr-reader'
import QrReader from 'react-qr-reader2'
import { CustomButton } from 'components'
import success from 'images/succesScanTL.svg'
import fail from 'images/failScanTL.svg'
import permission from 'images/microphone.svg'
import './index.styl'

const QRReader = ({ onScan, scanning, response, setStatus, camera, handleNextScan }) => {
  const [isActive, setIsActive] = useState(false)
  // const timeoutRef = useRef(null)

  // const setActiveScanner = () => {
  //  timeoutRef.current = setTimeout(() => setIsActive(false), 30000)
  // }

  const handleScan = (data, error) => {
    if (data && !scanning) {
      // clearTimeout(timeoutRef.current)
      // setActiveScanner()
      onScan(data)
    }
    // if (!!error) {
    //   setStatus({ status: 'permission', message: 'scanner not connected' })
    // }
  }
  const handleActive = () => {
    setIsActive(true)
    // setActiveScanner()
  }
  const handleError = () => setStatus({ status: 'permission', message: 'scanner not connected' })
  const icons = { success, fail, permission }
  return (
    <div className='mainContainer'>
      <div className='readerContainer'>
        {isActive && (
          <div>
            <QrReader
              className='qrReader'
              // // videoStyle={{ width: '100%', height: 530, objectFit: 'cover' }}
              // // containerStyle={{ height: 430, objectFit: 'cover', width: '100%' }}
              style={{ width: '100%', height: 530, objectFit: 'cover' }}
              facingMode={camera}
              onError={handleError}
              onScan={handleScan}
              delay={200}
            />

          </div>
        )}
        <div className='readerWrapper'>
          <div className='readerWindow'>
            {response && (
              <div className='response-status'>
                <div className='response-container'>
                  <img src={icons[response?.status]} alt='icon' className='status-icon' />
                  {/* {response?.message && (<span className='response-message'>{response?.message}</span>)} */}
                </div>
              </div>
            )}
            {!isActive && (
              <div className='response-status'>
                <div className='response-container' onClick={handleActive}>
                  <span className='response-message'>Tap here for start scanning</span>
                </div>
              </div>
            )}
            <span className='lTop' />
            <span className='rTop' />
            <span className='rBottom' />
            <span className='lBottom' />
          </div>
          {response && (
            <CustomButton className='nextScanBtn' text='Next Scan' type='dark' onClick={handleNextScan} />
          )}
        </div>
      </div>
    </div>
  )
}

export default QRReader
