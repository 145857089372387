import React from 'react'
import { Tooltip } from 'antd'
import InfoTooltip from 'images/BlueInformationToolTip.svg'
import './index.styl'

const TooltipInfo = ({ text, info, className = '', placement }) => {
  return (
    <div className={className}>
      {text}
      <Tooltip getPopupContainer={triggerNode => triggerNode.parentNode} placement={placement} title={info}>
        <img alt='InfoTooltip' src={InfoTooltip} className='infoTooltip' />
      </Tooltip>
    </div>
  )
}

export default TooltipInfo
