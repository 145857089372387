import React from 'react'
import UploadImage from './UploadImage'
import DetailsFields from './DetailsFields'
import './index.styl'
import Buttons from './Buttons'
import Body from '../Body'

const Header = ({ form, currentPageDetails }) => {
  return (
    <div className='membershipHeader'>
      <div className='headerTitle'>{currentPageDetails && 'Edit Member Lyfe' || 'Create Your Member Lyfe Page'}</div>
      <div className='headerWrapper'>
        <div className='upload-details-wrapper'>
          <DetailsFields currentPageDetails={currentPageDetails} form={form} />
          <div className='imgBtnMemberWrapper'>
            <UploadImage />
            <Buttons currentPageDetails={currentPageDetails} form={form} />
          </div>
        </div>
      </div>
      <Body />
    </div>
  )
}

export default Header
