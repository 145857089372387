import React from 'react'
import { useHistory } from 'react-router'
import moment from 'moment'
import clock from 'images/Time.svg'
// import map from 'images/Place.svg'
// import flagMap from 'images/Location.svg'
import calendar from 'images/Calendar.svg'
// import zoomIcon from 'images/zoomIcon.svg'
// import rainIcon from 'images/rain.svg'
import eventLiveLogo from 'images/eventLive-logo-old.png'
import { LeftOutlined } from '@ant-design/icons'
import './index.styl'

const Header = ({ eventData = {} }) => {
  const { eventName, startDate, endDate } = eventData
  const history = useHistory()

  return (
    <div className='stream_head'>
      <div className='flex-line'>
        <div className='btn-back' onClick={() => history.goBack()}>
          <LeftOutlined className='icon' />
          Back
        </div>
        <img className='logo' src={eventLiveLogo} alt='powered by eventlive' />
        <div className='stream_title'>{eventName || 'Loading...'}</div>
      </div>
      <div className='stream_info'>
        <div className='info_item'>
          <img src={calendar} />{startDate ? moment(startDate).format('MMMM DD, YYYY') : 'MMMM DD, YYYY'}
        </div>
        <div className='info_item'>
          <img src={clock} />
          {endDate ? moment(startDate).format('hh:mm a') : ''} - {endDate ? moment(endDate).format('hh:mm a') : ''}
        </div>
      </div>
    </div>
  )
}
export default Header
