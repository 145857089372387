import React, { useEffect, useState } from 'react'
import LogoMemberLyfe from '../LogoMemberLyfe'
import { useSelector } from 'react-redux'
import Logo from '../Logo'
import './index.styl'
import SwitcherBlock from '../SwitcherBlock'

const LayoutSwitcher = ({ type }) => {
  const [pageType, setPageType] = useState('ticket')
  const { user, isMobile } = useSelector(state => state.session)
  useEffect(() => {
    setPageType(type)
  }, [type])

  return (
    <div className='logoSwitcher'>
      {pageType === 'member' ? <LogoMemberLyfe user={user} /> : <Logo />}
      {(!isMobile && user) && <SwitcherBlock type={pageType} />}
    </div>
  )
}

export default LayoutSwitcher
