import React, { useState } from 'react'
import { Modal } from 'antd'
import closeIconSvg from 'images/Crossmark.svg'
import { ReactComponent as InfoIcon } from 'images/infoIcon.svg'
import circleDone from 'images/circleDone.png'
import { getCurrencySymbolByCode } from 'utils'
import FormPaymentMS from './components/FormPaymentMS'
import MSStepsModal from './components/MSStepsModal'
import './index.styl'

const steps = ['payment', 'success']

export default ({ visible = false, setVisible, callback, currency, image }) => {
  const [screen, setScreen] = useState(steps[0])
  // const [loading, setLoading] = useState(true)
  const currencySymbol = getCurrencySymbolByCode(currency)

  const onCancel = () => {
    setScreen(steps[0])
    setVisible(false)
  }
  const onTicketsBought = (token, agreement, cb) => {
    callback(token, agreement).then(() => cb && cb())
  }

  const screens = {
    payment: () => (
      <FormPaymentMS
        totalPrice={10.99}
        currencySymbol={currencySymbol}
        onSubmit={onTicketsBought}
      />
    ),
    success: () => (
      <div className='success-block'>
        <div className='img-block'>
          <img src={circleDone} alt='success' />
        </div>
        <div className='order-success'>
          <span className='title-success'>Paid</span>
          <span className='price-success'>{currencySymbol}{'10.99'}</span>
        </div>
        <div className='success-description'>Thank you for subscription</div>
      </div>
    ),
  }

  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const attr = { visible, onCancel, width: 840, maxWidth: 840, footer: null, className: 'modalBuyTicket' }

  return (
    <Modal title='Activate Your Manager Page' {...attr} closeIcon={closeIcon} className='ModalBuySubscribe' centered>
      <div className='leftSideWrapper'>
        <div className='wrapperCategories'>
          <div className='imgLeft'>
            <img
              src={image.original}
              alt='logo'
              className='pageImg'
            />
          </div>
          <div className='wrapperDescription'>
            <div className='title'>Purchase:</div>
            <ul>
              <li className='list-item'>
                <div className='label'>Subscription</div>
                <div className='amount'>{currencySymbol}{10.99}</div>
              </li>
              <li className='list-item summary'>
                <div className='amount'>Monthly Total</div>
                <div className='amount'>{currencySymbol}{10.99}</div>
              </li>
            </ul>
          </div>
        </div>
        <div className='infoMemberContainer'>
          <div className='infoIcon'>
            <InfoIcon />
          </div>
          <div className='textInfo'>
            This purchase will show up as MEMBER LYFE on your credit card statement, and All ticket proceeds are given to the Promoter of this Event, and are non-refundable by MEMBER LYFE--by clicking to buy these tickets you accept the conditions.
          </div>
        </div>
      </div>

      <div className='wrapperScreen'>
        <MSStepsModal activeScreen={screen} userLogged stepsItems={steps} />
        <div className='screen'>
          {screens[screen]()}
        </div>
      </div>
    </Modal>
  )
}
