import api from './axios'

const getAllEvents = ({ skip = 0, limit = 10, search = '' }) => {
  let apiUrl = `admin/getAllEvents?limit=${limit}&skip=${skip}`
  if (search) {
    apiUrl += `&search=${search}`
  }
  return api(apiUrl, 'GET')
}
const refundPayment = params => {
  return api('admin/refundPayment', 'POST', params)
}

export default {
  getAllEvents,
  refundPayment,
}
