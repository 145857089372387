import React from 'react'

import './index.styl'
import OptionalTabs from './OptionalTabs'

const Body = () => {
  return (
    <div className='membershipBodyWrapper'>
      <div className='staticWidth'>
        <OptionalTabs />
      </div>
    </div>
  )
}

export default Body
