import React from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import { CustomButton } from 'components'

import './index.styl'
const { Item } = Form
const ModalEditTable = ({ visible = false, renameTable, onChange, seatCount, onFinish, loading, title, tableName }) => {
  // const [form] = Form.useForm()

  // useEffect(() => {
  //  form.setFieldsValue({
  //    tableName: tableName,
  //    seatCount: seatCount,
  //  })
  // }, [tableName, seatCount])

  const validateLimit = {
    validator (_, value) {
      if (value > 200) {
        return Promise.reject(new Error('You cannot create more than 200 tables per section!'))
      }
      return Promise.resolve()
    },
  }

  const initialValues = {
    seatCount: seatCount,
    tableName: tableName,
  }
  return (
    <Modal
      centered
      closable={!loading}
      destroyOnClose
      width={544}
      footer={null}
      visible={visible}
      title={title}
      onCancel={onChange}
      className='modal-edit-tables'
    >
      <Form initialValues={initialValues} layout='vertical' className='form' onFinish={onFinish}>
        <Item
          rules={[{ required: true, message: 'Enter Table Name' }]}
          label='Table name'
          name='tableName'
          initialValue={tableName}
          className='tableName'
        >
          <Input className='fullWidth' />
        </Item>
        {!renameTable &&
          <Item
            rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
            label='Count of Seats'
            name='seatCount'
            initialValue={seatCount}
            className='seatNumber'
          >
            <InputNumber className='fullWidth' type='number' min={1} />
          </Item>}
        <div className='wrapperEditTablesFooter'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text='Ok' type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditTable
