import React from 'react'
import { CustomButton } from 'components'
// import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'

import './index.styl'

const ChooseAccount = () => {
  const history = useHistory()
  // const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  // const dispatch = useDispatch()
  const onChange = () => {
    // setOpenSubscribeModal(!openSubscribeModal)
    history.push('/auth/venue-sign-up')
  }

  // const createSubscribeSubmit = data => {
  //  // dispatch(createSubscribe(data)).then(data => {
  //  //  if (data?.status === 200) {
  //  //    setOpenSubscribeModal(false)
  //  //  }
  //  // })
  //  history.push('/auth/venue-sign-up')
  // }
  return (
    <div className='chooseAccountWrapper'>
      <div className='chooseTitleWrapper'>
        <div className='chooseTitle'>Sign Up</div>
        <div className='chooseSubtitle'>Please select your account type to proceed</div>
      </div>
      <div className='chooseCardWrapper'>
        <div className='chooseCard'>
          <div className='chooseCardTitle'>Lyfe User</div>
          <div className='chooseCardSubtitle'>Become active in Ticket Lyfe–both as a Ticket Buyer or Ticket Maker–or active in Member Lyfe, to make or join in on various memberships.</div>
          <CustomButton onClick={() => history.push('/auth/sign-up')} text='Free Sign Up' type='dark' className='freeSignUpBtn' htmlType='submit' />
        </div>
        <div className='chooseCardVenue'>
          <div className='chooseCardTitle'>TL Venue</div>
          <div className='chooseCardSubtitle'>You are the owner and/or Manager of a Brick and Mortar Venue and want all Ticketed Events to go through your personalized ticketing system.  Whether it’s renters or your own show, you will have access to all of the data with Venue Lyfe.</div>
          <CustomButton onClick={onChange} text='$200/annually' type='dark' className='freeSignUpBtn' htmlType='submit' />
        </div>
        <div />
      </div>
      <div className='linkToLogInChooseWrapper'>
        <div className='noAccountTitle'>Already have an account?</div>
        <Link className='logInButtonLink' to='/auth/sign-in'>Log in</Link>
      </div>
      {/* {openSubscribeModal &&
        <ModalSubscribe
          setVisible={setOpenSubscribeModal}
          callback={createSubscribeSubmit}
          visible={openSubscribeModal}
          onChange={onChange}
          total={process.env.REACT_APP_SUBSCRIBE}
        />} */}
    </div>
  )
}

export default ChooseAccount
