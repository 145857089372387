import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Switch,
  InputNumber,
  Divider,
  Radio,
} from 'antd'
import { currencyCodes, timeZones, eventTypes } from 'utils'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { clearGetEventDetails } from 'modules/events/events-actions'
import Location from 'images/LocationWhite.svg'
import PlacesAutocomplete from 'react-places-autocomplete'
import { CreateEventContext } from '../'
import TooltipInfo from '../TooltipInfo'

import c from 'classnames'
import calendarIcon from 'images/calendarIcon.svg'
import './index.styl'

const { Item } = Form
const { Option } = Select

const tooltipTitle = 'Streamed events require a deposit. You will be prompted to pay upon making the Tickets.'

// TODO: what should go as props and what as context
const DetailsEvent = ({ isVenueEvent, isVenueRole, changeEventType }) => {
  const { form, currentEventDetails, eventType, eventID, streamingEnabled, setStreamingEnabled, currency, setCurrency } = useContext(CreateEventContext)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [addressLocation, setAddressLocation] = useState(currentEventDetails?.address || '')
  const { user: { venueName, address, isVenueUser } } = useSelector(state => state.session)

  const getVenueName = () => isVenueUser ? venueName : currentEventDetails.venueDetails.venueName
  const getVenueLocation = () => isVenueUser ? address : currentEventDetails.venueDetails.venueAddress

  useEffect(() => {
    if (!eventID) {
      dispatch(clearGetEventDetails({}))
    }
  }, [pathname])

  useEffect(() => {
    if (streamingEnabled && eventType === eventTypes.TICKETED_ADMITION) {
      changeEventType(eventTypes.TICKETED_ADMITION_STREAM)
    } else if (!streamingEnabled && eventType === eventTypes.TICKETED_ADMITION_STREAM) {
      changeEventType(eventTypes.TICKETED_ADMITION)
    }
    if (streamingEnabled !== form.getFieldValue('streamingEnabled')) {
      form.setFieldsValue({ streamingEnabled })
    }
  }, [streamingEnabled])

  const handleSelect = async value => {
    setAddressLocation(value)
    await form.setFieldsValue({
      location: value,
    })
  }
  const zoomURLLabel = (
    <TooltipInfo
      text='Zoom URL'
      info='The Zoom URL is the link that is provided by Zoom for either your Personal Meeting ID or a generated Meeting.  It can be obtained by copying the value from the Zoom Meeting Invitation. i.e. https://us02web.zoom.us/j/#########?pwd=AAAAAAAAAA for meetings that have the Passcode embedded in the URL or may be of the format https://us02web.zoom.us/j/######### for meetings that use a Waiting Room'
    />
  )
  const zoomMeetingPasscodeLabel = (
    <TooltipInfo
      text='Zoom Meeting passcode'
      info='Meetings and webinars can require passcodes for an added layer of security. passcodes can be set at the individual meeting level. If the Passcode is not included on the Zoom URL, participants will be required to enter this value to join the meeting'
    />
  )

  const additionalTooltip = (
    <TooltipInfo
      className='tooltipTitle'
      text='Additional fees apply'
      info={tooltipTitle}
    />
  )
  const zoomPersonalAccount = (
    <TooltipInfo
      className='personalZoomAccountTitle'
      info='How to setup Virtual Events with Zoom'
      text='Use personal Zoom account'
    />
  )
  const onChangeCurrency = ({ target: { value } }) => {
    setCurrency(value)
    form.setFieldsValue({ currency: value })
  }
  const locationIcon = <img src={Location} alt='Location Logo' />
  const className = 'input location-search-input'
  const renderInputLocation = (isRequired = false) => (
    <PlacesAutocomplete value={addressLocation} onChange={setAddressLocation} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='locationContainer'>
          <Item
            rules={[{ required: isRequired, message: 'Enter Location' }]}
            className='item'
            name='location'
            label='Location'
            normalize={v => v.trimStart()}
          >
            <Input {...getInputProps({ className, placeholder: 'Start typing', suffix: locationIcon, disabled: currentEventDetails?.isVenueEvent })} />
          </Item>
          <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
  const dateIcon = <img src={calendarIcon} alt=' ' />

  const m = moment().utcOffset(0)
  m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  m.toISOString()
  m.format()
  const formEventObject = {
    TICKETED_ADMITION: isVenueEvent ? (
      <>
        <div className='defaultValueContainer'>
          <div className='label'>Venue Name</div>
          <div className='defaultValue'>{getVenueName()}</div>
        </div>
        <div className='defaultValueContainer'>
          <div className='label'>Location</div>
          <div className='defaultValue'>{getVenueLocation()}</div>
        </div>
      </>
    ) : (
      <>
        <Item
          rules={[{ required: true, message: 'Enter Venue name' }]}
          label='Venue Name'
          className='item'
          name='venue'
          normalize={v => v.trimStart()}
        >
          <Input
            disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent}
            placeholder='Enter Venue Name'
            className='input'
          />
        </Item>
        {renderInputLocation(true)}
      </>
    ),
    TICKETED_ADMITION_STREAM: isVenueEvent ? (
      <>
        <div className='defaultValueContainer'>
          <div className='label'>Venue Name</div>
          <div className='defaultValue'>{getVenueName()}</div>
        </div>
        <div className='defaultValueContainer'>
          <div className='label'>Location</div>
          <div className='defaultValue'>{getVenueLocation()}</div>
        </div>
      </>
    ) : (
      <>
        <Item
          rules={[{ required: true, message: 'Enter Venue name' }]}
          label='Venue Name'
          className='item'
          name='venue'
          normalize={v => v.trimStart()}
        >
          <Input
            disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent}
            placeholder='Enter Venue Name'
            className='input'
          />
        </Item>
        {renderInputLocation(true)}
      </>
    ),
    STREAM: (
      <>
        <div className='tooltipWrapper'>{additionalTooltip}</div>
        <Item
          rules={[{ required: true, message: 'Enter Venue name' }]}
          label='Venue Name'
          className='item'
          name='venue'
          normalize={v => v.trimStart()}
        >
          <Input disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent} placeholder='Enter Venue Name' className='input' />
        </Item>
        {renderInputLocation()}
      </>
    ),
    VIRTUAL: (
      <>
        {zoomPersonalAccount}
        <div className='radioText'>
          <Item
            rules={[{ required: eventType === 'virtual', message: 'Enter Zoom URL' }]}
            label={zoomURLLabel}
            className='item'
            name='zoomUrl'
            normalize={v => v.trimStart()}
          >
            <Input placeholder='Enter your personal link' className='input' />
          </Item>
          <Item label={zoomMeetingPasscodeLabel} name='zoomPassword' className='item' normalize={v => v.trimStart()}>
            <Input placeholder='Enter your passcode' className='input' />
          </Item>
          <Item
            rules={[{ required: eventType === 'virtual', message: 'Enter maximum event participants' }]}
            label='Maximum event participants'
            name='zoomEventParticipants'
            className='item participants'
          >
            <InputNumber className='fullWidth' type='number' min={1} />
          </Item>
        </div>
      </>
    ),
  }

  const handleEndTimeDisabled = currentDate => {
    const _start = form.getFieldValue('startDate')
    if (_start && currentDate) {
      currentDate = moment(currentDate).endOf('day')
      return moment(currentDate).isBefore(_start, 'hours')
    }
    if (currentDate) {
      return currentDate < moment().startOf('day')
    }
  }

  const validateStartTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('Start time should be later than now.'))
      }
      const _end = form.getFieldValue('endDate')
      if (value && _end && moment(_end).isSameOrBefore(value, 'minutes')) {
        return Promise.reject(new Error('Start time should be earlier than end time.'))
      }
      return Promise.resolve()
    },
  }

  const validateEndTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('End time should be later than now.'))
      }

      const _start = form.getFieldValue('startDate')
      if (value && _start) {
        if (moment(value).isSameOrBefore(_start)) {
          return Promise.reject(new Error('End time should be after start time.'))
        }
        if (moment(value).isSame(_start, 'minutes')) {
          return Promise.reject(new Error('End time should not be the same as start time.'))
        }
      }

      return Promise.resolve()
    },
  }
  // const test = form.getFieldValue([])
  return (
    <div className='wrapperDetailsEvent'>
      {!eventID && <div className='title'>Give some details for your ticket</div>}
      <>
        <Item
          rules={[{ required: true, message: 'Enter Event Name' }]}
          label='Event Name'
          className='item'
          name='eventName'
          normalize={v => v.trimStart()}
        >
          <Input placeholder='Enter event name' className='input' />
        </Item>
        {formEventObject[eventType]}
        <Item
          className='item'
          name='presentedBy'
          label='Presented by (Optional)'
        >
          <Input placeholder='Add information' className='input' />
        </Item>
        <Item
          className='item timezoneSelect'
          name='timeZone'
          label='Time Zone'
          rules={[{ required: true, message: 'Enter Time Zone' }]}
        >
          <Select disabled={!!currentEventDetails?.isVenueDraft || isVenueEvent && !isVenueRole} placeholder='Select a timezone' listHeight={300}>
            {timeZones.map(({ value, label }) => (
              <Option key={value} value={value}>{label}</Option>
            ))}
          </Select>
        </Item>
        <div className='groupItems'>
          <Item
            initialValue={currentEventDetails && moment(currentEventDetails?.startDate, 'YYYY-MM-DD h:mm A')}
            rules={[{ required: true, message: 'Enter Start Date' }, validateStartTime]}
            label='Start Date and Time'
            name='startDate'
          >
            <DatePicker
              disabledDate={currentDate => currentDate && currentDate < moment().startOf('day')}
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
              getPopupContainer={node => node?.parentNode}
              format='YYYY-MM-DD h:mm A'
              disabled={!!currentEventDetails?.isVenueDraft || isVenueEvent && !isVenueRole}
              suffixIcon={dateIcon}
            />
          </Item>
          <Item
            initialValue={currentEventDetails && moment(currentEventDetails?.endDate, 'YYYY-MM-DD h:mm A')}
            rules={[{ required: true, message: 'Enter End Date' }, validateEndTime]}
            label='End Date and Time'
            name='endDate'
          >
            <DatePicker
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
              getPopupContainer={node => node?.parentNode}
              disabledDate={handleEndTimeDisabled}
              format='YYYY-MM-DD h:mm A'
              suffixIcon={dateIcon}
              disabled={!!currentEventDetails?.isVenueDraft || isVenueEvent && !isVenueRole}
            />
          </Item>
        </div>
      </>
      {eventType === eventTypes.TICKETED_ADMITION || eventType === eventTypes.TICKETED_ADMITION_STREAM ? (
        <>
          <Item
            // initialValue={currentEventDetails?.rainDate ? moment(currentEventDetails?.rainDate) : ''}
            label='Rain Date'
            className='item rainContainer'
            name='rainDate'
          >
            <DatePicker
              disabledDate={currentDate => currentDate && currentDate < moment().add(-1, 'days')}
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              suffixIcon={dateIcon}
            />
          </Item>
          <Item
            className='item currencySelector'
            label='Currency Selector'
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={eventID && !currentEventDetails.isVenueDraft} onChange={onChangeCurrency} value={currency}>
              {Object.keys(currencyCodes).map(k => (
                <Radio key={k} value={currencyCodes[k]?.code}>
                  {`${currencyCodes[k]?.label} (${currencyCodes[k].symbol})`}
                </Radio>
              ))}
            </Radio.Group>
          </Item>
          <Divider />
          <Item className='item switchContainer'>
            <Item name='streamingEnabled' valuePropName='checked'>
              <Switch
                onChange={() => setStreamingEnabled(!streamingEnabled)}
                disabled={!!eventID}
              />
            </Item>
            <div className='label'>
              <div className='title'>Add Live Stream</div>
              {additionalTooltip}
            </div>
          </Item>
          <Divider />
        </>
      ) : (
        <Divider />
      )}
    </div>
  )
}

export default DetailsEvent
