import { Modal, notification } from 'antd'

export const handleConfirmModal = (title = '', onOk = () => {}, onCancel = () => {}) => {
  Modal.confirm({
    title,
    cancelText: 'No',
    okText: 'Yes',
    onOk,
    onCancel,
    centered: true,
  })
}

export const notify = (message = 'Something went wrong, try again!') => notification.warning({ message })

export const notifySuccess = (message = 'Something went wrong, try again!') => notification.success({ message })
