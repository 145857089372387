import React, { useState, useContext } from 'react'
import {
  Radio,
  Form,
} from 'antd'
import moment from 'moment'
import './index.styl'
import { eventsV2 } from 'apiv2'
import { CreateEventContext } from '../../index'

const { Item } = Form

const TemplateList = ({ template, setTemplateUpdateCategory }) => {
  const [selectedOption, setSelectedOptions] = useState(null)
  const { refillSeatingsCategories } = useContext(CreateEventContext)
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
    eventsV2.getTemplateCategories(event.target.value).then(response => {
      const seatingCategories = response.data?.categories
      setTemplateUpdateCategory(seatingCategories)
      refillSeatingsCategories(seatingCategories)
    })
      .catch(err => err)
  }
  const { templateName, created, sections, seats, id } = template
  const creationDate = created && moment(created).utcOffset(created).format('MMMM D, YYYY')
  return (
    <div className='templateListWrapper'>
      <Item
        name='templateID'
        // initialValue={selectedOption}
      >
        <Radio.Group onChange={onValueChange} value={selectedOption}>
          <Radio className='radioTemplateName' value={id}>
            <div className='templateName'>{templateName}</div>
          </Radio>
        </Radio.Group>
      </Item>
      <div className='templateInfo'>
        <div className='infoWrapper'>
          <div className='title'>Sections: <span>{sections}</span> </div>
        </div>
        <div className='infoWrapper'>
          <div className='title'>Seats: <span>{seats}</span> </div>
        </div>
        <div className='infoWrapper'>
          <div className='title'>Created: <span>{creationDate}</span></div>
        </div>
      </div>
    </div>
  )
}

export default TemplateList
