import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomButton } from 'components'
import {
  uploadImageSeating,
} from 'modules/events/events-actions'
import { profile } from 'api'
import { Upload } from 'antd'
import { isVenue } from 'utils'
import ImgCrop from 'antd-img-crop'
import avatarProfile from 'images/avatarProfile.png'
import './index.styl'
const ProfileImage = () => {
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.session)
  const [avatarUrl, setAvatarUrl] = useState(avatarProfile)
  const cancelRequest = useRef(null)

  const onChange = async info => {
    const imgUrl = await new Promise(resolve => {
      const reader = new FileReader()
      reader.readAsDataURL(info.file.originFileObj)
      reader.onload = () => resolve(reader.result)
    })
    if (info.file.status === 'done') {
      dispatch(uploadImageSeating(info.file.originFileObj)).then(response => {
        if (response) {
          setAvatarUrl(imgUrl)
          profile
            .editProfile({ image: JSON.stringify({ original: response.original, thumbnail1: response.thumbnail1 }) })
          cancelRequest.current = null
        }
      })
    }
  }
  const onCustomRequest = ({ onSuccess }) => {
    onSuccess('ok')
  }

  useEffect(() => {
    if (user?.image?.thumbnail1) {
      setAvatarUrl(user?.image?.thumbnail1)
    }
  }, [user])

  return (
    <div className='sectionProfile profileImage'>
      <div className='titleSection'>{isVenue(user?.role) ? 'Venue' : 'Profile'}  Logo</div>
      <div className='flexCol'>
        <img src={avatarUrl} alt='avatarProfile' className='imgAvatar' />
        <ImgCrop rotate>
          <Upload showUploadList={false} accept='image/png, image/jpeg' onChange={onChange} customRequest={onCustomRequest}>
            <CustomButton text='Upload image' type='white' className='btn' />
          </Upload>
        </ImgCrop>
        <div className='description'>*Only jpg/png file formats are allowed.</div>
      </div>
    </div>
  )
}

export default ProfileImage
