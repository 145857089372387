import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Avatar, Menu, Dropdown, Modal } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { logout } from 'modules/session/session-actions'
import DropDownIcon from 'images/DropDownIcon.svg'
import c from 'classnames'
import './index.styl'

const { Item } = Menu
const { confirm } = Modal

const User = () => {
  const { user } = useSelector(state => state.session)
  const history = useHistory()
  const dispatch = useDispatch()
  const activePage = window.location.pathname.split('/')[1]

  const logoutUser = () => {
    confirm({
      title: 'Do you want to logout?',
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        dispatch(logout())
        history.push('/home')
      },
    })
  }

  const goToMyProfile = () => history.push('/my-profile')

  const menu = (
    <Menu>
      <Item key='0' onClick={goToMyProfile}>Profile</Item>
      <Item key='1' onClick={logoutUser}>Log Out</Item>
    </Menu>
  )
  const avatar = user?.image ? <Avatar size={30} src={user?.image?.thumbnail1} className='userAvatar' /> : <Avatar size={30} icon={<UserOutlined />} className='userAvatar' />

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={c('wrapperUser', { active: activePage === 'my-profile' })}>
        {avatar}
        <div className='textName'>{user?.userName}</div>
        <img src={DropDownIcon} alt='Drow down icon' className='icon' />
      </div>
    </Dropdown>
  )
}

export default User
