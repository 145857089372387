import React, { createContext, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import {
  editTicketV2,
  makeTicketV2,
  setEventsIsStream,
  eventsSetImages,
} from 'modules/events/events-actions'
import Header from './components/Header'
import moment from 'moment'
import { CustomButton } from 'components'
import { useDispatch, useSelector } from 'react-redux'

import { prepareAttachments, currencyCodes } from 'utils'

import Body from './components/Body'
import Footer from './components/Footer'
import DesktopHoles from 'images/desktop-holes.svg'
import TablesHoles from 'images/tables-holes.svg'
import MobileHoles from 'images/mobile-holes.svg'
import { Button, Form, notification } from 'antd'
import './index.styl'
import ModalBuyStreamTicket from '../CreateEventV2/ModalBuyStreamTicket'

export const CreateEventContext = createContext(null)
const MakeTicketComponents = ({ currentEventDetails }) => {
  const [form] = Form.useForm()
  const { imageUrls, filesAttachment } = useSelector(state => state.events)
  const [finished, setFinished] = useState(false)
  const [modalBuyStream, setModalBuyStream] = useState(null)
  const [streamQuantity, setStreamQuantity] = useState(null)
  const [streamType, setStreamType] = useState(null)

  const { state = {} } = useLocation()
  const { push, goBack } = useHistory()

  const dispatch = useDispatch()
  const { eventID } = useParams()

  useEffect(() => {
    if (Object.keys(currentEventDetails?.images || {}).length) {
      dispatch(eventsSetImages(currentEventDetails?.images))
    }
    return () => dispatch(eventsSetImages({}))
  }, [currentEventDetails?.images])

  const initialValues = {
    eventName: currentEventDetails?.eventName,
    zoomUrl: currentEventDetails?.virtualEvent?.url,
    zoomPassword: currentEventDetails?.virtualEvent?.password,
    zoomEventParticipants: currentEventDetails?.virtualEvent?.maxParticipants,
    location: currentEventDetails?.location,
    venue: currentEventDetails?.venue,
    time: currentEventDetails?.time,
    presentedBy: currentEventDetails?.presentedBy,
    timeZone: currentEventDetails?.timeZone,
    date: currentEventDetails?.date?.startDate ? moment(currentEventDetails?.date?.startDate) : undefined,
    currency: currentEventDetails?.currency || currencyCodes.USD.code,
    rainDate: currentEventDetails?.rainDate ? moment(currentEventDetails?.rainDate) : undefined,
    donationEnabled: currentEventDetails?.donationEnabled || false,
    isShowTLHomePage: currentEventDetails?.isShowTLHomePage !== undefined ? currentEventDetails.isShowTLHomePage : true,
    description: currentEventDetails?.description,
    eventUrl: currentEventDetails?.eventUrl,
    generalAdmission: currentEventDetails?.generalAdmission || [{}],
    liveStream: currentEventDetails?.liveStream || [{}],
    raffle: currentEventDetails?.raffle || [{}],
    table: currentEventDetails?.table || [{}],
    virtualMeeting: currentEventDetails?.virtualMeeting || [{}],
    itemTicket: currentEventDetails?.itemTicket || [{}],
    generalAdmissionSwitch: !!currentEventDetails?.generalAdmission?.length,
    liveStreamSwitch: !!currentEventDetails?.liveStream?.length,
    raffleSwitch: !!currentEventDetails?.raffle?.length,
    tableSwitch: !!currentEventDetails?.table?.length,
    virtualMeetingSwitch: !!currentEventDetails?.virtualMeeting?.length,
    itemTicketSwitch: !!currentEventDetails?.itemTicket?.length,
  }
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [currentEventDetails])
  const handleBack = () => {
    if (eventID && !currentEventDetails?.isVenueDraft) {
      push({ pathname: `/my-box-office/event-details/tickets-categories/${eventID}`, state })
    } else {
      push({ pathname: '/my-box-office/table-events', state })
    }
  }
  const onCloseModal = () => {
    setFinished(false)
    setModalBuyStream(null)
  }

  const onSubmitBought = (cardToken, promo) => {
    return new Promise(resolve => {
      const data = { ...modalBuyStream, cardToken }
      if (promo) {
        data.promo = promo
      }
      dispatch(makeTicketV2(data))
        .then(response => {
          if (response) {
            dispatch(setEventsIsStream(true))
            dispatch(setEventsIsStream(true))
            onCloseModal()
            handleBack()
          }
          resolve()
        }).catch(resolve)
    })
  }

  const handleCancel = () => {
    goBack()
  }
  const handleFinish = values => {
    if (finished) return
    if (!values.generalAdmission && !values.liveStream && !values.raffle && !values.table && !values.virtualMeeting && !values.itemTicket) {
      return notification.warning({ message: 'One of the categories is required' })
    }
    const formatDateString = (startDate, endDate) => {
      const formattedStartDate = moment(startDate).format('YYYY-MM-DD')
      const formattedEndDate = moment(endDate).format('YYYY-MM-DD')
      const date = { startDate: formattedStartDate, endDate: formattedEndDate }
      return date
    }
    const formatRainDate = rainDate => {
      const formattedRainDate = moment(rainDate).format('YYYY-MM-DD')
      return formattedRainDate
    }
    const { start, end } = values.time

    const startTime = `${start.hour}:${start.minute} ${start.ampm}`
    const endTime = `${end.hour}:${end.minute} ${end.ampm}`

    const startMoment = moment(startTime, 'h:mm a')
    const endMoment = moment(endTime, 'h:mm a')
    const endDate = startMoment.isAfter(endMoment) ? moment(values.date).add(1, 'day') : moment(values.date)

    const eventData = {
      eventName: values.eventName,
      venue: values.venue,
      location: values.location,
      time: values.time,
      generalAdmission: values.generalAdmission,
      liveStream: values.liveStream,
      raffle: values.raffle,
      table: values.table,
      virtualMeeting: values.virtualMeeting,
      itemTicket: values.itemTicket,
      date: formatDateString(values.date, endDate),
      timeZone: values.timeZone,
      donationEnabled: values.donationEnabled,
      isShowTLHomePage: values.isShowTLHomePage,
      currency: values.currency,
      eventUrl: values.eventUrl,
      presentedBy: values.presentedBy,
      rainDate: values.rainDate && formatRainDate(values.rainDate),
      description: values.description,
      showAvailableTickets: values.showAvailableTickets || false,
    }
    if (!Object.keys(imageUrls).length && !currentEventDetails) {
      return notification.warning({ message: 'Upload image for ticket' })
    } else {
      // eventData.imageUrl = JSON.stringify(imageUrls)
      eventData.images = imageUrls
    }
    if (filesAttachment.length) {
      eventData.attachments = JSON.stringify(prepareAttachments(filesAttachment)) || []
      eventData.attachments = prepareAttachments(filesAttachment) || []
    } else {
      eventData.attachments = []
    }
    const fees = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.paymentProcessorFee) {
      fees.paymentProcessorFee = values?.paymentProcessorFee
    }
    eventData.fees = fees
    const action = eventID ? editTicketV2(eventData, eventID) : makeTicketV2(eventData)
    if (values.liveStream) {
      values.liveStream.map(i => {
        const { quantity, isPrivateStream } = i
        setStreamQuantity(quantity)
        setStreamType(isPrivateStream ? 'PRIVATE_STREAM' : 'PPV_STREAM')
      })
    }
    setFinished(true)
    if (values.liveStream && !eventID) {
      setModalBuyStream({ ...eventData })
    } else {
      dispatch(action).then(() => {
        setFinished(false)
        handleBack()
      }).catch(() => setFinished(false))
    }
  }
  const holes = window.innerWidth <= 1350 ? TablesHoles : window.innerWidth <= 730 ? MobileHoles : DesktopHoles
  return (
    <CreateEventContext.Provider
      value={{
        form,
        currentEventDetails,
      }}
    >
      <Form
        form={form}
        scrollToFirstError
        colon={false}
        layout='vertical'
        validateTrigger='onChange'
        initialValues={initialValues}
        onFinish={handleFinish}
        className='makeTicketV2'
        // onFinishFailed={validateEvent}
      >
        <div className='makeTicketWrapper'>
          <Header currentEventDetails={currentEventDetails} form={form} />
          <div style={{ position: 'relative' }}>
            <img className='holes' src={holes} alt='' />
          </div>
          <Body />
          <div style={{ position: 'relative' }}>
            <img className='holes' src={holes} alt='' />
          </div>
          <div className='footerBtnsWrapper'>
            <Footer form={form} currentEventDetails={currentEventDetails} />
            <div className='btnsWrapper'>
              <Button className='cancelBtn' onClick={handleCancel} type='link'>Cancel</Button>
              <CustomButton
                htmlType='submit'
                text={eventID ? 'Update Event' : 'Create Event'}
                className='btnMakeTicket'
                type='dark'
              />
            </div>
          </div>
        </div>
      </Form>
      <ModalBuyStreamTicket
        streamType={streamType}
        dataTicket={modalBuyStream}
        setVisible={onCloseModal}
        visible={!!modalBuyStream}
        callback={onSubmitBought}
        currency={currencyCodes.USD.code}
        quantity={streamQuantity}
      />
    </CreateEventContext.Provider>
  )
}

export default MakeTicketComponents
