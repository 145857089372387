import * as actionTypes from './templates-constants'

export const sessionInitialState = {
  buySeat: [],
}

export const templatesReducer = (state = sessionInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.BUY_SEAT:
      return { ...state, buySeat: [...state.buySeat, payload] }
    case actionTypes.REMOVE_BUY_SEAT:
    {
      const index = state.buySeat.findIndex(i => i === payload)
      const newArr = state.buySeat.slice(0, index).concat(state.buySeat.slice(index + 1))
      return { ...state, buySeat: newArr }
    }
    default:
      return state
  }
}
