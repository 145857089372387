import React from 'react'
import { useDispatch } from 'react-redux'
import { loginGuest } from 'modules/payment/payment-actions'
import { CustomButton } from 'components'
import { Form, Input, notification } from 'antd'
import { onValuesChange } from 'utils'
import { getRule } from '../MockTicket/MockTicketDetailsForm/formHelpers'
import './index.styl'
import c from 'classnames'

const { Item } = Form

const LoginGuest = ({ onChangeScreen }) => {
  const dispatch = useDispatch()

  const normalize = value => value?.trim()

  const onFinish = form => {
    const { phone, confirmPhone, email, confirmEmail, fullName } = form
    if (phone !== confirmPhone) {
      notification.warning({ message: 'Phones are not matched' })
    } else if (email !== confirmEmail) {
      notification.warning({ message: 'Emails are not matched' })
    } else {
      dispatch(loginGuest({ ...form, fullName: normalize(fullName) }))
      onChangeScreen('payment')
    }
  }

  const goBack = () => onChangeScreen('login')

  return (
    <div className='wrapperLoginGuest'>
      <div className='title'>Please enter the following information</div>
      <Form layout='vertical' className='form' onFinish={onFinish} onValuesChange={onValuesChange}>
        <Item name='fullName' label='Full name' rules={getRule('Full name', 'fullName')} validateTrigger='onBlur'>
          <Input className='input darkBg' />
        </Item>
        <Item
          name='phone'
          label='Phone number'
          className='item'
          rules={getRule('Phone number', 'phoneNumber')}
          validateTrigger='onBlur'
        >
          <Input type='number' className={c('input', 'darkBg')} prefix='+1' />
        </Item>
        <Item
          name='confirmPhone'
          label='Confirm phone number'
          className='item'
          rules={getRule('Confirm phone number', 'confirmPhone')}
          validateTrigger='onBlur'
        >
          <Input type='number' className={c('input', 'darkBg')} prefix='+1' />
        </Item>
        <Item
          name='email'
          label='Email'
          className='item'
          rules={getRule('Email', 'email')}
          validateTrigger='onBlur'
        >
          <Input className='input darkBg' />
        </Item>
        <Item
          name='confirmEmail'
          label='Confirm email'
          className='item'
          rules={getRule('Confirm email', 'confirmEmail')}
          validateTrigger='onBlur'
        >
          <Input className='input darkBg' />
        </Item>

        <div className='wrapperActions'>
          <CustomButton
            text='Go Back'
            type='white'
            className='additionalBtn'
            htmlType='button'
            onClick={goBack}
          />
          <CustomButton
            text='Continue'
            type='dark'
            className='btn'
            htmlType='submit'
          />
        </div>
      </Form>
    </div>
  )
}

export default LoginGuest
