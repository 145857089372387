import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Pagination, Input, Empty, notification } from 'antd'
import {
  getEventGetTransactionList,
  getEventDetailsSummaryV2,
} from 'modules/events/events-actions'
import { CustomButton, OverflowTooltip } from 'components'
import { handleConfirmModal, notify } from 'utils'
import { events } from 'api'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import SearchIcon from 'images/input-search.svg'
import c from 'classnames'
import './index.styl'

const PurchaseDetails = ({ eventID, isChange, updateFunc, currencySymbol, isResponsive }) => {
  const { transactionList } = useSelector(state => state.events)
  const [tableData, setTableData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(window.innerWidth < 1200 ? 3 : 10)
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(true)
  const [dataLength, setDataLength] = useState(0)
  const dispatch = useDispatch()
  const containerRef = useRef()

  useEffect(() => {
    if (isChange) {
      dispatch(getEventDetailsSummaryV2(eventID))
    }
  }, [isChange])

  const getTransactionList = (currentPage = 1) => {
    const skip = (currentPage * perPage) - perPage

    if (eventID) {
      setLoading(true)
      dispatch(getEventGetTransactionList(eventID, perPage, skip, searchValue))
        .then(() => {
          isResponsive && containerRef.current.scrollIntoView()
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setLoading(true)
    const data = transactionList?.data?.map(i => ({
      userName: i?.userName,
      userFullname: i?.fullName,
      userPhone: i?.fullMobile,
      userEmail: i?.email,
      transID: i?.trxId,
      amount: `${currencySymbol}${i.amount}`,
      status: i.isRefunded,
      ticketCategory: i?.ticketName?.join('/'),
      totalTickets: i.quantity,
      transactionID: i._id,
    }))
    setTableData(data)
    setLoading(false)
  }, [transactionList])

  useEffect(() => {
    getTransactionList()
  }, ['currentPage'])

  useEffect(() => {
    currentPage !== 1 ? setCurrentPage(1) : getTransactionList()
  }, [searchValue])

  useEffect(() => {
    setDataLength(transactionList?.total)
  }, [transactionList])

  useEffect(() => {
    setSearchValue('')
  }, [])

  let requestDelay = 0
  const onChangeSearch = e => {
    const search = e.target.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchValue(search)
    }, 700)
  }

  const onCopied = () => notification.success({ message: 'Element copied to clipboard' })
  const refundTransaction = ({ transactionID }) => {
    const handleRefund = () => {
      setLoading(true)
      events.refundTicket({ eventID, transactionID })
        .then(() => {
          getTransactionList()
          updateFunc(true)
        })
        .catch(error => notify(error?.response?.data?.message || error?.message))
        .finally(() => setLoading(false))
    }
    handleConfirmModal('Are you sure you want to refund this ticket?', handleRefund)
  }
  const renderRefundButton = section => (
    <CustomButton text='Refund' type='outline' disabled={section.status} onClick={() => refundTransaction(section)} />
  )

  const columns = [
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
      width: 90,
      render: userName => (
        <OverflowTooltip title={userName} trigger='hover'>
          <div className='nickname'>{userName}</div>
        </OverflowTooltip>
      ),
    },
    {
      title: 'Name, phone number, email',
      dataIndex: 'userInfo',
      key: 'userInfo',
      render: (_, record) => (
        <div className='groupInfo'>
          <OverflowTooltip title={record.userFullname} trigger='hover'>
            <div>{record.userFullname}</div>
          </OverflowTooltip>
          <OverflowTooltip title={record.userPhone} trigger='hover'>
            <div>{record.userPhone}</div>
          </OverflowTooltip>
          <OverflowTooltip title={record.userEmail} trigger='hover'>
            <div>{record.userEmail}</div>
          </OverflowTooltip>
        </div>
      ),
    },
    {
      title: 'Trans. ID',
      dataIndex: 'transID',
      key: 'transID',
      width: 80,
      render: transID => (
        <CopyToClipboard text={transID} onCopy={onCopied}>
          <div className='transIdText'>{transID}</div>
        </CopyToClipboard>
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      dataIndex: 'amount',
      render: amount => <div className='amount'>{amount}</div>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: status => (
        <div className={c('purchaseDetailsStatus', { refunded: status })}>{status ? 'Refunded' : 'Not Refunded'}</div>
      ),
    },
    {
      title: 'Ticket category',
      key: 'ticketCategory',
      dataIndex: 'ticketCategory',
      render: ticketCategory => <div className='ticketCategory'>{ticketCategory}</div>,
    },
    {
      title: 'Total tickets',
      key: 'totalTickets',
      dataIndex: 'totalTickets',
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, section) => renderRefundButton(section),
    },
  ]

  const searchPanel = (
    <div className='searchPanel'>
      <Input
        prefix={<img alt='search icon' src={SearchIcon} />}
        className='inputSearch darkBg noFocusBorder'
        placeholder='Search financial record'
        onChange={e => onChangeSearch(e)}
        allowClear
      />
    </div>)
  const MobileTable = () => {
    return (
      <div className='mobileTable'>
        {
          tableData?.length ? tableData.map((i, index) => (
            <div key={index} className='ticketCategoriesTable'>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Username</div>
                <div className='ticketCategoriesText'>{i.userName}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Phone</div>
                <div className='ticketCategoriesText'>{i.userPhone}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Email</div>
                <div className='ticketCategoriesText'>{i.userEmail}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Trans. ID</div>
                <div className='ticketCategoriesText'>{i.transID}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Amount</div>
                <div className='ticketCategoriesText'>{i.amount}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Status</div>
                <div className='ticketCategoriesText'>
                  <div className={c('purchaseDetailsStatus', { refunded: i.status })}>{i.status ? 'Refunded' : 'Not Refunded'}</div>
                </div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Ticket Category</div>
                <div className='ticketCategoriesText'>{i.ticketCategory}</div>
              </div>
              <div className='ticketCategoriesItem'>
                <div className='ticketCategorieslabel'>Total Tickets</div>
                <div className='ticketCategoriesText'>{i.totalTickets}</div>
              </div>
              <div className='ticketAction'>{renderRefundButton(i)}</div>
            </div>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </div>)
  }

  const handlePagination = async page => {
    setCurrentPage(page)
    getTransactionList(page)
  }

  return (
    <>
      <div className='wrapperTablesAssignSeating PurchaseDetails'>
        <div className='titleAssignedSeating' ref={containerRef}>Online Purchase Details</div>
        {isResponsive
          ? (
            <div className='mobileTableWrapper'>
              {searchPanel}
              <MobileTable />
            </div>
          )
          : (
            <Table
              columns={columns}
              pagination={false}
              title={() => searchPanel}
              loading={loading}
              dataSource={tableData}
              className='eventDetailsTable financialTable'
              rowKey='transID'
            />
          )}
        <Pagination hideOnSinglePage total={dataLength} pageSize={perPage} current={currentPage} onChange={handlePagination} className='pagination' />
      </div>
    </>
  )
}

export default PurchaseDetails
