import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LogoMemberLyfe } from 'components'
import SectionLinks from './SectionLinks'
import { isMobileOnly } from 'react-device-detect'
import './index.styl'

const FooterMemberLyfe = () => {
  const { isMobile, user } = useSelector(state => state.session)
  const { pathname } = useLocation()
  const links = [
    {
      title: 'Our Company',
      // items: ['About Us', 'Careers', 'Partners', 'For Developers'],
      items: [{ title: 'Terms & Conditions', link: { pathname: '/help/terms-and-conditions', state: { fromRouter: pathname } } }],
    },
    {
      title: 'Have Questions?',
      // items: ['Our Community', 'Help Center', 'Press Box', 'For Developers'],
      items: [
        { title: 'Help Center', link: isMobileOnly ? '/help' : '/help/overview/for-venues' },
        // { title: 'FAQ', link: { pathname: '/help/faq', state: { fromRouter: pathname } } },
        { title: 'Contact Us', link: '/contact-us' },
      ],
    },
  ]

  const text = isMobile
    ? ''
    : 'Use of this website signifies your agreement to our User Agreement, Privacy Notice and Cookie Notice.'

  return (
    <footer className='footerHome'>
      <div className='top'>
        <div className='leftFooter'>
          <div><LogoMemberLyfe user={user} /></div>
          {links.map(i => (
            <SectionLinks key={i.title} title={i.title} links={i.items} />
          ))}
        </div>
      </div>
      {/* <SubscribeInput /> */}

      <div className='bottom'>
        2010-2024 Ticket Lyfe. All Rights Reserved. {text}
      </div>
    </footer>
  )
}

export default FooterMemberLyfe
