/* eslint-disable indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, createContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CustomButton } from 'components'
import MultiMediaContainer from './MultiMediaContainer'
import ModalBuyStreamTicket from './ModalBuyStreamTicket'
import DetailsEvent from './DetailsEvent/index'
import OptionsEvent from './OptionsEvent/index'
import { isVenue, currencyCodes, categories, categoryInitialValues, prepareAttachments, prepareSections, getEventType } from 'utils'
import { Collapse, Form, notification, Button } from 'antd'
import {
  updateEvent,
  updateVenueEvent,
  addTicketCategory,
  removeTicketCategory,
  createEvent,
  createEventVenue,
  getEventDetails,
  eventsSetImages,
  setEventsIsStream,
} from 'modules/events/events-actions'

import { trimObject } from 'modules/utils'
import moment from 'moment'
import TicketCategory from './TicketCategory'
import EventsCheckbox from './EventsCheckbox'
import expandIcon from 'images/expandIcon.svg'
import backIcon from 'images/DropDownIcon.svg'
import doneIcon from 'images/doneIcon.svg'
import c from 'classnames'
import './index.styl'
import DetailsEventVenue from './DetailsEventVenue'
import ModalInfo from './ModalInfo'
import Fees from './Fees'
import ModalChoosePromotion from '../ModalChoosePromotion'
import SeatingCreationMode from './SeatingCreationMode'

export const CreateEventContext = createContext(null)
const { Panel } = Collapse

const CreateEvent = ({ currentEventDetails }) => {
  const dispatch = useDispatch()
  const { user: { isStripeAccount, role, venueName, address } } = useSelector(state => state.session)
  const { goBack, push } = useHistory()
  const { eventID } = useParams()
  const { state = {} } = useLocation()
  const [form] = Form.useForm()
  const { imageUrls, filesAttachment } = useSelector(state => state.events)
  const [finished, setFinished] = useState(false)
  const [modalBuyStream, setModalBuyStream] = useState(null)
  const [ticketLifeFee, setTicketLifeFee] = useState('')
  const [templateCover, setTemplateCover] = useState('')
  const [nonProfitOrganization, setNonProfitOrganization] = useState(currentEventDetails?.saleTax?.nonProfit || false)
  const [eventType, setEventType] = useState(getEventType(currentEventDetails))
  const [category, setCategory] = useState(categories[eventType] || categories.live)
  const [currency, setCurrency] = useState(currentEventDetails?.currency || currencyCodes.USD.code)
  const [activePanel, setActivePanel] = useState(['eventType'])
  const [eventDetailsStepDone, setEventDetailsStepDone] = useState(!!currentEventDetails?._id)
  const [AssignSeatingStepDone, setAssignSeatingStepDone] = useState(!!currentEventDetails?._id)
  const streamingCategoryIndex = currentEventDetails?.tickets.findIndex(i => i.isStreaming)
  const [streamingEnabled, setStreamingEnabled] = useState(!!currentEventDetails?.tickets[streamingCategoryIndex]?.isStreaming || currentEventDetails?.hasStream)
  const [modalInfo, setModalInfo] = useState({ visible: false, message: 'You have no co-promoter for this event', data: {} })
  const [quantity, setQuantity] = useState(null)
  const [stateComplite, setStateComplite] = useState(false)
  const [isStepTwo, setIsStepTwo] = useState(false)
  const [showModalPromotion, setShowModalPromotion] = useState(false)
  const [venuePromoterOff, setVenuePromoterOff] = useState(!!currentEventDetails?.imageUrl || false)
  useEffect(() => {
    setCategory(categories[eventType] || categories.live)
    window.dataLayer.push({ event: 'Make tickets' })
  }, [eventType])

  useEffect(() => {
     if (isVenue(role) && !currentEventDetails) {
      setShowModalPromotion(true)
    }
  }, [])

  useEffect(() => {
    currentEventDetails?.currency && setCurrency(currentEventDetails?.currency)
  }, [currentEventDetails?.currency])

  useEffect(() => {
    if (Object.keys(currentEventDetails?.imageUrl || {}).length) {
      dispatch(eventsSetImages(currentEventDetails?.imageUrl))
    }
    return () => dispatch(eventsSetImages({}))
  }, [currentEventDetails?.imageUrl])

  const handleBack = () => {
    if (eventID && !currentEventDetails?.isVenueDraft) {
      push({ pathname: `/my-box-office/event-details/tickets-categories/${eventID}`, state })
    } else {
      push({ pathname: '/my-box-office/table-events', state })
    }
  }
  // this comes from api after payment
  const streamCategoryPermitted = true

  const handleFinish = async values => {
    if (finished) return
    const { ticketType, quantity, price, bgColor, privacyOption, isEarlyBird, earlyBirdDate, earlyBirdPrice, venmo } = values[category]
    const defaultCategoryData = {
      ticketID: currentEventDetails?.tickets[0]?._id,
      ticketType,
      quantity,
      price,
      bgColor,
      isPrivate: privacyOption === 'invite',
      isInviteOnly: privacyOption === 'invite',
      isEarlyBird,
      venmo,
      isStreaming: eventType === 'stream',
      isPrivateStream: eventType === 'stream' && values?.ticketLifeFee === 'Private Live Stream' && true,
    }
    if (!!values?.defaultCategory) {
      const value = values?.defaultCategory?.subpromoters?.map(el => el?.defaultCategory)
      defaultCategoryData.subpromoters = value
    }
    if ((!price || price === 0) && privacyOption === 'visible') {
      return notification.warning({ message: 'Please change your privacy options to Invite Only' })
    }
    if (isEarlyBird) {
      defaultCategoryData.earlyBirdDate = moment(earlyBirdDate).format('YYYY-MM-DD')
      defaultCategoryData.earlyBirdPrice = earlyBirdPrice
    }
    trimObject(defaultCategoryData)

    const { eventName, timeStart, timeEnd, presentedBy, eventUrl = '', donationEnabled, timeZone, currency, ticketLifeFee, showOnHomePageEnabled } = values
    const dateTransform = date => {
      return `${moment(date).format('YYYY-MM-DD')}T${moment(date).format('HH:mm')}`
    }
    const eventData = {
      showAvailableTickets: values.showAvailableTickets,
      eventID: eventID,
      eventName,
      startDate: dateTransform(timeStart),
      endDate: dateTransform(timeEnd),
      isDonationEnable: donationEnabled,
      isShowTLHomePage: showOnHomePageEnabled,
      isAssignTicket: false,
      isVirtualTicket: eventType === 'virtual',
      timeZone,
      eventUrl,
      presentedBy,
      ticketType: [defaultCategoryData],
      currency: currency || 'USD',
    }
    if (moment(timeEnd).isSameOrBefore(timeStart, 'minutes')) {
      return notification.warning({ message: 'Start time should be earlier than end time.' })
    }

    if (filesAttachment.length) {
      eventData.attachments = JSON.stringify(prepareAttachments(filesAttachment))
    }
    // TO-DO - move image validation elsewhere
    if (!Object.keys(imageUrls).length) {
      return notification.warning({ message: 'Upload image for ticket' })
    } else {
      eventData.imageUrl = JSON.stringify(imageUrls)
    }

    if (eventType === 'virtual') {
      const { zoomUrl, zoomPassword, zoomEventParticipants, zoomEventDescription } = values
      const virtualEvent = { url: zoomUrl, maxParticipants: +zoomEventParticipants, password: zoomPassword, description: zoomEventDescription }
      trimObject(virtualEvent)
      eventData.virtualEvent = JSON.stringify(virtualEvent)
    }
    if (eventType === 'live') {
      const { rainDate, eventDescription, venue, isAssignTicket } = values
      eventData.rainDate = rainDate && moment(rainDate).format('YYYY-MM-DD')
      eventData.description = eventDescription
      eventData.isAssignTicket = isAssignTicket
      eventData.venue = venue
      if (streamCategoryPermitted && values?.streamingCategory) {
        const { bgColor, ticketType, privacyOption, price, quantity, isEarlyBird, earlyBirdDate, earlyBirdPrice, venmo, subpromoters } = values?.streamingCategory
        setQuantity(quantity)
        const streamingCategoryData = {
          isInviteOnly: privacyOption !== 'visible',
          isPrivate: privacyOption !== 'visible',
          isPrivateStream: values?.ticketLifeFee === 'Private Live Stream' && true,
          isStreaming: true,
          ticketType: ticketLifeFee || ticketType,
          isEarlyBird,
          bgColor,
          quantity,
          price,
          venmo,
        }
        if (!!subpromoters) {
          const subpromotersValue = subpromoters?.map(el => el?.streamingCategory)
          streamingCategoryData.subpromoters = subpromotersValue
        }
        // add ticket category if editing event and added streaming
        if (isEarlyBird) {
          streamingCategoryData.earlyBirdDate = moment(earlyBirdDate).format('YYYY-MM-DD')
          streamingCategoryData.earlyBirdPrice = earlyBirdPrice
        }
        if (eventID) {
          const streamingCategory = currentEventDetails?.tickets.find(i => i?.isStreaming)
          if (streamingCategory) {
            streamingCategoryData.ticketID = streamingCategory?._id
          } else {
            const toAddCategory = { eventID, ticketType: JSON.stringify([streamingCategoryData]) }
            const { data: res } = await dispatch(addTicketCategory(toAddCategory))
            streamingCategoryData.ticketID = res.data[0]._id
          }
        }
        eventData.ticketType = [...eventData.ticketType, streamingCategoryData]
      }

      // delete stream from live event if switch turned of
      if (!values.streamingCategory && !currentEventDetails?.isVenueDraft) {
        const streamingCategory = currentEventDetails?.tickets.find(i => i.isStreaming)
        if (streamingCategory) {
          const obj = {
            eventID: currentEventDetails._id,
            ticketID: streamingCategory._id,
          }
          await dispatch(removeTicketCategory(obj, eventID))
        }
      }
      if (isAssignTicket) {
        if (eventID && !currentEventDetails?.isVenueDraft) {
          eventData.sections = JSON.stringify([])
        } else if (values.sections?.length) {
          eventData.sections = JSON.stringify(prepareSections(values.sections))
        }
      }
      if (eventID) {
        const newCatsIds = values.categories.map(c => c._id).filter(Boolean)
        const toRemove = currentEventDetails.tickets
          ?.slice(1)
          ?.filter(i => !i.isStreaming)
          ?.filter(i => !newCatsIds.includes(i._id))
        if (toRemove.length) {
          await Promise.all(toRemove.map(async ticket => {
            dispatch(removeTicketCategory({ eventID, ticketID: ticket?._id }, eventID))
          }))
        }
      }
      // handle additional categories
      if (typeof values.categories !== 'undefined' && values.categories.length > 0) {
        const additionalCategories = await Promise.all(
          values.categories?.map(async (category, index) => {
            const indexOfExistingTicket = currentEventDetails?.tickets.findIndex(t => t._id === category._id)
            const { ticketType, bgColor, quantity, price, privacyOption, isEarlyBird, earlyBirdDate, earlyBirdPrice, subpromoters } = category
            const subpromotersFiltered = subpromoters?.slice(1, subpromoters?.length).map(el => el[0])
            const obj = {
              ticketType,
              bgColor,
              quantity,
              price,
              isPrivate: privacyOption !== 'visible',
              isInviteOnly: privacyOption !== 'visible',
            }
            if (subpromoters?.length) {
            obj.subpromoters = [subpromoters?.[0][0], ...subpromotersFiltered]
            }
            if (isEarlyBird) {
              obj.isEarlyBird = true
              obj.earlyBirdDate = earlyBirdDate
              obj.earlyBirdPrice = earlyBirdPrice
              if (currentEventDetails?.tickets[indexOfExistingTicket] && currentEventDetails?.tickets[indexOfExistingTicket].isEarlyBird !== isEarlyBird) {
                obj.earlyBirdDate = earlyBirdDate.add(1, 'days')
              }
            } else {
              obj.isEarlyBird = false
            }
            if (eventID) {
              if (indexOfExistingTicket !== -1) {
                obj.ticketID = currentEventDetails?.tickets[indexOfExistingTicket]._id
              } else {
                const toAddCategory = {
                  eventID,
                  ticketType: JSON.stringify([obj]),
                }
                const { data: res } = await dispatch(addTicketCategory(toAddCategory))
                obj.ticketID = res.data[0]._id
              }
            }
            return obj
          }))
        eventData.ticketType = [...eventData.ticketType, ...additionalCategories]
      }
    }
    if (eventType === 'stream') {
      const { eventDescription, venue } = values
      eventData.description = eventDescription
      eventData.venue = venue
      eventData.isStreamingEvent = true
      const subpromoters = values?.streamingCategory?.subpromoters?.map(el => el?.streamingCategory)
      eventData.ticketType = [{ ...eventData.ticketType[0], subpromoters }]
      setQuantity(quantity)
    }
    const streamTickets = eventData.ticketType.filter(({ isStreaming }) => !!isStreaming)
    // stringify final categories array
    eventData.ticketType = JSON.stringify(eventData.ticketType)
    trimObject(eventData, ['eventUrl'])

    if (eventType !== 'virtual') {
      eventData.address = values?.location || ''
    }
    const fees = {}
    const saleTax = {}

    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.stripeVenmoFee) {
      fees.paymentProcessorFee = values?.stripeVenmoFee
    }
    if (values?.saleTaxCoverage) {
      saleTax.coverage = values?.saleTaxCoverage
    }
    if (values?.saleTaxPercent) {
      saleTax.percent = values?.saleTaxPercent
    }
      saleTax.nonProfit = nonProfitOrganization
    if (values?.taxNumber) {
      saleTax.EIN = `${values?.taxNumber}`
    }
    if (currentEventDetails?.isVenueEvent || venuePromoterOff) {
    eventData.saleTax = JSON.stringify(saleTax)
    }
    eventData.fees = JSON.stringify(fees)
    const action = eventID ? updateEvent(eventData, eventID) : createEvent(eventData)
    setFinished(true)
    setEventDetailsStepDone(true)
    if (streamTickets.length && !eventID || currentEventDetails?.isVenueDraft && currentEventDetails?.tickets[1]?.isStreaming) {
      setTicketLifeFee(ticketLifeFee)
      setModalBuyStream({ ...eventData })
    } else {
      dispatch(action).then(() => {
        setFinished(false)
        handleBack()
      }).catch(() => setFinished(false))
    }
  }
  useEffect(() => {
    if (currentEventDetails?.isVenueDraft) {
    setEventDetailsStepDone(false)
     const streamingCategoryData = {
          ticketID: currentEventDetails?.tickets[0]?._id,
          ticketType: 'General Admission',
          bgColor: '#4F4F4F',
          quantity: 0,
          price: 0,
          isPrivate: false,
          isInviteOnly: false,
          isEarlyBird: false,
        }

         const defaultCategoryData = {
          isInviteOnly: false,
          isPrivate: true,
          // isPrivateStream: true,
          isStreaming: true,
          ticketType: 'PPV Live Stream',
          isEarlyBird: false,
          bgColor: '#4F4F4F',
          quantity: 0,
          price: 0,
        }
        if (currentEventDetails?.tickets?.length === 0 && !role?.includes('venue')) {
        const toAddCategory = { eventID, ticketType: JSON.stringify([streamingCategoryData]) }
        const { data: res } = dispatch(addTicketCategory(toAddCategory)).then(() => {
              dispatch(getEventDetails(eventID))
            })
        streamingCategoryData.ticketID = res?.data[0]._id
      }
       if (streamingEnabled && currentEventDetails?.tickets?.[1]?.ticketType !== 'PPV Live Stream' && !role?.includes('venue')) {
       const toAddCategory = { eventID, ticketType: JSON.stringify([defaultCategoryData]) }
            const { data: res } = dispatch(addTicketCategory(toAddCategory)).then(() => {
              dispatch(getEventDetails(eventID))
            })
            streamingCategoryData.ticketID = res?.data[1]._id
    }
  }
  }, [currentEventDetails?.isVenueDraft])

  const handleFinishVanue = values => {
    if (isStepTwo) {
     return false
    }
    const dateTransform = date => {
      return `${moment(date).format('YYYY-MM-DD')}T${moment(date).format('HH:mm')}`
    }
    const data = {
      startDate: dateTransform(values?.startDate),
      endDate: dateTransform(values?.endDate),
      venue: venueName,
      eventName: values?.eventName,
      address: address,
      countryCode: '+1',
      timeZone: values?.timeZone,
      promotersEmail: values?.promotersEmail.toLowerCase(),
      promotersMobile: values?.promotersMobile,
      promotersName: values?.promotersName,
    }
    if (streamingEnabled) {
     data.isStreaming = true
    }
    if (values?.eventPayout) {
      data.eventPayout = values?.eventPayout
    }
    const fees = {}
    const saleTax = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.stripeVenmoFee) {
      fees.paymentProcessorFee = values?.stripeVenmoFee
    }
    if (values?.saleTaxCoverage) {
      saleTax.coverage = values?.saleTaxCoverage
    }
    if (values?.saleTaxPercent) {
      saleTax.percent = values?.saleTaxPercent
    }
      saleTax.nonProfit = nonProfitOrganization
    if (values?.taxNumber) {
      saleTax.EIN = `${values?.taxNumber}`
    }
    data.saleTax = JSON.stringify(saleTax)
    data.fees = JSON.stringify(fees)
    if (currentEventDetails) {
        const dataEdit = {
      startDate: dateTransform(values?.startDate),
      endDate: dateTransform(values?.endDate),
      eventName: values?.eventName,
      countryCode: '+1',
      timeZone: values?.timeZone,
      promotersEmail: values?.promotersEmail.toLowerCase(),
      promotersMobile: values?.promotersMobile,
      promotersName: values?.promotersName,
    }
    if (streamingEnabled) {
     dataEdit.isStreaming = true
    }
    if (values?.eventPayout) {
      dataEdit.eventPayout = values?.eventPayout
    }
    const fees = {}
    const saleTax = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.stripeVenmoFee) {
      fees.paymentProcessorFee = values?.stripeVenmoFee
    }
    if (values?.saleTaxCoverage) {
      saleTax.coverage = values?.saleTaxCoverage
    }
    if (values?.saleTaxPercent) {
      saleTax.percent = values?.saleTaxPercent
    }
      saleTax.nonProfit = nonProfitOrganization
    if (values?.taxNumber) {
      saleTax.EIN = `${values?.taxNumber}`
    }
    dataEdit.saleTax = JSON.stringify(saleTax)
    dataEdit.fees = JSON.stringify(fees)
    dispatch(updateVenueEvent({ ...dataEdit, eventID })).then(() => {
      setFinished(false)
      handleBack()
    }).catch(() => setFinished(false))
    } else {
    dispatch(createEventVenue({ ...data })).then(() => {
      setFinished(false)
      handleBack()
    }).catch(() => setFinished(false))
  }
  }

  const onCloseModal = () => {
    setFinished(false)
    setModalBuyStream(null)
  }

  const onSubmitBought = (cardToken, promo) => {
    return new Promise(resolve => {
      const data = { ...modalBuyStream, cardToken }
      if (promo) {
        data.promo = promo
      }
      if (currentEventDetails?.isVenueDraft && currentEventDetails?.tickets[1]?.isStreaming) {
        dispatch(updateEvent(data, eventID))
        .then(() => {
            if (streamingEnabled) {
              dispatch(setEventsIsStream(true))
            }
            if (eventType === 'stream') {
              dispatch(setEventsIsStream(true))
            }
            onCloseModal()
            handleBack()
          resolve()
        }).catch(resolve)
      } else {
      dispatch(createEvent(data))
        .then(response => {
          if (response) {
            if (streamingEnabled) {
              dispatch(setEventsIsStream(true))
            }
            if (eventType === 'stream') {
              dispatch(setEventsIsStream(true))
            }
            onCloseModal()
            handleBack()
          }
          resolve()
        }).catch(resolve)
      }
    })
  }

  const prepareHeaderPanel = (title, number, isDone = false) => (
    <div className='headerContainer'>
      <div className={c('circle', { isDone })}>
        {isDone ? <img src={doneIcon} alt=' ' /> : number}
      </div>
      <div className='panelTitle'>{title}</div>
    </div>
  )

  const prepareIcon = ({ isActive }) => <img src={expandIcon} alt=' ' className={c('expandIcon', { isActive })} />
  const prepareBackIcon = <img src={backIcon} alt=' ' className='iconBtn' />

  const PreviousBtn = ({ onClick }) => (
    <Button icon={prepareBackIcon} onClick={onClick} className='stepBtn' type='link'>Previous Step</Button>
  )

  const unpackTicketCategory = category => {
    const { bgColor, ticketType, isInviteOnly, price, quantity, isEarlyBird, earlyBirdDate, earlyBirdPrice, isStreaming, venmo } = category
    const result = { bgColor, ticketType, privacyOption: isInviteOnly ? 'invite' : 'visible', price, quantity, earlyBirdPrice, isStreaming, venmo }
    if (isEarlyBird) {
      result.isEarlyBird = isEarlyBird
      result.earlyBirdDate = moment(earlyBirdDate, 'YYYY/MM/DD')
    }
    trimObject(result)
    return result
  }
  const initialTicketValues = {
    ticketType: categoryInitialValues[category],
    privacyOption: 'visible',
  }
  const initialValues = {
    eventName: currentEventDetails?.eventName,
    eventUrl: currentEventDetails?.eventUrl,
    zoomUrl: currentEventDetails?.virtualEvent?.url,
    zoomPassword: currentEventDetails?.virtualEvent?.password,
    zoomEventParticipants: currentEventDetails?.virtualEvent?.maxParticipants,
    zoomEventDescription: currentEventDetails?.virtualEvent?.description,
    defaultCategory: currentEventDetails?.tickets[0] ? unpackTicketCategory(currentEventDetails?.tickets[0]) : initialTicketValues,
    streamingCategory: { ticketType: categoryInitialValues.streamingCategory, privacyOption: 'visible' },
    location: currentEventDetails?.address,
    venue: currentEventDetails?.venue,
    presentedBy: currentEventDetails?.presentedBy,
    timeZone: currentEventDetails?.timeZone,
    showAvailableTickets: currentEventDetails?.isVenueDraft ? !currentEventDetails?.showAvailableTickets : currentEventDetails?.showAvailableTickets,
    venmo: currentEventDetails?.tickets[0]?.venmo,
    categories: currentEventDetails?.tickets?.slice(1)?.filter(i => !i.isStreaming).map(i => (
      {
        ...i,
        earlyBirdDate: i.isEarlyBird ? moment(i?.earlyBirdDate, 'YYYY/MM/DD') : '',
        privacyOption: i.isInviteOnly ? 'invite' : 'visible',
      })),
    subpromoters: [{}],
    sections: eventID && !currentEventDetails?.isVenueDraft ? prepareSections(currentEventDetails?.sections) : [{ sectionName: undefined }],
    templateCategories: [{}],
    templateSections: [{}],
    templateName: '',
    templateList: '',
    templateCover: templateCover,
    saveTemplate: false,
  }
  const streamingCategory = currentEventDetails?.tickets.find(i => i.isStreaming)
  const streamingCategoryData = streamingCategory && unpackTicketCategory(streamingCategory)
  if (streamingCategory) {
    initialValues.streamingCategory = unpackTicketCategory(streamingCategoryData)
  }
  trimObject(initialValues)
  const onChange = keys => setActivePanel(keys)
  const onSetEventDetails = () => {
    if (!!eventType) {
      !role?.includes('venue') || venuePromoterOff ? setActivePanel(['multimedia']) : setActivePanel(['eventDetails'])
    } else {
      notification.warning({ message: 'Choose an event type' })
    }
  }

  const onSetMultimedia = async () => {
    if (!Object.keys(imageUrls).length) {
      notification.warning({ message: 'Upload image for ticket' })
    } else {
      setActivePanel(['eventDetails'])
    }
  }
  const streamProps = { streamingEnabled, setStreamingEnabled }

  const validateEventDetails = () => {
    const { endDate, startDate, eventName, timeZone, promotersEmail, promotersName, promotersMobile } = form.getFieldsValue()
    if (endDate && startDate && eventName && timeZone && promotersEmail && promotersName && promotersMobile) {
      onChange(['fees'])
       setEventDetailsStepDone(true)
       return true
    } else {
      return false
     }
  }
    const validateAssign = () => {
    const { templateCategories, templateSections, templateName, templateList, saveTemplate } = form.getFieldsValue()
    if (templateList || templateCover && templateCategories && templateSections && !saveTemplate || templateCover && templateCategories && templateSections && saveTemplate && templateName) {
      onChange(['fees'])
       setAssignSeatingStepDone(true)
       return true
    } else {
      return false
     }
  }

  const validateEvent = e => {
    if (e?.errorFields?.length === 1 && !e?.values?.saleTaxPercent) {
      onChange(['fees'])
      return
    }
    if (e?.errorFields?.length) {
    onChange(['eventDetails'])
    } else {
      setStateComplite(true)
    }
   }
    return (
      <CreateEventContext.Provider
        value={{ eventID, form, currentEventDetails, eventType, setEventType, isStripeAccount, currency, setCurrency, ...streamProps }}
      >
        <div className='rootCreateEvent'>
          <div className='createEventTitle'><h1>{eventID ? 'Edit' : 'Make'} My Tickets</h1></div>
          <Form
            form={form}
            onFinish={!role?.includes('venue') || venuePromoterOff ? handleFinish : handleFinishVanue}
            scrollToFirstError
            colon={false}
            layout='vertical'
            className='makeTicketGrid'
            initialValues={initialValues}
            onFinishFailed={validateEvent}
          >
            <Collapse
              expandIcon={prepareIcon}
              expandIconPosition='right'
              className='panelContainer'
              activeKey={activePanel}
              onChange={onChange}
            >
              <Panel header={prepareHeaderPanel('Event Type', 1, !!eventType)} key='eventType' forceRender>
                <div className='bodyContainer'>
                  <div className='containerTitle'>Please choose an event type</div>
                  <EventsCheckbox
                    setIsStream={setStreamingEnabled}
                    isStream={streamingEnabled}
                    setEventType={setEventType}
                    disabled={!!eventID}
                    eventType={eventType}
                    form={form}
                    venuePromoterOff={venuePromoterOff}
                    role={role}
                    currentEventDetails={currentEventDetails}
                    isVenueEvent={currentEventDetails?.isVenueEvent}
                  />
                </div>
                <div className='bottomContainer'>
                  <Button type='link' onClick={goBack}>Cancel</Button>
                  <CustomButton text='Next Step' type='dark' onClick={onSetEventDetails} />
                </div>
              </Panel>
              {!role?.includes('venue') || venuePromoterOff
              ? <Panel header={prepareHeaderPanel('Multimedia', 2, !!Object.keys(imageUrls).length)} key='multimedia' forceRender>
                <div className='bodyContainer'>
                  <div className='containerTitle'>Add a ticket cover and some additional multimedia</div>
                  <MultiMediaContainer form={form} />
                </div>
                <div className='bottomContainer'>
                  <PreviousBtn onClick={() => onChange(['eventType'])} />
                  <CustomButton text='Next Step' type='dark' onClick={onSetMultimedia} />
                </div>
              </Panel>
              : null}
              <Panel
                header={prepareHeaderPanel('Event Details', isVenue(role) && !venuePromoterOff ? 2 : 3, eventDetailsStepDone)}
                key='eventDetails' forceRender
              >
                <div className='bodyContainer centerContainer'>
                  {!role?.includes('venue') || venuePromoterOff
                  ? <div>
                    <DetailsEvent role={!role?.includes('venue')} venuePromoterOff={venuePromoterOff} eventId={eventID} />
                    <TicketCategory namePrefix={category} currEvent={currentEventDetails} ticketData={currentEventDetails?.tickets[0]} isDefault stream={eventType === 'stream'} />
                    <OptionsEvent />
                  </div>
                  : <DetailsEventVenue />}

                </div>
                <div className='bottomContainer'>
                  <PreviousBtn onClick={() => onChange(!role?.includes('venue') || venuePromoterOff ? ['multimedia'] : ['eventType'])} />
                  {role?.includes('venue') && !venuePromoterOff
                    ? (
                      <CustomButton
                        onClick={() => {
                          setIsStepTwo(true)
                          validateEventDetails()
                        }}
                        htmlType='button'
                        text='Next Step'
                        type='dark'
                      />
                    ) : (
                      <CustomButton
                        onClick={() => onChange(['assignedSeating'])}
                        htmlType='button'
                        text='Next Step'
                        className='btnMakeTicket'
                        type='dark'
                      />
                    )}
                </div>
              </Panel>
              {isVenue(role) && !venuePromoterOff ? (
                null
              ) : (
                <Panel
                  header={prepareHeaderPanel('Assigned Seating', 4, AssignSeatingStepDone)}
                  key='assignedSeating' forceRender
                >
                  <div className='bodyContainer centerContainer'>
                    <div className='creationModeTitle'>Creation mode</div>
                    <SeatingCreationMode templateCover={templateCover} setTemplateCover={setTemplateCover} form={form} />
                  </div>
                  <div className='bottomContainer'>
                    <PreviousBtn onClick={() => onChange(!role?.includes('venue') || venuePromoterOff ? ['eventDetails'] : ['eventType'])} />
                    <div>
                      <CustomButton
                        onClick={() => onChange(['fees'])}
                        htmlType='button'
                        text='Skip'
                        className='skipBtn'
                        type='outline'
                      />
                      <CustomButton
                        onClick={validateAssign}
                        htmlType='button'
                        text='Next Step'
                        className='btnMakeTicket'
                        type='dark'
                      />
                    </div>
                  </div>
                </Panel>
              )}
              <Panel
                header={prepareHeaderPanel('Fees and payout', isVenue(role) && !venuePromoterOff ? 3 : 5,
                eventID || stateComplite)}
                key='fees' forceRender
              >
                <div className='bodyContainer centerContainer'>
                  <Fees
                    venuePromoterOff={venuePromoterOff}
                    setNonProfitOrganization={setNonProfitOrganization}
                    currentEventDetails={currentEventDetails}
                    nonProfitOrganization={nonProfitOrganization}
                  />
                </div>
                <div className='bottomContainer'>
                  <PreviousBtn onClick={() => onChange(!role?.includes('venue') || venuePromoterOff ||
                  role?.includes('venue') &&
                  currentEventDetails?.isVenueDraft && !venuePromoterOff
                  ? ['eventDetails']
                  : ['eventType'])}
                  />
                  <CustomButton
                    htmlType='submit'
                    onClick={() => setIsStepTwo(false)}
                    text={currentEventDetails?.isVenueDraft && role?.includes('venue') && !venuePromoterOff ? 'Update Event' : eventID ? currentEventDetails?.isVenueDraft ? 'Make Tickets' : 'Update Tickets' : isVenue(role) ? 'Create Event' : 'Make Tickets'}
                    className='btnMakeTicket'
                    type='dark'
                  />
                </div>
              </Panel>
            </Collapse>
          </Form>
        </div>
        <ModalBuyStreamTicket
          ticketLifeFee={ticketLifeFee}
          dataTicket={modalBuyStream}
          setVisible={onCloseModal}
          visible={!!modalBuyStream}
          callback={onSubmitBought}
          currency={currency}
          quantity={quantity}
        />
        <ModalInfo
          visible={modalInfo?.visible}
          submit={handleFinishVanue}
          title={modalInfo?.message}
          setModalInfo={setModalInfo}
          data={modalInfo?.data}
        />
        {showModalPromotion && <ModalChoosePromotion withoutPromoter={setVenuePromoterOff} setVisible={setShowModalPromotion} visible={showModalPromotion} />}
      </CreateEventContext.Provider>
  )
}

export default CreateEvent
