/* eslint-disable indent */
import React from 'react'
import './index.styl'
import { useDispatch } from 'react-redux'
import { clearGetEventDetails } from 'modules/events/events-actions'
import { Link } from 'react-router-dom'
import defaultLogo from 'images/logoMemberLyfe.svg'
import LogoSwitch from 'images/memberlyfeLogo.png'

const LogoMemberLyfe = ({ user, isSwitch }) => {
  const dispatch = useDispatch()
  return (
    <Link
      to={user ? '/memberlyfe-home' : '/help/overview/my-box-office'}
      onClick={() => {
        dispatch(clearGetEventDetails({}))
        window.scroll(0, 0)
      }}
    >
      <img src={isSwitch ? LogoSwitch : defaultLogo} alt='logo' className={isSwitch ? '' : 'logoMember'} />
    </Link>
  )
}

export default LogoMemberLyfe
