import React, { useEffect } from 'react'
import { InputNumber, Button } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'

import './index.styl'

const Counter = ({
  count = 0,
  setCount,
  onKeyPress,
  className = '',
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  setAllowBuyTicket = () => { },
  placeholder = 0,
  inputRef = null,
  disabled,
  hideButton = false,
}) => {
  const onChange = e => {
    if (!setCount) {
      return
    }
    if (e > max) {
      return
    }

    if (isNaN) {
      setCount(0)
    }
    if (!isNaN(e) && e > 0) {
      setCount(e)
    }
  }

  useEffect(() => {
    if (count > max) {
      setAllowBuyTicket(true)
    } else {
      if (typeof setAllowBuyTicket !== 'undefined') {
        setAllowBuyTicket(false)
      }
    }
  }, [count])

  return (
    <div className={`wrapperCounter ${className}`}>
      <Button className={`minus ${hideButton ? 'hidden' : ''}`} onClick={count > min ? () => setCount(--count) : null} disabled={disabled || hideButton}>
        <MinusOutlined />
      </Button>
      <InputNumber
        min={min}
        max={max}
        className='input'
        placeholder={placeholder}
        value={count || ''}
        disabled={disabled}
        onChange={onChange}
        ref={inputRef}
        onKeyPress={onKeyPress}
      />
      <Button className={`plus ${hideButton ? 'hidden' : ''}`} onClick={count < max ? () => setCount(++count) : null} disabled={disabled || hideButton}>
        <PlusOutlined />
      </Button>
    </div>
  )
}

export default Counter
