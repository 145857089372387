import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  getAssignTicketSection,
  clearGetAssignTicketTables,
  setAssignTicketSection,
  clearGetAssignTicketSection,
  editTableSectionName,
  // deleteAssignTicketSection,
  // unassignTicketsFromSection,
} from 'modules/events/events-actions'
// import ThreeDotIcon from 'images/threedots.svg'
import { CustomButton } from 'components'
import { ModalNewSection, ModalRenameField } from './ASComponents'
import RowsTable from './RowsTable/index'
import TablesTable from './TablesTable/index'
// import MobileTable from 'pages/MyBoxOffice/MobileTable'
// import seatsIcon from 'images/seatsIcon.svg'
// import tablesIcon from 'images/tablesIcon.svg'
import { eventsV2, reports } from 'apiv2'

import c from 'classnames'
import ColorTemplate from '../../../Template/ColorTemplate'
import TemplateScheme from '../../../Template/TemplateScheme'
import './index.styl'

// const limit = 3

const AssignedSeatings = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { eventID } = useParams()
  // const { push } = useHistory()
  const { currentEventDetails } = useSelector(state => state.events)
  const [visibleModal, setVisibleModal] = useState(false)
  const [template, getTemplate] = useState(null)
  // const [tableData, setTableData] = useState([])
  const [renameSection, setRenameSection] = useState(null)
  // const [current, setCurrent] = useState(1)
  // const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const activeTable = pathname.split('/')[5]
  const containerRef = useRef(null)
  const handleModal = () => {
    setVisibleModal(!visibleModal)
  }
  const handleSubmitFormNewSection = ({ formValues }) => {
    setLoading(true)
    dispatch(setAssignTicketSection({ eventID, ...formValues })).then(() => {
      setVisibleModal(false)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  const downloadSeatingsReport = async () => {
    const result = await reports.reportByEvent(eventID)
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }

  // const getRowLink = (sectionType = '', sectionID) => {
  //   return `/my-box-office/event-details/assigned-seating/${eventID}/${sectionType.toLowerCase()}/${sectionID}`
  // }

  const tableObj = {
    rows: () => <RowsTable />,
    tables: () => <TablesTable />,
  }

  const updateTableData = sectionName => {
    setLoading(true)
    const data = { eventID, sectionName, sectionID: renameSection?._id }
    dispatch(editTableSectionName(data)).then(() => {
      setRenameSection(null)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  // const popoverContent = section => {
  //   const handleDeleteSection = () => {
  //     handleConfirmModal(
  //       'Are you sure you want to remove this section',
  //       () => dispatch(deleteAssignTicketSection({ eventID, sectionID: section?._id })).catch(() => {}),
  //     )
  //   }
  //   const unassignTickets = () => {
  //     handleConfirmModal(
  //       'Are you sure you want to unassign all users？',
  //       () => dispatch(unassignTicketsFromSection({ eventID, sectionID: section?._id })),
  //     )
  //   }
  //   const handleRename = () => setRenameSection(section)
  //   const handlePropagation = event => {
  //     event.stopPropagation()
  //     event.preventDefault()
  //   }
  //   return (
  //     <div className='actionPopover' onClick={handlePropagation}>
  //       <div onClick={handleRename} className='popoverBtn'>Rename</div>
  //       <div className='popoverBtn'>
  //         <Link to={getRowLink(section?.sectionType, section?._id)}>
  //           Assign guests
  //         </Link>
  //       </div>
  //       <div onClick={unassignTickets} className='popoverBtn'>Unassign all</div>
  //       <div onClick={handleDeleteSection} className='popoverBtn popoverBtnRemove'>Remove section</div>
  //     </div>
  //   )
  // }

  useEffect(() => {
    dispatch(getAssignTicketSection(eventID))
    if (currentEventDetails._id === eventID) {
      return () => {
        dispatch(clearGetAssignTicketTables())
        dispatch(clearGetAssignTicketSection())
      }
    }
  }, [])

  useEffect(() => {
    if (currentEventDetails?.seatTemplateID) {
      eventsV2.getTemplate(currentEventDetails?.seatTemplateID).then(response => {
        getTemplate(response.data.template)
      })
    }
  }, [currentEventDetails?.seatTemplateID, loading])

  // useEffect(() => {
  //   setTableData(assignTicketSection)
  //   setTotal(assignTicketSection.length)
  // }, [assignTicketSection])

  // const isDesktop = window.innerWidth > 1200

  // const onRowClick = (event, record) => {
  //   if (!event?.target?.classList?.contains('threeDots') &&
  //     !event?.target?.classList?.contains('actionPopoverButton') &&
  //     !event?.target?.parentElement?.classList?.contains('actionPopoverButton')
  //   ) {
  //     push(getRowLink(record?.sectionType, record?._id))
  //   }
  // }

  // const onPaginate = current => {
  //   containerRef.current.scrollIntoView()
  //   setCurrent(current)
  // }
  // const getPagination = (list, current, limit) => {
  //   const end = current * limit
  //   return list.slice(end - limit, end)
  // }
  // const columns = [
  //   {
  //     title: 'Section #',
  //     key: '_id',
  //     render: (_, __, idx) => idx + 1,
  //   },
  //   {
  //     title: 'Section type',
  //     dataIndex: 'sectionType',
  //     key: 'sectionType',
  //     render: type => (
  //       <span className='sectionType'>
  //         <img src={type === 'ROWS' ? seatsIcon : tablesIcon} alt=' ' />
  //         {(type || '').toLowerCase()}
  //       </span>
  //     ),
  //   },
  //   {
  //     title: 'Section name',
  //     dataIndex: 'sectionName',
  //     key: 'sectionName',
  //   },
  //   {
  //     title: 'Number of tables',
  //     dataIndex: 'tableCount',
  //     key: 'tableCount',
  //   },
  //   {
  //     title: 'Number of seats',
  //     dataIndex: 'totalSeats',
  //     key: 'totalSeats',
  //   },
  //   {
  //     title: 'Assigned',
  //     dataIndex: 'assignedSeats',
  //     key: 'assignedSeats',
  //   },
  //   {
  //     title: '',
  //     key: 'actions',
  //     dataIndex: 'actions',
  //     render: (_, section) => (
  //       <Popover
  //         overlayClassName='actionPopoverContainer'
  //         content={popoverContent(section)}
  //         getPopupContainer={node => node?.parentNode}
  //         placement='topLeft'
  //         trigger='click'
  //       >
  //         {isDesktop
  //           ? <img src={ThreeDotIcon} className='threeDots' alt=' ' />
  //           : <CustomButton className='actionPopoverButton' text='More Options' />}
  //       </Popover>
  //     ),
  //   },
  // ]

  return (
    <div className={c('AssignedSeatings', { table: activeTable })} ref={containerRef}>
      {activeTable ? (
        tableObj[activeTable]()
      ) : (
        <>
          <div className='wrapperTablesAssignSeating'>
            <div className='titleFlexContainer'>
              <div className='titleAssignedSeating'>Assigned and/or Table Seating</div>
              <div className='buttonContainer'>
                <CustomButton text='Download report' onClick={downloadSeatingsReport} />
              </div>
            </div>

            <div className='boxOfficeTemplateWrapper'>
              <ColorTemplate className='colorTemplateOffice' templateID={currentEventDetails?.seatTemplateID} loading={loading} setLoading={setLoading} {...template} />
              <TemplateScheme className='templateSchemeOffice' templateID={currentEventDetails?.seatTemplateID} loading={loading} setLoading={setLoading} {...template} />
            </div>
          </div>
          <ModalNewSection
            submitForm={handleSubmitFormNewSection}
            updateVisible={handleModal}
            visible={visibleModal}
            loading={loading}
          />
          {renameSection && (
            <ModalRenameField
              onClose={() => setRenameSection(null)}
              updateData={updateTableData}
              field={renameSection?.sectionName}
              label='Section name'
              loading={loading}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AssignedSeatings
