import React from 'react'
import './index.styl'
import Mail from 'images/Mail.svg'

const ConfirmationPassword = () => (
  <div className='wrapperConfirmationScreen'>
    <div className='resetPassword'>Reset password</div>
    <div>
      <img src={Mail} alt='mail logo' />
    </div>
    <div className='resetPasswordRule'>
      Please check your email and follow instructions
    </div>
  </div>
)

export default ConfirmationPassword
