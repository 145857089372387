import React from 'react'
import { Modal } from 'antd'

const ModalInfo = ({ visible, submit, title, setModalInfo, data }) => {
  return (
    <Modal
      onCancel={() => setModalInfo({ visible: false, message: '', data: { ...data } })}
      onOk={() => {
        setModalInfo({ visible: false, message: '', data: { ...data } })
        submit(data, true)
      }}
      className='infoModal'
      visible={visible}
      title={title}
    />
  )
}

export default ModalInfo
