import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Switch,
  InputNumber,
  Divider,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import moment from 'moment'
import { clearGetEventDetails } from 'modules/events/events-actions'
import Location from 'images/LocationWhite.svg'
import PlacesAutocomplete from 'react-places-autocomplete'
import { CreateEventContext } from '../'
import TicketCategory from '../TicketCategory'
import TooltipInfo from '../TooltipInfo'
import AssignSeating from '../OptionsEvent/AssignSeating'

import { timeZones } from 'utils'

import c from 'classnames'
import calendarIcon from 'images/calendarIcon.svg'
import './index.styl'

const { Item, List } = Form
const { Option } = Select

const tooltipTitle = 'Streamed events require a deposit. You will be prompted to pay upon making the Tickets.'

const DetailsEvent = ({ eventId, venuePromoterOff, role }) => {
  const { form, currentEventDetails, eventType, streamingEnabled, setStreamingEnabled } = useContext(CreateEventContext)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const [addressLocation, setAddressLocation] = useState(currentEventDetails?.address || '')
  const [seatingEnabled, setSeatingEnabled] = useState(currentEventDetails?.isAssignTicket || false)

  const { user: { venueName, address, isVenueUser } } = useSelector(state => state.session)
  const { eventID } = useParams()

  useEffect(() => {
    if (!eventID) {
      dispatch(clearGetEventDetails({}))
    }
  }, [pathname])

  useEffect(() => {
    if (streamingEnabled !== form.getFieldValue('streamingEnabled')) {
      form.setFieldsValue({ streamingEnabled })
    }
  }, [streamingEnabled])

  const handleSelect = async value => {
    await setAddressLocation(value)
    await form.setFieldsValue({
      location: value,
    })
  }
  const seatingHandler = () => {
    if (role && !currentEventDetails?.isVenueDraft) {
      setSeatingEnabled(!seatingEnabled)
    } else {
      setSeatingEnabled(!seatingEnabled)
      form.setFieldsValue({
        isAssignTicket: !seatingEnabled,
      })
    }
  }
  const zoomURLLabel = (
    <TooltipInfo
      text='Zoom URL'
      info='The Zoom URL is the link that is provided by Zoom for either your Personal Meeting ID or a generated Meeting.  It can be obtained by copying the value from the Zoom Meeting Invitation. i.e. https://us02web.zoom.us/j/#########?pwd=AAAAAAAAAA for meetings that have the Passcode embedded in the URL or may be of the format https://us02web.zoom.us/j/######### for meetings that use a Waiting Room'
    />
  )
  const zoomMeetingPasscodeLabel = (
    <TooltipInfo
      text='Zoom Meeting passcode'
      info='Meetings and webinars can require passcodes for an added layer of security. passcodes can be set at the individual meeting level. If the Passcode is not included on the Zoom URL, participants will be required to enter this value to join the meeting'
    />
  )

  const additionalTooltip = (
    <TooltipInfo
      className='tooltipTitle'
      text='Additional fees apply'
      info={tooltipTitle}
    />
  )
  const zoomPersonalAccount = (
    <TooltipInfo
      className='personalZoomAccountTitle'
      info='How to setup Virtual Events with Zoom'
      text='Use personal Zoom account'
    />
  )
  const locationIcon = <img src={Location} alt='Location Logo' />
  const className = 'input location-search-input'
  const renderInputLocation = (isRequired = false) => (
    <PlacesAutocomplete value={addressLocation} onChange={setAddressLocation} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='locationContainer'>
          <Item
            rules={[{ required: isRequired, message: 'Enter Location' }]}
            className='item'
            name='location'
            label='Location'
            normalize={v => v.trimStart()}
          >
            <Input {...getInputProps({ className, placeholder: 'Start typing', suffix: locationIcon, disabled: !!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent })} />
          </Item>
          <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
  const dateIcon = <img src={calendarIcon} alt=' ' />

  const m = moment().utcOffset(0)
  m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  m.toISOString()
  m.format()
  const formEventObject = {
    live: !venuePromoterOff || !isVenueUser ? (
      <>
        <Item
          rules={[{ required: eventType === 'live', message: 'Enter Venue name' }]}
          label='Venue Name'
          className='item'
          name='venue'
          normalize={v => v.trimStart()}
        >
          <Input disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent} placeholder='Enter Venue Name' className='input' />
        </Item>
        {renderInputLocation(eventType === 'live')}
      </>
    ) : (
      <>
        <div className='defaultValueContainer'>
          <div className='label'>Venue Name</div>
          <div className='defaultValue'>{venueName}</div>
        </div>
        <div className='defaultValueContainer'>
          <div className='label'>Location</div>
          <div className='defaultValue'>{address}</div>
        </div>
      </>
    ),
    virtual: (
      <>
        {zoomPersonalAccount}
        <div className='radioText'>
          <Item
            rules={[{ required: eventType === 'virtual', message: 'Enter Zoom URL' }]}
            label={zoomURLLabel}
            className='item'
            name='zoomUrl'
            normalize={v => v.trimStart()}
          >
            <Input placeholder='Enter your personal link' className='input' />
          </Item>
          <Item label={zoomMeetingPasscodeLabel} name='zoomPassword' className='item' normalize={v => v.trimStart()}>
            <Input placeholder='Enter your passcode' className='input' />
          </Item>
          <Item
            rules={[{ required: eventType === 'virtual', message: 'Enter maximum event participants' }]}
            label='Maximum event participants'
            name='zoomEventParticipants'
            className='item'
          >
            <InputNumber className='fullWidth' type='number' min={1} />
          </Item>
        </div>
      </>
    ),
    stream: !venuePromoterOff || !isVenueUser ? (
      <>
        <div className='tooltipWrapper'>{additionalTooltip}</div>
        <Item
          rules={[{ required: true, message: 'Enter Venue name' }]}
          label='Venue Name'
          className='item'
          name='venue'
          normalize={v => v.trimStart()}
        >
          <Input disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent} placeholder='Enter Venue Name' className='input' />
        </Item>
        {renderInputLocation()}
      </>
    ) : (
      <>
        <div className='tooltipWrapper'>{additionalTooltip}</div>
        <div className='defaultValueContainer'>
          <div className='label'>Venue Name</div>
          <div className='defaultValue'>{venueName}</div>
        </div>
        <div className='defaultValueContainer'>
          <div className='label'>Location</div>
          <div className='defaultValue'>{address}</div>
        </div>
      </>
    ),
  }

  const handleEndTimeDisabled = currentDate => {
    const _start = form.getFieldValue('timeStart')
    if (_start && currentDate) {
      currentDate = moment(currentDate).endOf('day')
      return moment(currentDate).isBefore(_start, 'hours')
    }
    if (currentDate) {
      return currentDate < moment().startOf('day')
    }
  }

  const validateStartTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('Start time should be later than now.'))
      }
      const _end = form.getFieldValue('timeEnd')
      if (value && _end && moment(_end).isSameOrBefore(value, 'minutes')) {
        return Promise.reject(new Error('Start time should be earlier than end time.'))
      }
      return Promise.resolve()
    },
  }
  const validateEndTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('End time should be later than now.'))
      }
      const _start = form.getFieldValue('timeStart')
      if (value && _start && moment(_start).isSameOrAfter(value, 'minutes')) {
        return Promise.reject(new Error('End time should be after start time.'))
      }
      return Promise.resolve()
    },
  }
  return (
    <div className='wrapperDetailsEvent'>
      {!eventID && <div className='title'>Give some details for your ticket</div>}
      <>
        <Item
          rules={[{ required: true, message: 'Enter Event name' }]}
          label='Event name'
          className='item'
          name='eventName'
          normalize={v => v.trimStart()}
        >
          <Input placeholder='Enter event name' className='input' />
        </Item>
        {formEventObject[eventType]}
        <Item
          className='item'
          name='presentedBy'
          label='Presented by (Optional)'
        >
          <Input placeholder='Add information' className='input' />
        </Item>
        <Item
          className='item timezoneSelect'
          name='timeZone'
          label='Time Zone'
          rules={[{ required: true, message: 'Enter Time Zone' }]}
        >
          <Select disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent} placeholder='Select a timezone' listHeight={300}>
            {timeZones.map(({ value, label }) => (
              <Option key={value} value={value}>{label}</Option>
            ))}
          </Select>
        </Item>
        <div className='groupItems'>
          <Item
            initialValue={currentEventDetails && moment(currentEventDetails?.startDate, 'YYYY-MM-DD h:mm A')}
            rules={[{ required: true, message: 'Enter Start Date' }, validateStartTime]}
            label='Start Date and Time'
            name='timeStart'
          >
            <DatePicker
              disabledDate={currentDate => currentDate && currentDate < moment().startOf('day')}
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
              getPopupContainer={node => node?.parentNode}
              format='YYYY-MM-DD h:mm A'
              disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent}
              suffixIcon={dateIcon}
            />
          </Item>
          <Item
            initialValue={currentEventDetails && moment(currentEventDetails?.endDate, 'YYYY-MM-DD h:mm A')}
            rules={[{ required: true, message: 'Enter End Date' }, validateEndTime]}
            label='End Date and Time'
            name='timeEnd'
          >
            <DatePicker
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
              getPopupContainer={node => node?.parentNode}
              disabledDate={handleEndTimeDisabled}
              format='YYYY-MM-DD h:mm A'
              suffixIcon={dateIcon}
              disabled={!!currentEventDetails?.isVenueDraft || currentEventDetails?.isVenueEvent}
            />
          </Item>
        </div>
      </>
      {/* )} */}
      {eventType === 'live' ? (
        <>
          <Item
            initialValue={currentEventDetails?.rainDate ? moment(currentEventDetails?.rainDate) : ''}
            label='Rain Date'
            className='item rainContainer'
            name='rainDate'
          >
            <DatePicker
              disabledDate={currentDate => currentDate && currentDate < moment().add(-1, 'days')}
              className={c('input', 'inputDateMargin', 'inputDate', 'date')}
              suffixIcon={dateIcon}
            />
          </Item>
          <Divider />
          <Item className='item switchContainer'>
            <Item name='streamingEnabled' valuePropName='checked'>
              <Switch onChange={() => setStreamingEnabled(!streamingEnabled)} disabled={!!eventId} checked={streamingEnabled} />
            </Item>
            <div className='label'>
              <div className='title'>Add Live Stream</div>
              {additionalTooltip}
            </div>
          </Item>
          {eventType === 'live' && (
            <>
              <div className='radioItem'>
                <Item
                  name='isAssignTicket'
                  initialValue={seatingEnabled}
                  valuePropName='checked'
                  className='item noGutter'
                >
                  <Switch onChange={seatingHandler} defaultChecked={seatingEnabled} disabled={!!currentEventDetails?._id && !currentEventDetails?.isVenueEvent} />
                </Item>
                <div className='text'>Enable Special Seating</div>
              </div>
              {seatingEnabled && currentEventDetails?.isVenueDraft
                ? (
                  <List name='sections'>
                    {(fields, { add, remove }) => (
                      <div className='seatingWrapper'>
                        {fields.map(field => (<AssignSeating key={field.key} field={field} remove={remove} section={currentEventDetails?.sections[field.name]} />))}
                        <div className='btn addSection' onClick={() => add()}>Add New Section</div>
                      </div>)}
                  </List>
                ) : (
                  null)}
              {seatingEnabled && !currentEventDetails?._id && (
                <List name='sections'>
                  {(fields, { add, remove }) => (
                    <div className='seatingWrapper'>
                      {fields.map(field => (<AssignSeating key={field.key} field={field} remove={remove} section={currentEventDetails?.sections[field.name]} />))}
                      <div className='btn addSection' onClick={() => add()}>Add New Section</div>
                    </div>)}
                </List>
              )}
            </>
          )}
          {streamingEnabled && (
            <>
              <Divider />
              <TicketCategory ticketData={currentEventDetails?.tickets[1]} namePrefix='streamingCategory' />
            </>
          )}
          <Divider />
        </>
      ) : (
        <Divider />
      )}
    </div>
  )
}

export default DetailsEvent
