import React from 'react'
import { Select } from 'antd'
import DropDownIcon from 'images/DropDownIcon.svg'
import RedArrow from 'images/RedArrow.svg'
import c from 'classnames'
import './index.styl'

const { Option } = Select

const CustomSelect = ({ items = [], sortTag, setSortTag, customSuffix = false, disabled }) => {
  const handleChangeSort = e => {
    setSortTag(e)
  }
  const DropDownArrow = <img src={DropDownIcon} alt='custom_icon' />
  return (
    <Select
      getPopupContainer={node => node?.parentNode}
      className='customSelect'
      dropdownClassName='customSelectClassName'
      defaultValue={items[0].key}
      onChange={handleChangeSort}
      suffixIcon={customSuffix && DropDownArrow}
      disabled={disabled}
      value={sortTag}
    >
      {items.map(i => <Option key={i.key} value={i.key}>{i.name} <img src={RedArrow} className={c('sort-arrow', { 'sort-arrow-up': i.key?.includes('ASC') })} /></Option>)}
    </Select>
  )
}

export default CustomSelect
