/* eslint-disable indent */
import React from 'react'
import './index.styl'
import { useDispatch } from 'react-redux'
import { clearGetEventDetails } from 'modules/events/events-actions'
import { Link } from 'react-router-dom'
import defaultLogo from 'images/brand-header.svg'

const Logo = () => {
  const dispatch = useDispatch()
  return (
    <Link
      to='/home' onClick={e => {
        dispatch(clearGetEventDetails({}))
        window.scroll(0, 0)
      }}
      style={{ zIndex: 999 }}
    >
      <img src={defaultLogo} alt='logo' className='logoNewHomePage' />
    </Link>
  )
}

export default Logo
