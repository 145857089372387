import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Layout } from 'components'
import Header from './Header'
import ChatFrame from './ChatFrame'
import { getStreamUrl, getGuestStreamUrl } from 'modules/events/events-actions'
import streamIcon from 'images/streamIcon.svg'
import './index.styl'

const queryString = require('query-string')

const _eventData = {
  eventName: '',
  startDate: '',
  endDate: '',
  url: '',
}

const Stream = () => {
  const [videoSrc, setVideoSrc] = useState('')
  const [eventData, setEventData] = useState(_eventData)
  const { token } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  // const src = 'https://evt.live/joe7408/new-event-3_9750/embedBare'
  /** ** realSrc comes from iframe's inner video (ToDo: Find a way to get this url) *** **/
  /// const realSrc = 'https://eventlivehls.sfo2.cdn.digitaloceanspaces.com/streams/JKXLTXPV_final.mp4?from=2021-03-04T09:37:36Z'
  const { e: eventID, b: barcode } = queryString.parse(location.search)

  useEffect(() => {
    if (barcode && eventID) {
      const actionFunc = token ? getStreamUrl : getGuestStreamUrl
      dispatch(actionFunc(eventID, barcode))
        .then((res = _eventData) => {
          setEventData({ ...res })
          setVideoSrc(res?.url + '/embedBare?rsvp_off=true')
        })
        .catch(() => history.push('/'))
    } else {
      history.push('/')
    }
  }, [])

  return (
    <Layout className='wrapperStream'>
      <Header eventData={eventData} />
      <div className='stream_body'>
        <div className='iconVideo'>
          <img src={streamIcon} alt=' ' />Live
        </div>
        <iframe className='stream_frame' src={videoSrc} allowFullScreen frameBorder={0} />
        {/* <Player className='stream_frame' src={realSrc} /> */}
        {/* <ReactHlsPlayer
          url={src}
          autoplay={false}
          controls
          width='100%'
          height='auto'
        /> */}
        <div className='chat-container'>
          <ChatFrame barcode={barcode} eventID={eventID} />
        </div>
      </div>
    </Layout>
  )
}

export default Stream
