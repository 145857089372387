/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react'
import { Input, Modal } from 'antd'
import './index.styl'
import closeIconSvg from 'images/Crossmark.svg'
import MainButton from '../MainButton'

const ModalInviteMember = ({ visible = true, setVisible, sendInvite }) => {
  const [email, setEmail] = useState('')
  const onChange = ({ target }) => setEmail(target?.value)

  const onCancel = () => {
    setEmail('')
    setVisible(false)
  }

  const onSendInvite = () => {
    sendInvite(email)
    onCancel()
  }

  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const attr = { visible, onCancel, width: 472, footer: null, className: 'modal-invite-member' }

  return (
    <Modal title='Invite member' {...attr} closeIcon={closeIcon} centered>
      <div className='head-title'>
        Enter Email
      </div>
      <Input
        className='inputSearch noFocusBorder'
        onChange={onChange}
        placeholder=''
        value={email}
      />
      <div className='button-pad-wrapper'>
        <div className='button-pad'>
          <MainButton
            text='Cancel' className='cancel-button' onClick={() => onCancel()}
          />
          <MainButton text='Send Invite' className='invite-button' onClick={() => onSendInvite()} />
        </div>
      </div>
    </Modal>
  )
}

export default ModalInviteMember
