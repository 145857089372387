import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import { regiftFromWallet, regiftFromOrder, getMyWalletTickets } from 'modules/events/events-actions'
import { minLength } from 'modules/utils/validators'
import { onValuesChange } from 'utils'
import { getRule } from './formHelpers'
import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form

const GiftForm = ({ currentBarcode, isSimpleTicket, orderId, closeFormGift, updateOrder }) => {
  const [loading, setLoading] = useState('')
  const dispatch = useDispatch()

  const [formGift] = Form.useForm()

  const finish = (form, inviteType, rezForm) => {
    const { email, mobile } = form
    if (isSimpleTicket) {
      let obj = {
        barcode: currentBarcode,
        email: email,
        mobile: mobile,
      }

      if (inviteType === 'RESELL') {
        obj = { ...obj, price: form.price }
      }

      dispatch(regiftFromWallet(obj))
        .then(() => rezForm.resetFields())
        .then(() => {
          setLoading('')
          dispatch(getMyWalletTickets({ type: 'ACTIVE', limit: 20, skip: 0, search: '', ressel: true }))
        })
    } else {
      const data = {
        barcode: currentBarcode,
        email: email,
        mobile: mobile,
        // countryCode: '+1',
      }

      dispatch(regiftFromOrder(orderId, data))
        .then(() => {
          setLoading('')
          updateOrder()
          closeFormGift()
        })
    }
  }

  const onFinishGift = form => {
    const { email, quantity } = form
    if (!email) {
      if (!isSimpleTicket) { return setLoading('gift') } else if (!quantity) {
        return setLoading('gift')
      }
    }
    // if (!email || !quantity) return
    // setLoading('gift')
    finish(form, 'REGIFT', formGift)
  }
  return (
    <div className='rootTicketForms giftMargin'>
      <Form
        layout='vertical'
        className='form'
        onFinish={onFinishGift}
        onValuesChange={onValuesChange(formGift)}
        form={formGift}
      >
        <div className='formItemsWrapper'>
          <Item
            name='email'
            label='Email'
            className='item'
            rules={getRule('Email', 'email')}
            validateTrigger='onBlur'
          >
            <Input className='input darkTwoBg' placeholder='Type here' />
          </Item>
          <Item
            name='mobile'
            label='Phone number'
            className='item'
            rules={[{ required: true, message: 'Please enter phone number' }, minLength]}
            validateTrigger='onBlur'
          >
            <Input className='input darkTwoBg' prefix='+1' placeholder='234 567 890' type='number' />
          </Item>

        </div>
        <CustomButton
          text='Confirm'
          type='dark'
          htmlType='submit'
          className='wrapperSubmit'
          loading={loading === 'gift'}
          disabled={loading === 'gift'}
        />
      </Form>
    </div>
  )
}

export default GiftForm
