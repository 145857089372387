import React, { useEffect, useState } from 'react'
import { Counter, CustomButton, ModalBuyMemberTicket } from 'components'
import { getFormattedNumber, checkEarlyBirdActuality } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import {
  membershipRequest,
} from 'modules/memberships/memberships-actions'
import { publicBuyV2 } from 'modules/payment/payment-actions'
import isInteger from 'lodash/isInteger'
import RemoveBuySeat from 'images/removeBuySeat.svg'
import { Divider, notification } from 'antd'
import moment from 'moment'

import './index.styl'
import ModalAdditionalInformation from '../../../components/ModalAdditionalInformation'

const Body = ({ currentPageDetails, id }) => {
  // const tickets = [
  //  ...(currentPageDetails?.passes || []),
  //  ...(currentPageDetails?.sessions || []),
  //  ...(currentPageDetails?.items || []),
  // ].filter(Boolean)
  const {
    tickets = [],
    inviterID,
  } = useSelector(state => state.events.currentEvent)
  const {
    isMembershipMember,
  } = useSelector(state => state.memberships)
  const { timeZone } = currentPageDetails
  const [visible, setVisible] = useState(false)
  const [visibleAdditionalInformation, setVisibleAdditionalInformation] = useState(false)
  const [selectedTickets, setSelectedTickets] = useState([])
  const [allowBuyTicket, setAllowBuyTicket] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [donationAmount, setDonationAmount] = useState(0)
  const dispatch = useDispatch()

  const hasFreeMembershipPass = selectedTickets.some(ticket =>
    ticket.ticketType === 'MEMBERSHIP_PASS' && ticket.price === 0,
  )

  useEffect(() => {
    let sumTicket = 0
    selectedTickets.forEach(i => (sumTicket += i.total))
    setTotalPrice(sumTicket)
  }, [selectedTickets])

  const updateAfterBuy = () => {
    setSelectedTickets([])
    setDonationAmount(0)
    if (!hasFreeMembershipPass && (currentPageDetails?.parentApproval || currentPageDetails?.rosterDoB || currentPageDetails?.signupFields !== null)) {
      notification.success({ message: 'Payment successful', description: 'Tickets available in My wallet' })
      setVisible(false)
      setVisibleAdditionalInformation(true)
    }
    if (!isMembershipMember && (currentPageDetails?.rosterDoB || currentPageDetails?.signupFields !== null)) {
      return
    }
    if (!isMembershipMember && (!currentPageDetails?.parentApproval || !currentPageDetails?.rosterDoB || !currentPageDetails?.signupFields !== null)) {
      const obj = {
        eventID: id,
        signupData: [],
      }

      dispatch(membershipRequest(obj))
    }
  }
  const setDonateSelected = num => {
    if (isNaN(num) || num < 0) num = 0
    setDonationAmount(num)
  }

  const getFixed = num => num % 1 === 0 ? num : num.toFixed(2)

  const onChange = () => {
    const hasPaidTicket = selectedTickets.some(ticket =>
      ticket.price > 0,
    )
    if (hasFreeMembershipPass && !hasPaidTicket && !donationAmount) {
      const ticketDetails = trimArray(selectedTickets).map(i => {
        const isEarlyBirdActive = i.isEarlyBird && checkEarlyBirdActuality(i?.earlyBirdDate, timeZone)
        return {
          ticketID: i._id,
          quantity: i.count,
          price: isEarlyBirdActive ? i.earlyBirdPrice : i.price,
        }
      })
      const obj = {
        ticketDetails: [...ticketDetails],
        inviterID,
        eventID: id,
      }
      const handleBuyV2 = (obj, updateAfterBuy, successMessage) => {
        dispatch(publicBuyV2(obj, updateAfterBuy)).then(() => {
          if (!isMembershipMember && (currentPageDetails?.parentApproval || currentPageDetails?.rosterDoB || currentPageDetails?.signupFields !== null)) {
            notification.success({ message: successMessage })
            setVisibleAdditionalInformation(true)
          } else {
            notification.success({ message: successMessage })
          }
        })
      }
      if (currentPageDetails?.ownerApproval) {
        handleBuyV2(obj, updateAfterBuy, 'Your membership is awaiting for approval by the Manager')
      } else {
        handleBuyV2(obj, updateAfterBuy, 'Tickets available in My wallet')
      }
    } else if (allowBuyTicket) {
      notification.warning({
        message: "You can't buy more tickets than available",
      })
    } else {
      if (totalPrice || donationAmount) {
        setVisible(!visible)
      } else {
        notification.warning({
          message: 'Please enter quantity greater than 0',
        })
      }
    }
  }
  const setSelected = (ticketID, num, i) => {
    if (isNaN(num) || num < 0) num = 0
    if (!isInteger(num)) num = Math.trunc(num)
    const selectedTicketIndex = tickets.findIndex(cat => cat._id === ticketID)
    const currentAvailableTicket = tickets.find(cat => cat._id === ticketID)
    selectedTickets[selectedTicketIndex] = currentAvailableTicket
    if (!i.isDonate) {
      if (num >= i?.ticketCount?.available) {
        num = i?.ticketCount?.available
      }
      const newSelectedTickets = selectedTickets.map(i => {
        const selectedPrice = i?.isEarlyBird && checkEarlyBirdActuality(i?.earlyBirdDate, timeZone) ? i?.earlyBirdPrice : i?.price
        return i._id === ticketID
          ? { ...i, price: i.price, count: num, total: num * selectedPrice }
          : { ...i, price: i.price }
      })
      setSelectedTickets(newSelectedTickets)
    } else if (i.isDonate) {
      const newSelectedTickets = selectedTickets.map(i =>
        i._id === ticketID ? { ...i, price: i.price, total: num, count: 1, isDonate: true } : { ...i, price: i.price },
      )
      setSelectedTickets(newSelectedTickets)
    }
  }

  const sortTicketTypes = ticketTypes => {
    const sortedOrder = ['MEMBERSHIP_SUBSCRIPTIONS', 'MEMBERSHIP_PASS', 'MEMBERSHIP_SESSIONS', 'ITEM']
    return ticketTypes.sort((a, b) => {
      return sortedOrder.indexOf(a[0]) - sortedOrder.indexOf(b[0])
    })
  }

  const handleRemoveCategory = id => {
    const removeById = selectedTickets.filter(obj => obj._id !== id)
    setSelectedTickets(removeById)
  }
  // get all tickets by type and group them
  const groupedTickets = tickets.reduce((acc, ticket) => {
    if (!acc[ticket.ticketType]) acc[ticket.ticketType] = []
    acc[ticket.ticketType].push(ticket)
    return acc
  }, {})
  const trimArray = array => {
    return array.filter(item => item.total > 0 || item.count >= 1)
  }
  const sortedGroupedTickets = sortTicketTypes(Object.entries(groupedTickets))

  const donationAmountSumm = donationAmount && donationAmount.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 })
  // futher change ._id to id
  return (
    <div className='wrapperBodyPage'>
      <div className='wrapperBodyTicket'>
        {sortedGroupedTickets.map(([type, tickets]) => (
          <React.Fragment key={type}>
            <div className='titleGroup'>{type === 'MEMBERSHIP_SUBSCRIPTIONS' ? 'Subscription Passes' : type === 'MEMBERSHIP_PASS' ? 'Passes' : type === 'MEMBERSHIP_SESSIONS' ? 'Sessions' : 'Shop'}</div>
            {tickets.map((i, index) => {
              const currentPrice = i?.isEarlyBird ? i.earlyBirdPrice : i.price
              const selectedTicketsCount = selectedTickets.find(ticket => ticket?._id === i._id)?.count || 0
              const currentDateTime = moment().tz(timeZone)

              const earlyBirdDateTime = moment.tz(i.earlyBirdDate, 'YYYY-MM-DD', timeZone)
              const isEarlyBird = i?.isEarlyBird && currentDateTime.isSameOrBefore(earlyBirdDateTime)
              const earlyBirdDate = isEarlyBird ? moment.tz(i.earlyBirdDate, timeZone).format('MM.DD.YYYY') : null
              if (!i.isDonate && currentPrice >= 0) {
                return (
                  <div key={i._id} className='wrapperCategory'>
                    <div className='categoryName'>{i.name || i.categoryName}</div>
                    <div className='wrapperLeftRight'>
                      <div className='left'>
                        <div className='wrapperEarlyBirdPrice'>
                          {isEarlyBird ? (

                            <div className='price'>
                              <span>{'$'}{i.earlyBirdPrice}</span>&nbsp;<span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px', marginRight: '12px' }}>{'$'}{i.price}</span>
                            </div>
                          ) : <div className='price'>{'$'}{getFixed(i.price)}</div>}

                          {earlyBirdDate && (
                            <div className='earlyBirdDate'>"Early Bird Special" until {earlyBirdDate}</div>
                          )}
                        </div>
                      </div>
                      <div className='right'>
                        {i?.showAvailableTickets && i?.ticketCount?.available !== 0 &&
                          <div className='ticketsCount'>
                            Available tickets: {i?.ticketCount.available - selectedTicketsCount}
                            <span>/</span>{i?.quantity}
                          </div>}
                        <div className='soldoutWrapper'>
                          {i?.ticketCount?.available === 0 && <div className='soldoutBage'>Sold Out</div>}
                          <Counter
                            setAllowBuyTicket={setAllowBuyTicket}
                            min={0}
                            max={i?.ticketType === 'MEMBERSHIP_PASS' ? 1 : i?.ticketCount.available}
                            count={selectedTicketsCount}
                            setCount={num => setSelected(i._id, num, i)}
                            disabled={i?.ticketCount?.available === 0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </React.Fragment>
        ))}
        {visible &&
          <ModalBuyMemberTicket
            currencySymbol='$'
            img={currentPageDetails?.images?.thumbnail1}
            visible={visible}
            setVisible={setVisible}
            onChange={onChange}
            isMembership
            totalPriceTickets={totalPrice}
            orderedTickets={trimArray(selectedTickets)}
            donation={donationAmount}
            eventID={id}
            tickets={tickets}
            callback={updateAfterBuy}
          />}
        {visibleAdditionalInformation && !isMembershipMember &&
          <ModalAdditionalInformation
            visible={visibleAdditionalInformation}
            eventID={id}
            parentApproval={currentPageDetails?.parentApproval}
            rosterDoB={currentPageDetails?.rosterDoB}
            signupFields={currentPageDetails?.signupFields}
            setVisible={setVisibleAdditionalInformation}
          />}
      </div>
      <div className='sidebarPage'>
        <div className='title'>Selected Items</div>
        <Divider />
        {trimArray(selectedTickets).map(ticket => {
          const isEarlyBirdActive = ticket.isEarlyBird && checkEarlyBirdActuality(ticket?.earlyBirdDate, timeZone)
          return (
            <div key={ticket._id}>
              <div className='flex'>
                <div className='type'>{ticket.name || ticket.categoryName}</div>
                <div className='flex'>
                  <div className='price'>
                    {`${'$'}${isEarlyBirdActive ? ticket.earlyBirdPrice : ticket.price}`}
                  </div>
                  <img onClick={() => handleRemoveCategory(ticket._id)} className='removeBuySeat' src={RemoveBuySeat} alt='removeBuySeat' />
                </div>
              </div>
              <div className='wrapperQuantityEarlyBird'>
                <div className='quantity'>Tickets q-ty <span>{ticket.count}</span></div>
                {isEarlyBirdActive && <span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px', marginRight: '35px' }}>{'$'}{ticket.price}</span>}
              </div>
              <Divider />
            </div>
          )
        })}
        {currentPageDetails?.donationEnabled && (
          <div key='donation'>
            <div className='flex'>
              <div className='price'>Donate</div>
              <Counter
                min={0.01}
                max={1000000}
                count={donationAmountSumm}
                setCount={num => setDonateSelected(parseFloat(num))}
                placeholder={'$' + '0.00'}
                // disabled={isEventCancelled}
                hideButton
              />
            </div>
            <div className='quantity'>Input amount</div>
          </div>
        )}
        {currentPageDetails?.donationEnabled && <Divider />}
        <div className='flex'>
          <span className='type'>TOTAL</span>
          <span className='price'>{`${'$'}${getFormattedNumber(totalPrice + donationAmount)}`}</span>
        </div>
        <CustomButton
          text={totalPrice ? `Get tickets for ${'$'}${getFormattedNumber(totalPrice + donationAmount)}` : 'Get tickets'}
          // disabled={isEventCancelled}
          className='btn btnBuyPage'
          type='dark'
          onClick={onChange}
        />
      </div>
    </div>
  )
}
export default Body
