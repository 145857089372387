import React, { useState } from 'react'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { CustomButton } from 'components'
import { Form, notification, Checkbox } from 'antd'
import c from 'classnames'
import './index.styl'

const { Item } = Form

const objNumber = {
  invalid_number: 'The card number is not a valid credit card number',
  incorrect_number: 'The card number is incorrect',
  incomplete_number: 'The card number is incomplete',
}

const objCvc = {
  invalid_cvc: 'The card’s security code is invalid',
  incomplete_cvc: 'The card’s security code is incomplete',
  incorrect_cvc: 'The card’s security code is incorrect',
}

const objDate = {
  invalid_expiry_month: 'The card’s expiration month is invalid',
  invalid_expiry_month_past: 'The card’s expiration month is invalid',
  invalid_expiry_year: 'The card’s expiration year is invalid',
  incomplete_expiry: 'The card’s expiration date is incomplete',
  expired_card: 'The card has expired',
  invalid_expiry_year_past: 'The card’s expiration year is in the past',
}

export default ({ totalPrice, onSubmit, currencySymbol = '$' }) => {
  const [loading, setLoading] = useState(false)
  const [agreement, setAgreement] = useState(false)

  const [errorInput, setErrorInput] = useState({ cardNumber: '', cardExpiry: '', cardCvc: '' })
  const stripe = useStripe()
  const elements = useElements()

  const onChangeError = e => setErrorInput(errorInput => ({
    ...errorInput, [e.elementType]: e.error ? e.error.code : '',
  }))

  const onFinish = async () => {
    if (loading) return
    setLoading(true)

    const errorLength = Object.values(errorInput).find(i => i.length)
    if (!stripe || !elements || errorLength) {
      setLoading(false)
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    await stripe.createToken(cardElement).then(({ error, token }) => {
      if (error) {
        notification.warning({ message: error.message })
        setLoading(false)
        return
      }
      setLoading(true)
      onSubmit(token.id, agreement, () => setLoading(false))
    })
  }

  const textBtn = `Pay ${currencySymbol}${totalPrice ? totalPrice?.toFixed(2) : 0}`
  const disabled = loading

  return (
    <div className='wrapperFormPaymentMS formDiscount'>
      <div className='paymentDetails'>Payment Details</div>
      <Form layout='vertical' className='form' onFinish={onFinish}>
        <div>
          <Item name='numberCard' label='Credit card number' className='item itemStripe'>
            <CardNumberElement className='input' showIcon onChange={onChangeError} />
          </Item>
          <div className='errorMessage'>{errorInput.cardNumber && objNumber[errorInput.cardNumber]}</div>
        </div>
        <div className='row'>
          <div>
            <Item name='date' label='Expiration date' className='item itemStripe'>
              <CardExpiryElement className='input' onChange={onChangeError} />
            </Item>
            <div className={c('errorMessage', { isFull: !!errorInput.cardExpiry })}>
              {errorInput.cardExpiry && objDate[errorInput.cardExpiry]}
            </div>
          </div>
          <div>
            <Item name='code' label='CVC Code' className='item itemStripe'>
              <CardCvcElement className='input' onChange={onChangeError} />
            </Item>
            <div className={c('errorMessage', { isFull: !!errorInput.cardCvc })}>
              {errorInput.cardCvc && objCvc[errorInput.cardCvc]}
            </div>
          </div>
        </div>
        <div className='checkboxSubscribe'>
          <Item
            name='agreement'
            valuePropName='checked'
            className='agreement'
            initialValue={agreement}
          >
            <Checkbox
              checked={agreement}
              onChange={e => setAgreement(e.target.checked)}
            >
              By providing a telephone number you are consenting to be contacted by SMS text message from Ticket Lyfe. Message and data rates may apply. Message frequency varies. Reply STOP to opt-out of future messaging. Reply HELP for more information. See our <a href={`${window.location.origin}/help/terms-and-conditions`}>Privacy Policy.</a>
            </Checkbox>
          </Item>
        </div>
        <div className='wrapperActions'>
          <CustomButton text={textBtn} type='dark' className='buySubscribeBtn' htmlType='submit' loading={loading} disabled={disabled} />
        </div>
      </Form>
    </div>
  )
}
