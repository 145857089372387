import { combineReducers } from 'redux'

import { SESSION_STATE } from 'modules/session/session-constants'
import { sessionReducer } from 'modules/session/session-reducers'

import { EVENTS_STATE } from 'modules/events/events-constants'
import { eventsReducer } from 'modules/events/events-reducers'

import { PAYMENT_STATE } from 'modules/payment/payment-constants'
import { paymentReducer } from 'modules/payment/payment-reducers'

import { CHAT_STATE } from 'modules/chat/chat-constants'
import { chatReducer } from 'modules/chat/chat-reducers'

import { TEMPLATES_STATE } from 'modules/templates/templates-constants'
import { templatesReducer } from 'modules/templates/templates-reducers'

import { MEMBERSHIPS_STATE } from 'modules/memberships/memberships-constants'
import { membershipsReducer } from 'modules/memberships/memberships-reducers'

const createReducer = asyncReducers => {
  return (
    combineReducers({
      ...asyncReducers,
      [SESSION_STATE]: sessionReducer,
      [EVENTS_STATE]: eventsReducer,
      [PAYMENT_STATE]: paymentReducer,
      [CHAT_STATE]: chatReducer,
      [TEMPLATES_STATE]: templatesReducer,
      [MEMBERSHIPS_STATE]: membershipsReducer,
    })
  )
}

export default createReducer
