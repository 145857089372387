import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAssignTicketSeats, getAssignTicketSectionDetails, updateAssignTicketSeats } from 'modules/events/events-actions'
import { Checkbox, Popover, Popconfirm } from 'antd'
import { MainButton, ModalUpdateSeats } from 'components'
import Seats from 'images/Seats.svg'
import { push } from 'modules/router'
import c from 'classnames'
import settingsIcon from 'images/Settings.svg'
import './index.styl'

const RowsTable = () => {
  const [visible, setVisible] = useState(false)
  const [seatsData, setSeatsData] = useState([])
  const [activeSeat, setActiveSeat] = useState([0, 0])
  const [activeRows, setActiveRows] = useState([])
  const [activeColumns, setActiveColumns] = useState([])
  const [columnTableData, setColumnTableData] = useState([])
  const [rowTableData, setRowTableData] = useState([])
  const [checkboxRows, setCheckboxRows] = useState([])
  const [checkboxColumns, setCheckboxColumns] = useState([])
  const dispatch = useDispatch()
  const { eventID, sectionID } = useParams()
  const { assignTicketSeats, assignTicketSectionDetails, currentEventDetails } = useSelector(state => state.events)

  const handleBackToMyBoxOffice = () => {
    push('/my-box-office/table-events')
  }

  const handleModal = () => {
    setVisible(!visible)
  }

  const handleClickSettingsIcon = e => {
    setActiveColumns([e])
  }
  const getSeatStatus = (indRow, indCol) => {
    let status
    assignTicketSeats.map(i => {
      if (i.columnNo === indCol && i.rowNo === indRow) {
        if (i.isReserved) {
          status = '3'
        }
        if (i.isPasswordEnabled) {
          status = '5'
        }
      }
    })
    // console.log(indRow, indCol)
    switch (status) {
      case '6':
        return <div className={c('seatSectionStatus', 'deleted')}>Deleted</div>
      case '5':
        return <div className={c('seatSectionStatus', 'locked')}>Locked</div>
      case '3':
        return <div className={c('seatSectionStatus', 'reserved')}>Reserved</div>
      case '4':
        return <div className={c('seatSectionStatus', 'sold')}>Sold</div>
      case '2':
        return <div className={c('seatSectionStatus', 'requiresCode')}>Requires Code</div>
      default:
        return <div className={c('seatSectionStatus', 'available')}>Available</div>
    }
  }

  const submitFormUpdateSection = (values, isReserved, isAddEventCode) => {
    const arrayOfSeats = []
    for (const key of seatsData) {
      for (const key2 of key) {
        let obj = {}
        if (activeColumns.filter(Boolean).length > 0) {
          activeColumns.map(indCol => {
            if (key2.columnNo === indCol) {
              obj = {
                ...key2,
                isPasswordEnabled: isAddEventCode,
                isReserved: isReserved,
                password: values.eventcodeinput || '',
                price: +values.price || 0,
                columnName: values.colName || key2.columnNo + '',
              }
            } else {
              obj = key2
            }
          })
        } else if (activeRows.filter(Boolean).length > 0) {
          activeRows.map(indRow => {
            if (key2.rowNo === indRow) {
              obj = {
                ...key2,
                isPasswordEnabled: isAddEventCode,
                isReserved: isReserved,
                password: values.eventcodeinput || '',
                price: +values.price || 0,
                rowName: key2.rowNo + '',
              }
            } else {
              obj = key2
            }
          })
        } else if (activeSeat[0] !== 0 && activeSeat[1] !== 0) {
          if (key2.rowNo === activeSeat[0] && key2.columnNo === activeSeat[1]) {
            obj = {
              ...key2,
              isPasswordEnabled: isAddEventCode,
              isReserved: isReserved,
              password: values.eventcodeinput || '',
              price: +values.price || 0,
            }
          } else {
            obj = key2
          }
        }
        arrayOfSeats.push(obj)
      }
    }
    const fullObj = {
      eventID: eventID,
      sectionID: sectionID,
      seatArray: JSON.stringify(arrayOfSeats),
    }
    dispatch(updateAssignTicketSeats(eventID, sectionID, fullObj))
      .then(() => {
        setActiveColumns([])
        setActiveRows([])
      })
  }

  useEffect(() => {
    dispatch(getAssignTicketSectionDetails(eventID, sectionID))
    dispatch(getAssignTicketSeats(eventID, sectionID))
  }, [])

  useEffect(() => {
    const data = [...Array(assignTicketSectionDetails.sectionRows)]
      .map(() => Array(assignTicketSectionDetails.sectionColumns).fill({}))
    let num = 0
    const updatedData = data.map((row, indexRow) => {
      return row.map((_, indexCol) => {
        const newObj = assignTicketSeats.find((i, index) => index === num)
        num = num + 1
        return {
          columnName: newObj ? newObj.columnName : indexCol + 1 + '',
          isDeleted: newObj ? newObj.isDeleted : false,
          isPasswordEnabled: newObj ? newObj.isPasswordEnabled : false,
          isReserved: newObj ? newObj.isReserved : false,
          password: newObj ? newObj.password : '',
          price: newObj ? newObj.price : 0,
          rowName: newObj ? newObj.rowName : letters[indexRow],
          seatName: newObj ? newObj.seatName : [letters[indexRow], indexCol + 1 + ''].join('-'),
          columnNo: indexCol + 1,
          rowNo: indexRow + 1,
        }
      })
    })
    setSeatsData(updatedData)
  }, [assignTicketSectionDetails, assignTicketSeats])

  useEffect(() => {
    const columnData = seatsData[0]?.map((_, indexCol) => ({
      label: indexCol + 1,
      value: indexCol + 1,
    }))
    const rowData = seatsData.map((_, indexRow) => ({
      label: letters[indexRow],
      value: false,
    }))
    setColumnTableData(columnData)
    setRowTableData(rowData)
  }, [seatsData])

  useEffect(() => {
    const checkboxArrayRows = Array(rowTableData?.length).fill(false)
    const checkboxArrayColumns = Array(columnTableData?.length).fill(false)
    setCheckboxRows(checkboxArrayRows)
    setCheckboxColumns(checkboxArrayColumns)
  }, [columnTableData, rowTableData])

  const onChangeColumnSection = (e, index) => {
    if (!e.target.checked) {
      const updatedColumns = activeColumns.filter(i => i !== index)
      setActiveColumns(updatedColumns)
    } else {
      setActiveColumns([...activeColumns, index])
    }
    const resetRow = Array(rowTableData.length).fill(false)
    setActiveRows(resetRow)
    setCheckboxRows(resetRow)
    const checkBoxCurrentState = checkboxColumns
    checkBoxCurrentState[index - 1] = !checkBoxCurrentState[index - 1]
    setCheckboxColumns(checkBoxCurrentState)
    setActiveSeat([0, 0])
  }

  const onChangeRowSection = (e, index) => {
    if (!e.target.checked) {
      const updatedRows = activeRows.filter(i => i !== index)
      setActiveRows(updatedRows)
    } else {
      setActiveRows([...activeRows, index])
    }
    const resetColumn = Array(rowTableData.length).fill(false)
    setActiveColumns(resetColumn)
    setCheckboxColumns(resetColumn)
    const checkBoxCurrentState = checkboxRows
    checkBoxCurrentState[index - 1] = !checkBoxCurrentState[index - 1]
    setCheckboxRows(checkBoxCurrentState)
    setActiveSeat([0, 0])
  }
  const letters = (() => [...Array(52)].map((_, i) => String.fromCharCode(i + 65)))()

  const ConfigurateSeats = (rowInd, colInd) => {
    if (activeSeat[0] === rowInd && activeSeat[1] === colInd) {
      setActiveSeat([0, 0])
    } else {
      const resetColumn = Array(rowTableData.length).fill(false)
      const resetRow = Array(rowTableData.length).fill(false)
      setActiveColumns(resetColumn)
      setActiveRows(resetRow)
      setCheckboxColumns(resetColumn)
      setCheckboxRows(resetRow)
      setActiveSeat([rowInd, colInd])
    }
  }
  const popoverContent = (
    <>
      <div onClick={handleModal} className='popoverBtn'>Edit</div>
      <div className={c('popoverBtn', 'popoverBtnRemove')}>Remove</div>
    </>
  )

  return (
    <div>
      {/* <div className='navigationHeaderTables'>
        <span className='tablesLink' onClick={handleBackToMyBoxOffice}>My Box office</span>
        <span className='tablesLink' onClick={goBack}> / {currentEventDetails?.eventName}</span>
        <span> / {assignTicketSectionDetails?.sectionName}</span>
      </div> */}
      {/* <div className='nameAndActionsTables'>
        <div className='tablesTitle'><img src={Seats} alt='seats icon' />{assignTicketSectionDetails?.sectionName}</div>
        <div className='actionsTables'>
          {activeSeat[0] !== 0 && activeSeat[1] !== 0 &&
            <MainButton
              onClick={handleModal}
              text='Seat details'
              className={c('tablesBtn', 'tablesBtnWhite')}
            />}
          {activeColumns.filter(Boolean).length > 0 &&
            <MainButton
              onClick={handleModal}
              text='Column details'
              className={c('tablesBtn', 'tablesBtnWhite')}
            />}
          {activeRows.filter(Boolean).length > 0 &&
            <MainButton
              onClick={handleModal}
              text='Row details'
              className={c('tablesBtn', 'tablesBtnWhite')}
            />}
          <MainButton
            text='Section settings'
            className={c('tablesBtn', 'tablesBtnWhite')}
          />
          <MainButton
            text='Remove Section'
            className='tablesBtn'
          />
        </div>
      </div> */}
      <div className='navigationHeaderTables'>
        <span className='tablesLink' onClick={handleBackToMyBoxOffice}>My Box office</span>
        <span
          className='tablesLink' onClick={() => {
            push(`/my-box-office/event-details/assigned-table/${eventID}`)
          }}
        > / {currentEventDetails?.eventName}
        </span>
        <span> / Section name</span>
      </div>
      <div className='nameAndActionsTables'>
        <div className='tablesTitle'><img src={Seats} alt='seats icon' className='tablesTitleIcon' />Section name</div>
        <div className='actionsTables'>
          {activeSeat[0] !== 0 && activeSeat[1] !== 0 &&
            <MainButton
              onClick={handleModal}
              text='Seat details'
              className={c('tablesActionsBtn', 'tablesActiocsBtnWhite')}
            />}
          {activeColumns.filter(Boolean).length > 0 &&
            <MainButton
              onClick={handleModal}
              text='Column details'
              className={c('tablesActionsBtn', 'tablesActiocsBtnWhite')}
            />}
          {activeRows.filter(Boolean).length > 0 &&
            <MainButton
              onClick={handleModal}
              text='Row details'
              className={c('tablesActionsBtn', 'tablesActiocsBtnWhite')}
            />}
          <MainButton
            onClick={() => {}}
            text='Section Settings'
            className={c('tablesActionsBtn', 'tablesActiocsBtnWhite')}
          />
          <Popconfirm onConfirm={() => {}} title='Are you sure？' okText='Yes' cancelText='No' style={{ padding: '15px' }}>
            <MainButton
              text='Remove Section'
              className='tablesActionsBtn'
            />
          </Popconfirm>
        </div>
      </div>
      <div className='rowsTable'>
        <div className='rowsTableBlock'>
          {rowTableData.map((i, index) => (
            <div key={index} className='rowAction'>
              <Checkbox
                checked={checkboxRows[index]}
                onChange={e => onChangeRowSection(e, index + 1)}
              >
                {i.label}
              </Checkbox>
              <Popover
                placement='right'
                content={popoverContent}
                trigger='click'
                className='actionPopover'
              >
                <img src={settingsIcon} alt='settings Icon' />
              </Popover>
            </div>
          ))}
        </div>
        <div>
          <div className='tableHeader'>
            {columnTableData?.map((i, index) => (
              <div key={index} className='columnAction'>
                <Checkbox
                  checked={checkboxColumns[index]}
                  onChange={e => onChangeColumnSection(e, index + 1)}
                >
                  {i.label}
                </Checkbox>
                <Popover
                  placement='right'
                  content={popoverContent}
                  trigger='click'
                  className='actionPopover'
                >
                  <img src={settingsIcon} alt='settings Icon' onClick={() => handleClickSettingsIcon(index + 1)} />
                </Popover>
              </div>
            ))}
          </div>

          {seatsData?.map((column, indexRow) => (
            <div key={indexRow} className={c('tableRow')}>
              {column.map((_, indexCol) => (
                <div
                  key={indexCol}
                  className='tableColumn'
                  onClick={() => ConfigurateSeats(indexRow + 1, indexCol + 1)}
                >
                  <div
                    className={c('seatSection',
                      { tableRowActive: activeRows.some(i => i === indexRow + 1) },
                      { tableColActive: activeColumns.some(i => i === indexCol + 1) },
                      {
                        tableSeatActive: activeSeat[0] === indexRow + 1 &&
                         activeSeat[1] === indexCol + 1,
                      })}
                  >
                    {getSeatStatus(indexRow + 1, indexCol + 1)}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      <ModalUpdateSeats
        visible={visible}
        updateVisible={handleModal}
        submitForm={submitFormUpdateSection}
        activeRows={activeRows.filter(Boolean)}
        activeColumns={activeColumns.filter(Boolean)}
        activeSeat={activeSeat}
      />
    </div>
  )
}

export default RowsTable
