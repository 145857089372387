import React from 'react'
import itemKnote from 'images/itemKnote.svg'
import memberItemKnote from 'images/memberItemKnote.svg'
import { useLocation } from 'react-router'

import './index.styl'

const SpecialItemDetails = () => {
  const { pathname } = useLocation()
  return (
    <div className='titleCategoryWrapperDetails'>
      <img src={pathname?.includes('member') ? memberItemKnote : itemKnote} alt='item' className='knoteImg' />
      <div className='titleCategoryItem'>ITEM TICKET</div>
    </div>
  )
}

export default SpecialItemDetails
