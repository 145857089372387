import React from 'react'
import { Upload } from 'antd'

const UploadComponent = ({ index, handleOnChange, customRequest, listType, accept, showUploadList, children, filesList, disabled }) => {
  return (
    <Upload
      accept={accept}
      customRequest={customRequest}
      onChange={handleOnChange}
      showUploadList={showUploadList}
      listType={listType}
      disabled={disabled}
      fileList={filesList}
      // defaultFileList={defaultFileList}
    >
      {children}
    </Upload>
  )
}

export default UploadComponent
