import * as actionTypes from './events-constants'

export const sessionInitialState = {
  events: [],
  templates: [],
  promotions: [],
  publicEvents: [],
  currentEvent: {},
  currentEventDetails: {},
  currentEventDetailsV2: {},
  currentEventDetailsSummary: {},
  myWallets: {
    accepted: [],
    pending: [],
  },
  walletPendingDataGuest: [],
  imageUrls: {},
  filesAttachment: [],
  assignTicketSection: [],
  scannerInvites: [],
  scannedTickets: [],
  totalScannedTickets: 0,
  scannersTotalCount: 0,
  assignTicketSeats: [],
  assignTicketSectionDetails: {},
  tableArray: [],
  categoriesArray: [],
  unassignedTickets: [],
  unassignedTicketsData: {
    count: 0,
    totalTicketCount: 0,
  },
  transactionList: {},
  balanceList: {},
  soldTicketDetails: {},
  inviteSellUsers: {},
  inviteBuyUsers: {},
  invitePromoteUsers: {},
  inviteGiftUsers: {},
  inviteCashUsers: {},
  mockTicket: {},
  streamEvent: false,
  unreserveSeat: null,
}

export const eventsReducer = (state = sessionInitialState, action) => {
  const { type, payload } = action
  switch (type) {
    case actionTypes.EVENTS_GET_EVENTS:
      return { ...state, events: payload.ownEvents }
    case actionTypes.EVENTS_GET_TEMPLATES:
      return { ...state, templates: payload }
    case actionTypes.EVENTS_GET_PROMOTIONS:
      return { ...state, promotions: payload.promote }
    case actionTypes.EVENTS_SET_EVENT:
      return { ...state, currentEvent: payload }
    case actionTypes.EVENTS_GET_WALLETS:
      return { ...state, myWallets: payload }
    case actionTypes.EVENTS_GET_PUBLIC_EVENTS:
      return { ...state, publicEvents: payload.events }
    case actionTypes.EVENTS_SET_HOME_PUBLIC_EVENTS:
      return { ...state, publicEvents: payload }
    case actionTypes.EVENTS_SET_IMAGES:
      return { ...state, imageUrls: payload }
    case actionTypes.EVENTS_SET_FILES:
      return { ...state, filesAttachment: payload }
    case actionTypes.EVENTS_GET_ASSIGN_TICKETS:
      return { ...state, assignTicketSection: payload }
    case actionTypes.EVENTS_GET_EVENT_DETAILS:
      return { ...state, currentEventDetails: payload }
    case actionTypes.EVENTS_GET_EVENT_DETAILS_V2:
      return { ...state, currentEventDetailsV2: payload }
    case actionTypes.EVENTS_GET_EVENT_DETAILS_SUMMARY:
      return { ...state, currentEventDetailsSummary: payload }
    case actionTypes.EVENTS_GET_SCANNER_INVITES:
      return { ...state, scannerInvites: payload }
    case actionTypes.EVENTS_GET_SCANNED_TiCKETS:
      return { ...state, scannedTickets: payload.tickets, totalScannedTickets: payload.total }
    case actionTypes.EVENTS_GET_SCANNER_TOTAL_COUNT:
      return { ...state, scannersTotalCount: payload }
    case actionTypes.EVENTS_GET_ASSIGN_TICKETS_SEATS:
      return { ...state, assignTicketSeats: payload }
    case actionTypes.EVENTS_GET_ASSIGN_TICKET_SECTION_DETAILS:
      return { ...state, assignTicketSectionDetails: payload }
    case actionTypes.EVENTS_GET_TABLE_SEATS:
      return { ...state, tableArray: payload }
    case actionTypes.EVENTS_GET_TABLE_CATEGORIES:
      return { ...state, categoriesArray: payload }
    case actionTypes.EVENTS_UNASSIGNED_TICKETS_LIST:
      return { ...state, unassignedTickets: payload }
    case actionTypes.EVENTS_UNASSIGNED_TICKETS_DATA:
      return { ...state, unassignedTicketsData: { count: payload?.count || 0, totalTicketCount: payload?.totalTicketCount || 0 } }
    case actionTypes.EVENTS_GET_TRANSACTION_LIST:
      return { ...state, transactionList: payload }
    case actionTypes.EVENTS_GET_BALANCE_LIST:
      return { ...state, balanceList: payload }
    case actionTypes.EVENTS_GET_SOLD_TICKET_DETAILS:
      return { ...state, soldTicketDetails: payload }
    case actionTypes.EVENTS_GET_INVITE_SELL_USERS:
      return { ...state, inviteSellUsers: payload }
    case actionTypes.EVENTS_GET_INVITE_BUY_USERS:
      return { ...state, inviteBuyUsers: payload }
    case actionTypes.EVENTS_GET_INVITE_PROMOTE_USERS:
      return { ...state, invitePromoteUsers: payload }
    case actionTypes.EVENTS_GET_INVITE_GIFT_USERS:
      return { ...state, inviteGiftUsers: payload }
    case actionTypes.EVENTS_GET_INVITE_CASH_USERS:
      return { ...state, inviteCashUsers: payload }
    case actionTypes.EVENTS_GET_WALLETS_GUEST:
      return { ...state, walletPendingDataGuest: payload }
    case actionTypes.EVENTS_SET_MOCK_TICKET:
      return { ...state, mockTicket: payload }
    case actionTypes.EVENT_STREAM:
      return { ...state, streamEvent: payload }
    case actionTypes.UNRESERVE_SEAT:
    {
      const { sectionId, groupId, seatId } = payload
      const sectionIndex = state.currentEvent.assignedSeatings.sections.findIndex(s => s.id === sectionId)
      const section = state.currentEvent.assignedSeatings.sections[sectionIndex]
      const groupIndex = section.groups.findIndex(g => g.id === groupId)
      const group = section.groups[groupIndex]
      const seatIndex = group.seats.findIndex(s => s.id === seatId)
      const newSeat = { ...group.seats[seatIndex], isReserved: false }
      const newGroup = { ...group, seats: [...group.seats.slice(0, seatIndex), newSeat, ...group.seats.slice(seatIndex + 1)] }
      const newGroups = [...section.groups.slice(0, groupIndex), newGroup, ...section.groups.slice(groupIndex + 1)]
      const newSection = { ...section, groups: newGroups }
      const newSections = [...state.currentEvent.assignedSeatings.sections.slice(0, sectionIndex), newSection, ...state.currentEvent.assignedSeatings.sections.slice(sectionIndex + 1)]
      const newState =
    { ...state, currentEvent: { ...state.currentEvent, assignedSeatings: { ...state.currentEvent.assignedSeatings, sections: newSections } } }
      return newState
    }
    default:
      return state
  }
}
