import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomButton } from 'components'
import Location from 'images/LocationWhite.svg'
import PlacesAutocomplete from 'react-places-autocomplete'
import { Form, Input, Checkbox } from 'antd'
import c from 'classnames'
import { signUpVenue } from 'modules/session/session-actions'
import './index.styl'

const { Item } = Form

const VenueSignUp = () => {
  const [loading, setLoading] = useState(false)
  const [address, setAddress] = useState('')
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const onFinish = form => {
    setLoading(true)
    const obj = { ...form, deviceType: 'WEB', countryCode: '+1' }

    Object.keys(form).forEach(i => {
      if (i === 'agreement') {
        delete obj[i]
      } else {
        obj[i] = form[i].trim()
      }
    })

    dispatch(signUpVenue(obj)).then(() => setLoading(false))
  }
  const onPressSpace = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const onComparePasswords = async (rule, value) => {
    if (rule.field === 'password_confirm') {
      if (value && value !== form.getFieldValue('password')) {
        throw new Error('Passwords do not match.')
      }
    } else if (rule.field === 'password') {
      const confirmValue = form.getFieldValue('password_confirm')
      if (
        value &&
        confirmValue &&
        value !== form.getFieldValue('password_confirm')
      ) {
        throw new Error('Passwords do not match.')
      }
    }
  }
  const checkboxValidate = (_, value) =>
    value
      ? Promise.resolve()
      : Promise.reject(new Error('Please agree to Terms & Conditions'))

  const getRule = (label, name, min = 0, required) => {
    const rules = [
      {
        required: required,
        whitespace: true,
        message: 'Please enter ' + label.toLowerCase(),
      },
    ]
    if (name === 'email') {
      return rules.concat({
        type: 'email',
        transform: value => value?.trim(),
      })
    }
    if (name === 'password') {
      return rules.concat(
        {
          min: 6,
          message: 'Password length must be at least 6 characters',
        },
        {
          validator: onComparePasswords,
        },
      )
    }
    if (name === 'password_confirm') {
      return rules.concat({
        validator: onComparePasswords,
      })
    }
    if (name === 'mobile') {
      return rules.concat({
        min: 5,
        message: `${name} must be at least 5 characters.`,
      })
    }
    return rules
  }

  const CustomItem = (name, label, type, min = 0, required = true) =>
    name === 'password_confirm' ? (
      <Item
        name={name}
        label={label}
        dependencies={['password']}
        rules={getRule(label, name, min, required)}
        validateTrigger='onBlur'
      >
        <Input.Password onKeyDown={onPressSpace} className='input' />
      </Item>
    ) : (
      <Item
        name={name}
        label={label}
        className=''
        rules={getRule(label, name, min, required)}
        validateTrigger='onBlur'
      >
        {type === 'password' ? (
          <Input.Password onKeyDown={onPressSpace} className='input' />
        ) : name === 'mobile' || name === 'businessMobile'
          ? <Input prefix='+1' className='input' type='number' />
          : <Input className='input' />}
      </Item>
    )

  const handleSelect = async value => {
    await setAddress(value)
    await form.setFieldsValue({
      address: value,
    })
  }
  const locationIcon = <img src={Location} alt='Location Logo' />
  const className = 'input location-search-input'
  const renderInputLocation = (isRequired = true) => (
    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='locationContainer'>
          <Item
            rules={[{ required: isRequired, message: 'Please Enter Address' }]}
            className='item'
            name='address'
            label='Address'
            normalize={v => v.trimStart()}
          >
            <Input {...getInputProps({ className, suffix: locationIcon })} />
          </Item>
          <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
  return (
    <div className='wrapperSignUp'>
      <div className='radioSignUp'>
        <div className='title'>Sign Up</div>
      </div>
      <Form form={form} layout='vertical' className='form venueSignUp' onFinish={onFinish}>
        {CustomItem('venueName', 'Venue name', 'input', 3)}
        {CustomItem('userName', 'User name', 'input', 3)}
        {CustomItem('email', 'Email', 'input')}
        {renderInputLocation()}
        {CustomItem('businessMobile', 'Business number', 'input')}
        {CustomItem('mobile', 'Mobile number', 'input')}
        {CustomItem('password', 'Create password', 'password')}
        {CustomItem('password_confirm', 'Confirm password', 'password')}
        <div className='checkbox'>
          <Form.Item
            name='agreement'
            valuePropName='checked'
            rules={[
              {
                validator: checkboxValidate,
              },
            ]}
          >
            <Checkbox>
              I Accept{' '}
              <Link className='link' to='/help/terms-and-conditions'>
                Terms & Conditions
              </Link>
            </Checkbox>
          </Form.Item>
        </div>
        <CustomButton
          text='Sign up'
          type='dark'
          className='btn signUpVenueBtn'
          htmlType='submit'
          loading={loading}
        />
        <div className='linkToLogInWrapper'>
          <div className='noAccountTitle'>Already have an account?</div>
          <Link className='logInButtonLink' to='/auth/sign-in'>Log in</Link>
        </div>
      </Form>
    </div>

  )
}
export default VenueSignUp
