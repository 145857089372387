import React, { useState } from 'react'
import { getCurrencySymbolByCode } from 'utils'
import clock from 'images/Time.svg'
import location from 'images/Location.svg'
import calendar from 'images/Calendar.svg'
import priceIcon from 'images/ticket.svg'
import venueIcon from 'images/venueIcon.svg'
import edit from 'images/editWhite.svg'
import cardImg from 'images/card.png'
import { blockInvalidChar } from './../invalidChars'
import './index.styl'
import { getRule } from './formHelpers'
import c from 'classnames'
import moment from 'moment'
import PlacesAutocomplete from 'react-places-autocomplete'
import { Form, Input, InputNumber, DatePicker, TimePicker } from 'antd'

const { Item } = Form

const MockTicketDetailsForm = ({
  form,
  image,
  eventName,
  currency,
  setEventName,
}) => {
  const [address, setAddress] = useState('')
  const [editName, setEditName] = useState(false)
  const errorHandler = e => (e.target.src = cardImg)
  const currencySymbol = getCurrencySymbolByCode(currency)

  const FormItem = ({
    icon,
    title,
    children,
  }) => (
    <li className='item'>
      <div className='leftSide'>
        {icon && <img src={icon} alt=' ' />}
        <span>{title}</span>
      </div>
      {children}
    </li>
  )

  const renderLocations = () => {
    return (
      <li className='item'>
        <div className='leftSide'>
          <img src={location} alt=' ' />
          <span>Location</span>
        </div>
        <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className='locationContainer'>
              <Item
                name='location'
                className='formItem'
                rules={getRule('Location', 'Address')}
                normalize={v => v.trimStart()}
              >
                <Input className='input darkTwoBg' {...getInputProps({ placeholder: 'Start typing' })} />
              </Item>
              <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                  const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
                  return (
                    <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                      {suggestion.description}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </li>
    )
  }

  const handleSelect = async value => {
    await setAddress(value)
    await form.setFieldsValue({
      location: value,
    })
  }

  return (
    <div className='MockTicketDetailsForm'>
      <div className='imgWrapper'>
        <img onError={errorHandler} src={image} alt=' ' className='imgVideo' />
      </div>
      <div className='wrapperDetails'>
        <div className='detailsContainer'>
          <div className='containerTitle'>
            <div className='detailTicketTitle'>
              {!editName && (eventName || <span className='emptyName'>Enter Event Name</span>)}
              {editName ? (
                <Form
                  form={form}
                  className='form'
                  layout='vertical'
                >
                  <Item
                    name='eventName'
                    className='formItem eventName'
                    rules={getRule('Event Name', 'eventName')}
                    normalize={v => v.trimStart()}
                  >
                    <Input
                      autoFocus
                      className='input darkTwoBg'
                      placeholder='Add event name'
                      onChange={e => setEventName(e.target.value)}
                      onBlur={() => setEditName(false)}
                    />
                  </Item>
                </Form>)
                : (<div className='editIcon' onClick={() => setEditName(true)}><img src={edit} alt=' ' /></div>)}
            </div>
          </div>
          <ul className='wrapperItems'>
            <Form
              form={form}
              className='form'
              layout='vertical'
            >
              <FormItem title='Presented by'>
                <Item
                  name='presentedBy'
                  className='formItem'
                  rules={getRule('Presented by', 'presentedBy')}
                  normalize={v => v.trimStart()}
                >
                  <Input className='input darkTwoBg' placeholder='Add information' />
                </Item>
              </FormItem>
              <FormItem title='Date' icon={calendar}>
                <Item
                  name='date'
                  className='formItem'
                  rules={getRule('Date', 'date')}
                >
                  <DatePicker
                    disabledDate={currentDate => currentDate && currentDate < moment().add(-1, 'days')}
                    className='input darkTwoBg'
                    format='YYYY-MM-DD'
                    suffixIcon={null}
                    inputReadOnly
                  />
                </Item>
              </FormItem>
              <FormItem title='Time' icon={clock}>
                <Item
                  name='time'
                  className='formItem'
                  rules={getRule('Time', 'time')}
                >
                  <TimePicker.RangePicker
                    // disabledDate={currentDate => currentDate && currentDate < moment().add(-2, 'hours')}
                    className='input darkTwoBg'
                    format='h:mm A'
                    suffixIcon={null}
                    inputReadOnly
                    use12Hours
                  />
                </Item>
              </FormItem>
              <FormItem title='Price' icon={priceIcon}>
                <Item
                  name='price'
                  className='formItem'
                  rules={getRule('Price', 'price', 750)}
                  onKeyDown={blockInvalidChar}
                  onWheel={e => e.target.blur()}
                >
                  <InputNumber
                    type='number'
                    min={0}
                    className='input darkTwoBg'
                    placeholder={`${currencySymbol} 0`}
                  />
                </Item>
              </FormItem>
              {renderLocations()}
              <FormItem title='Venue' icon={venueIcon}>
                <Item
                  rules={getRule('Venue', 'venue')}
                  name='venue'
                  className='formItem'
                  normalize={v => v.trimStart()}
                >
                  <Input placeholder='Enter Venue' className='input darkTwoBg' />
                </Item>
              </FormItem>
            </Form>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MockTicketDetailsForm
