import api from './axios'

const login = obj => api('user/login', 'POST', obj, false)

const signUp = obj => api('signup', 'POST', obj, false)

const signUpVenue = obj => api('signupVenue', 'POST', obj, false)

const resetPassword = email => api('user/getResetPasswordToken', 'POST', { email }, false)

const confirmationResetPassword = obj => api('user/resetPassword', 'PUT', obj, false)

const resendEmail = () => api('user/resendEmail', 'PUT')

const verificationEmail = (token, en) => api(`user/verifyEmail?emailVerificationToken=${token}&lang=${en}`, 'GET', {}, false)

const makeConnectedAccount = obj => api('stripe/makeConnectedAccountV2', 'POST', obj)

export default { login, signUp, signUpVenue, resetPassword, resendEmail, verificationEmail, confirmationResetPassword, makeConnectedAccount }
