import api from './axios.v2.withoutTransform'

const preorderId = obj => api('purchase/preorder', 'POST', obj)

const publicBuyV2 = obj => api('purchase/buy', 'POST', obj)

const guestPublicBuyV2 = obj => api('purchase/guest-buy', 'POST', obj, false)

const guestPreorderId = obj => api('purchase/guest-preorder', 'POST', obj, false)

export default { preorderId, guestPreorderId, publicBuyV2, guestPublicBuyV2 }
