import React, { useState } from 'react'
import './index.styl'
import { Popover, notification } from 'antd'
import ThreeDotIcon from 'images/threedots.svg'
import RowSeats from '../RowSeats'
import { handleConfirmModal } from 'utils'
import { template } from 'apiv2'
import { ModalTemplate } from '../../../../../components'
const Section = ({ category, sectionType, tableGroups, categoriesArray, loading, setLoading, templateID, ...group }) => {
  const [visible, setVisible] = useState(false)
  const [openContent, setOpenContent] = useState(false)
  const onChange = () => {
    setVisible(!visible)
    setOpenContent(false)
  }
  const onChangeLoading = () => setLoading(!loading)
  const handleOpenChange = newOpen => {
    setOpenContent(newOpen)
  }
  const onFinish = form => {
    const { seatCount, categoryID } = form
    if (!seatCount) return
    onChangeLoading()
    const obj = { seatCount, categoryID, id: group.id, name: group.groupName }
    template.editRowTable(templateID, obj).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }
  const deleteTemplateRow = e => {
    const obj = { id: group.id }
    onDeleteSection(templateID, obj)
    setOpenContent(false)
  }
  const onDeleteSection = (id, obj) => {
    const onOk = () => {
      setLoading(true)
      template.deleteTemplateRow(id, obj).then(() => setLoading(false)).catch(error => {
        notification.warning({ message: error.response.data.error })
        setLoading(false)
      })
    }
    handleConfirmModal('Are you sure you want to delete this row?', onOk)
  }
  const popoverContent = (
    <div className='actionPopover'>
      <div className='popoverBtn' onClick={onChange}>Edit row</div>
      <div className='popoverBtn popoverBtnRemove' onClick={deleteTemplateRow}>Delete row</div>
    </div>
  )
  return (
    <div className='seatsWrapper'>

      <>
        <div className='groupName'>{group.groupName}</div>
        {group.seats.map(seat => {
          return (
            <div key={seat.id}>
              <RowSeats loading={loading} setLoading={setLoading} templateID={templateID} categoriesArray={categoriesArray} seat={seat} color={seat.category.color} />
            </div>
          )
        })}
      </>

      {sectionType === 'ROWS' &&
        <div className='popoverButton'>
          <Popover
            overlayClassName='actionPopoverContainer'
            getPopupContainer={node => node?.parentNode}
            content={popoverContent}
            placement='top'
            trigger='click'
            visible={openContent}
            onVisibleChange={handleOpenChange}
          >
            <img src={ThreeDotIcon} className='threeDots' alt=' ' />
          </Popover>
          <ModalTemplate additionalProps={{ categoriesArray, seatCount: group.seatCount, initialColor: category?.id }} visible={visible} onChange={onChange} onFinish={onFinish} name='row' width={472} title='Edit Row' loading={loading} />
        </div>}
    </div>
  )
}

export default Section
