/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { eventsV2 } from 'apiv2'
import { CustomButton, ResponsiveCards } from 'components'
import { Table, Input, Tabs, Pagination, notification, Spin } from 'antd'
import SearchIcon from 'images/input-search.svg'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import drum from 'images/drum.webm'
import { throttle } from 'lodash'
import './index.styl'
import ModalPickWinner from '../../../../components/ModalPickWinner'

const { TabPane } = Tabs

const throttleFunc = fn => throttle(fn, 300, { trailing: false })

const pageSize = 10
const responsivePageSize = 4

const RandomDraw = () => {
  const { eventID } = useParams()
  const videoRef = useRef(null)
  const [isResponsive, setIsResponsive] = useState(false)
  const [searchInput, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [visibleModal, setVisibleModal] = useState(false)
  const [waitingModal, setWaitingModal] = useState(false)
  const [visibleWinnerModal, setVisibleWinnerModal] = useState(false)
  const [totalWinners, setTotalWinners] = useState(0) // Total number of winners in the current tab
  const [loading, setLoading] = useState(false)
  const [winnerList, setWinnerList] = useState([])
  const [fullWinnerList, setFullWinnerList] = useState([])
  const [currentTab, setCurrentTab] = useState(0) // Initialize currentTab with 0 (the first tab)
  const [filteredWinnersData, setFilteredWinnersData] = useState([]) // Store the filtered winners separately
  const [filteredWinnersLength, setFilteredWinnersLength] = useState(0) // Store the length of the filtered winners
  const [checkedCategories, setCheckedCategories] = useState([])
  const [allowToggle, setAllowToggle] = useState(false)
  const [rerunDraw, setRerunDraw] = useState(false)
  const [currentRandomDrawID, setCurrentRandomDrawID] = useState(null)
  const [isResume, setIsResume] = useState(false)

  const currentWinnersCount = fullWinnerList[currentTab]?.winnersCount || 0
  const currentWinnersLength = fullWinnerList[currentTab]?.winners.length || 0
 useEffect(() => {
    setIsResponsive(window.innerWidth < 1130)
  }, [window.innerWidth])

  useEffect(() => {
    const filteredData = fullWinnerList.map(info => {
      return {
        ...info,
         winners: info.winners.filter(
          winner =>
            winner.owner.name.toLowerCase().includes(searchInput.toLowerCase()) ||
            winner.owner.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            winner.owner.phone.toLowerCase().includes(searchInput.toLowerCase()),
        ),
      }
    })
    setFilteredWinnersData(filteredData)
    setFilteredWinnersLength(filteredData[currentTab]?.winners.length || 0)
    setCurrentPage(1) // Reset the currentPage to 1 whenever the search input or tab changes
  }, [searchInput, currentTab, fullWinnerList])

  useEffect(() => {
    setTotalWinners(filteredWinnersLength)
  }, [filteredWinnersLength])

  useEffect(() => {
    eventsV2.getRandomDrawList(eventID).then(data => {
     setFullWinnerList(data.data.resData)
     if (!isResume) {
     setCurrentTab(data.data.resData.length - 1)
     }
    })
  }, [winnerList, isResume])

   useEffect(() => {
    // Whenever the currentTab changes, update the total number of winners for the new tab
    const winnersData = fullWinnerList[currentTab]?.winners || []
    setTotalWinners(winnersData.length)
    setCurrentPage(1) // Reset the currentPage to 1 whenever the tab changes
    setCurrentRandomDrawID(fullWinnerList[currentTab]?.id)
  }, [currentTab, fullWinnerList])

   const handleTabChange = activeKey => {
    const tabIndex = Number(activeKey.replace('tab_', ''))
    setCurrentTab(tabIndex)
    setCurrentRandomDrawID(fullWinnerList[currentTab]?.id)
    setCurrentPage(1) // Reset the currentPage to 1 whenever the tab changes
  }
  const onPageChange = page => {
    setCurrentPage(page)
  }
  const handleModal = () => setVisibleModal(!visibleModal)

  const handleResume = () => {
      setLoading(true)
      setWaitingModal(true)
      setRerunDraw(false)
      setIsResume(true)
      eventsV2.randomDrawNextWinner(eventID, currentRandomDrawID).then(data => {
        setWinnerList(data.data)
        setVisibleWinnerModal(true)
        if (data.status === 202) {
          notification.warning({ message: data?.data?.message })
          setWaitingModal(false)
          setVisibleWinnerModal(false)
          setLoading(false)
        }
      }).catch(err => {
        if (err) {
        notification.warning({ message: err?.data.data?.message })
        setWaitingModal(false)
        setRerunDraw(false)
        setVisibleWinnerModal(false)
        }
        })
  }
   const handleRerunWinner = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0

      const playPromise = videoRef.current.play()
      setLoading(true)
      setRerunDraw(false)
      setIsResume(true)
      eventsV2.randomDrawNextWinner(eventID, currentRandomDrawID).then(data => {
        setWinnerList(data.data)
        if (data.status === 202) {
          notification.warning({ message: data?.data?.message })
          setWaitingModal(false)
          setVisibleWinnerModal(false)
          setLoading(false)
        }
      }).catch(err => {
        if (err) {
        notification.warning({ message: err?.data.data?.message })
        setWaitingModal(false)
        setRerunDraw(false)
        setVisibleWinnerModal(false)
        }
        })
      if (playPromise !== undefined) {
        playPromise.then(_ => setVisibleWinnerModal(true)).catch(error => {
          console.error('Error playing video:', error)
        })
      }
    }
  }

  const handlePickWinner = values => {
      setLoading(true)
      setIsResume(false)
      const obj = { categories: values.categories, winnersCount: Number(values.winnersCount), winOnes: values.winOnes, notes: values.notes }
      eventsV2.randomDrawSubmitPopup(eventID, obj).then(data => {
      setWinnerList(data.data)
      setVisibleModal(false)
      setWaitingModal(true)
      setVisibleWinnerModal(true)
      setCheckedCategories([])
      setAllowToggle(false)
      })
  }

    const handleVideoTimeUpdate = () => {
    if (videoRef.current && videoRef.current.currentTime >= 8) {
      // eventsV2.randomDrawSubmitPopup().then(data => {
      setRerunDraw(true)
      setLoading(false)
      // })
    }
   }

  const onSearch = ({ target }) => {
    const searchValue = target?.value || ''
    setSearch(searchValue)
    setCurrentPage(1) // Reset the currentPage to 1 whenever the search input changes
  }

  const getWinnersDataForTab = (tabIndex, filteredData) => {
    const winnersData = filteredData[tabIndex]?.winners || []

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * pageSize
    const endIndex = startIndex + pageSize

    // Get the paginated winners for the current page
    const paginatedWinnersData = winnersData.slice(startIndex, endIndex)
    if (!loading) {
    return paginatedWinnersData.map(winner => ({
      ...winner.owner,
      number: winner.orderNumber,
      email: winner.owner.email,
      name: winner.owner.name,
      phone: winner.owner.phone,
      key: winner.orderNumber,
    }))
  }
  }
  const getResponsivePaginatedWinners = (tabIndex, filteredData) => {
    const winnersData = filteredData[tabIndex]?.winners || []
    const startIndex = (currentPage - 1) * responsivePageSize
    const endIndex = startIndex + responsivePageSize
    return winnersData.slice(startIndex, endIndex)
}

   const columnsDraw = [
    {
      title: '#',
      dataIndex: 'number',
      key: 'number',
      width: '20%',
    },
    {
      title: 'User',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      key: 'phone',
      width: '30%',
    },
   {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '30%',
    },
  ]

  const prepareCardData = data => [
    {
      title: 'User',
      text: data?.owner?.name,
    },
    {
      title: 'Mobile',
      text: data?.owner?.phone,
    },
    {
      title: 'Email',
      text: data?.owner?.email,
    },
    {
      title: 'Draw #',
      text: data?.orderNumber,
    },
  ]

   const tabs = (
     <Tabs type='card' className='customTabs' activeKey={`tab_${currentTab}`} onChange={handleTabChange}>
       {fullWinnerList.map((data, index) => (
         <TabPane tab={`D${index + 1}`} key={`tab_${index}`} />
      ))}
     </Tabs>
  )
  const undrawnTicketsBanner = currentWinnersCount !== currentWinnersLength
  const undrawnTicketsQuantity = currentWinnersCount - currentWinnersLength
 const catTabs = () => (
   <div className='wrapperTableHeader'>
     <div className='tableHeader'>
       {tabs}
       <Input
         prefix={<img alt='search icon' src={SearchIcon} />}
         className='inputSearch noFocusBorder responsiveInput'
         onChange={throttleFunc(onSearch)}
         placeholder='Search'
         value={searchInput}
         allowClear
       />
     </div>
     {undrawnTicketsBanner && !visibleWinnerModal &&
       <div className='incompleteDrawWrapper'>
         <div className='imageBlock'>
           <div className='incompleteDraw'>
             Incomplete Draw
           </div>
           <div className='undrawnQuantity'>You still have {undrawnTicketsQuantity} undrawn ticket(s)</div>
           <CustomButton
             text='Resume'
             className='btnHeader resumeBtn'
             onClick={handleResume}
             type='white'
           />
         </div>
       </div>}
     {!isResponsive &&
       <div className='informationWrapper'>
         {fullWinnerList.map((info, index) => {
        const ticketCategories = info.ticketCategories // Get the ticketCategories for the current tab
        if (index === currentTab) {
          return (
            <div key={index} className='info'>
              <div className='firstInformationWrapper'>
                <div>
                  <div className='categoryInfoWrapper'>
                    {ticketCategories.map((cat, index) => (
                      <div key={cat._id} className='categoryInfo'>
                        {index > 0 && ', '} {/* Добавляем пробел между категориями, кроме первой */}
                        {cat.categoryName}
                      </div>
                  ))}
                  </div>
                  <div className='allowInfo'>
                    {info.winOnes ? (
                      <span>Participants can only win one prize</span>
                    ) : (
                      <span>Participants may win more than one prize</span>
                    )}
                  </div>
                  <div className='prizeNoteTitle'>Prize note</div>
                  <div className='prizeNoteInfo'>{info.notes}</div>
                </div>
              </div>
              <div className='second-third-wrapper'>
                <div className='secondInformationWrapper'>
                  <div className='numberOfChancesTitle'>Number of Chances</div>
                  <div className='numberOfChances'>{info.chancesCount}</div>
                </div>
                <div className='thirdInformationWrapper'>
                  <div className='numberOfWinnersTitle'>Number of Winners</div>
                  <div className='numberOfWinners'>{info.winnersCount}</div>
                </div>
              </div>
            </div>
          )
        } else {
          return null // Return null for tabs other than the current tab
        }
      })}
       </div>}
   </div>
)
  return (
    <>
      {waitingModal &&
        <div className='gifWrapper'>
          <video ref={videoRef} width='540' height='330' autoPlay onTimeUpdate={handleVideoTimeUpdate}>
            <source src={drum} type='video/webm' />
          </video>
          {visibleWinnerModal && rerunDraw &&
            <div className='titleWrapper'>
              <div className='title'>Winner</div>
              {/* <div className='winnerNumber'>{winner.orderNumber + 1}.</div> */}
              {winnerList.currentWinner?.name === 'Guest'
                  ? <div className='winnerName'> {winnerList.currentWinner.email}</div>
                  : <div className='winnerName'>{winnerList.currentWinner?.name} <br /> {winnerList.currentWinner.email}</div>}
            </div>}
        </div>}
      <div className='wrapperRandomDraw'>
        <div className='draw-header'>
          <div className='draw-title'>Random Draw</div>
          <div className='wrapperBtn'>
            {visibleWinnerModal &&
              <CustomButton
                text='Finish Drawing'
                className='btnHeader whiteBtn'
                onClick={() => {
                setWaitingModal(false)
                setRerunDraw(false)
                setVisibleWinnerModal(false)
                }}
                type='white'
                disabled={loading}
              />}
            <CustomButton
              text={visibleWinnerModal ? 'Next Draw' : 'Start Drawing'}
              className='btnHeader'
              onClick={visibleWinnerModal ? handleRerunWinner : handleModal}
              type='dark'
              disabled={loading || rerunDraw && currentWinnersCount === currentWinnersLength}
            />
          </div>
        </div>
        {isResponsive
        ? (
          <>
            {loading ? (
              <Spin>
                {tabs}
                <div className='wrapperTableHeader'>
                  <div className='tableHeader'>
                    <Input
                      prefix={<img alt='search icon' src={SearchIcon} />}
                      className='inputSearch noFocusBorder responsiveInput'
                      onChange={throttleFunc(onSearch)}
                      placeholder='Search'
                      value={searchInput}
                      allowClear
                    />
                  </div>
                  {getResponsivePaginatedWinners(currentTab, filteredWinnersData).map(winner => (
                    <ResponsiveCards key={winner?._id} data={prepareCardData(winner)} />
          ))}
                </div>
                <Pagination
                  current={currentPage}
                  pageSize={responsivePageSize}
                  total={totalWinners}
                  showSizeChanger={false}
                  className='pagination'
                  onChange={onPageChange}
                />
                {/* )} */}
              </Spin>) : (
                <>
                  {tabs}
                  <div className='wrapperTableHeader'>
                    {undrawnTicketsBanner && !visibleWinnerModal &&
                      <div className='incompleteDrawWrapper'>
                        <div className='imageBlock'>
                          <div className='incompleteDraw'>
                            Incomplete Draw
                          </div>
                          <div className='undrawnQuantity'>You still have {undrawnTicketsQuantity} undrawn ticket(s)</div>
                          <CustomButton
                            text='Resume'
                            className='btnHeader resumeBtn'
                            onClick={handleResume}
                            type='white'
                          />
                        </div>
                      </div>}
                    <div className='tableHeader'>
                      <Input
                        prefix={<img alt='search icon' src={SearchIcon} />}
                        className='inputSearch noFocusBorder responsiveInput'
                        onChange={throttleFunc(onSearch)}
                        placeholder='Search'
                        value={searchInput}
                        allowClear
                      />
                    </div>
                    <div className='informationWrapper'>
                      {fullWinnerList.map((info, index) => {
        const ticketCategories = info.ticketCategories // Get the ticketCategories for the current tab
        if (index === currentTab) {
          return (
            <div key={index} className='info'>
              <div className='firstInformationWrapper'>
                <div>
                  <div className='categoryInfoWrapper'>
                    {ticketCategories.map((cat, index) => (
                      <div key={cat._id} className='categoryInfo'>
                        {index > 0 && ', '}
                        {cat.categoryName}
                      </div>
                  ))}
                  </div>
                  <div className='allowInfo'>
                    {info.winOnes ? (
                      <span>Participants can only win one prize</span>
                    ) : (
                      <span>Participants may win more than one prize</span>
                    )}
                  </div>
                  <div className='prizeNoteTitle'>Prize note</div>
                  <div className='prizeNoteInfo'>{info.notes}</div>
                </div>
              </div>
              <div className='second-third-wrapper'>
                <div className='secondInformationWrapper'>
                  <div className='numberOfChancesTitle'>Number of Chances</div>
                  <div className='numberOfChances'>{info.chancesCount}</div>
                </div>
                <div className='thirdInformationWrapper'>
                  <div className='numberOfWinnersTitle'>Number of Winners</div>
                  <div className='numberOfWinners'>{info.winnersCount}</div>
                </div>
              </div>
            </div>
          )
        } else {
          return null // Return null for tabs other than the current tab
        }
      })}
                    </div>
                    {getResponsivePaginatedWinners(currentTab, filteredWinnersData).map(winner => (
                      <ResponsiveCards key={winner?._id} data={prepareCardData(winner)} />
          ))}
                  </div>
                  <Pagination
                    current={currentPage}
                    pageSize={responsivePageSize}
                    total={totalWinners}
                    showSizeChanger={false}
                    className='pagination'
                    onChange={onPageChange}
                  />
                  {/* )} */}
                </>
              )}
          </>

        ) : fullWinnerList.length ? (
          <>
            {loading ? (
              <Spin>
                <Table
                  pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalWinners,
            onChange: onPageChange,
                  }}
                  dataSource={getWinnersDataForTab(currentTab, filteredWinnersData)}
                  columns={columnsDraw}
                  rowKey='id'
                  sticky
                  title={() => catTabs()}
                />
              </Spin>) : (<Table
                pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalWinners,
            onChange: onPageChange,
                }}
                dataSource={getWinnersDataForTab(currentTab, filteredWinnersData)}
                columns={columnsDraw}
                rowKey='id'
                sticky
                title={() => catTabs()}
                          />)}
          </>
  ) : (
    <div className='wrapperBodyEmpty'>
      <img src={NoEventsInformation} alt='Information Logo' />
      <div className='informationDescription'>Start your drawing by clicking the button above</div>
    </div>
  )}
      </div>
      <ModalPickWinner
        visible={visibleModal}
        updateVisible={handleModal}
        submitForm={handlePickWinner}
        checkedCategories={checkedCategories}
        setCheckedCategories={setCheckedCategories}
        allowToggle={allowToggle}
        setAllowToggle={setAllowToggle}
        loading={loading}
      />
    </>
  )
}

export default RandomDraw
