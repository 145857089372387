export const SESSION_STATE = 'session'

export const SESSION_IS_MOBILE = 'session/SESSION_IS_MOBILE'
export const SESSION_LOGIN = 'session/SESSION_LOGIN'
export const SESSION_LOGOUT = 'session/SESSION_LOGOUT'
export const SESSION_SKIP_TUTORIAL = 'session/SESSION_SKIP_TUTORIAL'
export const SUBSCRIBE_UPDATE = 'session/SUBSCRIBE_UPDATE'
export const LOGO_FOR_VENUE_UPDATE = 'session/LOGO_FOR_VENUE_UPDATE'
export const BANK_DETAILS_UPDATE = 'session/BANK_DETAILS_UPDATE'
export const SUBSCRIPTIONS_UPDATE = 'session/SUBSCRIPTIONS_UPDATE'
export const PURCHASES_UPDATE = 'session/PURCHASES_UPDATE'
export const ADMIN_BANK_DETAILS = 'session/ADMIN_BANK_DETAILS'
export const ADMIN_BANK_DETAILS_CLEAR = 'session/ADMIN_BANK_DETAILS_CLEAR'
export const SESSION_GUEST_RESERVATION_TOKEN = 'session/SESSION_GUEST_RESERVATION_TOKEN'
