import React, { useState } from 'react'
import { eventsV2 } from 'apiv2'
import {
  dereserveSeats,
  guestReserveSeats,
  guestDereserveSeats,
  eventsUnreserveSeat,
} from 'modules/events/events-actions'
import moment from 'moment-timezone'

import { notification } from 'antd'
import { useParams } from 'react-router-dom'
import './index.styl'
import { useDispatch } from 'react-redux'

const RowSeats = ({ seat, isUser, sectionName, sectionID, sectionType, color, group, selectSeatArray, selectedSeat, timeZone }) => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const [guestReservationToken, setGuestReservationToken] = useState(null)
  const isSeatSelected = !!selectedSeat.find(item => item.seatId === seat.id)
  function removeObjectWithId (arr, id) {
    return arr.filter(obj => obj.seatId !== id)
  }

  const currentDateTime = moment().tz(timeZone)
  const earlyBirdDateTime = moment.tz(seat?.earlyBirdDate, 'YYYY-MM-DD', timeZone)

  const isEarlyBirdActive = seat.category?.isEarlyBird && currentDateTime.isSameOrBefore(earlyBirdDateTime)

  const currentPrice = isEarlyBirdActive ? seat.category?.earlyBirdPrice : seat.category.price

  const handleClickBuySeat = () => {
    const obj = { eventID: id, seatID: seat.id }
    const dereserveGuestObj = { eventID: id, seatID: seat.id, guestReservationToken }
    if (isUser) {
      if (isSeatSelected) {
        dispatch(dereserveSeats(obj)).then(res => {
          dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seat.id }))
          selectSeatArray(removeObjectWithId(selectedSeat, seat.id))
        })
      } else {
        eventsV2.reserveSeats(obj).then(() => {
          notification.success({ message: 'Your seat will be reserved for you for 5 minutes' })
          selectSeatArray([...selectedSeat, { seatId: seat.id, sectionName, groupId: group.id, sectionId: sectionID, sectionType, rowName: group.groupName, seatName: seat.seatName, price: currentPrice, priceWithoutEarlyBird: seat.category.price, seatCategoryID: seat.category.ticketCategoryID, isActive: true, earlyBirdDate: seat.category?.earlyBirdDate, isEarlyBird: seat.category?.isEarlyBird, earlyBirdPrice: seat.category?.earlyBirdPrice }])
        }).catch(error => notification.warning({ message: error?.response?.data?.message || error?.response?.data?.error }))
      }
    } else {
      if (isSeatSelected) {
        dispatch(guestDereserveSeats(dereserveGuestObj)).then(res => {
          dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seat.id }))
          selectSeatArray(removeObjectWithId(selectedSeat, seat.id))
        })
      } else {
        dispatch(guestReserveSeats(obj)).then(res => {
          setGuestReservationToken(res?.data?.guestReservationToken)
          notification.success({ message: 'Your seat will be reserved for you for 5 minutes' })
          selectSeatArray([...selectedSeat, { seatId: seat.id, guestReservationToken: res?.data?.guestReservationToken, sectionName, groupId: group.id, sectionId: sectionID, sectionType, rowName: group.groupName, seatName: seat.seatName, price: currentPrice, priceWithoutEarlyBird: seat.category.price, seatCategoryID: seat.category.ticketCategoryID, isActive: true, earlyBirdDate: seat.category?.earlyBirdDate, isEarlyBird: seat.category?.isEarlyBird, earlyBirdPrice: seat.category?.earlyBirdPrice }])
        })
      }
    }
  }

  return (
    <div key={seat.index} className='rectangleBuySeat'>
      {seat.isReserved && !isSeatSelected
        ? (
          <div
            className='orderSeat'
            style={
              color === '#FFFF00' || color === '#FFCC00' || color === '#FF9900' || color === '#FF6600' || color === '#99CC00' || color === '#99FF00' || color === '#33FF00' || color === '#33CC00'
                ? { background: color, color: 'black' }
                : { background: color }
            }
          >
            {seat.seatName}
          </div>)
        : seat.isDisabled
          ? <div className='disableSeat' />
          : (
            <div
              onClick={handleClickBuySeat}
              className={!isSeatSelected ? 'rectangleItem' : 'rectangleItemActive'}
              style={
                color === '#FFFF00' || color === '#FFCC00' || color === '#FF9900' || color === '#FF6600' || color === '#99CC00' || color === '#99FF00' || color === '#33FF00' || color === '#33CC00'
                  ? { background: color, color: 'black' }
                  : isSeatSelected
                    ? { background: '#fff', color: 'black' }
                    : { background: color }
              }
            >
              {seat.seatName}
            </div>)}
    </div>
  )
}

export default RowSeats
