import React, { useEffect, useState } from 'react'
import { Form, Switch } from 'antd'
import UpdatedTicketCategory from '../../UpdatedTicketCategory'
import TooltipInfo from '../../../../../CreateEventV2/TooltipInfo'

const { Item, List } = Form
const LiveStreamCategory = ({ currentEventDetails }) => {
  const [liveStreamSwitch, setLiveStreamSwitch] = useState(false)
  const donationTicketHandler = () => {
    setLiveStreamSwitch(!liveStreamSwitch)
  }
  const tooltipTitle = 'Streamed events require a deposit. You will be prompted to pay upon making the Tickets.'
  const additionalTooltip = (
    <TooltipInfo
      className='tooltipTitle'
      text='Additional fees apply'
      info={tooltipTitle}
    />
  )
  useEffect(() => {
    if (!!currentEventDetails?.liveStream?.length) {
      setLiveStreamSwitch(true)
    }
  }, [currentEventDetails])
  return (
    <div className='typeCategoryWrapper'>
      <div className='wrapperTitleRadioText'>
        <div className='titleWrapper'>
          <div className='title'>Live Stream</div>
          <div className='tooltipWrapper'>{additionalTooltip}</div>
        </div>
        <div className='radioItem'>
          <Item style={{ marginBottom: 0 }} name='liveStreamSwitch' valuePropName='checked' className='item'>
            <Switch onChange={donationTicketHandler} defaultChecked={liveStreamSwitch} />
          </Item>
          <div className='text'>Enable Live Stream</div>
        </div>
      </div>
      {liveStreamSwitch &&
        <List name='liveStream'>
          {(fields, { add, remove }) => (
            <div className='updatedCategoriesWrapper'>
              {fields.map((field, i) =>
                <div key={field.fieldKey}>
                  <UpdatedTicketCategory
                    categoryType='Live Stream'
                    namePrefix={i}
                    fieldKey={field.fieldKey}
                    switchToggleOff={setLiveStreamSwitch}
                    fieldsLength={fields.length}
                    onRemove={remove}
                  />
                </div>,
              )}
              {fields.length === 0 && (
                <div key='defaultForm'>
                  {/* Render default form when no categories exist */}
                  <UpdatedTicketCategory
                    categoryType='Live Stream'
                    namePrefix={0}
                    fieldsLength={fields.length + 1}
                    switchToggleOff={setLiveStreamSwitch}
                    onRemove={remove}
                  />
                </div>
              )}
            </div>)}
        </List>}
    </div>
  )
}

export default LiveStreamCategory
