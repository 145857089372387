import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import stream from 'images/eventsIcons/LivestreamPPV.svg'
import live from 'images/eventsIcons/VenueAttended.svg'
import virtual from 'images/eventsIcons/VirtualMeating.svg'
import liveStream from 'images/eventsIcons/LiveStream.svg'
import './index.styl'

const categoriesImg = { live, virtual, stream, liveStream }
const categories = [
  {
    key: 'liveStream',
    label: <><div>Ticketed Admission +</div><div>Livestream</div></>,
  },
  { key: 'live', label: 'Ticketed Admission' },
  { key: 'stream', label: 'Livestream' },
  { key: 'virtual', label: 'Virtual Meeting' },
]

const categoryConfig = {
  virtual: { event: 'virtual' },
  live: { event: 'live' },
  stream: { event: 'stream' },
  liveStream: { event: 'live', ppvEnabled: true },
}

const getInitialEventType = (type, isStream) => type === 'live' && isStream ? 'liveStream' : type

const EventsCheckbox = ({ eventType, setEventType, disabled, venuePromoterOff, isStream, setIsStream, role, isVenueEvent }) => {
  const [checked, setChecked] = useState(getInitialEventType(eventType, isStream))

  useEffect(() => {
    setChecked(getInitialEventType(eventType, isStream))
  }, [eventType, isStream])
  const classVanue = role?.includes('venue') && !venuePromoterOff || isVenueEvent ? 'venue' : ''
  const categoriesVenue = [
    {
      key: 'liveStream',
      label: <><div className={classVanue}>Ticketed Admission + </div><div className={classVanue}>Livestream</div></>,
    },
    { key: 'live', label: 'Ticketed Admission' },
  ]
  const onChange = ({ target: { value } }) => {
    setChecked(value)
    setEventType(categoryConfig[value]?.event)
    setIsStream(!!categoryConfig[value]?.ppvEnabled)
  }

  const categoriesRole = () => {
    return role?.includes('venue') && !venuePromoterOff || isVenueEvent ? categoriesVenue : categories
  }

  return (
    <div className='rootEventsCheckbox'>
      <Radio.Group value={checked} onChange={onChange} className='radioContainer' disabled={disabled}>
        {categoriesRole().map(({ key, label }) => (
          <Radio key={key} value={key} className='radioItem'>
            <div className={`radioItemContainer ${role?.includes('venue') && !venuePromoterOff || isVenueEvent ? 'fullImg' : ''}`}>
              <img src={categoriesImg[key]} alt='' className='categoryImg' />
              <div className='categoryLabel'>{label}</div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  )
}

export default EventsCheckbox
