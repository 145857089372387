import React from 'react'
import './index.styl'
import TableSeats from '../TableSeats'
const SectionTable = ({ loading, blockSaleInitial, seatsFree, setLoading, seatID, templateID, categoryID, categoriesArray, seatName, color, seatCount }) => {
  return (
    <div>
      <TableSeats blockSaleInitial={blockSaleInitial} seatsFree={seatsFree} categoryID={categoryID} loading={loading} setLoading={setLoading} seatID={seatID} templateID={templateID} categoriesArray={categoriesArray} seatName={seatName} color={color} seatCount={seatCount} />
    </div>
  )
}

export default SectionTable
