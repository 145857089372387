import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import calendarIcon from 'images/Calendar.svg'
import placeIcon from 'images/Place.svg'
import locationIcon from 'images/Location.svg'
import timeIcon from 'images/Time.svg'
import arrow from 'images/simpleArrow.svg'
import './index.styl'

const DetailsList = ({ event }) => {
  const { address, startDate, endDate, eventName, imageUrl, venue } = event || {}

  const eventData = [
    { title: venue || ' ', icon: placeIcon },
    { title: address || ' ', icon: locationIcon },
    { title: startDate ? moment(startDate).format('MMMM DD, YYYY') : ' ', icon: calendarIcon },
    { title: endDate && startDate ? `${moment(startDate).format('hh:mm A')} - ${moment(endDate).format('hh:mm A')}` : ' ', icon: timeIcon },
  ]
  return (
    <div className='eventDetails'>
      <div className='linkWrapper xsLinkHidden'>
        <Link to='/scanner'>
          <img src={arrow} alt='arrow' className='headerBackArrow' />
          <span>Back</span>
        </Link>
      </div>
      <div className='eventName'>{eventName || ' '}</div>
      <img className='eventImage' src={imageUrl?.original} alt=' ' />
      <ul className='details-list'>
        {eventData.map(({ title, icon }, idx) => (
          <li key={idx} className='details-list-item'>
            <img src={icon} alt='icon' />
            <span className='list-item'>{title}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DetailsList
