import React from 'react'
import MSImage from 'images/MSDefaultImage.png'

import './index.styl'

const AnnouncementCard = ({ announcements }) => {
  return (
    <div className='announcementCardWrapper'>
      <div className='title'>Announcements</div>
      <div className='announcementCards'>
        {announcements?.map((announce, key) => (
          <div className='announcementCard' key={key}>
            <div className='annoucementImageContentWrapper'>
              <img src={announce?.imageUrl?.thumbnail1 || MSImage} alt='image' />
              <div>
                <div className='name'>{announce.title}</div>
                <div className='description'>{announce.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnnouncementCard
