import React, { useContext, useEffect, useState } from 'react'
import { CreateEventContext } from '../../../index'

import { handleConfirmModal } from 'utils'

import './index.styl'
import GeneralAdmissionItems from './components/GeneralAdmissionItems'
import RaffleItems from './components/RaffleItems'
import VirtualMeetingItems from './components/VirtualMeetingItems'
import LiveStreamItems from './components/LiveStreamItems'
import TableItems from './components/TableItems'
import ItemTicketItems from './components/ItemTicketItems'

// import { useSelector } from 'react-redux'
// import isInteger from 'lodash/isInteger'

// const isStream = (namePrefix, eventType) => namePrefix === 'streamingCategory' || eventType === 'stream' && namePrefix === 'defaultCategory'

const UpdatedTicketCategory = ({ onRemove, fieldKey, switchToggleOff, categoryType, namePrefix, fieldsLength }) => {
  const { form, currentEventDetails } = useContext(CreateEventContext)
  const [sectionNumber, setSectionNumber] = useState(1)

  useEffect(() => {
    setSectionNumber(fieldsLength)
  }, [fieldKey])

  const onRemoveCategory = () => {
    handleConfirmModal('Are you sure you want to remove this category', () => {
      onRemove(namePrefix)
      if (fieldsLength === 1) {
      // Set generalAdmissionSwitch to false
        switchToggleOff(false)
        if (categoryType === 'General Admission') {
          form.setFieldsValue({
            generalAdmissionSwitch: false,
          })
        }
        if (categoryType === 'Live Stream') {
          form.setFieldsValue({
            liveStreamSwitch: false,
          })
        }
        if (categoryType === 'Raffle') {
          form.setFieldsValue({
            raffleSwitch: false,
          })
        }
        if (categoryType === 'Item Ticket') {
          form.setFieldsValue({
            itemTicket: false,
          })
        }
        if (categoryType === 'Table') {
          form.setFieldsValue({
            tableSwitch: false,
          })
        }
        if (categoryType === 'Virtual Meeting') {
          form.setFieldsValue({
            virtualMeetingSwitch: false,
          })
        }
      }
    })
  }
  const getTicketDataGeneralAdmission = idx => {
    const categories = form.getFieldValue('generalAdmission')
    return categories[idx]
  }
  const getTicketDataRaffle = idx => {
    const categories = form.getFieldValue('raffle')
    return categories[idx]
  }
  const getTicketDataLiveStream = idx => {
    const categories = form.getFieldValue('liveStream')
    return categories[idx]
  }
  const getTicketDataTable = idx => {
    const categories = form.getFieldValue('table')
    return categories[idx]
  }
  const getTicketDataVirtualMeeting = idx => {
    const categories = form.getFieldValue('virtualMeeting')
    return categories[idx]
  }
  const getTicketDataItemTicket = idx => {
    const categories = form.getFieldValue('itemTicket')
    return categories[idx]
  }

  const sectionName = `Section ${sectionNumber}`

  return (
    <div className='categoryTypeWrapper'>
      {categoryType === 'General Admission' &&
        <GeneralAdmissionItems categoryData={getTicketDataGeneralAdmission(fieldKey)} currentEventDetails={currentEventDetails} onRemoveCategory={onRemoveCategory} namePrefix={namePrefix} form={form} />}
      {categoryType === 'Raffle' &&
        <RaffleItems categoryData={getTicketDataRaffle(fieldKey)} currentEventDetails={currentEventDetails} namePrefix={namePrefix} form={form} />}
      {categoryType === 'Table' &&
        <TableItems sectionName={sectionName} categoryData={getTicketDataTable(fieldKey)} onRemoveCategory={onRemoveCategory} currentEventDetails={currentEventDetails} namePrefix={namePrefix} form={form} />}
      {categoryType === 'Virtual Meeting' &&
        <VirtualMeetingItems categoryData={getTicketDataVirtualMeeting(fieldKey)} currentEventDetails={currentEventDetails} namePrefix={namePrefix} form={form} />}
      {categoryType === 'Item Ticket' &&
        <ItemTicketItems categoryData={getTicketDataItemTicket(fieldKey)} currentEventDetails={currentEventDetails} onRemoveCategory={onRemoveCategory} namePrefix={namePrefix} form={form} />}
      {categoryType === 'Live Stream' &&
        <LiveStreamItems categoryData={getTicketDataLiveStream(fieldKey)} currentEventDetails={currentEventDetails} namePrefix={namePrefix} form={form} />}

      <div className='btnWrapper'>
        <div onClick={() => onRemoveCategory()} className='removeBtn'>Delete Ticket Category</div>
      </div>
    </div>
  )
}

export default UpdatedTicketCategory
