import React from 'react'
import {
  Form,
} from 'antd'

import './index.styl'
import DateInput from './components/DateInput'

const { Item } = Form

const SingleDatePicker = ({ title, label, form, nameText, namePrefix, fromDate, getRainDate, setFromDate, msDate }) => {
  return (
    <div className='rainDateItemWrapper'>
      <div className='form-field'>
        <Item
          rules={[title === 'Rain Date' ? { required: false } : { required: true, message: `${title} is required!` }]}
          name={title === 'Rain Date' || title === 'Event Date' && namePrefix === undefined ? nameText : [namePrefix, nameText]}
          className={title === 'Rain Date' || title === 'Event Date' && namePrefix === undefined ? 'defaultInputLabel haveMargin rainDateInput' : 'earlyBirdDate'}
          label={label}
        >
          <DateInput msDate={msDate} nameText={nameText} namePrefix={namePrefix} getRainDate={getRainDate} form={form} title={title} value={fromDate} onChange={setFromDate} />
        </Item>

      </div>
    </div>
  )
}

export default SingleDatePicker
