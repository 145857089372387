export const MEMBERSHIPS_STATE = 'memberships'

export const PAGES_SET_ADDITIONAL_IMAGES = 'memberships/PAGES_SET_ADDITIONAL_IMAGES'
export const ANNOUNCEMENT_SET_IMAGES = 'memberships/ANNOUNCEMENT_SET_IMAGES'
export const BUY_SET_IMAGES = 'memberships/BUY_SET_IMAGES'
export const CHAT_SET_IMAGES = 'memberships/CHAT_SET_IMAGES'
export const GET_PAGE_DETAILS = 'memberships/GET_PAGE_DETAILS'
export const MEMBERSHIPS_GET_SOLD_MEMBER_DETAILS = 'memberships/MEMBERSHIPS_GET_SOLD_MEMBER_DETAILS'
export const MEMBERSHIP_GET_DETAILS_SUMMARY = 'memberships/MEMBERSHIP_GET_DETAILS_SUMMARY'
export const IS_MEMBERSHIP_MEMBER = 'memberships/IS_MEMBERSHIP_MEMBER'
export const EVENTS_GET_MEMBER_PUBLIC_EVENTS = 'memberships/EVENTS_GET_MEMBER_PUBLIC_EVENTS'
export const EVENTS_SET_MEMBER_HOME_PUBLIC_EVENTS = 'memberships/EVENTS_SET_MEMBER_HOME_PUBLIC_EVENTS'
export const EVENTS_SET_ACTIVE_PARTICIPANTS = 'memberships/EVENTS_SET_ACTIVE_PARTICIPANTS'
