import React, { useState } from 'react'
import {
  Radio,
  Form,
} from 'antd'
import ChooseTemplateList from '../ChooseTemplateList'
import './index.styl'
import ManualCreationSeating from '../ManualCreationSeating'
const { Item } = Form
const SeatingCreationMode = ({ form, templateCover, setTemplateCover }) => {
  const [selectedOption, setSelectedOptions] = useState('default')
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
  }
  return (
    <div className='creationModeWrapper'>
      <Item
        name='chooseCreation'
        className='chooseCreation'
        initialValue={selectedOption}
      >
        <Radio.Group onChange={onValueChange} value={selectedOption}>
          <Radio value='default'>
            {' '}
            No Assigned and/or Table Seating
          </Radio>
          <Radio value='choose'>
            {' '}
            Choose from template
          </Radio>
          <Radio value='manual'>
            {' '}
            Manual creation
          </Radio>
        </Radio.Group>
      </Item>
      {selectedOption === 'choose' ? (
        <ChooseTemplateList />
      ) : selectedOption === 'manual' ? (
        <ManualCreationSeating setTemplateCover={setTemplateCover} templateCover={templateCover} form={form} />
      ) : null}
    </div>
  )
}

export default SeatingCreationMode
