/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable indent */
import React, { useContext } from 'react'
import {
  Form,
  Input,
  DatePicker,
  Select,
} from 'antd'
import moment from 'moment'
import { timeZones } from 'utils'
import { notEqual } from 'modules/utils/validators'
import { useSelector } from 'react-redux'
import { CreateEventContext } from '../'
import { getRule } from '../../TicketOrder/TicketForms/formHelpers'
import calendarIcon from 'images/calendarIcon.svg'
import c from 'classnames'
import './index.styl'

const { Item } = Form
const { Option } = Select

const DetailsEventVenue = ({ eventId, error }) => {
  const { user: { venueName, address, email } } = useSelector(state => state.session)
  const { form, currentEventDetails } = useContext(CreateEventContext)
  const validateStartTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('Start time should be later than now.'))
      }
      const _end = form.getFieldValue('timeEnd')
      if (value && _end && moment(_end).isSameOrBefore(value, 'minutes')) {
        return Promise.reject(new Error('Start time should be earlier than end time.'))
      }
      return Promise.resolve()
    },
  }

  const validateEndTime = {
    validator (_, value) {
      if (value && moment(value).isSameOrBefore()) {
        return Promise.reject(new Error('End time should be later than now.'))
      }
      const _start = form.getFieldValue('timeStart')
      if (value && _start && moment(_start).isSameOrAfter(value, 'minutes')) {
        return Promise.reject(new Error('End time should be after start time.'))
      }
      return Promise.resolve()
    },
  }

  const handleEndTimeDisabled = currentDate => {
    const _start = form.getFieldValue('timeStart')
    if (_start && currentDate) {
      currentDate = moment(currentDate).endOf('day')
      return moment(currentDate).isBefore(_start, 'hours')
    }
    if (currentDate) {
      return currentDate < moment().startOf('day')
    }
  }

  const dateIcon = <img src={calendarIcon} alt=' ' />
  return (
    <div className='wrapperDetailsEvent wrapperDetailsEventVenue'>
      <div className='containerTitle'>Give some details for your ticket</div>
      <div className='defaultValueContainer'>
        <div className='label'>Venue Name</div>
        <div className='defaultValue'>{venueName}</div>
      </div>
      <div className='defaultValueContainer'>
        <div className='label'>Location</div>
        <div className='defaultValue'>{address}</div>
      </div>

      <Item
        rules={[{ required: true, message: 'Enter Event name' }]}
        label='Event Name'
        className='item'
        name='eventName'
        normalize={v => v.trimStart()}
      >
        <Input placeholder='Enter event name' className='input' />
      </Item>
      <Item
        className='item timezoneSelect'
        name='timeZone'
        label='Time Zone'
        rules={[{ required: true, message: 'Enter Time Zone' }]}
      >
        <Select placeholder='Select a timezone' listHeight={300}>
          {timeZones.map(({ value, label }) => (
            <Option key={value} value={value}>{label}</Option>
          ))}
        </Select>
      </Item>
      <div className='groupItems'>
        <Item
          className='date'
          initialValue={currentEventDetails && moment(currentEventDetails?.startDate, 'YYYY-MM-DD h:mm A')}
          rules={[{ required: true, message: 'Enter Start Date' }, validateStartTime]}
          label='Start Date and Time'
          name='startDate'
        >
          <DatePicker
            disabledDate={currentDate => currentDate && currentDate < moment().startOf('day')}
            className={c('input', 'inputDateMargin', 'inputDate', 'date')}
            showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
            getPopupContainer={node => node?.parentNode}
            format='YYYY-MM-DD h:mm A'
            suffixIcon={dateIcon}
          />
        </Item>
        <Item
          initialValue={currentEventDetails && moment(currentEventDetails?.endDate, 'YYYY-MM-DD h:mm A')}
          rules={[{ required: true, message: 'Enter End Date' }, validateEndTime]}
          label='End Date and Time'
          name='endDate'
        >
          <DatePicker
            className={c('input', 'inputDateMargin', 'inputDate', 'date')}
            showTime={{ defaultValue: moment('00:00', 'HH:mm A') }}
            getPopupContainer={node => node?.parentNode}
            disabledDate={handleEndTimeDisabled}
            format='YYYY-MM-DD h:mm A'
            suffixIcon={dateIcon}
          />
        </Item>
      </div>
      <Item
        rules={[{ required: true, message: 'Enter Co-Promoter’s Name' }]}
        label='Co-promoter’s Name'
        initialValue={currentEventDetails?.promoterDetails.name}
        className={`${error?.name && 'errorItem'} item`}
        name='promotersName'
        normalize={v => v.trimStart()}
      >
        <Input placeholder='Enter Co-promoter’s name' className={`${error?.name && 'error'} input`} />
      </Item>
      {error?.name && <div className='errorText'>Enter Co-promoter’s name</div>}
      <Item
        validateTrigger='onBlur'
        rules={[
            { required: true, message: 'Enter Co-Promoter’s Email' },
            { type: 'email', message: 'Please enter correct email', transform: value => value?.trim() },
             notEqual(email, "You can't send draft event to yourself"),
        ]}
        initialValue={currentEventDetails?.promoterDetails.email}
        label='Co-promoter’s Email'
        className={`${error?.email && 'errorItem'} item`}
        name='promotersEmail'
        normalize={v => v.trimStart()}
      >
        <Input className={`${error?.email && 'error'} input`} placeholder='Enter co-promoter’s Email' />
      </Item>
      {error?.email && <div className='errorText'>Enter Co-promoter’s Email</div>}
      <Item
        name='promotersMobile'
        label='Co-promoter’s Phone Number'
        className={`${error?.mobile && 'errorItem'} item`}
        rules={[{ required: true, message: 'Enter Co-Promoter’s Phone Number' }, getRule('Phone number', 'mobile')]}
        initialValue={currentEventDetails?.promoterDetails.phone.slice(2)}
        validateTrigger='onBlur'
      >
        <Input prefix='+1' className={`${error?.mobile && 'error'} input`} placeholder='(123) 456-7890' type={currentEventDetails ? 'text' : 'number'} />
      </Item>
      {error?.mobile && <div className='errorText'>Enter Co-promoter’s mobile</div>}
    </div>
  )
}

export default DetailsEventVenue
