/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable indent */
import React, { useEffect, useState, useContext } from 'react'
import {
  Form,
  Input,
  Radio,
  Divider,
  Checkbox,
  InputNumber,
} from 'antd'
import { isVenue } from 'utils'
import { CreateEventContext } from '../'
import { useSelector } from 'react-redux'
import './index.styl'

const { Item } = Form

const Fees = ({ eventId, currentEventDetails, venuePromoterOff, nonProfitOrganization, setNonProfitOrganization }) => {
  const { form } = useContext(CreateEventContext)
  const [selectedOption, setSelectedOptions] = useState('User')
  const { user } = useSelector(state => state?.session)
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
  }
  useEffect(() => {
    form.setFieldsValue({
     eventPayout: currentEventDetails?.eventPayout || 'venue',
   })
    form.setFieldsValue({
      TLFee: currentEventDetails?.fees?.TLFee || 'buyer',
    })
    form.setFieldsValue({
      stripeVenmoFee: currentEventDetails?.fees?.paymentProcessorFee || 'buyer',
    })
    form.setFieldsValue({ taxNumber: nonProfitOrganization ? currentEventDetails?.saleTax?.EIN : '' })
    if (nonProfitOrganization) {
      form.setFieldsValue({ saleTaxPercent: '' })
    }
  }, [nonProfitOrganization])
  return (
    <div className='wrapperDetailsEvent wrapperDetailsEventVenue'>
      {isVenue(user?.role) && !venuePromoterOff &&
        <Item
          initialValue={currentEventDetails?.eventPayout}
          name='eventPayout'
          label='Event Payout'
        >
          <Radio.Group onChange={onValueChange} value={selectedOption}>
            <Radio value='venue'>
              {' '}
              Venue
            </Radio>
            <Radio value='promoter'>
              {' '}
              Co-promoter
            </Radio>
          </Radio.Group>
        </Item>}
      <div className='containerTitle'>Charge the following Fees to</div>
      <Item
        initialValue={currentEventDetails?.fees?.TLFee}
        name='TLFee'
        label='Ticket Lyfe Fee'
      >
        <Radio.Group className={(isVenue(user?.role)) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent ? 'disableButton' : ''} disabled={isVenue(user?.role) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent} onChange={onValueChange} value={selectedOption}>
          <Radio disabled={isVenue(user?.role) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent} value='buyer'>
            {' '}
            Buyer of Ticket
          </Radio>
          <Radio disabled={isVenue(user?.role) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && !venuePromoterOff && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent} value='promoter'>
            {' '}
            Promoter
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        name='stripeVenmoFee'
        label='Payment Processing Fee'
        initialValue={currentEventDetails?.fees?.paymentProcessorFee}
      >
        <Radio.Group className={(isVenue(user?.role)) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && !venuePromoterOff && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent ? 'disableButton' : ''} disabled={isVenue(user?.role) && !venuePromoterOff || (isVenue(user?.role) && !venuePromoterOff && !currentEventDetails?.isVenueDraft) || (!isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueDraft) || isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueEvent} onChange={onValueChange} value={selectedOption}>
          <Radio value='buyer'>
            {' '}
            Buyer of Ticket
          </Radio>
          <Radio value='promoter'>
            {' '}
            Promoter
          </Radio>
        </Radio.Group>
      </Item>
      {(isVenue(user?.role) || currentEventDetails?.isVenueEvent) &&
        <>
          <Item
            style={{ marginBottom: 0 }}
            rules={[{ required: (!nonProfitOrganization), message: 'Enter sales taxes' }]}
            label='Sales Taxes %'
            className='item'
            name='saleTaxPercent'
            normalize={v => v.trimStart()}
            initialValue={currentEventDetails?.saleTax?.percent}
          >
            <Input
              disabled={currentEventDetails && !currentEventDetails?.isVenueDraft || !isVenue(user?.role) || nonProfitOrganization}
              placeholder='Enter Sales Taxes' className='input'
            />
          </Item>
          <Item
            style={{ marginBottom: '24px' }}
            name='saleTaxCoverage'
            initialValue='buyer'
          >
            <Radio.Group className={(isVenue(user?.role)) && !venuePromoterOff || (isVenue(user?.role) && !currentEventDetails?.isVenueDraft && !venuePromoterOff) || (!isVenue(user?.role) && currentEventDetails && !currentEventDetails?.isVenueDraft || nonProfitOrganization) ? 'disableButton' : ''} disabled={isVenue(user?.role) && !venuePromoterOff || nonProfitOrganization} onChange={onValueChange} value={selectedOption}>
              <Radio value='buyer'>
                {' '}
                Buyer of Ticket
              </Radio>
              <Radio value='promoter'>
                {' '}
                Promoter
              </Radio>
            </Radio.Group>
          </Item>
          <Divider style={{ marginTop: '0', marginBottom: '16px' }} />
          <Item
            name='nonProfitOrganization'
            className='item-checkbox'
          >
            <Checkbox
              disabled={isVenue() || currentEventDetails && !currentEventDetails?.isVenueDraft || !isVenue(user?.role) && currentEventDetails?.isVenueDraft}
              onChange={e => setNonProfitOrganization(e?.target?.checked)}
              checked={nonProfitOrganization}
            >Non Profit Organization</Checkbox>
          </Item>
          {(nonProfitOrganization || currentEventDetails?.saleTax?.nonProfit) &&
            <Item
              rules={[{ required: nonProfitOrganization, message: 'Enter EIN tax number' }]}
              label='EIN Tax Number'
              className='item'
              name='taxNumber'
            >
              <InputNumber
                disabled={currentEventDetails && !currentEventDetails?.isVenueDraft || !isVenue(user?.role) || !nonProfitOrganization}
                placeholder='Enter EIN Tax Number'
                className='input fullWidth'
              />
            </Item>}
        </>}
    </div>
  )
}

export default Fees
