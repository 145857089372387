import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import {
  getAssignTicketTables,
  getUnassignedTicketList,
  assignTicketsToTable,
  unassignTicketsFromTable,
  deleteAssignTicketSection,
  deleteTablesFromEvent,
  editTableSection,
  clearGetAssignTicketTables,
  setAssignTicketTables,
} from 'modules/events/events-actions'
import { Input, Avatar, notification, Popover, Badge, Skeleton } from 'antd'
import { CustomButton, OverflowTooltip } from 'components'
import { ModalEditTable, SelectWithoutBorder, ModalUsersSelect } from '../ASComponents'
import { UserOutlined, PlusOutlined } from '@ant-design/icons'
import Tables from 'images/Tables.svg'
import arrow from 'images/simpleArrow.svg'
import Settings from 'images/Settings.svg'
import close from 'images/close.svg'
import searchIcon from 'images/input-search.svg'
import { push } from 'modules/router'
import c from 'classnames'
import { uniq, orderBy } from 'lodash'
import { lightColors, handleConfirmModal } from 'utils'
import { reports } from 'apiv2'
import './index.styl'

const notify = message => notification.warning({ message })

const TablesTable = () => {
  const [isInit, setIsInit] = useState(false)
  const dispatch = useDispatch()
  const [tablesData, setTablesData] = useState({})
  const [currentSection, setCurrentSection] = useState(null)
  const [categories, setCategories] = useState({})
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [selected, setSelected] = useState([])
  const [editTable, setEditTable] = useState(null)
  const [loading, setLoading] = useState(false)
  const [modalUsers, setModalUsers] = useState(null)
  const [search, setSearch] = useState('')
  const [sortTag, setSortTag] = useState('asc')
  const [isSmall, setIsSmall] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const { eventID, sectionID } = useParams()
  const {
    unassignedTicketsData,
    assignTicketSection,
    currentEventDetails,
    unassignedTickets,
    tableArray,
  } = useSelector(state => state.events)

  const sorting = (sorted, by) => orderBy(sorted, 'fullName', by)

  const getIsLight = category => lightColors.includes(category)

  const downloadSeatingsReport = async () => {
    const result = await reports.reportByEvent(eventID)
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }

  const redirectTo = () => eventID ? push(`/my-box-office/event-details/assigned-seating/${eventID}`) : push('/')

  const normalizeSelected = (arr = [], replacer) => arr.map(i => i.replace(replacer, ''))

  const handleDeselectAll = () => {
    setSelectedGroup(null)
    setSelected([])
  }

  const setModalUsersData = ({ _id: id, seatsArray = [], tableCapacity, tableName }) => {
    setModalUsers(id ? { id, seatsLength: seatsArray?.length, tableCapacity, tableName } : null)
  }

  const handleUsersModalClose = () => {
    setModalUsers(null)
    handleDeselectAll()
  }

  const onAssignSelected = async () => {
    setLoading(true)
    if (selected.length > (modalUsers?.tableCapacity - modalUsers?.seatsLength)) {
      setLoading(false)
      return notify('Not enough space on this table')
    }
    try {
      await assignTickets(modalUsers?.id, normalizeSelected(selected, selectedGroup), modalUsers?.seatsLength)
      await dispatch(getAssignTicketTables(eventID, sectionID))
      await dispatch(getUnassignedTicketList(eventID))
      setLoading(false)
      handleUsersModalClose()
    } catch (e) {
      setLoading(false)
    }
  }
  const handleDeleteSection = () => {
    const onOk = () => {
      setLoading(true)
      dispatch(deleteAssignTicketSection({ templateID: currentEventDetails?.seatTemplateID, sectionID, eventID }))
        .then(() => {
          setLoading(false)
          redirectTo()
        })
        .catch(() => setLoading(false))
    }
    handleConfirmModal('Are you sure you want to remove this section?', onOk)
  }

  const fetchAsyncWrapper = () => {
    (async () => {
      dispatch(getAssignTicketTables(eventID, sectionID)).then(() => {
        dispatch(getUnassignedTicketList(eventID)).then(() => {
          setIsInit(true)
        }).catch(redirectTo)
      }).catch(redirectTo)
    })()
  }

  useEffect(() => {
    fetchAsyncWrapper()
    return () => dispatch(clearGetAssignTicketTables())
  }, [])

  useEffect(() => {
    setIsSmall(window.innerWidth <= 480)
    setIsMobile(window.innerWidth <= 768)
  }, [window.innerWidth])

  useEffect(() => {
    if (sectionID && assignTicketSection.length) {
      setCurrentSection(assignTicketSection.find(s => s._id === sectionID))
    }
  }, [assignTicketSection])

  useEffect(() => {
    if (currentEventDetails?.tickets?.length) {
      const cats = {}
      currentEventDetails.tickets.forEach(t => {
        cats[t.ticketType] = t?.bgColor
      })
      setCategories(cats)
    }
  }, [currentEventDetails])

  const filterTickets = (tickets, word = '') => {
    const isIncludes = (s = '') => s.toLowerCase().includes(word.toLowerCase())
    const filterFunc = s => {
      return isIncludes(s?.fullName) ||
        isIncludes(s?.firstName) ||
        isIncludes(s?.lastName) ||
        isIncludes(s?.ticketID?.ticketType) ||
        isIncludes(s?.subpromoter?.fullName) ||
        isIncludes(s?.subpromoter?.firstName) ||
        isIncludes(s?.subpromoter?.lastName)
    }
    return tickets.filter(filterFunc)
  }

  useEffect(() => {
    const tablesList = tableArray.reduce(function (acc, cur, i) {
      acc[i + '-col'] = cur
      return acc
    }, {})
    const userList = {
      tableName: 'Unassigned guests',
      id: 'BuyersList',
      seatsArray: sorting(search ? filterTickets(unassignedTickets, search) : unassignedTickets, sortTag),
    }
    handleDeselectAll()
    setTablesData({ userList, ...tablesList })
  }, [tableArray, unassignedTickets, search, sortTag])

  const toggleSelect = (event, id, group) => {
    event.preventDefault()
    if (selectedGroup === group || !selectedGroup) {
      !selectedGroup && setSelectedGroup(group)
      setSelected(selected.includes(id) ? selected.filter(i => i !== id) : [...selected, id])
    } else {
      setSelectedGroup(group)
      setSelected([id])
    }
  }
  const updateTableData = (name = '', seatCount) => {
    const callback = () => {
      setEditTable(null)
      setLoading(false)
      dispatch(getAssignTicketTables(eventID, sectionID))
    }
    setLoading(true)
    if (editTable?._id) {
      const obj = { seatCount, name, categoryID: editTable?.seatCategoryID, tableID: editTable?._id }
      dispatch(editTableSection(currentEventDetails?.seatTemplateID, obj)).then(callback).catch(() => setLoading(false))
    } else {
      const tableArray = JSON.stringify(name?.trim() ? [{ seatCount, name }] : [{ seatCount }])
      dispatch(setAssignTicketTables({ eventID, sectionID, tableArray })).then(callback).catch(() => setLoading(false))
    }
  }

  const assignTickets = async (tableID, tickets, position) => {
    await dispatch(assignTicketsToTable({
      seatsArray: JSON.stringify(tickets?.map((ticketPassID, idx) => ({
        position: position > 0 ? position + idx + 1 : idx + 1,
        ticketPassID,
      }))),
      tableID,
      eventID,
    }))
  }

  const unassignTickets = async (tableID, ticketsIds) => {
    await dispatch(unassignTicketsFromTable({
      seatsArray: JSON.stringify(ticketsIds?.map(ticketPassID => ({ ticketPassID }))),
      tableID,
      eventID,
    }))
  }

  const onDragEnd = async (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination, draggableId } = result
    const _selected = uniq([...normalizeSelected(selected, selectedGroup), draggableId])
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      if (destColumn.seatsArray.length + _selected.length > destColumn.tableCapacity) {
        return notify('Not enough space on this table')
      }
      if (destColumn.seatsArray.length === destColumn.tableCapacity) return
      const moved = [...sourceColumn.seatsArray].filter(i => _selected.includes(i._id))
      const rest = [...sourceColumn.seatsArray].filter(i => !_selected.includes(i._id))
      const mappedIds = arr => arr.map(i => ({ ...i, pending: true }))
      const newDest = [...destColumn.seatsArray, ...mappedIds(moved)]
      setColumns({
        ...columns,
        [source.droppableId]: { ...sourceColumn, seatsArray: rest },
        [destination.droppableId]: { ...destColumn, seatsArray: newDest },
      })
      if (destination.droppableId === 'userList') {
        await unassignTickets(sourceColumn._id, _selected)
      }
      if (destination.droppableId !== 'userList') {
        await assignTickets(destColumn._id, _selected, destColumn.seatsArray.length)
      }
      handleDeselectAll()
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.seatsArray]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({ ...columns, [source.droppableId]: { ...column, seatsArray: copiedItems } })
      handleDeselectAll()
    }
  }

  const renderUserItems = (item, columnId, tableID) => (provided, snapshot) => {
    const nickname = item?.fullName || `${item?.firstName || ''} ${item?.lastName || ''}`
    const ticketType = item?.ticketID?.ticketType || item?.ticketType
    const isPromote = item?.subpromoter
    const subPromoterName = item?.subpromoter?.fullName ||
      `${item?.subpromoter?.firstName || ''} ${item?.subpromoter?.lastName || ''}`
    const itemGroupId = `${item._id}${columnId}`
    const isUserList = columnId === 'userList'
    const classes = {
      active: snapshot.isDragging,
      selected: selected.includes(itemGroupId),
      isTable: !isUserList,
      pending: item?.pending,
      isPromote,
    }
    const unassignGuest = event => {
      event.preventDefault()
      event.stopPropagation()
      handleConfirmModal(
        'Are you sure you want to unassign this guest?',
        async () => await unassignTickets(tableID, [item._id]),
      )
    }
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={provided.draggableProps.style}
        className={c('userItem', classes)}
        onClick={event => toggleSelect(event, itemGroupId, columnId)}
      >
        <div className='flexContainer'>
          <Avatar size={isUserList ? 44 : 28} icon={<UserOutlined />} src={item?.avatar?.thumbnail1} className='userAvatar' />
          <div className='userInfo'>
            <div className='nickName'>{nickname.trim().length ? nickname : 'Guest User'}</div>
            {isUserList && (
              <>
                {isPromote && <div className='ticketInfo'>by {subPromoterName}</div>}
                <div className='ticketInfo'>{item?.email}</div>
                <div className='ticketInfo'>{item?.ticketID?.ticketType}</div>
              </>
            )}
          </div>
        </div>
        <div className='category' style={{ backgroundColor: categories[ticketType] }}>
          {isUserList && isPromote
            ? <div className={c('subPromoteTitle', { isLight: getIsLight(categories[ticketType]) })}>Subpromoter</div>
            : ''}
        </div>
        <div className='actionSection'>
          {isUserList ? '' : <img src={close} alt=' ' onClick={unassignGuest} />}
        </div>
        <Badge count={snapshot?.isDragging && selected.length > 1 ? selected.length : null} overflowCount={10}>
          <div />
        </Badge>
      </div>
    )
  }

  const renderTitle = title => {
    return (
      <div className='tablesTitle'>
        <img src={Tables} alt=' ' className='tablesTitleIcon' />{title}
      </div>
    )
  }

  const renderModalTitle = (sectionName, tableName) => {
    return (
      <>
        <div onClick={() => setModalUsers(null)} className='navigationHeaderTables'>
          <img src={arrow} alt='arrow' className='icon' />
          <span className='text'>Back to {sectionName}</span>
        </div>
        {renderTitle(tableName)}
      </>
    )
  }

  const popoverContent = table => {
    const unassignGuests = () => {
      handleConfirmModal(
        'Are you sure you want to unassign all users？',
        async () => {
          await dispatch(unassignTicketsFromTable({ eventID, tableID: table._id, clearAll: true }))
          await dispatch(getUnassignedTicketList(eventID))
        },
      )
    }
    const removeTable = () => {
      handleConfirmModal(
        'Are you sure you want to remove this table?',
        async () => {
          await dispatch(deleteTablesFromEvent({ eventID, sectionID, tableID: table._id }))
          await dispatch(getUnassignedTicketList(eventID))
        },
      )
    }
    return (
      <div className='actionPopover'>
        <div className='popoverBtn' onClick={() => setEditTable(table)}>Edit table</div>
        <div className='popoverBtn' onClick={unassignGuests}>Unassign all guests</div>
        <div className='popoverBtn popoverBtnRemove' onClick={removeTable}>Remove table</div>
      </div>
    )
  }

  const renderDroppable = (columnId, column, className) => (
    <Droppable droppableId={columnId} key={columnId}>
      {provider => (
        <>
          <div ref={provider.innerRef} {...provider.droppableProps} className={c(className, { empty: !column.seatsArray.length })}>
            {!!column.seatsArray.length
              ? column.seatsArray.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index} isDragDisabled={isMobile}>
                  {renderUserItems(item, columnId, column._id)}
                </Draggable>
              ))
              : (
                <div className='emptyContainer'>
                  <div className='emptyBox'>{columnId === 'userList' ? 'No guests available' : 'Drop guests here'}</div>
                </div>
              )}
            {provider.placeholder}
          </div>
          {isMobile && columnId !== 'userList' && (
            <div className={c('tablesFooter', { empty: !column.seatsArray.length })}>
              <CustomButton
                text='Add Guests'
                onClick={() => setModalUsersData(column)} disabled={loading || !isInit}
              />
            </div>
          )}
        </>
      )}
    </Droppable>
  )

  const usersListMap = ([columnId, column]) => {
    return (
      <div className='buyersList' key={columnId}>
        <div className='buyersListHeader'>
          <div className='buyersHeaderContainer'>
            <div className='buyersListName'>{column.tableName}</div>
            <div className='ticketsData'>
              {`${unassignedTicketsData.count} / ${unassignedTicketsData.totalTicketCount}`}
            </div>
          </div>
          <Input
            prefix={<img src={searchIcon} alt=' ' />}
            onChange={e => setSearch(e.target.value)}
            className='buyersListSearchField noFocusBorder'
            placeholder='Search by name, category, subpromoter'
            value={search}
            allowClear
          />
          <div className='buyersSubheaderContainer'>
            <SelectWithoutBorder sortTag={sortTag} setSortTag={setSortTag} disabled={loading} />
            <div className='buyersListSelectInfo'>
              Selected {selectedGroup === 'userList' ? selected.length : 0} of {column.seatsArray.length}
            </div>
          </div>
        </div>
        {renderDroppable(columnId, column, 'tableBuyersList')}
      </div>
    )
  }
  const tablesMap = ([columnId, column]) => {
    return (
      <div className='tablesBlock' key={columnId}>
        <div className='blockTitle'>
          <OverflowTooltip title={column.tableName} trigger='hover'>
            <div className='tableName'>{column.tableName}</div>
          </OverflowTooltip>
          <div className='tableSetting'>
            <span>{`${column.seatsArray.length} / ${column.tableCapacity}`}</span>
            <Popover
              overlayClassName='actionPopoverContainer'
              content={popoverContent(column)}
              getPopupContainer={node => node?.parentNode}
              placement='bottomRight'
              trigger='click'
            >
              <img src={Settings} alt=' ' />
            </Popover>
          </div>
        </div>
        {renderDroppable(columnId, column, 'tableBlock')}
      </div>
    )
  }
  return (
    <div className='AssignedSeatingsTables'>
      <div className='navigationHeaderTables'>
        <Link to={`/my-box-office/event-details/assigned-seating/${eventID}`}>
          <img src={arrow} alt='arrow' className='icon' />
          <span className='text'>Back to Assigned and/or Table Seating</span>
        </Link>
      </div>
      <div className='assignedSeatingsTablesHeader'>
        {renderTitle(currentSection?.sectionName)}
        <div className='actionsTables'>
          <CustomButton
            onClick={downloadSeatingsReport}
            disabled={loading || !isInit}
            text='Download report'
          />
          <CustomButton
            onClick={handleDeleteSection}
            text='Remove Section'
            disabled={loading || !isInit}
            type='dark'
          />
        </div>
      </div>
      <div className='tableSection'>
        <Skeleton loading={!isInit} active={!isInit} paragraph={{ rows: 15 }}>
          <DragDropContext onDragEnd={result => onDragEnd(result, tablesData, setTablesData)}>
            {!isMobile && Object.entries(tablesData).filter(([columnId]) => columnId === 'userList').map(usersListMap)}
            {modalUsers && (
              <ModalUsersSelect
                title={isSmall ? renderModalTitle(currentSection?.sectionName, modalUsers?.tableName) : null}
                onClose={handleUsersModalClose}
                onSubmit={onAssignSelected}
                loading={loading}
              >
                {Object.entries(tablesData).filter(([columnId]) => columnId === 'userList').map(usersListMap)}
              </ModalUsersSelect>
            )}
            <div className='tablesBlocksSection'>
              {Object.entries(tablesData).filter(([columnId]) => columnId !== 'userList').map(tablesMap)}
              <div className='tablesBlock creationBlock' onClick={() => setEditTable(true)}>
                <div className='creationBlockHeader' />
                <div className='creationBlockContainer'>
                  <div className='creationBlockAction'>
                    <PlusOutlined className='iconAction' />
                    <div>Add New Table</div>
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
        </Skeleton>
      </div>
      {editTable && (
        <ModalEditTable
          title={editTable?._id ? 'Edit table' : 'Create table'}
          onClose={() => setEditTable(null)}
          count={editTable?.tableCapacity || ''}
          name={editTable?.tableName || ''}
          updateData={updateTableData}
          isEdit={!!editTable?._id}
          loading={loading}
        />
      )}
    </div>
  )
}

export default TablesTable
