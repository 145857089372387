import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getEventV2, resetEvent, getEventIDByURL } from 'modules/events/events-actions'
import { Layout } from 'components'
import Header from './Header'
import Body from './Body'
import './index.styl'

const DetailTicket = () => {
  const { currentEvent } = useSelector(state => state.events)
  const dispatch = useDispatch()
  // const path = window.location.pathname.split('/')
  const { id, userId, customURL } = useParams()
  const getEventData = () => {
    if (customURL) {
      dispatch(getEventIDByURL(customURL))
    } else {
      dispatch(getEventV2(id, userId))
    }
  }
  useEffect(() => {
    getEventData()
    return () => {
      dispatch(resetEvent())
    }
  }, [id, userId])

  return (
    <Layout className='wrapperDetailTicket'>
      <Header />
      {currentEvent.id ? (
        <Body getEventData={getEventData} />
      ) : <div className='wrapperBodyTicket' />}
    </Layout>
  )
}

export default DetailTicket
