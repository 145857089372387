import isInteger from 'lodash/isInteger'

export const getRule = (label = '', name = '') => {
  const integerValidator = message => {
    return {
      validator (_, value) {
        if (value && (!isInteger(value))) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }
  const defaultRule = {
    required: true,
    message: 'Please enter ' + label.toLowerCase(),
  }
  switch (name) {
    case 'quantity':
      return [defaultRule, {
        type: 'number',
        message: 'Quantity must be between 1 and 1000',
        min: 1,
        max: 1000,
      }, integerValidator('Invalid number')]
    default: return [defaultRule]
  }
}
