import React, { useState } from 'react'
import { CustomButton } from 'components'
import './index.styl'
import DefaultImageTemplate from 'images/defaultTemplateMap.svg'
import { useDispatch } from 'react-redux'
import SectionColor from '../TemplateScheme/SectionColor'
import { editCategory } from 'modules/templates/templates-actions'
import {
  getEventDetails,
} from 'modules/events/events-actions'
import { ModalTemplate } from '../../../components'
import { useParams } from 'react-router-dom'
import EditTemplateImage from './EditTemplateImage'
import { Image } from 'antd'
const ColorTemplate = ({ loading, setLoading, templateID, isTemplate, className, ...props }) => {
  const { eventID } = useParams()
  const [visible, setVisible] = useState(false)
  const { categories, imageUrl } = props
  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)
  const dispatch = useDispatch()
  const onFinish = form => {
    const { templateCategories } = form
    onChangeLoading()
    const obj = { categories: templateCategories }
    dispatch(editCategory(templateID, obj)).then(() => {
      setLoading(false)
      onChange()
      dispatch(getEventDetails(eventID))
    }).catch(() => setLoading(false))
  }
  return (
    <div className={className || 'colorTemplate'}>
      <div>
        {categories?.map(category => {
          return (
            <div key={category.id}>
              <SectionColor className={className} category={category} />
            </div>
          )
        })}
        <CustomButton onClick={onChange} className='colorEditButton' text='Categories' type='white' />
      </div>
      <div className='templateMap'>
        <div className='imageContainer'>
          {imageUrl ? <Image className='image' preview={{ mask: 'see map', maskClassName: 'mapWrapper', src: imageUrl?.sourceImage }} src={imageUrl?.thumbnail1} /> : <Image className='image' preview={{ mask: 'see map', maskClassName: 'mapWrapper' }} src={DefaultImageTemplate} />}
        </div>
        <EditTemplateImage setLoading={setLoading} templateID={templateID} />
      </div>
      <ModalTemplate additionalProps={{ categoriesArray: categories, isTemplate: isTemplate }} visible={visible} onChange={onChange} onFinish={onFinish} name='categories' width={472} title='Edit Price Categories' loading={loading} />
    </div>
  )
}

export default ColorTemplate
