import React, { useState, createContext, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setEventsIsStream } from 'modules/events/events-actions'
import { notification, Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import { MemberLyfeLayout } from 'components'
import Header from './Header'
import TableEvents from './TableEvents'
import { MemberDetails } from './Pages'
import { useQuery, myBoxOfficeTabs, getMemberOfficeURL, isAdmin, isVenue } from 'utils'
import c from 'classnames'
import './index.styl'

export const MemberOfficeContext = createContext(null)

const MemberOffice = () => {
  const { userID } = useSelector(state => state.events.currentEventDetails)
  const { streamEvent } = useSelector(state => state?.events)
  const { _id, role } = useSelector(state => state.session.user)
  const query = useQuery()
  const _activeTab = query.get('activeTab')
  const filterDefault = myBoxOfficeTabs.find(tab => tab.key === _activeTab) || myBoxOfficeTabs[1]
  const [filterTag, setFilterTag] = useState(filterDefault)
  const [sortTag, setSortTag] = useState(query.get('orderBy') || 'DATE_DESC')
  const [searchTag, setSearchTag] = useState('')
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { push } = useHistory()
  const isTicketEdit = window.location.pathname.includes('member-edit')
  const isTableEvents = window.location.pathname.includes('table-members')
  const activeTab = window.location.pathname
    .split('/member-office/')[1]
    .split('/')[0]
    .replace(/[\-\/]/gi, ' ')
  const objContent = {
    'table members': () => <TableEvents />,
    'member details': () => <MemberDetails />,
  }

  const objHeader = {
    'table members': () => <Header />,
    'member details': () => { },
  }
  useEffect(() => {
    if (userID && _id && _id !== userID && isTicketEdit && !isAdmin(role) && !isVenue(role)) {
      push(getMemberOfficeURL(filterTag.key, sortTag))
      notification.warn({ message: "You don't have access to this page" })
    }
  }, [userID, _id])

  const contextValue = { filterTag, setFilterTag, sortTag, setSortTag, searchTag, setSearchTag, loading, setLoading }
  return (
    <MemberLyfeLayout className={c('wrapperMyBoxOffice', { isTicketEdit, isTableEvents })}>
      <MemberOfficeContext.Provider value={contextValue}>
        <div className='container containerV2'>
          {objHeader[activeTab]()}
          {objContent[activeTab]()}
        </div>
      </MemberOfficeContext.Provider>
      <Modal
        width='368px'
        height='266px'
        cancelText='Cancel'
        okText='Go to My Wallet'
        visible={streamEvent}
        onCancel={() => dispatch(setEventsIsStream(false))}
        onOk={() => {
          dispatch(setEventsIsStream(false))
          push('/my-member-wallet')
        }}
      >
        <div className='noteTicket'>Please note tickets have been added to your wallet</div>
      </Modal>
    </MemberLyfeLayout>
  )
}

export default MemberOffice
