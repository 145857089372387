import React from 'react'
import { Skeleton } from 'antd'
import Card from './Card'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const EventsList = ({ events, loading }) => {
  return (
    <div className='eventsListContainer'>
      <div className='headerContainer'>
        <div className='title'>Scanner</div>
        <div className='sub-title'>Select an event to start scanning</div>
      </div>
      <Skeleton loading={loading} active paragraph={{ rows: 8 }}>
        {events.length ? (
          <div className='wrapperCards'>
            {events.map((i, ind) => <Card key={ind} event={i} />)}
          </div>
        ) : (
          <div className='wrapperBodyEmpty'>
            <img src={NoEventsInformation} alt='Information Logo' />
            <div className='informationDescription'>No Events available yet</div>
          </div>
        )}
      </Skeleton>
    </div>
  )
}

export default EventsList
