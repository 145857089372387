import api from './axios'
import { eventsV2 } from '../apiv2'

const getEvents = ({ type, eventType = 'ACTIVE', order = 'NAME_ASC', search = '', limit = 0, skip = 0 }) => {
  if (search) {
    return api(`user/myBoxOffice?type=${type}&eventType=${eventType}&limit=${limit}&skip=${skip}&order=${order}&search=${search}`, 'GET')
  } else {
    return api(`user/myBoxOffice?type=${type}&eventType=${eventType}&limit=${limit}&skip=${skip}&order=${order}`, 'GET')
  }
}

const getEventIDByUrl = eventURL => api(`event/getEventByURL?eventUrl=${eventURL}`, 'GET', {}, false)

const getFee = (eventID, type, ticketsArray, currency = 'USD', paymentSystem) => api(`event/getFees?payerType=${type}&eventID=${eventID}&tickets=${ticketsArray}&currency=${currency}&paymentType=${paymentSystem}`, 'GET', {}, false)
// const getFee = (eventID, type, ticketsArray, seatsArray, currency = 'USD', paymentSystem) => api(`event/getFees?payerType=${type}&eventID=${eventID}&tickets=${ticketsArray}&seats=${seatsArray}&currency=${currency}&paymentType=${paymentSystem}`, 'GET', {}, false)

const getVenmoToken = () => api('braintree/getToken', 'GET', {}, false)

const deleteEvent = obj => api('event/deleteEvent', 'DELETE', obj)

const getAssignTicketSection = eventId =>
  api(`event/getAssignTicketSection?eventID=${eventId}`, 'GET', {}, false)

const getAssignTicketSectionDetails = (eventID, sectionID) =>
  api(`event/getAssignTicketSection?eventID=${eventID}&sectionID=${sectionID}`, 'GET', {}, false)

const setAssignTicketSection = obj =>
  api('event/addAssignTicketSection', 'POST', obj)

const editTableSectionName = obj => api('event/editTableSectionName', 'POST', obj)

const giftTicket = obj => api('event/boxOfficeShare', 'POST', obj)

const getSoldTicketDetails = (eventID, ticketID, limit = 10, skip = 0, search) => api(`event/getSoldTicketDetailsV2?eventID=${eventID}&ticketID=${ticketID}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getInviteUsers = (ticketID, type, limit = 10, skip = 0, search) => api(`event/getInviteUsersV3?inviteType=${type}&limit=${limit}&skip=${skip}&ticketID=${ticketID}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getUnassignedTicketList = eventID => api(`event/getUnassignedTicketList?eventID=${eventID}`, 'GET')

const getEventDetailsSummary = (eventID, isSubpromotion = false) => api(`event/getEventDetailsSummary?eventID=${eventID}&isSubpromotion=${isSubpromotion}`, 'GET')

const getTransactionList = (eventID, limit = 10, skip = 0, search = '') => api(`event/getTransactionListV2?eventID=${eventID}&limit=${limit}&skip=${skip}` + `${search ? `&search=${search}` : ''}`, 'GET')

const getAssignTicketSeats = (eventID, sectionID) => api(`event/getAssignTicketSeats?eventID=${eventID}&sectionID=${sectionID}`, 'GET', {}, false)

const updateAssignTicketSeats = obj => api('event/updateAssignTicketSeats', 'POST', obj)

const assignTicketsToTable = obj => api('event/assignTicketsToTable', 'POST', obj)

const unassignTicketsFromTable = obj => api('event/unassignTicketsFromTable', 'POST', obj)

const unassignTicketsFromSection = obj => api('event/unassignTicketsFromSection', 'POST', obj)

const deleteTablesFromEvent = obj => api('event/deleteTables', 'DELETE', obj)

const editTableSection = obj => api('event/editTable', 'POST', obj)

const deleteAssignTicketSection = obj => api('event/deleteAssignTicketSection', 'DELETE', obj)

const setScannerInvites = obj => api('event/makeScanner', 'POST', obj)

const deleteScannerInvites = obj => api('event/deleteScanner', 'POST', obj)

const getScannerInvites = ({ eventID, limit = 100000, skip = 0, search = '' }) => {
  let apiUrl = `event/scannerInvite?eventID=${eventID}&limit=${limit}&skip=${skip}`
  if (search) {
    apiUrl += `&search=${search}`
  }
  return api(apiUrl, 'GET')
}
const scanTicket = obj => api('event/scanTicket', 'POST', obj)

const toggleEventScanner = obj => api('event/activateScanner', 'POST', obj)

const getScannedTickets = (eventID, { limit = 100000, skip = 0, search = '' }) => api(`event/getScannedTickets?eventID=${eventID}&limit=${limit}&skip=${skip}`, 'GET')

const getSectionTables = (eventId, sectionID) => api(`event/getTables?eventID=${eventId}&sectionID=${sectionID}`, 'GET')

const setAssignTicketTables = obj => api('event/addTables', 'POST', obj)

const uploadFile = image => {
  const data = new FormData()
  const files = image?.fileList || []
  const obj = files[files.length - 1]
  data.append('image', obj?.originFileObj)
  return api('user/uploadFile', 'POST', data, false)
}

const uploadImage = (originalImage, image, options) => {
  const data = new FormData()
  data.append('image', image?.file?.originFileObj || image)
  data.append('originalImage', originalImage)
  return api('user/uploadImage', 'POST', data, true, options)
}

const uploadAttachment = (file, options) => {
  const data = new FormData()
  data.append('file', file)
  return api('user/uploadAttachment', 'POST', data, true, options)
}

const makeTicket = obj => {
  const formData = new FormData()
  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key])
  })
  return api('event/addEvent', 'POST', formData)
}
const addTicketCategory = obj => {
  const formData = new FormData()
  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key])
  })
  return api('event/addTicket', 'POST', obj)
}

const removeTicketCategory = obj => api('event/deleteTicket', 'DELETE', obj)

const updateTicket = obj => {
  const formData = new FormData()
  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key])
  })
  return api('event/editEvent', 'POST', obj)
}

const updateVenueTicket = obj => {
  const formData = new FormData()
  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key])
  })
  return api('event/editVenueEvent', 'POST', obj)
}

const getEvent = (id, userId) =>
  api(
    `event/getPublicEventDetails?eventID=${id}&userID=${userId}`,
    'GET',
    {},
    false,
  )

const getEventDetails = eventID => eventsV2.getEventDetails(eventID)

const getTickets = (type = 'ACTIVE') =>
  api(`user/myWallet?eventType=${type}&limit=0&skip=0`, 'GET')

const getPublicEvent = obj => api('event/findEvent', 'POST', obj, false)

const getPublicVenueEvent = obj => api(`venue/getEvents?venue=${obj?.venue}`, 'GET', obj)

const sellTickets = obj => api('event/walletShare', 'POST', obj)

const inviteTicket = obj => api('event/inviteResponse', 'POST', obj)

const getInviteTicket = inviteID => api(`event/getGuestInviteDetails?inviteID=${inviteID}`, 'GET', {}, false)

const setInviteTicketGuest = obj => api('event/inviteResponseGuest', 'POST', obj, false)

const joinVirtualEvent = code => api(`event/scanTicketV2?barcode=${code}`, 'GET')

const getGuestStreamUrl = (eventID, barcode) => api(`event/getGuestStreamUrl?eventID=${eventID}&barcode=${barcode}`, 'GET', {}, false)

const getStreamUrl = (eventID, barcode) => api(`event/getStreamUrl?eventID=${eventID}&barcode=${barcode}`, 'GET')

const getBroadcastFee = (currency = '$') => api(`event/getBroadcastFee?currency=${currency}`, 'GET')

const checkCoupon = couponCode => api('event/checkCoupon', 'POST', { couponCode })

const sendPromoterEmail = obj => api('event/sendPromoterEmail', 'POST', obj, false)

const refundTicket = obj => api('event/refundTicket', 'POST', obj)

const getTicketsByOrder = orderID => api(`event/getOrder?orderID=${orderID}`, 'GET', {}, false)

const getSubPromoteUrls = eventID => api(`event/getSubpromotedUrls?eventID=${eventID}`, 'GET')

const addVenueEvent = obj => api('event/addVenueEvent', 'POST', obj)

const buyPrivateStreamBundle = obj => api('event/buyPrivateStreamBundle', 'POST', obj)

const createSubscribe = data => api('user/createSubscription', 'POST', data)

const deleteSubscribe = () => api('user/deleteSubscription', 'POST')

export default {
  getEvents,
  getEvent,
  getTickets,
  getPublicEvent,
  sellTickets,
  inviteTicket,
  makeTicket,
  addTicketCategory,
  removeTicketCategory,
  buyPrivateStreamBundle,
  uploadFile,
  uploadImage,
  updateVenueTicket,
  uploadAttachment,
  getAssignTicketSection,
  setAssignTicketSection,
  editTableSectionName,
  unassignTicketsFromSection,
  unassignTicketsFromTable,
  getSectionTables,
  getScannedTickets,
  setAssignTicketTables,
  getEventDetails,
  getScannerInvites,
  setScannerInvites,
  deleteScannerInvites,
  toggleEventScanner,
  // scanTicket,
  getAssignTicketSeats,
  getAssignTicketSectionDetails,
  updateAssignTicketSeats,
  getUnassignedTicketList,
  assignTicketsToTable,
  deleteTablesFromEvent,
  editTableSection,
  deleteAssignTicketSection,
  deleteEvent,
  getTransactionList,
  getEventDetailsSummary,
  getSoldTicketDetails,
  getInviteUsers,
  giftTicket,
  updateTicket,
  getEventIDByUrl,
  getInviteTicket,
  setInviteTicketGuest,
  getFee,
  joinVirtualEvent,
  getBroadcastFee,
  checkCoupon,
  getStreamUrl,
  getGuestStreamUrl,
  sendPromoterEmail,
  refundTicket,
  getTicketsByOrder,
  getSubPromoteUrls,
  getVenmoToken,
  addVenueEvent,
  createSubscribe,
  deleteSubscribe,
  getPublicVenueEvent,
  scanTicket,
}
