import React from 'react'
import UploadImage from './UploadImage'
import DetailsFields from './DetailsFields'
import './index.styl'
import Buttons from './Buttons'

const Header = ({ form, currentEventDetails }) => {
  return (
    <div className='test'>
      <div className='headerTitle'>{currentEventDetails && 'Edit Event' || 'Create New Event'}</div>
      <div className='headerWrapper'>
        <div className='upload-details-wrapper'>
          <div className='imgBtnWrapper'>
            <UploadImage />
            <Buttons currentEventDetails={currentEventDetails} form={form} />
          </div>
          <DetailsFields currentEventDetails={currentEventDetails} form={form} />
        </div>
      </div>
    </div>
  )
}

export default Header
