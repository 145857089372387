import React from 'react'
import './index.styl'
import c from 'classnames'
import { useSelector } from 'react-redux'
import { HeaderMemberLyfe, FooterMemberLyfe } from 'components'

const MemberLyfeLayout = ({ children = '', className = '' }) => {
  const { isMobile } = useSelector(state => state.session)
  return (
    <div className={c('layoutWrapper', className)}>
      {isMobile && <div className='mobileMarginHeader' />}
      <HeaderMemberLyfe />
      {children}
      <FooterMemberLyfe />
    </div>
  )
}

export default MemberLyfeLayout
