import React, { useState, useContext, useEffect } from 'react'
import {
  Form,
  Switch,
  Divider,
} from 'antd'
import { ReactComponent as InfoIcon } from 'images/infoIcon.svg'

import { CreateMemberLyfeContext } from '../../../../..'

import SignUpInputFieldsForm from './SignUpInputFieldsForm'
import './index.styl'

const { Item, List } = Form

const SignUpInputFields = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)

  const [additionalFieldsSwitch, setAdditionalFieldsSwitch] = useState(false)
  const [rosterDoB, setRosterDoB] = useState(currentPageDetails?.rosterDoB || false)
  /* PARRENT APPROVAL */
  const [parentApproval, setParentApproval] = useState(currentPageDetails?.parentApproval || false)
  const [signupFields, setSignupFields] = useState(form.getFieldValue('signupFields') || [])
  useEffect(() => {
    if (!!currentPageDetails?.signupFields?.length) {
      setAdditionalFieldsSwitch(true)
      setSignupFields(currentPageDetails.signupFields)
      form.setFieldsValue({
        signupFields: currentPageDetails.signupFields,
      })
    }
  }, [currentPageDetails, form])

  useEffect(() => {
    if (!signupFields.length) {
      setAdditionalFieldsSwitch(false)
      form.setFieldsValue({
        signupFieldsSwitch: false,
      })
    }
  }, [signupFields, form])
  useEffect(() => {
    if (!rosterDoB) {
      form.setFieldsValue({
        parentApproval: false,
      })
    }
  }, [rosterDoB, form])

  const handleSwitchChange = checked => {
    setAdditionalFieldsSwitch(checked)
    if (checked && !signupFields.length) {
      const newSignUp = [{}]
      setSignupFields(newSignUp)
      form.setFieldsValue({
        signupFields: newSignUp,
      })
    }
  }

  const getSignupFieldsData = idx => {
    return signupFields[idx]
  }

  return (
    <div className='additionalFieldsWrapper radioItem'>
      <div className={additionalFieldsSwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='signUpInfoContainer'>
          <div className='infoIcon'>
            <InfoIcon />
          </div>
          <div className='textInfo'>
            During registration, your users will by default provide the following information: first name, middle name, nickname, email, and phone number. You can request additional information if needed. Please enable the “Additional Fields” toggle and specify the required parameters.
          </div>
        </div>
        <div className='radioWrapperReq'>
          <Item style={{ marginBottom: 0 }} name='rosterDoB' valuePropName='checked' className='requestDate'>
            <Switch onChange={() => setRosterDoB(!rosterDoB)} />
          </Item>
          <div className='text'>Request Birth Month/Year During Registration</div>
        </div>
        {/* PARRENT APPROVAL */}
        {rosterDoB &&
          <div className='radioWrapperReq'>
            <Item style={{ marginBottom: 0 }} name='parentApproval' valuePropName='checked' className='parentApproval'>
              <Switch onChange={() => setParentApproval(!parentApproval)} />
            </Item>
            <div className='text'>Required Parent Approval (For Minors)</div>
          </div>}
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='signupFieldsSwitch' valuePropName='checked' className='additionalFields'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Enable Additional Fields</div>
        </div>
      </div>
      {additionalFieldsSwitch && (
        <List name='signupFields'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <SignUpInputFieldsForm
                  form={form}
                  currentPageDetails={currentPageDetails}
                  categoryData={getSignupFieldsData(fieldKey)}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedSignupFields = form.getFieldValue('signupFields')
                    setSignupFields(updatedSignupFields)
                    if (updatedSignupFields.length === 0) {
                      form.setFieldsValue({
                        signupFieldsSwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory'
                className='addCategoryBtn'
                onClick={() => {
                  add()
                  const updatedSignupFields = form.getFieldValue('signupFields')
                  setSignupFields(updatedSignupFields)
                }}
              >Add Another Input Field
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default SignUpInputFields
