import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import './index.styl'

export default ({ message }) => {
  const { body, sender, date } = message
  const classes = ['message']
  const isSystemMsg = isEmpty(sender)
  const timeFormatter = t => moment(t).format('HH:mm a')
  if (isSystemMsg) classes.push('admin')

  const avatarProps = sender?.avatar
    ? { src: sender?.avatar }
    : { icon: <UserOutlined />, style: { backgroundColor: '#62677E' } }
  return (
    <div className={classes.join(' ')}>
      {isSystemMsg
        ? (
          <div className='messageWrapper'>
            <div className='system-message'>{body}</div>
            <small className='time'>{timeFormatter(date)}</small>
          </div>
        )
        : (
          <>
            <Avatar
              {...avatarProps}
              className='avatar'
              shape='circle'
              size={32}
            />
            <div className='messageWrapper'>
              <div className='nickname'>{sender?.fullName}</div>
              <div className='message-body'>{body}</div>
              <small className='time'>{timeFormatter(date)}</small>
            </div>
          </>
        )}
    </div>
  )
}
