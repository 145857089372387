import React from 'react'
import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import moment from 'moment'
import './index.styl'

export default ({ messageInfo }) => {
  const { createdAt, userName, message, type, avatar } = messageInfo
  const classes = ['messageChat']
  const timeFormatter = t => moment(t).format('HH:mm')

  const avatarProps = avatar
    ? { src: avatar?.thumbnail1 }
    : { icon: <UserOutlined />, style: { backgroundColor: '#62677E' } }
  return (
    <>
      {type === 'message' &&
        <div className={type === 'message' ? classes.join(' ') : ''}>
          <>
            <Avatar
              {...avatarProps}
              className='avatar'
              shape='circle'
              size={40}
            />
            <div className='messageWrapper'>
              <div className='nickname'>{userName}</div>
              <div className='message-body'>{message}</div>
              <small className='time'>{timeFormatter(createdAt)}</small>
            </div>
          </>
        </div>}
    </>
  )
}
