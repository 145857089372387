import React from 'react'
import c from 'classnames'

const Category = ({ item = {}, currencySymbol }) => {
  return (
    <li className={c('category')}>
      <div className='categoryWrapperLine'>
        {item.categoryName && <div className='textCategory'>{item.categoryName}</div>}
        {item.total > 0 && item.type && <div className={item.type === 'Total' ? 'textTotal' : 'textCategory'}>{item.type}</div>}
        {item.bundleName &&
          <>
            <div className='textCategory'>{item.bundleName}</div>
          </>}
        {item?.total > 0 && <div className='textPrice'>{currencySymbol}{!isNaN(item?.total > 0) && item?.total.toFixed(2)}</div>}
        {item?.total === 0 && <div className='textPrice'>{currencySymbol}{item?.total.toFixed(2)}</div>}
        {item.isActive &&
          <>
            <div className='textCategory'>{item.sectionName}</div>
            <div className='textPrice'>{currencySymbol}{!isNaN(item?.price) && item?.price.toFixed(2)}</div>
          </>}

      </div>
      {item.count > 0 && !item.isDonate && !item.bundleName && <div className='bottom'>{item.count} {item.count === 1 ? 'ticket' : 'tickets'}</div>}
      {item.count > 0 && !item.isDonate && item.bundleName && <div className='bottom'>{item.count} {item.count === 1 ? 'bundle' : 'bundles'}</div>}
      {item.isActive &&
        <>
          <div className='bottom'>{item.sectionType === 'TABLES' ? 'Table' : 'Row'} {item.rowName}</div>
          <div>Seat {item.seatName}</div>
        </>}
    </li>)
}
export default Category
