import React from 'react'
import './index.styl'
// import { getUtcOffsetDate } from 'utils'
import moment from 'moment-timezone'

import seatsIcon from 'images/seatsIcon.svg'
import Section from './Section'

import SectionTable from './SectionTable'
const SectionScheme = ({ section, isUser, sectionID, loading, setLoading, categories, selectSeatArray, selectedSeat, timeZone }) => {
  const currentDateTime = moment().tz(timeZone)
  const earlyBirdDateTime = moment.tz(section?.category?.earlyBirdDate, 'YYYY-MM-DD', timeZone)
  const isEarlyBirdActive = section?.category?.isEarlyBird && currentDateTime.isSameOrBefore(earlyBirdDateTime)

  // const earlyBirdDate = isEarlyBirdActive ? getUtcOffsetDate(section?.category?.earlyBirdDate, 'MM.DD.YYYY') : null
  const earlyBirdDate = isEarlyBirdActive ? moment.tz(section?.category?.earlyBirdDate, timeZone).format('MM.DD.YYYY') : null
  const isTableInvite = section?.category?.isInviteOnly
  return (
    <div className='buySeatSectionSchemeWrapper'>
      <div className='headers'>
        <div className='isEarlyBird'>
          <div className='sectionA'>
            <div className='name'>{section.sectionName}</div>
            <img alt='rows' src={seatsIcon} />
            {section.sectionType === 'ROWS' ? <span>Rows</span> : <span>Tables</span>}
          </div>
          {earlyBirdDate && (
            <div className='earlyBirdDate'>"Early Bird Special" until {earlyBirdDate}</div>
          )}
        </div>
      </div>
      <div className={section.sectionType === 'TABLES' ? 'sectionWrapper' : 'rowWrapp'}>
        {section.groups.map((group, index) => {
          return (
            <div key={index}>
              {section.sectionType === 'ROWS'
                ? (
                  <Section timeZone={timeZone} isUser={isUser} sectionID={sectionID} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} sectionName={section.sectionName} tableGroups={section.groups} loading={loading} setLoading={setLoading} categoriesArray={categories} sectionType={section.sectionType} category={group?.category} {...group} />
                ) : (
                  <SectionTable isTableInvite={isTableInvite} isCategoryInvite={group.category.isInviteOnly} isUser={isUser} sectionID={sectionID} sectionType={section.sectionType} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} sectionName={section.sectionName} categoryID={group.category.id} seatID={group.id} loading={loading} setLoading={setLoading} categoriesArray={categories} seatName={group.groupName} color={group?.category?.color} seatCount={group.seatCount} seatLeft={group.seatLeft} group={group} />
                )}
            </div>
          )
        })}
      </div>
    </div>)
}

export default SectionScheme
