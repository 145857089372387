import React from 'react'
import { notification } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
import { CustomButton, HiddenField } from 'components'
import c from 'classnames'
import './index.styl'

export const detailsByType = {
  VENUE: {
    eventType: 'Ticketed Admission',
    second: { field: 'quantity', title: 'Ticket quantity:' },
    third: { field: 'ticketType', title: 'Category:' },
    fifth: { field: 'sectionName', title: 'Section:' },
    seventh: { field: 'eventTableName', title: 'Table:' },
  },
  STREAM: {
    eventType: 'Livestream PPV',
    second: { field: 'quantity', title: 'Ticket quantity:', xsOrder: true },
    fourth: { field: 'ticketType', title: 'Category:' },
  },
}

const MockTicketEventDetails = ({ eventType, ...props }) => {
  if (!eventType) return <div />
  const renderCol = item => {
    const field = detailsByType[eventType][item]?.field ? props[detailsByType[eventType][item]?.field] : ''

    return field ? (
      <div className={c('eventDetailsCol', { xsOrder: detailsByType[eventType]?.[item]?.xsOrder })}>
        <span className='descTitle'>{detailsByType[eventType]?.[item]?.title}</span>
        <span className='descBody'>{field}</span>
      </div>
    ) : null
  }
  const renderAccessCode = item => {
    const field = detailsByType[eventType][item]?.field ? props[detailsByType[eventType][item]?.field] : ''
    const onSuccess = () => notification.success({ message: 'Password copied to clipboard' })
    return field ? (
      <>
        <div className='eventDetailsCol long'>
          <span className='descTitle'>{detailsByType[eventType]?.[item].title}</span>
          <span className='descBody'>
            <HiddenField label='****' value={field} />
          </span>
        </div>
        <div className='eventDetailsCol centerXS'>
          <CopyToClipboard text={field} onCopy={onSuccess}>
            <CustomButton text='Copy Access Code' type='dark' />
          </CopyToClipboard>
        </div>
      </>
    ) : null
  }

  return (
    <div className='MockTicketEventDetails'>
      <div className='eventDetailsRow'>
        <div className='eventDetailsCol'>
          <span className='descTitle'>Event Type:</span>
          <span className='descBody'>{detailsByType[eventType]?.eventType}</span>
        </div>
        {renderCol('second')}
      </div>
      <div className='eventDetailsRow xsOrder'>
        {renderCol('third')}
        {renderCol('fourth')}
      </div>
      <div className='eventDetailsRow'>
        {renderCol('seventh')}
        {renderCol('eighth')}
      </div>
      <div className='eventDetailsRow'>
        {renderCol('fifth')}
        {renderAccessCode('sixth')}
      </div>
    </div>
  )
}

export default MockTicketEventDetails
