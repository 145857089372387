import React from 'react'
import { getUtcOffsetDate, getGoogleUrl, getCurrencySymbolByCode } from 'utils'
import clock from 'images/Time.svg'
import location from 'images/Location.svg'
import calendar from 'images/Calendar.svg'
import priceIcon from 'images/ticket.svg'
import cardImg from 'images/card.png'
import venueIcon from 'images/venueIcon.svg'
import './index.styl'

const MockTicketDetailsPreview = ({
  startDate,
  endDate,
  image,
  eventName,
  address,
  ticket,
  presentedBy,
  originalPrice,
  currency,
  venue,
  ...other
}) => {
  const startDateTransformed = getUtcOffsetDate(startDate)
  const endDateTransformed = getUtcOffsetDate(endDate)
  const { timeZone = '' } = other || {}
  const errorHandler = e => (e.target.src = cardImg)
  const Item = ({ icon, title, text, textClass = '' }) => (
    <li className='item'>
      <div className='leftSide'>
        <img src={icon} alt=' ' />
        <span>{title}</span>
      </div>
      <div className={['text', textClass].join(' ')}>{text}</div>
    </li>
  )
  return (
    <div className='MockTicketDetailsPreview'>
      <div className='imgWrapper'>
        <img onError={errorHandler} src={image} alt=' ' className='imgVideo' />
      </div>
      <div className='wrapperDetails'>
        <div className='detailsContainer'>
          <div className='containerTitle'>
            <div className='detailTicketTitle'>{eventName}</div>
            {presentedBy}
          </div>
          <ul className='wrapperItems'>
            <Item
              text={getUtcOffsetDate(startDate, 'MMMM DD, YYYY')}
              title='Date'
              icon={calendar}
            />
            <Item
              text={`${startDateTransformed} - ${endDateTransformed} ${timeZone}`}
              textClass='time'
              title='Time'
              icon={clock}
            />
            <Item
              text={`${getCurrencySymbolByCode(currency)}${originalPrice}`}
              title='Price'
              icon={priceIcon}
            />
            <Item
              text={<a target='_blank' rel='noopener noreferrer' href={getGoogleUrl(address)}>{address}</a>}
              title='Location'
              icon={location}
            />
            <Item
              text={venue}
              title='Venue'
              icon={venueIcon}
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MockTicketDetailsPreview
