import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CustomButton } from 'components'
import { Form, Input, Checkbox, Row, Col } from 'antd'
import { signUp } from 'modules/session/session-actions'

import './index.styl'

const { Item } = Form

const SignUp = () => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = form => {
    setLoading(true)
    const obj = { ...form, deviceType: 'WEB', countryCode: '+1' }

    Object.keys(form).forEach(i => {
      if (i === 'agreement') {
        delete obj[i]
      } else {
        obj[i] = form[i].trim()
      }
    })

    dispatch(signUp(obj)).then(() => setLoading(false))
  }
  const onPressSpace = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  const onComparePasswords = async (rule, value) => {
    if (rule.field === 'password_confirm') {
      if (value && value !== form.getFieldValue('password')) {
        throw new Error('Passwords do not match.')
      }
    } else if (rule.field === 'password') {
      const confirmValue = form.getFieldValue('password_confirm')
      if (
        value &&
        confirmValue &&
        value !== form.getFieldValue('password_confirm')
      ) {
        throw new Error('Passwords do not match.')
      }
    }
  }
  const checkboxValidate = (_, value) =>
    value
      ? Promise.resolve()
      : Promise.reject(new Error('Please agree to Terms & Conditions'))

  const getRule = (label, name, min = 0) => {
    const rules = [
      {
        required: true,
        whitespace: true,
        message: 'Please enter ' + label.toLowerCase(),
      },
    ]
    if (name === 'email') {
      return rules.concat({
        type: 'email',
        transform: value => value?.trim(),
      })
    }
    if (name === 'password') {
      return rules.concat(
        {
          min: 6,
          message: 'Password length must be at least 6 characters',
        },
        {
          validator: onComparePasswords,
        },
      )
    }
    if (name === 'password_confirm') {
      return rules.concat({
        validator: onComparePasswords,
      })
    }
    if (name === 'mobile') {
      return rules.concat({
        min: 5,
        message: `${name} must be at least 5 characters.`,
      })
    }
    return rules
  }

  const CustomItem = (name, label, type, min = 0) =>
    name === 'password_confirm' ? (
      <Item
        name={name}
        label={label}
        dependencies={['password']}
        rules={getRule(label, name, min)}
        validateTrigger='onBlur'
      >
        <Input.Password onKeyDown={onPressSpace} className='input' />
      </Item>
    ) : (
      <Item
        name={name}
        label={label}
        className=''
        rules={getRule(label, name, min)}
        validateTrigger='onBlur'
      >
        {type === 'password' ? (
          <Input.Password onKeyDown={onPressSpace} className='input' />
        ) : name === 'mobile'
          ? <Input prefix='+1' className='input' type='number' />
          : <Input className='input' />}
      </Item>
    )

  return (
    <div className='wrapperSignUp'>
      <div className='radioSignUp'>
        <div className='title'>Sign Up</div>
      </div>
      <Form form={form} layout='vertical' className='formSignUp' onFinish={onFinish}>
        <Input.Group>
          <Row gutter={8}>
            <Col span={12}>
              {CustomItem('firstName', 'First name', 'input', 3)}
            </Col>
            <Col span={12}>
              {CustomItem('lastName', 'Last name', 'input', 3)}
            </Col>
          </Row>
        </Input.Group>
        {CustomItem('userName', 'Username', 'input', 3)}
        {CustomItem('email', 'Email', 'input')}
        {CustomItem('mobile', 'Mobile number', 'input')}
        {CustomItem('password', 'Create password', 'password')}
        {CustomItem('password_confirm', 'Confirm password', 'password')}
        <div className='checkbox'>
          <Form.Item
            name='agreement'
            valuePropName='checked'
            rules={[
              {
                validator: checkboxValidate,
              },
            ]}
          >
            <Checkbox>
              I Accept{' '}
              <Link className='link' to='/help/terms-and-conditions'>
                Terms & Conditions
              </Link>
            </Checkbox>
          </Form.Item>
        </div>
        <CustomButton
          text='Sign up'
          type='dark'
          className='signUpButton'
          htmlType='submit'
          loading={loading}
        />
        <div className='linkToLogInWrapper'>
          <div className='noAccountTitle'>Already have an account?</div>
          <Link className='logInButtonLink' to='/auth/sign-in'>Log in</Link>
        </div>
      </Form>
    </div>
  )
}

export default SignUp
