import React from 'react'
import { Button, Modal } from 'antd'
import './index.styl'

const ModalChoosePromotion = ({ visible, setVisible, withoutPromoter }) => {
  return (
    <div className='overlay'>
      <Modal
        onCancel={() => setVisible(false)}
        className='infoModal'
        footer={false}
        width={475}
        height={256}
        visible={visible}
        centered
        title='Choose promotion type'
      >
        <div className='promotionButton'>
          <Button
            onClick={() => {
              withoutPromoter(false)
              setVisible(false)
            }} className='ticketsButton' type='primary'
          >Event with Co-promoter
          </Button>
          <Button
            onClick={() => {
              withoutPromoter(true)
              setVisible(false)
            }} className='ticketsButton' type='primary'
          >Event without Co-promoter
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default ModalChoosePromotion
