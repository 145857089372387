import React from 'react'
import { LayoutSwitcher } from 'components'
import { BtnSignIn, Search, Navigation } from '../components'
import './index.styl'

const Unauthorized = () => {
  return (
    <div className='unauthorizedHeader'>
      <span className='logo'>
        <LayoutSwitcher type='ticket' />
      </span>
      <Navigation />
      <Search />
      <BtnSignIn />
    </div>
  )
}

export default Unauthorized
