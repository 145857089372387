import React from 'react'
import { MemberLyfeLayout } from 'components'
import CreateMemberLyfe from '../../components/CreateMemberLyfe'

const MakePage = () => {
  return (
    <MemberLyfeLayout>
      <CreateMemberLyfe />
    </MemberLyfeLayout>
  )
}

export default MakePage
