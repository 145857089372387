import { CHAT_INIT, CHAT_RESET, SET_MESSAGE, SET_DISCONNECT } from './chat-constants'

const chatInitialState = {
  isInit: false,
  disconnected: false,
  messages: [],
}

export const chatReducer = (state = chatInitialState, action) => {
  const { type, payload } = action

  switch (type) {
    case CHAT_RESET:
      return { ...chatInitialState }
    case SET_DISCONNECT:
      return { ...chatInitialState, disconnected: true }
    case CHAT_INIT:
      return { ...state, isInit: payload }
    case SET_MESSAGE:
      return { ...state, messages: [...state?.messages, payload] }
    default:
      return state
  }
}
