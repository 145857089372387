import React, { useEffect, useState } from 'react'
import { minimumPriceValidator } from 'modules/utils/validators'
import { Button, Form, Input, InputNumber, Switch } from 'antd'

import './index.styl'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import SingleDatePicker from '../../../../Header/DetailsFields/SingleDatePicker'

const { Item } = Form

const LiveStreamItems = ({ namePrefix, categoryData, form }) => {
  const [isEarlyBird, setIsEarlyBird] = useState(categoryData?.hasEarlyBird || false)
  const [availableTicket, setAvailableTicket] = useState(categoryData?.availableTicket || false)
  const [isPrivateStream, setIsPrivateStream] = useState(categoryData?.isPrivateStream || false)
  const [earlyBirdDate, setEarlyBirdDate] = useState(categoryData?.earlyBirdDate || '')
  const [privateQuantity, setPrivateQuantity] = useState(25)
  const availableTicketHandler = () => {
    setAvailableTicket(!availableTicket)
    // form.setFieldsValue({
    //  showAvailableTickets: !availableTicket,
    // })
  }
  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)

  const switchPrivateStream = () => {
    setIsPrivateStream(!isPrivateStream)
  }

  const handleIncrement = () => setPrivateQuantity(privateQuantity + 25)

  const handleDecrement = () => {
    if (privateQuantity > 25) {
      setPrivateQuantity(privateQuantity - 25)
    }
  }

  useEffect(() => {
    const liveStream = form.getFieldValue('liveStream')
    setPrivateQuantity(privateQuantity)
    liveStream[namePrefix].quantity = isPrivateStream ? privateQuantity : 10000
    form.setFieldsValue({ liveStream: liveStream })
  }, [privateQuantity, isPrivateStream])

  useEffect(() => {
    const liveStream = form.getFieldValue('liveStream')
    liveStream[namePrefix].name = isPrivateStream ? 'Private Live Stream' : 'Live Stream'
    if (isPrivateStream) {
      liveStream[namePrefix].price = 0
    }
    form.setFieldsValue({ liveStream: liveStream })
  }, [isPrivateStream, namePrefix, form])

  // const validQuantity = v => ({
  //  validator () {
  //    if (!!v) {
  //      return Promise.resolve()
  //    }
  //    return Promise.reject(new Error('Enter quantity!'))
  //  },
  // })

  return (
    <>
      <div className='topField'>
        <div className='inputFields'>
          <Item
            rules={[{ required: true, message: 'Enter Ticket Category Name' }]}
            name={[namePrefix, 'name']}
            label='Ticket Category Name'
          >
            <Input className='inputName' />
          </Item>
          <div className='priceQuantityWrapper'>
            <div className='mobileFlex'>
              <Item
                name={[namePrefix, 'price']}
                label='Ticket Price'
                rules={isPrivateStream ? [{ required: false }] : [
                  { required: true, message: 'Enter price' },
                  minimumPriceValidator,
                ]}
              >
                <InputNumber disabled={isPrivateStream} placeholder='0' className='price' min={0} />
              </Item>

              {isPrivateStream ? (
                <div className='castomQuantity'>
                  {/* Render quantity input for private streams */}
                  <Item
                    rules={[
                      { required: true, message: 'Enter quantity' },
                      { type: 'number', max: 100000, message: 'Quantity cannot exceed 100 000' },
                      {
                        validator: (_, value) =>
                          Number.isInteger(value) ? Promise.resolve() : new Promise((resolve, reject) => {
                            reject(new Error('Quantity must be an integer!'))
                          }),
                      },
                    ]}
                    name={[namePrefix, 'quantity']}
                    initialValue={privateQuantity}
                    label='Tickets Quantity'
                  >
                    <div className='quantity-control'>
                      <Button className='plus' icon={<PlusOutlined />} onClick={handleIncrement} />
                      <InputNumber className='quantity' placeholder='0' min={0} value={privateQuantity} disabled />
                      <Button className='minus' icon={<MinusOutlined />} onClick={handleDecrement} />
                    </div>
                  </Item>
                </div>
              ) : (
                <Item
                  rules={[{ required: true, message: 'Enter quantity' }]}
                  name={[namePrefix, 'quantity']}
                  initialValue='10000'
                  label='Tickets Quantity'
                >
                  <InputNumber className='quantity' placeholder='0' min={0} disabled />
                </Item>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='radioItemField'>
        <Item
          name={[namePrefix, 'isPrivateStream']}
          valuePropName='checked'
          initialValue={isPrivateStream}
          className='item'
        >
          <Switch onChange={switchPrivateStream} defaultChecked={isPrivateStream} />
        </Item>
        <div className='text'>Private Live Stream</div>
      </div>
      <div className='radioItemField'>
        <Item
          name={[namePrefix, 'showAvailableTickets']}
          valuePropName='checked'
          className='item'
          initialValue={availableTicket}
        >
          <Switch onChange={availableTicketHandler} defaultChecked={availableTicket} />
        </Item>
        <div className='text'>Show available tickets</div>
      </div>
      <div className='radioItemField earlyBirdDateItem'>
        <Item
          name={[namePrefix, 'hasEarlyBird']}
          className='item'
          valuePropName='checked'
        >
          <Switch onChange={onCheckEarlyBird} defaultChecked={isEarlyBird} />
        </Item>
        <div className='text'>Early Bird Special</div>
      </div>
      {isEarlyBird && (
        <div className='earlyBirdFieldsWrapper'>
          <SingleDatePicker label='Early Bird' namePrefix={namePrefix} nameText='earlyBirdDate' fromDate={earlyBirdDate} form={form} setFromDate={setEarlyBirdDate} title='Early Bird Date' />
          <Item
            name={[namePrefix, 'earlyBirdPrice']}
            dependencies={[[namePrefix, 'price']]}
            label='Price'
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
            className='earlyBirdPrice'
          >
            <InputNumber placeholder='0' min={0} />
          </Item>
        </div>
      )}
    </>
  )
}

export default LiveStreamItems
