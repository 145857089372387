import React, { useEffect, useState } from 'react'
import { eventsV2 } from 'apiv2'
import { Pagination } from 'antd'
import SeatsIcon from 'images/seatsTemplateIcon.svg'
import './index.styl'
import TemplateList from './TemplateList'
const paginationDefault = {
  current: 1,
  total: 0,
  limit: 5,
}
const ChooseTemplateList = ({ setTemplateUpdateCategory, eventID }) => {
  const [templates, getTemplates] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(paginationDefault)
  useEffect(() => {
    updateEvents()
  }, [])
  const updateEvents = (current = 1) => {
    const params = {
      limit: paginationDefault.limit,
      skip: (current * pagination.limit) - pagination.limit,
      search: '',
      eventID,
    }
    setLoading(true)
    eventsV2.getTemplates(params).then(response => {
      getTemplates(response.data.templates)
      setPagination({ ...pagination, total: response.data.total, current })
    })
      .catch(err => err)
      .finally(() => setLoading(false))
  }
  const onChange = (current, pageSize) => {
    const skip = (current * pageSize) - pagination.limit
    const params = {
      limit: pagination.limit,
      search: '',
      skip,
      eventID,
    }
    setLoading(true)
    eventsV2.getTemplates(params)
      .then(response => {
        getTemplates(response.data.templates)
        setPagination({ ...pagination, total: response.data.total, current })
      })
      .catch(err => err)
      .finally(() => setLoading(false))
  }
  return (
    <div className='chooseTemplateWrapper'>
      {templates?.length ? (
        <div>
          <div className='listoftemplates'>List of templates</div>
          {templates?.map((template, index) => {
            return (
              <TemplateList setTemplateUpdateCategory={setTemplateUpdateCategory} key={index} template={template} />
            )
          })}
        </div>
      ) : (
        <div className='noTemplateWrapper'>
          <img src={SeatsIcon} alt='noSeatIcon' />
          <div className='noSeatTitle'>You have no saved templates yet</div>
        </div>
      )}
      {!!templates?.length && !!pagination.total && (
        <Pagination
          current={pagination.current}
          pageSize={pagination.limit}
          total={pagination.total}
          showSizeChanger={false}
          className='pagination'
          onChange={onChange}
          disabled={loading}
        />
      )}
    </div>
  )
}

export default ChooseTemplateList
