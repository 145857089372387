import React, { useState } from 'react'
import moment from 'moment'
import ellipseBlack from 'images/EllipseBlack.svg'
import './index.styl'
import { ModalStartScanning } from '../../../../components'

const Card = ({ event }) => {
  const [openModal, setModalOpen] = useState(false)
  const handleModalOpen = () => {
    setModalOpen(!openModal)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <div className='cardContainer'>
      <div className='card-header'>
        <div className='card-img'>
          <img src={event?.imageUrl?.original} alt={event?.eventName} className='myWallet-image' />
          {/* <div className='background' /> */}
        </div>
        <div onClick={handleModalOpen} className='btn'>
          Start scan
        </div>
      </div>
      <div className='card-footer'>
        <div className='title'>{event?.eventName}</div>
        <div className='description'>
          <div>{event?.address}</div>
          <img className='ellipse' src={ellipseBlack} alt='ellipse' />
          <div className='date'>
            {!!event?.startDate ? moment(event?.startDate).format('ddd, MMM D') : ''}
          </div>
        </div>
      </div>
      {openModal && <ModalStartScanning eventName={event.eventName} eventID={event._id} visible={openModal} updateVisible={handleModalClose} />}
    </div>
  )
}

export default Card
