import React, { useEffect, useState } from 'react'
import { Divider, Form, Switch } from 'antd'

import UpdatedTicketCategory from '../../UpdatedTicketCategory'

const { Item, List } = Form
const TableCategory = ({ currentEventDetails }) => {
  const [tableSwitch, setTableSwitch] = useState(false)

  const donationTicketHandler = () => {
    setTableSwitch(!tableSwitch)
  }
  useEffect(() => {
    if (!!currentEventDetails?.table?.length) {
      setTableSwitch(true)
    }
  }, [currentEventDetails])
  return (
    <div className='typeCategoryWrapper'>
      <div className='wrapperTitleRadioText'>
        <div className='title'>Tables</div>
        <div className='radioItem'>
          <Item style={{ marginBottom: 0 }} name='tableSwitch' valuePropName='checked' className='item'>
            <Switch onChange={donationTicketHandler} defaultChecked={tableSwitch} />
          </Item>
          <div className='text'>Add Table Section</div>
        </div>
      </div>
      {tableSwitch && (
        <List name='table'>
          {(fields, { add, remove }) => (
            <div className='updatedCategoriesWrapper'>
              {fields.map((field, i) =>
                <div key={field.fieldKey}>
                  <UpdatedTicketCategory
                    categoryType='Table'
                    namePrefix={i}
                    fieldKey={field.fieldKey}
                    fieldsLength={fields.length}
                    switchToggleOff={setTableSwitch}
                    onRemove={remove}
                  />
                </div>,
              )}
              {fields.length === 0 && (
                <div key='defaultForm'>
                  {/* Render default form when no categories exist */}
                  <UpdatedTicketCategory
                    categoryType='Table'
                    namePrefix={0}
                    fieldsLength={fields.length + 1}
                    switchToggleOff={setTableSwitch}
                    onRemove={remove}
                  />
                </div>
              )}
              <div
                id='addCategory'
                className='addCategoryBtn'
                onClick={() => {
                  add()
                }}
              >
                Add Another Table Section
              </div>
            </div>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default TableCategory
