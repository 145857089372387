import React, { useState } from 'react'
import {
  Radio,
  Form,
} from 'antd'
import moment from 'moment'
import './index.styl'

const { Item } = Form

const TemplateList = ({ template }) => {
  const [selectedOption, setSelectedOptions] = useState(null)
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
  }
  const { templateName, created, sections } = template
  const creationDate = created && moment(created).utcOffset(created).format('MMMM D, YYYY')
  return (
    <div className='templateListWrapper'>
      <Item
        name='templateList'
        rules={[{ required: true, message: 'Choose Template' }]}
        initialValue={selectedOption}
      >
        <Radio.Group onChange={onValueChange} value={selectedOption}>
          <Radio className='radioTemplateName' value={templateName}>
            <div className='templateName'>{templateName}</div>
          </Radio>
        </Radio.Group>
      </Item>
      <div className='templateInfo'>
        <div className='infoWrapper'>
          <div className='title'>Sections: <span>{sections}</span> </div>
        </div>
        {/* <div className='infoWrapper'>
            <div className='title'>Seats: <span>{sections?.[0].seatCount}</span> </div>
          </div> */}
        <div className='infoWrapper'>
          <div className='title'>Creation date: <span>{creationDate}</span></div>
        </div>
      </div>
    </div>
  )
}

export default TemplateList
