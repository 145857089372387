export const COPYRIGHT = `Copyright © ${new Date().getFullYear()} Ticket Lyfe. All Rights Reserved`

export const MAIN_APP_URL = 'www.ticketlyfe.com'
export const MEMBERSHIP_URL = 'www.memberlyfe.com'

export const SUPPORT_EMAIL = 'support@ticketlyfe.com'

export const getEventUrl = part => `/buy/${part}`
export const getPagetUrl = part => `/memberPage/${part}`

export const getMembershipUrl = part => `/membership/${part}`

export const getGoogleUrl = address => `https://www.google.com/maps/search/?api=1&query=${address}`

export const timeZones = [
  { value: 'America/Puerto_Rico', label: 'ATLANTIC STANDARD TIME (UTC - 4)' },
  { value: 'US/Eastern', label: 'EASTERN STANDARD TIME (UTC - 5)' },
  { value: 'US/Central', label: 'CENTRAL STANDARD TIME (UTC - 6)' },
  { value: 'US/Mountain', label: 'MOUNTAIN STANDARD TIME (UTC - 7)' },
  { value: 'US/Pacific', label: 'PACIFIC STANDARD TIME (UTC - 8)' },
  { value: 'US/Alaska', label: 'ALASKA STANDARD TIME (UTC - 9)' },
  { value: 'US/Hawaii', label: 'HAWAII-ALEUTIAN STANDARD TIME (UTC - 10)' },
  { value: 'US/Samoa', label: 'SAMOA STANDARD TIME (UTC - 11)' },
  { value: 'Pacific/Guam', label: 'CHAMORRO STANDARD TIME (UTC +10)' },
]

export const categories = {
  live: 'defaultCategory',
  virtual: 'defaultCategory',
  stream: 'streamingCategory',
}
export const categoryInitialValues = {
  defaultCategory: 'General Admission',
  streamingCategory: 'Livestream PPV',
}

export const blacklistFileTypes = /(com|bat|exe|cmd|vbs|msi|jar|php(\d?)|phtml|access|js)$/i

export const lightColors = ['#F2F2F2', '#E0E0E0', '#BCBCBC', '#BDBDBD', '#FFFFFF']

export const colors = [
  '#D40000', '#000000', '#000066', '#0000CC', '#00CCFF', '#330000', '#333300', '#3333FF', '#336600', '#339900', '#3399FF', '#33CC00', '#33FF00', '#990000', '#993300', '#996600', '#999900', '#99CC00', '#99FF00', '#FF3300', '#FF6600', '#FF9900', '#FFCC00', '#FFFF00',
]

export const myBoxOfficeTabs = [
  { name: 'All events', key: 'ALL' },
  // { name: 'Sub-Promotions', key: 'SUBPROMOTIONS' },
  { name: 'Upcoming', key: 'ACTIVE' },
  { name: 'Past', key: 'ARCHIVE' },
  { name: 'On Demand', key: 'ON_DEMAND' },
]

export const eventTypes = {
  TICKETED_ADMITION: 'TICKETED_ADMITION',
  TICKETED_ADMITION_STREAM: 'TICKETED_ADMITION_STREAM',
  STREAM: 'STREAM',
  VIRTUAL: 'VIRTUAL',
}

export const streamTypes = {
  PPV_STREAM: 'PPV_STREAM',
  PRIVATE_STREAM: 'PRIVATE_STREAM',
  PPV_STREAM_CATEGORY_NAME: 'PPV Live Stream',
  PRIVATE_STREAM_CATEGORY_NAME: 'Private Stream',
}

export const categoryTypes = {
  CATEGORY_TYPE_COMMON: 'COMMON',
  CATEGORY_TYPE_DESIGNATED: 'DESIGNATED',
  CATEGORY_TYPE_DESIGNATED_NAME: 'Designated',
  CATEGORY_TYPE_COMMON_NAME: 'Common',
}

export const rosterTypes = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
  IN_INVITE: 'IN_INVITE',
}
