import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../Logo'
import BtnSignIn from '../BtnSignIn'
import { Dropdown, Menu } from 'antd'
import c from 'classnames'

import DropDownIcon from 'images/DropDownIcon.svg'
import burger from 'images/Menu.svg'
import burgerClose from 'images/burgerClose.svg'

import './index.styl'

const { Item } = Menu

const Navigation = ({ isOpen, setIsOpen }) => {
  const { pathname } = useLocation()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }
  const linksPaths = {
    getTickets: '/home',
    myWallet: '/help/overview/my-wallet',
    makeMyTickets: '/help/overview/make-my-tickets',
    myBoxOffice: '/help/overview/my-box-office',
  }

  const onScroll = () => {
    setIsOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }

    const handleResize = () => {
      updateWindowWidth()
    }

    window.addEventListener('resize', handleResize)

    return () => {
      document.body.style.overflow = 'auto'
      window.removeEventListener('resize', handleResize)
    }
  }, [isOpen])

  const isActive = math => {
    return Array.isArray(math) ? !!math.map(m => pathname.includes(m)).filter(Boolean).length : pathname.includes(math)
  }

  const renderLinkItem = ({ goTo, label, active }) => (
    <Link
      key={goTo}
      to={goTo}
      className={c('link', { active })}
      onClick={onScroll}
    >
      {label}
    </Link>
  )

  const renderSubLinkItem = ({ goTo, label, active }) => (
    <Item key={goTo}>
      <Link
        to={goTo}
        className={c('link', { active })}
        onClick={onScroll}
      >
        {label}
      </Link>
    </Item>
  )

  const mainLinks = [
    { goTo: linksPaths.getTickets, label: 'Get Tickets', active: isActive(linksPaths.getTickets) && !pathname.split('/')[1] },
    { goTo: linksPaths.myWallet, label: 'My Wallet', active: isActive(linksPaths.myWallet) },
  ]
  const subLink = [
    { goTo: linksPaths.makeMyTickets, label: 'Make My Tickets', active: isActive(linksPaths.makeMyTickets) },
    { goTo: linksPaths.myBoxOffice, label: 'My Box Office', active: isActive(linksPaths.myBoxOffice) },
  ]

  const handleMenuToggle = () => {
    setIsOpen(!isOpen)
  }
  const menu = (<Menu className='sub-menu'>{subLink.map(renderSubLinkItem)}</Menu>)
  const activeDropDown = isActive(['make-my-tickets', 'my-box-office', 'scanner'])
  return (
    <div className='newHeaderNavigationWrapper'>
      {windowWidth < 1050 ? (
        <div className='mobileHeaderNavWrapper'>
          <div className='burgerLogoWrapper'>
            <div onClick={handleMenuToggle}>
              {isOpen ? <img className='closeIcon' src={burgerClose} alt='' /> : <img src={burger} className='menu' />}
              <ul className={`burger-menu ${isOpen ? 'open menu1' : 'hide menu1'}`}>
                <li>{[...mainLinks, ...subLink].map(renderLinkItem)}</li>
              </ul>
            </div>
            <Logo />
          </div>
          <BtnSignIn />
        </div>
      ) : (
        <>
          <Logo />
          <div className='linksNavigation'>
            {mainLinks.map(renderLinkItem)}
            <Dropdown overlay={menu} trigger={['click']} key='SellerTools'>
              <div className='newDropdownLink'>
                <div className={c('link', 'dropdownLinkText', { activeDropDown })}>Seller Tools</div>
                <img src={DropDownIcon} alt='Drow down icon' className='icon' />
              </div>
            </Dropdown>
          </div>
          <BtnSignIn />
        </>
      )}
    </div>
  )
}

export default Navigation
