import React from 'react'
import { Link } from 'react-router-dom'
import { getUtcOffsetDate } from 'utils'
import clock from 'images/Time.svg'
import venueIcon from 'images/venueIcon.svg'
import flagMap from 'images/Location.svg'
import calendar from 'images/Calendar.svg'
import { Image } from 'antd'
import soldOutBadge from 'images/Soldoutbadge.svg'
import rainIcon from 'images/rain.svg'
import cardImg from 'images/card.png'
import donation from 'images/donationSymbol.svg'
import email from 'images/email.svg'
import './index.styl'

const DetailTicket = ({
  arrows = false,
  startDate,
  endDate,
  imageUrl,
  eventName,
  eventId,
  venue,
  adress,
  zoom_url,
  zoom_password,
  activeLink,
  inviteStatus,
  rainDate,
  barcode,
  isVirtual,
  isStreaming,
  quantityAvailable,
  ticket,
  presentedBy = '',
  eventLabel,
  onFocus,
  ...other
}) => {
  const address = adress?.split(',').join('').split(' ').join('+')
  const { timeZone = '', isDonationEnable } = ticket || other || {}
  const { id: eventID, inviterID, userID } = other || {}
  const isDonationEnableLocal = (inviterID !== userID) ? false : isDonationEnable
  const isLiveEvent = !isVirtual && !isStreaming
  const addressLink = `https://www.google.com/maps/search/?api=1&query=${address}`
  const Item = ({ icon, title = '', text, textClass = '' }) => (
    <li className='item'>
      <div className='leftSide'>
        <img src={icon} alt=' ' className={title.toLowerCase()} />
        <span>{title}</span>
      </div>
      <div className={['text', textClass].join(' ')}>{text}</div>
    </li>
  )
  return (
    <div className='wrapperDetailTicketHeader'>
      <div className='imgWrapper'>
        <Image src={imageUrl?.original} onError={e => (e.target.src = cardImg)} preview={{ src: imageUrl?.sourceImage }} className='imgVideo' />
        {quantityAvailable <= 0
          ? (
            <img src={soldOutBadge} alt='sold out badge' className='soldOutBadge' draggable={false} />
          ) : (
            null
          )}
        {eventLabel && <div className='eventType'>{eventLabel}</div>}
        {/* <div className='background' /> */}
      </div>
      <div className='wrapperDetails'>
        <div className='detailsContainer'>
          <div className='containerTitle'>
            <div className='detailTicketTitle'>{eventName || 'Loading...'}</div>
            <div className='eventSubtitle'>
              {!!presentedBy?.length && <span className='presentedBy'>Presented by <span>{presentedBy}</span></span>}
              <Link to={`/send-mail?eventID=${eventID}`} className='email'><img src={email} alt=' ' />Email to Promoter</Link>
            </div>
          </div>
          <ul className='wrapperItems'>
            {venue && venue !== 'undefined' && (
              <Item
                text={venue}
                title='Venue'
                icon={venueIcon}
              />
            )}
            <Item
              text={startDate ? getUtcOffsetDate(startDate, 'MMMM DD, YYYY') : ''}
              title='Date'
              icon={calendar}
            />
            {rainDate && (
              <Item
                text={getUtcOffsetDate(rainDate, 'MMMM DD, YYYY', timeZone)}
                title='Rain Date'
                icon={rainIcon}
              />
            )}
            <Item
              text={`${getUtcOffsetDate(startDate)} - ${getUtcOffsetDate(endDate)} ${timeZone}`}
              title='Time'
              icon={clock}
            />
            {(isStreaming || isLiveEvent) && address && (
              <Item
                text={<a target='_blank' rel='noopener noreferrer' href={addressLink}>{adress}</a>}
                title='Location'
                icon={flagMap}
              />
            )}
            {isDonationEnableLocal && (
              <Item
                text='or Add donation to your ticket'
                icon={donation}
                title='Donate'
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DetailTicket
