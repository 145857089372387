import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { MainButton } from 'components'
import TwoToneButton from 'pages/Home/TwoToneButton'
import moment from 'moment'
import { getCurrencySymbolByCode, isOnDemand, getEventLabel } from 'utils'
import cardImg from 'images/card.png'
import soldOutBadge from 'images/Soldoutbadge.svg'
import onDemand from 'images/OnDemand.svg'
import canceled from 'images/Canceled.svg'
import earlyBirdSign from 'images/earlyBird.svg'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const SliderBody = ({ items = [] }) => {
  const history = useHistory()
  const getPrice = item => {
    const lowestPrice = item.lowestPrice
    const currencySymbol = getCurrencySymbolByCode(items?.currency)
    return `Get from ${currencySymbol} ${lowestPrice % 1 === 0 ? lowestPrice : lowestPrice.toFixed(2)}`
  }
  const onClick = item => {
    history.push(`/buy/${item.id}/${item.userID}`)
    window.scrollTo(0, 0)
  }
  const renderButton = item => {
    const ButtonComponent = item.isDonationEnable ? TwoToneButton : MainButton
    return (
      <ButtonComponent
        onClick={() => onClick(item)}
        subText='Donate Today'
        text={getPrice(item)}
        className={item.isDonationEnable ? 'donateBtn' : 'btn'}
      />
    )
  }
  return items.length ? (
    <div className='wrapperHomeSlider'>
      {items.map((item, index) => (
        <div key={index} className='wrapperSlideBody'>
          {item.isEarlyBird &&
            <div className='earlyBirdSpecialLabel'><img src={earlyBirdSign} alt='' />Early Bird Special</div>}
          <div className='body' onClick={() => onClick(item)}>
            <div className='imgWrap'>
              <img
                src={item?.imageUrl?.thumbnail1}
                onError={e => (e.target.src = cardImg)}
                alt='card'
                className='img'
              />
              {item?.isEventCancelled
                ? <img src={canceled} alt=' ' className='onDemandBadge' draggable={false} />
                : isOnDemand(item.endDate) && !!item.streamTicketsAvailable
                  ? <img src={onDemand} alt=' ' className='onDemandBadge' draggable={false} />
                  : item.quantityAvailable <= 0
                    ? <img src={soldOutBadge} alt='sold out badge' className='soldOutBadge' draggable={false} />
                    : null}
            </div>
            {item.quantityAvailable &&
              <div className='front'>
                {item.quantityAvailable && renderButton(item)}
              </div>}
          </div>
          <div className='wrapperEventType'>
            <div className='eventType'><span className='eventTypeText'>{getEventLabel(item)}</span></div>
          </div>
          <div className='foot'>
            <div className='title' onClick={() => onClick(item)}>
              {item.eventName}
            </div>
            <ul className='flex'>
              <li className='name'>
                {item.venue ? item.venue : 'Virtual event'}
              </li>
              <li className='ellipse'>&#183;</li>
              <li className='date'>
                {moment(item.startDate).utcOffset(item.startDate).format('MMMM DD, YYYY')}
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className='wrapperBody'>
      <Tooltip title='tooltip example'>
        <img src={NoEventsInformation} alt='Information Logo' />
      </Tooltip>
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )
}

export default SliderBody
