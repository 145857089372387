import React, { useState } from 'react'
// import QRCode from 'qrcode.react'
import arrowSingle from 'images/arrowSingle.svg'
import { Button } from 'antd'
import { QRCode } from 'react-qrcode-logo'

import Scanned from 'images/Scanned.svg'
import QRCodeLogoIcon from 'images/QRCodeLogo.svg'
import closeIconSvg from 'images/Crossmark.svg'

import c from 'classnames'
import './index.styl'

const BarcodeSlider = ({ barcode, current, isScanned, total, onNext, onPrev, isNext, isPrev, showSlider = true }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(!isModalVisible)
  }

  const closePopup = () => {
    setIsModalVisible(false)
  }

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closePopup()
    }
  }
  const barcodeValue = `${process.env.REACT_APP_API_BARCODE}${barcode}`
  const qrCodeSize = window.innerWidth <= 700 ? 299 : 112
  return (
    <div className='rootBarcodeSlider'>
      <div className='whiteWrapper'>
        <div onClick={showModal} className='wrapperQrCodeContainer'>
          <QRCode className={c('wrapperQrCode', { isScanned })} size={qrCodeSize} logoImage={QRCodeLogoIcon} removeQrCodeBehindLogo logoPadding={5} value={barcodeValue} />
          <div className={c('scannedBadgeContainer', { isVisible: isScanned })}>
            <img src={Scanned} className='scannedBadge' alt=' ' />
          </div>
        </div>
      </div>
      {showSlider && (
        <div className='sliderControls'>
          <Button className='buttonPrev' onClick={onPrev} disabled={!isPrev}>
            <img src={arrowSingle} alt='' className='controlLeft' />
          </Button>
          <div className='summaryContainer'>
            <span>{current}</span>of<span>{total}</span>
          </div>
          <Button className='buttonNext' onClick={onNext} disabled={!isNext}>
            <img src={arrowSingle} alt='' className='controlRight' />
          </Button>
        </div>
      )}
      {isModalVisible &&
        <div className='modal' onClick={handleOverlayClick}>
          <div className='modal-content'>
            <img className='closeQrCodeIcon' onClick={closePopup} src={closeIconSvg} alt='Close' />
            <QRCode className={c('wrapperQrCode', { isScanned })} size={400} logoImage={QRCodeLogoIcon} removeQrCodeBehindLogo logoPadding={10} value={barcodeValue} />
          </div>
        </div>}
      {/* {isModalVisible &&
        <QRCodeModal onCancel={hideModal} visible={isModalVisible} logoImage={QRCodeLogoIcon} barcode={barcode} />} */}
    </div>
  )
}

export default BarcodeSlider
