import React from 'react'
import { Tooltip } from 'antd'
import InfoTooltip from 'images/BlueInformationToolTip.svg'
import MemberInfoTooltip from 'images/grayInformationTooltip.svg'
import './index.styl'

const TooltipInfo = ({ text, info, className = '', placement, isMember = false }) => {
  return (
    <div className={className}>
      {text}
      <Tooltip placement={placement} title={info}>
        <img alt='InfoTooltip' src={isMember ? MemberInfoTooltip : InfoTooltip} className='infoTooltip' />
      </Tooltip>
    </div>
  )
}

export default TooltipInfo
