import React from 'react'
import { Radio } from 'antd'
import stream from 'images/eventsIcons/LivestreamPPV.svg'
import live from 'images/eventsIcons/VenueAttended.svg'
import virtual from 'images/eventsIcons/VirtualMeating.svg'
import liveStream from 'images/eventsIcons/LiveStream.svg'
import './index.styl'
import { eventTypes } from 'utils'

const EventsCheckbox = ({ eventType, isVenueRole, changeEventType, disabled, isVenueEvent, setStreamingEnabled }) => {
  const classVanue = isVenueEvent ? 'venue' : ''
  const categoriesRegularEvent = [
    {
      key: eventTypes.TICKETED_ADMITION_STREAM,
      label: <><div>Ticketed Admission +</div><div>Livestream</div></>,
      img: liveStream,
    },
    { key: eventTypes.TICKETED_ADMITION, label: 'Ticketed Admission', img: live },
    { key: eventTypes.STREAM, label: 'Livestream', img: stream },
    { key: eventTypes.VIRTUAL, label: 'Virtual Meeting', img: virtual },
  ]
  const categoriesVenueEvent = [
    {
      key: eventTypes.TICKETED_ADMITION_STREAM,
      label: <><div className={classVanue}>Ticketed Admission + </div><div className={classVanue}>Livestream</div></>,
      img: liveStream,
    },
    { key: eventTypes.TICKETED_ADMITION, label: 'Ticketed Admission', img: live },
  ]
  const onChange = ({ target: { value } }) => {
    changeEventType(value)
    setStreamingEnabled([eventTypes.STREAM, eventTypes.TICKETED_ADMITION_STREAM].includes(value))
  }
  const categoriesRole = () => {
    return isVenueEvent || isVenueRole ? categoriesVenueEvent : categoriesRegularEvent
  }
  return (
    <div className='rootEventsCheckbox'>
      <Radio.Group value={eventType} onChange={onChange} className='radioContainer' disabled={disabled}>
        {categoriesRole().map(({ key, label, img }) => (
          <Radio key={key} value={key} className='radioItem'>
            <div className={`radioItemContainer ${isVenueEvent ? 'fullImg' : ''}`}>
              <img src={img} alt='' className='categoryImg' />
              <div className='categoryLabel'>{label}</div>
            </div>
          </Radio>
        ))}
      </Radio.Group>
    </div>
  )
}

export default EventsCheckbox
