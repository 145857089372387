import React, { useEffect, useState } from 'react'
import {
  Form,
  Radio,
} from 'antd'

import './index.styl'

const { Item } = Form

const Footer = ({ form, currentEventDetails }) => {
  const [selectedOption, setSelectedOptions] = useState('BUYER')
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
  }
  useEffect(() => {
    form.setFieldsValue({
      TLFee: currentEventDetails?.fees?.tlFee || 'BUYER',
    })
    form.setFieldsValue({
      paymentProcessorFee: currentEventDetails?.fees?.paymentProcessorFee || 'BUYER',
    })
  }, [currentEventDetails])
  return (
    <div className='MakeTicketfooterWrapper'>
      <div className='titleFees'>Fees</div>
      <div className='feesItemWrapper'>
        <Item
        // initialValue={currentEventDetails?.fees?.TLFee}
          name='TLFee'
          label='Ticket Lyfe Fee Will be Paid by'
          initialValue={selectedOption}
          className='itemFee'
        >
          <Radio.Group // disabled while draft creation/edition (draft event edited by venue user)
            onChange={onValueChange}
            value={selectedOption}
            className='radioFees tlFee'
          >
            <Radio value='BUYER'>
              <div className='radioText buyer'>Buyer of Ticket</div>
            </Radio>
            <Radio value='PROMOTER'>
              <div className='radioText'>Promoter</div>
            </Radio>
          </Radio.Group>
        </Item>
        <Item
          name='paymentProcessorFee'
          label='Payment Processing Fee Will be Paid by'
          initialValue={selectedOption}
          className='itemFee'
        // initialValue={currentEventDetails?.fees?.paymentProcessorFee}
        >
          <Radio.Group // disabled while draft creation/edition (draft event edited by venue user)
            onChange={onValueChange}
            value={selectedOption}
            className='radioFees'
          >
            <Radio value='BUYER'>
              <div className='radioText buyer'>Buyer of Ticket</div>
            </Radio>
            <Radio value='PROMOTER'>
              <div className='radioText'>Promoter</div>
            </Radio>
          </Radio.Group>
        </Item>
      </div>
    </div>
  )
}

export default Footer
