import React, { useEffect, useState } from 'react'
import { Form, Switch, Radio, Row, Col } from 'antd'
import { currencyCodes } from 'utils'
import './index.styl'
const { Item } = Form

const Buttons = ({ form, currentEventDetails }) => {
  const [switchValue, setSwitchValue] = useState(currentEventDetails?.isShowTLHomePage !== undefined ? currentEventDetails.isShowTLHomePage : true)
  const [donationTicketBool, setDonationTicketBool] = useState(currentEventDetails?.donationEnabled || false)
  const [currency, setCurrency] = useState(currencyCodes.USD.code)
  const handleSwitchChange = value => {
    setSwitchValue(value)
  }
  useEffect(() => {
    if (currentEventDetails) {
      setDonationTicketBool(currentEventDetails?.donationEnabled)
      form.setFieldsValue({
        donationEnabled: currentEventDetails?.donationEnabled,
      })
    }
  }, [currentEventDetails, form])

  const donationTicketHandler = () => {
    setDonationTicketBool(!donationTicketBool)
    form.setFieldsValue({
      donationEnabled: !donationTicketBool,
    })
  }
  const onChangeCurrency = ({ target: { value } }) => {
    setCurrency(value)
    form.setFieldsValue({ currency: value })
  }
  function chunkArray (arr, size) {
    return Array.from({ length: Math.ceil(arr.length / size) }, (_, index) =>
      arr.slice(index * size, (index + 1) * size),
    )
  }
  const sizes = window.innerWidth <= 1350 ? 2 : 3
  const sizesSpan = window.innerWidth <= 1350 ? 12 : 8
  return (
    <div className='buttonsWrapper'>
      <div className='switchButtonsWrapper'>
        <div className='switchWrapper'>
          <Item
            name='donationEnabled'
            valuePropName='checked'
            className=''
          >
            <Switch className='donationSwitch' onChange={donationTicketHandler} defaultChecked={donationTicketBool} />
          </Item>
          <div className='text'>Add Donation Ticket</div>
        </div>
        <div className='switchWrapper'>
          <Item
            name='isShowTLHomePage'
            valuePropName='checked'
            className=''
          >
            <Switch className='donationSwitch' defaultChecked={switchValue} onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Show tickets on TL Home Page</div>
        </div>
      </div>
      <Item
        className='currencySelectorLabel'
        label='Currency Selector'
        name='currency'
        rules={[{ required: true }]}
      >
        <Radio.Group className='radioCurrency' onChange={onChangeCurrency} value={currency}>
          {chunkArray(Object.keys(currencyCodes), sizes).map((row, rowIndex) => (
            <Row key={rowIndex} gutter={16}>
              {row.map(k => (
                <Col key={k} span={sizesSpan}>
                  <Radio value={currencyCodes[k]?.code}>
                    {`${currencyCodes[k]?.label} (${currencyCodes[k].symbol})`}
                  </Radio>
                </Col>
              ))}
            </Row>
          ))}
        </Radio.Group>
      </Item>
    </div>
  )
}

export default Buttons
