import React, { useState } from 'react'
import {
  Radio,
  Form,
} from 'antd'
import ChooseTemplateList from '../ChooseTemplateList'
import './index.styl'
import ManualCreationSeating from '../ManualCreationSeating'
const { Item } = Form
const SeatingCreationMode = ({ form, templateCover, setTemplateCover, selectedOption, setSelectedOption, refillSeatingsCategories, eventID }) => {
  // const [selectedOption, setSelectedOptions] = useState('default')
  const [templateUpdateCategory, setTemplateUpdateCategory] = useState(null)
  const onValueChange = event => {
    if (event.target.value === 'default') {
      refillSeatingsCategories([])
    }
    setSelectedOption(event.target.value)
  }
  const onUpdateCategory = () => {
    const catArr = form.getFieldValue('manualTemplateCategories')
    refillSeatingsCategories(catArr)
  }
  const onUpdateCategoryTemplate = () => {
    refillSeatingsCategories(templateUpdateCategory || [])
    // console.log('upd cat', catArr)
  }
  const templateSectionArray = form.getFieldValue('manualTemplateSections')
  return (
    <div className='creationModeWrapper'>
      <Item
        name='selectedOption'
        className='chooseCreation'
        initialValue={selectedOption}
      >
        <Radio.Group onChange={onValueChange} value={selectedOption}>
          <Radio checked defaultChecked={selectedOption === 'default'} value='default'>
            {' '}
            No Assigned and/or Table Seating
          </Radio>
          <Radio onChange={onUpdateCategoryTemplate} value='choose'>
            {' '}
            Choose from template
          </Radio>
          <Radio onChange={onUpdateCategory} value='manual'>
            {' '}
            Manual creation
          </Radio>
        </Radio.Group>
      </Item>
      {selectedOption === 'choose' ? (
        <ChooseTemplateList setTemplateUpdateCategory={setTemplateUpdateCategory} eventID={eventID} />
      ) : selectedOption === 'manual' ? (
        <ManualCreationSeating templateSectionArray={templateSectionArray} setTemplateCover={setTemplateCover} templateCover={templateCover} form={form} onUpdateCategory={onUpdateCategory} />
      ) : null}
    </div>
  )
}

export default SeatingCreationMode
