import React, { useState } from 'react'
import {
  Form,
  Input,
} from 'antd'

import SingleDatePicker from '../../../../../../../MakeTicketComponents/components/Header/DetailsFields/SingleDatePicker'
import TimePicker from '../../../../../../../MakeTicketComponents/components/Header/DetailsFields/TimePicker'

import './index.styl'

const { Item } = Form
const { TextArea } = Input

const EventForm = ({ name, onRemove, form, categoryData }) => {
  const [eventDate, setEventDate] = useState(categoryData?.date?.startDate)
  const [time, setTime] = useState(categoryData?.time || '')
  return (
    <div className='eventWrapper'>
      <div className='eventTopField'>
        <Item
          name={[name, 'name']} label='Event Name' rules={[
            { required: true, message: 'Event Name is required!' },
            { max: 100, message: 'Event Name cannot exceed 100 characters!' },
          ]}
          normalize={v => v.trimStart()}
        >
          <Input className='categorySession' placeholder='Event name' />
        </Item>
        <SingleDatePicker msDate namePrefix={name} label='Event Date' nameText='date' fromDate={eventDate} form={form} setFromDate={setEventDate} title='Event Date' />
        <TimePicker fieldName='upcomingEvents' onChange={setTime} namePrefix={name} nameText='time' msTime label='Time' time={time} form={form} />
      </div>
      <Item
        rules={[
          { required: false },
          { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
        ]}
        name={[name, 'description']}
        label='Additional Info'
        normalize={v => v.trimStart()}
      >
        <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
      </Item>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Event
        </div>
      </div>
    </div>
  )
}
export default EventForm
