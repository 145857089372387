import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { getEventV2 } from 'modules/events/events-actions'
import ThreeDotIcon from 'images/threedots.svg'

import './index.styl'
import { Popover, Tooltip } from 'antd'
const RenderTables = ({ color, table, isUser, sectionID, sectionType, seatCount, tableName, sectionName, seatLeft, selectedSeat, selectSeatArray, isReserved, seatsFree, onRemoveTable, onChangeEditTable, handleReserveTable, onChangeRename, currentEventDetails }) => {
  const [visible, setVisible] = useState(false)
  const [popoverVisible, setPopoverVisible] = useState(false)

  const onChangePopover = () => {
    setPopoverVisible(!popoverVisible)
  }
  const handleRemoveTable = () => {
    onRemoveTable(table)
    setPopoverVisible(false)
  }

  const handleEditTable = () => {
    onChangeEditTable(table)
    setPopoverVisible(false)
  }
  const handleRenameTable = () => {
    onChangeRename(table)
    setPopoverVisible(false)
  }
  const popoverContent = (
    <div className='actionPopover'>
      <div className='popoverBtn' onClick={handleRenameTable}>Rename</div>
      <div className='popoverBtn' onClick={handleEditTable}>
        Edit
      </div>
      <div className='popoverBtn' onClick={handleReserveTable}>{isReserved ? 'Unreserve Table' : 'Reserve Table'}</div>
      <div onClick={handleRemoveTable} className='popoverBtn popoverBtnRemove'>
        Remove Table
      </div>
    </div>
  )
  // const dispatch = useDispatch()
  // const onChangeLoading = () => setLoading(!loading)
  // const { id, userId } = useParams()
  const onChange = () => setVisible(!visible)
  // const test = group.seats.filter(i => i.isReserved)
  const isColorBlack = color === '#FFFF00' || color === '#FFCC00' || color === '#FF9900' || color === '#FF6600' || color === '#99CC00' || color === '#99FF00' || color === '#33FF00' || color === '#33CC00'
  return (
    <div className='tablesSeats'>
      <div>
        <Popover
          overlayClassName='actionPopoverContainer'
          getPopupContainer={node => node?.parentNode}
          content={popoverContent}
          placement='top'
          trigger='click'
          visible={popoverVisible}
          onVisibleChange={newOpen => setPopoverVisible(newOpen)}
        >
          <img src={ThreeDotIcon} className='threeDots' alt=' ' onClick={onChangePopover} />
          {isReserved
            ? (
              <div className='disableRectangleDefault' style={{ background: color }}>
                <Tooltip placement='top' title={tableName}>
                  <span
                    className='seatName'
                    style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
                  >
                    {tableName?.length > 17 ? `${tableName.substring(0, 16)}...` : tableName}
                  </span>
                </Tooltip>

                <div className='seatsLeftWrapper'>
                  <span
                    style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
                    className='seats'
                  >seats left
                  </span>
                  <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seatsNumber'>{seatsFree} of {seatCount} </span>
                </div>
              </div>
            ) : (
              <div onClick={onChange} className='tableRectangleDefault' style={{ background: color }}>
                <Tooltip placement='top' title={tableName}>
                  <span
                    className='seatName'
                    style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
                  >
                    {tableName?.length > 17 ? `${tableName.substring(0, 16)}...` : tableName}
                  </span>
                </Tooltip>
                <div className='seatsLeftWrapper'>
                  <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seats'>seats left</span>
                  <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seatsNumber'> {seatsFree} of {seatCount} </span>
                </div>
              </div>
            )}
        </Popover>
      </div>
    </div>
  )
}
export default RenderTables
