import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { CustomButton, Layout } from 'components'
import { history } from 'modules/router'
import alert from 'images/Alert.svg'
import block from 'images/BlockIcon.svg'
import shape from 'images/404-image.svg'
import shapeMobile from 'images/404-image-mobile.svg'
import './index.styl'

const NotFound = () => {
  const [status, setStatus] = useState('404')
  const { reason } = useParams()

  useEffect(() => {
    reason && setStatus(reason)
  }, [reason])

  const statuses = {
    404: {
      icon: alert,
      title: 'Sorry we can\'t find the page you\'re looking for',
      subtitle: '404',
    },
    blocked: {
      icon: block,
      title: 'The Event Was Blocked ',
      subtitle: 'Event was blocked',
    },
    'no-event': {
      icon: alert,
      title: 'Event with this URL has been cancelled or does not exist',
      subtitle: 'Event not found',
    },
  }

  const handleBack = () => history.push('/home')
  return (
    <Layout className='NotFound'>
      <div className='notFoundContainer'>
        <img src={statuses[status].icon} alt=' ' className='alertIcon' />
        <img src={shape} alt=' ' className='backgroundShape' />
        <img src={shapeMobile} alt=' ' className='mobileShape' />
        <div className='infoContainer'>
          <div className='notFoundTitle'>{statuses[status].title}</div>
          <div className='notFoundStatus'>{statuses[status].subtitle}</div>
          <CustomButton text='Back' type='dark' onClick={handleBack} />
        </div>
      </div>
    </Layout>
  )
}

export default NotFound
