import React from 'react'
import SectionScheme from './SectionScheme'
import { getCurrencySymbolByCode } from 'utils'
import { Counter } from 'components'
import moment from 'moment-timezone'
import './index.styl'
const BuySeatScheme = ({ loading, isUser, currency, ticketsCategory, clickBuySeat, setClickBuySeat, setLoading, selectSeatArray, selectedSeat, seatings, setAllowBuyTicket, setSelected, isEventCancelled, selectedTickets, timeZone }) => {
  const currencySymbol = getCurrencySymbolByCode(currency)
  return (
    <div className='templateSchemeWrapper'>
      {seatings?.sections.map((section, index) => {
        const tableCategory = ticketsCategory?.filter(category => category._id === section?.category?.ticketCategoryID && section?.tableSeatSelection === 'PROMOTER')
        return (
          <div key={index}>
            {section?.tableSeatSelection === 'BUYER'
              ? (<SectionScheme timeZone={timeZone} isUser={isUser} sectionID={section.id} clickBuySeat={clickBuySeat} seatings={seatings} setClickBuySeat={setClickBuySeat} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} loading={loading} setLoading={setLoading} section={section} />
              ) : (
                <>
                  {tableCategory?.map(category => {
                    const currentDateTime = moment().tz(timeZone)
                    const earlyBirdDateTime = moment.tz(category.earlyBirdDate, 'YYYY-MM-DD', timeZone)
                    const isEarlyBird = category?.isEarlyBird && currentDateTime.isSameOrBefore(earlyBirdDateTime)

                    const earlyBirdDate = isEarlyBird ? moment.tz(category.earlyBirdDate, timeZone).format('MM.DD.YYYY') : null
                    const selectedTicketsCount = selectedTickets.find(ticket => ticket?.id === category._id)?.count || 0
                    const getFixed = num => num % 1 === 0 ? num : num.toFixed(2)
                    const getPriceDisplay = () => {
                      return isEarlyBird
                        ? <><span>{currencySymbol}{getFixed(category?.earlyBirdPrice)}</span>&nbsp;<span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px', marginRight: '12px' }}>{currencySymbol}{getFixed(category?.price)}</span></>
                        : <span>{currencySymbol}{getFixed(category?.price)}</span>
                    }
                    return (
                      <div key={category._id} className='wrapperCategoryTable'>
                        <div className='categoryName'>{category?.categoryName}</div>
                        <div className='wrapperLeftRight'>
                          <div className='left'>
                            <div className='wrapperEarlyBirdPrice'>
                              <div className='price'>{getPriceDisplay()}</div>
                              {earlyBirdDate && (
                                <div className='earlyBirdDate'>"Early Bird Special" until {earlyBirdDate}</div>
                              )}
                            </div>
                          </div>
                          <div className='right'>
                            {category?.showAvailableTickets && category?.ticketCount.available !== 0 &&
                              <div className='ticketsCount'>
                                Available tickets:{' '}
                                {category?.ticketCount.available - selectedTicketsCount}
                                <span>/</span>
                                {category?.quantity}
                              </div>}
                            <div className='soldoutWrapper'>
                              {category?.ticketCount?.available === 0 && <div className='soldoutBage'>Sold Out</div>}
                              <Counter
                                setAllowBuyTicket={setAllowBuyTicket}
                                min={0}
                                max={category?.ticketCount?.available}
                                count={selectedTicketsCount}
                                setCount={num => setSelected(category._id, num, category)}
                                disabled={isEventCancelled || category?.ticketCount?.available === 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </>
              )}
          </div>

        )
      })}
    </div>
  )
}

export default BuySeatScheme
