import React, { useState } from 'react'
import {
  Form,
  Input,
} from 'antd'

import TimePicker from '../../../../../../../../MakeTicketComponents/components/Header/DetailsFields/TimePicker'

import './index.styl'

const { Item } = Form
const { TextArea } = Input

const HourForm = ({ name, onRemove, form, categoryData, fieldName }) => {
  const [time, setTime] = useState(categoryData?.time || '')
  return (
    <div className='hourWrapper'>
      <div className='hourTopField'>
        <Item
          name={[name, 'name']} label='Activity Name' rules={[
            { required: true, message: 'Activity Name is required!' },
            { max: 50, message: 'Activity Name cannot exceed 50 characters!' },
          ]}
          normalize={v => v.trimStart()}
        >
          <Input className='activityName' placeholder='Activity Name' />
        </Item>
        <TimePicker fieldName={fieldName} onChange={setTime} namePrefix={name} nameText='time' msTime label='Time' time={time} form={form} />
      </div>
      <Item
        rules={[
          { required: false },
          { max: 100, message: 'Additional Info cannot exceed 100 characters!' },
        ]}
        name={[name, 'description']}
        label='Additional Info'
        normalize={v => v.trimStart()}
      >
        <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
      </Item>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Activity
        </div>
      </div>
    </div>
  )
}
export default HourForm
