import React, { useState } from 'react'
import { template } from 'apiv2'
import { ModalTemplate } from '../../../../../components'

import './index.styl'
const RowSeats = ({ seat, color, categoriesArray, loading, setLoading, templateID }) => {
  const [visible, setVisible] = useState(false)
  // const [loading, setLoading] = useState(false)
  const [seatAccessebility, setSeatAccessebility] = useState(!!seat.isDisabled)
  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)
  const onFinish = form => {
    const { name, categoryID } = form
    if (!name || !categoryID) return
    onChangeLoading()
    const obj = { isDisabled: seatAccessebility, name, categoryID, id: seat.id }
    template.editSeat(templateID, obj).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }
  return (
    <div className='recWrapper'>
      {seat.isDisabled
        ? (
          <div onClick={onChange} className='emptyRectangle' />)
        : seat.isBought ? (
          <div
            className='orderSeat'
            style={
              color === '#FFFFFF' ||
              color === '#F2F2F2'
                ? { background: color, color: 'black' }
                : { background: color }
            }
          >
            {seat.seatName}
          </div>
        ) : (
          <div onClick={onChange} className='rectangleItem' style={color === '#FFFFFF' || color === '#F2F2F2' ? { background: color, color: 'black' } : { background: color }}>{seat.seatName}</div>)}
      <ModalTemplate additionalProps={{ isDisabled: seat.isDisabled, setSeatAccessebility, seatAccessebility, seatNumber: seat.seatName, categoriesArray, initialColor: seat?.category?.id }} visible={visible} onChange={onChange} onFinish={onFinish} name='seat' width={472} title='Edit Seat' loading={loading} />
    </div>
  )
}

export default RowSeats
