import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CustomButton, ModalProfile } from 'components'
import { changePassword } from 'modules/session/session-actions'
import lock from 'images/lock.png'

const ChangePassword = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)

  const onFinish = form => {
    const { currentPassword, confirmPassword, newPassword } = form
    if (!confirmPassword || !currentPassword || !newPassword || confirmPassword !== newPassword) return
    onChangeLoading()

    const obj = { oldPassword: currentPassword, newPassword }

    dispatch(changePassword(obj)).then(() => {
      setLoading(false)
      onChange()
    })
  }

  return (
    <div className='sectionProfile sectionProfileRow independenceSection'>
      <div className='left'>
        <img src={lock} alt='lock' />
        <div className='titleSection'>Change Password</div>
      </div>
      <CustomButton text='Change' type='white' onClick={onChange} />
      <ModalProfile visible={visible} onChange={onChange} onFinish={onFinish} name='password' loading={loading} />
    </div>
  )
}

export default ChangePassword
