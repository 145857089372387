import React from 'react'
import fileIcon from 'images/fileIcon.svg'
import { CloudDownloadOutlined } from '@ant-design/icons'
import './index.styl'

const Attachments = ({ attachments = [] }) => {
  return (
    <div className='rootAttachments'>
      {!!attachments?.length && (
        <>
          <div className='attachmentTitle'>Additional files</div>
          <div className='attachmentContainer'>
            {attachments?.map((file, idx) => (
              <div key={`${file.fileName}-${idx}`} className='attachmentContent'>
                {file?.thumbnail ? (
                  <img src={file?.thumbnail} alt=' ' />
                ) : (
                  <div className='iconContainer'><img src={fileIcon} alt='' className='fileIcon' /></div>
                )}
                <div className='infoContent'>
                  <div className='fileName'>{file?.fileName}</div>
                  <div className='fileSize'>{file?.fileSize}</div>
                </div>
                <div className='linkWrapper'>
                  <a href={file?.original} target='_blank' rel='noopener noreferrer' download>
                    <CloudDownloadOutlined className='fileDownload' />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default Attachments
