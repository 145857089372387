import React, { useState, useRef, useMemo, useEffect } from 'react'
import getDaysInMonth from '../utils/getDaysInMonth'
import { ReactComponent as CalendarIcon } from 'images/calendarIcon.svg'
import useClickOutside from '../hooks/useClickOutside'
import moment from 'moment'
import DateInputPopup from './DateInputPopup'
import DateItem from './DateItem'
import { Input } from 'antd'

function getDateSlots (currentMonth, currentYear) {
  const dateArray = getDaysInMonth(currentMonth, currentYear)
  const slotSkipCount = moment(dateArray[0]).day()

  for (let i = 0; i < slotSkipCount; i++) {
    dateArray.unshift(null)
  }

  return dateArray
}

const DateInput = props => {
  const popupRef = useRef()
  const [showPopup, setShowPopup] = useState(false)
  const [selectedDate, setSelectedDate] = useState('')
  const [currentMonth, setCurrentMonth] = useState(moment().month())
  const [currentYear, setCurrentYear] = useState(moment().year())
  useEffect(() => {
    if (props.value && props.value !== '') {
      const formattedDate = moment(props.value).format('YYYY-MM-DD')
      setSelectedDate(formattedDate)
    } else setSelectedDate('')
  }, [props.value])
  useClickOutside(popupRef, () => {
    setShowPopup(false)
  })
  const dateArray = useMemo(
    () => getDateSlots(currentMonth, currentYear),
    [currentMonth, currentYear],
  )

  const togglePopupHandler = () => {
    setShowPopup(currentShowPopup => {
      return !currentShowPopup
    })
  }

  const navigateMonthHandler = (navigateBy = 1) => {
    setCurrentMonth(currentState => {
      const newMonth = currentState + navigateBy
      if (newMonth === 12) {
        setCurrentYear(currentYear + 1)
        return 0
      }
      if (newMonth === -1) {
        setCurrentYear(currentYear - 1)
        return 11
      }
      return newMonth
    })
  }

  const selectDateHandler = date => {
    const formattedDate = moment(date).format('YYYY-MM-DD')
    props.onChange(formattedDate)
    setSelectedDate(formattedDate)
  }
  const handleInputBlur = () => {
    setSelectedDate('')
    props.form.setFieldsValue({
      ...props.form,
      [props.nameText]: props.value || '',
    })
    props.onChange(null)
    setShowPopup(false)
  }

  const handleSetButtonClick = () => {
    setShowPopup(false)
  }

  const today = moment()
  const isDateDisabled = date => {
    if (props?.title === 'Early Bird Date' && !props?.msDate) {
      const startDate = moment(props.form.getFieldValue('date'))
      const previousDate = startDate.clone().subtract(1, 'day')
      return !moment(date).isBetween(today, previousDate, 'day', '[]')
    } else {
      return moment(date).isBefore(today, 'day')
    }
  }
  const formatDateToMonth = selectedDate ? moment(selectedDate).format('MMMM DD') : ''
  return (
    <div ref={popupRef}>
      <Input
        value={props.namePrefix === undefined || props.title === 'Early Bird Date' ? selectedDate : formatDateToMonth} onFocus={togglePopupHandler} suffix={
          <CalendarIcon />
        }
        className={props.namePrefix !== undefined && props.title === 'Event Date' ? 'upEvent' : ''}
        placeholder={props.title === 'Early Bird Date' ? 'Select date' : ''}
      />

      {showPopup && (
        <DateInputPopup
          currentMonth={currentMonth}
          selectedDate={selectedDate}
          currentYear={currentYear}
          title={props.title}
          msDate={props?.msDate}
          navigateMonth={navigateMonthHandler}
          onCancel={handleInputBlur}
          onOk={handleSetButtonClick}
        >
          {dateArray.map((dateText, index) => {
            return (
              <DateItem
                key={index}
                disabledDate={date => isDateDisabled(date)} // Pass the function
                dateObj={dateText ? moment(dateText) : null}
                selected={selectedDate === dateText}
                onClick={() => selectDateHandler(dateText)}
              />
            )
          })}
        </DateInputPopup>
      )}
    </div>
  )
}

export default DateInput
