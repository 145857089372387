import api from './axios.v2'

const getFinancialReports = eventID => {
  return api(`reports/financeReports/${eventID}`, 'GET')
}

const reportByEvent = eventID => {
  return api(`boxOffice/${eventID}/seatCsv`, 'GET')
}

export default {
  getFinancialReports,
  reportByEvent,
}
