import React from 'react'
import {
  Form,
  Input,
  Select,
} from 'antd'
import './index.styl'
import MembershipLocationField from './MembershipLocationField'
import { urlValidator, mobileValidator } from 'modules/utils/validators'

import { MEMBERSHIP_URL, getMembershipUrl, timeZones } from 'utils'

import TooltipInfo from '../../../../CreateEventV2/TooltipInfo'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const DetailsFields = ({ form, currentPageDetails }) => {
  const eventUrlLabel = () => (
    <TooltipInfo
      text='Customized Membership Link (Vanity URL)'
      isMember
      info='The Customized Ticket Link can be a custom, memorable and pronounceable link to tickets for your event. Use this for marketing or to send people directly to your event. This feature is only available for paid ticket events'
    />
  )
  const validateName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('The Name of Manager or Managing Group is required'))
    }
    if (value.length > 100) {
      return Promise.reject(new Error('The Name of Manager or Managing Group  cannot exceed 100 characters'))
    }
    if (/^\d+$/.test(value)) {
      return Promise.reject(new Error('The Name of Manager or Managing Group  cannot contain only digits'))
    }
    return Promise.resolve()
  }
  return (
    <div className='detailsFieldsMembershipWrapper'>
      <div className='detailsFieldsWrapper'>
        <div className='rightLeftItems'>
          <Item
            rules={[{ required: true, validator: validateName }]}
            label='Name of Manager or Managing Group'
            className='defaultInputLabel haveMargin name'
            name='name'
            normalize={v => v.trimStart()}
          >
            <Input className='input' />
          </Item>
          <Item
            className='defaultInputLabel timezoneSelect'
            name='timeZone'
            label='Time Zone'
            rules={[{ required: true, message: 'The Time Zone is required' }]}
          >
            <Select listHeight={300}>
              {timeZones.map(({ value, label }) => (
                <Option key={value} value={value}>{label}</Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className='rightLeftItems'>
          <Item
            name='mobile'
            label='Phone Number (displayed on Home Page)'
            className='defaultInputLabel haveMargin mobile'
            rules={[{ required: true, message: 'The Phone Number is required' }, mobileValidator]}
          >
            <Input type='number' />
          </Item>
        </div>

        <div className='rightLeftItems'>
          <MembershipLocationField currentPageDetails={currentPageDetails} form={form} />
          <Item
            rules={[
              { required: true },
              { type: 'email', message: 'Please enter a valid email', transform: value => value?.trim() },
            ]}
            label='Manager’s Email'
            className='defaultInputLabel email'
            name='promoterEmail'
            normalize={v => v.trimStart()}
          >
            <Input />
          </Item>
        </div>
        <div className='membershipVanityWrapper'>
          <Item
            className='defaultInputLabel eventUrl'
            label={eventUrlLabel()}
            rules={[{ validator: urlValidator }]}
            name='eventUrl'
            normalize={v => v.trimStart()}
          >
            <Input prefix={`${MEMBERSHIP_URL}${getMembershipUrl('')}`} />
          </Item>
        </div>
        <div className='membershipNotes'>
          <Item
            rules={[
              { required: false },
              { max: 500, message: 'Notes cannot exceed 500 characters!' },
            ]}
            name='description'
            className='notes'
            label='Notes'
            normalize={v => v.trimStart()}
          >
            <TextArea autoSize={{ maxRows: 50 }} className='textArea' />
          </Item>
        </div>
      </div>
    </div>
  )
}

export default DetailsFields
