import React from 'react'
import { Modal } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CustomButton } from 'components'
import closeIconSvg from 'images/Crossmark.svg'
import './index.styl'

const PUBLIC_PATH = window.location.origin

const ModalEmbedCode = ({ eventID, onClose, onCopy }) => {
  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const embedCodeWidget = `<iframe
    style="border-style:none;width:300px;height:242px;border-radius:10px;"
    src='${PUBLIC_PATH}/widget${eventID}'
    cellSpacing='0'
    frameBorder='0'
    scrolling='no'
    border='0'
  />`
  return (
    <Modal
      maskStyle={{ background: '#62677EE5' }}
      className='ModalEmbedCode'
      closeIcon={closeIcon}
      onCancel={onClose}
      title='Embed code'
      maskClosable
      closable
      visible
    >
      <code>
        {embedCodeWidget}
      </code>
      <div className='actionContainer'>
        <CopyToClipboard text={embedCodeWidget} onCopy={onCopy}>
          <CustomButton text='Copy Code' type='dark' />
        </CopyToClipboard>
      </div>
    </Modal>
  )
}

export default ModalEmbedCode
