import React, { useState } from 'react'
// import { useParams } from 'react-router-dom'
// import { useDispatch } from 'react-redux'
// import { getEventV2 } from 'modules/events/events-actions'

import './index.styl'
import ModalReserveTables from './ModalReserveTables'
import { Tooltip } from 'antd'
const TableSeats = ({ color, isUser, sectionID, sectionType, seatCount, seatName, sectionName, seatLeft, selectedSeat, selectSeatArray, group, isTableInvite, isCategoryInvite }) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  // const onChangeLoading = () => setLoading(!loading)
  // const { id, userId } = useParams()
  const onChange = () => setVisible(!visible)
  const onFinish = () => {
    // onChangeLoading()
    // dispatch(getEventV2(id, userId)).then(() => {
    onChange()
    // setLoading(false)
    // })
  }
  // const test = group.seats.filter(i => i.isReserved)
  const isColorBlack = color === '#FFFF00' || color === '#FFCC00' || color === '#FF9900' || color === '#FF6600' || color === '#99CC00' || color === '#99FF00' || color === '#33FF00' || color === '#33CC00'
  return (
    <div className='tablesSeats'>
      {group.isDisabled || !group.seatsFree || group.blockSale || isTableInvite || isCategoryInvite
        ? (
          <div className='disableRectangle' style={{ background: color }}>
            <Tooltip placement='top' title={seatName}>
              <span
                className='seatName'
                style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
              >
                {seatName.length > 17 ? `${seatName.substring(0, 16)}...` : seatName}
              </span>
            </Tooltip>

            <div className='seatsLeftWrapper'>
              <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seats'>seats left</span>
              <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seatsNumber'>{group.seatsFree} of {seatCount} </span>
            </div>
          </div>
        ) : (
          <div onClick={onChange} className='tableRectangle' style={{ background: color }}>
            <Tooltip placement='top' title={seatName}>
              <span
                className='seatName'
                style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
              >
                {seatName.length > 17 ? `${seatName.substring(0, 16)}...` : seatName}
              </span>
            </Tooltip>
            <div className='seatsLeftWrapper'>
              <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seats'>seats left</span>
              <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seatsNumber'> {group.seatsFree} of {seatCount} </span>
            </div>
          </div>
        )}
      <ModalReserveTables isUser={isUser} sectionID={sectionID} sectionType={sectionType} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} group={group} sectionName={sectionName} visible={visible} onChange={onChange} onFinish={onFinish} name='reserveSeats' width={472} title='Choose Seats at the Table' setLoading={setLoading} loading={loading} />
    </div>
  )
}
export default TableSeats
