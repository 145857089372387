import React from 'react'

import Monday from './components/Monday'
import Tuesday from './components/Tuesday'
import Wednesday from './components/Wednesday'
import Thursday from './components/Thursday'
import Friday from './components/Friday'
import Saturday from './components/Saturday'
import Sunday from './components/Sunday'
import './index.styl'

const Hours = ({ currentPageDetails }) => {
  return (
    <div className='hoursWrapper'>
      <Monday currentPageDetails={currentPageDetails} />
      <Tuesday currentPageDetails={currentPageDetails} />
      <Wednesday currentPageDetails={currentPageDetails} />
      <Thursday currentPageDetails={currentPageDetails} />
      <Friday currentPageDetails={currentPageDetails} />
      <Saturday currentPageDetails={currentPageDetails} />
      <Sunday currentPageDetails={currentPageDetails} />
    </div>
  )
}

export default Hours
