import React from 'react'
import moment from 'moment'

import './index.styl'

const UpcomingEventsCard = ({ upcomingEvents }) => {
  // Function to convert time from 12 hour format to 24 hour format and format string
  const formatTime = (date, time) => {
    let hour = time.hour
    if (time.ampm.toLowerCase() === 'pm' && hour !== 12) {
      hour += 12
    } else if (time.ampm.toLowerCase() === 'am' && hour === 12) {
      hour = 0
    }
    const formattedHour = hour.toString().padStart(2, '0')
    const formattedMinute = time.minute.toString().padStart(2, '0')
    return `${date}T${formattedHour}${formattedMinute}00`
  }

  return (
    <div className='upcomingEventsCardWrapper'>
      <div className='title'>Upcoming Events</div>
      <div className='upcomingEventsCards'>
        {upcomingEvents?.map((event, key) => {
          const formattedMonth = moment(event.date).format('MMM')
          const formattedDate = moment(event.date).format('DD')

          const eventDate = moment(event.date).format('YYYYMMDD')
          const startTimeFormatted = formatTime(eventDate, event.time.start)
          const endTimeFormatted = formatTime(eventDate, event.time.end)

          const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(event.name)}&dates=${startTimeFormatted}/${endTimeFormatted}&details=${encodeURIComponent(event.description)}&location=${encodeURIComponent(location)}&sf=true&output=xml`

          return (
            <div key={key} className='upcomingEventCardWrapper'>
              <div className='upcomingEventCard'>
                <div className='month'>{formattedMonth.toUpperCase()}</div>
                <div className='date'>{formattedDate.toUpperCase()}</div>
                <div className='time'>{`${event.time.start.hour}:${event.time.start.minute.toString().padStart(2, '0')}${event.time.start.ampm.toUpperCase()} to ${event.time.end.hour}:${event.time.end.minute.toString().padStart(2, '0')}${event.time.end.ampm.toUpperCase()} `}</div>
              </div>
              <div className='eventDetailWrapper'>
                <div className='name'>{event.name}</div>
                <div className='description'>{event.description}</div>
                <a href={googleCalendarLink} target='_blank' rel='noopener noreferrer' className='addBtn'>Add To My Calendar</a>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default UpcomingEventsCard
