import React, { useState, useEffect } from 'react'
import { CustomButton, Layout } from 'components'
import { onValuesChange, useQuery, notify, notifySuccess } from 'utils'
import { Form, Input } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { eventsV2 } from 'apiv2'
import { pick } from 'lodash'
import './index.styl'

const { Item } = Form

const SendMail = () => {
  const { email, firstName, lastName } = useSelector(state => state?.session?.user || {})
  const [loading, setLoading] = useState(false)
  const { goBack } = useHistory()
  const [sendEmail] = Form.useForm()
  const query = useQuery()

  useEffect(() => {
    if (email) {
      sendEmail.setFieldsValue({ email })
    }
  }, [email])

  useEffect(() => {
    if (firstName || lastName) {
      sendEmail.setFieldsValue({ name: `${firstName} ${lastName}` })
    }
  }, [firstName, lastName])

  const onFinish = form => {
    setLoading(true)
    const props = { eventID: query.get('eventID'), ...pick(form, ['email', 'name', 'message']) }
    eventsV2.sendPromoterEmail(props)
      .then(() => {
        setLoading(false)
        notifySuccess('Message sent successfully.')
        sendEmail.resetFields()
        goBack()
      })
      .catch(({ response }) => {
        setLoading(false)
        notify(response?.data?.message)
      })
  }

  const FormItem = ({ name, rules, label, children }) => {
    return (
      <Item validateTrigger='onBlur' className='item' label={label} rules={rules} name={name}>
        {children}
      </Item>
    )
  }

  return (
    <Layout className='SendMail'>
      <div className='container smContainer'>
        <div className='headerSendEmail'>Send Mail</div>
        <Form
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          layout='vertical'
          className='form'
          form={sendEmail}
        >
          <FormItem rules={[{ type: 'email', required: true, message: 'Enter an existing email' }]} label='Email' name='email'>
            <Input className='input darkBg' />
          </FormItem>
          <FormItem name='name' label='User name'>
            <Input className='input darkBg' />
          </FormItem>
          <FormItem rules={[{ required: true, message: 'Enter message' }]} label='Message' name='message'>
            <Input.TextArea className='input textarea darkBg' />
          </FormItem>
          <div className='wrapperActions'>
            <CustomButton text='Send' type='dark' htmlType='submit' className='btn' loading={loading} />
          </div>
        </Form>
      </div>
    </Layout>
  )
}

export default SendMail
