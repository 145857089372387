import React from 'react'
import { CreateTemplate, Layout } from 'components'

const CreateNewTemplate = () => {
  return (
    <Layout>
      <CreateTemplate />
    </Layout>
  )
}

export default CreateNewTemplate
