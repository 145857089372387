import React from 'react'
// import Navigation from './Navigation'
import SliderHome from './SliderHome'
import './index.styl'

const Header = ({ loaded }) => {
  return (
    <div className='headerHome'>
      {/* <Navigation /> */}
      <SliderHome loaded={loaded} />
    </div>
  )
}

export default Header
