import React, { useEffect } from 'react'
import { Modal, Form, Input } from 'antd'

import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form
const { TextArea } = Input

const ModalEditNote = ({ visible = false, currentNote, onChange, onFinish, loading, noteTo }) => {
  const attr = { visible, onCancel: onChange, width: 472, maxWidth: 472, footer: null, className: 'wrapperModalEditNote', destroyOnClose: true }
  const [form] = Form.useForm()

  const textSubmit = 'Save'
  const initialValues = {
    notes: currentNote,
  }
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [currentNote])
  return (
    <Modal centered title={`Edit note to ${noteTo}`} {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish} initialValues={initialValues}>
        <Item name='notes' label={`Note to ${noteTo}`} className='item' rules={[{ required: false }]} validateTrigger='onBlur'>
          <TextArea maxLength={255} className='notesToPromoter' autoSize={{ maxRows: 50 }} />
        </Item>
        <div className='wrapperActions'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditNote
