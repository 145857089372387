import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyWalletTickets, filterTicketsV2, eventsGetMyWallets } from 'modules/events/events-actions'
import { Input, Select } from 'antd'
import './index.styl'
import searchIcon from 'images/input-search.svg'
import DropDownIcon from 'images/DropDownIcon.svg'
import RedArrow from 'images/RedArrow.svg'
import OrangeArrow from 'images/orangeArrow.svg'
import c from 'classnames'
const { Option } = Select

const filters = [
  {
    text: 'Sort by date',
    value: 'date (ascending)',
  },
  {
    text: 'Sort by date',
    value: 'date (descending)',
  },
  /* {
    text: 'Name (Ascending)',
    value: 'name (ascending)',
  },
  {
    text: 'Name (Descending)',
    value: 'name (descending)',
  },
  {
    text: 'Location (Ascending)',
    value: 'location (ascending)',
  },
  {
    text: 'Location (Descending)',
    value: 'location (descending)',
  }, */
]

const limit = 20
const Header = ({ valueSearch, onChangeSearch, setLoading, location, setSortType, setTotal, setCurrent, setLoadingPagination }) => {
  const { myWallets } = useSelector(state => state.events)
  const [skip, setSkip] = useState(0)
  const dispatch = useDispatch()
  useEffect(() => {
    window.dataLayer.push({ event: 'My member wallet' })
  }, [])

  const handleChangeState = value => {
    setLoading(true)
    setSortType(value)
    dispatch(
      eventsGetMyWallets({ accepted: [], pending: [] }),
    )
    dispatch(getMyWalletTickets({ type: value, limit, skip, search: valueSearch })).then(({ total, current }) => {
      setLoading(false)
      setTotal(total)
      setLoadingPagination(false)
      setCurrent(current)
      setSkip(skip)
    })
  }
  const handleFilter = value => dispatch(filterTicketsV2(value, myWallets))

  const searchPlaceholder = <img src={searchIcon} alt='search icon' />
  const DropDownArrow = <img src={DropDownIcon} alt='Drow down icon' />
  return (
    <div className='wrapperHeaderMyWallet'>
      <div className='title'>My wallet</div>

      <div className='right'>
        <Input
          className='input noFocusBorder'
          prefix={searchPlaceholder}
          onChange={e => onChangeSearch(e)}
          placeholder='Search'
          allowClear
        />
        <Select suffixIcon={DropDownArrow} defaultValue='ACTIVE' onChange={handleChangeState} className='filterState'>
          <Option value='ACTIVE'>Active</Option>
          <Option value='ARCHIVE'>Archive</Option>
        </Select>
        <Select suffixIcon={DropDownArrow} defaultValue='date (descending)' onChange={handleFilter} className='filterStateFilter'>
          {filters.map(i => <Option value={i.value} key={i.value}>{i.text} <img src={location.pathname.includes('member') ? OrangeArrow : RedArrow} className={c('sort-arrow', { 'sort-arrow-up': i.value.includes('ascending') })} /></Option>)}
        </Select>
      </div>
    </div>
  )
}

export default Header
