import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { notification, Modal, Popover } from 'antd'
import { CustomButton } from 'components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { inviteTicket } from 'modules/events/events-actions'
import CalendarIcon from 'images/Calendar.svg'
import copyLinkImg from 'images/copyLinkImg.svg'
import copyIcon from 'images/copyIcon.png'
import ThreeDotIcon from 'images/threedots.svg'
import moment from 'moment'
import { isVenue, getCurrencySymbolByCode, useQuery, getPagetUrl, isAdmin } from 'utils'
import './index.styl'

const { confirm } = Modal
const MainComponent = props => props.to ? <Link {...props} /> : <div {...props} />

const Row = props => {
  const isPromoteInvite = props?.inviteType === 'PROMOTE'
  const thisProps = isPromoteInvite ? props?.ticketID?.eventID : props
  const { startDate, endDate, eventName, summary, _id, eventUrl, isPromoted = false } = thisProps
  const dateEnd = moment(endDate).utcOffset(endDate).format('MMM D').split(' ')
  const dateStart = moment(startDate).utcOffset(startDate).format('MMMM D, YYYY, h:mm a')
  const dispatch = useDispatch()
  const query = useQuery()
  const { user } = useSelector(state => state.session)
  const state = { mainLink: '/member-office', activeTab: query.get('activeTab'), orderBy: query.get('orderBy'), page: query.get('page') }

  const handleEventPropagation = e => {
    onStop(e)
    onPrevent(e)
  }

  const inviteAction = status => dispatch(inviteTicket({ inviteID: props._id, status })).then(() => props.updateEvents('SUBPROMOTIONS'))
  const handleInvite = e => {
    handleEventPropagation(e)
    inviteAction('ACCEPTED')
  }
  const handleReject = e => {
    handleEventPropagation(e)
    confirm({
      title: 'Do you want to reject this invite?',
      onOk: () => inviteAction('REJECTED'),
    })
  }

  const onStop = e => {
    e && e.stopPropagation && e.stopPropagation()
  }
  const onPrevent = e => {
    e && e.preventDefault && e.preventDefault()
  }

  const handleCopy = () => notification.success({ message: 'Link copied to clipboard success' })

  const isVanity = eventUrl && eventUrl !== 'undefined'
  const getFullUrl = eventUrl => `${window.location.origin}${eventUrl}`
  const currentPromoter = isAdmin(user?.role) || isVenue(user?.role) ? props?.userID || props?.userID : user?._id
  const eventLink = isVanity ? getPagetUrl(eventUrl) : getPagetUrl(`${_id}/${currentPromoter}`)
  const currencySymbol = getCurrencySymbolByCode(props?.currency)
  const defaultProps = { className: 'TableEvent', onClick: onStop }
  const editProps = { pathname: `/member-office/member-details/member-edit/${_id}`, state }
  const viewProps = props?.isVenueDraft && props?.isVenueEvent ? { pathname: `/member-office/member-details/member-edit/${_id}`, state } : { pathname: `/member-office/member-details/member-categories/${_id}`, state }
  const containerProps = isPromoteInvite && props?.inviteStatus === 'PENDING'
    ? defaultProps
    : { to: viewProps, ...defaultProps }
  const popoverContent = (
    <div className='actionPopover'>
      {!thisProps?.isEventBlocked && !isVenue(user?.role) &&
        <div className='popoverBtn'><Link to={editProps} onClick={onStop}>Edit</Link></div>}
      <div className='popoverBtn'><Link to={viewProps} onClick={onStop}>View Detail</Link></div>
    </div>
  )
  return (
    <MainComponent {...containerProps}>
      <div className='content'>
        <div className='head'>
          <div className='left'>
            <div className='month'>{dateEnd[0]}</div>
            <div className='day'>{dateEnd[1]}</div>
          </div>
          <div className='right'>
            <div className='title'>
              {isPromoteInvite ? eventName + ` (${props?.ticketID?.ticketType})` : eventName}
              {(isPromoteInvite || isPromoted) && <div className='promotion_label'>Sub-Promotion</div>}
            </div>
            <div className='description'>
              <div className='startDate'>
                <img src={CalendarIcon} alt='calendar' />
                <div>{dateStart}</div>
              </div>
              <div className='links'>
                {!props.isVenueDraft &&
                  <div className='link'>
                    <img alt='place marker' className='copyIcon' src={copyLinkImg} />
                    <Link to={eventLink} className={isVanity ? 'vanityLink' : 'eventLink'} onClick={onStop}>
                      {isVanity ? getFullUrl(eventLink) : 'Membership Link'}
                    </Link>
                    <span onClick={onPrevent}>
                      <CopyToClipboard text={getFullUrl(eventLink)} onCopy={handleCopy}>
                        <img alt='copy' src={copyIcon} className='copyImg' />
                      </CopyToClipboard>
                    </span>
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <div className='footer'>
          {isPromoteInvite
            ? (
              <div className='buttons promotion'>
                <div className='section'>
                  <CustomButton onClick={handleInvite} text='Accept' type='white' />
                </div>
                <div className='section'>
                  <CustomButton onClick={handleReject} text='Reject' type='dark' />
                </div>
              </div>
            ) : (!isPromoteInvite &&
              <div className='statistic'>
                <div className='section'>
                  <div className='title'>Tickets Made</div>
                  <div className='number'>{summary?.ticketsMade}</div>
                </div>
                <div className='section'>
                  <div className='title'>On Hand</div>
                  <div className='number'>{summary?.onHand}</div>
                </div>
                <div className='section' hidden>
                  <div className='title'>Scanned</div>
                  <div className='number'>0</div>
                </div>
                <div className='section'>
                  <div className='title'>Online Sales</div>
                  <div className='number'>{`${currencySymbol}${summary?.onlineSales}`}</div>
                </div>
                {!isPromoted && (
                  <div className='buttons'>
                    <Popover
                      overlayClassName='actionPopoverContainer'
                      getPopupContainer={node => node?.parentNode}
                      content={popoverContent}
                      placement='topRight'
                      trigger='click'
                    >
                      <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                      <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
                    </Popover>
                  </div>)}
              </div>)}
        </div>
      </div>
    </MainComponent>
  )
}

export default Row
