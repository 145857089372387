import React, { useEffect, useState } from 'react'
import { Form, Switch } from 'antd'
import './index.styl'
const { Item } = Form

const Buttons = ({ form, currentPageDetails }) => {
  const [membershipSwitch, setMembershipSwitch] = useState(currentPageDetails?.isShowMSHomePage !== undefined ? currentPageDetails.isShowMSHomePage : true)
  const [donationTicketBool, setDonationTicketBool] = useState(currentPageDetails?.donationEnabled !== undefined ? currentPageDetails.donationEnabled : false)
  const [ownerApproval, setOwnerApproval] = useState(currentPageDetails?.ownerApproval !== undefined ? currentPageDetails.ownerApproval : false)

  const handleMembershipChange = value => {
    setMembershipSwitch(value)
    // form.setFieldsValue({
    //  isShowMSHomePage: !membershipSwitch,
    // })
  }

  useEffect(() => {
    if (currentPageDetails) {
      setDonationTicketBool(currentPageDetails?.donationEnabled)
      form.setFieldsValue({
        donationEnabled: currentPageDetails?.donationEnabled,
      })
      setOwnerApproval(currentPageDetails?.ownerApproval)
      form.setFieldsValue({
        ownerApproval: currentPageDetails?.ownerApproval,
      })
      setMembershipSwitch(currentPageDetails?.isShowMSHomePage)
      form.setFieldsValue({
        isShowMSHomePage: currentPageDetails?.isShowMSHomePage,
      })
    }
  }, [currentPageDetails, form])

  const donationTicketHandler = () => {
    setDonationTicketBool(!donationTicketBool)
    form.setFieldsValue({
      donationEnabled: !donationTicketBool,
    })
  }
  const ownerApprovalHandler = () => {
    setOwnerApproval(!ownerApproval)
    form.setFieldsValue({
      ownerApproval: !ownerApproval,
    })
  }

  return (
    <div className='membershipButtonsWrapper'>
      <div className='switchButtonsWrapper'>
        <div className='leftSide'>
          <div className='switchWrapper'>
            <Item
              name='ownerApproval'
              valuePropName='checked'
              className=''
            >
              <Switch className='donationSwitch' onChange={ownerApprovalHandler} defaultChecked={ownerApproval} />
            </Item>
            <div className='text'>Membership Approval Required</div>
          </div>
          <div className='switchWrapper'>
            <Item
              name='isShowMSHomePage'
              valuePropName='checked'
              className=''
            >
              <Switch className='donationSwitch' defaultChecked={membershipSwitch} onChange={handleMembershipChange} />
            </Item>
            <div className='text'>Show Membership on Home Page</div>
          </div>
        </div>
        <div className='rightSide'>
          <div className='switchWrapper'>
            <Item
              name='donationEnabled'
              valuePropName='checked'
              className=''
            >
              <Switch className='donationSwitch' onChange={donationTicketHandler} defaultChecked={donationTicketBool} />
            </Item>
            <div className='text donation'>Add Donation Ticket</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Buttons
