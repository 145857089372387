import React from 'react'
import { Image } from 'antd'
import './index.styl'
import DefaultImageTemplate from 'images/defaultTemplateMap.svg'
import SectionColor from '../BuySeatScheme/SectionColor'
const BuySeatColorPicker = ({ image, categories, timeZone }) => {
  return (
    <div className='buySeatColorPicker'>
      <div className='buySeatCategories'>
        {categories?.filter(c => !c.isInviteOnly).map(category => {
          return (
            <div key={category.index}>
              <SectionColor timeZone={timeZone} category={category} />
            </div>
          )
        })}
      </div>
      <div className='imageContainer'>
        {/* <div className='mapWrapper'> */}
        {image ? <Image className='image' preview={{ mask: 'see map', maskClassName: 'mapWrapper', src: image.sourceImage }} src={image.original} /> : <Image className='image' preview={{ mask: 'see map', maskClassName: 'mapWrapper' }} src={DefaultImageTemplate} />}
        {/* <div className='centered'>see map</div> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default BuySeatColorPicker
