import React from 'react'
import c from 'classnames'
import './index.styl'

const StepsModal = ({ activeScreen, userLogged = false, stepsItems = [] }) => {
  const steps = stepsItems.length ? stepsItems : [!userLogged && 'login', 'payment', 'tickets']
  const active = activeScreen === 'guest' ? 'login' : activeScreen

  return (
    <div className={c('wrapperStepsModal', { centered: userLogged })}>
      {steps.filter(i => i).map((i, ind) => (
        <div key={i} className={c('wrapperStep', { activeStep: active === i })}>
          <div className='wrapperCircle'>
            <div className='circle'>{ind + 1}</div>
            <div className='title'>{i}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default StepsModal
