import React, { createContext, useState } from 'react'
import { Layout } from 'components'
import c from 'classnames'
import Header from './Header'
import TableTemplates from './TableTemplates'
import './index.styl'

export const MyTemplatesContext = createContext(null)

const MyTemplates = () => {
  const [searchTag, setSearchTag] = useState('')
  const [loading, setLoading] = useState(true)
  const contextValue = { searchTag, setSearchTag, loading, setLoading }
  return (
    <Layout className={c('wrapperMyBoxOffice')}>
      <MyTemplatesContext.Provider value={contextValue}>
        <div className='container'>
          <Header />
          <TableTemplates />
        </div>
      </MyTemplatesContext.Provider>
    </Layout>
  )
}

export default MyTemplates
