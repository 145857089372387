import React, { useContext } from 'react'
import GeneralAdmissionCategory from './GeneralAdmission'
import RaffleCategory from './Raffle'
import TableCategory from './Table'
import LiveStreamCategory from './LiveStream'
import ItemTicketCategory from './ItemTicket'
import { CreateEventContext } from '../../../index'

import './index.styl'

const Categories = () => {
  const { currentEventDetails } = useContext(CreateEventContext)

  return (
    <div className='staticWidth'>
      <GeneralAdmissionCategory currentEventDetails={currentEventDetails} />
      <TableCategory currentEventDetails={currentEventDetails} />
      <RaffleCategory currentEventDetails={currentEventDetails} />
      <ItemTicketCategory currentEventDetails={currentEventDetails} />
      <LiveStreamCategory currentEventDetails={currentEventDetails} />
    </div>
  )
}

export default Categories
