import React, { useState, useRef, useEffect } from 'react'
import c from 'classnames'
import './index.styl'

const OverflowNotes = ({ notes, additionalInfo, style }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)
  const containerRef = useRef(null)

  useEffect(() => {
    checkOverflow()
  }, [notes])

  const isTextOverflow = element => {
    return element?.clientWidth < element?.scrollWidth || element?.clientHeight < element?.scrollHeight
  }
  const checkOverflow = () => {
    const element = containerRef.current

    const overflow = isTextOverflow(element)
    if (overflow !== isOverflow) {
      setIsOverflow(overflow)
    }
  }

  return (
    <div className='OverflowNotes'>
      <div className='notesTitle'>{additionalInfo ? 'Additional Info' : 'Notes'}</div>
      <div ref={containerRef} className={c('notesExpanded', { hidden: !isExpanded })} style={style}>{notes}</div>
      {isOverflow && (
        <div className='buttonExpanded' onClick={() => setIsExpanded(!isExpanded)}>
          Show {isExpanded ? 'Less' : 'More'}
        </div>
      )}
    </div>
  )
}

export default OverflowNotes
