import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Popover } from 'antd'
import { CustomButton, ModalEditTemplates } from 'components'
import moment from 'moment'
import { eventsV2 } from 'apiv2'
import ThreeDotIcon from 'images/threedots.svg'
import { useQuery } from 'utils'
import './index.styl'

const MainComponent = props => props.to ? <Link {...props} /> : <div {...props} />

const Row = props => {
  const { created, seats, sections, id, templateName } = props
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const query = useQuery()
  const state = { mainLink: '/my-templates', page: query.get('page') }
  const handleEventPropagation = e => {
    onStop(e)
    onPrevent(e)
  }
  const onChange = e => {
    handleEventPropagation(e)
    setVisible(!visible)
  }
  const onChangeLoading = () => setLoading(!loading)
  const deleteTemplateHandler = e => {
    handleEventPropagation(e)
    props.onDeleteEvent(id)
  }
  const onFinish = form => {
    const { newName } = form
    if (!newName) return
    onChangeLoading()
    const obj = { newName }
    eventsV2.renameTemplate(id, obj).then(() => {
      setLoading(false)
      if (props.prevName !== newName) {
        props.setPageRefresh(newName)
      }
      onChange()
    }).catch(() => setLoading(false))
  }

  const onStop = e => {
    e && e.stopPropagation && e.stopPropagation()
  }
  const onPrevent = e => {
    e && e.preventDefault && e.preventDefault()
  }

  const popoverContent = (
    <div className='actionPopover'>
      <div className='renameTemplate'>
        <div className='popoverBtn' onClick={onChange}>Rename template</div>
      </div>
      <div className='popoverBtn popoverBtnRemove' onClick={deleteTemplateHandler}>Delete template</div>
    </div>
  )
  const creationDate = moment(created).utcOffset(created).format('MMMM D, YYYY')
  const viewProps = { pathname: `/my-templates/${id}`, state }
  const defaultProps = { className: 'TableTemplate' }
  const containerProps = visible ? { ...defaultProps } : { to: viewProps, ...defaultProps }
  return (
    <MainComponent {...containerProps}>
      <div className='content'>
        <div className='head'>
          <div className='right'>
            <div className='title'>
              {templateName}
            </div>
          </div>
        </div>
        <div className='footer'>
          <div className='statistic'>
            <div className='section'>
              <div className='title'>Sections</div>
              <div className='number'>{sections}</div>
            </div>
            <div className='section'>
              <div className='title'>Seats</div>
              <div className='number'>{seats}</div>
            </div>
            <div className='section'>
              <div className='title'>Creation date</div>
              <div className='number'>{creationDate}</div>
            </div>

            <div className='buttons'>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={popoverContent}
                placement='bottomRight'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <ModalEditTemplates templateName={templateName} visible={visible} onChange={onChange} onFinish={onFinish} loading={loading} />
    </MainComponent>
  )
}

export default Row
