import React, { useState, createContext, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { CustomButton } from 'components'
import { Collapse, Form, notification, Button, Divider } from 'antd'
import { isVenue, currencyCodes, eventTypes, streamTypes, prepareAttachments } from 'utils'
import moment from 'moment'
import c from 'classnames'
import './index.styl'
import EventsCheckbox from './EventsCheckbox'
import expandIcon from 'images/expandIcon.svg'
import backIcon from 'images/DropDownIcon.svg'
import doneIcon from 'images/doneIcon.svg'
import ModalChoosePromotion from '../ModalChoosePromotion'
import MultiMediaContainer from './MultiMediaContainer'
import ModalBuyStreamTicket from './ModalBuyStreamTicket'
import ModalInfo from './ModalInfo'
import Fees from './Fees'
import DetailsEventVenue from './DetailsEventVenue'
import DetailsEvent from './DetailsEvent/index'
import OptionsEvent from './OptionsEvent/index'
import TicketCategory from './TicketCategory'
import { trimObject } from 'modules/utils'
import SeatingCreationMode from './SeatingCreationMode'
// TODO: all this events (create/delete ticketCategory, create/edit event/venue) should be on V2
import {
  updateEventV2,
  updateEventVenueV2,
  addTicketCategoryV2,
  removeTicketCategoryV2,
  createEventV2,
  createEventVenueV2,
  // getEventDetails,
  eventsSetImages,
  setEventsIsStream,
} from 'modules/events/events-actions'
import ModalRaffleCategory from '../ModalRaffleCategory'

export const CreateEventContext = createContext(null)
const { List } = Form
const { Panel } = Collapse
const CreateEventV2 = ({ currentEventDetails }) => {
  const dispatch = useDispatch()
  const eventDetailsRef = useRef(null)
  // const { currentEventDetails } = useSelector(state => state.events)
  const [eventType, setEventType] = useState(currentEventDetails?.eventType || eventTypes.TICKETED_ADMITION)
  const [currency, setCurrency] = useState(currentEventDetails?.currency || currencyCodes.USD.code)
  const [streamType, setStreamType] = useState(null)
  const [streamQuantity, setStreamQuantity] = useState(null)
  const { user: { isStripeAccount, role, venueName, address } } = useSelector(state => state.session)
  const { goBack, push } = useHistory()
  const { eventID } = useParams()
  const { state = {} } = useLocation()
  const [form] = Form.useForm()
  const { imageUrls, filesAttachment } = useSelector(state => state.events)
  const [finished, setFinished] = useState(false)
  const [modalBuyStream, setModalBuyStream] = useState(null)
  const [nonProfitOrganization, setNonProfitOrganization] = useState(currentEventDetails?.saleTax?.nonProfit || false)
  const [activePanel, setActivePanel] = useState(['eventType'])
  const [eventDetailsStepDone, setEventDetailsStepDone] = useState(!!currentEventDetails?._id)
  const [modalInfo, setModalInfo] = useState({ visible: false, message: 'You have no co-promoter for this event', data: {} })
  const [isStepTwo, setIsStepTwo] = useState(false)
  const [showModalPromotion, setShowModalPromotion] = useState(false)
  // const [venuePromoterOff, setVenuePromoterOff] = useState(!!currentEventDetails?.imageUrl || false)
  // const [isDraftVenueEvent, setIsDraftVenueEvent] = useState(!!currentEventDetails?.isVenueDraft || false)
  const [isVenueEvent, setIsVenueEvent] = useState(!!currentEventDetails?.isVenueEvent || false)
  const [stateComplete, setStateComplete] = useState(false)
  const [streamingEnabled, setStreamingEnabled] = useState(eventType === eventTypes.TICKETED_ADMITION_STREAM)
  const [draftConstructing, setDraftConstructing] = useState(false)
  const [templateCover, setTemplateCover] = useState('')
  const [selectedOption, setSelectedOption] = useState('default')
  const [assignSeatingStepDone, setAssignSeatingStepDone] = useState(!!currentEventDetails?._id)
  const [showAssignSeatingsPanel, setShowAssignSeatingsPanel] = useState()
  const [updatePrivacyType, setUpdatePrivacyType] = useState(!currentEventDetails && 'visible')
  const [addNewCategory, setAddNewCategory] = useState(false)
  const [visibleModalRaffle, setVisibleModalRaffle] = useState(false)
  const [isRaffleModalActive, setIsRaffleModalActive] = useState(false)
  // console.log('draftConstructing', draftConstructing)
  // const collapseRef = useRef(null)
  useEffect(() => {
    if (Object.keys(currentEventDetails?.imageUrl || {}).length) {
      dispatch(eventsSetImages(currentEventDetails?.imageUrl))
    }
    return () => dispatch(eventsSetImages({}))
  }, [currentEventDetails?.imageUrl])

  useEffect(() => {
    setShowAssignSeatingsPanel(!draftConstructing && [eventTypes.TICKETED_ADMITION, eventTypes.TICKETED_ADMITION_STREAM].includes(eventType))
  }, [eventType, draftConstructing])

  const refillSeatingsCategories = arr => {
    const catArr = form.getFieldValue('categories').map(i => {
      if (i.isDefault) {
        delete i.isDefault
      }
      return i
    })

    const streams = catArr.filter(i => i.isStreaming)
    // (i.price || i.quantity || i.bgColor) - if General Admission is not touched by user it will be removed
    const regulars = catArr.filter(i => !i.isStreaming && !i.isSeatingCategory && (i.price || i.quantity || i.bgColor))
    const newSeatingsCats = arr.map(i => {
      return {
        isSeatingCategory: true,
        privacyOption: 'visible',
        name: i.name,
        bgColor: i.color,
        price: i.price,
        quantity: i.seatCount || 0,
        templateCategoryID: i.id,
      }
    })

    // if we deleted seatings and no regular cats
    if (!newSeatingsCats.length && !regulars.length) {
      regulars.push({
        name: 'General Admission',
        privacyOption: 'visible',
        isDefault: true,
      })
    }

    if (newSeatingsCats.length) {
      newSeatingsCats[0].type = 'DESIGNATED'
    } else if (regulars.length) {
      regulars[0].isDefault = true
    } else if (streams.length) {
      streams[0].isDefault = true
    }
    form.setFieldsValue({ categories: [...streams, ...newSeatingsCats, ...regulars] })
  }
  // this function is applicable to new event. It should rebuild the categories array according to event type
  const changeEventType = type => {
    switch (type) {
      case eventTypes.TICKETED_ADMITION:
        if (eventType === eventTypes.STREAM) {
        // delete stream, add regular
          const newCat = { isDefault: true, name: 'General Admission' }
          form.setFieldsValue({ categories: [newCat] })
        } else if (eventType === eventTypes.TICKETED_ADMITION_STREAM) {
        // delete stream
          const catArr = form.getFieldValue('categories').filter(i => !i.isStreaming)
          form.setFieldsValue({ categories: catArr })
        }
        break
      case eventTypes.TICKETED_ADMITION_STREAM:
        if (eventType === eventTypes.STREAM) {
        // leave stream but unmark isDefault and add regular category
          const newCat = { isDefault: true, name: 'General Admission' }
          const catArr = form.getFieldValue('categories').map(i => {
            if (i.isDefault) {
              delete i.isDefault
            }
            return i
          })
          catArr.push(newCat)
          form.setFieldsValue({ categories: catArr })
        } else {
        // just add stream category in the start
          const catArr = form.getFieldValue('categories')
          const newCat = {
            isStreaming: true,
            privacyOption: 'visible',
            isPrivateStream: false,
            name: streamTypes.PPV_STREAM_CATEGORY_NAME,
            streamType: streamTypes.PPV_STREAM,
            quantity: 10000,
          }
          form.setFieldsValue({ categories: [newCat, ...catArr] })
        }
        break
      case eventTypes.STREAM:
        if (eventType === eventTypes.TICKETED_ADMITION_STREAM) {
        // leave only stream, make it deafault
          const catArr = form.getFieldValue('categories').filter(i => i.isStreaming)
          form.setFieldsValue({ categories: [{ ...catArr, isDefault: true }] })
        } else {
        // remove all, add stream
          const newCat = {
            isStreaming: true,
            privacyOption: 'visible',
            isPrivateStream: false,
            name: streamTypes.PPV_STREAM_CATEGORY_NAME,
            streamType: streamTypes.PPV_STREAM,
            quantity: 10000,
            isDefault: true,
          }
          form.setFieldsValue({ categories: [newCat] })
        }
        break
      case eventTypes.VIRTUAL:
        if (eventType === eventTypes.STREAM) {
        // remove all, add regular
          const newCat = { isDefault: true, privacyOption: 'visible' }
          form.setFieldsValue({ categories: [newCat] })
        } else {
        // remove all but default
          const catArr = form.getFieldValue('categories').filter(i => i.isDefault)
          form.setFieldsValue({ categories: catArr })
        }
        break
      default:
        break
    }
    setEventType(type)
  }

  const prepareHeaderPanel = (title, number, isDone = false) => (
    <div className='headerContainer'>
      <div className={c('circle', { isDone })}>
        {isDone ? <img src={doneIcon} alt=' ' /> : number}
      </div>
      <div className='panelTitle'>{title}</div>
    </div>
  )
  const onSetEventDetails = () => {
    if (!!eventType) {
      !draftConstructing ? setActivePanel(['multimedia']) : setActivePanel(['eventDetails'])
    } else {
      notification.warning({ message: 'Choose an event type' })
    }
  }

  useEffect(() => {
    if (isVenue(role)) {
      if (!currentEventDetails) {
        setShowModalPromotion(true)
      } else if (currentEventDetails?.isVenueDraft) {
        setDraftConstructing(true)
      }
    }
  }, [])

  const handleBack = () => {
    if (eventID && !currentEventDetails?.isVenueDraft) {
      push({ pathname: `/my-box-office/event-details/tickets-categories/${eventID}`, state })
    } else {
      push({ pathname: '/my-box-office/table-events', state })
    }
  }

  const validateEvent = e => {
    if (e?.errorFields?.length === 1 && !e?.values?.saleTaxPercent) {
      setActivePanel(['fees'])
      return
    }
    if (e?.errorFields?.length) {
      setActivePanel(['eventDetails'])
    } else {
      setStateComplete(true)
    }
  }

  const dateTransform = date => {
    return `${moment(date).format('YYYY-MM-DD')}T${moment(date).format('HH:mm')}`
  }

  const onCloseModal = () => {
    setFinished(false)
    setModalBuyStream(null)
  }
  const onSubmitBought = (cardToken, promo) => {
    return new Promise(resolve => {
      const data = { ...modalBuyStream, cardToken }
      if (promo) {
        data.promo = promo
      }
      if (currentEventDetails?.isVenueDraft) {
        dispatch(updateEventV2(data, eventID))
          .then(() => {
            if (streamingEnabled) {
              dispatch(setEventsIsStream(true))
            }
            if (eventType === 'stream') {
              dispatch(setEventsIsStream(true))
            }
            onCloseModal()
            handleBack()
            resolve()
          }).catch(resolve)
      } else {
        dispatch(createEventV2(data))
          .then(response => {
            if (response) {
              if (streamingEnabled) {
                dispatch(setEventsIsStream(true))
              }
              if (eventType === 'stream') {
                dispatch(setEventsIsStream(true))
              }
              onCloseModal()
              handleBack()
            }
            resolve()
          }).catch(resolve)
      }
    })
  }

  const handleFinish = async values => {
    if (finished) return
    const eventData = {
      eventID,
      eventName: values.eventName,
      startDate: dateTransform(values.startDate),
      endDate: dateTransform(values.endDate),
      timeZone: values.timeZone,
      isDonationEnable: values.donationEnabled,
      isShowTLHomePage: values.isShowTLHomePage,
      currency,
      eventUrl: values.eventUrl,
      presentedBy: values.presentedBy,
      eventType,
      rainDate: values.rainDate,
      description: values.description,
      showAvailableTickets: values.showAvailableTickets || false,
    }
    if (!Object.keys(imageUrls).length) {
      return notification.warning({ message: 'Upload image for ticket' })
    } else {
      // eventData.imageUrl = JSON.stringify(imageUrls)
      eventData.imageUrl = imageUrls
    }
    if (filesAttachment.length) {
      eventData.attachments = JSON.stringify(prepareAttachments(filesAttachment))
      eventData.attachments = prepareAttachments(filesAttachment)
    }
    if (moment(values.endDate).isSameOrBefore(values.startDate, 'minutes')) {
      return notification.warning({ message: 'Start time should be earlier than end time.' })
    }
    if (eventType === eventTypes.VIRTUAL) {
      const { zoomUrl, zoomPassword, zoomEventParticipants } = values
      const virtualEvent = {
        url: zoomUrl,
        maxParticipants: +zoomEventParticipants,
        password: zoomPassword,
      }
      trimObject(virtualEvent)
      // eventData.virtualEvent = JSON.stringify(virtualEvent)
      eventData.virtualEvent = virtualEvent
    } else if (eventType === eventTypes.STREAM) {
      const streamCat = values.categories.find(i => i.isStreaming)
      eventData.hasStream = true
      eventData.hasPrivateStream = streamCat.streamType === streamTypes.PRIVATE_STREAM
      eventData.hasPpvStream = streamCat.streamType === streamTypes.PPV_STREAM
      eventData.address = values.location
      eventData.venue = values.venue
    } else if (eventType === eventTypes.TICKETED_ADMITION) {
      eventData.address = values.location
      eventData.venue = values.venue
    } else if (eventType === eventTypes.TICKETED_ADMITION_STREAM) {
      eventData.address = values.location
      eventData.venue = values.venue
    }
    const categories = values.categories.map(i => {
      const { _id, isStreaming, streamType, name, quantity, price, bgColor, isEarlyBird, earlyBirdDate, earlyBirdPrice, privacyOption, isSeatingCategory, templateCategoryID, type, isRaffleCategory } = i
      if (isStreaming) {
        setStreamQuantity(quantity)
        setStreamType(streamType)
      }
      const cat = {
        ticketID: _id || undefined,
        isStreaming: !!isStreaming,
        ticketType: name,
        isRaffleCategory: !!isRaffleCategory,
        quantity: quantity || 0,
        price,
        bgColor,
        isEarlyBird: !!isEarlyBird,
        earlyBirdDate,
        earlyBirdPrice,
        isInviteOnly: privacyOption === 'invite',
        isPrivateStream: streamType === streamTypes.PRIVATE_STREAM,
        isSeatingCategory,
        templateCategoryID,
      }
      if (type === 'DESIGNATED') {
        cat.isSeatingCategory = true
      }
      // check for new subpromotions
      if (i.subpromoters) {
        const subpromoters = i.subpromoters.filter(j => j.isNew)
        if (subpromoters.length) {
          const subpromotersNewArray = subpromoters.map(obj => {
            return {
              isNew: obj.isNew,
              subPromotersEmail: obj.subPromotersEmail.toLowerCase(),
              vanityURL: obj.vanityURL,
              subPromotersQuantity: obj.subPromotersQuantity,
            }
          })
          cat.subpromoters = subpromotersNewArray
        }
      }

      trimObject(cat)
      return cat
    })

    if (eventID) {
      // delete removed cats
      const updCatsIds = categories.map(c => c.ticketID).filter(Boolean)
      const toRemove = currentEventDetails.tickets
        ?.slice(1)
        ?.filter(i => !i.isStreaming)
        ?.filter(i => !updCatsIds.includes(i._id))
      if (toRemove.length) {
        await Promise.all(toRemove.map(async ticket => {
          dispatch(removeTicketCategoryV2({ eventID, ticketID: ticket?._id }, eventID))
        }))
      }

      // add new created cats and fiill their ids
      await Promise.all(categories.map(async c => {
        const toAddCategory = { eventID, category: c }
        if (!c.ticketID) {
          await dispatch(addTicketCategoryV2(toAddCategory, eventID)).then(res => {
            if (res.data.data) {
              c.ticketID = res.data.data._id
            }
          })
        }
      }))
    }
    if (values?.templateID) {
      eventData.templateID = values?.templateID
    }
    if (values?.manualTemplateCategories || values?.manualTemplateSections) {
      const assignedSeatings = {}
      if (values?.manualTemplateCategories) {
        assignedSeatings.categories = values?.manualTemplateCategories
      }
      if (values?.manualTemplateSections) {
        const sections = values?.manualTemplateSections.map(section => {
          return {
            name: section.sectionName,
            type: section.sectionType,
            rowArrangement: section.rowArrangement,
            tableSeatSelection: section.tableSeatSelection ? 'BUYER' : 'PROMOTER',
            seatCount: section.sectionColumns || section.tableCapacity,
            groupCount: section.sectionRows || section.tableCount,
          }
        })
        assignedSeatings.sections = sections
      }
      if (templateCover) {
        assignedSeatings.imageUrl = templateCover
      }
      // if (values?.templateName) {
      //   assignedSeatings.templateName = values?.templateName
      // }
      eventData.assignedSeatings = assignedSeatings
    }

    // TODO: next till the end of the procedure should be studied, it was just copypasted from old version
    const fees = {}
    const saleTax = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.stripeVenmoFee) {
      fees.paymentProcessorFee = values?.stripeVenmoFee
    }
    if (values?.saleTaxCoverage) {
      saleTax.coverage = values?.saleTaxCoverage
    }
    if (values?.saleTaxPercent) {
      saleTax.percent = values?.saleTaxPercent
    }
    if (nonProfitOrganization && isVenueEvent) {
      saleTax.nonProfit = nonProfitOrganization
    }
    if (values?.taxNumber) {
      saleTax.EIN = `${values?.taxNumber}`
    }
    if (isVenue(role)) {
      eventData.saleTax = saleTax
    }
    eventData.ticketType = categories
    // eventData.fees = JSON.stringify(fees)
    eventData.fees = fees
    eventData.saleTax = saleTax
    const action = eventID ? updateEventV2(eventData, eventID) : createEventV2(eventData)
    setFinished(true)
    setEventDetailsStepDone(true)
    trimObject(eventData)

    if ([eventTypes.TICKETED_ADMITION_STREAM, eventTypes.STREAM].includes(eventType) &&
      (!eventID || currentEventDetails?.isVenueDraft)) {
      setModalBuyStream({ ...eventData })
    } else {
      dispatch(action).then(() => {
        setFinished(false)
        handleBack()
      }).catch(() => setFinished(false))
    }
  }

  const handleFinishVenueDraftCreation = async values => {
    if (isStepTwo) {
      return false
    }

    const eventData = {
      eventID,
      startDate: dateTransform(values.startDate),
      endDate: dateTransform(values.endDate),
      venue: venueName,
      eventName: values.eventName,
      address: address,
      timeZone: values.timeZone,
      promotersEmail: values.promotersEmail,
      countryCode: '+1',
      promotersMobile: values?.promotersMobile,
      promotersName: values?.promotersName,
      eventType,
    }

    if (values?.eventPayout) {
      eventData.eventPayout = values?.eventPayout
    }

    const fees = {}
    const saleTax = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.stripeVenmoFee) {
      fees.paymentProcessorFee = values?.stripeVenmoFee
    }
    if (values?.saleTaxCoverage) {
      saleTax.coverage = values?.saleTaxCoverage
    }
    if (values?.saleTaxPercent) {
      saleTax.percent = values?.saleTaxPercent
    }
    if (nonProfitOrganization) {
      saleTax.nonProfit = nonProfitOrganization
    }
    if (values?.taxNumber) {
      saleTax.EIN = `${values?.taxNumber}`
    }
    eventData.saleTax = saleTax
    eventData.fees = fees

    const action = eventID ? updateEventVenueV2(eventData, eventID) : createEventVenueV2(eventData)
    setFinished(true)
    setEventDetailsStepDone(true)
    // trimObject(eventData)

    dispatch(action).then(() => {
      setFinished(false)
      handleBack()
    }).catch(() => setFinished(false))
  }

  const venueWithoutPromoter = t => {
    setIsVenueEvent(true)
    setDraftConstructing(!t)
  }
  const prepareIcon = ({ isActive }) => <img src={expandIcon} alt=' ' className={c('expandIcon', { isActive })} />
  const prepareBackIcon = <img src={backIcon} alt=' ' className='iconBtn' />
  const PreviousBtn = ({ onClick }) => (
    <Button icon={prepareBackIcon} onClick={onClick} className='stepBtn' type='link'>Previous Step</Button>
  )
  const onSetMultimedia = async () => {
    if (!Object.keys(imageUrls).length) {
      notification.warning({ message: 'Upload image for ticket' })
    } else if (eventID && !currentEventDetails?.isVenueDraft || eventType === eventTypes.STREAM || eventType === eventTypes.VIRTUAL) {
      setActivePanel(['eventDetails'])
    } else {
      setActivePanel(['assignedSeating'])
    }
  }
  const validateEventDetails = () => {
    const { endDate, startDate, eventName, timeZone, promotersEmail, promotersName, promotersMobile } = form.getFieldsValue()
    if (endDate && startDate && eventName && timeZone && promotersEmail && promotersName && promotersMobile) {
      setActivePanel(['fees'])
      setEventDetailsStepDone(true)
      return true
    } else {
      return false
    }
  }

  const validateAssign = () => {
    const { manualTemplateSections, selectedOption, templateID } = form.getFieldsValue()
    const validSectionRows = manualTemplateSections?.every(item => item.sectionRows > 0)
    const validSectionColumns = manualTemplateSections?.every(item => item.sectionColumns > 0)
    if (templateID || selectedOption === 'default' || validSectionRows && validSectionColumns) {
      setActivePanel(['eventDetails'])
      setAssignSeatingStepDone(true)
      // collapseRef.current.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      //   inline: 'nearest',
      // }) // initiate scroll to the "AddMock" Accordion component
      return true
    } else if (selectedOption === 'choose' && !templateID) {
      notification.warning({ message: 'Choose template' })
    } else {
      setActivePanel(['eventDetails'])
    }
  }

  const getTicketData = idx => {
    const categories = form.getFieldValue('categories')
    return categories[idx]
  }
  // we make categories array with stream in the beginning of array, and define the default category to display currency block
  const categories = []
  if (eventID) {
    // fill categories for initialValues by data from existing event
    const streamCategory = currentEventDetails?.tickets?.find(i => i.isStreaming)
    if (streamCategory) {
      streamCategory.earlyBirdDate = streamCategory.isEarlyBird ? moment(streamCategory?.earlyBirdDate, 'YYYY/MM/DD') : ''
      streamCategory.name = streamCategory.ticketType
      streamCategory.bgColor = streamCategory.bgColor || streamCategory.bgColor
      streamCategory.streamType = streamCategory.isPrivateStream ? streamTypes.PRIVATE_STREAM : streamTypes.PPV_STREAM
    }
    const regularCategories = currentEventDetails?.tickets?.filter(i => !i.isStreaming).map(i => ({
      ...i,
      earlyBirdDate: i.isEarlyBird ? moment(i?.earlyBirdDate, 'YYYY/MM/DD') : '',
      bgColor: i.bgColor || i.bgColor,
      name: i.ticketType,
    })) || []
    if (streamCategory) {
      categories.push(streamCategory)
    }
    if (regularCategories.length) {
      categories.push(...regularCategories)
    }
    if (currentEventDetails?.eventType === eventTypes.TICKETED_ADMITION_STREAM) {
      if (categories.length > 1) {
        categories[1].isDefault = true
      }
    } else {
      if (categories.length) {
        categories[0].isDefault = true
      }
    }
    if (currentEventDetails?.isVenueDraft && !isVenue(role)) {
      if (currentEventDetails?.eventType === eventTypes.TICKETED_ADMITION_STREAM) {
        if (streamType === streamTypes.PRIVATE_STREAM) {
          categories.push({
            isStreaming: true,
            privacyOption: 'visible',
            isPrivateStream: true,
            name: streamTypes.PRIVATE_STREAM_CATEGORY_NAME,
            streamType: streamTypes.PRIVATE_STREAM,
            quantity: 0,
          })
        } else {
          categories.push({
            isStreaming: true,
            privacyOption: 'visible',
            isPrivateStream: false,
            name: streamTypes.PPV_STREAM_CATEGORY_NAME,
            streamType: streamTypes.PPV_STREAM,
            quantity: 10000,
          })
        }
        categories.push({ isDefault: true, name: 'General Admission' })
      } else if (currentEventDetails?.eventType === eventTypes.TICKETED_ADMITION) {
        categories.push({ isDefault: true, name: 'General Admission' })
      }
    }
  } else {
    // fill categories for initialValues for NEW event
    // we start new event by event type TICKETED_ADMITION.
    // if this requirement will be changed in a future we should modify here ...
    if (eventType === eventTypes.TICKETED_ADMITION) {
      categories.push({ isDefault: true, name: 'General Admission' })
    }
  }

  const handleRaffleModalOpen = () => {
    const raffleCategoryExist = currentEventDetails?.tickets?.find(i => i.isRaffleCategory)
    if ((eventType === eventTypes.TICKETED_ADMITION || eventType === eventTypes.TICKETED_ADMITION_STREAM) && !raffleCategoryExist) {
      setIsRaffleModalActive(true)
      isRaffleModalActive ? setActivePanel(['fees']) : setVisibleModalRaffle(true)
    } else {
      setActivePanel(['fees'])
    }
  }

  const handleAddRaffleCategory = () => {
    const eventDetailsContainer = eventDetailsRef.current

    // Прокрутка к разделу "Event Details"
    if (eventDetailsContainer) {
      setVisibleModalRaffle(false)
      eventDetailsContainer.scrollIntoView({ behavior: 'smooth' })
      const catArr = form.getFieldValue('categories')
      const newCat = {
        privacyOption: 'visible',
        name: 'Charity Raffle',
        isRaffleCategory: true,
      }
      form.setFieldsValue({ categories: [...catArr, newCat] })
    }
  }

  const onCloseRaffleModal = () => {
    setVisibleModalRaffle(false)
    setActivePanel(['fees'])
  }

  const initialValues = {
    eventName: currentEventDetails?.eventName,
    zoomUrl: currentEventDetails?.virtualEvent?.url,
    zoomPassword: currentEventDetails?.virtualEvent?.password,
    zoomEventParticipants: currentEventDetails?.virtualEvent?.maxParticipants,
    location: currentEventDetails?.address,
    venue: currentEventDetails?.venue,
    presentedBy: currentEventDetails?.presentedBy,
    timeZone: currentEventDetails?.timeZone,
    startDate: currentEventDetails ? moment(currentEventDetails?.startDate, 'YYYY-MM-DD h:mm A') : undefined,
    endDate: currentEventDetails ? moment(currentEventDetails?.endDate, 'YYYY-MM-DD h:mm A') : undefined,
    currency: currentEventDetails?.currency || currency,
    promotersName: currentEventDetails?.promoterDetails?.name,
    promotersEmail: currentEventDetails?.promoterDetails?.email,
    promotersMobile: currentEventDetails?.promoterDetails?.phone.slice(2),
    rainDate: currentEventDetails?.rainDate ? moment(currentEventDetails?.rainDate) : undefined,
    showAvailableTickets: currentEventDetails?.showAvailableTickets || false,
    donationEnabled: currentEventDetails?.isDonationEnable,
    isShowTLHomePage: currentEventDetails?.isShowTLHomePage,
    description: currentEventDetails?.description,
    eventUrl: currentEventDetails?.eventUrl,
    categories,
    manualTemplateCategories: [{}],
    manualTemplateSections: [{}],
    // templateName: '',
    templateCover: templateCover,
    // saveTemplate: false,
  }
  return (
    <CreateEventContext.Provider
      value={{
        eventID,
        form,
        currentEventDetails,
        eventType,
        setEventType,
        isStripeAccount,
        currency,
        setCurrency,
        streamingEnabled,
        setStreamingEnabled,
        refillSeatingsCategories,
        selectedOption,
      }}
    >
      <div className='rootCreateEvent'>
        <div className='createEventTitle'><h1>{eventID ? 'Edit' : 'Make'} My Tickets</h1></div>
        <Form
          form={form}
          onFinish={!draftConstructing ? handleFinish : handleFinishVenueDraftCreation}
          scrollToFirstError
          colon={false}
          layout='vertical'
          className='makeTicketGrid'
          initialValues={initialValues}
          onFinishFailed={validateEvent}
        >
          <Collapse
            expandIcon={prepareIcon}
            expandIconPosition='right'
            className='panelContainer'
            activeKey={activePanel}
            onChange={setActivePanel}
            // ref={collapseRef}
          >
            <Panel header={prepareHeaderPanel('Event Type', 1, !!eventType)} key='eventType' forceRender>
              <div className='bodyContainer'>
                <div className='containerTitle'>Please choose an event type</div>
                <EventsCheckbox
                  eventType={eventType}
                  isVenueRole={isVenue(role)}
                  changeEventType={changeEventType}
                  disabled={!!eventID}
                  isVenueEvent={isVenueEvent}
                  setStreamingEnabled={setStreamingEnabled}
                />
              </div>
              <div className='bottomContainer'>
                <Button type='link' onClick={goBack}>Cancel</Button>
                <CustomButton text='Next Step' type='dark' onClick={onSetEventDetails} />
              </div>
            </Panel>
            {!draftConstructing
              ? (
                <Panel header={prepareHeaderPanel('Multimedia', 2, !!Object.keys(imageUrls).length)} key='multimedia' forceRender>
                  <div className='bodyContainer'>
                    <div className='containerTitle'>Add a ticket cover and some additional multimedia</div>
                    <MultiMediaContainer form={form} />
                  </div>
                  <div className='bottomContainer'>
                    <PreviousBtn onClick={() => setActivePanel(['eventType'])} />
                    <CustomButton text='Next Step' type='dark' onClick={onSetMultimedia} />
                  </div>
                </Panel>
              )
              : null}
            {showAssignSeatingsPanel && (
              <Panel
                header={prepareHeaderPanel(
                  'Assigned and/or Table Seating',
                  3,
                  assignSeatingStepDone)}
                key='assignedSeating'
                forceRender
                disabled={currentEventDetails?.seatTemplateID || currentEventDetails?._id && !currentEventDetails?.isVenueDraft}
              >
                <div className='bodyContainer centerContainer'>
                  <div className='creationModeTitle'>Creation mode</div>
                  <SeatingCreationMode
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    templateCover={templateCover}
                    setTemplateCover={setTemplateCover}
                    form={form}
                    refillSeatingsCategories={refillSeatingsCategories}
                    eventID={eventID}
                  />
                </div>
                <div className='bottomContainer'>
                  <PreviousBtn onClick={() => setActivePanel([draftConstructing ? 'eventType' : 'multimedia'])} />
                  <div className='skipNextWrapper'>
                    <CustomButton
                      onClick={() => {
                        setSelectedOption('default')
                        form.setFieldsValue({ selectedOption: 'default' })
                        form.setFieldsValue({ manualTemplateSections: [{ sectionName: 'Section 1', sectionType: 'ROWS', rowArrangement: 'HORIZONTAL' }] })
                        setActivePanel(['eventDetails'])
                      }}
                      htmlType='button'
                      text='Skip'
                      className='skipBtn'
                      type='outline'
                    />
                    <CustomButton
                      onClick={validateAssign}
                      htmlType='button'
                      text='Next Step'
                      className='btnMakeTicket'
                      type='dark'
                    />
                  </div>
                </div>
              </Panel>
            )}
            <Panel
              header={prepareHeaderPanel(
                'Event Details',
                draftConstructing
                  ? 2
                  : showAssignSeatingsPanel
                    ? 4
                    : 3,
                eventDetailsStepDone)}
              key='eventDetails' forceRender
            >
              <div className='bodyContainer centerContainer'>
                {draftConstructing
                  ? <DetailsEventVenue />
                  : (
                    <div>
                      <DetailsEvent
                        isVenueRole={isVenue(role)}
                        isVenueEvent={isVenueEvent}
                        changeEventType={changeEventType}
                      />
                      <List name='categories'>
                        {(fields, { add, remove }) => (
                          <div className='categoriesWrapper'>
                            {fields.map((field, i) =>
                              <div key={field.fieldKey}>
                                <TicketCategory
                                  key={i}
                                  namePrefix={i}
                                  eventID={eventID}
                                  selectedOption={selectedOption}
                                  isDraft={currentEventDetails?.isVenueDraft}
                                  setUpdatePrivacyType={setUpdatePrivacyType}
                                  categoryData={getTicketData(field.fieldKey)}
                                  onRemove={remove}
                                />
                                <Divider />
                              </div>,
                            )}
                            {(eventType === eventTypes.TICKETED_ADMITION || eventType === eventTypes.TICKETED_ADMITION_STREAM) && (
                              <div
                                ref={eventDetailsRef}
                                id='addCategory' className='btn' onClick={() => {
                                  add()
                                  setAddNewCategory(!addNewCategory)
                                  setUpdatePrivacyType('visible')
                                }}
                              >Add New Category
                              </div>
                            )}
                          </div>)}
                      </List>
                      <OptionsEvent updatePrivacyType={updatePrivacyType} addNewCategory={addNewCategory} currentEventDetails={currentEventDetails} />
                    </div>)}
              </div>
              <div className='bottomContainer'>
                <PreviousBtn
                  onClick={
                    () => setActivePanel([
                      draftConstructing
                        ? 'eventType'
                        : showAssignSeatingsPanel && !eventID
                          ? 'assignedSeating'
                          : 'multimedia'])
                  }
                />
                {draftConstructing
                  ? (
                    <CustomButton
                      onClick={() => {
                        setIsStepTwo(true)
                        validateEventDetails()
                      }} htmlType='button' text='Next Step' type='dark'
                    />)
                  : (
                    <CustomButton
                      onClick={handleRaffleModalOpen}
                      htmlType='button'
                      text='Next Step'
                      className='btnMakeTicket'
                      type='dark'
                    />)}
              </div>
            </Panel>

            <Panel
              header={prepareHeaderPanel(
                'Fees and payout',
                draftConstructing
                  ? 3
                  : showAssignSeatingsPanel
                    ? 5
                    : 4,
                eventID || stateComplete,
              )}
              key='fees' forceRender
            >
              <div className='bodyContainer centerContainer'>
                <Fees
                  isVenueEvent={isVenueEvent}
                  draftConstructing={draftConstructing}
                  currentEventDetails={currentEventDetails}
                  nonProfitOrganization={nonProfitOrganization}
                  setNonProfitOrganization={setNonProfitOrganization}
                />
              </div>
              <div className='bottomContainer'>
                <PreviousBtn onClick={() => setActivePanel(['eventDetails'])} />
                <CustomButton
                  htmlType='submit'
                  onClick={() => setIsStepTwo(false)}
                  text={draftConstructing ? eventID ? 'Update Event' : 'Create Event' : eventID ? 'Update Tickets' : 'Make tickets'}
                  className='btnMakeTicket'
                  type='dark'
                />
              </div>

            </Panel>
          </Collapse>
        </Form>
      </div>
      <ModalBuyStreamTicket
        streamType={streamType}
        dataTicket={modalBuyStream}
        setVisible={onCloseModal}
        visible={!!modalBuyStream}
        callback={onSubmitBought}
        currency={currency}
        quantity={streamQuantity}
      />
      <ModalInfo
        visible={modalInfo?.visible}
        submit={handleFinishVenueDraftCreation}
        title={modalInfo?.message}
        setModalInfo={setModalInfo}
        data={modalInfo?.data}
      />
      <ModalRaffleCategory title='Before going further would you like to add charity raffle tickets to your event?' onClose={() => setVisibleModalRaffle(false)} onCancel={onCloseRaffleModal} onSubmit={handleAddRaffleCategory} visible={visibleModalRaffle} />
      {showModalPromotion && <ModalChoosePromotion withoutPromoter={venueWithoutPromoter} setVisible={setShowModalPromotion} visible={showModalPromotion} />}
    </CreateEventContext.Provider>
  )
}

export default CreateEventV2
