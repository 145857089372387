import React from 'react'
import './index.styl'
const SectionColor = ({ category, className }) => {
  const isColorBlack = category.color === '#FFFF00' || category.color === '#FFCC00' || category.color === '#FF9900' || category.color === '#FF6600' || category.color === '#99CC00' || category.color === '#99FF00' || category.color === '#33FF00' || category.color === '#33CC00'
  return (
    <div className='categoryColorWrapper'>
      <div style={isColorBlack ? { backgroundColor: category.color, color: 'black' } : { backgroundColor: category.color }} className='colorBlock'>
        {'$' + category.price}
      </div>
    </div>
  )
}

export default SectionColor
