import React, { useEffect } from 'react'
import './index.styl'

const Header = () => {
  useEffect(() => {
    window.dataLayer.push({ event: 'contact-us' })
  })
  return (
    <div className='headerContactUs'>
      <div className='title'>Contact Us</div>
    </div>
  )
}

export default Header
