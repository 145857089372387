import React from 'react'
import { Input } from 'antd'
import { CustomSelect } from 'components'
import { MBSelect } from 'pages/MyBackOffice/MBComponents'
import SearchIcon from 'images/input-search.svg'
import closeIconSvg from 'images/Crossmark.svg'
import { getNameByKey } from 'utils'
// import { throttle } from 'lodash'
import c from 'classnames'
import './index.styl'

const MBControlPanel = ({
  search,
  setSearch,
  sort,
  setSort,
  statusesActive,
  onChangeSearch,
  setStatusesActive,
  statusesPayment,
  setStatusesPayment,
  loading,
}) => {
  const sortByItems = [
    { name: 'Sort by Date', key: 'DATE_ASC' },
    { name: 'Sort by Date', key: 'DATE_DESC' },
    { name: 'Sort by Name', key: 'NAME_ASC' },
    { name: 'Sort by Name', key: 'NAME_DESC' },
  ]
  const filterByStatuses = [
    { name: 'Active', key: 'active' },
    { name: 'Blocked', key: 'blocked' },
    { name: 'Canceled', key: 'canceled' },
    { name: 'Deleted', key: 'deleted' },
  ]
  const filterByPayments = [
    { name: 'Refunded', key: 'refunded' },
    { name: 'Partially refunded', key: 'partiallyRefunded' },
    { name: 'Future payments', key: 'futurePayments' },
    { name: 'Partially payed out', key: 'partiallyPayedOut' },
    { name: 'Payed out', key: 'payedOut' },
  ]

  const handleClearAll = () => {
    if (!loading) {
      setStatusesPayment([])
      setStatusesActive([])
    }
  }
  const renderClearItem = (statuses, setStatus) => status => {
    return (
      <div key={status} className={c('clearStatus', { disabled: loading })}>
        {getNameByKey(status, [...filterByStatuses, ...filterByPayments])}
        <span className='clearIcon' onClick={() => !loading && setStatus(statuses.filter(s => s !== status))}>
          <img src={closeIconSvg} alt=' ' />
        </span>
      </div>
    )
  }
  const renderStatusesIndicator = [...statusesActive, ...statusesPayment].length ? (
    <>
      {statusesActive.map(renderClearItem(statusesActive, setStatusesActive))}
      {statusesPayment.map(renderClearItem(statusesPayment, setStatusesPayment))}
      <div className={c('clearStatus clearAll', { disabled: loading })} onClick={handleClearAll}>Clear All Filters</div>
    </>
  ) : null

  return (
    <div className='MBControlPanel'>
      <div className='mboLeftBlock'>
        <Input
          onChange={onChangeSearch}
          prefix={<img alt='search icon' src={SearchIcon} />}
          className='input noFocusBorder'
          placeholder='Search'
          // value={search}
          allowClear
        />
        <div className='mdVisible'>
          {renderStatusesIndicator}
        </div>
      </div>
      <div className='mboRightBlock'>
        <CustomSelect
          setSortTag={tag => setSort(tag)}
          items={sortByItems}
          disabled={loading}
          sortTag={sort}
          customSuffix
        />
        <MBSelect
          placeholder='Filter by status'
          setValues={setStatusesActive}
          options={filterByStatuses}
          values={statusesActive}
          className='mboSelect'
          disabled={loading}
        />
        <MBSelect
          placeholder='Filter by payments'
          setValues={setStatusesPayment}
          className='mboSelectFilter'
          options={filterByPayments}
          values={statusesPayment}
          disabled={loading}
        />
        <div className='xsVisible'>
          {renderStatusesIndicator}
        </div>
      </div>
    </div>
  )
}

export default MBControlPanel
