/* eslint-disable prefer-promise-reject-errors */

export const minimumPriceValidator = {
  validator (_, value) {
    const val = String(value)
    const regValidationPrice = /^\d{1,8}(?:\.\d{1,2})?$/
    if (!regValidationPrice.test(val) && value > 0) {
      return Promise.reject('Price is not valid')
    }
    if (value < 1) {
      return Promise.reject('Minimum price for a non-free ticket is $1')
    }
    return Promise.resolve()
  },
}
export const quanityValidator = {
  validator (_, value) {
    if (!Number.isInteger(value) && value > 0) {
      return Promise.reject(new Error('Quantity must be an integer!'))
    }
    return Promise.resolve()
  },
}
export const minimumPriceValidatorInviteOnly = {
  validator (_, value) {
    const val = String(value)
    const regValidationPrice = /^\d{1,8}(?:\.\d{1,2})?$/
    if (value < 0) {
      return Promise.reject('Minimum price for a non-free ticket is $1')
    }
    if (!regValidationPrice.test(val)) {
      return Promise.reject('Price is not valid')
    }
    return Promise.resolve()
  },
}
export const stripeValidator = (isStripeAccount, isVenueEvent) => (
  {
    validator (_, value) {
      if (value > 0 && !isStripeAccount && !isVenueEvent) return Promise.reject('You can not set price greater than 0 until stripe account is connected')
      return Promise.resolve()
    },
  }
)
export const notEqual = (input, message) => (
  {
    validator (_, value) {
      if (value === input) return Promise.reject(message)
      return Promise.resolve()
    },
  }
)
export const maximumEventParticipantsValidator = (eventType, maximumParticipants) => (
  {
    validator (_, value) {
      if ((eventType !== 'virtual') || (maximumParticipants >= value)) return Promise.resolve()
      return Promise.reject('Ticket Quantity can not exceed Maximum Event Participants')
    },
  }
)
export const earlyBirdPriceValidator = originalPrice => (
  {
    validator (_, value) {
      if (!value) {
        return Promise.reject('Enter Early Bird price')
      }
      if (originalPrice > value) {
        return Promise.resolve()
      }
      return Promise.reject('Should be lower than price')
    },
  })
export const earlyBirdDateValidator = originalDate => (
  {
    validator (_, value) {
      if (originalDate > value) {
        return Promise.resolve()
      }
      return Promise.reject('Early Bird date cannot be later than Start Date')
    },
  })

export const urlValidator = (_, value) => {
  return new Promise((resolve, reject) => {
    return !!value && value.match(/[^a-z0-9_-]/gi)?.length
      ? reject(new Error("Only letters, numbers, '-' and '_' are allowed!"))
      : resolve()
  })
}

export const minLength = {
  validator (_, value) {
    if (value && value.length < 5) {
      return Promise.reject(new Error('Phone number must be at least 5 characters.'))
    }
    return Promise.resolve()
  },
}
export const mobileValidator = {
  validator (_, value) {
    if (value?.length < 10) {
      return Promise.reject(new Error('Phone number must be at least 10 digits'))
    }
    if (value?.length > 15) {
      return Promise.reject(new Error('Phone number cannot exceed 15 digits'))
    }
    return Promise.resolve()
  },
}
