import React, { useState, useContext, useEffect } from 'react'
import { Form, Switch, Divider } from 'antd'
import { CreateMemberLyfeContext } from '../../../../..'
import AnnouncementForm from './AnnouncementForm'
// import TooltipInfo from '../../../../../../CreateEventV2/TooltipInfo'
import './index.styl'

const { Item, List } = Form

const Announcements = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)
  const [announcementsSwitch, setAnnouncementsSwitch] = useState(false)
  const [announcements, setAnnouncements] = useState(form.getFieldValue('announcements') || [])

  // const mobile = window.innerWidth <= 730
  // const tooltipTitle = `Charity Raffles are limited to $30,000 in total ticket sales and $100 max price per ticket

  // Ticket Lyfe charges for Charity Raffle Tickets are $1 for Raffles priced up to $50 and $2 for higher priced Raffle Tickets

  // Do not pick Charity Raffles if you are doing a regular event with Random Draw Prizes--Because you can do a Random Draw on ANY Ticket Lyfe Event, the Charity Raffle limits above will not apply, and Ticket Lyfe treat your event as an ordinary Ticketed Event`

  // const additionalTooltip = (
  //  <TooltipInfo
  //    className='tooltipTitle'
  //    text={mobile ? '' : 'Additional info'}
  //    info={tooltipTitle}
  //  />
  // )

  useEffect(() => {
    if (currentPageDetails?.announcements?.length) {
      setAnnouncementsSwitch(true)
      setAnnouncements(currentPageDetails.announcements)
    }
  }, [currentPageDetails])

  useEffect(() => {
    if (!announcements.length) {
      setAnnouncementsSwitch(false)
      form.setFieldsValue({
        announcementsSwitch: false,
      })
    }
  }, [announcements, form])

  const handleSwitchChange = checked => {
    setAnnouncementsSwitch(checked)
    if (checked && !announcements.length) {
      const newAnnouncement = [{}]
      setAnnouncements(newAnnouncement)
      form.setFieldsValue({
        announcements: newAnnouncement,
      })
    }
  }

  const getAnnouncementData = idx => {
    return announcements[idx]
  }

  return (
    <div className='announcementsWrapper radioItem'>
      <div className={announcementsSwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='announcementsSwitch' valuePropName='checked' className='announcements'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Announcements</div>
        </div>
        {/* <div className='tooltipWrapper'>{additionalTooltip}</div> */}
      </div>
      {announcementsSwitch && (
        <List name='announcements'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <AnnouncementForm
                  currentPageDetails={currentPageDetails}
                  categoryData={getAnnouncementData(fieldKey)}
                  form={form}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedAnnouncements = form.getFieldValue('announcements')
                    setAnnouncements(updatedAnnouncements)
                    if (updatedAnnouncements.length === 0) {
                      form.setFieldsValue({
                        announcementsSwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                  const updatedAnnouncements = form.getFieldValue('announcements')
                  setAnnouncements(updatedAnnouncements)
                }}
              >Add Another Announcement
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default Announcements
