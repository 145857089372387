import React, { useEffect, useState } from 'react'
// import { Popover } from 'antd'
import MSImage from 'images/MSDefaultImage.png'
// import ThreeDotIcon from 'images/threedots.svg'

import ChatArrowBack from 'images/chatArrow.svg'

import './index.styl'

const ChatList = ({ chats, setSelectedChatId, selectedChatId, isOwner }) => {
  // const popoverRef = useRef()
  const isTablet = window.innerWidth <= 900
  const [isCollapsed, setIsCollapsed] = useState(!!isTablet)
  // const popoverContent = id => {
  //  return (
  //    <div className='actionPopover'>
  //      <div className='popoverBtn'>Edit</div>
  //      <div className='popoverBtn btnRemove'>Remove</div>
  //    </div>
  //  )
  // }
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }
  useEffect(() => {
    if (isTablet) {
      setIsCollapsed(true)
    } else {
      setIsCollapsed(false)
    }
  }, [isTablet])
  return (
    <div className='allSectionWrapper'>
      <div className={`titleSection  ${isCollapsed ? 'collapsed' : ''}`}>
        <div onClick={isTablet && toggleCollapse} className={`titleWrapper ${isCollapsed ? 'collapsed' : ''}`}>
          <div className='titleImage'>
            <img src={ChatArrowBack} alt='' />
            <div className='title'>Chat</div>
          </div>
        </div>
      </div>
      <div className={`chat-list ${isCollapsed ? 'collapsed' : ''}`}>
        {!isCollapsed && (
          <div className='chatListWrapper'>
            {chats?.map(chat => (
              <div key={chat.id} className={`chat-item ${selectedChatId === chat.id ? 'active' : ''}`} onClick={() => setSelectedChatId(chat.id)}>
                <img className='chatImage' src={chat?.imageUrl?.thumbnail1 || MSImage} alt={chat.name} />
                <div className='chat-info'>
                  <div>
                    <div className='name'>{chat.title}</div>
                    <div className='lastMessage'>{chat.lastMessage}</div>
                  </div>
                  {/* <div>
                    {isOwner &&
                      <Popover
                        overlayClassName='actionPopoverContainer'
                        content={popoverContent(chat.id)}
                        getPopupContainer={node => node?.parentNode}
                        placement='top'
                        trigger='click'
                        ref={popoverRef}
                      >
                        <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                      </Popover>}
                    {chat.unreadCount > 0 && <div className='unread-count'>{chat.unreadCount}</div>}
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatList
