import adminapi from './axios.v2.withoutTransform'

const resetPassword = email => adminapi('user/getResetPasswordToken', 'POST', { email }, false)

const signUp = obj => adminapi('user/regularUser', 'POST', obj, false)

const signUpVenue = obj => adminapi('user/venueUser', 'POST', obj, false)

const verificationEmail = (token, en) => adminapi(`user/verifyEmail/${token}`, 'PUT', {}, false)

export default { resetPassword, signUp, signUpVenue, verificationEmail }
