export const EVENTS_STATE = 'events'

export const EVENTS_GET_EVENTS = 'events/EVENTS_GET_EVENTS'
export const EVENTS_GET_TEMPLATES = 'events/EVENTS_GET_TEMPLATES'
export const EVENTS_GET_PROMOTIONS = 'events/EVENTS_GET_PROMOTIONS'
export const EVENTS_SET_EVENT = 'events/EVENTS_SET_EVENT'
export const EVENTS_GET_PUBLIC_EVENTS = 'events/EVENTS_GET_PUBLIC_EVENTS'
export const EVENTS_SET_HOME_PUBLIC_EVENTS = 'events/EVENTS_SET_HOME_PUBLIC_EVENTS'
export const EVENTS_GET_WALLETS = 'events/EVENTS_GET_WALLETS'
export const EVENTS_SET_IMAGES = 'EVENTS_SET_IMAGES'
export const EVENTS_SET_FILES = 'EVENTS_SET_FILE'
export const EVENTS_GET_ASSIGN_TICKETS = 'EVENTS_GET_ASSIGN_TICKETS'
export const EVENTS_GET_EVENT_DETAILS = 'EVENTS_GET_EVENT_DETAILS'
export const EVENTS_GET_EVENT_DETAILS_V2 = 'EVENTS_GET_EVENT_DETAILS_V2'
export const EVENTS_GET_SCANNER_INVITES = 'EVENTS_GET_SCANNER_INVITES'
export const EVENTS_GET_SCANNER_TOTAL_COUNT = 'EVENTS_GET_SCANNER_TOTAL_COUNT'
export const EVENTS_GET_ASSIGN_TICKETS_SEATS = 'EVENTS_GET_ASSIGN_TICKETS_SEATS'
export const EVENTS_GET_ASSIGN_TICKET_SECTION_DETAILS = 'EVENTS_GET_ASSIGN_TICKET_SECTION_DETAILS'
export const EVENTS_GET_TABLE_SEATS = 'EVENTS_GET_TABLE_SEATS'
export const EVENTS_GET_TABLE_CATEGORIES = 'EVENTS_GET_TABLE_CATEGORIES'
export const EVENTS_UNASSIGNED_TICKETS_LIST = 'EVENTS_UNASSIGNED_TICKETS_LIST'
export const EVENTS_UNASSIGNED_TICKETS_DATA = 'EVENTS_UNASSIGNED_TICKETS_DATA'
export const EVENTS_GET_TRANSACTION_LIST = 'EVENTS_GET_TRANSACTION_LIST'
export const EVENTS_GET_BALANCE_LIST = 'EVENTS_GET_BALANCE_LIST'
export const EVENTS_GET_EVENT_DETAILS_SUMMARY = 'EVENTS_GET_EVENT_DETAILS_SUMMARY'
export const EVENTS_GET_SOLD_TICKET_DETAILS = 'EVENTS_GET_SOLD_TICKET_DETAILS'
export const EVENTS_GET_INVITE_SELL_USERS = 'EVENTS_GET_INVITE_SELL_USERS'
export const EVENTS_GET_INVITE_BUY_USERS = 'EVENTS_GET_INVITE_BUY_USERS'
export const EVENTS_GET_INVITE_PROMOTE_USERS = 'EVENTS_GET_INVITE_PROMOTE_USERS'
export const EVENTS_GET_INVITE_GIFT_USERS = 'EVENTS_GET_INVITE_GIFT_USERS'
export const EVENTS_GET_INVITE_CASH_USERS = 'EVENTS_GET_INVITE_CASH_USERS'
export const EVENTS_GET_WALLETS_GUEST = 'events/EVENTS_GET_WALLETS_GUEST'
export const UNRESERVE_SEAT = 'events/UNRESERVE_SEAT'
export const EVENTS_SET_MOCK_TICKET = 'EVENTS_SET_MOCK_TICKET'
export const EVENT_STREAM = 'EVENTS_STREAM'
export const EVENTS_GET_SCANNED_TiCKETS = 'EVENTS_GET_SCANNED_TiCKETS'
