import React from 'react'
import './index.styl'

const FAQ = () => {
  // const priceData = [
  //   {
  //     title: 'Attended Venue',
  //     subTitle: 'for TicketLyfe member',
  //     categories: [
  //       { label: 'Ticket Price', price: '$1' },
  //       { label: 'TicketLyfe Fee', price: '$0.50' },
  //       { label: 'User Pays', price: '$1.50' },
  //       { label: 'Stripe Fee', price: '$0.34' },
  //       { label: 'Promoter\'s Take', price: '$0.66' },
  //     ],
  //   },
  //   {
  //     title: 'Virtual Meeting',
  //     subTitle: 'for TicketLyfe guest',
  //     categories: [
  //       { label: 'Ticket Price', price: '$100' },
  //       { label: 'TicketLyfe Fee', price: '$8' },
  //       { label: 'User Pays', price: '$108.00' },
  //       { label: 'Stripe Fee', price: '$3.43' },
  //       { label: 'Promoter\'s Take', price: '$96.57' },
  //     ],
  //   },
  //   {
  //     title: 'PPV',
  //     subTitle: 'for TicketLyfe guest',
  //     categories: [
  //       { label: 'Ticket Price', price: '$1' },
  //       { label: 'TicketLyfe Fee', price: '$1.80' },
  //       { label: 'User Pays', price: '$2.80' },
  //       { label: 'Stripe Fee', price: '$0.38' },
  //       { label: 'Promoter\'s Take', price: '$0.62' },
  //     ],
  //   },
  //   {
  //     title: 'PPV',
  //     subTitle: 'for TicketLyfe member',
  //     categories: [
  //       { label: 'Ticket Price', price: '$100' },
  //       { label: 'TicketLyfe Fee', price: '$6' },
  //       { label: 'User Pays', price: '$106.00' },
  //       { label: 'Stripe Fee', price: '$3.37' },
  //       { label: 'Promoter\'s Take', price: '$96.63' },
  //     ],
  //   },
  // ]
  return (
    <div className='help_container faqPage'>
      <h1 className='pageTitle'>
        Frequently Asked Questions
      </h1>
      {/* <h2 className='chapterTitle text-center'>
        Price Chart Examples
      </h2> */}
      {/* <div className='categoriesContainer'>
        {priceData.map(({ title, subTitle, categories }, idx) => (
          <div key={idx} className='categoriesItem'>
            <h4 className='categoryTitle'>{title}</h4>
            <div className='categorySubTitle'>{subTitle}</div>
            <div className='ticketCategoryTable'>
              {categories.map(({ label, price }, idx) => (
                <div key={idx} className='ticketCategoryItem'>
                  <div className='ticketCategoryLabel'>{label}</div>
                  <div className='ticketCategoryText'>{price}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div> */}
      <h2 className='chapterTitle'>
        1. How do I make a Ticket Lyfe account?
      </h2>
      <ul>
        <li>Just sign up with your email address and mobile number; and give yourself a Unique Username
        </li>
        <li>Once you make a Ticket Lyfe account, you will need to VERIFY your email by answering the new account email that we send to first-time accounts [If you didn’t get the email, check spam just in case]</li>
      </ul>
      <h2 className='chapterTitle'>
        2. What are my choices when signing up to Ticket Lyfe?
      </h2>
      <ul>
        You can sign up to Ticket Lyfe as a (1) User (2) Venue Partner (3) Promoter Partner
        <li>A.<b> User</b>--just enter user name, email and mobile number and sign up. You will immediately be able to: Buy/Make/Sell/Gift tickets</li>
        <li>B. <b> Venue Lyfe Partner</b>--this means that you own/manage/control a physical venue, along with ONE SPECIFIC ADDRESS; and you would like to be a Venue Partner with Ticket Lyfe [Introductory Offer--Annual fee is $200]
          <br />
          <br />  --your <b> Venue will</b> Profit-Share in Ticket Lyfe Fees, by receiving 2 percentage points on all ticket sales from your Venue: minimum of .20 cents to maximum of $2.00 on each ticket sold to your Venue
          <br />
          <br /> --as a <b> Venue Partner</b> you will assign those people selling tickets to events at your Venue as a Co-Promoter via Ticket Lyfe, where both of you will get box offices in order to track the ticket sales in real time
        </li>
        <li>C. <b> Promoter Partner</b>--this is an upgrade from User, where you get to profit-share in Ticket Lyfe Fees. [Introductory Offer—Annual fee is $200]
          <br />
          <br />--as a <b> Promoter Partner</b> you will Profit-Share in Ticket Lyfe Fees, by receiving 1.5 percentage points you sell: minimum of .15 cents to maximum of $1.50 on each ticket sold by you, or your sub-promoter
        </li>

      </ul>
      <h2 className='chapterTitle'>
        3. What kind of tickets can I make?
      </h2>
      <ul>
        <li>You can make 3 kinds of tickets: (1) Ticketed Admission (Livestream PPV can be added to this choice) (2) Virtual Meeting (3) Live Stream PPV</li>
        <li>Ticketed Admission is where customers will come to your venue, and will need tickets to enter said event</li>
        <li>A Virtual Meeting makes tickets for Zoom, Google Meets, etc, where customers virtually enter this event via their tickets</li>
        <li>A Live Stream PPV is a broadcast event, able to be viewed by ticket holders</li>
      </ul>
      <h2 className='chapterTitle'>
        4. How do I make a ticket for a Ticketed Admission event?
      </h2>
      <ul>
        <li>
          Go to Make My Tickets and click on Attended Venue Event
        </li>
        <li>
          Fill in the blanks, and click on the “Make Ticket” button
        </li>
      </ul>
      <h2 className='chapterTitle'>
        5. How do I make a ticket for a Virtual Meeting (ex: Zoom) event?
      </h2>
      <ul>
        <li>
          For Virtual Events, go to your Virtual Site first and make your Virtual Meeting/Event first (Ex. Zoom Meeting)
        </li>
        <li>
          Go to Make My Tickets and click on Virtual Meetings
        </li>
        <li>
          Fill in the blanks, and click on the “Make Ticket” button
        </li>
        <li>
          For the Virtual Meeting you will have to copy and paste your Event URL from the Virtual Meeting that you have made
        </li>
        <li>
          For those sites with passwords (Ex. Zoom) you can copy and paste your password into your ticket
        </li>
      </ul>
      <h2 className='chapterTitle'>
        6. How do I make a ticket for a PPV Live Stream event?
      </h2>
      <ul>
        <li>
          Go to Make My Tickets and click on Live Stream PPV
        </li>
        <li>
          Fill in the blanks, and click on the “Make Ticket” button
        </li>
        <li>
          Note: The Promoter will be CHARGED a Stream Reserve Fee of $50
        </li>
        <li>
          The RTMP URL and Stream Key can be accessed from your Box Office for your videographer(please pay attention to our streaming capability limits)
        </li>
        <li>
          Event Live (our streaming provider) will keep your event up for 1 year; and your stream will be available as On Demand, for that year
        </li>
      </ul>
      <h2 className='chapterTitle'>
        7. What is my Ticket Link, and custom URL?
      </h2>
      <ul>
        <li>
          Once you make a Value Ticket you get a custom URL link to sell your tickets
        </li>
        <li>
          You can fill in the last part of your URL to customize the link to your event
        </li>
        <li>
          Once you have your URL Ticket Link, you can post it to Social Media or share via text, email, etc.
        </li>
        <li>
          Sub-promoters get their own custom URL links as well
        </li>
        <li>
          There are no URL links for free tickets (free tickets are INVITE ONLY and have to be gifted out via text and email)
        </li>
      </ul>
      <h2 className='chapterTitle'>
        8. Can I make free tickets?
      </h2>
      <ul>
        <li>
          Yes you can
        </li>
        <li>
          Free tickets are in the category of Invite Only
        </li>
        <li>
          The reason we do this is so that no one takes a hundred of your free tickets with a click of their mouse
        </li>
        <li>
          You can only distribute these tickets via Direct Gift
        </li>
        <li>
          Ex. You can make tickets to your Birthday Party and have your cousin scanning tickets at the door
        </li>
      </ul>
      <h2 className='chapterTitle'>
        9. Can you take me through all the options when I click on Make My Tickets?
      </h2>
      <ul>
        <li>A. Event Type—Ticketed Admission, PPV, Virtual Meeting</li>
        <li>B. Add Multimedia —a picture (or Poster) for your ticket—additional jpegs or pdfs, documents</li>
        <li>C. Notes—ex’s: “Free Parking” “must be 21 to drink”</li>
        <li>D. Category —default category is “General Admission” but can be changed —you can add categories [ex’s: VIP, Children] —we recommend to pick different colors for different categories —each category has its own price and amount of tickets made</li>
        <li>E. Early Bird special—Discount of tickets until the date chosen</li>
        <li>F. Sub-promoters —given tickets to sell for you under their own URL ticket link —the money from sub-promoter’s sales goes to the promoter —sub-promoters can see what they sold via their own Box Office</li>
        <li>G. Custom URL —so that you have a Unique Link to sell your tickets —so that you have a Unique Link to sell your tickets</li>
        <li>H. Show Available Tickets, if you want your customers to know how many tickets are left in each category</li>
        <li>I. Add Donation Tickets, if you want your customers to be able to donate funds to your cause</li>
        <li>J. Fees —there are 3 types: Ticket Lyfe, Payment Processing, Taxes —The promoter can either pay or pass them onto the customers —These are toggle buttons when you are making your tickets</li>
        <li>K. Enable Special Seating to allow for Table Seating —you will be allowed to make up tables and seats —this will be in the form of X tables with Y seats—the making tables process can be repeated as needed—as tickets are sold you can drag and drop them into tables—this will make for easy seating when the guests check in</li>
      </ul>
      <h2 className='chapterTitle'>
        10. What are the Ticket Lyfe fees and who pays them?
      </h2>
      <ul>
        <li>Ticket Lyfe fees are 7.5% for Ticket Lyfe Users and 8.5% for Guests</li>
        <li>Ticket Lyfe fees are charged at a minimum of One Dollar, and maximum of $7.50 per ticket for Users, and $1.25 to $8.50 for Guests</li>
        <li>For tickets priced above $100, Ticket Lyfe does not charge more fees than the maximum stated above</li>
        <li>Livestream fees are $2 per stream, with no additional Ticket Lyfe fees</li>
        <li>Payment processing (credit card and Venmo processing fees) are 2.9% plus 30 cents</li>
        <li>For Venue Partners, the sales taxes are shown, and charged, in the ticket price</li>
        <li>The Promoter—the ticket maker—has a toggle for each of these fees, and decides whether the customer or the Promoter pays each or all of these fees</li>
      </ul>
      <h2 className='chapterTitle'>
        11. How do I Sub-promote tickets?
      </h2>
      <ul>
        <li>You have the ability to Sub-promote tickets in your Box Office</li>
        <li>You will find the sub-promoter link by going into Ticket Distribution, and then clicking on Sub promote tickets</li>
        <li>Sub-promoters MUST be a Ticket Lyfe Member</li>
        <li>Just put the email of the Ticket Lyfe member you want, along with the number of tickets to sub-promote</li>
        <li>The Sub-promoter can now sell via a (1) Link; (2) Direct Sales; of he/she can (3) Gift a ticket </li>
        <li>The Sub-promoter cannot re-subpromote these tickets again</li>
        <li> All of the monies from the Sub-promoters sales goes DIRECTLY TO THE ORIGINAL PROMOTER</li>
      </ul>
      <h2 className='chapterTitle'>
        12. What is a Direct Sale?
      </h2>
      <ul>
        <li>Instead of using a link the ticket is sold directly to another via their email</li>
        <li>This Direct Sale can be done through the Box Office</li>
        <li>The price of a Direct sale can be any price up to the price of the ticket</li>
        <li>No scalping is done on Direct Sales [selling above face value of ticket]</li>
      </ul>
      <h2 className='chapterTitle'>
        13. What are Cash/Zelle Sales?
      </h2>
      <ul>
        <li>Via Box Office, a Ticket Lyfe Promoter or User can sell their tickets for cash or check</li>
        <li>The Promoter opens up his box office, goes to ticket distribution and then clicks on the tab for Cash/Zelle Sales</li>
        <li>The Promoter records the cash/zelle received so that the box office keeps track of all Cash/Zelle Sales as well as digital sales</li>
      </ul>
      <h2 className='chapterTitle'>
        14. What is a Direct Gift?
      </h2>
      <ul>
        <li>
          Via Box Office or Wallet, a Ticket Lyfe Promoter or User can “gift” their tickets via email
        </li>
        <li>For Free Tickets, this is the ONLY form of Ticket Distribution</li>
      </ul>
      <h2 className='chapterTitle'>
        15. Where are the tickets that I bought (or was gifted)?
      </h2>
      <ul>
        <li>
          As a Ticket Lyfe member, you get a digital wallet to hold your tickets
        </li>
        <li>
          As a Ticket Lyfe guest, your tickets come to you in your email
        </li>
      </ul>
      <h2 className='chapterTitle'>
        16. As a Promoter selling tickets, how and when do I get paid?
      </h2>
      <ul>
        <li>
          Promoters get paid 70% approximately three (3) business days after their event
        </li>
        <li>
          Promoters get paid the other 30% approximately ten (10) business days after their event—this allows for refunds and/or negotiation to be settled
        </li>
        <li>
          This is the same for PPV events, except for On Demand purchases, which get paid as soon as the payment clears through payment processing (about 3 business days)
        </li>
        <li>
          To get paid you just go to your profile page to put in your bank account and routing number so you can get an ACH payment
        </li>
        <li>
          If you have a Stripe account, you can connect it to Ticket Lyfe, and get paid as a Connected Stripe Account
        </li>
      </ul>
      <h2 className='chapterTitle'>
        17. Can I buy tickets as a Guest User?
      </h2>
      <ul>
        <li>
          Non-Ticket Lyfe users are Guest Users
        </li>
        <li>Guests can buy a ticket and will get them via email</li>
        <li>Guest Users also may use their Guest Wallets (but can enter only through the original email with the ticket)</li>
      </ul>
      <h2 className='chapterTitle'>
        18. Can you take me through the Box Office and explain its features?
      </h2>
      <ul>
        <li>
          In your Box Office all of your events are listed, in a ticket style format
        </li>
        <li>
          They show basic information, click on one to get more info
        </li>
        <li>
          Edit Tickets—by clicking on the pencil icon, just to the right of the event’s name (top left corner)
        </li>
        <li>
          Generate Embed Code is a button underneath the ticket image, so you can put your ticket link right into a website [if you don’t know how to do this please get someone who is more computer savvy then yourself]
        </li>
        <li>
          Livestream PPVs and Privates—by choosing to broadcast a show on Ticket Lyfe you are representing yourself as someone who knows how to stream, and all of the streaming parameters are on the left side of the box Office
        </li>
        <li>
          Ticket Categories—the first page shown in the Box Office is Ticket Categories, and it shows how many tickets were moved in each category
        </li>
        <li>
          Ticket Distribution—also by Ticket Category, it shows who bought your ticket by giving you the User Name and info of each person buying a ticket to your event
        </li>
        <li>
          Ticket Distribution also allows the Ticket Maker use the Direct Sales or Direct Gift Features
        </li>
        <li>
          Scan Tickets—allows you to assign your Ticket Scanners, and also Enable them to scan
        </li>
        <li>
          Financial Reporting—gives you an overall accounting to your event
        </li>
        <li>
          Purchase Details—a detailed information of each ticket buy, and allows the promoter to REFUND a buyer
        </li>
        <li>
          Sub-promoters get their own Box Office for their ticket sales
        </li>
      </ul>
      <h2 className='chapterTitle'>
        19. Where in the Box Office are Direct Sales and Direct Gifts?
      </h2>
      <ul>
        <li>
          Go into your Box Office and go into the Ticket Distribution area
        </li>
        <li>
          Under Ticket Distribution you have the choices of: (1) Public sale tickets, (2) Direct sale tickets, (3) Gift tickets, (4) Cash/Zelle sale tickets and (5) Sub promote tickets
        </li>
        <li>
          As a Promoter or Subpromoter, you can use Direct Sales to sell a customer one or more tickets at a lesser price. Example: Someone wants to bring twenty persons to your event so you can give them a discount... Just fill in their name, email and mobile number along with the amount of tickets and cost and they will buy those tickets at the discounted price
        </li>
        <li>For Direct Sales or Direct Gifts click on either Direct sale tickets or Gift tickets (these links are entered by clicking on the words) and it will take you into the direct selling or gifting areas.  Here you can click on the red box that says either “Sell Tickets” or “Gift Tickets” where you can fill in the names, emails, mobiles, along with the amounts and/or prices of the tickets</li>
        <li>
          Note: this gateway in the Box Office will also take you to Sub-promote the tickets
        </li>
      </ul>
      <h2 className='chapterTitle'>
        20. How are tickets scanned at the event?
      </h2>
      <ul>
        <li>
          Promoters assign any Ticket Lyfe Member as a Ticket Scanner for their event
        </li>
        <li>
          The Ticket Lyfe Scanner will use his/her Smartphone or a Scanner to read the QR Codes—or they attach an actual scanner to a Venue’s computer at the event site
        </li>
        <li>
          For Ticket Lyfe Members, with Wallets, their tickets will read “Scanned” once scanned
        </li>
        <li>
          Note: if you screenshot a picture of your QR Code to another person and it gets scanned; the ticket in your wallet will now read “Scanned” when pulled out of the wallet
        </li>
      </ul>
      <h2 className='chapterTitle'>
        21. Can a ticket get scanned by accident?
      </h2>
      <ul>
        <li>
          If you somehow forward a Screenshot or an email of the QR Code to another person
        </li>
        <li>
          And that person uses that ticket to scan into the event
        </li>
        <li>
          Then ANY copy or original QR Code will become invalid
        </li>
        <div className='textBlock'>
          <div><b>Ex 1:</b> I buy a ticket as a Guest User and get my ticket via email, which I forward to my friend.</div>
          <div>My friend uses this QR Code and gets into the event</div>
          <div>I come in after my friend and try to scan in with my email, but the scanner reads “Scanned”</div>
          <div>Because the unique ticket number was already used, I cannot get into the event</div>
        </div>
        <div className='textBlock'>
          <div><b>Ex 2:</b> I buy a Ticket as a member of Ticket Lyfe and take a screenshot of this ticket, with it’s QR Code</div>
          <div>I text this picture to three friends, the first who uses this screen shot to get into the event</div>
          <div>Neither of the other two friends, nor myself, will be able to get into this event now because the ticket has already been used</div>
        </div>
      </ul>
      <h2 className='chapterTitle'>
        22. Are all Ticket Lyfe sales final?
      </h2>
      <ul>
        <li>
          Yes, just like all events that tickets are sold for, ALL SALES ARE FINAL
        </li>
        <li>
          If the event is not up to your standards, it has nothing to with Ticket Lyfe—this is a Customer/Promoter issue
        </li>
        <li>
          ANY event given will suit the definition of holding an event for the price of the tickets sold
        </li>
        <li>
          ANY unsatisfactory definition of an event will be between the ticket holder and the Promoter
        </li>
        <li>
          All representations of event responsibilities rest solely with the Promoters, and not with Ticket Lyfe
        </li>
        <li>ANYONE buying a ticket recognizes that they themselves are confident in the Promoter's ability to deliver the event</li>
      </ul>
      <h2 className='chapterTitle'>
        23. Can Promoters cancel events?
      </h2>
      <ul>
        <li>
          Yes, Promoters can cancel events
        </li>
        <li>
          If a Promoter cancels an event, immediate refunds will be given to all ticket holders
        </li>
        <li>
          The Promoter is one hundred percent responsible to return all of the money for tickets sold to canceled events
        </li>
        <li>
          The Promoter will be locked out of running any more events until his canceled event money is fully returned to Ticket Lyfe buyers
        </li>
      </ul>
      <h2 className='chapterTitle'>
        24. Can a Promoter issue refunds?
      </h2>
      <ul>
        <li>
          Yes, the Promoter has the ability to issue refunds through their Box Office
        </li>
        <li>
          To issue a refund go to Purchase Details in your Box Office
        </li>
        <li>
          Within the Purchase Details window you can find the customer and issue the refund
        </li>
      </ul>
      <div className='textBlock'>
        <b>Ex:</b> The Promoter makes a ticket in a City where the sales tax is 6.5%. The promoter prices his tickets at $50 and a customer buys two (2) tickets as a Ticket Lyfe member. The price of this transaction is 2 x ($50 + 2.50) = $105, this is because of the 5% Ticket Lyfe fee. The Promoter receives $100 – (105 x .029 + .30) = $96.65, because of the Stripe Fee. The sales tax on the entire transaction is $6.83, of which the Promoter is entirely responsible for.
      </div>
      <h2 className='chapterTitle'>
        25. Who collects the sales tax on tickets sold?
      </h2>
      <ul>
        <li>
          All of the burden on collecting taxes is on the Promoter
        </li>
        <li>
          For Venue Lyfe Partner events, Ticket Lyfe will keep track of the taxes and report it to the Venue via their Back Office
        </li>
      </ul>
      <h2 className='chapterTitle'>
        26. Can I delete an event in my Box Office?
      </h2>
      <ul>
        <li>
          A Promoter can delete an event unless he/she has sold tickets to this event [to keep records of his/her sales]
        </li>
        <li>
          The Promoter still can cancel this event
        </li>
      </ul>
      <h2 className='chapterTitle'>
        27. How do I assign tickets out as a Venue?
      </h2>
      <ul>
        <li>
          A Venue will get rented out for an Event. As that Renter will sell the tickets to the Venue, Ticket Lyfe will recognize them as a Co-promoter
        </li>
        <li>
          To be able to sell tickets the Co-promoter must have a Ticket Lyfe User (or Promoter Partner) account
        </li>
        <li>
          The Venue manager signs into Ticket Lyfe and on the home page, clicks on the drop-down menu and clicks on Make My Tickets
        </li>
        <li>
          Pick the type of event it is—Ticketed Admission, with or without Livestream
        </li>
        <li>
          Add the basic details to your event—event’s name/time/date and Co-promoter
        </li>
        <li>
          Fees—put in the sales tax OR non-profit number for tax free [Ticket Lyfe does not check numbers or taxes; this is strictly for the Venue’s records]
        </li>
        <li>
          The Co-promoter receives the ticket in their Box Office, and finishes making the tickets (the cover picture, prices, amounts, etc.)
        </li>
      </ul>
    </div>
  )
}

export default FAQ
