import { notification } from 'antd'
import React from 'react'
import ReactPlayer from 'react-player'
// import ReactHlsPlayer from 'react-hls-player'

const StreamV2 = () => {
  const reactPlayerSrc = 'http://54.163.157.169:8080/live/ticketlyfe/index.m3u8'

  return (
    <div>
      <ReactPlayer
        url={reactPlayerSrc}
        controls
        width='100%'
        height='400px'
        playing
        playsinline
        onError={e => {
          if (e === 'hlsError') {
            return notification.warning({ message: 'The stream hasn\'t started yet' })
          }
        }}
        config={{
          file: {
            forceHLS: true, // This will force React Player to use HLS.js for HLS streams
          },
          attributes: {
            crossOrigin: true,
          },
        }}
      />
      {/* <ReactHlsPlayer
        src={reactPlayerSrc}
        autoPlay
        controls
        width='100%'
        height='auto'
        hlsConfig={{
          maxLoadingDelay: 4,
          minAutoBitrate: 0,
          lowLatencyMode: true,
        }}
      /> */}
    </div>
  )
}

export default StreamV2
