import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { createStore } from 'modules/store'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ParallaxProvider } from 'react-scroll-parallax'
import App from './App'

import 'styles/index.styl'
import 'antd/dist/antd.css'
import 'styles/antd.styl'
import 'styles/video-react.styl'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    attachStacktrace: true,
  })
}

const { store, persistor } = createStore()

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: 'en' })
const injectGA = () => {
  if (typeof window === 'undefined') {
    return
  }
  window.dataLayer = window.dataLayer || []
  function gtag () {
    window.dataLayer.push(arguments)
  }
  gtag('js', new Date())

  gtag('config', `${process.env.REACT_GTA_ID}`)
}

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_GTA_ID}`} />
          <script>{injectGA()}</script>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </Elements>,
  document.getElementById('root'),
)
