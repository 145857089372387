import React, { useState } from 'react'
import {
  Form,
  Input,
} from 'antd'
import c from 'classnames'
import PlacesAutocomplete from 'react-places-autocomplete'

import './index.styl'
const { Item } = Form
const LocationField = ({ form }) => {
  const [addressLocation, setAddressLocation] = useState('')
  const handleSelect = async value => {
    setAddressLocation(value)
    await form.setFieldsValue({
      location: value,
    })
  }
  const className = 'input location-search-input'
  const renderInputLocation = (isRequired = false) => (
    <PlacesAutocomplete value={addressLocation} onChange={setAddressLocation} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='locationContainer'>
          <Item
            rules={[{ required: isRequired, message: 'Enter Location' }]}
            className='detailsFieldsItem haveMargin'
            name='location'
            label='Location'
            normalize={v => v.trimStart()}
          >
            <Input {...getInputProps({ className })} />
          </Item>
          <div className={c('autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, index) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={index}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
  return (
    <div className='newLocationWrapper'>{renderInputLocation(true)}</div>
  )
}
export default LocationField
