import React, { useEffect, useState } from 'react'
import { Modal, notification, Input, Divider, Button, Form, Select } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useDispatch, useSelector } from 'react-redux'
import { CustomButton } from 'components'
import { adminBankAccount, adminPayout } from 'modules/session/session-actions'
import CopyIcon from 'images/copy.svg'
import './index.styl'
const { Item } = Form
const { Option } = Select
const MBModalBankDetails = ({ onCancel, fetchData, showBankDetails, loading }) => {
  const dispatch = useDispatch()
  const { adminBankDetails } = useSelector(state => state.session)
  const prepareCopyIcon = <img src={CopyIcon} alt='copy' className='copyImg' />
  const [totalPay, setTotalPay] = useState('')
  const [manualPayoutOperator, setManualPayoutOperator] = useState(showBankDetails?.event?.eventPayout === 'VENUE' ? 'VENUE' : 'PROMOTER')
  const lastFourDigitsPromoterRoutingTransit = '...' + adminBankDetails?.promoterUser?.routingNumber?.substr(-4)
  const lastFourDigitsVenueRoutingTransit = '...' + adminBankDetails?.venueUser?.routingNumber?.substr(-4)
  const lastFourDigitsPromoterAccountNumber = '...' + adminBankDetails?.promoterUser?.accountNumber?.substr(-4)
  const lastFourDigitsVenueAccountNumber = '...' + adminBankDetails?.venueUser?.accountNumber?.substr(-4)
  const selectRoutingTransit = manualPayoutOperator === 'PROMOTER' ? lastFourDigitsPromoterRoutingTransit : lastFourDigitsVenueRoutingTransit
  const selectAccountNumber = manualPayoutOperator === 'PROMOTER' ? lastFourDigitsPromoterAccountNumber : lastFourDigitsVenueAccountNumber

  // if no online purchases the balance object comes like {} and here the problems begin
  const strCoownerAmount = showBankDetails?.event?.balances?.coownerAmount
    ? showBankDetails?.event?.balances?.coownerAmount.replace(/,/g, '')
    : '0.00'
  const strPromoterPaidOff = showBankDetails?.event?.balances?.promoterPaidOff
    ? showBankDetails?.event?.balances?.promoterPaidOff.replace(/,/g, '')
    : '0.00'
  const money = strCoownerAmount - strPromoterPaidOff

  const tallage = 70
  const payoutPromoter = showBankDetails?.event?.payoutStatus === 'PARTICALLY_PAID_OUT' || showBankDetails?.event?.payoutStatus === 'PAID_OUT'
    ? money
    : Math.round((money / 100 * tallage) * 100) / 100 // вычисление процентов

  const strVenueFeeAmount = showBankDetails?.event?.balances?.venueFeeAmount
    ? showBankDetails?.event?.balances?.venueFeeAmount.replace(/,/g, '')
    : '0.00'
  const strVenuePaidOff = showBankDetails?.event?.balances?.venuePaidOff
    ? showBankDetails?.event?.balances?.venuePaidOff.replace(/,/g, '')
    : '0.00'
  // const payoutVenue = showBankDetails?.event?.balances?.venueFeeAmount - showBankDetails?.event?.balances?.venuePaidOff > 0 ? showBankDetails?.event?.balances?.venueFeeAmount - showBankDetails?.event?.balances?.venuePaidOff : '0.00'
  const payoutVenue = strVenueFeeAmount - strVenuePaidOff
  const payoutResult = (manualPayoutOperator === 'PROMOTER' ? payoutPromoter : payoutVenue).toFixed(2)

  const handleTotalPay = event => {
    setTotalPay(event.target.value)
  }
  useEffect(() => {
    dispatch(adminBankAccount(showBankDetails?.event.id))
  }, [])
  const handlePayout = () => {
    const obj = {
      amount: totalPay,
      receiver: manualPayoutOperator,
    }
    dispatch(adminPayout(obj, showBankDetails?.event.id)).then(() => {
      fetchData()
      onCancel()
    })
  }
  const handleSelect = value => {
    setManualPayoutOperator(value)
  }
  return (
    <Modal
      maskStyle={{ background: '#62677EE5', display: 'block' }}
      className='MBModalBankDetails'
      onCancel={onCancel}
      footer={null}
      title='Manual Payout'
      closable={!loading}
      visible
    >
      {adminBankDetails ? (
        <>
          <div className='manualPayoutWrapper'>
            <div className='description'>Information for the super admin: Please be advised that the amount displayed in the field corresponds to the 70% from the total amount due. You can enter any other amount at your own consideration.</div>
            {showBankDetails?.event?.isVenueEvent &&
              <Item
                className='item priceCatSelect'
                name='bankDetailsOperator'
                initialValue={manualPayoutOperator}
                rules={[{ required: true }]}
              >
                <Select defaultValue={manualPayoutOperator} onChange={handleSelect} listHeight={300}>
                  <Option value='VENUE'>Venue</Option>
                  {showBankDetails?.event?.eventPayout === 'PROMOTER' && <Option value='PROMOTER'>Promoter</Option>}
                </Select>
              </Item>}
            <div className='inputWrapper'>
              <div className='textInputWrapper'>
                <div className='amountTotalPayWrapper'>
                  <div className='amountTitle'>Amount to pay</div>
                  <div className='totalTitle'>Total to pay out ${manualPayoutOperator === 'PROMOTER' ? money : payoutResult}</div>
                </div>
                <Input
                  disabled={loading || payoutResult === '0.00' || payoutResult === 0}
                  onChange={handleTotalPay}
                  value={totalPay}
                  placeholder={'$' + payoutResult}
                  className='input'
                />
              </div>
              <CustomButton
                onClick={handlePayout}
                disabled={loading || payoutResult === '0.00' || payoutResult === 0}
                loading={loading}
                className='btn'
                text='Apply'
                type='dark'
              />

            </div>
          </div>
          <div className='bankDetailsWrapper'>
            <div className='title'>
              {manualPayoutOperator === 'PROMOTER' ? 'Promoter Bank Details' : 'Venue Bank Details'}
            </div>
            {manualPayoutOperator === 'PROMOTER' && adminBankDetails?.promoterUser !== null || manualPayoutOperator === 'VENUE' && adminBankDetails?.venueUser
              ? (
                <div className='bankDetailsWrapper'>
                  <div className='bankDetails'>
                    <div className='detailsName'>Routing transit #</div>
                    <div className='detailsNumberWrapper'>
                      <div className='detailsNumber'>{selectRoutingTransit}</div>
                      <CopyToClipboard disabled={!adminBankDetails?.promoterUser && !adminBankDetails?.venueUser} text={manualPayoutOperator === 'PROMOTER' ? adminBankDetails?.promoterUser?.routingNumber : adminBankDetails?.venueUser?.routingNumber} onCopy={() => notification.success({ message: 'Copied to clipboard success' })}>
                        <Button icon={prepareCopyIcon} className='btnIcon' />
                      </CopyToClipboard>
                    </div>
                  </div>
                  <Divider />
                  <div className='bankDetails'>
                    <div className='detailsName'>Account #</div>
                    <div className='detailsNumberWrapper'>
                      <div className='detailsNumber'>{selectAccountNumber}</div>
                      <CopyToClipboard disabled={!adminBankDetails?.promoterUser && !adminBankDetails?.venueUser} text={manualPayoutOperator === 'PROMOTER' ? adminBankDetails?.promoterUser?.accountNumber : adminBankDetails?.venueUser?.accountNumber} onCopy={() => notification.success({ message: 'Copied to clipboard success' })}>
                        <Button icon={prepareCopyIcon} className='btnIcon' />
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              ) : (<div className='nodetails'>No details found</div>)}
          </div>
        </>
      ) : (
        <>
          <div className='manualPayoutWrapper'>
            <div className='description'>Information for the super admin: Please be advised that the amount displayed in the field corresponds to the 70% from the total amount due. You can enter any other amount at your own consideration.</div>
            <div className='inputWrapper'>
              <div className='textInputWrapper'>
                <div className='amountTotalPayWrapper'>
                  <div className='amountTitle'>Amount to pay</div>
                  <div className='totalTitle'>Total to pay out ${manualPayoutOperator === 'PROMOTER' ? money : payoutResult}</div>
                </div>
                <Input
                  disabled={loading || payoutResult === '0.00' || payoutResult === 0}
                  onChange={handleTotalPay}
                  value={totalPay}
                  placeholder={'$' + payoutResult}
                  className='input'
                />
              </div>
              <CustomButton
                onClick={handlePayout}
                disabled={loading || payoutResult === '0.00' || payoutResult === 0}
                loading={loading}
                className='btn'
                text='Apply'
                type='dark'
              />

            </div>
          </div>
          <div className='bankDetailsWrapper'>
            <div className='title'>
              Bank Details
            </div>
            <div className='nodetails'>No details found</div>
          </div>
        </>
      )}
    </Modal>
  )
}

export default MBModalBankDetails
