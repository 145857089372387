import React, { useEffect, useState } from 'react'
import { MainButton } from 'components'
import { getCurrencySymbolByCode, getEventLabel, getUtcOffsetDate, useQuery } from 'utils'
import TwoToneButton from '../Home/TwoToneButton'
import soldOutBadge from 'images/Soldoutbadge.svg'
import { events } from 'api'
import moment from 'moment'
import './index.styl'

const Widget = () => {
  const [event, setEvent] = useState({})
  const [error, setError] = useState(null)
  const query = useQuery()

  const fetchData = () => {
    events.getEvent(query.get('e'), query.get('u'))
      .then(({ data: { data } }) => {
        setEvent({ ...data?.eventData, tickets: data?.ticketArray })
      })
      .catch(() => setError(true))
  }

  useEffect(fetchData, [])

  const getPrice = item => {
    let lowestPrice = item.tickets[0].price
    const currencySymbol = getCurrencySymbolByCode(item?.currency)
    item.tickets.forEach(ticket => {
      const price = ticket.isEarlyBird && moment() <= moment(ticket.earlyBirdDate) && ticket.earlyBirdPrice > 0 ? ticket.earlyBirdPrice : ticket.price
      if (price < lowestPrice) lowestPrice = price
    })
    return `Get from ${currencySymbol} ${lowestPrice % 1 === 0 ? lowestPrice : lowestPrice.toFixed(2)}`
  }

  const renderButton = e => {
    const ButtonComponent = e.isDonationEnable ? TwoToneButton : MainButton
    return (
      <ButtonComponent
        subText='Donate Today'
        text={getPrice(e)}
        className='btn'
      />
    )
  }
  const renderError = <div className='errorContainer'><h2>Something went wrong</h2></div>
  const isQuantityAvailableNull = event?.tickets?.every(i => i.quantityAvailable <= 0)
  return (
    <div className='Widget'>
      {!!Object.keys(event).length
        ? (
          <a
            href={`${window.location.origin}/buy/${query.get('e')}/${query.get('u')}`}
            className='wrapperSlideBody'
            rel='noopener noreferrer'
            target='_blank'
          >
            <div className='eventType'>{getEventLabel(event)}</div>
            <div className='bodyImage'>
              <div className='imgWrap'>
                <img
                  src={event?.imageUrl.original}
                  alt='card' className='img'
                />
                {isQuantityAvailableNull
                  ? (
                    <img src={soldOutBadge} alt='sold out badge' className='soldOutBadge' draggable={false} />
                  ) : (
                    null
                  )}
              </div>
              {/* <div className='background' /> */}
              <div className='front'>
                {!isQuantityAvailableNull && renderButton(event)}
              </div>
            </div>
            <div className='foot'>
              <div className='title'>{event?.eventName}</div>
              <ul className='flex'>
                <li className='name'>{event?.venue ? event?.venue : 'Virtual event'}</li>
                <li className='ellipse'>&#183;</li>
                <li className='date'>{getUtcOffsetDate(event?.startDate, 'MMMM DD, YYYY')}</li>
              </ul>
            </div>
          </a>
        )
        : error ? renderError : <span />}
    </div>
  )
}

export default Widget
