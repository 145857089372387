import React from 'react'
import { Slider } from 'components'
import cardImg from 'images/card.png'
import './index.styl'

const SliderMemberLyfeHome = ({ sliderImages }) => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2200 },
      items: 1,
    },
    largeDesktop: {
      breakpoint: { max: 2200, min: 1800 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  return (
    <Slider className='memberLyfeCarousel' showDots responsive={responsive}>
      {sliderImages?.map((item, index) => (
        <div key={index} className='memberLyfeSlider'>
          <img
            src={item?.thumbnail1}
            onError={e => (e.target.src = cardImg)}
            alt='card'
            className='imgCard'
          />
        </div>
      ))}
    </Slider>
  )
}

export default SliderMemberLyfeHome
