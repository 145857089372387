import React, { useState, useEffect } from 'react'
import { Divider, Form, Switch } from 'antd'
import './index.styl'

import UpdatedTicketCategory from '../../UpdatedTicketCategory'

const { Item, List } = Form
const ItemTicketCategory = ({ currentEventDetails }) => {
  const [itemTicketSwitch, setItemTicketSwitch] = useState(false)
  const itemTicketHandler = () => {
    setItemTicketSwitch(!itemTicketSwitch)
  }
  useEffect(() => {
    if (!!currentEventDetails?.itemTicket?.length) {
      setItemTicketSwitch(true)
    }
  }, [currentEventDetails])
  return (
    <div className='typeCategoryWrapper'>
      <div className='wrapperTitleRadioText'>
        <div className='title'>Item Ticket</div>
        <div className='radioItem'>
          <Item style={{ marginBottom: 0 }} name='itemTicketSwitch' valuePropName='checked' className='item'>
            <Switch onChange={itemTicketHandler} defaultChecked={itemTicketSwitch} />
          </Item>
          <div className='text'>Add Item Ticket</div>
        </div>
      </div>
      {itemTicketSwitch &&
        <List name='itemTicket'>
          {(fields, { add, remove }) => (
            <div className='updatedCategoriesWrapper'>
              {fields.map((field, i) =>
                <div key={field.fieldKey}>
                  <UpdatedTicketCategory
                    categoryType='Item Ticket'
                    namePrefix={i}
                    fieldKey={field.fieldKey}
                    fieldsLength={fields.length}
                    switchToggleOff={setItemTicketSwitch}
                    onRemove={remove}
                  />
                </div>,
              )}
              {fields.length === 0 && (
                <div key='defaultForm'>
                  {/* Render default form when no categories exist */}
                  <UpdatedTicketCategory
                    categoryType='Item Ticket'
                    namePrefix={0}
                    fieldsLength={fields.length + 1}
                    switchToggleOff={setItemTicketSwitch}
                    onRemove={remove}
                  />
                </div>
              )}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                }}
              >Add Another Item Ticket
              </div>
            </div>)}
        </List>}
      <Divider />
    </div>
  )
}

export default ItemTicketCategory
