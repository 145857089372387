import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { CustomButton } from 'components'
import { memberships } from 'apiv2'

import {
  getScannedTickets,
} from 'modules/events/events-actions'
import {
  getMembershipQRCode,
} from 'modules/memberships/memberships-actions'
import { Table, Input, Spin, Pagination } from 'antd'
import SearchIcon from 'images/input-search.svg'
import { throttle } from 'lodash'
import './index.styl'
import MobileTable from '../../MobileTable'
import PrintQRCode from './PrintQRCode'
import ReactToPrint from 'react-to-print'

const throttleFunc = fn => throttle(fn, 300, { trailing: false })

const getOffset = ({ current, limit }) => current * limit - limit
const getParams = ({ current, limit }) => ({ limit, skip: getOffset({ current, limit }) })
const limit = 1000

const ScanTickets = () => {
  const { scannedTickets, scannedTicketsTotal } = useSelector(state => state.events)

  const [currentPageScannedTickets, setCurrentPageScannedTickets] = useState(1)
  const [searchInputScannedTickets, setSearchScannedTickets] = useState('')
  const [loading, setLoading] = useState(true)
  const [qrCodeValue, setQrCodeValue] = useState('')
  const { eventID } = useParams()
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const componentRef = useRef()
  const handlePagination = (current, limit) => fetchEventData(current, limit)

  const fetchEventData = (current, limit, search = '') => {
    dispatch(getScannedTickets({ eventID, search, ...getParams({ current, limit }) }))
      .then(() => {
        setLoading(false)
        setCurrentPageScannedTickets(current)
      })
  }

  const searchFetching = throttleFunc((current, limit, search = '') => {
    dispatch(getScannedTickets({ eventID, search, ...getParams({ current, limit }) }))
      .then(() => {
        setLoading(false)
        setCurrentPageScannedTickets(current)
      })
  })

  useEffect(() => {
    searchFetching(1, limit, searchInputScannedTickets)
  }, [searchInputScannedTickets])

  const onSearch = ({ target }) => setSearchScannedTickets(target?.value)

  const columnsScaned = [
    {
      title: 'Date',
      dataIndex: 'scannedOn',
      key: 'scannedOn',
      width: '15%',
      render: (_, { scannedOn }) => scannedOn ? moment(scannedOn).format('MMM D, YYYY') : '',
    },
    {
      title: 'Time',
      dataIndex: 'scannedOn',
      key: 'scannedOn',
      width: '13%',
      render: (_, { scannedOn }) => scannedOn ? moment(scannedOn).format('h:mm A') : '',
    },
    {
      title: 'Category',
      dataIndex: 'ticketName',
      key: 'ticketName',
      width: '25%',
    },
    {
      title: 'User Name',
      dataIndex: 'userName',
      key: 'userName',
      width: '25%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '27%',
    },
    {
      title: 'Mobile',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
    },
  ]

  const getCSV = async () => {
    const result = await memberships.getScanCsv(eventID)
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }
  useEffect(() => {
    dispatch(getMembershipQRCode(eventID)).then(res => {
      setQrCodeValue(res.data?.qrCode)
    })
  }, [])
  // const handlePrintQRCode = () => {
  //  dispatch(getMembershipQRCode(eventID)).then(res => {
  //    setQrCodeValue(res.data?.qrCode)
  //  })
  // }

  const tableHeader = (
    <div className='tableHeader'>
      <button className={`table-title ${'activeTableTitle'}`}>Scanned in Attendance</button>
      <span className='border' />
      <Input
        prefix={<img alt='search icon' src={SearchIcon} />}
        className='inputSearch noFocusBorder'
        onChange={throttleFunc(onSearch)}
        placeholder='Search'
        value={searchInputScannedTickets}
        allowClear
      />
    </div>
  )

  return (
    <>
      <div className='wrapperMembershipScanner' ref={containerRef}>
        <div className='scanner-header'>
          <div className='scanner-title'>Scan History</div>
          <div className='btnScanHistoryWrapper'>
            <ReactToPrint
              trigger={() => (
                <CustomButton
                  text='Download QR Code'
                  className='donwloadQRCode'
                  // onClick={handlePrintQRCode}
                  type='dark'
                />
              )}
              content={() => componentRef.current}
            />
            <CustomButton
              text='Export History'
              onClick={() => getCSV()}
              className='exportHistory'
              type='dark'
            />
          </div>
          <div style={{ display: 'none' }}>
            <PrintQRCode ref={componentRef} qrCodeValue={qrCodeValue} />
          </div>
        </div>
        <Spin spinning={loading} indicator={null}>

          <div>
            {window.innerWidth > 550
              ? (
                <Table
                  pagination={{ pageSize: 6 }}
                  dataSource={scannedTickets}
                  columns={columnsScaned}
                  rowKey='_id'
                  sticky
                  title={() => tableHeader}
                />
              ) : (
                <div>
                  {tableHeader}
                  <MobileTable data={scannedTickets} columns={columnsScaned} loading={loading} />
                </div>
              )}
          </div>
        </Spin>
        {!!scannedTickets.length && scannedTicketsTotal && (
          <Pagination
            showSizeChanger={false}
            className='pagination'
            onChange={handlePagination}
            disabled={loading}
            current={currentPageScannedTickets}
            pageSize={limit}
            total={scannedTicketsTotal}
          />
        )}
      </div>
    </>
  )
}

export default ScanTickets
