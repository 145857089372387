import React from 'react'
import './index.styl'

const Statistics = () => {
  return (
    <div className='wrapperStatistics' />
  )
}

export default Statistics
