import React, { useEffect, useState } from 'react'
import { minimumPriceValidator, minimumPriceValidatorInviteOnly } from 'modules/utils/validators'
import { Form, Input, InputNumber, Radio, Switch, notification } from 'antd'

import './index.styl'
import TooltipInfo from '../../../../../../CreateEventV2/TooltipInfo'
import SingleDatePicker from '../../../../Header/DetailsFields/SingleDatePicker'
import TablesBlock from './components/TablesBlock'

const { Item } = Form

export const privacyOptionsLabel = () => (
  <TooltipInfo
    className='privacyLabel'
    text='Privacy options'
    info='The privacy options determine who can see your event. If it is a Public event it is available on the main page for anyone to purchase. If it is Invite Only, the only way to get tickets is by Gifting, Direct Sale or sharing the event link.'
  />
)
const TableItems = ({ namePrefix, sectionName, currentEventDetails, categoryData, form }) => {
  const [purchaseOption, setPurhaseOption] = useState(categoryData?.purchaseOption)
  const [isEarlyBird, setIsEarlyBird] = useState(categoryData?.hasEarlyBird || false)
  const [tablesCount, setTablesCount] = useState(categoryData?.tablesCount || null)
  const [totalTablesCount, setTotalTablesCount] = useState(categoryData?.tablesCount || null)
  const [tables, setTables] = useState(categoryData?.tables || [])
  const [price, setPrice] = useState(categoryData?.price || null)
  const [name, setName] = useState(categoryData?.name)
  const [earlyBirdDate, setEarlyBirdDate] = useState(categoryData?.earlyBirdDate || '')
  const [earlyBirdPrice, setEarlyBirdPrice] = useState(categoryData?.earlyBirdPrice || '')
  const [seatCount, setSeatCount] = useState(categoryData?.seatCount)
  const [namePrefixColor, setNamePrefixColor] = useState(categoryData?.tableColor || null)
  const [deletedIndexes, setDeletedIndexes] = useState([])
  const [prevTablesCount, setPrevTablesCount] = useState(categoryData?.tablesCount || null)

  const tableColors = ['#D40000', '#000000', '#000066', '#0000CC', '#00CCFF', '#330000', '#333300', '#336600', '#3333FF', '#339900', '#3399FF', '#33CC00', '#33FF00', '#990000', '#993300', '#996600', '#999900', '#99CC00', '#99FF00', '#FF3300', '#FF6600', '#FF9900', '#FFCC00', '#FFFF00'].sort(() => Math.random() - 0.5)
  useEffect(() => {
    const color = tableColors[namePrefix % tableColors.length]
    setNamePrefixColor(categoryData?.tableColor || color)
  }, [namePrefix])
  useEffect(() => {
    const newTables = Array.from({ length: totalTablesCount }, (_, index) => {
      return {
        tableName: `${index + 1}`,
        seatCount: seatCount,
        seatsFree: seatCount,
        isReserved: false,
      }
    })
    setTables(newTables)
  }, [tablesCount, seatCount])
  useEffect(() => {
    if (currentEventDetails && prevTablesCount !== null) {
      if (tablesCount > prevTablesCount) {
      // add new table
        const newTables = [...tables]
        for (let i = prevTablesCount; i < tablesCount; i++) {
          newTables.push({
            tableName: `${i + 1}`,
            seatCount: seatCount,
            seatsFree: seatCount,
            isReserved: false,
          })
        }
        setTables(newTables)
      } else if (tablesCount < prevTablesCount) {
      // remove tables
        const newTables = tables.slice(0, tablesCount)
        setTables(newTables)
      }
    }
    // update tables
    setPrevTablesCount(tablesCount)
  }, [tablesCount])
  useEffect(() => {
    if (currentEventDetails) {
      const newTables = Array.from({ length: tablesCount }, (_, index) => {
        return {
          tableName: categoryData?.tables?.[index]?.tableName,
          seatCount: categoryData?.tables?.[index]?.seatCount,
          seatsFree: categoryData?.tables?.[index]?.seatsFree,
          isReserved: categoryData?.tables?.[index]?.isReserved,
          id: categoryData?.tables?.[index]?.id,
        }
      })
      setTables(newTables)
    }
  }, [currentEventDetails])

  const addNewTable = () => {
    if (seatCount) {
      let newIndex
      const lastTable = tables[tables.length - 1]
      const maxDeletedIndex = Math.max(...deletedIndexes, 0)

      if (lastTable && !isNaN(parseInt(lastTable.tableName))) {
      // If the last table has a numeric name, generate the next numeric name
        const lastTableNumber = parseInt(lastTable.tableName)
        newIndex = lastTableNumber + 1
      } else {
      // Otherwise, we find the first available numeric name for the new table
        newIndex = 1
        tables.forEach(table => {
          if (!isNaN(parseInt(table.tableName))) {
            newIndex = Math.max(newIndex, parseInt(table.tableName) + 1)
          }
        })
      }

      // We take into account the indexes of remote tables
      newIndex = Math.max(newIndex, maxDeletedIndex + 1)

      const newTable = {
        tableName: `${newIndex}`,
        seatCount: seatCount,
        seatsFree: seatCount,
        isReserved: false,
      }

      // Adding a new table
      setTables(prevTables => [...prevTables, newTable])
      setTotalTablesCount(prevTotal => prevTotal + 1)
    }
  }

  const onRemoveTable = removedTable => {
    const index = parseInt(removedTable.tableName)
    setDeletedIndexes(prevIndexes => [...prevIndexes, index])
    const updatedTables = tables.filter(table => table !== removedTable)
    setTables(updatedTables)
    setTotalTablesCount(prevTotal => prevTotal - 1)
  }

  const handleTablesCountChange = value => {
    setTablesCount(value)
    setTotalTablesCount(value)
  }

  const handleNameChange = event => {
    setName(event.target.value)
  }
  const handlePriceChange = value => {
    setPrice(value)
  }
  const handleEarlyBirdPriceChange = value => {
    setEarlyBirdPrice(value)
  }

  const handleSeatCountChange = value => {
    setSeatCount(value)
  }
  const handleReserveTable = tableIndex => {
  // Update the reservation status for the specified table
    const updatedTables = tables.map((table, index) => ({
      ...table,
      isReserved: index === tableIndex ? !table.isReserved : table.isReserved,
    }))

    // Update the state with the new tables array
    setTables(updatedTables)
  }

  const handlePurchaseOption = type => {
    setPurhaseOption(type)
  }
  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)
  useEffect(() => {
    const currentTableValue = form.getFieldValue('table')
    const updatedTableValue = currentTableValue.map((item, index) => {
      if (index === namePrefix) {
        return {
          name: name || sectionName,
          price: price,
          tablesCount: totalTablesCount,
          seatCount: seatCount,
          purchaseOption: purchaseOption,
          hasEarlyBird: isEarlyBird,
          earlyBirdDate: earlyBirdDate,
          earlyBirdPrice: earlyBirdPrice,
          tableColor: namePrefixColor,
          tables: tables,
          seatSectionID: categoryData?.seatSectionID,
          id: categoryData?.id,
        }
      }
      return item
    })

    form.setFieldsValue({
      table: updatedTableValue,
    })
  }, [name, price, totalTablesCount, seatCount, purchaseOption, isEarlyBird, categoryData?.earlyBirdDate, categoryData?.earlyBirdPrice, categoryData?.tableColor, tables])
  return (
    <div className='tableItemsWrapper'>
      <div className='topField'>
        <div className='inputFields'>
          <Item
            name={[namePrefix, 'seatSectionID']}
            style={{ display: 'none' }}
          />
          <Item
            rules={[{ required: true, message: 'Enter Section Name' }]}
            name={[namePrefix, 'name']}
            label='Table Section Name'
          >
            <Input onChange={handleNameChange} className='inputName' />
          </Item>
          <div className='priceQuantityWrapper'>
            <div className='mobileFlex'>
              <Item
                name={[namePrefix, 'price']}
                label='Ticket Price'
                rules={[
                  { required: true, message: 'Enter price' },
                  minimumPriceValidatorInviteOnly,
                ]}
              >
                <InputNumber onChange={handlePriceChange} placeholder='0' className='price' min={0} />
              </Item>
              <Item
                rules={[{ required: true, message: 'Enter Count of Tables' }]}
                name={[namePrefix, 'tablesCount']}
                label='Count of Tables '
              >
                <InputNumber
                  className='quantity'
                  placeholder='0'
                  min={1}
                  onChange={handleTablesCountChange}
                />
              </Item>
            </div>
            <Item
              rules={[{ required: true, message: 'Enter Count of Seats' }]}
              name={[namePrefix, 'seatCount']}
              label='Count of Seats per Table'
            >
              <InputNumber
                className='quantity seatCountPT'
                placeholder='0'
                min={1}
                onChange={handleSeatCountChange}
              />
            </Item>
          </div>
        </div>
        {/* <div className='privacyOptionWrapper'>
          {privacyOptionsLabel()}
          <Item
            rules={[{ required: true, message: 'Choose Privacy Option' }]}
            name={[namePrefix, 'privacyOption']}
            initialValue={privacyOption}
            className='no_margin item_radio-group'
          >
            <Radio.Group className='radioTable' onChange={e => handlePrivacyOption(e.target.value)}>
              <Radio checked value='visible'>Visible to Public</Radio>
              <Radio value='invite'>Reserved Tables (Invite Only)</Radio>
            </Radio.Group>
          </Item>
        </div> */}
        <div>
          <Item
            rules={[{ required: true, message: 'Choose Purchase Option' },
              {
                validator: (_, value) => {
                  return new Promise(resolve => {
                    if (!value) {
                      // Display notification for missing Purchase Option
                      notification.warning({
                        message: 'Missing Purchase Option',
                        description: 'Please select a desired purchase option.',
                      })
                    } else {
                      resolve()
                    }
                  })
                },
              },
            ]}
            name={[namePrefix, 'purchaseOption']}
            label='Purchase Options (Pick one below and then you can always add another section with a different purchase option)'
            className='no_margin item_radio-group aligment'
          >
            <Radio.Group className='radioTableWrapper' onChange={e => handlePurchaseOption(e.target.value)}>
              <div className={purchaseOption === 'RESERVED_SECTION' ? 'radioContentWrapper active' : 'radioContentWrapper'}>
                <div className='radioContent'>
                  <Radio className='radioBtn' value='RESERVED_SECTION'>
                    <div className='contentTitle'>Reserved Section</div>
                  </Radio>
                  <div className='contentSubtitle'>These tables are reserved from public sale. Must Gift, or Sell via Direct Sale or Cash/ Check Sale</div>
                </div>
              </div>
              <div className={purchaseOption === 'UNASSIGNED_SECTION' ? 'radioContentWrapper active' : 'radioContentWrapper'}>
                <div className='radioContent'>
                  <Radio className='radioBtn' value='UNASSIGNED_SECTION'>
                    <div className='contentTitle'>Unassigned Section</div>
                  </Radio>
                  <div className='contentSubtitle'>These tables are for public sale. But you the Promoter must move each ticket onto a table</div>
                </div>
              </div>
              <div className={purchaseOption === 'ASSIGNED_SECTION' ? 'radioContentWrapper active' : 'radioContentWrapper'}>
                <div className='radioContent'>
                  <Radio className='radioBtn' value='ASSIGNED_SECTION'>
                    <div className='contentTitle'>Assigned Table Seating</div>
                  </Radio>
                  <div className='contentSubtitle'>These tables are for public sale where the buyer picks the table they are seating</div>
                </div>
              </div>
            </Radio.Group>
          </Item>
        </div>
      </div>
      <div className='radioItemField earlyBirdDateItem'>
        <Item
          name={[namePrefix, 'hasEarlyBird']}
          className='item'
          valuePropName='checked'
        >
          <Switch onChange={onCheckEarlyBird} defaultChecked={isEarlyBird} />
        </Item>
        <div className='text'>Early Bird Special</div>
      </div>
      {isEarlyBird && (
        <div className='earlyBirdFieldsWrapper'>
          <SingleDatePicker label='Early Bird' namePrefix={namePrefix} nameText='earlyBirdDate' fromDate={earlyBirdDate} form={form} setFromDate={setEarlyBirdDate} title='Early Bird Date' />
          <Item
            name={[namePrefix, 'earlyBirdPrice']}
            dependencies={[[namePrefix, 'price']]}
            label='Price'
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
            className='earlyBirdPrice'
          >
            <InputNumber onChange={handleEarlyBirdPriceChange} placeholder='0' min={0} />
          </Item>
        </div>
      )}
      <Item
        name={[namePrefix, 'tableColor']}
        style={{ display: 'none' }}
      />
      <div className='titleTable'>Tables</div>
      <Item className='labelTabels' name={[namePrefix, 'tables']}>
        <TablesBlock currentEventDetails={currentEventDetails} tablesSeatCount={seatCount} handleReserveTable={handleReserveTable} namePrefix={namePrefix} form={form} setTables={setTables} onRemoveTable={onRemoveTable} addNewTable={addNewTable} namePrefixColor={namePrefixColor} tables={tables} />
      </Item>
    </div>
  )
}

export default TableItems
