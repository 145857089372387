import React, { useRef, useState } from 'react'
import { Modal, Form, Input, notification } from 'antd'
import moment from 'moment'
import { memberships } from 'apiv2'
import { useHistory } from 'react-router-dom'
import calendarIcon from 'images/calendarIcon.svg'
import CrossMark from 'images/date-cross.svg'
import InputMask from 'react-input-mask'
import { mobileValidator } from 'modules/utils/validators'
import { CustomButton } from 'components'
import { useDispatch } from 'react-redux'
import './index.styl'

const { Item } = Form

const ModalAdditionalInformation = ({ visible, setVisible, signupFields = [], rosterDoB, parentApproval, eventID, page }) => {
  const [isAdult, setIsAdult] = useState(true)
  const [loading, setLoading] = useState(false)
  const [birthDate, setBirthDate] = useState(null)
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  // Prepare initial values for the form based on signupFields
  const initialFormValues = signupFields?.reduce((acc, field) => {
    acc[field.name] = field.value || '' // Set initial value to the provided value or empty string
    return acc
  }, {})

  const handleDateChange = e => {
    const date = e.target.value
    setBirthDate(date)
    const birthMoment = moment(date, 'MM/YYYY')
    const age = moment().diff(birthMoment, 'years')
    if (/^\d{2}\/\d{4}$/.test(date) && !isNaN(age) && age >= 0) {
      setIsAdult(age >= 18)
    } else {
      setIsAdult(true)
    }
  }

  const validateBirthDate = (_, value) => {
    const [month, year] = value?.split('/')
    if (month && year) {
      const monthNum = parseInt(month, 10)
      if (monthNum < 1 || monthNum > 12) {
        return Promise.reject(new Error('Enter a valid month (01-12)'))
      }

      const birthMoment = moment(`${year}-${month}-01`)
      const currentDate = moment()
      const age = currentDate.diff(birthMoment, 'years')

      if (monthNum < 1 || monthNum > 12) {
        return Promise.reject(new Error('Enter a valid month (01-12)'))
      }

      if (birthMoment.isAfter(currentDate)) {
        return Promise.reject(new Error('Date cannot be in the future'))
      }

      if (age > 100) {
        return Promise.reject(new Error('Age cannot exceed 100 years'))
      }

      return Promise.resolve()
    }
  }

  const submitForm = value => {
    if (birthDate && !value.birthDate || birthDate && !/^\d{2}\/\d{4}$/.test(value.birthDate)) {
      notification.warning({
        message: 'Enter Birth Month/Year in MM/YYYY format',
      })
      return
    }
    setLoading(true)
    const fieldData = signupFields?.map(field => ({
      id: field.id,
      value: value[field.name] !== undefined ? value[field.name] : undefined,
    })).filter(field => field.value !== undefined)

    const dateTransform = date => {
      const [month, year] = date?.split('/')
      return `${year}-${month}`
    }

    const obj = {
      eventID,
      signupData: fieldData || [],
      dob: value.birthDate ? dateTransform(value.birthDate) : undefined,
    }

    if (value.name || value.email || value.phone) {
      obj.parentData = {
        name: value.name,
        email: value.email,
        phone: value.phone,
      }
    }

    dispatch(memberships.membershipRequest(obj)
      .then(() => {
        setVisible(false)
        if (page !== 'wallet') history.push('/my-member-wallet')
      })
      .catch(err => notification.warning({ message: err?.response?.data?.error || err?.response?.data?.message }))
      .finally(() => setLoading(false)))
  }

  const clearDate = () => {
    setBirthDate('')
    setIsAdult(true)
    form.resetFields(['birthDate'])
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }, 0)
  }
  const dateIcon = birthDate && birthDate !== '__/____' ? (
    <img src={CrossMark} alt='Clear Date' className='clearDateInput' onClick={clearDate} />
  ) : (
    <img src={calendarIcon} alt=' ' />
  )

  return (
    <Modal
      className='modal-additional-information'
      title='Please Provide Additional Details'
      visible={visible}
      width={472}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      closable={!loading}
      destroyOnClose
    >
      <Form onFinish={submitForm} initialValues={initialFormValues} layout='vertical' form={form} id='additional-info'>
        {rosterDoB &&
          <Item
            rules={[
              { required: true, message: 'Enter Birth Month/Year' },
              { validator: validateBirthDate },
            ]}
            label='Birth Month/Year'
            name='birthDate'
            className='defaultInputLabel item'
          >
            <InputMask
              mask='99/9999'
              value={birthDate}
              onChange={handleDateChange}
              placeholder='MM/YYYY'
              suffix={dateIcon}
            >
              {inputProps => <Input {...inputProps} ref={inputRef} className='darkBg dateOfBirthInput' />}
            </InputMask>
          </Item>}
        {birthDate && parentApproval && !isAdult && (
          <>
            <Item
              rules={[
                { required: true, message: 'Please enter email!' },
                { type: 'email', message: 'Please enter a correct email!', transform: value => value?.trim() },
              ]}
              label='Parent Email'
              className='defaultInputLabel email'
              name='email'
              normalize={v => v.trimStart()}
            >
              <Input className='darkBg' />
            </Item>
            <Item
              rules={[{ required: true, message: 'Please enter parent name!' }]}
              normalize={v => v.trimStart()}
              name='name'
              label='Parent Name'
              className='item'
            >
              <Input className='darkBg' maxLength={32} />
            </Item>
            <Item
              name='phone'
              label='Parent Phone'
              className='defaultInputLabel haveMargin mobile'
              rules={[{ required: true, message: 'Please enter phone number' }, mobileValidator]}
            >
              <Input className='darkBg' type='number' />
            </Item>
          </>
        )}
        {signupFields?.map(field => (
          <div key={field.id}>
            {field?.minorsOnly && !isAdult && birthDate && (
              <Item
                rules={[{ required: field.isRequired, message: `Please enter ${field?.name}!` }]}
                normalize={v => v.trimStart()}
                name={field?.name}
                label={field?.name}
                className='item'
              >
                <Input className='darkBg' maxLength={32} />
              </Item>
            )}
            {!field?.minorsOnly &&
              <Item
                rules={[{ required: field.isRequired, message: `Please enter ${field?.name}!` }]}
                normalize={v => v.trimStart()}
                name={field?.name}
                label={field?.name}
                className='item'
              >
                <Input className='darkBg' maxLength={32} />
              </Item>}
          </div>
        ))}
        <div className='additionalInfoBtnWrapper'>
          <CustomButton onClick={() => setVisible(false)} className='cancelInfoBtn' text='Cancel' type='white' />
          <CustomButton className='submitInfoBtn' text='Save' htmlType='submit' type='dark' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalAdditionalInformation
