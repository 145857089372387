import React from 'react'
import { getUtcOffsetDate, getGoogleUrl, getCurrencySymbolByCode } from 'utils'
import clock from 'images/Time.svg'
import location from 'images/Location.svg'
import calendar from 'images/Calendar.svg'
import priceIcon from 'images/ticket.svg'
import { Image } from 'antd'
import cardImg from 'images/card.png'
import './index.styl'

const TicketDetails = ({
  startDate,
  endDate,
  image,
  eventName,
  address,
  ticket,
  presentedBy,
  originalPrice,
  currency,
  imageUrl,
  ...other
}) => {
  const startDateTransformed = getUtcOffsetDate(startDate)
  const endDateTransformed = getUtcOffsetDate(endDate)
  const _address = address?.split(',').join('').split(' ').join('+')
  const { timeZone = '' } = ticket || other || {}
  const errorHandler = e => (e.target.src = cardImg)
  const Item = ({ icon, title, text, textClass = '' }) => (
    <li className='item'>
      <div className='leftSide'>
        <img src={icon} alt=' ' />
        <span>{title}</span>
      </div>
      <div className={['text', textClass].join(' ')}>{text}</div>
    </li>
  )
  return (
    <div className='DetailTicketHeader'>
      <div className='imgWrapper'>
        <Image src={imageUrl?.thumbnail1} onError={errorHandler} preview={{ src: imageUrl?.sourceImage }} className='imgVideo' />
        {/* <img onError={errorHandler} src={image} alt=' ' className='imgVideo' /> */}
      </div>
      <div className='wrapperDetails'>
        <div className='detailsContainer'>
          <div className='containerTitle'>
            <div className='detailTicketTitle'>{eventName}</div>
            {presentedBy}
          </div>
          <ul className='wrapperItems'>
            <Item
              text={getUtcOffsetDate(startDate, 'MMMM DD, YYYY')}
              title='Date'
              icon={calendar}
            />
            <Item
              text={`${startDateTransformed} - ${endDateTransformed} ${timeZone}`}
              textClass='time'
              title='Time'
              icon={clock}
            />
            <Item
              text={originalPrice ? `${getCurrencySymbolByCode(currency)}${originalPrice}` : `${getCurrencySymbolByCode(currency)}${0}`}
              title='Price'
              icon={priceIcon}
            />
            {_address && (
              <Item
                text={<a target='_blank' rel='noopener noreferrer' href={getGoogleUrl(address)}>{address}</a>}
                title='Location'
                icon={location}
              />
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TicketDetails
