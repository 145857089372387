/* eslint-disable prefer-promise-reject-errors */
import React, { useState } from 'react'
import { Form, InputNumber, Divider, Space, Radio, Checkbox } from 'antd'
import { handleConfirmModal, colors } from 'utils'
import { minimumPriceValidator } from 'modules/utils/validators'
import './index.styl'
const { Item } = Form

const Categories = ({ field, onRemove, categories, isTemplate, isInviteOnlyCategory, removeIndex, setTemplateBgColor, setTemplatePrice, onUpdateCategory }) => {
  const caregoryInviteOnly = categories?.some(category => category.inviteOnly)
  const [inviteOnlyCategory, setInviteOnlyCategory] = useState(caregoryInviteOnly || false)
  const onRemoveSubpromotion = index => {
    handleConfirmModal('Are you sure you want to remove this category', () => {
      onRemove(index)
      removeIndex && removeIndex(true)
    })
  }
  const onCheckInviteOnlyCategory = () => setInviteOnlyCategory(!inviteOnlyCategory)
  return (
    <Space
      style={{ display: 'flex', flexDirection: 'column' }}
      key={[field.fieldKey, 'templateCategories']}
    >
      <div className='categoryWrapper priceCategoriesTemplate'>
        <div className='priceCategories'>
          <Item
            name={[field.name, 'name']}
            style={{ position: 'absolute' }}
            initialValue={`Category ${field.name + 1}`}
            fieldKey={[field.fieldKey, 'name']}
            onChange={onUpdateCategory}
          />
          <Item
            name={[field.name, 'color']}
            label='Color'
            className='item-colorPicker'
            fieldKey={[field.fieldKey, 'color']}
            rules={[{ required: true, message: 'Choose background color' }]}
          >
            <Radio.Group className='radio-colors' onChange={onUpdateCategory}>
              {colors.map(
                color =>
                  <Radio
                    onChange={color => setTemplateBgColor && setTemplateBgColor(color)}
                    key={color}
                    value={color}
                    style={{ backgroundColor: color }}
                  />)}
            </Radio.Group>
          </Item>
          <Item
            name={[field.name, 'price']}
            label='Price'
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
            className='templatePrice'
            fieldKey={[field.fieldKey, 'price']}
            onChange={onUpdateCategory}
          >
            <InputNumber
              onChange={value => setTemplatePrice && setTemplatePrice(value)}
              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              className='input fullWidth'
              addonBefore='$'
              min={0}
            />
          </Item>
          {isInviteOnlyCategory && !isTemplate &&
            <Item
              name={[field.name, 'isInviteOnly']}
              fieldKey={[field.fieldKey, 'isInviteOnly']}
              className='item-checkbox'
              valuePropName='checked'
              initialValue={inviteOnlyCategory}
              onChange={onUpdateCategory}
            >
              <Checkbox value={inviteOnlyCategory} checked={inviteOnlyCategory} onChange={onCheckInviteOnlyCategory}>
                Invite Only
              </Checkbox>
            </Item>}
        </div>
        {field?.name !== 0 &&
          <>
            <Divider />
            <div
              id='removePriceCategory'
              style={{ justifyContent: 'flex-start' }}
              onClick={() => onRemoveSubpromotion(field?.name)}
              className='removeBtn'
            >
              Remove Category {field.name + 1}
            </div>
          </>}
      </div>
    </Space>
  )
}

export default Categories
