import React from 'react'
import './index.styl'
import { Button } from 'antd'
function DateItem (props) {
  if (!props.dateObj) {
    return <button className='empty-date-item'> </button>
  }

  const displayDate = props.dateObj.date()
  const cssClasses = ['date-item']

  if (props.selected) {
    cssClasses.push('selected')
  }
  return (
    <Button disabled={props.disabledDate(props.dateObj)} onClick={props.onClick} className={cssClasses.join(' ')}>
      {displayDate}
    </Button>
  )
}

export default DateItem
