import React, { useState } from 'react'
import { Input, Modal } from 'antd'
import './index.styl'

const ModalRenameField = ({ field, label, title, onClose, updateData, loading }) => {
  const [name, setName] = useState(field)
  const onSave = () => updateData(name)
  return (
    <Modal
      okButtonProps={{ loading, disabled: loading || !name }}
      maskStyle={{ backgroundColor: '#62677EE5' }}
      onCancel={() => loading ? {} : onClose()}
      className='ModalRenameField'
      title={title || 'Edit table'}
      closable={!loading}
      onOk={onSave}
      okText='Save'
      visible
    >
      <div className='item'>
        <div className='label'><span>*</span>{label || 'Edit field'}</div>
        <Input value={name} onChange={e => setName(e.target?.value)} />
      </div>
    </Modal>
  )
}

export default ModalRenameField
