import React, { useEffect, useState } from 'react'
import LogoMemberLyfe from '../LogoMemberLyfe'
import SwitchLayoutArrow from 'images/switchLayoutArrow.svg'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Logo from '../Logo'
import './index.styl'

const SwitcherBlock = ({ type }) => {
  const [pageType, setPageType] = useState('ticket')
  const { user, isMobile } = useSelector(state => state.session)
  useEffect(() => {
    setPageType(type)
  }, [type])

  return (
    <div className={isMobile ? 'burgerSwitcherBlock' : 'switcherBlock'}>
      <div className='arrowBlock'>
        <img src={SwitchLayoutArrow} alt='switch icon' className='switchArrow' />
      </div>
      {pageType === 'member'
        ? (
          <Link to='/home'>
            <div className='textSwitcherBlock'>
              <div className='textSwitcher'>Switch to {pageType === 'member' ? 'Ticket Lyfe' : 'Member Lyfe'}</div>
              <Logo isSwitch />
            </div>
          </Link>
        ) : (
          <Link to={user ? '/memberlyfe-home' : '/help/overview/my-box-office'}>
            <div className='textSwitcherBlock'>
              <div className='textSwitcher'>Switch to {pageType === 'member' ? 'Ticket Lyfe' : 'Member Lyfe'}</div>
              <LogoMemberLyfe isSwitch user={user} />
            </div>
          </Link>
        )}
    </div>
  )
}

export default SwitcherBlock
