import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { CustomButton } from 'components'
import ThreeDotIcon from 'images/threedots.svg'
import { eventsV2 } from 'apiv2'
import { Table, Input, Pagination, Popover, notification } from 'antd'
import { handleConfirmModal } from 'utils'
import SearchIcon from 'images/input-search.svg'
import { throttle } from 'lodash'
import MobileTable from '../../MobileTable'
import './index.styl'
import ModalCreateBundle from '../../../../components/ModalCreateBundle'
import ModalEditBundles from '../../../../components/ModalEditBundles'

const throttleFunc = fn => throttle(fn, 300, { trailing: false })
const pageSize = 3

const BundlesDistribution = ({ currencySymbol, currentEventDetails }) => {
  const { eventID } = useParams()
  const [visibleModal, setVisibleModal] = useState(false)
  const [visibleEditModal, setVisibleEditModal] = useState(false)
  const [totalBundles, setTotalBundles] = useState(0) // Total number of winners in the current tab
  const [searchInput, setSearchInput] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [categoryCounts, setCategoryCounts] = useState([])
  const [bundleList, setBundleList] = useState([])
  const [selectedBundleID, setSelectedBundleID] = useState('')
  const [loading, setLoading] = useState(true)
  const popoverRef = useRef()
  const fetchBundleList = (search, pageSize, skip) => {
    setLoading(true)
    eventsV2.getBundleList(eventID, pageSize, skip, search)
      .then(data => {
        setBundleList(data?.data?.bundles)
        setTotalBundles(data?.data?.meta.total)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchBundleList(searchInput)
  }, [searchInput])

  const handleSubmitBundle = values => {
    setLoading(true)
    const obj = { ticketCategories: categoryCounts, bundleName: values.bundleName, notes: values.notes, price: values.price, quantity: values.quantity }
    eventsV2.createBundle(eventID, obj).then(data => {
      setVisibleModal(false)
      setCategoryCounts([]) // Reset the categoryCounts to an empty array
      fetchBundleList()
    }).catch(error => notification.warning({ message: error?.response?.data?.error })).finally(() => setLoading(false))
  }
  const handleEditBundle = values => {
    setLoading(true)
    const obj = { bundleID: selectedBundleID, bundleName: values.bundleName, notes: values.notes, price: values.price, quantity: values.quantity }
    eventsV2.editBundle(eventID, obj).then(data => {
      setVisibleEditModal(false)
      fetchBundleList()
    }).catch(error => notification.warning({ message: error?.response?.data?.error })).finally(() => setLoading(false))
  }

  const onChangeSearch = throttleFunc(e => {
    const search = e.target.value
    setSearchInput(search)
  })

  const onPageChange = page => {
    setCurrentPage(page)
    fetchBundleList(searchInput, pageSize, (page - 1) * pageSize)
  }

  const handleModal = () => {
    setVisibleModal(!visibleModal)
    setCategoryCounts([]) // Reset the categoryCounts to an empty array
  }
  const handleEditModal = bundleID => {
    setSelectedBundleID(bundleID)
    setVisibleEditModal(!visibleEditModal)
    popoverRef.current.close()
  }
  const handleDeleteBundle = bundleID => {
    popoverRef.current.close()
    handleConfirmModal(
      'Are you sure you want to delete bundle?',
      () =>
        eventsV2.deleteBundle(eventID, { bundleID }).then(data => {
          fetchBundleList()
        }).catch(error => notification.warning({ message: error?.response?.data?.error })),
    )
  }
  const popoverContent = bundleID => {
    return (
      <div className='actionPopover'>
        <div className='popoverBtn' onClick={() => handleEditModal(bundleID)}>Edit Bundle</div>
        <div className='popoverBtn btnRemove' onClick={() => handleDeleteBundle(bundleID)}>Remove Bundle</div>
      </div>
    )
  }

  const columnsBundles = [
    {
      title: 'Bundle Name',
      dataIndex: 'bundleName',
      key: 'bundleName',
      width: '20%',
    },
    {
      title: 'Categories',
      dataIndex: 'ticketCategories',
      key: 'ticketCategories',
      width: '20%',
      render: ticketCategories => {
        return (
          <ul>
            {ticketCategories?.map((category, index) => (
              <li key={index} className='list-item'>{category.categoryName} ({category.quantity})</li>
            ))}
          </ul>
        )
      },
    },
    {
      title: 'Note',
      dataIndex: 'notes',
      key: 'notes',
      width: '45%',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '5%',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      width: '5%',
      render: (item, record) => <span>{item ? currencySymbol : ''}{item}</span>,
    },
    {
      key: 'moreOptions',
      dataIndex: 'moreOptions',
      render: (item, record) => {
        return (
          <>
            <Popover
              overlayClassName='actionPopoverContainer'
              content={popoverContent(record.id)}
              getPopupContainer={node => node?.parentNode}
              placement='top'
              trigger='click'
              ref={popoverRef}
            >
              <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
              <CustomButton className='xsVisible' text='More Options' />
            </Popover>
          </>
        )
      },
    },
  ]

  const searchPanel = (
    <div className='searchPanel'>
      <Input
        className='inputSearch darkBg noFocusBorder'
        onChange={e => onChangeSearch(e)}
        placeholder='Search'
        prefix={<img alt='search icon' src={SearchIcon} />}
        allowClear
      />
    </div>)

  const catTabs = () => (
    <div className='tableHeader'>
      <Input
        prefix={<img alt='search icon' src={SearchIcon} />}
        className='inputSearch noFocusBorder responsiveInput'
        onChange={e => onChangeSearch(e)}
        placeholder='Search'
        value={searchInput}
        allowClear
      />
    </div>
  )
  return (
    <>
      <div className='wrapperBundlesDistribution'>
        <div className='bundles-header'>
          <div className='bundles-title'>Bundles Distribution</div>
          <CustomButton
            text='Create a Bundle'
            className='btnHeader'
            onClick={handleModal}
            type='dark'
          />
        </div>
        <div className='wrapperTableHeader'>
          {window.innerWidth > 1295
            ? (
              <Table
                columns={columnsBundles}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalBundles,
                  onChange: onPageChange,
                }}
                loading={loading}
                dataSource={bundleList}
                title={() => catTabs()}
                className='bundlesTableDetails'
              />
            )
            : (
              <div className='mobileTable'>
                {searchPanel}
                <MobileTable data={bundleList} columns={columnsBundles} loading={loading} />
                <Pagination
                  hideOnSinglePage total={totalBundles} pageSize={pageSize} onChange={page => onPageChange(page)}
                  current={currentPage} className='pagination'
                />
              </div>)}
        </div>
      </div>
      <ModalCreateBundle
        visible={visibleModal}
        updateVisible={handleModal}
        categoryCounts={categoryCounts}
        setCategoryCounts={setCategoryCounts}
        submitForm={handleSubmitBundle}
        currentEventDetails={currentEventDetails}
        loading={loading}
        currencySymbol={currencySymbol}
      />
      <ModalEditBundles selectedBundleID={selectedBundleID} bundleList={bundleList} onFinish={handleEditBundle} visible={visibleEditModal} onChange={handleEditModal} />
    </>
  )
}

export default BundlesDistribution
