import React, { useState } from 'react'
import './index.styl'
import seatsIcon from 'images/seatsIcon.svg'
import tablesIcon from 'images/tablesIcon.svg'
import addNewIcon from 'images/addNewTable.svg'
import { Popover, notification } from 'antd'
import { useDispatch } from 'react-redux'
import ThreeDotIcon from 'images/threedots.svg'
import { template } from 'apiv2'
import Section from './Section'
import { handleConfirmModal } from 'utils'

import { editSection } from 'modules/templates/templates-actions'
import { addNewTable } from 'modules/events/events-actions'
import { ModalTemplate } from '../../../../components'
import SectionTable from './SectionTable'

const SectionScheme = ({ section, loading, setLoading, ...props }) => {
  const { categories, id } = props
  const [visible, setVisible] = useState(false)
  const [visibleNewTable, setVisibleNewTable] = useState(false)
  const [openContent, setOpenContent] = useState(false)
  const [blockSale, setBlockSale] = useState(false)
  // const [tableSeatSelection, setTableSeatSelection] = useState(section.tableSeatSelection || false)
  const dispatch = useDispatch()
  const onChange = e => {
    handleEventPropagation(e)
    setVisible(!visible)
    setOpenContent(false)
  }
  const onChangeNewTable = e => {
    handleEventPropagation(e)
    setVisibleNewTable(!visibleNewTable)
    setOpenContent(false)
  }

  // const tableSeatSelectionHandler = () => {
  //   setTableSeatSelection(!tableSeatSelection)
  // }
  const handleOpenChange = newOpen => {
    setOpenContent(newOpen)
  }
  const onChangeLoading = () => setLoading(!loading)
  const onFinish = form => {
    const { type, name, groupCount, categoryID, seatCount, rowArrangement, tableSeatSelection } = form
    if (!name || !groupCount || !seatCount) return
    onChangeLoading()
    const tableSeatSelectionOwner = tableSeatSelection ? 'BUYER' : 'PROMOTER'
    const obj = { type, name, groupCount, seatCount, categoryID, rowArrangement, id: section.id, tableSeatSelection: tableSeatSelectionOwner }
    dispatch(editSection(id, obj)).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }
  const onFinishNewTable = form => {
    const { type, name, groupCount, categoryID, seatCount, rowArrangement, tableSeatSelection } = form
    if (!name || !seatCount) return
    onChangeLoading()
    const tableSeatSelectionOwner = tableSeatSelection ? 'BUYER' : 'PROMOTER'
    const obj = { type, name, groupCount, seatCount, blockSale, categoryID, rowArrangement, sectionID: section.id, tableSeatSelection: tableSeatSelectionOwner }
    dispatch(addNewTable(id, obj)).then(() => {
      setLoading(false)
      onChangeNewTable()
    }).catch(() => setLoading(false))
  }
  const handleEventPropagation = e => {
    onStop(e)
    onPrevent(e)
  }
  const deleteTemplateSection = e => {
    handleEventPropagation(e)
    setOpenContent(false)
    const obj = { id: section.id }
    onDeleteSection(id, obj)
  }

  const onStop = e => {
    e && e.stopPropagation && e.stopPropagation()
  }
  const onPrevent = e => {
    e && e.preventDefault && e.preventDefault()
  }
  const onDeleteSection = (id, obj) => {
    const onOk = () => {
      setLoading(true)
      template.deleteTemplateSection(id, obj).then(() => setLoading(false)).catch(error => {
        notification.warning({ message: error.response.data.error })
        setLoading(false)
      })
    }
    handleConfirmModal('Are you sure you want to delete this section?', onOk)
  }
  const popoverContent = (
    <div className='actionPopover'>
      <div className='popoverBtn' onClick={onChange}>Edit section</div>
      {props?.sections?.length > 1 && <div className='popoverBtn popoverBtnRemove' onClick={deleteTemplateSection}>Delete section</div>}
    </div>
  )
  return (
    <div className={section.sectionType === 'ROWS' ? 'boxOfficeSectionSchemeWrapper' : 'boxOfficeSectionSchemeWrapperTable'}>
      <div className='headers'>
        <div className={section.index === 0 ? 'section0' : 'sectionA section0'}>
          <div className='name'>{section.sectionName}</div>
          <img alt='rows' src={section.sectionType === 'ROWS' ? seatsIcon : tablesIcon} />
          {section.sectionType === 'ROWS' ? <span>Rows</span> : <span>Tables</span>}
          <div className='popoverButton'>
            <Popover
              overlayClassName='actionPopoverContainer'
              getPopupContainer={node => node?.parentNode}
              content={popoverContent}
              placement='top'
              trigger='click'
              visible={openContent}
              onVisibleChange={handleOpenChange}
            >
              <img src={ThreeDotIcon} className='threeDots' alt=' ' />
            </Popover>
          </div>
        </div>
      </div>
      <div className={section.sectionType === 'TABLES' ? 'sectionWrapper' : 'rowWrapp'}>
        {section.groups.map(group => {
          return (
            <div key={group.id}>
              {section.sectionType === 'ROWS'
                ? (
                  <Section tableGroups={section.groups} loading={loading} setLoading={setLoading} templateID={id} categoriesArray={categories} sectionType={section.sectionType} category={group?.category} {...group} />
                ) : (
                  <SectionTable blockSaleInitial={group.blockSale} seatsFree={group.seatsFree} categoryID={group.category.id} seatID={group.id} loading={loading} setLoading={setLoading} templateID={id} categoriesArray={categories} seatName={group.groupName} color={group?.category?.color} seatCount={group.seatCount} />
                )}
            </div>
          )
        })}
        {section.sectionType === 'TABLES' &&
          <div onClick={onChangeNewTable} className='tableRectangle'>
            <div className='creationBlockAction'>
              <img src={addNewIcon} alt='' />
              <div className='addNewTitle'>Add New Table</div>
            </div>
          </div>}
        <ModalTemplate additionalProps={{ type: section.sectionType, categoriesArray: categories, sectionName: section.sectionName, groupCount: section.groupCount, seatCount: section.seatCount, rowArrangement: section.rowArrangement, initialColor: section?.category?.id }} visible={visible} onChange={onChange} onFinish={onFinish} name='section' width={472} title='Edit Section' loading={loading} />
        <ModalTemplate additionalProps={{ categoriesArray: categories, blockSale, setBlockSale }} visible={visibleNewTable} onChange={onChangeNewTable} onFinish={onFinishNewTable} name='addTable' width={472} title='Add New Table' loading={loading} />
      </div>
    </div>)
}

export default SectionScheme
