import React from 'react'
import { Button } from 'antd'
import c from 'classnames'
import './index.styl'

const TwoToneButton = ({ onClick, text, subText, className }) => {
  return (
    <Button onClick={onClick} className={c('TwoToneButton', className)}>
      <div className={c('btnTitle', { isSingle: !subText })}>{text}</div>
      {subText && <div className='btnSubtitle'>{subText}</div>}
    </Button>
  )
}

export default TwoToneButton
