import React from 'react'
import moment from 'moment-timezone'

import './index.styl'

const SectionColor = ({ category, timeZone }) => {
  const currentDateTime = moment().tz(timeZone)
  const earlyBirdDateTime = moment.tz(category?.earlyBirdDate, 'YYYY-MM-DD', timeZone)
  const isEarlyBirdActive = category.isEarlyBird && currentDateTime.isSameOrBefore(earlyBirdDateTime)

  const currentPrice = isEarlyBirdActive ? '$' + category.earlyBirdPrice : '$' + category.price
  const isColorBlack = category.color === '#FFFF00' || category.color === '#FFCC00' || category.color === '#FF9900' || category.color === '#FF6600' || category.color === '#99CC00' || category.color === '#99FF00' || category.color === '#33FF00' || category.color === '#33CC00'
  return (
    <div className='categoryColorWrapper'>
      <div style={isColorBlack ? { backgroundColor: category.color, color: 'black' } : { backgroundColor: category.color }} className={isEarlyBirdActive ? 'colorBlock earlyBirdPadding ' : 'colorBlock'}>
        {isEarlyBirdActive && <span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '12px', fontWeight: '400px', marginBottom: '3px' }}>{'$' + category.price}</span>}
        <div>
          {currentPrice}
        </div>
      </div>
    </div>
  )
}

export default SectionColor
