import React from 'react'
import ReactDOM from 'react-dom'
import { Tooltip } from 'antd'
import './index.styl'

export default class OverflowTooltip extends React.Component {
  state = {
    overflow: false,
  }

  componentDidMount () {
    this.checkOverflow()
  }

  componentWillReceiveProps () {
    this.setState({ overflow: false })
  }

  componentDidUpdate () {
    this.checkOverflow()
  }

  isTextOverflow = element => {
    const { ignoreHeight, ignoreWidth } = this.props
    if (!ignoreHeight && element?.clientHeight < element?.scrollHeight) return true
    if (!ignoreWidth && element?.clientWidth < element?.scrollWidth) return true
    return false
  }

  checkOverflow () {
    const element = ReactDOM.findDOMNode(this)

    const overflow = this.isTextOverflow(element)
    if (overflow !== this.state.overflow) {
      this.setState({ overflow: overflow })
    }
  }

  render () {
    if (this.state.overflow) {
      return <Tooltip {...this.props} title={<span className='tooltipText'>{this.props.title}</span>} />
    } else {
      return this.props.children
    }
  }
}
