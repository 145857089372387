import React, { useContext, useEffect, useState } from 'react'
import { Form, Input, Space, Divider, Switch } from 'antd'
import TooltipInfo from '../TooltipInfo'
import c from 'classnames'
import { CreateEventContext } from '../'
import { urlValidator } from 'modules/utils/validators'
import { getEventUrl, MAIN_APP_URL } from 'utils'
import './index.styl'

const { Item } = Form
const { TextArea } = Input
const eventUrlLabel = () => (
  <TooltipInfo
    text='Customized ticket link (Vanity URL)'
    info='The Customized Ticket Link can be a custom, memorable and pronounceable link to tickets for your event. Use this for marketing or to send people directly to your event. This feature is only available for paid ticket events'
  />
)

const OptionsEvent = ({ currentEventDetails, updatePrivacyType, addNewCategory }) => {
  const { form } = useContext(CreateEventContext)
  const [donationTicketBool, setDonationTicketBool] = useState(form.getFieldValue('donationEnabled') || false)
  const [availableTicket, setAvailableTicket] = useState(form.getFieldValue('showAvailableTickets') || false)
  const [switchValue, setSwitchValue] = useState(currentEventDetails?.isShowTLHomePage || true)
  const [isDisabled, setIsDisabled] = useState(false)
  const categories = form.getFieldValue('categories')
  const visibleCategory = categories.some(c => c.privacyOption === 'visible')
  useEffect(() => {
    if (!visibleCategory) {
      setIsDisabled(true)
      setSwitchValue(false)
      form.setFieldsValue({
        isShowTLHomePage: false,
      })
    } else if (currentEventDetails && !currentEventDetails?.isVenueDraft) {
      setIsDisabled(false)
      setSwitchValue(currentEventDetails?.isShowTLHomePage)
      form.setFieldsValue({
        isShowTLHomePage: currentEventDetails?.isShowTLHomePage,
      })
    } else if (currentEventDetails?.isVenueDraft) {
      setIsDisabled(false)
      setSwitchValue(true)
      form.setFieldsValue({
        isShowTLHomePage: true,
      })
    } else {
      setIsDisabled(false)
      setSwitchValue(true)
      form.setFieldsValue({
        isShowTLHomePage: true,
      })
    }
  }, [visibleCategory, addNewCategory, updatePrivacyType])
  const handleSwitchChange = value => {
    setSwitchValue(value)
  }

  const availableTicketHandler = () => {
    setAvailableTicket(!availableTicket)
    form.setFieldsValue({
      showAvailableTickets: !availableTicket,
    })
  }
  const donationTicketHandler = () => {
    setDonationTicketBool(!donationTicketBool)
    form.setFieldsValue({
      donationEnabled: !donationTicketBool,
    })
  }
  return (
    <div className='detailsWrapper'>
      <Divider />
      <Item className='item' label={eventUrlLabel()}>
        <Space>
          {`${MAIN_APP_URL}${getEventUrl('')}`}
          <Item
            rules={[{ validator: urlValidator }]}
            name='eventUrl'
            noStyle
          >
            <Input placeholder='Add information' className={c('input', 'inputDisabled')} />
          </Item>
        </Space>
      </Item>
      <div className='special-divider' />
      <Item
        name='description'
        className='item'
        label='Notes'
        normalize={v => v.trimStart()}
      >
        <TextArea placeholder='Your notes' rows={4} />
      </Item>
      <Divider />
      <div className='radioItem'>
        <Item
          style={{ marginBottom: 0 }}
          name='showAvailableTickets'
          valuePropName='checked'
          className='item'
        >
          <Switch onChange={availableTicketHandler} defaultChecked={availableTicket} />
        </Item>
        <div className='text'>Show available tickets</div>
      </div>
      <Divider />
      <div className='radioItem'>
        <Item
          name='donationEnabled'
          valuePropName='checked'
          className='item noGutter'
        >
          <Switch onChange={donationTicketHandler} defaultChecked={donationTicketBool} />
        </Item>
        <div className='text'>Add Donation Ticket</div>
      </div>
      <Divider />
      <>
        <div className='radioItem'>
          <Item
            name='isShowTLHomePage'
            valuePropName='checked'
            className='item noGutter'
            initialValue={currentEventDetails?.isShowTLHomePage || switchValue}
          >
            <Switch disabled={isDisabled} onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Show tickets on TL Home Page</div>
        </div>
        <Divider />
      </>
    </div>
  )
}

export default OptionsEvent
