import React, { useEffect, useState } from 'react'
import { Form, Input, Button } from 'antd'
import c from 'classnames'
import CrossMark from 'images/date-cross.svg'
import PlacesAutocomplete from 'react-places-autocomplete'

import './index.styl'

const { Item } = Form

const MembershipLocationField = ({ form, currentPageDetails }) => {
  const [addressLocations, setAddressLocations] = useState(currentPageDetails?.locations || [''])

  useEffect(() => {
    if (currentPageDetails?.locations) {
      setAddressLocations(currentPageDetails?.locations)
      form.setFieldsValue({
        locations: currentPageDetails?.locations,
      })
    }
  }, [currentPageDetails])

  const handleSelect = async (value, index) => {
    const newAddressLocations = [...addressLocations]
    newAddressLocations[index] = value
    setAddressLocations(newAddressLocations)
    await form.setFieldsValue({
      locations: newAddressLocations,
    })
  }

  const handleAddLocation = () => {
    setAddressLocations([...addressLocations, ''])
  }

  const handleRemoveLocation = index => {
    const newAddressLocations = addressLocations.filter((_, i) => i !== index)
    setAddressLocations(newAddressLocations)
    form.setFieldsValue({
      locations: newAddressLocations,
    })
  }

  const renderInputLocation = index => (
    <PlacesAutocomplete
      value={addressLocations[index]}
      onChange={value => {
        const newAddressLocations = [...addressLocations]
        newAddressLocations[index] = value
        setAddressLocations(newAddressLocations)
      }}
      onSelect={value => handleSelect(value, index)}
      key={index}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className='locationContainerMembership'>
          <Item
            rules={[{ required: true, message: 'The Location is required' }]}
            className='detailsFieldsMembershipItem haveMargin'
            name={['locations', index]}
            label='Location'
            normalize={v => v.trimStart()}
          >
            <Input
              suffix={
                index > 0 &&
                  <img className='crossMark' src={CrossMark} alt='' onClick={() => handleRemoveLocation(index)} />
              }
              {...getInputProps({ className: 'input membership-location-search-input' })}
            />
          </Item>
          <div className={c('membership-autocomplete-dropdown-container', { isActive: !!suggestions.length })}>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, suggestionIndex) => {
              const className = suggestion.active ? 'membership-suggestion-item--active' : 'membership-suggestion-item'
              const style = { backgroundColor: suggestion.active ? '#fafafa' : '#ffffff', cursor: 'pointer' }
              return (
                <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestionIndex}>
                  {suggestion.description}
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )

  return (
    <div className='membershipLocationWrapper'>
      {addressLocations.map((_, index) => renderInputLocation(index))}
      <Button className='addLocation' onClick={handleAddLocation}>
        Add One More Location
      </Button>
    </div>
  )
}

export default MembershipLocationField
