import React, { useState } from 'react'
import { CustomButton } from 'components'
import { template } from '../../../apiv2'
import SectionScheme from './SectionScheme'
import { ModalTemplate } from '../../../components'
import './index.styl'
const TemplateScheme = ({ loading, className, setLoading, templateID, ...props }) => {
  const [visible, setVisible] = useState(false)
  const [type, setType] = useState('TABLES')

  const { sections, categories } = props
  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)
  const onFinish = form => {
    const { type, name, groupCount, seatCount, categoryID, rowArrangement, tableSeatSelection } = form
    onChangeLoading()
    const tableSeatSelectionOwner = tableSeatSelection ? 'BUYER' : 'PROMOTER'
    const obj = { type, name, groupCount, seatCount, categoryID, rowArrangement, tableSeatSelection: tableSeatSelectionOwner }
    template.addSection(templateID, obj).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }
  return (
    <div className={className || 'templateSchemeWrapper'}>
      {sections?.map(section => {
        return (
          <div key={section.id}>
            <SectionScheme loading={loading} setLoading={setLoading} section={section} {...props} />
          </div>
        )
      })}
      <CustomButton onClick={onChange} className='addNew' text='Add New Section' type='white' />
      <ModalTemplate additionalProps={{ type, setType, categoriesArray: categories }} visible={visible} onChange={onChange} onFinish={onFinish} name='addSection' width={472} title='Add New Section' loading={loading} />
    </div>
  )
}

export default TemplateScheme
