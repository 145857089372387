import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { CustomButton } from 'components'
import SearchIcon from 'images/input-search.svg'
import { Input } from 'antd'

import { MyTemplatesContext } from '../index'
import c from 'classnames'
import './index.styl'

const Header = () => {
  const { setSearchTag } = useContext(MyTemplatesContext)
  const history = useHistory()

  const searchIcon = <img alt='search icon' src={SearchIcon} />

  // useEffect(() => {
  //   const currentTab = query.get('activeTab')
  //   const orderBy = query.get('orderBy')
  //   window.dataLayer.push({ event: 'My Box Office' })
  //   if (!!currentTab && filterTag.key !== currentTab) {
  //     history.push(getMyBoxOfficeURL(currentTab, sortTag))
  //     setFilterTag(myBoxOfficeTabs.find(i => i.key === currentTab))
  //   }
  //   if (!!orderBy && orderBy !== sortTag) {
  //     history.push(getMyBoxOfficeURL(currentTab, sortTag))
  //     setSortTag(orderBy)
  //   }
  // }, [history.location.search])

  let requestDelay = 0
  const onChangeSearch = e => {
    const searchValue = e.target?.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchTag(searchValue)
    }, 700)
  }

  return (
    <div className='headerMyTemplates'>
      <div className='subHeaderTemplates'>
        <div className='title'>My Templates</div>

      </div>
      <div className={c('subHeaderTemplates', 'subHeader2Templates')}>
        <div className='leftHeaderTemplates'>
          <Input
            className='input noFocusBorder'
            onChange={onChangeSearch}
            placeholder='Search'
            prefix={searchIcon}
            allowClear
          />
          {/* <div className='wrapperFilters'>{myBoxOfficeTabs.map(item)}</div> */}
        </div>
        <CustomButton
          text='New Template'
          type='dark'
          className='btnHeader'
          onClick={() => history.push('/create-new-template')}
        />
      </div>
    </div>
  )
}

export default Header
