import { PAYMENT_LOGIN_GUEST, PAYMENT_TOKEN_VENMO } from './payment-constants'

export const sessionInitialState = {
  card: '',
  guest: {},
  tokenVenmo: '',
}

export const paymentReducer = (state = sessionInitialState, action) => {
  const { type, payload } = action

  switch (type) {
    case PAYMENT_LOGIN_GUEST:
      return { ...state, guest: payload }
    case PAYMENT_TOKEN_VENMO:
      return { ...state, tokenVenmo: payload }
    default:
      return state
  }
}
