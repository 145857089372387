import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { MockTicket } from 'components'
import closeIconSvg from 'images/Crossmark.svg'
import './index.styl'

const ModalMockTicket = ({
  onCloseModal,
}) => {
  const [visible, setVisible] = useState(null)

  const onCancel = () => {
    setVisible(false)
    setTimeout(onCloseModal, 100)
  }

  useEffect(() => {
    setVisible(true)
  }, [])

  const attr = { onCancel, width: 800, maxWidth: 800, footer: null, maskStyle: { backgroundColor: '#E8EEF7E6' } }
  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />

  return (
    <Modal {...attr} className='wrapperModalMockTicket' closeIcon={closeIcon} visible={visible}>
      <MockTicket tag='CreateTicket' onCancel={onCancel} />
    </Modal>
  )
}

export default ModalMockTicket
