import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { publicBuyV2, guestPublicBuyV2 } from 'modules/payment/payment-actions'
import {
  setInviteTicketGuest,
  inviteTicket,
  cashSaleTicketFromMyBoxOffice, getEventDetailsSummaryV2,
} from 'modules/events/events-actions'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { isAdmin, getFormattedNumber } from 'utils'
import stripeImg from 'images/CCicon.png'
import venmoImg from 'images/Venmo.png'
import { CustomButton } from 'components'
import { Checkbox, Form, notification, Radio, Input } from 'antd'
import moment from 'moment'
import VenmoPayment from '../VenmoPayment'
import './index.styl'

const { Item } = Form
const { TextArea } = Input

const objNumber = {
  invalid_number: 'The card number is not a valid credit card number',
  incorrect_number: 'The card number is incorrect',
  incomplete_number: 'The card number is incomplete',
}

const objCvc = {
  invalid_cvc: 'The card’s security code is invalid',
  incomplete_cvc: 'The card’s security code is incomplete',
  incorrect_cvc: 'The card’s security code is incorrect',
}

const objDate = {
  invalid_expiry_month: 'The card’s expiration month is invalid',
  invalid_expiry_month_past: 'The card’s expiration month is invalid',
  invalid_expiry_year: 'The card’s expiration year is invalid',
  incomplete_expiry: 'The card’s expiration date is incomplete',
  expired_card: 'The card has expired',
  invalid_expiry_year_past: 'The card’s expiration year is in the past',
}

const FormPayment = ({
  orderedTickets = [],
  orderedSeats = [],
  orderedBundles = [],
  totalPrice,
  donation = 0,
  eventID,
  isResell,
  preorderCode,
  totalPriceVenmo,
  callback = () => {},
  updateFunc,
  subscribe,
  currencySymbol,
  selectedOption,
  inviteType,
  eventDetails,
  giftActiveTable,
  giftActiveTab,
  giftValues,
  updateVisible,
  updateTicketsOnPage,
  setSelectedOptions,
  action,
  quantity,
  isMembership,
  classBtn,
}) => {
  const [loading, setLoading] = useState(false)
  const [errorInput, setErrorInput] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  })
  const {
    session: { user },
    payment,
  } = useSelector(state => state)
  const { inviterID } = useSelector(state => state.events.currentEvent)
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [agreement, setAgreement] = useState(false)
  const [agreementVenmo, setAgreementVenmo] = useState(false)
  const [notes, setNotes] = useState('')
  const res = {}
  orderedSeats.forEach(s => {
    res[s.seatCategoryID] = { ...res[s.seatCategoryID], seatCategoryID: s.seatCategoryID, price: s.price, seats: [...(res[s.seatCategoryID]?.seats || []), { seatID: s.seatId, guestReservationToken: s?.guestReservationToken }] }
  })
  // change ticketId i._id to i.id
  const ticketDetails = orderedTickets.map(i => {
    const isEarlyBirdActive = i.isEarlyBird && (moment() <= moment(i?.earlyBirdDate))
    return {
      ticketID: i.id,
      quantity: i.count,
      price: isEarlyBirdActive ? i.earlyBirdPrice : i.price,
    }
  })
  const bundleDetails = orderedBundles.map(i => {
    return {
      bundleID: i.id,
      quantity: i.count,
      price: i.price,
    }
  })
  const seatsDetails = Object.values(res).map(seat => {
    return {
      ticketID: seat.seatCategoryID,
      price: seat.price,
      quantity: seat.seats.length,
      seats: seat.seats.map(s => s),
    }
  })
  const onChangeError = e =>
    setErrorInput(errorInput => ({
      ...errorInput,
      [e.elementType]: e.error ? e.error.code : '',
    }))

  const stripePayment = (error, token) => {
    if (error) {
      notification.warning({ message: error.message })
      setLoading(false)
      return
    }
    setLoading(true)
    if (isResell) {
      const { quantity, inviteID } = orderedTickets[0]
      const resellObj = {
        quantity: quantity,
        inviteID: inviteID,
        cardToken: token.id,
        status: 'ACCEPTED',
        paymentType: 'Stripe',
      }
      user
        ? dispatch(inviteTicket(resellObj, callback)).then(() =>
          setLoading(false),
        )
        : dispatch(setInviteTicketGuest(resellObj, callback)).then(() =>
          setLoading(false),
        )
    } else {
      const obj = {
        ticketDetails: [...seatsDetails, ...ticketDetails],
        bundleDetails: [...bundleDetails],
        notes,
        inviterID,
        cardToken: token.id,
        eventID: eventID,
        paymentType: 'Stripe',
        agreement,
      }
      if (donation) {
        obj.donation = donation
      }
      if (user) {
        if (inviteType === 'Private Live Stream') {
          const obj = {
            cardToken: token.id,
            ticketID: eventID,
            quantity,
          }
          dispatch(action(obj, callback)).then(e => {
            setLoading(false)
            updateFunc(true)
          })
        } else if (inviteType === 'Gift') {
          const ticketOwnerId = eventDetails?.userID
          const ticketVenueUserID = eventDetails?.venueUserID
          let obj = {
            eventID: eventID,
            ticketID: giftValues.category,
            email: giftValues.email.trim(),
            quantity: giftValues.quantity,
            cardToken: token.id,
            inviteType: giftActiveTable === 'gift tickets' ? 'GIFT' : giftActiveTable === 'sub promote tickets' ? 'PROMOTE' : 'SELL',
            isSubPromoter: user._id !== ticketOwnerId && user._id !== ticketVenueUserID && !isAdmin(user?.role),
          }
          if (giftValues.price) {
            obj = { ...obj, price: giftValues.price }
          }
          if (giftValues.mobile) {
            obj.mobile = giftValues.mobile
          }
          if (giftValues.notes) {
            obj.notes = giftValues.notes
          }
          if (giftValues.uniqueName && giftValues.uniqueName.trim()) {
            obj = { ...obj, uniqueName: giftValues.uniqueName.trim() }
          }

          const action = giftActiveTable === 'gift tickets' ? dispatch(cashSaleTicketFromMyBoxOffice(obj)) : dispatch(cashSaleTicketFromMyBoxOffice(obj))
          action.then(response => {
            /* dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'GIFT'))
            dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'SELL')) */
            if (response) {
              dispatch(getEventDetailsSummaryV2(eventID, eventDetails?.isPromoted))
              updateTicketsOnPage(giftActiveTab)
              updateVisible()
              callback('tickets')
            }
            setLoading(false)
          })
        } else { dispatch(publicBuyV2(obj, callback)).then(e => setLoading(false)) }
      } else {
        const { guest } = payment
        const newObj = {
          ...obj,
          countryCode: '+1',
          mobile: guest.phone,
          email: guest.email.trim().toLowerCase(),
          name: guest.fullName,
          paymentType: 'Stripe',
        }
        dispatch(guestPublicBuyV2(newObj, callback)).then(() =>
          setLoading(false),
        )
      }
    }
  }

  const venmoPayment = (orderID, paymentID) => {
    if (loading) return
    setLoading(true)

    const errorLength = Object.values(errorInput).find(i => i.length)
    if (!stripe || !elements || errorLength) {
      setLoading(false)
      return
    }

    if (isResell) {
      const { quantity, inviteID } = orderedTickets[0]
      const resellObj = {
        quantity: quantity,
        inviteID: inviteID,
        preorderID: paymentID,
        cardToken: orderID,
        status: 'ACCEPTED',
        paymentType: 'Venmo',
        agreement: agreementVenmo,
      }
      user
        ? dispatch(inviteTicket(resellObj, callback)).then(() =>
          setLoading(false),
        ).catch(({ response: { data: { message } } }) => {
          notification.warning({ message })
          setLoading(true)
        })
        : dispatch(setInviteTicketGuest(resellObj, callback)).then(() =>
          setLoading(false),
        ).catch(({ response: { data: { message } } }) => {
          notification.warning({ message })
          setLoading(true)
        })
    } else {
      const obj = {
        ticketDetails: [...seatsDetails, ...ticketDetails],
        bundleDetails: [...bundleDetails],
        inviterID,
        notes,
        preorderID: paymentID,
        cardToken: orderID,
        // paymentSum: totalPrice,
        eventID: eventID,
        paymentType: 'Venmo',
        agreement: agreementVenmo,
      }
      if (donation) {
        obj.donation = donation
      }
      if (user) {
        dispatch(publicBuyV2(obj, callback)).then(e => setLoading(false)).catch(({ response: { data: { message } } }) => {
          notification.warning({ message })
          setLoading(true)
        })
      } else {
        const { guest } = payment
        const newObj = {
          ...obj,
          countryCode: '+1',
          mobile: guest.phone,
          email: guest.email.trim().toLowerCase(),
          name: guest.fullName,
          paymentType: 'Venmo',
        }
        dispatch(guestPublicBuyV2(newObj, callback)).then(() =>
          setLoading(false),
        ).catch(({ response: { data: { message } } }) => {
          notification.warning({ message })
          setLoading(true)
        })
      }
    }
  }

  const handleChangeNote = event => {
    setNotes(event.target.value)
  }
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
    setNotes('')
  }

  const onFinish = async values => {
    if (loading) return
    setLoading(true)
    const errorLength = Object.values(errorInput).find(i => i.length)
    if (!stripe || !elements || errorLength) {
      setLoading(false)
      return
    }
    const cardElement = elements.getElement(CardNumberElement)
    // if (subscribe) {
    //  await stripe.createToken(cardElement).then(({ token }) => {
    //      token?.id ? callback(token?.id) : setLoading(false)
    //  })
    //  return
    // }
    selectedOption === 'Stripe' ? await stripe.createToken(cardElement).then(({ error, token }) => {
      stripePayment(error, token)
    })
      : venmoPayment()
  }

  const totalPriceNumber = Number(totalPrice)
  const textBtn = `Pay ${currencySymbol}${
    totalPrice && !Number.isInteger(totalPriceNumber) ? getFormattedNumber(totalPrice) : Number.isInteger(totalPriceNumber) ? totalPrice : 0
  }`
  return (
    <>
      {currencySymbol === '$' && inviteType === 'SELL' || inviteType === undefined ? (
        <div className='wrapperFormPayment'>
          <div className='wrapRadioTitle'>
            <div className='title'>Payment Method</div>
            <Radio.Group onChange={onValueChange} value={selectedOption}>
              <Radio value='Stripe'>
                {' '}
                <img src={stripeImg} alt='stripe' className='img' />
                CC
              </Radio>
              <Radio value='Venmo'>
                {' '}
                <img src={venmoImg} alt='venmo' className='img' />
                Venmo
              </Radio>
            </Radio.Group>
          </div>
          <div className='title payoutDetails'>Payment Details</div>
          {selectedOption === 'Stripe' ? (
            <Form layout='vertical' className='form' onFinish={onFinish}>
              <div>
                <Item
                  name='numberCard'
                  label='Credit card number'
                  className='item itemStripe'
                >
                  <CardNumberElement
                    className='input'
                    showIcon
                    onChange={onChangeError}
                  />
                </Item>
                <div className='errorMessage'>
                  {errorInput.cardNumber && objNumber[errorInput.cardNumber]}
                </div>
              </div>
              <div className='row'>
                <div>
                  <Item
                    name='date'
                    label='Expiration date'
                    className='item itemStripe'
                  >
                    <CardExpiryElement className='input' onChange={onChangeError} />
                  </Item>
                  <div className='errorMessage'>
                    {errorInput.cardExpiry && objDate[errorInput.cardExpiry]}
                  </div>
                </div>
                <div>
                  <Item name='code' label='CVC Code' className='item itemStripe'>
                    <CardCvcElement className='input' onChange={onChangeError} />
                  </Item>
                  <div className='errorMessage'>
                    {errorInput.cardCvc && objCvc[errorInput.cardCvc]}
                  </div>
                </div>
              </div>
              {!subscribe && (
                <Item
                  name='notes'
                  validateTrigger='onSubmit'
                  className='item promoterNotes'
                  label='Note To Promoter'
                  normalize={v => v.trimStart()}
                >
                  <TextArea onChange={handleChangeNote} placeholder='Type here your note' autoSize maxLength={256} />
                </Item>
              )}
              <div className='checkbox'>
                <Item
                  name='agreement'
                  valuePropName='checked'
                  className='agreement'
                  initialValue={agreement}
                >
                  <Checkbox
                    checked={agreement}
                    onChange={e => setAgreement(e.target.checked)}
                  >
                    By providing a telephone number you are consenting to be contacted by SMS text message from Ticket Lyfe. Message and data rates may apply. Message frequency varies. Reply STOP to opt-out of future messaging. Reply HELP for more information. See our <a href={`${window.location.origin}/help/terms-and-conditions`}>Privacy Policy.</a>
                  </Checkbox>
                </Item>
              </div>
              <div className='wrapperActions'>
                <CustomButton
                  text={textBtn}
                  type='dark'
                  className={`${classBtn} 'btn'`}
                  htmlType='submit'
                  onClick={onFinish}
                  loading={loading}
                />
              </div>
            </Form>
          ) : (
            <div className='venmoContainer'>
              <VenmoPayment venmoPayment={venmoPayment} preorderCode={preorderCode} totalPrice={totalPriceVenmo} />
              {!subscribe && (
                <Item
                  name='notes'
                  validateTrigger='onSubmit'
                  className='item promoterNotes'
                  label='Note To Promoter'
                  normalize={v => v.trimStart()}
                >
                  <TextArea onChange={handleChangeNote} placeholder='Type here your note' autoSize maxLength={256} />
                </Item>
              )}
              <div className='checkbox'>
                <Checkbox
                  checked={agreementVenmo}
                  onChange={e => setAgreementVenmo(e.target.checked)}
                  className='agreement'
                >
                  By providing a telephone number you are consenting to be contacted by SMS text message from Ticket Lyfe. Message and data rates may apply. Message frequency varies. Reply STOP to opt-out of future messaging. Reply HELP for more information. See our <a href={`${window.location.origin}/help/terms-and-conditions`}>Privacy Policy.</a>
                </Checkbox>
              </div>
            </div>
          )}

        </div>
      ) : (
        <>
          <div className='wrapperFormPayment'>
            <Form layout='vertical' className='form' onFinish={onFinish}>
              <div>
                <Item name='numberCard' label='Credit card number' className='item itemStripe'>
                  <CardNumberElement className='input' showIcon onChange={onChangeError} />
                </Item>
                <div className='errorMessage'>{errorInput.cardNumber && objNumber[errorInput.cardNumber]}</div>
              </div>
              <div className='row'>
                <div>
                  <Item name='date' label='Expiration date' className='item itemStripe'>
                    <CardExpiryElement className='input' onChange={onChangeError} />
                  </Item>
                  <div className='errorMessage'>{errorInput.cardExpiry && objDate[errorInput.cardExpiry]}</div>
                </div>
                <div>
                  <Item name='code' label='CVC Code' className='item itemStripe'>
                    <CardCvcElement className='input' onChange={onChangeError} />
                  </Item>
                  <div className='errorMessage'>{errorInput.cardCvc && objCvc[errorInput.cardCvc]}</div>
                </div>
              </div>
              <div className='wrapperActions'>
                <CustomButton text={textBtn} type='dark' className='btn' htmlType='submit' loading={loading} />
              </div>
            </Form>
          </div>
        </>
      )}
    </>
  )
}

export default FormPayment
