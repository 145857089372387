import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { adminClearBankDetails } from 'modules/session/session-actions'
import { useHistory } from 'react-router-dom'
import { Layout, CustomButton, MemberLyfeLayout } from 'components'
import { MBEventItem, MBModalConfirmAction, MBControlPanel, MBModalBankDetails } from './MBComponents'
import { isAdmin, useQuery, getMyBackOfficeURL, notify, isVenue } from 'utils'
import { Pagination, Skeleton, Spin } from 'antd'
import { eventsV2 } from 'apiv2'
import { adminApi } from 'api'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const msgError = error => {
  notify(error?.response?.data?.error || error?.response?.data?.message || error?.message)
}
const getOffset = ({ current, limit }) => current * limit - limit
const arrayToString = arr => arr.filter(Boolean).join(',')
const getParams = ({ current, limit, search, sort, statuses = [], other = [] }) => {
  return { skip: getOffset({ current, limit }), statuses, other, search, limit, sort }
}
const limit = 10
const defaultStatuses = ['active', 'blocked', 'canceled']

const MyBackOffice = () => {
  const { user } = useSelector(state => state?.session)
  const dispatch = useDispatch()
  const query = useQuery()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const [current, setCurrent] = useState(0)
  const [total, setTotal] = useState(0)
  const [search, setSearch] = useState(query.get('search') || '')
  const [sort, setSort] = useState(query.get('sort') || 'DATE_DESC')
  const [statusesActive, setStatusesActive] = useState(query.get('filter') ? query.get('filter').split(',') : defaultStatuses)
  const [statusesPayment, setStatusesPayment] = useState(query.get('other') ? query.get('other').split(',') : [])
  const [modalAction, setModalAction] = useState(null)
  const [showBankDetails, setShowBankDetails] = useState(null)
  const [showBankDetailsModal, setShowBankDetailsModal] = useState(false)
  const { push } = useHistory()
  const containerRef = useRef(null)
  useEffect(() => {
    window.dataLayer.push({ event: 'My Back office' })
    if (!!user.role && !isAdmin(user.role) && !isVenue(user.role)) {
      push('/')
    }
  }, [user])
  const handleCancelModal = () => {
    setShowBankDetailsModal(false)
    setShowBankDetails(null)
    dispatch(adminClearBankDetails())
  }
  let requestDelay = 0
  const onChangeSearch = e => {
    const statuses = arrayToString(statusesActive)
    const other = arrayToString(statusesPayment)
    if (+query.get('page') !== 1) {
      const urlObj = { type: location.pathname.includes('member') ? 'member' : 'my', sort, current, statuses, search, other }
      history.push(getMyBackOfficeURL(urlObj))
    }
    const searchValue = e.target?.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearch(searchValue)
    }, 700)
  }
  const fetchData = () => {
    setLoading(true)
    const statuses = arrayToString(statusesActive)
    const other = arrayToString(statusesPayment)
    const params = { current, limit, search, sort, statuses, other }
    const urlObj = { type: location.pathname.includes('member') ? 'member' : 'my', sort, current, statuses, search, other }
    history.push(getMyBackOfficeURL(urlObj))
    eventsV2
      .getAllEvents(getParams(params))
      .then(({ data }) => {
        setEvents(data?.events)
        setTotal(data?.meta?.total[0]?.count)
        if (containerRef.current) {
          containerRef.current.scrollIntoView(true)
        }
      })
      .catch(msgError)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (query.get('page') && +query.get('page') !== current) {
      setCurrent(+query.get('page'))
    }
    if (!!query.get('sort') && query.get('sort') !== sort) {
      setSort(query.get('sort'))
    }
    if (!!query.get('search') && query.get('search') !== search) {
      setSearch(query.get('search'))
    }
    if (!!query.get('filter') && query.get('filter') !== arrayToString(statusesActive)) {
      setStatusesActive(query.get('filter').split(','))
    }
    if (!!query.get('other') && query.get('other') !== arrayToString(statusesPayment)) {
      setStatusesPayment(query.get('other').split(','))
    }
  }, [history.location.search])

  useEffect(() => {
    current && fetchData()
  }, [current])

  useEffect(() => {
    if (current !== 1) {
      setCurrent(!current ? +query.get('page') || 1 : 1)
    } else {
      fetchData()
    }
  }, [search, sort, statusesActive, statusesPayment])

  const handlePagination = current => setCurrent(current)

  const renderEmpty = (
    <div className='wrapperBodyEmpty'>
      <img src={NoEventsInformation} alt='Information Logo' />
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )

  const handleRefund = () => eventID => {
    setLoading(true)
    adminApi.refundPayment({ eventID })
      .then(() => {
        fetchData()
        setModalAction(null)
      })
      .catch(msgError)
      .finally(() => setLoading(false))
  }

  const functionAction = fnName => eventID => {
    setLoading(true)
    eventsV2[fnName](eventID)
      .then(() => {
        fetchData()
        setModalAction(null)
      })
      .catch(msgError)
      .finally(() => setLoading(false))
  }

  const actionProps = {
    activateEvent: {
      action: functionAction('activateEvent'),
      title: 'Are you sure you want to activate this event?',
    },
    refundAll: {
      title: 'Are you sure you want to refund all tickets for this event?',
      action: handleRefund(),
    },
    blockEvent: {
      action: functionAction('blockEvent'),
      title: 'Are you sure you want to block this event?',
    },
    cancelEvent: {
      action: functionAction('cancelEvent'),
      title: 'Are you sure you want to cancel this event?',
    },
    deleteEvent: {
      action: functionAction('deleteEvent'),
      title: 'Are you sure you want to delete this event?',
    },
    state: {
      mainLink: '/my-back-office',
      sort: query.get('sort'),
      page: query.get('page'),
      filter: query.get('filter'),
      search: query.get('search'),
      other: query.get('other'),
    },
  }

  const getCSV = async () => {
    const result = await eventsV2.getCsv()
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }
  const getStreamsCSV = async () => {
    const result = await eventsV2.getStreamsCsv()
    if (result?.status === 200) {
      window.open(result?.data?.urlToReport, '_blank')
    }
  }
  const LayoutComponent = location.pathname.includes('member') ? MemberLyfeLayout : Layout
  return (
    <LayoutComponent>
      <div className='container myBackOffice'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className='myBackOfficeTitle' ref={containerRef}>
          <div>
            My Back Office
          </div>
          <div>
            <CustomButton className='reportBtn' text='Streams Report' type='white' onClick={() => getStreamsCSV()} />
            <CustomButton text='Export' type='dark' onClick={() => getCSV()} />
          </div>
        </div>
        <MBControlPanel
          setStatusesPayment={setStatusesPayment}
          setStatusesActive={setStatusesActive}
          statusesPayment={statusesPayment}
          statusesActive={statusesActive}
          setSearch={setSearch}
          onChangeSearch={onChangeSearch}
          loading={loading}
          setSort={setSort}
          search={search}
          sort={sort}
        />
        <Spin wrapperClassName='eventsListWrapper' spinning={loading}>
          <Skeleton loading={loading && !events.length} active paragraph={{ rows: 10 }}>
            {events.length ? (
              <>
                {events.map(e => (
                  <MBEventItem user={user} setShowBankDetailsModal={setShowBankDetailsModal} setShowBankDetails={setShowBankDetails} setModalAction={setModalAction} actionProps={actionProps} key={e.id} event={e} />))}
                {total && total > 6 && (
                  <Pagination
                    onChange={handlePagination}
                    showSizeChanger={false}
                    className='pagination'
                    disabled={loading}
                    current={current}
                    pageSize={limit}
                    total={total}
                  />
                )}
              </>
            ) : renderEmpty}
          </Skeleton>
        </Spin>
      </div>
      {modalAction && (
        <MBModalConfirmAction
          onCancel={() => setModalAction(null)}
          modalData={modalAction}
          loading={loading}
        />
      )}
      {showBankDetails && showBankDetailsModal && (
        <MBModalBankDetails
          onCancel={handleCancelModal}
          showBankDetails={showBankDetails}
          loading={loading}
          fetchData={fetchData}
        />
      )}
    </LayoutComponent>
  )
}

export default MyBackOffice
