import React from 'react'
import { useSelector } from 'react-redux'
import { Header, FooterHome } from 'components'
import c from 'classnames'
import './index.styl'

const Layout = ({ children = '', className = '' }) => {
  const { isMobile } = useSelector(state => state.session)

  return (
    <div className={c('layoutWrapper', className)}>
      {isMobile && <div className='mobileMarginHeader' />}
      <Header />
      {children}
      <FooterHome />
    </div>
  )
}

export default Layout
