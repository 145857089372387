import React from 'react'
import { Link } from 'react-router-dom'
import ticketedAdmisionIcon from 'images/redTables.svg'
import ItemIcon from 'images/itemKnote.svg'
import RaffleIcon from 'images/fiveStar.svg'
import { add3Dots } from 'utils'

import './index.styl'

const SpecialScanningCard = ({ category, eventID }) => {
  const { isTicketedAdmission, isRaffleCategory, isItemCategory, categoryName, id } = category
  const categoryID = id || 0
  return (
    <div className='specialScanningCardWrapper'>
      <Link to={`/scanner/${eventID}?categoryID=${categoryID}`}>
        <div className='specialScanningCard'>
          {isTicketedAdmission &&
            <div className='ticketedAdmissionCardWrapper'>
              <img src={ticketedAdmisionIcon} alt='' className='ticketedAdmisionIcon' />
              <div><span className='ticketed'>TICKETED</span> <span className='admission'>ADMISSION</span></div>
            </div>}
          {isItemCategory &&
            <div className='itemCardWrapper'>
              <img src={ItemIcon} alt='' className='itemIcon' />
              <div className='item'>ITEM</div>
              <div className='ticket'>TICKET</div>
              <div className='categoryName'>{add3Dots(categoryName, 30)}</div>
            </div>}
          {isRaffleCategory &&
            <div className='raffleCardWrapper'>
              <img src={RaffleIcon} alt='' className='raffleIcon' />
              <div className='charity'>CHARITY</div>
              <div className='raffle'>RAFFLE</div>
              <img src={RaffleIcon} alt='' className='raffleIcon' />
              <div className='categoryName'>{add3Dots(categoryName, 30)}</div>
            </div>}
          <div className='specialCircleWrapper'>
            <div className='bottom' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
            <div className='circle' />
          </div>
          <div className='scanButton'>
            Scan
          </div>
        </div>
      </Link>
    </div>
  )
}

export default SpecialScanningCard
