import React from 'react'
import { Modal } from 'antd'
import { CustomButton } from 'components'
import './index.styl'

const ModalRaffleCategory = ({ visible, loading, onClose, onCancel, onSubmit, title, subtitle }) => {
  return (
    <Modal
      className='modal-raffle-category'
      title='Add Charity Raffle Tickets'
      visible={visible}
      closeIcon={false}
      width={472}
      footer={null}
      centered
      closable={!loading}
      destroyOnClose
      onCancel={onClose}
    >
      <div>
        <div className='title'>{title}</div>
        {subtitle && <div className='title'>{subtitle}</div>}
        <div className='raffleFooterBtn'>
          <CustomButton className='btnCancel' text='Cancel' type='white' onClick={onCancel} htmlType='button' />
          <CustomButton className='submitBtn' text='Add Charity Raffle Tickets' onClick={onSubmit} type='dark' />
        </div>
      </div>
    </Modal>
  )
}

export default ModalRaffleCategory
