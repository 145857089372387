import React, { useState } from 'react'
import AccordionPlus from 'images/accordion-plus.svg'
import AccordionMinus from 'images/accordion-minus.svg'
import './index.styl'

const AccordionFAQ = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='accordion'>
      <div className={`accordion-header ${isOpen ? 'open' : ''}`} onClick={toggleAccordion}>
        <div className='accordionWrapperContent'>
          <div className='accordionWrapperTitle'>
            <div className='accordion-header-title'>{title}</div>
            {isOpen ? <img src={AccordionMinus} alt='' /> : <img src={AccordionPlus} alt='' />}
          </div>
          {isOpen && <div className='accordion-content' dangerouslySetInnerHTML={{ __html: content }} />}
        </div>
      </div>
    </div>
  )
}

export default AccordionFAQ
