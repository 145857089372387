import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input } from 'antd'
import { CustomButton } from 'components'
import { contactUs } from 'modules/communication/communication-actions'
import { onValuesChange, SUPPORT_EMAIL } from 'utils'
import { pick } from 'lodash'
import './index.styl'

const { Item } = Form

const Body = ({ supportEmail = SUPPORT_EMAIL, textSubmit = 'Send' }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [contactForm] = Form.useForm()
  const onFinish = form => {
    setLoading(true)
    dispatch(contactUs(pick(form, ['email', 'name', 'message']))).then(() => {
      setLoading(false)
      contactForm.resetFields()
    })
  }

  return (
    <div className='bodyContactUs'>
      <Form
        className='form'
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        form={contactForm}
      >
        <Item
          name='email'
          label='Email'
          className='item'
          rules={[{ type: 'email', required: true, message: 'Enter an existing email' }]}
          validateTrigger='onBlur'
        >
          <Input className='input darkBg' />
        </Item>
        <Item
          name='name'
          label='User name'
          className='item'
        >
          <Input className='input darkBg' />
        </Item>
        <Item
          name='message'
          label='Message'
          className='item'
          rules={[{ required: true, message: 'Enter message' }]}
          validateTrigger='onBlur'
        >
          <Input.TextArea className='input textarea darkBg' />
        </Item>
        <div className='ant-form-item item'>
          <div>
            <label className='commentLabel'>Or you can email us:</label>
          </div>
          <div>
            <a href={`mailto:${supportEmail}`} className='supportEmail'>
              {supportEmail}
            </a>
          </div>
          <div>
            <label className='commentLabel'>Or you can call us:</label>
          </div>
          <div>
            <div className='supportEmail'>
              516-962-0662
            </div>
          </div>
        </div>
        <div className='wrapperActions'>
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </div>
  )
}

export default Body
