import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, InputNumber, DatePicker, Checkbox, Radio, Divider, Button } from 'antd'
import moment from 'moment'
import { minimumPriceValidator, minimumPriceValidatorInviteOnly } from 'modules/utils/validators'
import { CreateEventContext } from '../index'
import { getCurrencySymbolByCode, colors, handleConfirmModal, streamTypes, categoryTypes } from 'utils'
import TooltipInfo from '../TooltipInfo'
import calendarIcon from 'images/calendarIcon.svg'
import assignedSeatingIcon from 'images/assignedSeating.svg'
import tablesIcon from 'images/tablesIcon.svg'
import commonIcon from 'images/commonCategoryType.svg'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import './index.styl'
// import { useSelector } from 'react-redux'
// import isInteger from 'lodash/isInteger'

const { Item } = Form

export const privacyOptionsLabel = () => (
  <TooltipInfo
    className='privacyLabel'
    text='Privacy options'
    info='The privacy options determine who can see your event. If it is a Public event it is available on the main page for anyone to purchase. If it is Invite Only, the only way to get tickets is by Gifting, Direct Sale or sharing the event link.'
  />
)
// const isStream = (namePrefix, eventType) => namePrefix === 'streamingCategory' || eventType === 'stream' && namePrefix === 'defaultCategory'

const TicketCategory = ({ onRemove, categoryData = {}, setUpdatePrivacyType, isDraft, eventID, namePrefix, selectedOption }) => {
  const { form, currency, currentEventDetails } = useContext(CreateEventContext)
  const [isEarlyBird, setIsEarlyBird] = useState(categoryData?.isEarlyBird || false)
  const [isSubpromoters, setIsSubpromoters] = useState(!!categoryData?.subpromoters?.length || false)
  // const [quantity, setQuantity] = useState(categoryData?.quantity || '')
  const [quantityForButtons, setQuantityForButtons] = useState(!isDraft ? categoryData?.quantity || 0 : 0)
  const [streamType, setStreamType] = useState(categoryData?.streamType || streamTypes.PPV_STREAM)
  const [privacyOption, setPrivacyOption] = useState('visible')
  const [categoryType, setCategoryType] = useState(categoryData?.categoryType || categoryData.isSeatingCategory ? categoryTypes.CATEGORY_TYPE_DESIGNATED : categoryTypes.CATEGORY_TYPE_COMMON)
  // const [quantityAvailable, setQuantityAvailable] = useState(categoryData?.ticketCount?.available || 0)
  // const { user } = useSelector(state => state.session)
  // const valid = namePrefix === 'streamingCategory' && ticketLifeFeeValue === 'Private Live Stream'
  const onRemoveCategory = index => {
    !categoryData.isStreaming &&
    !categoryData.isDefault &&
    handleConfirmModal('Are you sure you want to remove this category', () => onRemove(index))
  }
  // const valuesQuantity = form.getFieldValue(namePrefix)
  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)
  // const getItemPrefixedName = name => listItem ? ['categories', namePrefix, name] : [namePrefix, name]
  const isDateDisabled = currentDate => form.getFieldValue('startDate')
    ? moment().add(-1, 'days') >= currentDate || moment(form.getFieldValue('startDate')) <= currentDate
    : currentDate // TODO: rename it
  // const onChangeCurrency = ({ target: { value } }) => {
  //   setCurrency(value)
  //   form.setFieldsValue({ currency: value })
  // }
  const privacyOptionsProps = () => categoryData?.isInviteOnly ? { initialValue: 'invite' } : { initialValue: 'visible' }
  const validQuantity = v => ({
    validator () {
      if (!!v) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Enter quantity!'))
    },
  })
  // const integerValidator = message => {
  //  return {
  //    validator (_, value) {
  //      if (value && (!isInteger(value))) {
  //        return Promise.reject(new Error(message))
  //      }
  //      return Promise.resolve()
  //    },
  //  }
  // }

  // TODO: refactor it.. doesn't work
  // const quantityValidator = message => {
  //  return {
  //    validator (_, value) {
  //      if (+value <= 0) {
  //        return Promise.reject(new Error('Invalid number'))
  //      }
  //      if (quantityAvailable < 0) {
  //        return Promise.reject(new Error(message))
  //      }
  //      return Promise.resolve()
  //    },
  //  }
  // }
  const handleStreamTypeChange = type => {
    setStreamType(type)
    const fields = form.getFieldValue('categories')
    if (type === streamTypes.PRIVATE_STREAM) {
      fields[namePrefix].quantity = 0
      fields[namePrefix].name = streamTypes.PRIVATE_STREAM_CATEGORY_NAME
      fields[namePrefix].streamType = streamTypes.PRIVATE_STREAM
    } else {
      fields[namePrefix].quantity = 10000
      fields[namePrefix].name = streamTypes.PPV_STREAM_CATEGORY_NAME
      fields[namePrefix].streamType = streamTypes.PPV_STREAM
    }
    form.setFieldsValue({ categories: fields })
  }
  const handleCategoryTypeChange = type => {
    const fields = form.getFieldValue('categories')
    if (type === categoryTypes.CATEGORY_TYPE_DESIGNATED) {
      categoryData.type = categoryTypes.CATEGORY_TYPE_DESIGNATED
      setIsSubpromoters(false)
      fields[namePrefix].quantity = 0
    }
    if (type === categoryTypes.CATEGORY_TYPE_COMMON) {
      categoryData.type = categoryTypes.CATEGORY_TYPE_COMMON
    }
    setCategoryType(type)
    form.setFieldsValue({ categories: fields })
  }
  const handlePrivacyOption = type => {
    if (type === 'visible') {
      setUpdatePrivacyType('visible')
      categoryData.privacyOption = 'visible'
    }
    if (type === 'invite') {
      setUpdatePrivacyType('invite')
      categoryData.privacyOption = 'invite'
    }
    setPrivacyOption(type)
  }

  useEffect(() => {
    if (categoryData.isStreaming && streamType === streamTypes.PRIVATE_STREAM) {
      const fields = form.getFieldValue('categories')
      fields[namePrefix].quantity = quantityForButtons
      form.setFieldsValue({ categories: fields })
    }
  }, [quantityForButtons])

  useEffect(() => {
    if (!isSubpromoters) {
      const fields = form.getFieldValue('categories')
      if (fields[namePrefix].subpromoters) {
        fields[namePrefix].subpromoters = fields[namePrefix].subpromoters.filter(i => !i.isNew)
      }
      form.setFieldsValue({ categories: fields })
    }
  }, [isSubpromoters])

  // const addSubpromotion = () => {
  //  const fields = form.getFieldValue('categories')
  //  // if it's first subpromoter
  //  if (!fields[namePrefix].subpromoters) {
  //    fields[namePrefix].subpromoters = []
  //  }
  //  fields[namePrefix].subpromoters.push({
  //    isNew: true,
  //    subPromotersEmail: '',
  //    vanityURL: '',
  //    subPromotersQuantity: '',
  //  })
  //  form.setFieldsValue({ categories: fields })
  // }

  // const handleIsSubpromoters = () => {
  //  if (!isSubpromoters) {
  //    addSubpromotion()
  //  }
  //  setIsSubpromoters(!isSubpromoters)
  // }

  // const recalculateQuantityAvailable = () => {
  //  const fields = form.getFieldValue(['categories', namePrefix])
  //  const sumSubpromoted = fields.subpromoters
  //    ? fields.subpromoters.reduce((acc, obj) => acc + obj.subPromotersQuantity, 0)
  //    : 0
  //  const q = fields.quantity || 0
  //  setQuantityAvailable(q - sumSubpromoted)
  // }

  // const onRemoveSubpromotion = index => {
  //  const fields = form.getFieldValue('categories')
  //  if (fields[namePrefix].subpromoters.length > index && fields[namePrefix].subpromoters[index].isNew) {
  //    fields[namePrefix].subpromoters.splice(index, 1)
  //  }
  //  form.setFieldsValue({ categories: fields })
  // }
  // const test = form.getFieldValue(['categories', namePrefix])
  // console.log('category log', namePrefix, 'avaliable', quantityAvailable, test)
  return (
    <div className='categoryWrapper'>
      <Item
        name={[namePrefix, 'id']}
        style={{ display: 'none' }}
      />
      {
        categoryData.isStreaming &&
          <Item
            className='choose'
            // parental component (List) name is "categories", so here name is following:
            name={[namePrefix, 'streamType']}
            initialValue={streamType}
            label='Choose Live Stream Type'
            rules={[{ required: true, message: 'Choose stream type' }]}
          >
            <Radio.Group
              disabled={categoryData.id}
              onChange={e => handleStreamTypeChange(e.target.value)}
              className={`streamingCategory ${streamType === streamTypes.PRIVATE_STREAM && 'striming'}`}
            >
              <Radio checked={streamType} style={{ marginRight: '20px' }} value={streamTypes.PRIVATE_STREAM}>
                <div> {streamTypes.PRIVATE_STREAM_CATEGORY_NAME}</div>
                <div className='textStream'>You can distribute <br /> these tickets for free</div>
              </Radio>
              <Radio checked={streamType} value={streamTypes.PPV_STREAM}>
                <div>  {streamTypes.PPV_STREAM_CATEGORY_NAME}</div>
                <div className='textStream'>You can sell these <br /> tickets out</div>
              </Radio>
            </Radio.Group>
          </Item>
      }

      <Item
        name={[namePrefix, 'bgColor']}
        label={`Choose background color for ${categoryData.name ? categoryData.name : 'new category'} `}
        className='item-colorPicker'
        rules={[{ required: true, message: 'Choose background color' }]}
      >
        <Radio.Group className='radio-colors'>
          {colors.map(color => <Radio key={color} value={color} style={{ backgroundColor: color }} />)}
        </Radio.Group>
      </Item>
      <Divider />
      {!categoryData.isStreaming && selectedOption !== 'default'
        ? (
          <Item
            rules={[{ required: true }]}
            name={[namePrefix, 'type']}
            initialValue={categoryType}
            label='Category type'
          >
            <Radio.Group defaultValue={categoryType} value={categoryType} disabled={categoryData.isSeatingCategory && categoryData.templateCategoryID || selectedOption === 'manual' && categoryData.isSeatingCategory || categoryData.eventID && categoryData.isSeatingCategory} onChange={e => handleCategoryTypeChange(e.target.value)} className='radioContainer' optionType='button'>
              <div className='radioTypeStyle'>
                <Radio defaultChecked checked={categoryType} value={categoryTypes.CATEGORY_TYPE_COMMON}>
                  <div className='wrapperCategoryType'>
                    <div>
                      <div className='categoryTypeName'> {categoryTypes.CATEGORY_TYPE_COMMON_NAME}</div>
                      <div className='categoryTypeSubtitle'>Tickets without a fixed seat</div>
                    </div>
                    <div>
                      <img className='commonIcon' src={commonIcon} alt='commonIcon' />
                    </div>
                  </div>
                </Radio>
              </div>
              <div className='radioTypeStyle'>
                <Radio checked={categoryType} value={categoryTypes.CATEGORY_TYPE_DESIGNATED}>
                  <div className='wrapperCategoryType'>
                    <div>
                      <div className='categoryTypeName'> {categoryTypes.CATEGORY_TYPE_DESIGNATED_NAME}</div>
                      <div className='categoryTypeSubtitle'>Allow guests to choose a seat in rows or tables</div>
                    </div>
                    <div>
                      <img className='seatingIcon' src={assignedSeatingIcon} alt='assignedSeatingIcon' />
                      <img className='tablesIcon' src={tablesIcon} alt='tablesIcon' />
                    </div>
                  </div>
                </Radio>
              </div>
            </Radio.Group>
          </Item>
        )
        : eventID && selectedOption === 'default' && !categoryData.isStreaming && !currentEventDetails?.isVenueDraft ? (
          <Item
            rules={[{ required: true }]}
            name={[namePrefix, 'type']}
            initialValue={categoryType}
            label='Category type'
          >
            <Radio.Group defaultValue={categoryType} value={categoryType} disabled={categoryData.isSeatingCategory && categoryData.templateCategoryID || selectedOption === 'manual' && categoryData.isSeatingCategory || categoryData.eventID && categoryData.isSeatingCategory} onChange={e => handleCategoryTypeChange(e.target.value)} className='radioContainer' optionType='button'>
              <div className='radioTypeStyle'>
                <Radio defaultChecked checked={categoryType} value={categoryTypes.CATEGORY_TYPE_COMMON}>
                  <div className='wrapperCategoryType'>
                    <div>
                      <div className='categoryTypeName'> {categoryTypes.CATEGORY_TYPE_COMMON_NAME}</div>
                      <div className='categoryTypeSubtitle'>Tickets without a fixed seat</div>
                    </div>
                    <div>
                      <img className='commonIcon' src={commonIcon} alt='commonIcon' />
                    </div>
                  </div>
                </Radio>
              </div>
              <div className='radioTypeStyle'>
                <Radio checked={categoryType} value={categoryTypes.CATEGORY_TYPE_DESIGNATED}>
                  <div className='wrapperCategoryType'>
                    <div>
                      <div className='categoryTypeName'> {categoryTypes.CATEGORY_TYPE_DESIGNATED_NAME}</div>
                      <div className='categoryTypeSubtitle'>Allow guests to choose a seat in rows or tables</div>
                    </div>
                    <div>
                      <img className='seatingIcon' src={assignedSeatingIcon} alt='assignedSeatingIcon' />
                      <img className='tablesIcon' src={tablesIcon} alt='tablesIcon' />
                    </div>
                  </div>
                </Radio>
              </div>
            </Radio.Group>
          </Item>
        ) : ''}
      <Item
        rules={[{ required: true, message: 'Enter Category name' }]}
        name={[namePrefix, 'name']}
        label='Category name'
      >
        <Input placeholder='Enter Category name' className='input' />
      </Item>

      {streamType !== streamTypes.PRIVATE_STREAM &&
        <>{privacyOptionsLabel()}
          <Item
            rules={[{ required: true, message: 'Choose Privacy Option' }]}
            name={[namePrefix, 'privacyOption']}
            className='no_margin item_radio-group'
            {...privacyOptionsProps()}
          >
            <Radio.Group onChange={e => handlePrivacyOption(e.target.value)}>
              <Radio value='visible'>Visible to Public</Radio>
              <Radio value='invite'>Invite Only</Radio>
            </Radio.Group>
          </Item>
        </>}
      {/* {categoryData.isDefault && streamType !== streamTypes.PRIVATE_STREAM && (
        <>
          <Divider />
          <Item
            className='item currencySelector'
            label='Currency Selector'
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={eventID && !currentEventDetails.isVenueDraft} onChange={onChangeCurrency} value={currency}>
              {Object.keys(currencyCodes).map(k => (
                <Radio key={k} value={currencyCodes[k]?.code}>
                  {`${currencyCodes[k]?.label} (${currencyCodes[k].symbol})`}
                </Radio>
              ))}
            </Radio.Group>
          </Item>
        </>
      )} */}
      <Divider />
      <div className='item-group group-2'>
        {streamType !== streamTypes.PRIVATE_STREAM &&
          <Item
            name={[namePrefix, 'price']}
            label={`Price (${getCurrencySymbolByCode(currency)})`}
            rules={[
              { required: true, message: 'Enter price' },
              privacyOption === 'visible' ? minimumPriceValidator : minimumPriceValidatorInviteOnly,
            ]}
          >
            <InputNumber
              placeholder='0'
              className='input fullWidth'
              min={0}
            />
          </Item>}
        {streamType === streamTypes.PRIVATE_STREAM &&
          (
            <div className='castomQuantity'>
              <Button
                disabled={categoryData.id}
                onClick={() => setQuantityForButtons(quantityForButtons === 0 ? 0 : quantityForButtons - 25)}
                className='minus'
                type='button'
              >
                <MinusOutlined />
              </Button>
              <Item
                style={{ position: 'relative' }}
                rules={[validQuantity]}
                name={[namePrefix, 'quantity']}
                // initialValue={categoryData.quantity}
                className='inputWidth'
                label='Quantity'
              >
                <InputNumber
                  className='input fullWidth'
                  placeholder='0'
                  min={0}
                  disabled
                />
              </Item>
              <Button
                // disabled={ticketData?.isPrivateStream}
                disabled={categoryData.id}
                onClick={() => setQuantityForButtons(quantityForButtons + 25)}
                className='plus'
                type='button'
              >
                <PlusOutlined />
              </Button>
            </div>
          )}
        {streamType !== streamTypes.PRIVATE_STREAM && (
          <Item
            style={{ position: 'relative' }}
            rules={categoryData.type === categoryTypes.CATEGORY_TYPE_DESIGNATED ? [{ required: false }] : [{ required: true, message: 'Enter quantity' }]}
            name={[namePrefix, 'quantity']}
            initialValue={(categoryData.isStreaming) ? 10000 : ''}
            label='Quantity'
          >
            <InputNumber
              className='input fullWidth'
              disabled={categoryData.isStreaming || categoryData.isSeatingCategory || categoryData.type === categoryTypes.CATEGORY_TYPE_DESIGNATED}
              placeholder='0'
              min={0}
              // onChange={() => recalculateQuantityAvailable()}
            />
          </Item>
        )}
      </div>
      {streamType !== streamTypes.PRIVATE_STREAM &&
        <>
          <Divider />
          <Item
            name={[namePrefix, 'isEarlyBird']}
            className='item-checkbox'
            valuePropName='checked'
          >
            <Checkbox checked={isEarlyBird} onChange={onCheckEarlyBird}>Early Bird Special</Checkbox>
          </Item>
          {isEarlyBird && (
            <div className='item-group group-2'>
              <Item
                rules={[{ required: true, message: 'Enter Early Bird date' }]}
                name={[namePrefix, 'earlyBirdDate']}
                suffixIcon={<img src={calendarIcon} alt=' ' />}
                className='inputDateMargin'
                label='Select date'
              >
                <DatePicker disabledDate={isDateDisabled} suffixIcon={<img src={calendarIcon} alt=' ' />} />
              </Item>
              <Item
                name={[namePrefix, 'earlyBirdPrice']}
                dependencies={[[namePrefix, 'price']]}
                label={`Price (${getCurrencySymbolByCode(currency)})`}
                rules={[
                  { required: true, message: 'Enter price' },
                  minimumPriceValidator,
                ]}
              >
                <InputNumber placeholder='0' className='input fullWidth' min={0} />
              </Item>
            </div>
          )}
          {/* {!categoryData.isSeatingCategory && categoryType === 'COMMON' &&
            <Item
              className='item-checkbox'
              valuePropName='checked'
            >
              <Checkbox checked={isSubpromoters} onChange={handleIsSubpromoters}>Subpromoters</Checkbox>
            </Item>} */}
        </>}
      {/* {isSubpromoters &&
        <div className='detailsWrapper'>
          {categoryData?.subpromoters?.map((i, index) => {
            return (
              <Space
                style={{ display: 'flex', flexDirection: 'column' }}
                key={index}
              >
                <Item
                  style={{ width: '100%' }}
                  validateTrigger='onBlur'
                  name={[namePrefix, 'subpromoters', index, 'subPromotersEmail']}
                  rules={[
                    { required: true, message: 'Please enter email' },
                    { type: 'email', message: 'Please enter correct email', transform: value => value?.trim() },
                    notEqual(user.email, "You can't send invites to yourself"),
                  ]}
                  label='Email'
                >
                  <Input disabled={!i.isNew} placeholder='Enter email' className='input ' />
                </Item>
                <Item
                  style={{ width: '100%' }}
                  validateTrigger='onBlur'
                  normalize={value => value ? value.trim() : ''}
                  // initialValue={i.vanityURL}
                  name={[namePrefix, 'subpromoters', index, 'vanityURL']}
                  dependencies={['email']}
                  label={'Subpromoter\'s vanity URL'}
                >
                  <Input disabled={!i.isNew} placeholder={'Subpromoter\'s vanity URL'} className='input ' />
                </Item>
                <Item
                  validateTrigger='onBlur'
                  rules={[
                    { required: true, type: 'number', message: 'Please enter quantity' },
                    integerValidator('Invalid number!'),
                    quantityValidator('Subpromoters quantity exceeds category quantity'),
                  ]}
                  // initialValue={i.subPromotersQuantity}
                  name={[namePrefix, 'subpromoters', index, 'subPromotersQuantity']}
                  label='Quantity'
                >
                  <InputNumber
                    disabled={!i.isNew}
                    placeholder='0'
                    onChange={() => recalculateQuantityAvailable()}
                  />
                </Item>
                {i.isNew && index > 0 &&
                  <div
                    style={{ justifyContent: 'flex-start' }}
                    onClick={() => onRemoveSubpromotion(index)}
                    className='removeBtn'
                  >Remove Subpromoter
                  </div>}
                <Divider />
              </Space>
            )
          },
          )}
          <div
            id='addCategory'
            className='btn'
            style={{ marginBottom: '15px', marginTop: '16px' }}
            onClick={() => {
              addSubpromotion()
            }}
          >Add New Subpromoter
          </div>
        </div>} */}
      {onRemove && !categoryData.isDefault && !categoryData.isStreaming && !categoryData.isSeatingCategory && (
        <>
          {!categoryData.isSeatingCategory && categoryType === 'COMMON' && <Divider />}
          <div className='btnWrapper'>
            <div onClick={() => onRemoveCategory(namePrefix)} className='removeBtn'>Remove Ticket Category</div>
          </div>
        </>
      )}
    </div>
  )
}

export default TicketCategory
