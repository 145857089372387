import React from 'react'
import { CustomButton, Layout } from 'components'
import alert from 'images/Alert.svg'
import shape from 'images/404-image.svg'
import shapeMobile from 'images/404-image-mobile.svg'
import { history } from 'modules/router'
import './index.styl'

const ContactPromoter = () => {
  const handleBack = () => history.push('/home')
  return (
    // <Layout>
    //  <div className='contactPromoterWrapper'>
    //    <div>Contact Promoter</div>
    //    <CustomButton text='Back' type='dark' onClick={handleBack} />
    //  </div>
    // </Layout>
    <Layout className='NotFound'>
      <div className='notFoundContainer'>
        <img src={alert} alt=' ' className='alertIcon' />
        <img src={shape} alt=' ' className='backgroundShape' />
        <img src={shapeMobile} alt=' ' className='mobileShape' />
        <div className='infoContainer'>
          <div className='notFoundTitle'>Contact Promoter</div>
          <CustomButton text='Back' type='dark' onClick={handleBack} />
        </div>
      </div>
    </Layout>
  )
}

export default ContactPromoter
