import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Input } from 'antd'
import { getBalanceLedgers } from 'modules/events/events-actions'
import SearchIcon from 'images/input-search.svg'
import moment from 'moment'
import './index.styl'

const PaymentBalances = ({ eventID, currencySymbol, isResponsive }) => {
  const { balanceList } = useSelector(state => state.events)
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const containerRef = useRef()

  const getBalanceList = () => {
    if (eventID) {
      setLoading(true)
      dispatch(getBalanceLedgers(eventID, searchValue))
        .then(() => {
          isResponsive && containerRef.current.scrollIntoView()
          setLoading(false)
        })
    }
  }

  useEffect(() => {
    setLoading(true)
    // console.log('balanceList', balanceList)
    const data = balanceList?.data?.map(i => ({
      date: i.date ? `${moment(i?.date).format('DD MMM YYYY HH:mm')}` : '',
      ledgerType: i?.ledgerType,
      purchaseType: i?.purchaseType || i?.payoutType || '',
      paymentType: i?.paymentType || '',
      trxId: i?.trxId,
      user: i?.user?.email || i?.buyer?.email || '',
      subpromoter: i?.subpromoter?.email || '',
      categories: i?.categories ? i?.categories.reduce((acc, val) => `${acc} ${val.categoryName}|${val.quantity}|${val.price} `, '') : '',
      totalAmount: `${currencySymbol}${i.totalAmount}`,
      saleAmount: i.saleAmount ? `${currencySymbol}${i.saleAmount}` : '',
      donationAmount: i.donationAmount ? `${currencySymbol}${i.donationAmount}` : '',
      onDemandAmount: i.onDemandAmount ? '+' : '',
      tlFeeAmount: i.tlFeeAmount ? `${currencySymbol}${i.tlFeeAmount}` : '',
      streamFeeAmount: i.streamFeeAmount ? `${currencySymbol}${i.streamFeeAmount}` : '',
      saleTax: i.saleTax ? `${currencySymbol}${i.saleTax}` : '',
      stripeFeeAmount: i.stripeFeeAmount ? `${currencySymbol}${i.stripeFeeAmount}` : '',
      partnerPayback: i.partnerPayback ? `${currencySymbol}${i.partnerPayback}` : '',
      venueFeeAmount: i.venueFeeAmount ? `${currencySymbol}${i.venueFeeAmount}` : '',
      coownerAmount: i.coownerAmount ? `${currencySymbol}${i.coownerAmount}` : '',
      promoterAmount: i.promoterAmount ? `${currencySymbol}${i.promoterAmount}` : '',
      promoterBalance: i.promoterBalance ? `${currencySymbol}${i.promoterBalance}` : '',
      isRefunded: i?.isRefunded ? '+' : '',
      refundedAmount: i.refundedAmount ? `${currencySymbol}${i.refundedAmount}` : '',
      isStage1PromoterProcessed: i?.isStage1PromoterProcessed ? '+' : '',
      isStage2PromoterProcessed: i?.isStage2PromoterProcessed ? '+' : '',
    }))
    setTableData(data)
    setLoading(false)
  }, [balanceList])

  useEffect(() => {
    getBalanceList()
  }, [searchValue])

  useEffect(() => {
    setSearchValue('')
  }, [])

  let requestDelay = 0
  const onChangeSearch = e => {
    const search = e.target.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchValue(search)
    }, 700)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      width: 120,
    },
    {
      title: 'Operation Type',
      dataIndex: 'ledgerType',
      key: 'ledgerType',
    },
    {
      title: 'Purpose',
      dataIndex: 'purchaseType',
      key: 'purchaseType',
    },
    {
      title: 'Proc',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Payer/Receiver',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Categories',
      dataIndex: 'categories',
      key: 'categories',
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
    },
    {
      title: 'Sale',
      dataIndex: 'saleAmount',
      key: 'saleAmount',
    },
    {
      title: 'Donation',
      dataIndex: 'donationAmount',
      key: 'donationAmount',
    },
    {
      title: 'On Demand',
      dataIndex: 'onDemandAmount',
      key: 'onDemandAmount',
    },
    {
      title: 'TL Fee',
      dataIndex: 'tlFeeAmount',
      key: 'tlFeeAmount',
    },
    {
      title: 'Stream Fee',
      dataIndex: 'streamFeeAmount',
      key: 'streamFeeAmount',
    },
    {
      title: 'Tax',
      dataIndex: 'saleTax',
      key: 'saleTax',
    },
    {
      title: 'Proc Fee',
      dataIndex: 'stripeFeeAmount',
      key: 'stripeFeeAmount',
    },
    {
      title: 'Partner Payback',
      dataIndex: 'partnerPayback',
      key: 'partnerPayback',
    },
    {
      title: 'Venue Fee',
      dataIndex: 'venueFeeAmount',
      key: 'venueFeeAmount',
    },
    {
      title: 'Promoter Take',
      dataIndex: 'coownerAmount',
      key: 'coownerAmount',
    },
    {
      title: 'Total to payout',
      dataIndex: 'promoterAmount',
      key: 'promoterAmount',
      width: 100,
    },
    {
      title: 'Left to pay',
      dataIndex: 'promoterBalance',
      key: 'promoterBalance',
    },
    {
      title: 'Refunded',
      dataIndex: 'isRefunded',
      key: 'isRefunded',
    },
    {
      title: 'Stage 1',
      dataIndex: 'isStage1PromoterProcessed',
      key: 'isStage1PromoterProcessed',
      width: 70,
    },
    {
      title: 'Stage 2',
      dataIndex: 'isStage2PromoterProcessed',
      key: 'isStage2PromoterProcessed',
      width: 70,
    },
    {
      title: 'trxId',
      dataIndex: 'trxId',
      key: 'trxId',
    },
  ]

  const searchPanel = (
    <div className='searchPanel'>
      <Input
        prefix={<img alt='search icon' src={SearchIcon} />}
        className='inputSearch darkBg noFocusBorder'
        placeholder='Search'
        onChange={e => onChangeSearch(e)}
        allowClear
      />
    </div>)

  return (
    <>
      <div className='wrapperTablesAssignSeating BalanceDetails'>
        <div className='titleAssignedSeating' ref={containerRef}>Balances Ledgers</div>
        <Table
          columns={columns}
          pagination={false}
          title={() => searchPanel}
          loading={loading}
          dataSource={tableData}
          className='eventDetailsTable financialTable balanceTable'
          rowKey='transID'
        />
      </div>
    </>
  )
}
export default PaymentBalances
