import React from 'react'
import { Button } from 'antd'
import './index.styl'

const types = {
  white: 'btnTypeWhite',
  dark: 'btnTypeDark',
  darkInverse: 'btnTypeDarkInverse',
  outline: 'btnTypeOutline',
}

const CustomButton = ({
  text = '',
  type = 'white',
  loading = false,
  onClick = () => {},
  className = '',
  htmlType,
  disabled = false,
}) => {
  return (
    <Button
      className={types[type] + ' ' + className}
      loading={loading}
      onClick={onClick}
      htmlType={htmlType}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

export default CustomButton
