import React, { useState } from 'react'
import { Form, Input, InputNumber, Radio } from 'antd'
import tablesIcon from 'images/tablesIcon.svg'
import seatsIcon from 'images/seatsIcon.svg'
import TooltipInfo from '../CreateEvent/TooltipInfo'
import TooltipSeating from '../CreateEvent/TooltipSeating'
import './index.styl'

const { Item } = Form

export const rowArrangementLabel = () => (
  <TooltipInfo
    placement='topLeft'
    className='arrangmentLabel'
    text='Row Arrangement'
    info={<TooltipSeating />}
  />
)
const CreateSectionBlock = ({ className = '', setArrangement, templateSectionArray, setCountSeats, setCountRowsTables, setSectionName, options, namePrefix, sectionID, sectionType, sectionName }) => {
  // const isTable = templateSectionArray?.some(item => item.type || item.sectionType === 'ROWS' ? 'ROWS' : 'TABLES') ? 'ROWS' : 'TABLES'
  const [selectType, setSelectType] = useState(sectionType || 'TABLES')
  const [rowArrangement, setRowArrangement] = useState(sectionType || 'VERTICAL')
  // const [tableSeatSelection, setTableSeatSelection] = useState(sectionType || false)
  // console.log('isTable', isTable)
  const validateLimit = {
    validator (_, value) {
      const seatCount = _?.field?.indexOf('seatCount')
      const groupCount = _?.field?.indexOf('groupCount')
      if (seatCount === 19 && value > 50) {
        return Promise.reject(new Error('You cannot create more than 50 seats per section!'))
      } else if (groupCount === 19 && value > 200) {
        return Promise.reject(new Error(`You cannot create more than 200 ${selectType === 'TABLES' ? 'tables' : 'rows'} per section!`))
      }
      return Promise.resolve()
    },
  }
  // const tableSeatSelectionHandler = () => {
  //   setTableSeatSelection(!tableSeatSelection)
  // }
  return (
    <div className={`CreateSectionBlock ${className}`}>
      <Item
        rules={[{ required: true, message: 'Enter Section Name' }, { min: 1, message: 'Field must be at least 1 characters!' }]}
        initialValue={options?.page === 'template' ? '' : sectionName}
        validateTrigger='onBlur'
        label='Section Name'
        name={[namePrefix, options?.page === 'template' ? 'name' : 'sectionName']}
        className='item'
      >
        <Input onChange={e => options?.page === 'template' && setSectionName(e.target.value)} placeholder='Enter section name' />
      </Item>
      {sectionID && (
        <Item
          name={[namePrefix, 'sectionID']}
          initialValue={sectionID}
          className='item hidden'
        >
          <Input type='hidden' />
        </Item>
      )}
      <Item
        rules={[{ required: true }]}
        initialValue='TABLES'
        label='Section Type'
        name={[namePrefix, options?.page ? 'type' : 'sectionType']}
        className='sectionType'
      >
        <Radio.Group
          onChange={e => {
            // setSectionType && setSectionType(e.target.value)
            setSelectType(e.target.value)
          }} className='radioGroup'
          defaultValue='TABLES'
          value='TABLES'
        >
          <Radio value='TABLES'>
            <div className='wrapperRadioSectionType'>
              <img alt='rows' src={tablesIcon} />
              <div>Tables</div>
            </div>
          </Radio>
          <Radio checked defaultChecked value='ROWS'>
            <div className='wrapperRadioSectionType'>
              <img alt='rows' src={seatsIcon} />
              <div>Rows</div>
            </div>
          </Radio>
        </Radio.Group>
      </Item>
      <div className='sectionRow'>
        <Item
          rules={[{ required: true, message: `Enter Count of ${selectType === 'TABLES' ? 'Tables' : 'Rows'}` }, validateLimit]}
          label={`Count of ${selectType === 'TABLES' ? 'Tables' : 'Rows'}`}
          name={[namePrefix, options?.page === 'template' && selectType === 'TABLES' ? 'groupCount' : options?.page === 'template' && selectType === 'ROWS' ? 'groupCount' : selectType === 'TABLES' ? 'tableCount' : 'sectionRows']}
          className='item'
          min={1}
        >
          <InputNumber onChange={value => options?.page === 'template' && setCountRowsTables(value)} type='number' className='inputNumber' placeholder={0} min={1} />
        </Item>
        <Item
          rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
          label='Count of Seats'
          name={[namePrefix, options?.page === 'template' && selectType === 'TABLES' ? 'seatCount' : options?.page === 'template' && selectType === 'ROWS' ? 'seatCount' : selectType === 'TABLES' ? 'tableCapacity' : 'sectionColumns']}
          className='item'
          min={1}
        >
          <InputNumber onChange={value => options?.page === 'template' && setCountSeats(value)} type='number' className='inputNumber' placeholder={0} min={1} />
        </Item>
      </div>
      {selectType === 'ROWS' &&
        (
          <div className='assignTooltip'>{rowArrangementLabel()}
            <Item
              rules={[{ required: true }]}
              name={[namePrefix, 'rowArrangement']}
              className='no_margin item_radio-group'
              initialValue={rowArrangement}
            >
              <Radio.Group
                onChange={e => {
                  setRowArrangement(e.target.value)
                  setArrangement && setArrangement(e.target.value)
                }} className='arrangment'
              >
                <Radio value='HORIZONTAL'>Alphabetic</Radio>
                <Radio value='VERTICAL'>Numeric</Radio>
              </Radio.Group>
            </Item>
          </div>
        )}
      {/* {selectType === 'ROWS'
        ? (
          <div className='assignTooltip'>{rowArrangementLabel()}
            <Item
              rules={[{ required: true }]}
              name={[namePrefix, 'rowArrangement']}
              className='no_margin item_radio-group'
              initialValue={rowArrangement}
            >
              <Radio.Group
                onChange={e => {
                  setRowArrangement(e.target.value)
                  setArrangement && setArrangement(e.target.value)
                }} className='arrangment'
              >
                <Radio value='HORIZONTAL'>Alphabetic</Radio>
                <Radio value='VERTICAL'>Numeric</Radio>
              </Radio.Group>
            </Item>
          </div>
        ) : (
          <>
            <Item className='item switchContainer'>
              <Item
                className='no_margin item_radio-group'
                initialValue={tableSeatSelection}
                name={[namePrefix, 'tableSeatSelection']}
              >
                <Switch
                  defaultChecked={tableSeatSelection}
                  onChange={tableSeatSelectionHandler}
                />
              </Item>
              <div className='label'>
                <div className='title'>Allow guests to choose a seat at table</div>
              </div>
            </Item>
          </>
        )} */}
    </div>
  )
}

export default CreateSectionBlock
