import React from 'react'
import './index.styl'

const Terms = () => {
  return (
    <div className='help_container'>
      <h1 className='pageTitle'>
        Terms and Conditions
      </h1>
      <div className='textBlock'>
        <p>
          This user agreement is a contract between you and Ticket Lyfe that governs your use of your Ticket Lyfe account and the Ticket Lyfe services. It applies to all Ticket Lyfe accounts for use of the Ticket Lyfe services. Please carefully read all of the terms of these policies and each of the other agreements that apply to you. We may amend this user agreement at any time by posting a revised version on our website. The revised version will be effective at the time we post it.
        </p>
        <p>
          By opening and using a Ticket Lyfe Account, you (the user) agree to comply with all of the terms and conditions in this user agreement, so please read all of the terms and conditions carefully. You also agree to comply with the following additional policies and each of the other agreements on the Stripe Payment if you are using Stripe as your method of getting payouts, as they apply to you.  All pertinent aspects of Ticket Lyfe are defined specifically in the definitions listed below
        </p>
      </div>
      <h2 className='chapterTitle'>
        Definitions:
      </h2>
      <div className='textBlock'>
        <ol className='offNumeration'>
          <li>
            <strong className='boldText'>Promoter:</strong> All Ticket Lyfe Users are promoters, because once you become a Ticket Lyfe member, you can make, buy, sell, gift and sub-promote tickets.  In general, a Promoter of an event is the one who makes and sells the tickets for an event.  In the case of a Venue Lyfe Account, a Venue can also be its own Promoter: making tickets for their own venue (example: an entertainment club); in this case, they would be both the Promoter and the Venue at the same time.
          </li>
          <li>
            <strong className='boldText'>Co-Promoter:</strong> A Ticket Lyfe member who is assigned to make tickets to a venue that has a Venue Lyfe Account.  (Example: A person rents a Venue for a night, and that Venue has a Venue Lyfe Account.  The Venue starts the ticket with the date, time and co-promoter, and then assigns it to that person who is the renter of the venue, to finish making the ticket).  That person who is the renter of the venue, will now be defined as the Co-Promoter—who finishes up the rest of the details of the ticket to be sold.  To be assigned as a Co-promoter, this person must have made a Ticket Lyfe account.
          </li>
          <li>
            <strong className='boldText'>Sub-Promoter:</strong> Once Promoters make a ticket, they can give some of their tickets to other Ticket Lyfe members to sell—this is defined as Sub-Promoting.  (Example: a Promoter makes 500 tickets to an event and then gives 100 tickets to another Ticket Lyfe member to sell.  That person is now a Sub-Promoter.)  Sub-Promoters get their own URL links to sell tickets; they also get their own box office to track their own sales.  The money from the tickets sold via Sub-Promoter still goes to the Promoter.  Whatever financial arrangements that are made between the Promoter and Sub-Promoter are between themselves, and not Ticket Lyfe.
          </li>
          <li>
            <strong className='boldText'>Promoter Partner:</strong> Ticket Lyfe users who have subscribed themselves to become a partner of Ticket Lyfe and will receive a 1.5% share of ticket sales (up to maximum $1.50 per individual ticket) on top of their ticket sales from Ticket Lyfe.  The cost of a yearly subscription to become a Ticket Lyfe Promoter Partner is $300.  (Example: if a Promoter Partner sells 100 tickets at $100 apiece for an event, they will receive 1.5% of $10,000, which is $150.  Obviously only serious Promoters should get a subscription to become a Ticket Lyfe Promoter Partner,)
          </li>
          <li>
            <strong className='boldText'>Venue Lyfe Account:</strong> The Venue has signed up as a partner of Ticket Lyfe FOR ONE ADDRESS and will either make tickets to their venue, or assign tickets to Co-Promoters, so that the tickets made from their Venue will be Ticket Lyfe Tickets.  Once enrolled in Venue Lyfe, no Promoters can make a Ticket Lyfe event at this venue unless assigned by the Venue; in other words, Ticket Lyfe recognizes the Venue Lyfe Partner’s exclusive rights to that address.  The annual cost to become a Venue Lyfe Member is $300; and the Ticket Lyfe Fee share is 2% per each ticket sold (maximum of $2.00 per ticket).  The Venue has a choice of whether to receive the money directly into their bank or Stripe account; or have the money Pass-Through, and go directly to the Co-Promoter instead.  In other words, the Venue has the choice to handle the money itself, or have the Co-Promoter handle the ticket money.  If the Venue makes their own tickets without a Co-Promoter, they will be able to do so as well (example: Entertainment Club).  Example 1: the Venue rents out to a Co-Promoter who sells 100 tickets for $100 and the Venue uses the Pass-Through system.  The Co-Promoter receives $10,000 directly from Ticket Lyfe, and the Venue receives $200 directly from Ticket Lyfe.  Example 2: The Venue is a Comedy Club and sells its own tickets—100 tickets at $50 apiece.  The Venue first receives $5,000 for the tickets sold, and then $100 as a Venue Lyfe Partner.
          </li>
          <li>
            <strong className='boldText'>Event:</strong> A Ticket Lyfe event is either (1) any physical event in any specific location where a ticket can be sold to attend; (2) a Virtual Live Stream Event that is either Private or Pay Per View where the Promoter Streams via Ticket Lyfe’s RTMP server and Private Stream Key; (3) a virtual meeting that a Promoter has made tickets to via Ticket Lyfe.
          </li>
        </ol>
      </div>
      <h2 className='chapterTitle'>
        TL accounts are used to:
      </h2>
      <div className='textBlock'>
        <ol className='olBlock'>
          <li>
            Make tickets
          </li>
          <li>
            Sell tickets
          </li>
          <li>
            Buy tickets
          </li>
          <li>
            Gift tickets
          </li>
          <li>
            Store tickets  (Sellers in their box office; buyers in their digital wallet)
          </li>
          <li>
            Assign Sub-Promoters to sell tickets
          </li>
          <li>
            Assign Co-Promoters to sell tickets
          </li>
          <li>
            Accept payments for tickets
          </li>
          <li>
            Invite others to buy tickets
          </li>
          <li>
            Keep track of tickets made, bought, sold, gifted, or assigned to a sub-promoter
          </li>
        </ol>
      </div>
      <h2 className='chapterTitle'>
        Opening an Account
      </h2>
      <div className='textBlock'>
        <p>
          You will need to open up a TL account to use any of our promoting services but are able to buy tickets as a guest (using your mobile number and email address). You are required to make up a unique username, and also connect to Ticket Lyfe with your email address and mobile phone number.  Once you open an account with TL you are responsible for maintaining adequate security and control of any and all IDs, passwords, personal identification numbers (PINs), or any other codes that you use to access your TL account and TL services. You are responsible for keeping your mailing address, email address and other contact information up-to- date in your TL account profile.  You are responsible to keep up to date with your bank account or Stripe information if you are selling tickets.  If you are a Venue Lyfe or Promoter Partner you are responsible to renew your subscription annually in order to get paid your partnership commissions for the tickets you sell.
        </p>
        <p>
          All Ticket Lyfe users and guests understand that anytime they buy a ticket that their Ticket Lyfe username, email address and mobile phone numbers are shared to Ticket Lyfe and the Promoters and Venues who are selling or gifting the tickets for the purposes of tracking ticket sales.  All Ticket Lyfe Promoters and Venues agree that they will not use the information obtained for any other reason than the tracking of ticket sales and agree not to share this information with any other third parties for any reason.
        </p>
      </div>
      <h2 className='chapterTitle'>
        Payment of Ticket Sales to Ticket Maker
      </h2>
      <div className='textBlock'>
        <p>
          Promoters and Venues will get their ticket sales at 70% approximately three business days after the event, and then the remaining 30% approximately ten business days after the event.  Promoters and Venues agree to be responsible for all disputes which result in the return of money to the ticket buyer, although it is understood that all ticket purchases are non-refundable.  If a credit card company reverses a Ticket Lyfe charge, the Promoter or Venue assumes responsibility for all monies returned, including all fees associated with the tickets in question.  To receive payments Promoters and Venues can either use their bank account or Stripe account.
        </p>
      </div>
      <h2 className='chapterTitle'>
        Buying of Tickets and Non-Refundablity
      </h2>
      <div className='textBlock'>
        <p>
          All ticket sales and fees are nonrefundable.  The buyer of a ticket via Ticket Lyfe believes both the event and the promoter of the event to be credible, and able to produce the event in question.  The money for the tickets is being directed to the promoter—ie, the ticket maker—of the event, and not to Ticket Lyfe.  Therefore, all monetary ticket disputes are to be taken up with the promoter of the event, and not Ticket Lyfe.  If the ticket buyer cannot make the event for some unforeseen reason (example: sickness, death, fire, accidents, emergencies of any kind…) this still does not entitle the ticket buyer to get a refund as the event is still on-going, and the tickets held by the buyer could have been either transferred to another who could make the event, or resold by the promoter; therefore the ticket buyer acknowledges that the promoter is entitled to sell the ticket without refund, and that the action of buying a ticket precludes the sale of that same ticket to another buyer.   Any event produced by the Promoter will be considered as providing an event, even if not exactly matching the exact advertised terms of the event.  If the promoter of the event faces circumstances where the exact event promised is not able to be produced, then whatever substitute event offered will satisfy the definition of an event production.
        </p>
      </div>
      <h2 className='chapterTitle'>
        Storing of Tickets
      </h2>
      <div className='textBlock'>
        <p>
          Tickets made by a Promoter are stored in their Box Office. Gifted or bought tickets are stored in a Ticket Lyfe user’s Digital Wallet.  Guest Users will be emailed their tickets.
        </p>
      </div>
      <h2 className='chapterTitle'>
        Raffle Tickets
      </h2>
      <div className='textBlock'>
        <p>
          For CHARITY RAFFLE Add-Ons to a Ticketed Admission or Live Stream or Virtual Event, the limits are: $35,000 per Raffle Category and $100 per ticket.  Note that Ticket Lyfe also limits it's fees to $1 or $2, depending on the charity raffle price.  For RANDOM DRAWS pertaining to Ticketed Events  (Admission, Live Stream, Virtual Events)--such as Door Prizes, or other prizes that are given out to random ticket holders chosen to receive prizes: the choosing of these prize winners is done the same as the Charity Raffles, except that Ticket Lyfe does not recognize these random prizes as a raffle, nor puts limits on the revenue, nor gives the lower Ticket Lyfe fees for Charity Raffles.  These type of tickets are recognized as regular events, where the promoter uses the Ticket Lyfe random draw feature to give out prizes to their audience.
        </p>
      </div>
      <div className='textBlock'>
        <p>
          Last Updated: <b className='boldText'>February 2, 2024</b>
        </p>
      </div>
    </div>
  )
}

export default Terms
