import React, { useState } from 'react'
import { Modal, Form, Input, Switch } from 'antd'
import './index.styl'
import c from 'classnames'

const { Item } = Form

const ModalUpdateSeats = ({ visible, updateVisible, submitForm, activeColumns, activeRows, activeSeat }) => {
  const [isReserved, setIsReserved] = useState(false)
  const reservedHandler = () => {
    setIsReserved(!isReserved)
  }
  const submitFormHandler = e => {
    // const seatArray = {
    //   rowNo: '',
    //   columnNo: '',
    //   rowName: '',
    //   columnName: '',
    //   price: '',
    //   isPasswordEnabled: '',
    //   password: '',
    //   isDeleted: '',
    //   isReserved: '',
    // }
    submitForm(e, isReserved)
  }
  let title
  let label
  let itemName
  if (activeColumns.length > 0) {
    itemName = 'colName'
    title = `Edit Column ${activeColumns.join(' ')}`
    label = 'Column name'
  }
  if (activeRows.length > 0) {
    itemName = 'rowName'
    title = `Edit Row ${activeRows.join(' ')}`
    label = 'Row name'
  }
  if (activeSeat[0] !== 0 && activeSeat[1] !== 0) {
    title = `Edit Seat (${activeSeat[0]} Row and ${activeSeat[1]} Column)`
    label = false
  }
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={updateVisible}
      onOk={updateVisible}
      okText='Save'
      okButtonProps={{ form: 'updateSeats', key: 'submit', htmlType: 'submit' }}
      destroyOnClose
    >
      <Form
        layout='vertical'
        onFinish={submitFormHandler}
        id='updateSeats'
      >
        {label &&
          <Item
            name={itemName}
            label={label}
            className='item'
            colon={false}
          >
            <Input />
          </Item>}
        <Item
          name='price'
          label='price for tickets'
          className='item'
          colon={false}
        >
          <Input placeholder='$0' />
        </Item>
        <div className='secondTitle'>Advanced settings</div>
        {/* <div className='groupItemsModal'>
          <Item
            name='eventcode'
            className={c('item, groupItem')}
            initialValue={isAddEventCode}
            valuePropName='checked'
          >
            <div className='eventcode'>
              <Switch onChange={eventCodeHandler} />
              <div className='text'>Add event code</div>
            </div>
          </Item>
          <Item
            name='eventcodeinput'
            className={c('item', 'groupItem')}
          >
            <Input />
          </Item>
        </div> */}
        <div className='borderLine' />
        <Item
          name='reserved'
          className={c('item')}
          initialValue={isReserved}
          valuePropName='checked'
        >
          <div className='eventcode'>
            <Switch onChange={reservedHandler} />
            <div className='text'>Make these selected seat/s reserved for someone</div>
          </div>
        </Item>
      </Form>
    </Modal>
  )
}

export default ModalUpdateSeats
