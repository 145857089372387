export const currencyCodes = {
  USD: {
    label: 'US Dollars',
    code: 'USD',
    symbol: '$',
  },
  GBP: {
    label: 'British Pounds',
    code: 'GBP',
    symbol: '£',
  },
  EUR: {
    label: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  CAD: {
    label: 'Canadian Dollar',
    code: 'CAD',
    symbol: 'C$',
  },
}

export const getCurrencySymbolByCode = (code = currencyCodes.USD.code) => {
  return currencyCodes[code]?.symbol || currencyCodes.USD.symbol
}
