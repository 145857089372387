import React from 'react'
import { Button, Modal } from 'antd'
import { CreateSectionBlock } from 'components'
import './index.styl'
import { getLetter } from 'utils'

const AssignSeating = ({ field, remove, removeIndex, update, templateSectionArray, section, setCountSeats, setArrangement, setCountRowsTables, options, setSectionName }) => {
  const onRemoveSeating = (onRemove, field) => {
    Modal.confirm({
      title: 'Are you sure？',
      cancelText: 'No',
      okText: 'Yes',
      onOk: () => {
        onRemove(field)
        removeIndex && removeIndex(!update)
      },
    })
  }
  const num = field.name + 1
  const sectionName = `Section ${options?.page === 'template' ? getLetter(num) : num}`
  return (
    <div className={options?.page === 'template' ? 'AssignSeatingTemplate' : 'AssignSeating'}>
      <div className='seatingName'>
        {sectionName}
        {options?.page === 'template' && <span className='required'>*</span>}
      </div>
      <div className={options?.page === 'template' ? 'seatingBlock templateWrapper' : 'seatingBlock'}>
        <CreateSectionBlock
          sectionType={section?.sectionType}
          templateSectionArray={templateSectionArray}
          sectionName={sectionName}
          setArrangement={setArrangement}
          setCountSeats={setCountSeats}
          setCountRowsTables={setCountRowsTables}
          setSectionName={setSectionName}
          options={options}
          sectionID={section?._id}
          className='seatingItem'
          namePrefix={field.name}
          onRemove={remove}
          key={field.key}
          listItem
        />
        {field?.name !== 0 &&
          <div className='seatingFooter'>
            <Button
              onClick={() => onRemoveSeating(remove, field.name)}
              className='removeSection'
              type='link'
            >
              Remove {sectionName}
            </Button>
          </div>}
      </div>
    </div>
  )
}

export default AssignSeating
