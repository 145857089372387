import React, { useEffect } from 'react'
import { Layout } from 'components'
import Header from './Header'
import Body from './Body'
import './index.styl'

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout className='wrapperContactUs'>
      <div className='container'>
        <Header />
        <Body />
      </div>
    </Layout>
  )
}

export default ContactUs
