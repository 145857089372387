import React from 'react'
import { useSelector } from 'react-redux'
import { FormSignIn, CustomButton } from 'components'
import circleUser from 'images/circleUser.png'
import './index.styl'

const Login = ({ onChangeScreen, setAuthorizedBuy }) => {
  const { user } = useSelector(state => state.session)
  const onFinishLogin = () => {
    onChangeScreen('payment', true)
    setAuthorizedBuy(true)
  }
  const goGuest = () => {
    setAuthorizedBuy(false).then(() => onChangeScreen('guest'))
  }
  return (
    <div className='screen screenLogin'>
      {user
        ? (
          <div className='wrapperUserModal'>
            <img src={circleUser} alt='circleUser' className='icon' />
            <div className='title'>You are logged in as {user.userName}</div>

            <div className='actions'>
              <CustomButton text='Next' type='dark' onClick={onFinishLogin} />
            </div>
          </div>
        )
        : (
          <FormSignIn title='Please sign in or continue as a guest' onFinishLogin={onFinishLogin} goGuest={goGuest} />
        )}
    </div>
  )
}

export default Login
