import React from 'react'
import { useSelector } from 'react-redux'
import { LayoutSwitcher } from 'components'
import { Navigation, User, Search } from '../components'
import './index.styl'
import c from 'classnames'

const Authorized = () => {
  const { isMobile } = useSelector(state => state.session)
  return (
    <div className={c('authorizedHeader', { authorizedHeaderMob: isMobile === true })}>
      {isMobile
        ? (
          <>
            <Navigation />
            <div className='authorizedLogo'>
              <LayoutSwitcher type='ticket' />
            </div>
            <Search />
          </>)
        : (
          <>
            <LayoutSwitcher type='ticket' />
            <Navigation />
            <Search />
          </>)}
      <User />
    </div>
  )
}

export default Authorized
