import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
} from 'antd'
import {
  uploadAnnouncementImage,
} from 'modules/memberships/memberships-actions'

import './index.styl'
import ImageUploader from '../../../../../ImageUploader'

const { Item } = Form
const { TextArea } = Input

const AnnouncementForm = ({ name, onRemove, form, categoryData }) => {
  const [imageUrl, setImageUrl] = useState(categoryData?.imageUrl || {})
  useEffect(() => {
    const announcementsData = form.getFieldValue('announcements')
    const updatedAnnouncementsData = announcementsData?.map((item, index) => {
      if (index === name && Object.keys(imageUrl).length > 0) {
        return {
          ...item,
          imageUrl: imageUrl,
        }
      }
      return item
    })

    form.setFieldsValue({
      announcements: updatedAnnouncementsData,
    })
  }, [imageUrl])

  const uniqueId = `imageAnnouncementUploadInput-${name}`

  return (
    <div className='AnnouncementWrapper'>
      <div className='tt'>
        <div className='AnnouncementTopField'>
          <Item
            name={[name, 'title']} label='Title' rules={[
              { required: true, message: 'Title is required!' },
              { max: 100, message: 'Title cannot exceed 100 characters!' },
            ]}
            normalize={v => v.trimStart()}
          >
            <Input className='inputTitle' />
          </Item>
          <Item
            rules={[
              { required: false },
              { max: 500, message: 'Additional Info cannot exceed 500 characters!' },
            ]}
            name={[name, 'description']}
            label='Additional Info'
            normalize={v => v.trimStart()}
          >
            <TextArea autoSize={{ maxRows: 50 }} className='additionalInfo' />
          </Item>
        </div>
        <ImageUploader uploaderTitle='Announcement' id={uniqueId} categoryData={categoryData} setImageUrl={setImageUrl} form={form} name={name} action={uploadAnnouncementImage} />
      </div>
      <div className='btnWrapper'>
        <div className='removeAnnouncementBtn' onClick={() => onRemove(name)}>
          Delete Announcement
        </div>
      </div>
    </div>
  )
}
export default AnnouncementForm
