import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import c from 'classnames'
import { slide as MenuSlide } from 'react-burger-menu'
import burger from 'images/Menu.svg'
import DropDownIcon from 'images/DropDownIcon.svg'
import { isAdmin, isVenue } from 'utils'
import './index.styl'
import { SwitcherBlock } from 'components'
import { Search } from '../../components'

const { Item } = Menu

const Navigation = () => {
  const { pathname } = useLocation()
  const { isMobile, user } = useSelector(state => state.session)
  const { currentEvent } = useSelector(state => state.events)
  const [isOpen, setIsOpen] = useState(false)

  const linksPaths = {
    home: (pathname.includes('venue')) && !(pathname.includes('help')) ? pathname : currentEvent?.venueDetails ? `/venue/${currentEvent?.venueDetails.venueUrl}` : user?.isVenueUser ? `/venue/${user?.venueUrl}` : '/home',
    myWallet: user ? '/my-wallet' : '/help/overview/my-wallet',
    makeMyTickets: user ? '/make-my-tickets' : '/help/overview/make-my-tickets',
    myBoxOffice: user ? '/my-box-office/table-events' : '/help/overview/my-box-office',
    scanner: '/scanner',
    myBackOffice: '/my-back-office',
    userListing: '/user-listing',
    myTemplates: '/my-templates',
    makeMyMemberLyfe: user ? '/make-my-memberLyfe' : '/help/overview/make-my-tickets',
  }

  const onScroll = () => {
    setIsOpen(false)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const isActive = math => {
    return Array.isArray(math) ? !!math.map(m => pathname.includes(m)).filter(Boolean).length : pathname.includes(math)
  }

  const renderLinkItem = ({ goTo, label, active }) => (
    <Link
      key={goTo}
      to={goTo}
      className={c('link', { active })}
      onClick={onScroll}
    >
      {label}
    </Link>
  )

  const renderSubLinkItem = ({ goTo, label, active }) => (
    <Item key={goTo}>
      <Link
        to={goTo}
        className={c('link', { active })}
        onClick={onScroll}
      >
        {label}
      </Link>
    </Item>
  )

  const mainLinks = [
    { goTo: linksPaths.home, label: 'Home', active: isActive(linksPaths.home) && !pathname.split('/')[1] },
    { goTo: linksPaths.myWallet, label: 'My Wallet', active: isActive(linksPaths.myWallet) },
  ]
  const subLink = [
    { goTo: linksPaths.makeMyTickets, label: 'Make My Tickets', active: isActive(linksPaths.makeMyTickets) },
    { goTo: linksPaths.myBoxOffice, label: 'My Box Office', active: isActive(linksPaths.myBoxOffice) },
  ]
  if (user) {
    subLink.push({ goTo: linksPaths.myTemplates, label: 'My Templates', active: isActive(linksPaths.myTemplates) })
    subLink.push({ goTo: linksPaths.scanner, label: 'Scanner', active: isActive(linksPaths.scanner) })
  }
  const adminLink = []
  if (isAdmin(user?.role)) {
    adminLink.push({ goTo: linksPaths.myBackOffice, label: 'My Back Office', active: isActive(linksPaths.myBackOffice) })
    adminLink.push({ goTo: linksPaths.userListing, label: 'Users', active: isActive(linksPaths.userListing) })
  }
  if (isVenue(user?.role)) {
    adminLink.push({ goTo: linksPaths.myBackOffice, label: 'Back Office', active: isActive(linksPaths.myBackOffice) })
  }

  const menu = (<Menu className='sub-menu'>{subLink.map(renderSubLinkItem)}</Menu>)
  const menuBackOffice = (<Menu className='sub-menu'>{adminLink.map(renderSubLinkItem)}</Menu>)
  const activeDropDown = isActive(['make-my-tickets', 'my-box-office', 'my-templates', 'scanner'])
  const activeDropDownBackOffice = isActive(['user-listing', 'my-back-office'])

  return (
    <div className='mainNavigation'>
      {isMobile ? (
        <MenuSlide
          left
          customBurgerIcon={<img src={burger} className='menu' />}
          onClose={() => setIsOpen(!isOpen)}
          onOpen={() => setIsOpen(!isOpen)}
          burgerButtonClassName='burger-btn'
          isOpen={isOpen}
        >
          {user && <SwitcherBlock type='ticket' />}
          <div className='additionalSwitcherWrapper'>
            <Search isSwitcherSearch />
          </div>
          {[...mainLinks, ...subLink, ...adminLink].map(renderLinkItem)}
        </MenuSlide>
      ) : (
        <>
          {mainLinks.map(renderLinkItem)}
          <Dropdown overlay={menu} trigger={['click']} key='SellerTools'>
            <div className='dropdownLink'>
              <div className={c('link', 'dropdownLinkText', { activeDropDown })}>Seller Tools</div>
              <img src={DropDownIcon} alt='Drow down icon' className='icon' />
            </div>
          </Dropdown>
          {isAdmin(user?.role)
            ? (
              <Dropdown overlay={menuBackOffice} trigger={['click']} key='BackOffice'>
                <div className='dropdownLink backOfficeMenu'>
                  <div className={c('link', 'dropdownLinkText', { activeDropDownBackOffice })}>Back Office</div>
                  <img src={DropDownIcon} alt='Drow down icon' className='icon' />
                </div>
              </Dropdown>
            ) : (isVenue(user?.role) ? adminLink.map(renderLinkItem) : null)}
          {/* {adminLink.map(renderLinkItem)} */}
        </>
      )}
    </div>
  )
}

export default Navigation
