import React, { forwardRef } from 'react'
import { QRCode } from 'react-qrcode-logo'
import QRCodeLogoIcon from 'images/MLQRLogo.svg'
import VisitMLImage from 'images/visitML.png'
import BecomeMemberImage from 'images/becomeMember.png'
import OpenWalletImage from 'images/openWalletMember.png'
import ArrowNext from 'images/printArrow.svg'
import './index.styl'

const PrintQRCodePage = forwardRef(({ qrCodeValue }, ref) => {
  return (
    <div ref={ref} className='printPageWrapper'>
      <div className='printTitle'>
        Scan the QR Code to Enter
      </div>
      <div className='qr-code-container'>
        <QRCode
          size={400}
          logoImage={QRCodeLogoIcon}
          removeQrCodeBehindLogo
          logoPadding={10}
          value={qrCodeValue}
        />
      </div>
      <div className='instructions'>
        <div className='step'>
          <span>Visit www.memberlyfe.com</span>
          <img src={VisitMLImage} alt='' />
        </div>
        <img className='arrowNextStepMember' src={ArrowNext} alt='' />
        <div className='step'>
          <span>Become a member "Club Name"</span>
          <img src={BecomeMemberImage} alt='' />
        </div>
        <img className='arrowNextStepMember' src={ArrowNext} alt='' />
        <div className='step'>
          <span>Open "My Wallet" and Scan QR Code</span>
          <img src={OpenWalletImage} alt='' />
        </div>
      </div>
    </div>
  )
})

export default PrintQRCodePage
