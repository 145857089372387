import React from 'react'
import { useSelector } from 'react-redux'
// import { CustomButton } from 'components'
import circleDone from 'images/circleDone.png'
import './index.styl'
import { push } from 'router'

const Tickets = ({ price = 0, inviteType, donationOnly = false, currencySymbol }) => {
  const { user } = useSelector(state => state.session)
  const desc = user
    ? <>Tickets available in <span className='link' onClick={() => push('/my-member-wallet')}>My wallet</span></>
    : <>Check your email to access your tickets</>
  return (
    <div className='wrapperMemberTicketsStep'>
      <img src={circleDone} alt='circleDone' />
      <div className='title'>Paid <span>{currencySymbol}{price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></div>
      {inviteType !== 'Gift' &&
        <div>
          {donationOnly
            ? <p className='description'>Thank you for the donation !</p>
            : (
              <p className='description'>
                {desc}
              </p>)}
          {/* <CustomButton text='Download tickets' type='white' /> */}
        </div>}
    </div>)
}

export default Tickets
