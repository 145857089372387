import api from './axios.v2'

const uploadFile = image => {
  const data = new FormData()
  const files = image?.fileList || []
  const obj = files[files.length - 1]
  data.append('image', obj?.originFileObj)
  // TODO: potential weekness, our project can be used as a free file storage
  // need to make authorization on frontend and backend
  return api('user/uploadFile', 'POST', data, false)
}

const preparePoster = (image, options) => {
  const data = new FormData()
  data.append('data', image?.file?.originFileObj)
  return api('poster/prepare', 'POST', data, true, options)
}
const posterCover = (originalImage, image, options) => {
  const data = new FormData()
  data.append('sourceImage', originalImage)
  data.append('posterImage', image)
  return api('poster/cover', 'POST', data, true, options)
}
const posterSeatingSchema = (originalImage, options) => {
  const data = new FormData()
  data.append('data', originalImage)
  return api('poster/seatingSchema', 'POST', data, true, options)
}

export default {
  uploadFile,
  preparePoster,
  posterCover,
  posterSeatingSchema,
}
