import React from 'react'

import './index.styl'
import Categories from './Categories'

const Body = () => {
  return (
    <div className='bodyWrapper'>
      <Categories />
    </div>
  )
}

export default Body
