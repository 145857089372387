import React from 'react'
import { Form, Modal } from 'antd'
import { CreateSectionBlock } from 'components'

const ModalNewSection = ({ visible, updateVisible, submitForm, loading }) => {
  return (
    <Modal
      okButtonProps={{ form: 'newSection', key: 'submit', htmlType: 'submit', loading, disabled: loading }}
      maskStyle={{ backgroundColor: '#62677EE5' }}
      onCancel={() => loading ? {} : updateVisible()}
      closable={!loading}
      title='New section'
      visible={visible}
      okText='Create'
      destroyOnClose
    >
      <Form layout='vertical' onFinish={submitForm} id='newSection'>
        <CreateSectionBlock className='ModalNewSection' namePrefix='formValues' />
      </Form>
    </Modal>
  )
}

export default ModalNewSection
