import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  parentApproveToken,
  getParentApproveToken,
} from 'modules/memberships/memberships-actions'
import { MemberLyfeLayout, CustomButton } from 'components'

import { useParams } from 'react-router-dom'

import './index.styl'

const ParentApproval = () => {
  const { token } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingDisaprove, setLoadingDisaprove] = useState(false)
  const [parentMessage, setParentMessage] = useState('')
  const [parentData, setParentData] = useState({})
  const dispatch = useDispatch()
  const handleApprove = () => {
    setLoading(true)
    const obj = {
      token,
      approval: true,
    }
    dispatch(parentApproveToken(obj)).then(data => {
      setLoading(false)
      setParentMessage(data.data.message)
    }).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    dispatch(getParentApproveToken(token)).then(data => {
      setParentData(data.data)
    })
  }, [token])
  const handleDisapprove = () => {
    setLoadingDisaprove(true)
    const obj = {
      token,
      approval: false,
    }
    dispatch(parentApproveToken(obj)).then(data => {
      setLoadingDisaprove(false)
      setParentMessage(data.data.message)
    }).catch(() => {
      setLoadingDisaprove(false)
    })
  }
  return (
    <MemberLyfeLayout>
      <div className='wrapperApprovalScreen'>
        <div className='parentApprovalTitle'>Parent Approval</div>
        {parentMessage === 'OK' ? (
          <div className='parentApprovalDescription'>Parent approval succesfully updated!</div>
        ) : (
          <>
            <div className='parentApprovalDescription'>
              To proceed with {parentData.kidName} becoming a member, we require your permission as their parent/guardian. Please take a moment to review the page of Membership, and kindly provide your approval or denial.
            </div>
            <div className='parentAproveBtnWrapper'>
              <CustomButton
                onClick={handleApprove}
                text='Approve'
                type='dark'
                loading={loading}
                className='approveBtn'
              />
              <CustomButton
                onClick={handleDisapprove}
                text='Deny'
                loading={loadingDisaprove}
                className='disaproveBtn'
                type='dark'
              />
            </div>
          </>
        )}
      </div>
    </MemberLyfeLayout>
  )
}

export default ParentApproval
