import React from 'react'
import { Form, Divider } from 'antd'

import SeatingScheme from '../../CreateTemplate/GeneralInfo/SeatingScheme'
import AssignSeating from '../../CreateEvent/OptionsEvent/AssignSeating'
import InfoTooltip from 'images/BlueInformationToolTip.svg'
import ArrowImg from 'images/red-arrow.svg'
import './index.styl'
const { List } = Form

const ManualCreationSeating = ({ templateCover, setTemplateCover, templateSectionArray, onUpdateCategory }) => {
  // const [saveTemplate, setSaveTemplate] = useState(false)
  // const saveTemplateHandler = () => {
  //   setSaveTemplate(!saveTemplate)
  // }

  return (
    <div>
      <div className='titleGeneral'>General Info</div>
      <SeatingScheme templateCover={templateCover?.thumbnail1} setTemplateCover={setTemplateCover} />
      <div className='containerTitle mt-3'>Sections</div>
      {/* <List name='manualTemplateCategories'>
        {(fields, { add, remove }) => (
          <div className='priceCatMap'>
            {fields.map((field, i) => {
              return (
                <div key={field.name}>
                  <div className='categoryLabel'>Category {field.name + 1}</div>
                  <Categories
                    field={{ ...field, key: `${field?.name}` }}
                    key={[field?.name]}
                    onRemove={remove}
                    onUpdateCategory={onUpdateCategory}
                  />
                </div>
              )
            },
            )}
            <div
              id='addPriceCategory' className='btn' onClick={() => {
                add()
              }}
            >Add New Price Category
            </div>
          </div>)}
      </List> */}
      <List name='manualTemplateSections'>
        {(fields, { add, remove }) => (
          <div className='priceCatMap'>
            <div className='seatingWrapper'>
              {fields.map(field => (<AssignSeating templateSectionArray={templateSectionArray} key={field.key} field={field} remove={remove} />))}
            </div>
            <div id='addSections' className='btn sectionBtn' onClick={() => add()}>Add New Section</div>
          </div>
        )}
      </List>
      <Divider />
      <div className='additionalSettingWrap'>
        <img alt='InfoTooltip' src={InfoTooltip} />
        <div>
          <div className='additionalSettingsLabel'>You can make additional adjustments to the seating layout after creating the event using your Box Office, including adding, removing or adjusting seats.</div>
          <div>
            <span className='infoPath'><span>My Box Office </span><img src={ArrowImg} alt='arrowRed' /><span> The Event </span> <img src={ArrowImg} alt='arrowRed' /> <span>Assigned and/or Table Seating</span> </span>
          </div>
        </div>
      </div>
      <Divider />
      {/* <div className='radioItem'>
        <Item
          name='saveTemplate'
          initialValue={saveTemplate}
          valuePropName='checked'
          className='item noGutter'
        >
          <Switch onChange={saveTemplateHandler} defaultChecked={saveTemplate} />
        </Item>
        <div className='text'>Save the seating scheme in my templates</div>
      </div>
      {saveTemplate &&
        <Item
          rules={[{ required: true, message: 'Template Name is required' }]}
          label='Template Name'
          className='item templateName'
          name='templateName'
          normalize={v => v.trimStart()}
        >
          <Input placeholder='Name' className='input' />
        </Item>} */}
    </div>
  )
}

export default ManualCreationSeating
