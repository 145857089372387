import React from 'react'

import './index.styl'

import Subscriptions from './components/Subscriptions'
import Sessions from './components/Sessions'
import Buy from './components/Buy'
import Passes from './components/Passes'

const SessionAndPasses = ({ form, currentPageDetails }) => {
  return (
    <div className='SPBWrapper'>
      <Subscriptions currentPageDetails={currentPageDetails} />
      <Passes currentPageDetails={currentPageDetails} />
      <Sessions currentPageDetails={currentPageDetails} />
      <Buy currentPageDetails={currentPageDetails} />
    </div>
  )
}

export default SessionAndPasses
