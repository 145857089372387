import React, { useState } from 'react'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import './index.styl'

const HiddenField = ({ label, value }) => {
  const [visible, setVisible] = useState(false)
  return (
    <span className='HiddenField'>
      <span className='hiddenFieldLabel'>
        {visible ? value : label}
      </span>
      <span onClick={() => setVisible(!visible)} className='hiddenFieldToggle'>
        {visible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
      </span>
    </span>
  )
}

export default HiddenField
