import React from 'react'
import { Layout } from 'components'
import { Switch } from 'antd'
import categoriesIcon from 'images/Category.svg'
import assignedSeatingIcon from 'images/assignedSeating.svg'
import scannerIcon from 'images/Scanner.svg'
import financeReport from 'images/financeReport.svg'
import arrow from 'images/simpleArrow.svg'
import c from 'classnames'
import './index.styl'

const TicketGuide = () => {
  const onChange = () => {}

  return (
    <Layout>
      <div className='wrapperTicketGuide'>
        <div className='navigationSectionGuide'>
          <div className='linkGuide'>
            <img
              src={arrow}
              alt='arrow'
              className={c('linkGuideArrow', 'linkBackGuide')}
            />
            Back to 'event name'
          </div>
          <div>
            <Switch onChange={onChange} /> Do not Show Again
          </div>
        </div>
        <div className='titleGuide'>Event Guide</div>
        <div className='textGuide'>text about this workflow.</div>
        <div className='wrapperStepsGuide'>
          <div className='stepSectionGuide'>
            <div className='stepTitleGuide'>
              <div className='stepNumberGuide'>1.</div>
              <div className='stepNameGuide'>Before Event</div>
            </div>
            <div className='actionSectionGuide'>
              <div className='wrapperActionNameGuide'>
                <img
                  src={categoriesIcon}
                  alt='Tickets Distribution Icon'
                  className='actionIconsGuide'
                />
                <div className='actionNameGuide'>Tickets Distribution</div>
              </div>

              <div className='actionDesctiptionGuide'>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                <br />
                elit, sed do eiusmod tempor incididunt.
              </div>
              <div className='linkGuide'>
                View tickets
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
              <div className='linkGuide'>
                Gift tickets
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
              <div className='linkGuide'>
                Add promote
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
            </div>
            <div className='actionSectionGuide'>
              <div className='wrapperActionNameGuide'>
                <img
                  src={assignedSeatingIcon}
                  alt='assigned Seating Icon'
                  className='actionIconsGuide'
                />
                <div className='actionNameGuide'>Assigned and/or Table Seating</div>
              </div>

              <div className='actionDesctiptionGuide'>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                <br />
                elit, sed do eiusmod tempor incididunt.
              </div>
              <div className='linkGuide'>
                Configure tables
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
              <div className='linkGuide'>
                Assigned and/or Table Seating
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
            </div>
          </div>
          <div>
            <img src={arrow} alt='arrow' className='borderArrowGuide' />
            <div className='borderLineGuide' />
          </div>
          <div className='stepSectionGuide'>
            <div className='stepTitleGuide'>
              <div className='stepNumberGuide'>2.</div>
              <div className='stepNameGuide'>During Event</div>
            </div>
            <div className='actionSectionGuide'>
              <div className='wrapperActionNameGuide'>
                <img
                  src={scannerIcon}
                  alt='scanner Icon'
                  className='actionIconsGuide'
                />
                <div className='actionNameGuide'>Scan Tickets</div>
              </div>

              <div className='actionDesctiptionGuide'>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                <br />
                elit, sed do eiusmod tempor incididunt.
              </div>
              <div className='linkGuide'>
                Activate Scanners
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
            </div>
          </div>
          <div>
            <img src={arrow} alt='arrow' className='borderArrowGuide' />
            <div className='borderLineGuide' />
          </div>
          <div className='stepSectionGuide'>
            <div className='stepTitleGuide'>
              <div className='stepNumberGuide'>3.</div>
              <div className='stepNameGuide'>After Event</div>
            </div>
            <div className='actionSectionGuide'>

              <div className='actionDesctiptionGuide'>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                <br />
                elit, sed do eiusmod tempor incididunt.
              </div>
              <div className='linkGuide'>
                Review details
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
            </div>
            <div className='actionSectionGuide'>
              <div className='wrapperActionNameGuide'>
                <img
                  src={financeReport}
                  alt='finance Report'
                  className='actionIconsGuide'
                />
                <div className='actionNameGuide'>Online Purchase details</div>
              </div>

              <div className='actionDesctiptionGuide'>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                <br />
                elit, sed do eiusmod tempor incididunt.
              </div>
              <div className='linkGuide'>
                View report
                <img src={arrow} alt='arrow' className='linkGuideArrow' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TicketGuide
