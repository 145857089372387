import React from 'react'
import OverflowTooltip from '../OverflowTooltip'
import './index.styl'

const ResponsiveCards = ({ data = {} }) => {
  if (!Object.entries(data).length) {
    return null
  }
  return (
    <div className='mobileTicketContainer'>
      {data.map(({ title, text }, index) => (
        <div key={index} className='ticketMobileItem'>
          <div className='dataLabel'>{title}</div>
          <div className='dataTextContainer'>
            <OverflowTooltip title={text} trigger='hover'>
              <div className='dataText'>{text}</div>
            </OverflowTooltip>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ResponsiveCards
