/* eslint-disable indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useContext, useEffect } from 'react'
import { Form, Input, InputNumber, DatePicker, Checkbox, Radio, Divider, Button } from 'antd'
import moment from 'moment'
import { minimumPriceValidator } from 'modules/utils/validators'
import { CreateEventContext } from '../index'
import { getCurrencySymbolByCode, colors, handleConfirmModal } from 'utils'
import TooltipInfo from '../TooltipInfo'
import calendarIcon from 'images/calendarIcon.svg'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import './index.styl'
// import Subpromoters from './Subpromoters'
// import { useSelector } from 'react-redux'
// import isInteger from 'lodash/isInteger'

const { Item } = Form

export const privacyOptionsLabel = () => (
  <TooltipInfo
    className='privacyLabel'
    text='Privacy options'
    info='The privacy options determine who can see your event. If it is a Public event it is available on the main page for anyone to purchase. If it is Invite Only, the only way to get tickets is by Gifting, Direct Sale or sharing the event link.'
  />
)
const isStream = (namePrefix, eventType) => namePrefix === 'streamingCategory' || eventType === 'stream' && namePrefix === 'defaultCategory'

const TicketCategory = ({ listItem, onRemove, namePrefix, ticketData, isDefault = false, stream }) => {
  const { form, currentEventDetails, eventType, currency } = useContext(CreateEventContext)
  const [isEarlyBird, setIsEarlyBird] = useState(ticketData?.isEarlyBird || false)
  const [isStreamingCat, setIsStreamingCat] = useState(isStream(namePrefix, eventType))
  // const [isSubpromoters, setIsSubpromoters] = useState(!!ticketData?.subpromoters?.length || false)
  const [quantity, setQuantity] = useState(ticketData?.quantity || 0)
  // const [isAddSubpromoter, setIsAddSubpromoter] = useState(false)
  const [streamType, setStreamType] = useState(ticketData?.ticketType || 'PPV Live Stream')
  const ticketLifeFeeValue = form.getFieldValue('ticketLifeFee')
  const valid = namePrefix === 'streamingCategory' && ticketLifeFeeValue === 'Private Live Stream'
  // const { user } = useSelector(state => state.session)
  useEffect(() => {
    setIsStreamingCat(isStream(namePrefix, eventType))
  }, [eventType, namePrefix])

  const onRemoveCategory = index => {
    handleConfirmModal('Are you sure you want to remove this category', () => onRemove(index))
  }

  // const valuesQuantity = form.getFieldValue(namePrefix)

  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)

  const getItemPrefixedName = name => listItem ? ['categories', namePrefix, name] : [namePrefix, name]
  const isDateDisabled = currentDate => form.getFieldValue('timeStart')
    ? moment().add(-1, 'days') >= currentDate || moment(form.getFieldValue('timeStart')) <= currentDate
    : currentDate
  // const onChangeCurrency = ({ target: { value } }) => setCurrency(value)
  const privacyOptionsProps = () => namePrefix || !!currentEventDetails ? {} : { initialValue: 'visible' }

  // const initialSub = {}
  // const handleIsSubpromoters = useCallback(async () => {
  //  setIsSubpromoters(!isSubpromoters)
  //  const values = form.getFieldValue(namePrefix)
  //  if (!values) {
  //    const categories = form.getFieldValue('categories')
  //    categories[namePrefix].subpromoters = [initialSub]
  //  }
  //  if (!!values && !Array.isArray(values?.subpromoters)) {
  //    values.subpromoters = [initialSub]
  //    await form.setFieldsValue({
  //      namePrefix: values,
  //    })
  //  }
  // }, [isSubpromoters, namePrefix])

  const validQuality = v => ({
    validator () {
      if (!!v) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Enter quantity!'))
    },
  })

  // const integerValidator = message => {
  //  return {
  //    validator (_, value) {
  //      if (value && (!isInteger(value))) {
  //        return Promise.reject(new Error(message))
  //      }
  //      return Promise.resolve()
  //    },
  //  }
  // }
  // const quantityValidator = message => {
  //  return {
  //    validator (_, value) {
  //      if (+value <= 0) {
  //        return Promise.reject(new Error('Invalid number'))
  //      }
  //      if (value && valuesQuantity.quantity <= value) {
  //        return Promise.reject(new Error(message))
  //      }
  //      return Promise.resolve()
  //    },
  //  }
  // }
const handleStreamTypeChange = str => {
setStreamType(str)
form.setFieldsValue({
        [namePrefix]: { ticketType: str },
      })
  if (str === 'Private Live Stream') {
    form.setFieldsValue({
        [namePrefix]: { quantity: 0 },
      })
  } else {
     form.setFieldsValue({
        [namePrefix]: { quantity: 10000 },
      })
  }
}
  useEffect(() => {
    if (streamType === 'PPV Live Stream' && namePrefix === 'streamingCategory') {
 form.setFieldsValue({
          [namePrefix]: { quantity: 10000 },
        })
} else {
  form.setFieldsValue({
          [namePrefix]: namePrefix === 'defaultCategory' ? {} : { quantity: quantity },
        })
}
  }, [quantity])
  return (
    <div className='categoryWrapper'>
      {
        namePrefix === 'streamingCategory' &&
          <Item
            className='choose'
            name='ticketLifeFee'
            initialValue={streamType}
            label='Choose Live Stream Type'
            rules={[{ required: true, message: 'Enter ticket life fee' }]}
          >
            <Radio.Group disabled={ticketData} onChange={e => handleStreamTypeChange(e.target.value)} className={`streamingCategory ${valid && 'striming'}`}>
              <Radio checked={streamType} style={{ marginRight: '20px' }} value='Private Live Stream'>
                <div> Private Live Stream</div>
                <div className='textStream'>You can distribute <br /> these tickets for free</div>
              </Radio>
              <Radio checked={!streamType} value='PPV Live Stream'>
                <div>  PPV Live Stream</div>
                <div className='textStream'>You can sell these <br /> tickets out</div>
              </Radio>
            </Radio.Group>
          </Item>
      }
      <Item
        name={[namePrefix, 'bgColor']}
        label={`Choose background color for ${namePrefix === 'streamingCategory' ? streamType : 'General Admission'} `}
        className='item-colorPicker'
        rules={[{ required: true, message: 'Choose background color' }]}
      >
        <Radio.Group className='radio-colors'>
          {colors.map(color => <Radio key={color} value={color} style={{ backgroundColor: color }} />)}
        </Radio.Group>
      </Item>
      <Divider />

      <Item
        rules={[{ required: true, message: 'Enter Category name' }]}
        name={[namePrefix, 'ticketType']}
        label='Category name'
      >
        <Input placeholder='Enter Category name' defaultValue={streamType} className='input' />
      </Item>

      {streamType !== 'Private Live Stream' &&
        <>{privacyOptionsLabel()}
          <Item
            rules={[{ required: true, message: 'Choose Privacy Option' }]}
            name={[namePrefix, 'privacyOption']}
            className='no_margin item_radio-group'
            {...privacyOptionsProps()}
          >
            <Radio.Group>
              <Radio value='visible'>Visible to Public</Radio>
              <Radio value='invite'>Invite Only</Radio>
            </Radio.Group>
          </Item>
        </>}
      {/* {((eventType === 'stream' && namePrefix === 'defaultCategory') || (namePrefix === 'streamingCategory')) && ( */}
      {/*  <> */}
      {/*    <Divider /> */}
      {/*    <Item */}
      {/*      name={[namePrefix, 'venmo']} */}
      {/*      label='Venmo' */}
      {/*      rules={[ */}
      {/*        { */}
      {/*          required: eventType === 'stream' || namePrefix === 'streamingCategory', */}
      {/*          message: 'Enter Venmo', */}
      {/*        }, */}
      {/*      ]} */}
      {/*    > */}
      {/*      <Input placeholder='Enter Venmo' /> */}
      {/*    </Item> */}
      {/*  </> */}
      {/* )} */}
      {/* {isDefault && streamType !== 'Private Live Stream' && (
        <>
          <Divider />
          <Item
            className='item currencySelector'
            name='currency'
            label='Currency Selector'
            initialValue={ticketData?.currency || currency}
            rules={[{ required: true }]}
          >
            <Radio.Group disabled={eventID} onChange={onChangeCurrency}>
              {Object.keys(currencyCodes).map(k => (
                <Radio key={k} value={currencyCodes[k]?.code}>
                  {`${currencyCodes[k]?.label} (${currencyCodes[k].symbol})`}
                </Radio>
              ))}
            </Radio.Group>
          </Item>
        </>
      )} */}
      <Divider />
      <div className='item-group group-2'>
        {!valid &&
          <Item
            name={[namePrefix, 'price']}
            label={`Price (${getCurrencySymbolByCode(currency)})`}
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
          >
            <InputNumber placeholder='0' className='input fullWidth' min={0} />
          </Item>}
        {streamType === 'Private Live Stream'
          ? (
            <div className={valid && namePrefix === 'streamingCategory' && 'castomQuantity'}>
              <Button
                disabled={ticketData?.isPrivateStream}
                onClick={() => setQuantity(quantity === 0 ? 0 : quantity - 25)}
                className='minus'
                type='button'
              >
                <MinusOutlined />
              </Button>
              <Item
                style={{ position: 'relative' }}
                rules={[validQuality]}
                name={[namePrefix, 'quantity']}
                initialValue={ticketData?.quantity}
                className='inputWidth'
                label='Quantity'
              >
                <InputNumber
                  className='input fullWidth'
                  placeholder='0'
                  min={0}
                  disabled={ticketData?.isPrivateStream}
                />
              </Item>
              <Button
                disabled={ticketData?.isPrivateStream}
                onClick={() => setQuantity(quantity + 25)}
                className='plus'
                type='button'
              >
                <PlusOutlined />
              </Button>
            </div>
          ) : (
            <Item
              style={{ position: 'relative' }}
              rules={[{ required: true, message: 'Enter quantity' }]}
              name={[namePrefix, 'quantity']}
              initialValue={(isStreamingCat || valid) ? 10000 : ''}
              label='Quantity'
            >
              <InputNumber
                className='input fullWidth'
                disabled={isStreamingCat}
                placeholder='0'
                min={0}
              />
            </Item>
          )}

      </div>
      {streamType === 'PPV Live Stream' &&
        <>
          <Divider />
          <Item
            name={[namePrefix, 'isEarlyBird']}
            className='item-checkbox'
            valuePropName='checked'
          >
            <Checkbox checked={isEarlyBird} onChange={onCheckEarlyBird}>Early Bird Special</Checkbox>
          </Item>
          {isEarlyBird && (
            <div className='item-group group-2'>
              <Item
                rules={[{ required: true, message: 'Enter Early Bird date' }]}
                name={[namePrefix, 'earlyBirdDate']}
                suffixIcon={<img src={calendarIcon} alt=' ' />}
                className='inputDateMargin'
                label='Select date'
              >
                <DatePicker disabledDate={isDateDisabled} suffixIcon={<img src={calendarIcon} alt=' ' />} />
              </Item>
              <Item
                name={[namePrefix, 'earlyBirdPrice']}
                dependencies={[getItemPrefixedName('price')]}
                label={`Price (${getCurrencySymbolByCode(currency)})`}
                rules={[
                  { required: true, message: 'Enter price' },
                ]}
              >
                <InputNumber placeholder='0' className='input fullWidth' min={0} />
              </Item>
            </div>
          )}
        </>}
      <Divider />
      {/* {!valid &&
        <Item
          className='item-checkbox'
          valuePropName='checked'
        >
          <Checkbox checked={isSubpromoters} onChange={handleIsSubpromoters}>Subpromoters</Checkbox>
        </Item>} */}
      {/* {isSubpromoters && ticketData?.subpromoters?.length <= 0 || isSubpromoters && ticketData?.subpromoters.length === undefined ? (
        <div className='detailsWrapper'>
          <List key={`${listItem?.key}${namePrefix}`} name={[namePrefix, 'subpromoters']}>
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, i) => {
                  return (
                    <Subpromoters
                      catQuantity={valuesQuantity.quantity}
                      field={{ ...field, key: `${field?.name}${namePrefix}` }}
                      key={[field?.name, namePrefix]}
                      namePrefix={namePrefix}
                      onRemove={remove}
                    />
                  )
                })}
                <div id='addCategory' className='btn' style={{ marginBottom: '15px', marginTop: '16px' }} onClick={() => add()}>Add New Subpromoter</div>
              </div>
            )}
          </List>
        </div>
      ) : (
        <>
          {isSubpromoters &&
            <>
              {ticketData?.subpromoters?.map((i, index) => {
            return (
              <div
                key={i.subPromotersEmail}
              >
                <Item
                  style={{ width: '100%' }}
                  validateTrigger='onBlur'
                  initialValue={i.subPromotersEmail}
                  name={[namePrefix, index, 'subPromotersEmail']}
                  rules={[
          { required: true, message: 'Please enter email' },
          { type: 'email', message: 'Please enter correct email', transform: value => value?.trim() },
          notEqual(user.email, "You can't send invites to yourself"),
                  ]}
                  label='Email'
                >
                  <Input disabled placeholder='Enter email' className='input ' />
                </Item>
                <Item
                  style={{ width: '100%' }}
                  validateTrigger='onBlur'
                  normalize={value => value ? value.trim() : ''}
                  initialValue={i.vanityURL}
                  name={[namePrefix, index, 'vanityURL']}
                  dependencies={['email']}
                  label={'Subpromoter\'s vanity URL'}
                >
                  <Input disabled placeholder={'Subpromoter\'s vanity URL'} className='input ' />
                </Item>
                <Item
                  validateTrigger='onBlur'
                  rules={[
          { required: true, type: 'number', message: 'Please enter quantity' },
          integerValidator('Invalid number!'),
          quantityValidator('Subpromoters quantity exceeds category quantity'),
                  ]}
                  initialValue={i.subPromotersQuantity}
                  name={[namePrefix, 'subPromotersQuantity']}
                  label='Quantity'
                >
                  <InputNumber disabled placeholder='0' />
                </Item>
                <Divider />
              </div>
            )
          })}
              <div className='detailsWrapper'>
                <List key={`${listItem?.key}${namePrefix}`} name={[namePrefix, 'subpromoters']}>
                  {(fields, { add, remove }) => (
                    <div>
                      {fields.map((field, i) => {
                  return (
                    <>
                      {isAddSubpromoter &&

                        <Subpromoters
                          catQuantity={valuesQuantity.quantity}
                          field={{ ...field, key: `${field?.name}${namePrefix}` }}
                          key={[field?.name, namePrefix]}
                          namePrefix={namePrefix}
                          newTicket={!!ticketData}
                          onRemove={remove}
                        />}

                    </>
                  )
                })}
                      <div
                        id='addCategory' className='btn' style={{ marginBottom: '15px', marginTop: '16px' }} onClick={() => {
                        setIsAddSubpromoter(true)
                        add()
                        }}
                      >Add New Subpromoter</div>
                    </div>
            )}
                </List>
              </div>

            </>}
        </>
      )} */}
      {listItem && onRemove && namePrefix !== 'streamingCategory' && (
        <>
          <Divider />
          <div className='btnWrapper'>
            <div onClick={() => onRemoveCategory(namePrefix)} className='removeBtn'>Remove Ticket Category</div>
          </div>
        </>
      )}
    </div>
  )
}

export default TicketCategory
