import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { editBankDetails } from 'modules/session/session-actions'
import { CustomButton, ModalProfile } from 'components'
import { notification } from 'antd'
import c from 'classnames'
import './index.styl'

const BankDetails = () => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const { bankDetails } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const onChange = () => setVisible(!visible)
  const onChangeLoading = () => setLoading(!loading)
  const lastFourDigitsRoutingTransit = '...' + bankDetails?.routingNumber?.substr(-4)
  const lastFourDigitsAccountNumber = '...' + bankDetails?.accountNumber?.substr(-4)
  const Information = ({ item, index, lastIndex }) => (
    <div className={c('wrapperInformation', { border: index !== lastIndex - 1 })}>
      <div className='left'>{item.title}</div>
      <div className='right'>{item.text}</div>
    </div>
  )
  const informations = [
    {
      title: 'Routing Transit #',
      text: bankDetails?.routingNumber ? lastFourDigitsRoutingTransit : '',
    },
    {
      title: 'Account #',
      text: bankDetails?.accountNumber ? lastFourDigitsAccountNumber : '',
    },
  ]

  const onFinish = form => {
    const { routing, account } = form
    if (!routing || !account) return
    onChangeLoading()
    const obj = { routing, account }
    const validRouting = /^\d+$/.test(String(routing))
    const validAccount = /^[a-zA-Z0-9\-]*$/.test(String(account))
    if (validRouting && validAccount) {
      dispatch(editBankDetails(obj)).then(() => {
        setLoading(false)
        onChange()
      }).catch(() => setLoading(false))
    } else if (!validRouting) {
      setLoading(false)
      return notification.warning({ message: 'Please enter valid Routing Transit number' })
    } else if (!validAccount) {
      setLoading(false)
      return notification.warning({ message: 'Please enter valid Account number' })
    }
  }

  return (
    <div className='sectionProfile'>
      <div className='head'>
        <div className='titleSection'>Bank Details</div>
        <CustomButton text='Edit' type='white' onClick={onChange} />
      </div>
      <div className='body'>
        {informations.map((i, ind) => <Information item={i} index={ind} key={i.title} lastIndex={informations.length} />)}
      </div>

      <ModalProfile visible={visible} onChange={onChange} onFinish={onFinish} name='bankDetails' loading={loading} />
    </div>
  )
}

export default BankDetails
