import React, { useState, useContext, useEffect } from 'react'
import {
  Form,
  Switch,

  Divider,
} from 'antd'

import { CreateMemberLyfeContext } from '../../../../..'
// import TooltipInfo from '../../../../../../CreateEventV2/TooltipInfo'

import EventForm from './EventForm'
import './index.styl'

const { Item, List } = Form

const UpcomingEvents = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)

  const [upcomingEventsSwitch, setUpcomingEventsSwitch] = useState(false)
  const [upcomingEvents, setUpcomingEvents] = useState(form.getFieldValue('upcomingEvents') || [])
  // const mobile = window.innerWidth <= 730
  // const tooltipTitle = `Charity Raffles are limited to $30,000 in total ticket sales and $100 max price per ticket

  // Ticket Lyfe charges for Charity Raffle Tickets are $1 for Raffles priced up to $50 and $2 for higher priced Raffle Tickets

  // Do not pick Charity Raffles if you are doing a regular event with Random Draw Prizes--Because you can do a Random Draw on ANY Ticket Lyfe Event, the Charity Raffle limits above will not apply, and Ticket Lyfe treat your event as an ordinary Ticketed Event`

  // const additionalTooltip = (
  //  <TooltipInfo
  //    className='tooltipTitle'
  //    text={mobile ? '' : 'Additional info'}
  //    info={tooltipTitle}
  //  />
  // )

  useEffect(() => {
    if (!!currentPageDetails?.upcomingEvents?.length) {
      setUpcomingEventsSwitch(true)
      setUpcomingEvents(currentPageDetails.upcomingEvents)
      form.setFieldsValue({
        upcomingEvents: currentPageDetails.upcomingEvents,
      })
    }
  }, [currentPageDetails, form])

  useEffect(() => {
    if (!upcomingEvents.length) {
      setUpcomingEventsSwitch(false)
      form.setFieldsValue({
        upcomingEventsSwitch: false,
      })
    }
  }, [upcomingEvents, form])

  const handleSwitchChange = checked => {
    setUpcomingEventsSwitch(checked)
    if (checked && !upcomingEvents.length) {
      const newUpcomingEvents = [{}]
      setUpcomingEvents(newUpcomingEvents)
      form.setFieldsValue({
        upcomingEvents: newUpcomingEvents,
      })
    }
  }

  const getUpcomingEventsData = idx => {
    return upcomingEvents[idx]
  }

  return (
    <div className='upcomingEventsWrapper radioItem'>
      <div className={upcomingEventsSwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='upcomingEventsSwitch' valuePropName='checked' className='upcomingEvents'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Upcoming Events</div>
        </div>
        {/* <div className='tooltipWrapper'>{additionalTooltip}</div> */}
      </div>
      {upcomingEventsSwitch && (
        <List name='upcomingEvents'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <EventForm
                  form={form}
                  currentPageDetails={currentPageDetails}
                  categoryData={getUpcomingEventsData(fieldKey)}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedUpcomingEvents = form.getFieldValue('upcomingEvents')
                    setUpcomingEvents(updatedUpcomingEvents)
                    if (updatedUpcomingEvents.length === 0) {
                      form.setFieldsValue({
                        upcomingEventsSwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory'
                className='addCategoryBtn'
                onClick={() => {
                  add()
                  const updatedUpcomingEvents = form.getFieldValue('upcomingEvents')
                  setUpcomingEvents(updatedUpcomingEvents)
                }}
              >Add Another Event
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default UpcomingEvents
