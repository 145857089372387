import React from 'react'
import { Select } from 'antd'
import RedArrow from 'images/RedArrow.svg'
import c from 'classnames'
import './index.styl'

const { Option } = Select

const items = [
  { name: 'Sort by Name', key: 'asc' },
  { name: 'Sort by Name', key: 'desc' },
]

const SelectWithoutBorder = ({ setSortTag, sortTag, disabled }) => {
  return (
    <Select
      getPopupContainer={node => node?.parentNode}
      className='SelectWithoutBorder'
      dropdownClassName='dropdownWithoutBorder'
      defaultValue={sortTag}
      onChange={setSortTag}
      disabled={disabled}
      showArrow={false}
    >
      {items.map(i => <Option key={i.key} value={i.key}>{i.name} <img src={RedArrow} className={c('sort-arrow', { 'sort-arrow-up': i.key?.includes('asc') })} /></Option>)}
    </Select>
  )
}

export default SelectWithoutBorder
