import React from 'react'
import horizontalSection from 'images/horizontalSection.png'
import verticalSection from 'images/verticalSection.png'
import './index.styl'
const TooltipSeating = () => {
  return (
    <div className='wrapperTooltip'>
      <div>
        <div className='wrapperTitle'>
          <div className='titleTooltip '>Alphabetic</div>
          <div className='titleTooltip '>Numeric</div>
        </div>
        <div className=''>
          <img src={horizontalSection} style={{ marginRight: '32px' }} alt='horizontalPng' />
          <img src={verticalSection} alt='verticalPng' />
        </div>
      </div>
    </div>
  )
}
export default TooltipSeating
