import api from './axios.v2.withoutTransform'

const addSection = (id, obj) => {
  return api(`seats/templates/${id}/sections `, 'POST', obj)
}
const addNewTable = (id, obj) => {
  return api(`seats/templates/${id}/groups  `, 'POST', obj)
}

const editSeat = (id, obj) => {
  return api(`seats/templates/${id}/seatings`, 'PUT', obj)
}
const editSection = (id, obj) => {
  return api(`seats/templates/${id}/sections `, 'PUT', obj)
}
const editRowTable = (id, obj) => {
  return api(`seats/templates/${id}/groups  `, 'PUT', obj)
}
const editCategory = (id, obj) => {
  return api(`seats/templates/${id}/categories  `, 'PUT', obj)
}
const editImage = (id, obj) => {
  return api(`seats/templates/${id}/image  `, 'PUT', obj)
}

const deleteTemplateSection = (id, obj) => {
  return api(`seats/templates/${id}/sections `, 'DELETE', obj)
}
const deleteTemplateRow = (id, obj) => {
  return api(`seats/templates/${id}/groups `, 'DELETE', obj)
}

export default { editSeat, editSection, editRowTable, editCategory, editImage, addSection, addNewTable, deleteTemplateSection, deleteTemplateRow }
