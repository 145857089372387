import React from 'react'
import { Modal, Form, Input } from 'antd'

import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form

const ModalEditTemplates = ({ visible = false, templateName, onChange, onFinish, name, loading }) => {
  const attr = { visible, onCancel: onChange, width: 472, maxWidth: 472, footer: null, className: 'wrapperModalEditTemplates', destroyOnClose: true }
  const [form] = Form.useForm()

  const textSubmit = 'Save'
  const initialValues = {
    newName: templateName,
  }
  const validateTemplateName = {
    validator (_, value) {
      if (value && value < 3) {
        return Promise.reject(new Error('Template Name must be at least 3 characters'))
      }
      return Promise.resolve()
    },
  }
  return (
    <Modal title='Edit Template' {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish} initialValues={initialValues}>
        <Item name='newName' label='Template name' className='item templateName' rules={[{ required: true, message: 'Please enter your Template Name' }, validateTemplateName]} validateTrigger='onBlur'>
          <Input className='darkBg' maxLength={32} />
        </Item>
        <div className='wrapperActions'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditTemplates
