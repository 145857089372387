import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Input, InputNumber, Select } from 'antd'
import { notEqual, minLength } from 'modules/utils/validators'
import { getEventDetails } from 'modules/events/events-actions'
import ModalBuyPrivateTickets from '../ModalBuyPrivateTickets'
import isInteger from 'lodash/isInteger'
import get from 'lodash/get'
import DropDownIcon from 'images/DropDownIcon.svg'
import './index.styl'

const { Item, useForm } = Form
const { Option } = Select

const getText = actionType => {
  switch (actionType) {
    case 'gift':
      return { title: 'Gift Tickets', okText: 'Gift' }
    case 'sell':
      return { title: 'Sell Tickets', okText: 'Sell' }
    case 'promote':
      return { title: 'Sub-promote tickets', okText: 'Sub-promote' }
  }
}

const ModalTicketAction = ({
  actionType = 'gift',
  visible,
  eventDetails,
  categories = [],
  bundles = [],
  updateVisible,
  submitForm,
  currencySymbol,
  loading,
  urls,
  giftActiveTable,
  eventID,
  giftActiveTab,
  updateTicketsOnPage,
  openModalForm,
}) => {
  const combinedCategories = [...categories, ...(bundles || [])]
  const { user } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const [actionForm] = useForm()
  const [price, setPrice] = useState(0)
  const [defaultPrice, setDefaultPrice] = useState(0)
  const [fieldForm, setFieldForm] = useState(null)
  const [giftCheckoutForm, setGiftCheckoutForm] = useState(false)
  const [quantity, setQuantity] = useState(0)
  useEffect(() => {
    if (loading && actionType === 'gift') {
      dispatch(getEventDetails(eventID))
    }
    setDefaultPrice(combinedCategories[0]?.price)
  }, [eventDetails?.tickets])
  const maxValidator = (field, message) => {
    return {
      validator (_, value) {
        const catId = actionForm.getFieldValue('category')
        const ticket = combinedCategories.find(({ id, _id }) => id || _id === catId)
        if (value && (value > get(ticket, field, 0))) {
          return Promise.reject(new Error(message))
        }
        if (value && value.toString().includes('.')) {
          const decimalPart = value.toString().split('.')[1]
          if (decimalPart.length > 2) {
            return Promise.reject(new Error('Price should have at most two decimal digits'))
          }
        }
        if (value <= 0) {
          return Promise.reject(new Error('Price should be more than 0'))
        }
        return Promise.resolve()
      },
    }
  }

  const customQuantityValidator = () => ({
    validator (_, value) {
      const catId = actionForm.getFieldValue('category')
      const ticket = combinedCategories.find(({ id, _id }) => id || _id === catId)
      const ticketCountAvailable = get(ticket, 'ticketCount.available', null)

      if (value && ticketCountAvailable !== null && value > ticketCountAvailable) {
        return Promise.reject(new Error('Quantity shouldn\'t exceed tickets you have'))
      }

      return Promise.resolve()
    },
  })

  const maxValidatorCashCheck = message => {
    return {
      validator (_, value) {
        if (value <= 0) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }
  const noteValidator = message => {
    return {
      validator (_, value) {
        if (value?.length > 50) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }

  const integerValidator = message => {
    return {
      validator (_, value) {
        if (value && (!isInteger(value))) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }

  const validateUrls = ({ setFieldsValue }) => ({
    validator (_, value) {
      const founded = urls.find(({ email }) => email === value)
      if (founded) {
        const { uniqueName } = founded
        setFieldsValue({ uniqueName })
      }
      return Promise.resolve()
    },
  })

  const onFinish = values => {
    const currentCategory = categories?.find(obj => obj._id === values?.category)
    if (giftActiveTab === 'gift tickets' && currentCategory?.isStreaming && !currentCategory?.isPrivateStream) {
      setFieldForm(values)
      setGiftCheckoutForm(true)
    } else {
      actionForm.resetFields()
      submitForm(values)
      // dispatch(getEventDetails(eventID))
    }
  }
  const giftTicket = token => {
    submitForm(fieldForm, token)
    actionForm.resetFields()
  }
  const resetField = () => {
    actionForm.resetFields()
  }

  const currenCategoryesId = combinedCategories.map(i => i.id || i._id)
  const firstCategoryId = currenCategoryesId.shift()
  return (
    <>
      <Modal
        title={getText(actionType).title}
        visible={visible}
        onCancel={() => !loading && updateVisible()}
        okText={getText(actionType).okText}
        className='inviteForm'
        okButtonProps={{
          form: actionType === 'gift' ? 'giftTicket' : 'sellTicket',
          key: 'submit',
          htmlType: 'submit',
          disabled: loading,
          loading,
        }}
        cancelButtonProps={{ disabled: loading }}
        closable={!loading}
        destroyOnClose
      >
        <Form
          layout='vertical'
          onFinish={onFinish}
          id={actionType === 'gift' ? 'giftTicket' : 'sellTicket'}
          form={actionForm}
        >
          <Item
            name='email'
            label='Enter email'
            className='item'
            colon={false}
            rules={[
              { required: true, message: 'Please enter email' },
              { type: 'email', message: 'Please enter correct email', transform: value => value?.trim() },
              actionType === 'promote' && notEqual(user.email, 'You can\'t send invites to yourself'),
              validateUrls,
            ]}
            dependencies={['uniqueName']}
            normalize={value => value.trim()}
            validateTrigger='onBlur'
          >
            <Input className='darkBg' placeholder='email@email.com' />
          </Item>
          {actionType !== 'promote' && (
            <>
              <Item
                rules={[{ required: true, message: 'Please enter phone number' }, minLength]}
                validateTrigger='onBlur'
                label='Phone number'
                className='item'
                colon={false}
                name='mobile'
              >
                <Input className='darkBg' prefix='+1' placeholder='234 567 890' type='number' />
              </Item>
              <Item
                name='notes'
                label='Note'
                className='item'
                colon={false}
                rules={[
                  { required: false },
                  noteValidator('Note must be lower than 50 symbol'),
                ]}
                validateTrigger='onBlur'
              >
                <Input placeholder='Your notes' className='darkBg' />
              </Item>
            </>
          )}

          <Item
            name='category'
            label='Ticket category'
            className='item'
            initialValue={firstCategoryId}
            validateTrigger='onBlur'
            colon={false}
          >
            <Select
              suffixIcon={<img src={DropDownIcon} alt=' ' />}
              className='darkBg modalSelect'
              onChange={value => {
                const ticket = combinedCategories.find(({ id, _id }) => id || _id === value)
                setDefaultPrice(ticket?.price)
              }}
            >
              {combinedCategories.map(i => {
                return (<Option key={i.id || i._id} value={i.id || i._id}>{i.name || i.ticketName || i.bundleName}</Option>)
              })}
            </Select>
          </Item>
          {actionType === 'promote' &&
            <Item
              rules={[validateUrls]}
              name='uniqueName'
              label="Enter subpromoter's vanity URL"
              className='item'
              colon={false}
              normalize={value => value ? value.trim() : ''}
              dependencies={['email']}
              validateTrigger='onBlur'
            >
              <Input className='darkBg' />
            </Item>}
          <Item>
            {actionType === 'sell' &&
              <Item
                name='price'
                label='Price/Ticket'
                className='item half'
                style={{ display: 'inline-block', marginRight: '20px' }}
                validateTrigger='onBlur'
                colon={false}
                rules={giftActiveTable.includes('direct') ? [
                  { required: true, type: 'number', message: 'Please enter price' },
                  maxValidator('price', 'Price should be lower than ticket price'),
                ] : [
                  { required: true, type: 'number', message: 'Please enter price' },
                  maxValidatorCashCheck('Price should be more than 0'),
                ]}
              >
                <InputNumber
                  placeholder={defaultPrice}
                  className='darkBg'
                  onChange={value => setPrice(value)}
                />
              </Item>}
            <Item
              name='quantity'
              label='Quantity'
              className='item half'
              style={{ display: 'inline-block' }}
              validateTrigger='onBlur'
              colon={false}
              rules={[
                { required: true, type: 'number', message: 'Please enter quantity' },
                integerValidator('Invalid number!'),
                customQuantityValidator(),
              ]}
            >
              <InputNumber
                placeholder='0'
                min={1}
                className='darkBg'
                onChange={value => setQuantity(value)}
              />
            </Item>

            {actionType === 'sell' &&
              <Item
                label={`Total ${currencySymbol}`}
                className='item half'
                style={{ display: 'inline-block', marginLeft: '20px' }}
                colon={false}
              >
                <Input
                  className='darkBg'
                  value={(price * quantity).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                  placeholder={`${currencySymbol} 0`}
                  readOnly
                />
              </Item>}
          </Item>
        </Form>
      </Modal>
      {giftCheckoutForm && giftActiveTab === 'gift tickets' &&
        <ModalBuyPrivateTickets
          img={eventDetails?.imageUrl?.thumbnail1}
          quantity={fieldForm.quantity}
          eventDetails={eventDetails}
          donation={fieldForm.quantity}
          eventID={eventID}
          currencySymbol='$'
          visible={giftCheckoutForm}
          openModalForm={openModalForm}
          giftActiveTable={giftActiveTable}
          giftActiveTab={giftActiveTab}
          updateTicketsOnPage={updateTicketsOnPage}
          giftValues={fieldForm}
          updateVisible={updateVisible}
          resetField={resetField}
          inviteType='Gift'
          setVisible={setGiftCheckoutForm}
          action={giftTicket}
          price={fieldForm.quantity * 1.50}
        />}
    </>
  )
}

export default ModalTicketAction
