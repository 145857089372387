import React from 'react'
// import { getUtcOffsetDate } from 'utils'
import locationIcon from 'images/memberLocationIcon.svg'
import mobileIcon from 'images/memberMobileIcon.svg'
import emailIcon from 'images/memberEmail.svg'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import './index.styl'
import SliderMemberLyfeHome from './SliderMemberLyfeHome'
import { CustomButton } from '../../../components'
import Body from '../Body'

const Header = ({ vanityId }) => {
  const { currentPageDetails: { name, description, locations, mobile, promoterEmail, images, additionalImages, announcements, sessions, passes, subscriptions, items, workingHours, upcomingEvents, additionalChats } } = useSelector(state => state.memberships)
  const { user } = useSelector(state => state.session)
  const { id, userId } = useParams()
  const history = useHistory()
  const Item = ({ icon, title = '', text, textClass = '' }) => (
    <li className='item'>
      <div className='leftSide'>
        <img src={icon} alt=' ' />
        <span>{title}</span>
      </div>
      <div className={['text', textClass].join(' ')}>{text}</div>
    </li>
  )
  const sliderImages = Array.isArray(additionalImages) ? [images, ...additionalImages] : [images]
  const mobileSize = window.innerWidth <= 730
  const eventID = vanityId.eventID || id
  const getUserID = vanityId.userID || userId
  const handleGetMembership = () => {
    if (!user) {
      history.push(`/auth/sign-in?redirectTo=/buyPage/${eventID}/${getUserID}`)
    } else {
      history.push(`/buyPage/${eventID}/${getUserID}`)
      window.scrollTo(0, 0)
    }
  }
  const handleEmailTo = () => {
    history.push(`/send-mail?eventID=${eventID}`)
  }
  return (
    <div className='memberHeaderWrapper'>
      <div className='contentImageWrapper'>
        <div className='contentWrapper'>
          <div className='leftSideContent'>
            <div className='name'>{name}</div>
            {mobileSize && <SliderMemberLyfeHome sliderImages={sliderImages} />}
            {description && <div className='note'>{description}</div>}
            <ul className='wrapperItems'>
              <div>
                {locations?.map((location, index) => (
                  <Item
                    key={index}
                    text={<a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(location)}`} target='_blank' rel='noopener noreferrer'>{location}</a>}
                    title='Location'
                    icon={locationIcon}
                  />
                ))}
                <Item
                  text={promoterEmail}
                  title='Manager Email'
                  icon={emailIcon}
                />
              </div>
              <div>
                <Item
                  text={mobile}
                  title='Phone Number'
                  icon={mobileIcon}
                />
                {/* {timeZone && timeZone !== 'undefined' && (
                <Item
                  text={`${timeZone}`}
                  title='Time'
                  icon={clock}
                />
              )} */}
              </div>
            </ul>
            <div className='btnMemberWrpapper'>
              <CustomButton
                text='Get Membership'
                className='getMembership'
                type='dark'
                onClick={handleGetMembership}
                disabled={!subscriptions?.length && !sessions?.length && !passes?.length && !items?.length}
              />
              <CustomButton
                text='Email To Manager'
                className='emailTo'
                type='dark'
                onClick={handleEmailTo}
              />
            </div>
          </div>
          {!mobileSize && <SliderMemberLyfeHome sliderImages={sliderImages} />}
        </div>
      </div>
      <Body id={eventID} currentUser={user?._id} userId={getUserID} announcements={announcements} sessions={sessions} subscriptions={subscriptions} passes={passes} upcomingEvents={upcomingEvents} items={items} workingHours={workingHours} additionalChats={additionalChats} />
    </div>
  )
}

export default Header
