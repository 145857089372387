import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  getPageDetails,
  checkMembershipMember,
} from 'modules/memberships/memberships-actions'
import { getEventIDByURL } from 'modules/events/events-actions'
import { MemberLyfeLayout } from 'components'
import './index.styl'
import Header from './Header'

const MemberPage = () => {
  const { id, customURL } = useParams()
  const { user } = useSelector(state => state.session)
  const [vanityId, setVanityId] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    const auth = !!user
    if (customURL) {
      dispatch(getEventIDByURL(customURL, 'page')).then(res => {
        setVanityId(res.data.data)
      })
    } else {
      dispatch(getPageDetails(id, auth))
      if (user) {
        dispatch(checkMembershipMember(id))
      }
    }
  }, [])

  return (
    <MemberLyfeLayout className='wrapperHome'>
      <Header vanityId={vanityId} />
    </MemberLyfeLayout>
  )
}

export default MemberPage
