import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getPublicVenueEvent, eventsSetPublicEvents } from 'modules/events/events-actions'
import { Layout, CustomButton } from 'components'
import Header from '../Home/Header'
import Body from '../Home/Body'
import '../Home/index.styl'

const limit = 20

const VenueHomePage = () => {
  const [loaded, setLoaded] = useState(false)
  const [loadingPagination, setLoadingPagination] = useState(true)
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(0)
  const [skip, setSkip] = useState(0)
  const dispatch = useDispatch()
  const params = useParams()

  const handleEndFetch = () => {
    setLoaded(true)
    setLoadingPagination(false)
  }

  const handleFetch = obj => {
    dispatch(getPublicVenueEvent(obj))
      .then(({ total, current }) => {
        setTotal(total)
        setCurrent(current)
        setSkip(obj.skip)
        handleEndFetch()
      })
      .catch(handleEndFetch)
  }

  useEffect(() => {
    handleFetch({ limit, skip, venue: params?.venueUrl })
    window.dataLayer.push({ event: 'Home' })
    return () => dispatch(eventsSetPublicEvents([]))
  }, [])

  const handleFetchMore = () => {
    setLoadingPagination(true)
    handleFetch({ limit, skip: skip + limit })
  }

  // const restLength = (total - publicEvents.length)

  return (
    <Layout className='wrapperHome'>
      <Header loaded={loaded} />
      <div className='container'>
        <Body loaded={loaded} />
        <div className='fetchMoreContainer'>
          {loaded && current < total && (
            <CustomButton
              disabled={current >= total || loadingPagination}
              loading={loadingPagination}
              onClick={handleFetchMore}
              // text={`Show More ${restLength > limit ? limit : restLength}`}
              text='Show More'
              type='dark'
            />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default VenueHomePage
