import React, { useEffect, useState } from 'react'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'
/* eslint-disable */
const VenmoPayment = ({ totalPrice, preorderCode, venmoPayment }) => {
  const [activeButton, setActiveButton] = useState(true)
  const handleApprove = (orderID, paymentID) => {
    venmoPayment(orderID, paymentID)
  }

  const InEligibleError = ({ text }) => {
    return (
      <h3 style={{ color: '#dc3545', textTransform: 'capitalize' }}>
        {text || 'The component is ineligible to render'}
      </h3>
    )
  }
  useEffect(() => {
    setActiveButton(true)
    setTimeout(function () {
      setActiveButton(false)
    }, 2000)
  }, [])
  return (
    <>
      {preorderCode ? (
        <PayPalScriptProvider
          options={{
            'client-id':
            process.env.REACT_APP_VENMO_CLIENT_ID,
            components: 'buttons,funding-eligibility',
            'enable-funding': 'venmo',
          }}
        >
          <PayPalButtons
            fundingSource="venmo"
            id="votebutton"
            disabled={activeButton}
            style={{ color: 'blue' }}
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: 'USD',
                      value: totalPrice.toFixed(2),
                    },
                    custom_id: preorderCode,
                  },
                ],
                application_context: {
                  payment_method: {
                    payee_preferred: 'IMMEDIATE_PAYMENT_REQUIRED',
                  },
                  shipping_preference: 'NO_SHIPPING',
                },
              })
            }}
            onApprove={async (data, actions) => {
              handleApprove(data.orderID, preorderCode)
            }}
             onError={(err) => {
              notification.warning({ message: err.message })
            }}
          >
            <InEligibleError text="Payment is available on Safari for iPhone and Chrome for Android." />
          </PayPalButtons>
        </PayPalScriptProvider>
      ) : null}
    </>
  )
}

export default VenmoPayment

// import React, { useEffect, useState, useRef } from 'react'
// import DropIn from 'braintree-web-drop-in-react'
// import { getTokenVenmo } from 'modules/payment/payment-actions'
// import { CustomButton } from 'components'
// import { events } from 'api'
// import { useDispatch } from 'react-redux'

// const VenmoPayment = ({ textBtn, onFinish, loading }) => {
//   // const { inviterID, _id } = useSelector(state => state.events.currentEvent)
//   const dispatch = useDispatch()
//   const [values, setValues] = useState({
//     clientToken: null,
//     instance: '',
//   })
//   const ref = useRef()
//   useEffect(() => {
//     getToken()
//   }, [])
//   const getToken = () => {
//     events.getVenmoToken().then(response => {
//       setValues({
//         ...values,
//         clientToken: response.data.data.clientToken,
//       })
//     })
//   }
//   const { clientToken, instance } = values

//   const onPurchase = () => {
//     instance.requestPaymentMethod().then(data => {
//       const nonce = data.nonce
//       dispatch(getTokenVenmo({ ...data, tokenNonce: nonce }))
//     },
//     )
//   }

//   return (
//     <>
//       {!clientToken ? (
//         <div>
//           <h1>Loading...</h1>
//         </div>
//       ) : (
//         <div>
//           <DropIn
//             ref={ref}
//             options={{
//               authorization: clientToken,
//               card: false,
//               venmo: {
//                 allowDesktop: true,
//                 paymentMethodUsage: 'multi_use',
//               },
//             }}
//             onPaymentMethodRequestable={onPurchase}
//             onInstance={instance => setValues({ ...values, instance: instance })}
//           />
//           {ref.current === undefined ? (<div> Please try another browser</div>)
//             : (
//               <CustomButton
//                 text={textBtn}
//                 type='dark'
//                 className='btn'
//                 htmlType='submit'
//                 onClick={onFinish}
//                 loading={loading}
//               />
//             )}
//         </div>
//       )}
//     </>
//   )
// }
// export default VenmoPayment
