import React from 'react'
import fiveStar from 'images/fiveStar.svg'

import './index.styl'

const SpecialRaffleDetails = () => {
  return (
    <div className='titleCategoryWrapperDetails'>
      <img src={fiveStar} alt='five star' className='fiveStarDetails' />
      <div className='titleCategory'><span className='firstText'>CHARITY</span><span className='secondText'>RAFFLE</span></div>
      <img src={fiveStar} alt='five star' className='fiveStarDetails' />
    </div>
  )
}

export default SpecialRaffleDetails
