import { communication } from 'api'
// import { PAYMENT_LOGIN_GUEST } from './payment-constants'
import { notification } from 'antd'

const msgError = error => {
  const message = error.response.data.message || error.response.data.error
  notification.warning({ message })
}

export const contactUs = obj => dispatch => communication.contactUs(obj)
  .then(() => {
    notification.success({ message: 'Message successfully sent' })
  })
  .catch(err => msgError(err))
