import React from 'react'
import { blockInvalidChar } from './../invalidChars'
import './index.styl'
import {
  Form,
  Select,
  InputNumber,
} from 'antd'
import { getRule } from './formHelpers'

const { Item } = Form
const { Option } = Select

const categories = [
  { key: 'VENUE', label: 'Ticketed Admission' },
  { key: 'STREAM', label: 'Livestream PPV' },
]

const MockTicketEventForm = ({ form }) => {
  return (
    <div className='MockTicketEventForm'>
      <Form
        form={form}
        className='form'
        layout='vertical'
        initialValues={{
          quantity: 100,
        }}
      >
        <div className='groupItems'>
          <Item
            rules={getRule('Event Type', 'eventType')}
            label='Event Type'
            name='eventType'
          >
            <Select placeholder='Select event type' listHeight={300}>
              {categories.map(({ key, label }) => (
                <Option key={key} value={key}>{label}</Option>
              ))}
            </Select>
          </Item>
          <Item
            rules={getRule('Ticket quantity', 'quantity')}
            label='Ticket quantity'
            name='quantity'
            onKeyDown={blockInvalidChar}
            onWheel={e => e.target.blur()}
          >
            <InputNumber type='number' placeholder='Enter ticket quantity' className='input' disabled />
          </Item>
        </div>
      </Form>
    </div>
  )
}

export default MockTicketEventForm
