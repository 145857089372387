import React from 'react'
import { Layout, BannerMockTicket } from 'components'
import Body from './Body'
import './index.styl'

const MockTicket = () => {
  return (
    <Layout className='wrapperHome'>
      <BannerMockTicket />
      <div className='container'>
        <Body loaded />
      </div>
    </Layout>
  )
}

export default MockTicket
