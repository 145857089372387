import React, { useState, useEffect } from 'react'
import {
  Form,
  Input,
  Switch,
} from 'antd'
import {
  uploadChatImage,
} from 'modules/memberships/memberships-actions'

import './index.styl'
import ImageUploader from '../../../../../ImageUploader'

const { Item } = Form

const ChatForm = ({ name, onRemove, form, categoryData }) => {
  const [makePrivate, setPrivate] = useState(form.getFieldValue('makePrivate') || false)
  const [imageUrl, setImageUrl] = useState({})

  const togglePrivateStatus = () => {
    setPrivate(!makePrivate)
  }

  useEffect(() => {
    const chatFormData = form.getFieldValue('additionalChats')
    const updatedChatFormData = chatFormData?.map((item, index) => {
      if (index === name && Object.keys(imageUrl).length > 0) {
        return {
          ...item,
          imageUrl: imageUrl,
        }
      }
      return item
    })

    form.setFieldsValue({
      additionalChats: updatedChatFormData,
    })
  }, [imageUrl])

  const uniqueId = `imageChatUploadInput-${name}`
  return (
    <div className='ChatWrapper'>
      <div className='chatImageWrapper'>
        <div>
          <div className='ChatTopField'>
            <Item
              name={[name, 'title']} label='Chat Name' rules={[
                { required: true, message: 'Chat Name is required!' },
                { max: 50, message: 'Chat Name cannot exceed 50 characters!' },
              ]}
              normalize={v => v.trimStart()}
            >
              <Input className='channelName' placeholder='Chat name' />
            </Item>
          </div>
          <div className='radioItemField makePrivate'>
            <Item
              name={[name, 'isPrivate']}
              valuePropName='checked'
              className='item'
              initialValue={makePrivate}
            >
              <Switch onChange={togglePrivateStatus} defaultChecked={makePrivate} />
            </Item>
            <div className='text'>Make private</div>
          </div>
        </div>
        <ImageUploader uploaderTitle='Chat' id={uniqueId} categoryData={categoryData} setImageUrl={setImageUrl} form={form} name={name} action={uploadChatImage} />
      </div>
      <div className='btnWrapper'>
        <div className='removeBtn' onClick={() => onRemove(name)}>
          Delete Chat
        </div>
      </div>
    </div>
  )
}
export default ChatForm
