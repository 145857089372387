import React from 'react'
import './index.styl'
import { Link } from 'react-router-dom'

const SectionLinks = ({ title = '', links = [] }) => {
  return (
    <div className='wrapperSectionLinks'>
      <div className='footerTitle'>{title}</div>
      <ul className='wrapperLinks'>
        {links.map((i, index) => <li key={index} className='item'> <Link className='link' to={i.link}>{i.title}</Link> </li>)}
      </ul>
    </div>
  )
}

export default SectionLinks
