import React from 'react'
import { useSelector } from 'react-redux'
import { CreateEventV2, MakeTicketComponents, Layout, BannerMockTicket } from 'components'

import { isVenue } from 'utils'

const MakeTicket = () => {
  const { user } = useSelector(state => state?.session)
  return (
    <Layout>
      <BannerMockTicket />
      {isVenue(user?.role) ? <CreateEventV2 />
        : <MakeTicketComponents />}
    </Layout>
  )
}

export default MakeTicket
