import React from 'react'
import { Modal, Form } from 'antd'
import { CustomButton } from 'components'
import TableSeatsReservation from '../TableSeatsReservation'

import './index.styl'

const ModalReserveTables = ({ visible = false, isUser, sectionID, sectionType, selectedSeat, selectSeatArray, group, sectionName, title, onChange, width = 400, onFinish, loading }) => {
  const attr = { visible, onCancel: onChange, width: width, maxWidth: 400, footer: null, className: 'wrapperModalReserve', destroyOnClose: true }
  // const [isCheckAll, setIsCheckAll] = useState(false)

  // const handleSelectAll = e => {
  //   setIsCheckAll(!isCheckAll)

  //   if (isCheckAll) {
  //     // selectSeatArray([])
  //   } else {
  //     group.seats.filter(item => !item.isReserved).map(i => {
  //       selectSeatArray([...selectedSeat, { seatId: i.id, sectionName, rowName: group.groupName, seatName: i.seatName, sectionType, price: group.category.price, isActive: true }])
  //     })
  //   }
  // }

  const [form] = Form.useForm()
  const initialValues = {

  }
  return (
    <Modal className='templateModal' title={title} {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish} initialValues={initialValues}>
        <div className='reserveSeats'>
          <div className='flexWrapper'>
            <div className='title'>
              Section <span className='sectionName'>{sectionName}</span>
            </div>
            <div className='title'>
              Table <span>{group.groupName}</span>
            </div>
          </div>
          <div className='rectangleSeat'>
            {group.seats.map((seat, index) => {
              return (
                <div key={index}>
                  <TableSeatsReservation isUser={isUser} sectionType={sectionType} selectSeatArray={selectSeatArray} sectionName={sectionName} group={group} sectionID={sectionID} selectedSeat={selectedSeat} seat={seat} />
                </div>
              )
            })}
          </div>
        </div>

        {/* <div className='freeSeatsWrapper'> */}
        {/* <Checkbox onChange={handleSelectAll}>Select all free seats</Checkbox> */}
        <div className='wrapperActions'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text='Continue' type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
        {/* </div> */}

      </Form>
    </Modal>
  )
}

export default ModalReserveTables
