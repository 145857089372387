import React, { useState } from 'react'
import ThreeDotIcon from 'images/threedots.svg'
import { Popover, notification, Tooltip } from 'antd'
import { template } from 'apiv2'
import { handleConfirmModal } from 'utils'
import { ModalTemplate } from '../../../../../components'
import './index.styl'
const TableSeats = ({ color, blockSaleInitial, seatsFree, seatCount, seatName, categoryID, seatID, templateID, categoriesArray, loading, setLoading }) => {
  const [visible, setVisible] = useState(false)
  const [openContent, setOpenContent] = useState(false)
  const [blockSale, setBlockSale] = useState(blockSaleInitial || false)
  const isColorBlack = color === '#FFFF00' || color === '#FFCC00' || color === '#FF9900' || color === '#FF6600' || color === '#99CC00' || color === '#99FF00' || color === '#33FF00' || color === '#33CC00'

  const onChange = () => {
    setVisible(!visible)
    setOpenContent(false)
  }
  const onChangeLoading = () => setLoading(!loading)
  const handleOpenChange = newOpen => {
    setOpenContent(newOpen)
  }

  const onFinish = form => {
    const { seatCount, name, categoryID } = form
    if (!name || !seatCount) return
    onChangeLoading()
    const obj = { seatCount, blockSale, name, categoryID, id: seatID }
    template.editRowTable(templateID, obj).then(() => {
      setLoading(false)
      onChange()
    }).catch(() => setLoading(false))
  }
  const blockSaleHandler = () => {
    onChangeLoading()
    const obj = { blockSale: true, id: seatID, categoryID, seatCount, name: seatName }
    template.editRowTable(templateID, obj).then(() => {
      setBlockSale(true)
      setLoading(false)
    }).catch(() => setLoading(false))
  }
  const unblockSaleHandler = () => {
    onChangeLoading()
    const obj = { blockSale: false, id: seatID, categoryID, seatCount, name: seatName }
    template.editRowTable(templateID, obj).then(() => {
      setBlockSale(false)
      setLoading(false)
    }).catch(() => setLoading(false))
  }
  const deleteTemplateTable = e => {
    const obj = { id: seatID }
    onDeleteSection(templateID, obj)
    setOpenContent(false)
  }
  const onDeleteSection = (id, obj) => {
    const onOk = () => {
      setLoading(true)
      template.deleteTemplateRow(id, obj).then(() => setLoading(false)).catch(error => {
        notification.warning({ message: error.response.data.error })
        setLoading(false)
      })
    }
    handleConfirmModal('Are you sure you want to delete this table?', onOk)
  }
  const isMyTemplatesPage = window.location.pathname.startsWith('/my-templates/')
  const popoverContent = (
    <div className='actionPopoverTable'>
      <div className='popoverBtn' onClick={onChange}>Edit table</div>
      {!isMyTemplatesPage && (
        <div>
          {blockSale
            ? <div className='popoverBtn' onClick={unblockSaleHandler}>Unblock from sale</div>
            : <div className='popoverBtn' onClick={blockSaleHandler}>Block from sale</div>}
        </div>
      )}
      <div className='popoverBtnRemove' onClick={deleteTemplateTable}>Delete table</div>
    </div>
  )
  return (
    <div className='tablesSeats'>
      <div className={blockSale ? 'blockSale' : 'tableRectangle'} style={{ background: color }}>
        <div className='popoverButton'>
          <Popover
            overlayClassName='actionPopoverContainer'
            content={popoverContent}
            placement='top'
            trigger='click'
            visible={openContent}
            onVisibleChange={handleOpenChange}
          >
            <img src={ThreeDotIcon} className='threeDotsSeats' alt=' ' />
          </Popover>
        </div>
        <Tooltip placement='top' title={seatName}>
          <span
            className='seatName'
            style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }}
          >
            {seatName.length > 17 ? `${seatName.substring(0, 16)}...` : seatName}
          </span>
        </Tooltip>

        {seatsFree === 0 ? (
          <div className='soldoutTextWrapper'>
            <div className='soldoutText' style={{ color: color }}>Sold Out {seatCount}</div>
          </div>
        ) : (
          <div className='seatsLeftWrapper'>
            <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seats'>seats left</span>
            <span style={{ color: isColorBlack ? '#1A1D24' : '#FFFFFF' }} className='seatsNumber'>{seatsFree} of {seatCount}</span>
          </div>
        )}
      </div>
      <ModalTemplate additionalProps={{ categoriesArray, seatNumber: seatName, seatCount, initialColor: categoryID, blockSale, setBlockSale }} visible={visible} onChange={onChange} onFinish={onFinish} name='table' width={472} title='Edit Table' loading={loading} />
    </div>
  )
}

export default TableSeats
