import React from 'react'
import './index.styl'

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const colorPalette = {
  12: '#D40000',
  1: '#000000',
  2: '#000066',
  3: '#0000CC',
  4: '#00CCFF',
  5: '#330000',
  6: '#333300',
  7: '#336600',
  8: '#3333FF',
  9: '#339900',
  10: '#3399FF',
  11: '#33CC00',
}

const formatMinute = minute => {
  return minute === 0 ? '00' : String(minute).padStart(2, '0')
}

const CalendarComponent = ({ schedule = {} }) => {
  // Найти максимальное количество событий в любом дне недели
  const maxEvents = Math.max(...daysOfWeek.map(day => schedule[day.toLowerCase()]?.length || 0))

  return (
    <div className='hoursBlock'>
      <div className='hoursCalendarWrapper'>
        <div className='title'>Hours</div>
        <div className='daysOfWeekWrapper'>
          {daysOfWeek.map(day => (
            <div key={day} className='dayTitleWrapper'>
              <div className='dayTitle'>{day}</div>
            </div>
          ))}
        </div>
        {Array.from({ length: maxEvents }).map((_, rowIndex) => (
          <div className='daysOfWeekWrapper' key={rowIndex}>
            {daysOfWeek.map(day => (
              <div key={day} className='hoursCardBlock'>
                {schedule[day.toLowerCase()]?.[rowIndex] && (
                  <div className='container'>
                    <div className='hoursCard' style={{ backgroundColor: colorPalette[schedule[day.toLowerCase()][rowIndex]?.time?.start?.hour] }} />
                    <div className='colorNameWrapper'>
                      <div className='color' style={{ backgroundColor: colorPalette[schedule[day.toLowerCase()][rowIndex]?.time?.start?.hour] }} />
                      <div className='name'>{schedule[day.toLowerCase()][rowIndex].name}</div>
                    </div>
                    <div className='time'>
                      <div className='startTime'>
                        {schedule[day.toLowerCase()][rowIndex]?.time?.start?.hour}:
                        {formatMinute(schedule[day.toLowerCase()][rowIndex]?.time?.start.minute)}
                        {schedule[day.toLowerCase()][rowIndex]?.time?.start.ampm.toUpperCase()}
                      </div>
                      <div className='textTo'>to</div>
                      <div className='endTime'>
                        {schedule[day.toLowerCase()][rowIndex]?.time?.end.hour}:
                        {formatMinute(schedule[day.toLowerCase()][rowIndex]?.time?.end?.minute)}
                        {schedule[day.toLowerCase()][rowIndex]?.time.end.ampm.toUpperCase()}
                      </div>
                    </div>
                    <div className='description'>{schedule[day.toLowerCase()][rowIndex].description}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CalendarComponent
