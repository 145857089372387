import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import ThreeDotIcon from 'images/threedots.svg'
import './index.styl'
import { Table, Popover } from 'antd'

const Index = () => {
  // const [visible, setVisible] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const { user } = useSelector(state => state.session)
  // const dispatch = useDispatch()
  // const { email, mobile, firstName, lastName, userName } = user
  const { userSubscriptionDetails, userPurchasesDetails } = useSelector(state => state.session)

  const popoverContent = id => {
    // const handleCancel = inviteID => {
    //  handleConfirmModal(
    //    'Are you sure you want to cancel?',
    //    () =>
    //      dispatch(deleteInvite({ eventID, inviteID }))
    //        .then(() => updateTicketsOnPage(activeTab))
    //        .catch(() => {}),
    //  )
    // }
    return (
      <div className='actionPopover'>
        <div className='popoverBtn popove`rBtnRemove'>Cancel</div>
      </div>
    )
  }

  const currentCol = [
    {
      title: 'Name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: 'Type',
      dataIndex: 'ticketName',
      key: 'ticketName',
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewalDate',
      key: 'renewalDate',
      render: (_, { renewalDate }) => renewalDate ? moment(renewalDate).format('MMM D, YYYY') : '',
    },
    {
      title: 'Price',
      dataIndex: 'fullPrice',
      key: 'fullPrice',
      align: 'right',
      render: (item, record) => <span>{item ? '$' : ''}{item ? item.toFixed(2) : ''}</span>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: record => {
        return (
          <>
            <Popover
              overlayClassName='actionPopoverContainer'
              getPopupContainer={node => node?.parentNode}
              content={() => popoverContent(record?._id)}
              placement='top'
              trigger='click'
            >
              <img src={ThreeDotIcon} className='threeDotsActions' alt=' ' />
            </Popover>
          </>
        )
      },
    },
  ]

  const historyCol = [
    {
      title: 'Purchase Date',
      dataIndex: 'date',
      key: 'date',
      render: (_, { date }) => date ? moment.utc(date).format('MMM D, YYYY') : '',
    },
    {
      title: 'Name',
      dataIndex: 'eventName',
      key: 'eventName',
    },
    {
      title: 'Type',
      dataIndex: 'ticketName',
      key: 'ticketName',
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (item, record) => <span>{item ? '$' : ''}{item ? item.toFixed(2) : ''}</span>,
    },
    // {
    //  title: 'Download',
    //  dataIndex: 'download',
    //  key: 'Download',
    // },
  ]

  return (
    <div className='sectionProfile'>
      <div className='head'>
        <div className='titleSection'>Subscriptions and Purchases</div>
      </div>
      <div className='body'>
        <div>
          <div className='tableTitle'>Current Subscriptions</div>
          <Table
            columns={currentCol}
            pagination={false}
            // loading={loading}
            // here need date
            dataSource={userSubscriptionDetails || null}
            // title={() => catTabs()}
            className='eventDetailsTable detailsTable'
          />
        </div>

        <div className='tableTitle'>Purchase History</div>
        <Table
          columns={historyCol}
          pagination={false}
          // loading={loading}
          // here need date
          dataSource={userPurchasesDetails || null}
          // title={() => catTabs()}
          className='eventDetailsTable detailsTable'
        />
      </div>

    </div>
  )
}

export default Index
