import axios from 'axios'
import qs from 'qs'

const api = (url = '', method = '', data = {}, authorization = true, requestOptions = {}) => {
  const state = localStorage.getItem('persist:blueline-ticket')

  const options = {
    method,
    headers: {
      'content-language': 'en',
      utcoffset: '-180',
    },
    data: qs.stringify(data),
    url: `${process.env.REACT_APP_API_ENDPOINT}/${url}`,
    // url: `https://admin.bluelineticket.com/${url}`,
  }

  if (authorization) {
    options.headers.authorization = 'bearer ' + JSON.parse(JSON.parse(state).session).token
  }
  if (
    url === 'user/uploadAttachment' ||
    url === 'user/uploadImage' ||
    url === 'user/uploadFile' ||
    url === 'event/addEvent' ||
    url === 'event/deleteTables' ||
    url === 'event/deleteAssignTicketSection' ||
    url === 'event/deleteEvent' ||
    url === 'event/deleteTicket') {
    options.data = data
  }

  axios.interceptors.response.use(response => response, error => {
    if (error?.response?.data?.error === 'Unauthorized') {
      window.location.reload(true)
    } else if (error) {
      throw error
    }
  })

  if (requestOptions?.cancelToken) {
    options.cancelToken = requestOptions?.cancelToken
  }
  if (requestOptions?.onUploadProgress) {
    options.onUploadProgress = requestOptions?.onUploadProgress
  }

  return axios(options)
}

export const CancelToken = axios.CancelToken

export default api
