import React from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import { notification } from 'antd'
import c from 'classnames'
import './index.styl'

const ShippingAddress = () => {
  // const [visible, setVisible] = useState(false)
  // const [loading, setLoading] = useState(false)
  // const { bankDetails } = useSelector(state => state.session)
  // const dispatch = useDispatch()
  const Information = ({ item, index, lastIndex }) => (
    <div className={c('wrapperInformation', { border: index !== lastIndex - 1 })}>
      <div className='left'>{item.title}</div>
      <div className='right'>{item.text}</div>
    </div>
  )
  const informations = [
    {
      title: 'Country',
      text: null,
    },
    {
      title: 'Address Line 1',
      text: null,
    },
    {
      title: 'Address Line 2',
      text: null,
    },
    {
      title: 'City',
      text: null,
    },
    {
      title: 'State/Province/Region',
      text: null,
    },
    {
      title: 'ZIP Code',
      text: null,
    },
  ]

  return (
    <div className='sectionProfile'>
      <div className='head'>
        <div className='titleSection'>Shipping Address</div>
      </div>
      <div className='body'>
        {informations.map((i, ind) => <Information item={i} index={ind} key={i.title} lastIndex={informations.length} />)}
      </div>

    </div>
  )
}

export default ShippingAddress
