import { minLength } from 'modules/utils/validators'
import { isInteger } from 'lodash'

const integerValidator = message => {
  return {
    validator (_, value) {
      if (value && (!isInteger(value))) {
        return Promise.reject(new Error(message))
      }
      return Promise.resolve()
    },
  }
}

export const getRule = (label = '', name = '', max) => {
  const defaultRule = {
    required: true,
    message: 'Please enter ' + label.toLowerCase(),
  }
  switch (name) {
    case 'price':
      return [defaultRule, {
        type: 'number',
        message: 'Price should be lower',
        max,
      }]
    case 'quantity':
      return [defaultRule, {
        type: 'number',
        message: "You don't have enough",
        max,
      }, integerValidator('Invalid number')]
    case 'mobile':
      return [minLength]
    case ('email'):
      return [defaultRule, {
        type: 'email',
        whitespace: true,
        message: 'Please enter a valid ' + label.toLowerCase(),
        transform: value => value?.trim(),
      }]
    default: return [defaultRule]
  }
}
