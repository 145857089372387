import React from 'react'
import { useSelector } from 'react-redux'
// import Tags from './Tags'
import SliderBody from './SliderBody'
import { Skeleton, Tooltip } from 'antd'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const Body = ({ loaded }) => {
  const { memberPublicEvents = [], activeParticipants = [] } = useSelector(state => state.memberships)
  // const publicEvents = []
  // const dataSource = [
  //   {
  //     title: 'Concert',
  //     tags: ['Pop', 'Rock', 'Country', 'Hip-Hop', 'R&B'],
  //     items: publicEvents,
  //   },
  // ]
  const placeholder = (
    <div className='wrapperBodyEmpty'>
      <Tooltip title='No Events available'>
        <img src={NoEventsInformation} alt='Information Logo' />
      </Tooltip>
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )
  return (
    <Skeleton loading={!loaded} active={!loaded} paragraph={{ rows: 15 }}>
      <div className='homeBody'>
        {activeParticipants.length > 0 &&
          <div className='activeMembershipWrapper'>
            <div className='activeMembershipTitle'>Your Memberships</div>
            <SliderBody items={activeParticipants} />
          </div>}
        {activeParticipants.length > 0
          ? (
            <div className='currentPublicActiveWrapper'>
              <div className='currentPublicActiveTitle'>Explore More Memberships</div>
              {memberPublicEvents.length && <SliderBody items={memberPublicEvents} />}
            </div>
          ) : (
            <>
              {memberPublicEvents.length ? <SliderBody items={memberPublicEvents} /> : placeholder}
            </>
          )}
        {/* <div className='title'>
        <div className='text'>{i.title}</div>
        <div className='btnViewAll'>View All</div>
      </div>
        <Tags tags={i.tags} />  */}
      </div>
    </Skeleton>
  )
}

export default Body
