import React from 'react'
import { Link } from 'react-router-dom'
import arrow from 'images/simpleArrow.svg'
import './index.styl'

const NavBack = ({ path = '/' }) => {
  return (
    <div className='navRootContainer'>
      <Link to={path}>
        <img src={arrow} alt='arrow' className='navBackArrow' />
        <span>Back</span>
      </Link>
    </div>
  )
}

export default NavBack
