import React from 'react'
import { Link } from 'react-router-dom'
import DetailPageHeader from '../../../components/DetailPageHeader'
import arrow from 'images/simpleArrow.svg'
import './index.styl'

const Header = ({ currentPageDetails, id }) => {
  return (
    <div className='headerDetailPage'>
      <div className='headerContainer'>
        <div className='titleBack'>
          <Link to='/memberlyfe-home'>
            <img src={arrow} alt='arrow' className='icon' />
            <span className='text'>Back to Homepage</span>
          </Link>
        </div>
        <DetailPageHeader
          currentPageDetails={currentPageDetails}
          id={id}
        />
      </div>
    </div>
  )
}

export default Header
