import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeConnectedAccount, accessTokenLogin } from 'modules/session/session-actions'
import './index.styl'

const StripeRedirect = () => {
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  useEffect(() => {
    const code = queryParams.get('code')
    if (code) {
      dispatch(makeConnectedAccount({ authToken: code }))
        .then(() => {
          dispatch(accessTokenLogin({ deviceType: 'WEB' }))
          setMessage('Session confirmed, you can return Home')
        })
        .catch(() => {
          setMessage('Session expired')
        })
    } else if (queryParams.get('error')) {
      setMessage(queryParams.get('error_description'))
    } else {
      setMessage('Error occured')
    }
  }, [])

  return (
    <div className='wrapperConfirmationScreen'>
      <div className='resetPassword'>Stripe authorization</div>

      <div className='resetPasswordRule'>
        {message}
      </div>
    </div>
  )
}

export default StripeRedirect
