import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { CustomButton, CustomSelect } from 'components'
import SearchIcon from 'images/input-search.svg'
import { Input } from 'antd'
import { MemberOfficeContext } from '../index'
import { useQuery, getMemberOfficeURL } from 'utils'
import c from 'classnames'
import './index.styl'

const Header = () => {
  const { sortTag, setSortTag, setSearchTag, loading } = useContext(MemberOfficeContext)
  const history = useHistory()
  const query = useQuery()

  const items = [
    { name: 'Sort by Name', key: 'NAME_ASC' },
    { name: 'Sort by Name', key: 'NAME_DESC' },
    { name: 'Sort by Date', key: 'DATE_ASC' },
    { name: 'Sort by Date', key: 'DATE_DESC' }]
  const searchIcon = <img alt='search icon' src={SearchIcon} />

  useEffect(() => {
    if (!query.get('activeTab') || !query.get('page') || !query.get('orderBy')) {
      history.push(getMemberOfficeURL(sortTag))
    }
  }, [])

  useEffect(() => {
    const currentTab = query.get('activeTab')
    const orderBy = query.get('orderBy')
    window.dataLayer.push({ event: 'My Member Office' })
    if (!!currentTab) {
      history.push(getMemberOfficeURL(sortTag))
    }
    if (!!orderBy && orderBy !== sortTag) {
      history.push(getMemberOfficeURL(sortTag))
      setSortTag(orderBy)
    }
  }, [history.location.search])

  let requestDelay = 0
  const onChangeSearch = e => {
    if (+query.get('page') !== 1) {
      history.push(getMemberOfficeURL(sortTag))
    }
    const searchValue = e.target?.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchTag(searchValue)
    }, 700)
  }
  const onChangeSorting = tag => {
    history.push(getMemberOfficeURL(tag, query.get('page')))
    setSortTag(tag)
  }

  return (
    <div className='headerMemberOffice'>
      <div className='subHeader'>
        <div className='title'>Member Office</div>
        <div>
          <CustomButton
            text='Make My Page'
            type='dark'
            className='btnHeader'
            onClick={() => history.push('/make-my-memberlyfe')}
          />
        </div>
      </div>
      <div className={c('subHeader', 'subHeader2')}>
        <div className='leftHeader'>
          <Input
            className='input noFocusBorder'
            onChange={onChangeSearch}
            placeholder='Search'
            prefix={searchIcon}
            allowClear
          />
        </div>
        <CustomSelect items={items} sortTag={sortTag} setSortTag={onChangeSorting} customSuffix disabled={loading} />
      </div>
    </div>
  )
}

export default Header
