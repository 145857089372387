import React, { useState, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Progress } from 'antd'
import {
  uploadImage,
  preparePoster,
} from 'modules/events/events-actions'
import { blacklistFileTypes, getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'
import { template } from 'apiv2'

import './index.styl'
import ModalCropImage from '../../../../components/CreateEvent/MultiMediaContainer/ModalCropImage'
import { CustomButton } from 'components'

const isDenied = (type = '') => !!type.match(blacklistFileTypes)
const onNotifyDenied = () => notify('Invalid file extension')

const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const EditTemplateImage = ({ templateID, setLoading }) => {
  const dispatch = useDispatch()

  // const { currentEventDetails } = useContext(CreateEventContext)
  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [ticketCover, setTicketCover] = useState('')
  const [ticketCoverName, setTicketCoverName] = useState(ticketCover)
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')
  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])

  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }
  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadImage(originalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setLoading(true)
            setTicketCoverName(fileNameTemp)
            clearTempState()
            const obj = { imageUrl: response }
            template.editImage(templateID, obj).then(() => {
              setLoading(false)
            })
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
  }

  const onFileChanged = async image => {
    if (isDenied(image?.type)) {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    dispatch(preparePoster(image, { onUploadProgress, responseType: 'blob' }))
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onSuccess = ({ onSuccess }) => onSuccess('ok')

  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className='progressContainerTemplate'>
      <Progress
        percent={progress}
        type='circle'
        width={size}
      />
    </div>
  )

  return (
    <div className='wrapperBackgroundSeating'>
      <div className='uploadContainer'>
        <div>
          <Upload
            accept='image/png, image/jpeg'
            onChange={onFileChanged}
            customRequest={onSuccess}
            showUploadList={false}
            className='uploader'
            disabled={loadingCover}
            name='image'
          >
            <div>
              {loadingCover && showProgress(progressCover)}
              <CustomButton className='colorEditButton' text='Edit image' type='white' />
            </div>
          </Upload>
          {fileDataBlob && (
            <ModalCropImage
              onConfirm={handleConfirmModify}
              className='cropImageModal'
              loading={loadingCover}
              onCancel={onCancel}
              file={fileDataBlob}
              setCrop={setCrop}
              onLoad={onLoad}
              crop={crop}
            />
          )}
        </div>
      </div>

    </div>
  )
}

export default EditTemplateImage
