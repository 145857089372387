import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import { MainButton, ModalTicketAction, CustomButton } from 'components'
import { handleConfirmModal, isAdmin, notify, formatInteger } from 'utils'
import ThreeDotIcon from 'images/threedots.svg'
import EditIcon from 'images/editGray.svg'
import {
  getInvites,
  getEventDetailsSummaryV2,
  getDistributedTickets,
  cashSaleTicketFromMyBoxOffice,
  deleteInvite,
} from 'modules/events/events-actions'
import { Table, Input, Pagination, Tabs, Popover } from 'antd'
import moment from 'moment'
import SearchIcon from 'images/input-search.svg'
import { isEmpty } from 'lodash'
import { events } from 'api'
import { distribution } from 'apiv2'
import MobileTable from '../../MobileTable'
import c from 'classnames'
import './index.styl'
import { setScanned } from '../../../../modules/events/events-actions'
import ModalEditNote from '../../../../components/ModalEditNote'

const { TabPane } = Tabs

const Distribution = ({ currencySymbol }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [ticketID, setTicketID] = useState('')
  const [urls, setUrls] = useState([])
  const [loadUrls, setLoadUrls] = useState(false)
  const [visibleModal, setVisibleModal] = useState(false)
  const { eventID } = useParams()
  const { pathname, state = {} } = useLocation()
  const activeTable = pathname.split('/')[5].replace(/[\-\/]/gi, ' ')
  const [publicSaleData, setPublicSaleData] = useState([])
  const [directSaleData, setDirectSaleData] = useState([])
  const [cashCheckSaleData, setCashCheckSaleData] = useState([])
  const [publicBuyData, setPublicBuyData] = useState([])
  const [subPromotionsData, setSubPromotionsData] = useState([])
  const [giftData, setGiftTableData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(window.innerWidth < 1200 ? 3 : 10)
  const [dataLength, setDataLength] = useState(0)
  const [loading, setLoading] = useState(true)
  const [actionProcessing, setActionProcessing] = useState(false)
  const [activeMainTab, setActiveMainTab] = useState(pathname)
  const [activeTab] = useState(activeTable)
  const [openNotesModal, setOpenNotesModal] = useState(false)
  const [currentInviteID, setCurrentInviteID] = useState('')
  const scrollRef = useRef(null)
  const {
    soldTicketDetails,
    currentEventDetails,
    inviteGiftUsers,
    inviteCashUsers,
    inviteSellUsers,
    inviteBuyUsers,
    invitePromoteUsers,
  } = useSelector(state => state.events)
  const { user: { _id: currentUserId, role } } = useSelector(state => state.session)
  const skip = (currentPage - 1) * perPage
  const fetchUrlsData = () => {
    setLoadUrls(true)
    events.getSubPromoteUrls(eventID)
      .then(({ data: { data } }) => setUrls(data))
      .catch(error => notify(error?.response?.data?.message))
      .finally(() => setLoadUrls(false))
  }
  const handleModal = () => {
    setVisibleModal(!visibleModal)
  }
  const handleOpenNotesModal = () => {
    setOpenNotesModal(!openNotesModal)
  }

  const onFinishNotes = form => {
    const { notes } = form
    setLoading(!loading)
    const obj = { eventID, inviteID: currentInviteID, notes }
    distribution.updateInviteNotes(obj).then(() => {
      setLoading(false)
      updateTicketsOnPage(activeTab)
      setOpenNotesModal(false)
    }).catch(() => setLoading(false))
  }

  const formTicketAction = values => {
    if (actionProcessing) return
    setActionProcessing(true)
    const ticketOwnerId = currentEventDetails.userID
    const ticketVenueUserID = currentEventDetails?.venueUserID
    let obj = {
      eventID: eventID,
      ticketID: values.category,
      email: values.email.trim(),
      quantity: values.quantity,
      inviteType: activeTable === 'gift tickets' ? 'GIFT' : activeTable === 'sub promote tickets' ? 'PROMOTE' : activeTable === 'cash sale tickets' ? 'CASH_SELL' : 'SELL',
      isSubPromoter: currentUserId !== ticketOwnerId && currentUserId !== ticketVenueUserID && !isAdmin(role),
    }
    if (values.price) {
      obj = { ...obj, price: values.price }
    }
    if (values.mobile) {
      obj.mobile = values.mobile
    }
    if (values.notes) {
      obj.notes = values.notes
    }
    if (values.uniqueName && values.uniqueName.trim()) {
      obj = { ...obj, uniqueName: values.uniqueName.trim() }
    }
    dispatch(cashSaleTicketFromMyBoxOffice(obj)).then(response => {
      /* dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'GIFT'))
      dispatch(getEventGetInviteUsers(currentEventDetails?.tickets[0]?._id, 'SELL')) */
      if (response) {
        dispatch(getEventDetailsSummaryV2(eventID, currentEventDetails?.isPromoted))
        updateTicketsOnPage(activeTab)
      }
      setActionProcessing(false)
    })
  }
  const processTableData = array => {
    return array?.map((i, index) => ({
      key: index,
      user: i?.invitee?.fullName || i?.fullName,
      mobile: i?.invitee?.fullMobile || i?.fullMobile,
      email: i?.invitee?.email || i?.email,
      inviteID: i?._id,
      barcode: i?.barcode,
      quantity: formatInteger(i?.quantity),
      note: i?.notes,
      sentOn: `${moment(i?.sentOn).format('MM.DD.YYYY')} at ${moment(i?.sentOn).format('hh:mm a')}`,
      status: i?.inviteStatus,
      seat: i?.seat,
      isScanned: i?.isScanned,
      vanityUrl: i?.vanityUrl,
      price: i?.price,
      notes: i?.notes,
    }))
  }
  const switchByActiveTable = (all, pub, direct, gift, cash, sub) => {
    const runIfFunc = obj => typeof obj === 'function' ? obj() : obj
    switch (activeTable) {
      case 'all tickets':
        return runIfFunc(all)
      case 'public sale tickets':
        return runIfFunc(pub)
      case 'direct sale tickets':
        return runIfFunc(direct)
      case 'gift tickets':
        return runIfFunc(gift)
      case 'cash sale tickets':
        return runIfFunc(cash)
      case 'sub promote tickets':
        return runIfFunc(sub)
    }
  }
  const calculateDataLength = () => switchByActiveTable(soldTicketDetails?.total, inviteBuyUsers?.total, inviteSellUsers?.total, inviteGiftUsers?.total, inviteCashUsers?.total, invitePromoteUsers?.total)
  const noStreamTickets = (tickets = []) => {
    return tickets.filter(({
      isPrivateStream,
      isSeatingCategory,
      isStreaming,
    }) => activeTable.includes('cash') ? !isStreaming && !isPrivateStream && currentEventDetails?.eventType !== 'VIRTUAL' : activeTable.includes('direct') || activeTable.includes('gift') || activeTable.includes('promot') ? !isPrivateStream : !isPrivateStream)
  }
  const updateTicketsOnPage = type => {
    setLoading(true)
    switch (type) {
      case 'all tickets':
        dispatch(getDistributedTickets(eventID, ticketID, perPage, skip, searchValue))
        break
      case 'public sale tickets':
        dispatch(getInvites(ticketID, 'BUY', perPage, skip, searchValue))
        break
      case 'direct sale tickets':
        dispatch(getInvites(ticketID, 'SELL', perPage, skip, searchValue))
        break
      case 'cash sale tickets':
        dispatch(getInvites(ticketID, 'CASH_SELL', perPage, skip, searchValue))
        break
      case 'gift tickets':
        dispatch(getInvites(ticketID, 'GIFT', perPage, skip, searchValue))
        break
      case 'sub promote tickets':
        dispatch(getInvites(ticketID, 'PROMOTE', perPage, skip, searchValue))
        break
    }
  }

  useEffect(() => {
    setSearchValue('')
  }, [])

  useEffect(() => {
    if (currentEventDetails?.tickets?.length && isEmpty(ticketID)) {
      setTicketID(noStreamTickets(currentEventDetails?.tickets)[0]?._id)
    }
    // if (currentEventDetails?.bundles?.length && isEmpty(bundleID)) {
    //   setBundleID(currentEventDetails?.bundles[0]?.id)
    // }
  }, [currentEventDetails])
  useEffect(() => {
    if (visibleModal && activeTable.includes('promot')) {
      fetchUrlsData()
    }
  }, [visibleModal])

  useEffect(() => {
    setGiftTableData(processTableData(inviteGiftUsers?.data))
    setLoading(false)
  }, [inviteGiftUsers])

  useEffect(() => {
    setSubPromotionsData(processTableData(invitePromoteUsers?.data))
    setLoading(false)
  }, [invitePromoteUsers])

  useEffect(() => {
    setDirectSaleData(processTableData(inviteSellUsers?.data))
    setLoading(false)
  }, [inviteSellUsers])
  useEffect(() => {
    setCashCheckSaleData(processTableData(inviteCashUsers?.data))
    setLoading(false)
  }, [inviteCashUsers])

  useEffect(() => {
    setPublicBuyData(processTableData(inviteBuyUsers?.data))
    setLoading(false)
  }, [inviteBuyUsers])

  useEffect(() => {
    setPublicSaleData(processTableData(soldTicketDetails?.data))
    setLoading(false)
  }, [soldTicketDetails])

  useEffect(() => {
    setDataLength(calculateDataLength())
  }, [inviteBuyUsers, inviteSellUsers, inviteGiftUsers, inviteCashUsers, invitePromoteUsers, soldTicketDetails])

  useEffect(() => {
    ticketID && updateTicketsOnPage(activeTab)
    window.innerHeight <= 815 && scrollRef.current.scrollIntoView()
  }, [currentPage])

  useEffect(() => {
    ticketID && (currentPage !== 1 ? setCurrentPage(1) : updateTicketsOnPage(activeTab))
  }, [activeTab, ticketID, searchValue])

  let requestDelay = 0
  const onChangeSearch = e => {
    const search = e.target.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchValue(search)
    }, 700)
  }

  const isOwner = currentUserId === currentEventDetails.userID || isAdmin(role)
  const distributionNavigation = [
    {
      text: 'All tickets',
      to: `/my-box-office/event-details/tickets-distribution/${eventID}/all-tickets`,
    },
    {
      text: 'Online sale tickets',
      to: `/my-box-office/event-details/tickets-distribution/${eventID}/public-sale-tickets`,
    },
  ]
  if (isOwner || !currentEventDetails?.isStreamingEvent) {
    const directSale = {
      text: 'Direct sale tickets',
      to: `/my-box-office/event-details/tickets-distribution/${eventID}/direct-sale-tickets`,
    }
    distributionNavigation.splice(distributionNavigation.length, 0, directSale)
  }

  const giftTicket = {
    text: 'Gift tickets',
    to: `/my-box-office/event-details/tickets-distribution/${eventID}/gift-tickets`,
  }
  distributionNavigation.splice(distributionNavigation.length, 0, giftTicket)

  const cashCheck = {
    text: 'Cash/Zelle sale tickets',
    to: `/my-box-office/event-details/tickets-distribution/${eventID}/cash-sale-tickets`,
  }
  distributionNavigation.splice(distributionNavigation.length, 0, cashCheck)
  // if (isOwner) {
  //  const subPromote = {
  //    text: 'Sub promote tickets',
  //    to: `/my-box-office/event-details/tickets-distribution/${eventID}/sub-promote-tickets`,
  //  }
  //  distributionNavigation.splice(distributionNavigation.length, 0, subPromote)
  // }

  useEffect(() => {
    if (currentEventDetails.userID && !distributionNavigation.find(({ to }) => pathname.includes(to))) {
      history.push(distributionNavigation[0]?.to)
    }
  }, [pathname, currentEventDetails.userID, JSON.stringify(distributionNavigation)])

  const handleModalVisibleModalConfirmScanner = inviteID => {
    const userInfo = dataSource.filter(d => d.inviteID === inviteID)[0]
    const handleSetScanned = barcode => {
      handleConfirmModal(
        `Are you sure you want to mark a ticket as scanned for ${userInfo.user} ${userInfo.email}?`,
        () => {
          setScanned(barcode, eventID)
            .then(() => updateTicketsOnPage(activeTab))
            .catch(() => {})
        },
      )
    }
    return (
      <div className='actionPopover'>
        <div className='popoverBtn' onClick={() => handleSetScanned(userInfo.barcode)}>Scan manually</div>
      </div>
    )
  }
  const popoverContent = (inviteID, isStatus) => {
    const handleCancel = inviteID => {
      handleConfirmModal(
        'Are you sure you want to cancel?',
        () =>
          dispatch(deleteInvite({ eventID, inviteID }))
            .then(() => updateTicketsOnPage(activeTab))
            .catch(() => {}),
      )
    }
    return (
      <div className='actionPopover'>
        <div
          className='popoverBtn popove`rBtnRemove' onClick={() => {
            handleOpenNotesModal()
            setCurrentInviteID(inviteID)
          }}
        >Edit note
        </div>
        {(isStatus === 'PENDING' || activeTab !== 'direct sale tickets') && <div className='popoverBtn popove`rBtnRemove' onClick={() => handleCancel(inviteID)}>Cancel</div>}
      </div>
    )
  }

  const defaultCol = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: isUser => isUser || '',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      render: isMobile => isMobile || '',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
  ]
  const readyCol = {
    all: [
      ...defaultCol,
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: (_, { isScanned, inviteID }) => isScanned !== undefined && (

          <div
            className={c('ticketDistributionStatus', { isScanned }, { notScanned: !isScanned })}
          >{isScanned ? 'Scanned' : 'Not Scanned'}
          </div>
        ),
      },
      {
        title: 'Seat',
        key: 'seat',
        dataIndex: 'seat',
        render: (_, { seat }) => seat && (
          <div>{seat}</div>
        ),
      },
      {
        key: 'moreOptions',
        dataIndex: 'moreOptions',
        render: (_, { isScanned, inviteID }) => {
          return (
            <>{isScanned
              ? (<></>)
              : (
                <Popover
                  overlayClassName='actionPopoverContainer'
                  getPopupContainer={node => node?.parentNode}
                  content={() => handleModalVisibleModalConfirmScanner(inviteID)}
                  placement='top'
                  trigger='click'
                >
                  <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                  <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
                </Popover>
              )}

            </>
          )
        },
      },
    ],
    public: [
      ...defaultCol,
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Purchased on',
        key: 'sentOn',
        dataIndex: 'sentOn',
      },
      {
        title: 'Note to promoter',
        key: 'notes',
        dataIndex: 'notes',
        render: (note, inviteID) => {
          return (
            <div className='editNoteWrapper'>
              <div className='promoterNote'>
                {note}
              </div>
              <img
                onClick={() => {
                  handleOpenNotesModal()
                  setCurrentInviteID(inviteID.inviteID)
                }} src={EditIcon} alt='editIcon'
              />
            </div>
          )
        },
      },
    ],
    direct: [
      ...defaultCol,
      {
        title: 'Qty',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: price => (
          `$${price}`
        ),
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: isStatus => (
          <div className={c('ticketDistributionStatus',
            { isAccepted: isStatus === 'ACCEPTED' },
            { isPending: isStatus === 'PENDING' },
            { isRejected: isStatus === 'REJECTED' })}
          >{isStatus}
          </div>
        ),
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
        render: note => (
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px', overflow: 'hidden' }}>
            {note}
          </div>
        ),
      },
      {
        key: 'status',
        dataIndex: 'status',
        render: (isStatus, record) => {
          return (
            <>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={() => popoverContent(record?.inviteID, isStatus)}
                placement='top'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </>
          )
        },
      },
    ],
    cash: [
      ...defaultCol,
      {
        title: 'Qty',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Price',
        key: 'price',
        dataIndex: 'price',
        render: price => (
          `$${price}`
        ),
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
        render: note => (
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px', overflow: 'hidden' }}>
            {note}
          </div>
        ),
      },
      {
        dataIndex: 'inviteID',
        render: (inviteID, record) => {
          return (
            <>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={() => popoverContent(inviteID === undefined ? record.inviteID : inviteID)}
                placement='top'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </>
          )
        },
      },
    ],
    sub: [
      ...defaultCol,
      {
        title: 'Vanity URL',
        key: 'vanityUrl',
        dataIndex: 'vanityUrl',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: isStatus => (
          <div className={c('ticketDistributionStatus',
            { isAccepted: isStatus === 'ACCEPTED' },
            { isPending: isStatus === 'PENDING' },
            { isRejected: isStatus === 'REJECTED' })}
          >{isStatus}
          </div>
        ),
      },
    ],
    rest: [
      ...defaultCol,
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Note',
        key: 'note',
        dataIndex: 'note',
        render: note => (
          <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '180px', overflow: 'hidden' }}>
            {note}
          </div>
        ),
      },
      {
        dataIndex: 'inviteID',
        render: (inviteID, record) => {
          return (
            <>
              <Popover
                overlayClassName='actionPopoverContainer'
                getPopupContainer={node => node?.parentNode}
                content={() => popoverContent(inviteID === undefined ? record.inviteID : inviteID)}
                placement='top'
                trigger='click'
              >
                <img src={ThreeDotIcon} className='threeDots mdVisible' alt=' ' />
                <CustomButton className='actionPopoverButton xsVisible' text='More Options' />
              </Popover>
            </>
          )
        },
      },
    ],
  }
  const actionButton = (currentEventDetails?.hasPublicStream && !activeTable.includes('public') && !activeTable.includes('all') || !currentEventDetails?.isStreamingEvent && !activeTable.includes('public') && !activeTable.includes('all') && (isAdmin(role) || !currentEventDetails.isEventBlocked && !currentEventDetails.isEventCancelled)
    ? (
      <MainButton
        text={activeTable.includes('direct') || activeTable.includes('cash')
          ? 'Sell Tickets'
          : activeTable.includes('promot')
            ? 'Sub-promote Tickets'
            : 'Gift Tickets'}
        className='btnAction'
        onClick={handleModal}
      />)
    : <div />)

  const placeholder = switchByActiveTable('Search by ticket owners', 'Search by publicly sold tickets', 'Search by directly sold tickets', 'Search by gifted tickets', 'Search by cash/zelle tickets', 'Search by sub-promoters')

  const searchPanel = (
    <div className='searchPanel'>
      <Input
        className='inputSearch darkBg noFocusBorder'
        placeholder={placeholder}
        onChange={e => onChangeSearch(e)}
        prefix={<img alt='search icon' src={SearchIcon} />}
        allowClear
      />
    </div>)

  const includes = (item, key) => item[key] ? item[key].toLowerCase().includes(searchValue.toLowerCase()) : false
  const filterData = data =>
    !searchValue ? data : data?.filter(item => includes(item, 'user') || includes(item, 'email') || includes(item, 'mobile') || includes(item, 'note'))

  // Filter stream tickets for gift tab
  const tabs = (
    <Tabs
      onChange={id => setTicketID(id)} type='card' className='customTabs'
    >
      {noStreamTickets(currentEventDetails?.tickets).map(i => (
        <TabPane tab={i.ticketName} key={i._id} />
      ))}
      {(activeTable !== 'all tickets') && currentEventDetails?.bundles?.map(bundle => (
        <TabPane tab={bundle.bundleName} key={bundle.id} />
      ))}
    </Tabs>
  )
  const catTabs = () => (
    <div className='categoryTabs'>
      {tabs}
      <Input
        className='inputSearch darkBg noFocusBorder'
        placeholder={placeholder}
        onChange={e => onChangeSearch(e)}
        prefix={<img alt='search icon' src={SearchIcon} />}
        allowClear
      />
    </div>
  )
  const ticket = currentEventDetails?.tickets?.find(ticket => ticket._id === ticketID)
  const columns = switchByActiveTable(ticket?.isSeatingCategory ? readyCol.all : readyCol.all.filter(col => col.key !== 'seat'), readyCol.public, readyCol.direct, readyCol.rest, readyCol.cash, readyCol.sub)
  const dataSource = switchByActiveTable(filterData(publicSaleData), filterData(publicBuyData), filterData(directSaleData), filterData(giftData), filterData(cashCheckSaleData), filterData(subPromotionsData))
  const onMainTabChanged = pathname => {
    setActiveMainTab(pathname)
    history.push({ pathname, state })
  }
  return (
    <>
      <div className='wrapperTablesAssignSeating ticketDistribution'>
        <div ref={scrollRef} className='titleAssignedSeating'>Ticket Distribution</div>
        <div className='navigation_wrap'>
          <Tabs
            onChange={onMainTabChanged}
            animated={false}
            className='distributionNavTabs'
            activeKey={activeMainTab}
            tabBarExtraContent={{ right: actionButton }}
          >
            {distributionNavigation.map(i => (
              <TabPane tab={i.text} key={i.to} />
            ))}
          </Tabs>
          {actionButton}
        </div>
        <div className='table_content'>
          {window.innerWidth > 1400
            ? (
              <Table
                columns={columns}
                pagination={false}
                loading={loading}
                dataSource={!loading ? dataSource : null}
                title={() => catTabs()}
                className='eventDetailsTable'
              />
            )
            : (
              <div className='mobileTable'>
                {tabs}
                {searchPanel}
                <MobileTable data={dataSource} columns={columns} loading={loading} />
              </div>)}
          <Pagination
            hideOnSinglePage total={dataLength} pageSize={perPage} onChange={page => setCurrentPage(page)}
            current={currentPage} className='pagination'
          />
        </div>
      </div>
      <ModalTicketAction
        actionType={activeTable.includes('direct') || activeTable.includes('cash') ? 'sell' : activeTable.includes('promot') ? 'promote' : 'gift'}
        giftActiveTable={activeTable}
        visible={visibleModal}
        eventID={eventID}
        giftActiveTab={activeTab}
        updateTicketsOnPage={updateTicketsOnPage}
        eventDetails={currentEventDetails}
        categories={noStreamTickets(currentEventDetails.tickets)}
        bundles={currentEventDetails.bundles}
        updateVisible={handleModal}
        submitForm={formTicketAction}
        currencySymbol={currencySymbol}
        openModalForm={setVisibleModal}
        loading={actionProcessing || loadUrls}
        urls={urls}
        setTicketID={setTicketID}
      />
      <ModalEditNote noteTo='promoter' currentNote={dataSource?.filter(item => item.inviteID === currentInviteID)[0]?.note} onFinish={onFinishNotes} onChange={handleOpenNotesModal} visible={openNotesModal} loading={loading} />
      {/* <ModalConfirmScanner */}
      {/*  visible={visibleModalConfirmScanner} */}
      {/*  updateVisible={setVisibleModalConfirmScanner} */}
      {/*  dataSource={dataModalConfirmScanner !== null ? dataModalConfirmScanner : null} */}
      {/*  updateDataSource={setDataModalConfirmScanner} */}
      {/*  loading={actionProcessing} */}
      {/*  eventID={eventID} */}
      {/*  barcode={null} */}
      {/* /> */}
    </>
  )
}

export default Distribution
