import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { Modal } from 'antd'
import { FormPayment } from 'components'
import { getFee } from '../../modules/events/events-actions'
import { getCurrencySymbolByCode, getUtcOffsetDate } from 'utils'
import { payment as paymentApi } from 'apiv2'
import closeIconSvg from 'images/Crossmark.svg'
import c from 'classnames'
import './index.styl'

const getFixed = num => (typeof num !== 'number' || isNaN(num)) ? ' ' : num % 1 === 0 ? num : num.toFixed(2)

const ModalAcceptInvite = ({ inviteData, onCloseModal }) => {
  const [visible, setVisible] = useState(false)
  const [fee, setFee] = useState([])
  const [selectedOption, setSelectedOptions] = useState('Stripe')
  const [totalPriceVenmo, setTotalPriceVenmo] = useState(0)
  const [preorderCode, setPreorderCode] = useState('')
  const [totalFee, setTotalFee] = useState(0)
  const dispatch = useDispatch()
  const { eventName, image, startDate, endDate, resellPrice, quantity, guest, inviteType, eventID, inviteID } = inviteData
  useEffect(() => {
    setVisible(inviteData)
  }, [inviteData])
  const onCancel = () => {
    setVisible(false)
    setTimeout(() => onCloseModal(), 200)
  }
  useEffect(() => {
    const ticketDetails = [{
      ticketID: inviteData.ticketID,
      quantity: quantity,
      price: resellPrice,
    }]
    const arrToFee = [{ quantity: inviteData.quantity, price: resellPrice, ticketID: inviteData.ticketID }]
    selectedOption === 'Stripe' && inviteType === 'SELL'
      ? dispatch(getFee(eventID, guest ? 'GUEST' : 'USER', arrToFee, inviteData?.currency, 'Stripe'))
        .then(res => {
          setFee(res)
          setTotalFee(res.reduce((a, b) => a + (b.amount || 0), 0))
        })
      : dispatch(getFee(eventID, guest ? 'GUEST' : 'USER', arrToFee, inviteData?.currency, 'Venmo'))
        .then(res => {
          setFee(res)
          const totalFee = res.reduce((a, b) => a + (b.amount || 0), 0)
          setTotalFee(res.reduce((a, b) => a + (b.amount || 0), 0))
          setTotalPriceVenmo(resellPrice * quantity + totalFee)
          const obj = {
            ticketDetails: JSON.stringify(ticketDetails),
            paymentSum: resellPrice * quantity + totalFee,
            eventID: eventID,
            inviteID: inviteID,
            paymentType: 'venmo',
          }
          paymentApi.preorderId(obj).then(res => setPreorderCode(res.data.preorderCode))
        })
    // : dispatch(getFee(guest ? 'GUEST' : 'USER', arrToFee, inviteData?.currency, 'venmo'))
    //   .then(res => {
    //     setFee(res)
    //     const totalFee = res.reduce((a, b) => a + (b.amount || 0), 0)
    //     setTotalFee(res.reduce((a, b) => a + (b.amount || 0), 0))
    //     const obj = {
    //       ticketDetails: JSON.stringify(ticketDetails),
    //       paymentSum: resellPrice * quantity + totalFee,
    //       eventID: eventID,
    //       inviteID: inviteID,
    //       paymentType: 'venmo',
    //     }
    //     paymentApi.preorderId(obj).then(res => setPreorderCode(res.data.preorderCode))
    //   })
  }, [guest, selectedOption])

  const totalPrice = resellPrice * inviteData?.quantity
  const currencySymbol = getCurrencySymbolByCode(inviteData?.currency)

  const startDateTransformed = getUtcOffsetDate(startDate)
  const endDateTransformed = getUtcOffsetDate(endDate)

  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  return (
    <Modal title='Purchase tickets' visible={visible} closeIcon={closeIcon} className='modal_accept-invite' footer={null} onCancel={onCancel}>
      <div className='invite_info'>
        <div className='invite_info_head'>
          <div className='imgWrap'>
            <img
              src={image}
              // onError={e => (e.target.src = cardImg)}
              alt='cardDetail'
              className='img'
            />
            <div className='imgCover' />
          </div>
          <div className='face'>
            <div className='title'>{eventName}</div>
            <div className='date'>
              {moment(startDate).format('MMMM DD, YYYY')}
              {/* <img src={calendar} alt='calendar' className='dateIcon' />
                <div className='date'>{moment(startDate).format('MMMM DD, YYYY')}</div> */}
            </div>
          </div>
        </div>
        <div className='invite_info_body'>
          <div className='title'>Purchase:</div>
          <div className='body_content'>
            <div className='content_row'>
              <div className='label'>Time</div>
              <div className='value'>{`${startDateTransformed} - ${endDateTransformed} `}</div>
            </div>
            <div className='content_row'>
              <div className='label'>Quantity</div>
              <div className='value'>{quantity}</div>
            </div>
            <div className='content_row'>
              <div className='label'>Per ticket</div>
              <div className='value'>{currencySymbol}{getFixed(resellPrice)}</div>
            </div>
            <div className='content_row'>
              <div className='label'>Price</div>
              <div className='value'>{currencySymbol}{getFixed(totalPrice)}</div>
            </div>
            {fee.map((item, index) => (
              <div key={index} className='content_row'>
                <div className='label'>{item.name}</div>
                <div className='value'>{currencySymbol}{getFixed(item?.amount)}</div>
              </div>
            ))}
            <div className='content_row'>
              <div className='textTotal'>Total</div>
              <div className={c('value', 'textTotal')}>{currencySymbol}{getFixed(totalPrice + totalFee)}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='invite_payment'>
        <FormPayment
          currencySymbol={currencySymbol}
          orderedTickets={[inviteData]}
          setSelectedOptions={setSelectedOptions}
          selectedOption={selectedOption}
          preorderCode={preorderCode}
          isResell
          inviteType={inviteType}
          totalPrice={totalPrice + totalFee}
          totalPriceVenmo={totalPriceVenmo}
          callback={onCancel}
        />
      </div>
    </Modal>
  )
}

export default ModalAcceptInvite
