import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setMockTicket } from 'modules/events/events-actions'
import { Form, Input, notification } from 'antd'
import { OverflowNotes, CustomButton, BarcodeSlider } from 'components'
import MockTicketDetailsPreview from './MockTicketDetailsPreview'
import MockTicketDetailsForm from './MockTicketDetailsForm'
import MockTicketEventDetails from './MockTicketEventDetails'
import MockTicketEventForm from './MockTicketEventForm'
import email from 'images/email.svg'
import edit from 'images/editWhite.svg'
import { getRule } from './MockTicketDetailsForm/formHelpers'
import './index.styl'
import moment from 'moment'
import c from 'classnames'

const { Item } = Form
const { TextArea } = Input

const IMAGE = 'https://s3.amazonaws.com/devstatic.blulineticket.com/dev/user/thumbnail1_HJxqHOTt6_1626097729771b.jpeg'
const EVENT_NAME = 'My event'

const MockTicket = ({
  ticketData,
  wrapperClassName = '',
  tag = 'CreateTicket',
  onCancel,
}) => {
  const isCreateTicket = tag === 'CreateTicket'
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const [editName, setEditName] = useState(false)
  const [currentInd, setCurrentIndex] = useState(1)
  const [eventName, setEventName] = useState(EVENT_NAME)
  const ticketQuantity = 100

  if (ticketQuantity && currentInd > ticketQuantity) setCurrentIndex(ticketQuantity)
  if (eventName.trim() === '') setEventName(EVENT_NAME)

  const renderNotesMediaQuery = query => (
    <div className={query}>
      {
        isCreateTicket
          ? (
            <>
              <div className='notesTitle'>Notes</div>
              <Form form={form}>
                <Item
                  name='eventDescription'
                  className='item'
                  rules={getRule('Event Details', 'eventDescription')}
                  normalize={v => v.trimStart()}
                >
                  <TextArea className='textArea' placeholder='Your notes' rows={4} />
                </Item>
              </Form>
            </>
          )
          : (
            <>
              {!!ticketData?.ticket?.description && (
                <OverflowNotes notes={ticketData?.ticket?.description} style={{ wordBreak: 'break-all' }} />
              )}
            </>
          )
      }
    </div>
  )

  const renderSubtitle = (
    <div className='eventSubtitle'>
      {!!ticketData?.presentedBy?.length && (<span className='presentedBy'>Presented by <span>{ticketData?.presentedBy}</span></span>)}
      <span className='email'><img src={email} alt=' ' />Email to Promoter</span>
    </div>)

  const handleSubmit = () => {
    if (!eventName) {
      return notification.error({ message: 'Please enter Event Name' })
    }
    form.validateFields()
      .then(v => {
        dispatch(setMockTicket({
          _id: '60e5d5831bcbbe001c72fe65',
          eventID: '60e5d5831bcbbe001c72fe65',
          eventName: eventName,
          ticket: {
            description: v.eventDescription,
          },
          presentedBy: v.presentedBy,
          eventType: v.eventType,
          venue: v.venue,
          quantity: v.quantity,
          startDate: moment(moment(v.date).format('YYYY-MM-DD') + moment(v.time[0]).format('HH:mm'), 'YYYY-MM-DDLT').format(),
          endDate: moment(v.time[1]).format(),
          address: v.location,
          timeZone: 'US/Eastern',
          originalPrice: v.price,
          currency: 'USD',
          image: IMAGE,
        }))

        history.push('/mock-ticket')
        onCancel()
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }).catch(() => notification.error({ message: 'All fields are required' }))
  }

  const handleQRClick = () => {
    history.push('/')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={c('MockTicket', wrapperClassName)}>
      <div className='wrapperBack'>
        <div className='mobileContent'>
          {isCreateTicket ? (
            <div className='eventTitle'>
              {!editName && (eventName || <span className='emptyName'>Enter Event Name</span>)}
              {editName ? (
                <Form
                  form={form}
                  className='form'
                  layout='vertical'
                >
                  <Item
                    name='eventName'
                    className='formItem eventName'
                    rules={getRule('Event Name', 'eventName')}
                    validateTrigger='onBlur'
                  >
                    <Input
                      autoFocus
                      className='input darkTwoBg'
                      placeholder='Add event name'
                      onChange={e => setEventName(e.target.value)}
                      onBlur={() => setEditName(false)}
                    />
                  </Item>
                </Form>)
                : (<div className='editIcon' onClick={() => setEditName(true)}><img src={edit} alt=' ' /></div>)}
            </div>
          ) : (
            <>
              <div className='eventTitle'>{ticketData?.eventName}</div>
              {!isCreateTicket && renderSubtitle}
            </>
          )}
        </div>
        {isCreateTicket
          ? <MockTicketDetailsForm {...ticketData} image={IMAGE} eventName={eventName} setEventName={setEventName} form={form} />
          : <MockTicketDetailsPreview {...ticketData} image={IMAGE} presentedBy={renderSubtitle} />}
      </div>
      <div className='middleContainer'>
        <div className='decorationWrapper'>
          <div className='darkDecoration' />
          <div className='lightDecoration' />
        </div>
        <div className={isCreateTicket ? 'createContentWrapper' : 'previewContentWrapper'}>
          <div className='middleContent'>
            <div className='leftSide'>
              {isCreateTicket
                ? (
                  <MockTicketEventForm
                    {...ticketData}
                    form={form}
                  />
                ) : <MockTicketEventDetails {...ticketData} />}
              {renderNotesMediaQuery('smVisible')}
            </div>
            <div className='rightSide'>
              {
                ticketQuantity && (
                  <BarcodeSlider
                    onNext={() => setCurrentIndex(currentInd + 1)}
                    onPrev={() => setCurrentIndex(currentInd - 1)}
                    isNext={currentInd < ticketQuantity}
                    total={ticketQuantity}
                    showSlider={ticketQuantity}
                    isScanned={false}
                    isPrev={currentInd > 1}
                    current={currentInd}
                    barcode={location.origin}
                    onClick={handleQRClick}
                  />
                )
              }
            </div>
            {renderNotesMediaQuery('xsVisible')}
          </div>
        </div>
      </div>
      <div className='bottomLine' />
      <div className='bottomDesign'>
        {isCreateTicket && <CustomButton className='button' text='Make Ticket' type='dark' onClick={handleSubmit} />}
      </div>
    </div>
  )
}

export default MockTicket
