import React from 'react'
import { Modal } from 'antd'
import './index.styl'

const MBModalConfirmAction = ({ onCancel, modalData, loading }) => {
  return (
    <Modal
      maskStyle={{ background: '#62677EE5', display: 'block' }}
      okButtonProps={{ loading, disabled: loading }}
      onOk={() => modalData.action(modalData?.id)}
      cancelButtonProps={{ disabled: loading }}
      className='MBModalConfirmAction'
      onCancel={onCancel}
      closable={!loading}
      cancelText='No'
      okText='Yes'
      visible
    >
      <div className='contentTitle'>{modalData.title}</div>
    </Modal>
  )
}

export default MBModalConfirmAction
