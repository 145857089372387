import React from 'react'
import { Link } from 'react-router-dom'
import signInArrow from 'images/signInArrow.svg'
import TLManImg from 'images/TLMan.png'
import VLImage from 'images/VLImage.png'
import PromoterLyfeImg from 'images/promoterLyfe.png'
import PPVStreamImg from 'images/PPVStream.png'
import JuniorRockImg from 'images/juniorRock.png'
import ContentCreatorLyfeImg from 'images/contentCreatorLyfe.png'
import TablesAndChairsImg from 'images/tablesAndChairs.png'
import ChanceAtLyfeImg from 'images/chanceAtLyfe.png'
import PickTypeOfLyfeImg from 'images/pickTypeOfLyfe.png'
import { Fade } from 'react-awesome-reveal'

import './index.styl'
import AccordionFAQ from './AccordionFAQ'

const Body = () => {
  const accordionData = [
    {
      title: 'How do I join Ticket Lyfe?',
      content: 'Before you make decisions in Lyfe, know your paths.&nbsp&nbsp<b>User Accounts are FREE</b>, but if you are a Venue, you\'ll need to make a <b>Venue Account</b>.&nbsp&nbspWhich is different from a User Account.&nbsp&nbspVenue Accounts are all about selling tickets at One Venue, while User Accounts can be used anywhere to Make, Sell, Buy Tickets.&nbsp&nbspA <b>Promoter Partner</b> is an upgrade from a Regular User Account, to a Partner who will share in our profits.&nbsp&nbspBoth Venue and Promoter Partner Accounts cost $200 annually, and again, User Accounts are FREE.',
    },
    {
      title: 'Why Venue Lyfe?',
      content: `No—the question is if you’re running a Venue, <b><i>why aren’t you using Venue Lyfe already?</i></b>   
       <br><br>
      If tickets are being sold to events at your Venue, then you should have: the low-down, the insight, the overview, the wherewithal, the bottom line, and the whole scoop as to what’s going on....&nbsp&nbspBecause it’s Your Venue.&nbsp&nbspPromoters and Venue Owners debating over head-counts; decisions on the number of staff working on the night of; questions about who really attended your venue, or what kind of cash was enumerated by an event—let all these things be questions of the past....&nbsp&nbsp
       <br><br>
      These are the questions that should be answered even before they're asked:&nbsp&nbsp&nbsp<b>Who</b> is buying Tickets?&nbsp&nbsp<b>What</b> rate of speed are the Tickets selling at?&nbsp&nbsp<b>Why</b> wouldn’t you want to know this?&nbsp&nbsp<b>Where</b> are the extra bar tenders for this sold-out event?&nbsp&nbsp<b>When</b> did these tickets sell?&nbsp&nbsp<b>How</b> many tickets are being sold each week, or day, or minute?  Look no further than Venue Lyfe for the answers.`,
    },
    {
      title: 'How does Venue Lyfe work?',
      content: `If you’re running a Venue then you’re either throwing events, or you’re renting your establishment out.&nbsp&nbspIf you’re throwing the event, then you’re making the tickets.&nbsp&nbspMaybe you have Promoters helping with the Ticket sales.&nbsp&nbspIn the Ticket Lyfe world, we call them <b>Sub-Promoters</b>.&nbsp&nbspAfter making your tickets, you can Sub-Promote if you want to—by giving them their own link.&nbsp&nbspOtherwise everyone just uses your link.&nbsp&nbspEither way, it’s your Box Office, and you’ll see all the Data on sales.
       <br><br>
      If you’re not throwing the event, then you’re renting out your place. Then the renter is making their own tickets and selling them.&nbsp&nbspAt Venue Lyfe we call that person a <b>Co-Promoter</b>, and instead of letting them go out-of-network with the Tickets, you start off their ticket with a few preliminary items—name, date, email, mobile number.&nbsp&nbspThen the Co-Promoter finishes making the ticket.&nbsp&nbspAs soon as that happens the Venue gets to see the Co-Promoter’s Box Office.&nbsp&nbspThe Ticket Data that has been going out of the window is now yours. Simple as that.
      `,
    },
    {
      title: 'Promoter Partner Lyfe?',
      content: 'Who looks out for the one that’s throwing the events?&nbsp&nbspWorking at an event from all different angles and times, you sometimes have to defy gravity in order to get the show on.&nbsp&nbspSo many questions and matters to take care, but who is looking out for you?&nbsp&nbspWhere’s your payback?&nbsp&nbspLook no further that Ticket Lyfe…. Become a Promoter Partner and get a point and a half paid to you over and above your natural Promoter’s Take from your Ticket sales.&nbsp&nbspYes, you heard it right.&nbsp&nbspAfter you get paid what you’re owed, Ticket Lyfe gives you a share of what they charge.&nbsp&nbspBecause you’re a partner. ',
    },
    {
      title: 'What Kind of Ticket are we Making Anyway?',
      content: `Yes, you’re right.&nbsp&nbspWe let you make Tickets in Anyway you want.
    <br><br> 
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspNeed a Ticket to enter?&nbsp&nbspThat’s called <b>Ticketed Admission</b>.
    <br><br> 
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspVIP or kids’ discounts?&nbsp&nbsp Add new <b>Ticket Categories</b>.
    <br><br> 
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspGot <b>Tables and Chairs</b> that you want to arrange and sell?&nbsp&nbspTables or Assigned Seating (sections, lettered rows and numbered seats) are your choices for <b>Special Seating</b>.
    <br><br>
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspWant to do a Pay Per View Ticket for your Live Stream?&nbsp&nbspNo problem, we’ll get a RMTP URL and Stream Key.
    <br><br>
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspNot ready for all that videography, but you still want to zoom out a Virtual Event and monetize that?&nbsp&nbsp Then just Ticket your <b>Virtual Meeting</b>.
    <br><br> 
    ·&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbspA Church or a Non-Profit <b>Raffle</b>?&nbsp&nbspYes, we have a <b>Random Draw</b> for you.&nbsp&nbspAnd for all of your events, you can pick a random winner for other prizes as well.`,
    },
  ]
  return (
    <>
      <div className='newHomePageBodyWrapper'>
        <>
          <div className='wrapperContent oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={TLManImg} alt='' />
              </div>
            </Fade>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Ticket Lyfe</div>
                <div className='subtitle'>To be Super you need some Super gear. How bullet proof is a live ticket? One where you can just change things as you go if need be—without worrying about a reprint. Paid tickets, free tickets, VIP ticket, virtual tickets, pay per views, table or assigned seating: Hey! Why not do what you want to do? Why be average, when you can be Super….</div>
              </div>
            </Fade>
          </div>
          <div className='wrapperContentReversed oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Venue Lyfe</div>
                <div className='subtitle'>Imagine you’re running a Venue — rented out to a promoter. You’d like to know how the Promoter is doing — how many tickets have been sold, what the head count is at the door, how different events compare to each other, and how many busboys should you use this Saturday night? Now we’re not saying there won’t be a heck of a walk-up crowd but we are saying knowledge is power. Your venue, you’ve got the right to know…  Yes, we love you too.</div>
              </div>
            </Fade>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={VLImage} alt='' />
              </div>
            </Fade>
          </div>
          <div className='wrapperContent oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={PromoterLyfeImg} alt='' />
              </div>
            </Fade>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Promoter Lyfe</div>
                <div className='subtitle'>So who takes care of the one who takes care of everything? Where’s the thank-you for the One who is behind the Event? So many things to put together, yet so little light… that’s just not right. And yet you’ve been loyal to those who’ve never acknowledged that you bring in the business. Well Ticket Lyfe sees you.  Yes you. So as a Promoter Partner, we’re kicking back a point and a half—up to a buck-fifty—on each ticket you sell through us. Because if we’re going to work together, let’s agree to a give and ticket relationship. Put it there, Partner.</div>
              </div>
            </Fade>
          </div>
          <div className='wrapperContentReversed oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Pay Per View Streaming Lyfe</div>
                <div className='subtitle'>Okay, so maybe you’re not up to having your own Roku channel. But you are a videographer; or have one ready to go—because you’ve got an event that is Pay Per View right now. If that’s the case we’ve got your URL and Stream Key ready to go too. And besides going up live, we’ll keep you up as an On-Demand, for a full year. So you can keep on selling your stream. And keep living your dream.</div>
              </div>
            </Fade>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={PPVStreamImg} alt='' />
              </div>
            </Fade>
          </div>
          <div className='wrapperContent oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={JuniorRockImg} alt='' />
              </div>
            </Fade>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Junior Rock Band Lyfe</div>
                <div className='subtitle'>Just because you don’t have a videographer doesn't mean you don’t have talent. You want to do a show. Your whole class wants your band to go live. You just know you can rock this. With Ticket Lyfe you can monetize virtual meetings with tickets—like Zoom or Google Meets. With your laptop or your buddy holding a phone, you can still go live for your show. And why not? Every band has to start somewhere-- and where else are you going to get a paying gig?</div>
              </div>
            </Fade>
          </div>
          <div className='wrapperContentReversed oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Content Creator Lyfe</div>
                <div className='subtitle'>You’re a Content Creator with a niche audience. You need a private stream. You don’t want to tell us why and we really don’t need to know anyway. Whether you’ve got the video skills to use a custom URL and Stream Key or you just zoom through it we’re good with it. Do what you gotta do. We’re only here to help. It’s your show.</div>
              </div>
            </Fade>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={ContentCreatorLyfeImg} alt='' />
              </div>
            </Fade>
          </div>
          <div className='wrapperContent oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={TablesAndChairsImg} alt='' />
              </div>
            </Fade>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>The Lyfe of Tables and Chairs</div>
                <div className='subtitle'>We understand that not all events are first come, first serve. Some events need order. Table seating, or assigned seating—sections, rows, seats with numbers. Not everyone wants to run for seats like they’ve bought a flight on a budget airline where the process of boarding is just a mad scramble…. We get that. So if you need special seating, go ahead and make that happen. Because we’re not about that mad scramble either (unless you want us to be).</div>
              </div>
            </Fade>
          </div>
          <div className='wrapperContentReversed oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>A Chance at Lyfe</div>
                <div className='subtitle'>If you’re a 501c3 charity then you do have the right to run raffle tickets. But just how many you can sell and what kind of prize you’re offering—well that depends on the local laws within your state. In other words, you can’t run a lottery. But you do have options. Go to your state’s website and make sure that your raffle is within the legal limits. And, if you’re not running raffles, it doesn’t mean that you can’t pull a random draw with the tickets of your events. You can give out the prizes you want to, from t-shirts to veggie burgers. One or more of your event attendees will be very happy when they win a prize picked by your Random Draw. An ordinary ticket, but with the threat of winning a prize for the holder thereof? Care to take a chance… or maybe give one.</div>
              </div>
            </Fade>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={ChanceAtLyfeImg} alt='' />
              </div>
            </Fade>
          </div>
          <div className='wrapperContent oneLevelWidthTextIMG'>
            <Fade triggerOnce direction='up'>
              <div>
                <img src={PickTypeOfLyfeImg} alt='' />
              </div>
            </Fade>
            <Fade triggerOnce direction='up' delay={500}>
              <div className='wrapperText'>
                <div className='title'>Pick Your Type of Lyfe</div>
                <div className='titleOfSubtitle'>User</div>
                <div className='pickTypeSubtitle'>You can make or buy tickets with a User Account. Buyers of tickets can manage their tickets with their own digital wallet, and a discount on User Fees.</div>
                <div className='titleOfSubtitle'>Promoter Partner</div>
                <div className='pickTypeSubtitle'>For the serious Promoters who want more than what they're charging. Partners share in our profits. One and a half percent on every ticket sold, up to a dollar fifty on each ticket sold through online sales. Your cost of this account is $200 annually.</div>
                <div className='titleOfSubtitle'>Venue Lyfe</div>
                <div className='subtitle'>You own a Venue and you want access to all of the data that anyone promoting an event at your venue has in their Ticket Lyfe Box Office. So all ticketed events go through you, and no Ticket Lyfe event can be made at your Venue's address without your say-so. Your cost of this account is $200 annually.</div>
              </div>
            </Fade>
          </div>
        </>
        <Fade triggerOnce direction='up' delay={500}>
          <div className='btnSignUpWrapper'>
            <Link className='btnSignUp' to='/auth/venue-sign-up'>
              <span className='text'>Venue Sign Up</span>
              <img src={signInArrow} alt='sign up arrow' />
            </Link>
            <Link className='btnSignUp' to='/auth/sign-up'>
              <span className='text'>User Sign Up</span>
              <img src={signInArrow} alt='sign up arrow' />
            </Link>
          </div>
        </Fade>
      </div>
      <div />
      <div className='wrapperFAQ'>
        <div className='titleFAQWrapper'>
          <div className='titleFAQ'>Frequently Asked Questions</div>
          <div className='accordionWrapper'>
            {accordionData.map((data, index) => (
              <AccordionFAQ key={index} title={data.title} content={data.content} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Body
