import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout, NavBack } from 'components'
import FAQComponent from './FAQ'
import Overview from './Overview/'
import Sidebar from './Sidebar'
import Terms from './Terms'
import c from 'classnames'
import './index.styl'

const Help = () => {
  const location = useLocation()
  const activePage = location.pathname.split('/')[2]

  const backButton = (
    <div className='help_xs_visible'>
      <NavBack path={location.state?.fromRouter || '/help'} />
    </div>
  )

  const HelpPage = (
    <div className='mobile_help_page'>
      <Sidebar />
    </div>
  )

  const helpObj = {
    overview: <Overview />,
    faq: <FAQComponent />,
    'terms-and-conditions': <Terms />,
    mobilePage: HelpPage,
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout className='wrapperTermsAndConditions'>
        <div className={c('help_wrap', { full_page: !activePage })}>
          <Sidebar />
          <main className='help_content'>
            {!!activePage && backButton}
            {helpObj[activePage || 'mobilePage']}
          </main>
        </div>
      </Layout>
    </>
  )
}

export default Help
