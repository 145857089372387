import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { Upload, Progress } from 'antd'
import {
  uploadImage,
  uploadAttachment,
  eventsSetFiles,
  eventsDeleteFile,
  preparePoster,
} from 'modules/events/events-actions'
import plus from 'images/red-plus.svg'
import { getCroppedImg, notify } from 'utils'
import { CancelToken } from 'api/axios'

import c from 'classnames'
import './index.styl'
import ModalCropImage from '../../../../CreateEvent/MultiMediaContainer/ModalCropImage'
import UploadComponent from '../UploadComponent'
import { CreateEventContext } from '../../../index'

const { Dragger } = Upload
const onNotifyDenied = () => notify('Invalid file extension')

const getCoverName = imgUrl => imgUrl.replace(/^.*[\\\/]/, '')
const cropDefault = { unit: '%', width: 240, aspect: 16 / 9 }

const UploadImage = () => {
  const dispatch = useDispatch()
  const { currentEventDetails } = useContext(CreateEventContext)

  const [loadingCover, setLoadingCover] = useState(false)
  const [progressCover, setProgressCover] = useState(false)
  const [loading, setLoading] = useState(null)
  const [ticketCover, setTicketCover] = useState('')
  const [ticketCoverName, setTicketCoverName] = useState(getCoverName(ticketCover))
  const [fileNameTemp, setFileNameTemp] = useState('')
  const [filesList, setFilesList] = useState([])
  const [fileDataBlob, setFileDataBlob] = useState(null)
  const [crop, setCrop] = useState(cropDefault)
  const [originalImage, setOriginalImage] = useState('')

  const cancelRequest = useRef(null)
  const imageRef = useRef(null)
  const onLoad = useCallback(img => (imageRef.current = img), [])

  const uploadProgress = setFunc => progressEvent => {
    setFunc(Math.round((progressEvent.loaded * 100) / progressEvent.total))
  }

  const clearTempState = () => {
    setFileDataBlob(null)
    setFileNameTemp('')
    setCrop(cropDefault)
  }

  const prepareFilesList = (files = []) => files.map(obj => {
    return { uid: obj?.original, name: obj?.fileName, uploadedUrl: obj?.original, thumbUrl: obj?.thumbnail }
  })

  useEffect(() => {
    if (currentEventDetails?.images?.thumbnail1) {
      setTicketCover(currentEventDetails?.images?.thumbnail1)
      setTicketCoverName(getCoverName(currentEventDetails?.images?.thumbnail1))
    }
    if (currentEventDetails?.attachments?.length) {
      dispatch(eventsSetFiles(currentEventDetails?.attachments))
      setFilesList(prepareFilesList(currentEventDetails?.attachments))
    }
    return () => dispatch(eventsSetFiles([]))
  }, [currentEventDetails])

  const handleConfirmModify = async () => {
    setLoadingCover(true)
    await getCroppedImg(imageRef.current, crop, ticketCoverName)
      .then(file => {
        const onUploadProgress = uploadProgress(setProgressCover)
        const cancelToken = new CancelToken(cancel => (cancelRequest.current = { cancel }))
        dispatch(uploadImage(originalImage, file, { onUploadProgress, cancelToken })).then(response => {
          if (response) {
            setTicketCover(response.original)
            setTicketCoverName(fileNameTemp)
            clearTempState()
          }
          setLoadingCover(false)
          setProgressCover(null)
          cancelRequest.current = null
        })
      })
      .catch(err => {
        notify(err?.message)
        setLoadingCover(false)
      })
  }

  const onCancel = () => {
    clearTempState()
    if (cancelRequest.current?.cancel) {
      cancelRequest.current.cancel('Upload canceled')
    }
  }

  const onFileChanged = async image => {
    if (image.file.status !== 'done') return
    if (image?.file?.type !== 'image/jpeg' && image?.file?.type !== 'image/png') {
      return onNotifyDenied()
    }
    setLoadingCover(true)
    setFileNameTemp(image?.file?.name)
    setOriginalImage(image?.file?.originFileObj)

    const onUploadProgress = uploadProgress(setProgressCover)
    dispatch(preparePoster(image, { onUploadProgress, responseType: 'blob' }))
      .then(response => {
        const blob = URL.createObjectURL(response.data)
        setFileDataBlob(blob)
        setLoadingCover(false)
      })
      .catch(err => {
        notify(err?.message || err?.response?.data?.message)
        setFileNameTemp(null)
        setLoadingCover(false)
      })
  }

  const onChangeFiles = async data => {
    const { onSuccess, file } = data
    // if (data?.file?.status === 'uploading') {
    //  setLoading(true)
    //  setProgress(Math.round((data?.file?.percent || 0) * 100))
    // } else if (data?.file?.status === 'done') {
    //  if (filesList.length > 4) {
    //    return
    //  }
    //  if (isDenied(data?.file?.type)) {
    //    return onNotifyDenied()
    //  }
    //  if (isSizeDenied(data?.file?.size)) {
    //    return onNotifyDeniedSize()
    //  }
    //  setLoading(true)
    // let imgData = {}
    // if (data.file.type.includes('image')) {
    //  imgData = await new Promise(resolve => {
    //    const reader = new FileReader()
    //    reader.readAsDataURL(data.file.originFileObj)
    //    reader.onload = () => resolve(reader.result)
    //  })
    //  data.file.imgData = imgData
    // }
    const _fileList = [...filesList]
    const cancelToken = new CancelToken(cancel => {
      cancelRequest.current = { cancel }
    })
    const options = { cancelToken }
    dispatch(uploadAttachment(file, options)).then(response => {
      if (response) {
        setFilesList(_fileList.map(file => {
          if (file.uid === data.file.uid) {
            file.uploadedUrl = response?.original
            if (response?.thumbnail) {
              file.imgData = response?.thumbnail
            }
          }
          return file
        }))
      } else {
        setFilesList(filesList.filter(f => f.uid !== data.file.uid))
      }
      onSuccess('Ok')
      setLoading(false)
      cancelRequest.current = null
    })
    // } else if (data?.file?.status === 'error') {
    //  setLoading(false)
    //  setProgress(null)
    //  setFilesList(prevList => prevList.filter(item => item.uid !== data?.file?.uid))
    // }
  }
  const onSuccess = ({ onSuccess }) => onSuccess('ok')
  const showProgress = (progress, size = 52, isDark = false) => typeof progress === 'number' && (
    <div className={c('progressContainer', { isDark })}>
      <Progress
        trailColor='transparent'
        strokeLinecap='square'
        strokeColor='#19B47D'
        percent={progress}
        strokeWidth={10}
        showInfo={false}
        type='circle'
        width={size}
      />
    </div>
  )

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    // Using Hooks to update the state to the current filelist
    setFilesList(fileList)
    dispatch(eventsDeleteFile(file.uploadedUrl))
    // filelist - [{uid: "-1",url:'Some url to image'}]
  }
  const uploadButton = (
    <div className='upload specialDesignGutter'>
      <div className='uploadTitle'>
        Additional Files
        <br />
        <img src={plus} alt='plus' />
      </div>
    </div>
  )

  return (
    <div className='wrapperUploader'>
      <div className='uploadContainer'>
        <div className={c('uploadSpinner', { isFull: !!ticketCover })}>
          <Dragger
            accept='image/png, image/jpeg'
            onChange={onFileChanged}
            customRequest={onSuccess}
            showUploadList={false}
            disabled={loadingCover}
            name='image'
          >
            <div className={c('upload', { ticketCover: !!ticketCover })}>
              {loadingCover && showProgress(progressCover)}
              {ticketCover && <img src={ticketCover} alt='' />}
              <div className='coverBackground' />
              {window.innerWidth <= 1350
                ? (
                  <div className='uploadTitle'> Upload ticket cover image or video</div>
                ) : (
                  <div className='uploadTitle'>Upload ticket cover image or video using Drag & Drop <br /> or</div>
                )}
              <div className='uploadBtn'>
                {ticketCover ? <>Change <span className='xsHidden'>Photo</span></> : 'Select file'}
              </div>
              <div className='uploadInfo'>Only jpg/ .JPG/ .png/ .jpeg/  file formats are allowed</div>
            </div>
          </Dragger>
          {fileDataBlob && (
            <ModalCropImage
              onConfirm={handleConfirmModify}
              className='cropImageModal'
              loading={loadingCover}
              onCancel={onCancel}
              file={fileDataBlob}
              setCrop={setCrop}
              onLoad={onLoad}
              crop={crop}
            />
          )}
        </div>
      </div>
      <div className='uploadAdditionalFilesWrapper'>
        <div className='uploadAdditionalFiles'>
          <UploadComponent
            accept=''
            disabled={loading}
            customRequest={onChangeFiles}
            handleOnChange={handleOnChange}
            listType='picture-card'
            showUploadList={{ showPreviewIcon: false }}
            filesList={filesList}
          >
            {uploadButton}
          </UploadComponent>
        </div>
        {/* <div className='fileListContainer'>
          {filesList.map((file, idx) => {
            return (
              <div key={`${file.uid}${idx}`} className='fileContainer'>
                <>
                  {!file?.uploadedUrl && showProgress(progress, 24, !file?.imgData)}
                  {file?.imgData ? (
                    <img src={file?.imgData} alt=' ' />
                  ) : (
                    <div className='iconContainer'><img src={fileIcon} alt='' className='fileIcon' /></div>
                  )}
                </>
                <div className='fileName'>{file?.name}</div>
                <Button className='deleteBtn' type='link' onClick={() => onClick(file)}>
                  <img src={deleteIcon} alt=' ' />
                </Button>
              </div>
            )
          })}
        </div> */}
      </div>
    </div>
  )
}

export default UploadImage
