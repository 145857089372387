/* eslint-disable indent */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, createContext } from 'react'
import { Steps } from 'antd'
import './index.styl'
import GeneralInfo from './GeneralInfo'
import PriceCategories from './PriceCategories'
import Sections from './Sections'

export const CreateEventContext = createContext(null)

const { Step } = Steps

const CreateTemplate = () => {
  const [current, setCurrent] = useState(0)
  const [templateName, setTemplateName] = useState('')
  const [templateCover, setTemplateCover] = useState('')
  const [templateCoverName, setTemplateCoverName] = useState('')
  const [, setTemplateBgColor] = useState('')
  const [, setTemplatePrice] = useState('')
  const [, setSectionName] = useState('')
  const [, setCountRowsTables] = useState('')
  const [, setCountSeats] = useState('')
  const [, setArrangement] = useState('')
  const [templateCategories, setTemplateCategories] = useState(null)
  const [templateSections, setTemplateSections] = useState(null)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }
  const steps = [
  {
    content: <GeneralInfo templateCoverName={templateCoverName} setTemplateCoverName={setTemplateCoverName} templateCover={templateCover} setTemplateCover={setTemplateCover} setTemplateName={setTemplateName} templateName={templateName} next={next} />,
  },
  {
    content: <PriceCategories setTemplateCategories={setTemplateCategories} templateCategories={templateCategories} setTemplatePrice={setTemplatePrice} setTemplateBgColor={setTemplateBgColor} next={next} prev={prev} />,
  },
  {
    content: <Sections templateCategories={templateCategories} templateName={templateName} templateCover={templateCover} setArrangement={setArrangement} setCountSeats={setCountSeats} setCountRowsTables={setCountRowsTables} setSectionName={setSectionName} templateSections={templateSections} setTemplateSections={setTemplateSections} next={next} prev={prev} />,
  },
]

  return (
    <>
      <div className='rootCreateTemplate'>
        <div className='createEventTitle'><h1>Create New Template</h1></div>
        <Steps current={current}>
          <Step />
          <Step />
          <Step />
        </Steps>
        <div className='steps-content'>{steps[current]?.content}</div>
      </div>
    </>
  )
}

export default CreateTemplate
