import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { DetailTicket } from 'components'
import { getEventLabel } from 'utils'
import arrow from 'images/simpleArrow.svg'
import './index.styl'

const Header = () => {
  const { currentEvent } = useSelector(state => state.events)
  return (
    <div className='headerDetailTicker'>
      <div className='headerContainer'>
        <div className='titleBack'>
          <Link to={currentEvent?.venueDetails ? `/venue/${currentEvent?.venueDetails.venueUrl}` : '/home'}>
            <img src={arrow} alt='arrow' className='icon' />
            <span className='text'>Back to Homepage</span>
          </Link>
        </div>
        <DetailTicket
          isStreaming={currentEvent?.isStreamingEvent}
          isVirtual={currentEvent?.isVirtualTicket}
          adress={currentEvent.address}
          eventLabel={getEventLabel(currentEvent)}
          {...currentEvent}
        />
      </div>
    </div>
  )
}

export default Header
