import React, { useState, useEffect } from 'react'
import { Divider, Form, Switch } from 'antd'
import './index.styl'

import UpdatedTicketCategory from '../../UpdatedTicketCategory'

const { Item, List } = Form
const GeneralAdmissionCategory = ({ currentEventDetails }) => {
  const [generalAdmissionSwitch, setGeneralAdmissionSwitch] = useState(false)
  const donationTicketHandler = () => {
    setGeneralAdmissionSwitch(!generalAdmissionSwitch)
  }
  useEffect(() => {
    if (!!currentEventDetails?.generalAdmission?.length) {
      setGeneralAdmissionSwitch(true)
    }
  }, [currentEventDetails])
  return (
    <div className='typeCategoryWrapper'>
      <div className='wrapperTitleRadioText'>
        <div className='title'>Ticketed Admission</div>
        <div className='radioItem'>
          <Item style={{ marginBottom: 0 }} name='generalAdmissionSwitch' valuePropName='checked' className='item'>
            <Switch onChange={donationTicketHandler} defaultChecked={generalAdmissionSwitch} />
          </Item>
          <div className='text'>Ticketed Admission</div>
        </div>
      </div>
      {generalAdmissionSwitch &&
        <List name='generalAdmission'>
          {(fields, { add, remove }) => (
            <div className='updatedCategoriesWrapper'>
              {fields.map((field, i) =>
                <div key={field.fieldKey}>
                  <UpdatedTicketCategory
                    categoryType='General Admission'
                    namePrefix={i}
                    fieldKey={field.fieldKey}
                    fieldsLength={fields.length}
                    switchToggleOff={setGeneralAdmissionSwitch}
                    onRemove={remove}
                  />
                </div>,
              )}
              {fields.length === 0 && (
                <div key='defaultForm'>
                  {/* Render default form when no categories exist */}
                  <UpdatedTicketCategory
                    categoryType='General Admission'
                    namePrefix={0}
                    fieldsLength={fields.length + 1}
                    switchToggleOff={setGeneralAdmissionSwitch}
                    onRemove={remove}
                  />
                </div>
              )}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                }}
              >Add Another Ticketed Admission Category
              </div>
            </div>)}
        </List>}
      <Divider />
    </div>
  )
}

export default GeneralAdmissionCategory
