import React from 'react'
import moment from 'moment'
import './index.styl'
import DateInputControl from './DateInputControl'
import { Button } from 'antd'

function DateInputPopup (props) {
  const customHeader = () => {
    return (
      <div className='rainDateBlockWrapper'>
        <div className='rainDateBlock'>
          <div
            className='titleDate'
          >{props.title}
          </div>
          {props.selectedDate && (
            <div className='startDate'>{moment(props.selectedDate).format('MMMM D, YYYY')}</div>
          )}
        </div>
      </div>
    )
  }
  return (
    <div className={props.msDate ? 'msDate-popupWrapper' : 'date-popupWrapper'}>
      {customHeader()}

      <div className='date-popup'>
        <DateInputControl
          currentMonth={props.currentMonth}
          currentYear={props.currentYear}
          selectedDate={props.selectedDate}
          navigateToNextMonth={() => props.navigateMonth(1)}
          navigateToPrevMonth={() => props.navigateMonth(-1)}
        />
        <div className='date-popup__grid'>
          <span className='week-code'>Su</span>
          <span className='week-code'>Mo</span>
          <span className='week-code'>Tu</span>
          <span className='week-code'>We</span>
          <span className='week-code'>Th</span>
          <span className='week-code'>Fr</span>
          <span className='week-code'>Sa</span>
          {props.children}
        </div>
      </div>
      <div className='dateFooterWrapper'>
        <div className='dateFooterBtn'>
          <Button className='setBtn' onClick={props.onCancel}>Clear</Button>
          <Button className='setBtn' onClick={props.onOk}>Set</Button>
        </div>
      </div>
    </div>
  )
}

export default DateInputPopup
