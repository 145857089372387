import React from 'react'
import { useHistory } from 'react-router-dom'
import { Tooltip } from 'antd'
import { MainButton } from 'components'
import TwoToneButton from 'pages/MemberLyfeHome/TwoToneButton'
import { getCurrencySymbolByCode, isOnDemand } from 'utils'
import cardImg from 'images/card.png'
import soldOutBadge from 'images/Soldoutbadge.svg'
import onDemand from 'images/OnDemand.svg'
import canceled from 'images/Canceled.svg'
import locationBadge from 'images/homeLocation.svg'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import './index.styl'

const SliderBody = ({ items = [] }) => {
  const history = useHistory()
  const getPrice = item => {
    const lowestPrice = item.minimalTicketPrice
    const currencySymbol = getCurrencySymbolByCode(items?.currency)
    if (lowestPrice === 0) return 'Free Membership'
    return `Membership from ${currencySymbol} ${lowestPrice % 1 === 0 ? lowestPrice : lowestPrice.toFixed(2)}`
  }
  const onClick = item => {
    history.push(`/memberPage/${item.id}/${item.userID}`)
    window.scrollTo(0, 0)
  }
  const renderButton = item => {
    const ButtonComponent = item.isDonationEnable ? TwoToneButton : MainButton
    return (
      <ButtonComponent
        onClick={() => onClick(item)}
        subText='Donate Today'
        text={getPrice(item)}
        className={item.isDonationEnable ? 'donateBtn' : 'memberBtn'}
      />
    )
  }
  const visitHomePageButton = item => {
    return (
      <MainButton
        onClick={() => onClick(item)}
        text='Visit Home Page'
        className='memberBtn'
      />
    )
  }

  return items.length ? (
    <div className='wrapperHomeSlider'>
      {items.map((item, index) => (
        <div key={index} className='wrapperSlideBody'>
          <div className='body' onClick={() => onClick(item)}>
            <div className='imgWrap'>
              <img
                src={item?.imageUrl?.thumbnail1}
                onError={e => (e.target.src = cardImg)}
                alt='card'
                className='img'
              />
              {item?.isEventCancelled
                ? <img src={canceled} alt=' ' className='onDemandBadge' draggable={false} />
                : isOnDemand(item.endDate) && !!item.streamTicketsAvailable
                  ? <img src={onDemand} alt=' ' className='onDemandBadge' draggable={false} />
                  : item.quantityAvailable <= 0
                    ? <img src={soldOutBadge} alt='sold out badge' className='soldOutBadge' draggable={false} />
                    : null}
            </div>
            {item?.minimalTicketPrice >= 0
              ? (
                <div className='front'>
                  {renderButton(item)}
                </div>
              ) : (
                <div className='front'>
                  {visitHomePageButton(item)}
                </div>
              )}
          </div>
          <div className='wrapperMemberEventType'>
            <div className='eventType'>
              <img src={locationBadge} alt='' />
              <span className='eventTypeText'>
                {item?.locations[0]}
              </span>
            </div>
          </div>
          <div className='foot'>
            <div className='title' onClick={() => onClick(item)}>
              {item.eventName}
            </div>
            <div className='memberEventDescription'>{item?.eventDescription}</div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className='wrapperBody'>
      <Tooltip title='tooltip example'>
        <img src={NoEventsInformation} alt='Information Logo' />
      </Tooltip>
      <div className='informationDescription'>No Events available yet</div>
    </div>
  )
}

export default SliderBody
