import React, { useEffect, useState } from 'react'
import { Modal, Button, Divider } from 'antd'
import { eventsV2 } from 'apiv2'
import SpecialScanningCard from './SpecialScanningCard'
import './index.styl'

const ModalStartScanning = ({ visible, updateVisible, eventID, eventName }) => {
  const [scanCategories, setScanCategories] = useState([])

  useEffect(() => {
    eventsV2.getScanCategories(eventID).then(response => {
      setScanCategories(response?.data?.categories)
    })
  }, [])
  return (
    <Modal
      className='modal-start-scanning'
      visible={visible}
      onCancel={() => updateVisible()}
      closeIcon={null}
      footer={false}
      centered
      destroyOnClose
    >
      <div className='bodyWrapper'>
        <div className='ticketTypeWrapper'>
          <div className='title'>{eventName}</div>
          <div className='subtitle'>Select Ticket Type For Scanning</div>
        </div>
        <div className='scanCategoriesWrapper'>
          <div className='fixedWidth'>
            {scanCategories.map(category => (
              <div key={category._id}>
                <SpecialScanningCard eventID={eventID} category={category} />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Divider />
      <Button
        className='cancelBtn' type='link' onClick={() => {
          updateVisible()
        }}
      >Cancel
      </Button>
    </Modal>
  )
}

export default ModalStartScanning
