/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import {
  getEventDetails,
} from 'modules/events/events-actions'
import { MainButton, PrivateLiveStreamModal } from 'components'
import { getUtcOffsetDate, formatInteger } from 'utils'
import { buyPrivateTiket } from '../../../../modules/events/events-actions'
import './index.styl'
import ModalBuyPrivateTickets from '../../../../components/ModalBuyPrivateTickets'

const Categories = ({ currencySymbol, tableData, setTableData, selectedTicket, setSelectedTicket, updateFunc, isChange, isResponsive }) => {
  const [privateLiveStream, setPrivateLiveStream] = useState()
  const [buyTiket, setByTiket] = useState()
  const [bundles, setBundles] = useState([])
  const { currentEventDetails } = useSelector(state => state.events)
  const { user } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const { eventID } = useParams()
  // const [isChange, setIsChange] = useState(false)
  const ticketId = tableData?.filter(({ ticketType }) => ticketType === 'Private Live Stream')[0]?.id

    const handleModal = ticketInfo => {
    setSelectedTicket(ticketInfo)
  }
  useEffect(() => {
    if (isChange) {
      dispatch(getEventDetails(eventID))
    }
    }, [isChange])
  useEffect(() => {
    const data = currentEventDetails.tickets?.map(i => {
      const ticketData = {
        isInvite: false,
        category: i?.ticketName,
        ticketType: i?.ticketType,
        quantity: formatInteger(i?.quantity),
        originalPrice: formatInteger(i?.price),
        total: formatInteger(i?.ticketCount?.total),
        directSell: formatInteger(i?.ticketCount?.sell),
        cashSalesRevenue: formatInteger(i?.ticketCount?.cashSales),
        gift: formatInteger(i?.ticketCount?.gift),
        ticketsScanned: formatInteger(i?.ticketCount?.ticketsScanned),
        sold: formatInteger(i?.ticketCount?.buy),
        subPromote: formatInteger(i?.ticketCount?.promote),
        available: formatInteger(i?.ticketCount?.available),
        price: formatInteger(i?.price || 0),
        id: i?._id,
        eventID,
        userID: currentEventDetails.userID,
        eventName: currentEventDetails.eventName,
        startDate: currentEventDetails.startDate,
        endDate: currentEventDetails.endDate,
        ticketColor: i.bgColor,
        isVirtualTicket: currentEventDetails.isVirtualTicket,
        isStreamingEvent: i.isStreaming,
        image: currentEventDetails.imageUrl?.thumbnail1,
        imageUrl: currentEventDetails.imageUrl,
        currency: currentEventDetails.currency,
        eventType: currentEventDetails.eventType,
        presentedBy: currentEventDetails.presentedBy,
        rainDate: getUtcOffsetDate(currentEventDetails.rainDate, 'MMMM DD, YYYY', currentEventDetails.timeZone),
      }
      if (!currentEventDetails.isVirtualTicket) {
        ticketData.venue = currentEventDetails?.venue
        ticketData.address = currentEventDetails?.address
      }
      return ticketData
    })
    if ((currentEventDetails.isDonationEnable && currentEventDetails?.userID === user._id) || (currentEventDetails.isDonationEnable && user.role?.includes('admin'))) {
      const objDonationRow = {
        id: currentEventDetails.donations?._id,
        category: 'Donations',
        total: currentEventDetails.donations?.quantity || 0,
        price: currentEventDetails.donations ? currentEventDetails.donations.total : 0,
        isDonation: true,
      }
      data.push(objDonationRow)
    }
     const bundlesCategories = currentEventDetails.bundles?.map(i => {
      const bundleData = {
        bundleName: i?.bundleName,
        quantity: formatInteger(i?.quantity),
        originalPrice: formatInteger(i?.price),
        total: formatInteger(i?.counters?.total),
        directSell: formatInteger(i?.counters?.sell),
        cashSalesRevenue: formatInteger(i?.counters?.cashSales),
        gift: formatInteger(i?.counters?.gift),
        ticketsScanned: formatInteger(i?.counters?.ticketsScanned),
        sold: formatInteger(i?.counters?.buy),
        subPromote: formatInteger(i?.counters?.promote),
        available: formatInteger(i?.counters?.available),
        price: formatInteger(i?.price || 0),
        id: i?.id,
      }
      return bundleData
    })
    setBundles(bundlesCategories)
    setTableData(data)
  }, [currentEventDetails])
  const defaultColumns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Online sales',
      dataIndex: 'sold',
      key: 'sold',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Direct sales',
      dataIndex: 'directSell',
      key: 'directSell',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Gift',
      dataIndex: 'gift',
      key: 'gift',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Cash/Zelle sales',
      dataIndex: 'cashSalesRevenue',
      key: 'cashSalesRevenue',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Tickets scanned',
      dataIndex: 'ticketsScanned',
      key: 'ticketsScanned',
      render: (item, record) => record.isDonation ? '' : item || 0,
    },
    /* {
      title: 'Public sale link',
      key: 'publicSaleLink',
      dataIndex: 'publicSaleLink',
      render: (_, record) => (
        <a className='publicSaleLink' href={`https://dev.bluelineticket.com/${record.id}`} target='_blank' rel='noopener noreferrer'>Click here</a>
      ),
    }, */
    {
      title: 'Available',
      key: 'available',
      dataIndex: 'available',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
    {
      title: '',
      key: 'actions',
      dataIndex: 'actions',
      width: 170,
      render: (_, record) => record?.isDonation ? '' : (
        record.ticketType === 'Private Live Stream'
          ? (
            <MainButton
              text='Get More'
              className='previewTicketBtn'
              onClick={() => setPrivateLiveStream(record)}
            />
          ) : (
            <MainButton
              text='Preview ticket'
              className='previewTicketBtn'
              onClick={() => handleModal(record)}
            />
          )
      ),
    },
  ]

 const bundlesColumns = [
    {
      title: 'Bundle name',
      dataIndex: 'bundleName',
      key: 'bundleName',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Online sales',
      dataIndex: 'sold',
      key: 'sold',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Direct sales',
      dataIndex: 'directSell',
      key: 'directSell',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Gift',
      dataIndex: 'gift',
      key: 'gift',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Cash/Zelle sales',
      dataIndex: 'cashSalesRevenue',
      key: 'cashSalesRevenue',
      render: (item, record) => record.isDonation ? '' : item,
    },
    /* {
      title: 'Public sale link',
      key: 'publicSaleLink',
      dataIndex: 'publicSaleLink',
      render: (_, record) => (
        <a className='publicSaleLink' href={`https://dev.bluelineticket.com/${record.id}`} target='_blank' rel='noopener noreferrer'>Click here</a>
      ),
    }, */
    {
      title: 'Available',
      key: 'available',
      dataIndex: 'available',
      render: (item, record) => record.isDonation ? '' : item,
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },

  ]

  // const ticketCreatorColumns = [
  //   ...defaultColumns.slice(0, 4),
  //   {
  //     title: 'Sub-promote',
  //     key: 'subPromote',
  //     dataIndex: 'subPromote',
  //     render: (item, record) => record.isDonation ? '' : item,
  //   },
  //   ...defaultColumns.slice(4),
  // ]
  const columns = defaultColumns
  // eventname, venue, address, startDate, startTime - endTime, ticketColor
  return (
    <>
      <div id='categories' className='wrapperTablesAssignSeating'>
        <div className='titleAssignedSeating'>Ticket Categories</div>
        {isResponsive
          ? tableData && tableData.map((i, idx) => {
            return (
              <div key={idx} className='ticketCategoriesTable'>
                <div className='ticketCategoriesItem'>
                  <div className='ticketCategorieslabel'>Category</div>
                  <div className='ticketCategoriesText'>{i.category}</div>
                </div>
                <div className='ticketCategoriesItem'>
                  <div className='ticketCategorieslabel'>Total</div>
                  <div className='ticketCategoriesText'>{i.total}</div>
                </div>
                {i.isDonation
                ? (
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Received</div>
                    <div className='ticketCategoriesText'>{currencySymbol}{i.price}</div>
                  </div>
                ) : (
                  <>
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Online sales</div>
                      <div className='ticketCategoriesText'>{i.sold}</div>
                    </div>
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Direct sales</div>
                      <div className='ticketCategoriesText'>{i.directSell}</div>
                    </div>
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Gift</div>
                      <div className='ticketCategoriesText'>{i.gift}</div>
                    </div>
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Cash/Zelle sales</div>
                      <div className='ticketCategoriesText'>{i.cashSalesRevenue}</div>
                    </div>
                    {i?.ticketsScanned &&
                      <div className='ticketCategoriesItem'>
                        <div className='ticketCategorieslabel'>Tickets Scanned</div>
                        <div className='ticketCategoriesText'>{i.ticketsScanned}</div>
                      </div>}
                    {/* {user?._id === currentEventDetails?.userID &&
                      <div className='ticketCategoriesItem'>
                        <div className='ticketCategorieslabel'>Sub-promote</div>
                        <div className='ticketCategoriesText'>{i.subPromote}</div>
                      </div>} */}
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Available</div>
                      <div className='ticketCategoriesText'>{i.available}</div>
                    </div>
                    <div className='ticketCategoriesItem'>
                      <div className='ticketCategorieslabel'>Price</div>
                      <div className='ticketCategoriesText'>{currencySymbol}{i.price}</div>
                    </div>
                    <div className='ticketCategoriesItem row'>
                      <div className='ticketCategorieslabel btnWrap'>
                        {i.ticketType === 'Private Live Stream'
                          ? (
                            <MainButton
                              text='Get More'
                              className='previewTicketBtnTable'
                              onClick={() => setPrivateLiveStream(i)}
                            />
                            ) : (
                              <MainButton
                                text='Preview ticket'
                                className='previewTicketBtnTable'
                                onClick={() => setSelectedTicket(i)}
                              />
                            )}
                      </div>
                    </div>
                  </>)}
              </div>
          )
})
          : (
            <Table
              columns={columns}
              dataSource={tableData}
              className='eventDetailsTable'
              rowKey='id'
            />
          )}

      </div>
      {bundles?.length
        ? (
          <div id='bundleCategories' className='wrapperTablesAssignSeating'>
            <div className='titleAssignedSeating'>Bundles</div>
            {isResponsive
          ? bundles && bundles.map((bundle, idx) => {
            return (
              <div key={idx} className='ticketCategoriesTable'>
                <div className='ticketCategoriesItem'>
                  <div className='ticketCategorieslabel'>Bundle name</div>
                  <div className='ticketCategoriesText'>{bundle.bundleName}</div>
                </div>
                <div className='ticketCategoriesItem'>
                  <div className='ticketCategorieslabel'>Total</div>
                  <div className='ticketCategoriesText'>{bundle.total}</div>
                </div>

                <>
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Online sales</div>
                    <div className='ticketCategoriesText'>{bundle.sold}</div>
                  </div>
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Direct sales</div>
                    <div className='ticketCategoriesText'>{bundle.directSell}</div>
                  </div>
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Gift</div>
                    <div className='ticketCategoriesText'>{bundle.gift}</div>
                  </div>
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Cash/Zelle sales</div>
                    <div className='ticketCategoriesText'>{bundle.cashSalesRevenue}</div>
                  </div>
                  {/* {user?._id === currentEventDetails?.userID &&
                      <div className='ticketCategoriesItem'>
                        <div className='ticketCategorieslabel'>Sub-promote</div>
                        <div className='ticketCategoriesText'>{i.subPromote}</div>
                      </div>} */}
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Available</div>
                    <div className='ticketCategoriesText'>{bundle.available}</div>
                  </div>
                  <div className='ticketCategoriesItem'>
                    <div className='ticketCategorieslabel'>Price</div>
                    <div className='ticketCategoriesText'>{currencySymbol}{bundle.price}</div>
                  </div>
                </>
              </div>
          )
})
          : (
            <Table
              columns={bundlesColumns}
              dataSource={bundles}
              className='eventDetailsTable'
              rowKey='id'
      />
          )}
          </div>
          ) : (null)}
      {privateLiveStream && <PrivateLiveStreamModal tag='PreviewTicket' ticketId={ticketId} setByTiket={setByTiket} ticketData={tableData} visible={!!privateLiveStream} onCloseModal={() => setPrivateLiveStream()} />}
      {<ModalBuyPrivateTickets
        img={currentEventDetails?.imageUrl?.thumbnail1}
        quantity={buyTiket / 25}
        orderedTickets={[]}
        donation={buyTiket}
        eventID={ticketId}
        currencySymbol='$'
        visible={buyTiket}
        updateFunc={updateFunc}
        inviteType='Private Live Stream'
        setVisible={setByTiket}
        action={buyPrivateTiket}
        price={buyTiket * 2}
      />}
    </>
  )
}

export default Categories
