import axios from 'axios'
import { snakeizeKeys } from 'api/transform'
import { isPlainObject } from 'lodash'
import qs from 'qs'

const api = (url = '', method = '', data = {}, authorization = true, requestOptions = {}) => {
  const state = localStorage.getItem('persist:blueline-ticket')

  const options = {
    method,
    headers: {
      'content-language': 'en',
      utcoffset: '-180',
    },
    data: qs.stringify(data),
    url: `${process.env.REACT_APP_API_V2_ENDPOINT}/${url}`,
    transformRequest: data => {
      if (isPlainObject(data)) {
        return JSON.stringify(snakeizeKeys(data))
      }
      return data
    },
    // transformResponse: data => camelizeKeys(data),
  }

  if (authorization) {
    options.headers.authorization = 'bearer ' + JSON.parse(JSON.parse(state).session).token
  }

  options.data = data

  if (requestOptions?.responseType) {
    options.responseType = requestOptions?.responseType
  }
  if (requestOptions?.cancelToken) {
    options.cancelToken = requestOptions?.cancelToken
  }
  if (requestOptions?.onUploadProgress) {
    options.onUploadProgress = requestOptions?.onUploadProgress
  }

  return axios(options)
}

export const CancelToken = axios.CancelToken

export default api
