import React, { useEffect } from 'react'
import { Modal, Form, Input, Switch, InputNumber, Select, Radio } from 'antd'
import tablesIcon from 'images/tablesIcon.svg'
import seatsIcon from 'images/seatsIcon.svg'
import { CustomButton } from 'components'
import './index.styl'
import Categories from '../CreateTemplate/PriceCategories/Categories'

const { Item, List } = Form
const { Option } = Select

const ModalTemplate = ({ visible = false, additionalProps, title, onChange, width = 400, onFinish, name, loading }) => {
  const attr = { visible, onCancel: onChange, width: width, maxWidth: 400, footer: null, className: 'wrapperModalTemplate', destroyOnClose: true }
  const [form] = Form.useForm()
  const { setSeatAccessebility, seatAccessebility, blockSale, setBlockSale, seatNumber, categoriesArray = [{}], type, sectionName, groupCount, seatCount, rowArrangement, setType, initialColor, isTemplate } = additionalProps
  const initialCategoryValue = {
    templateCategories: categoriesArray || [{}],
  }
  useEffect(() => {
    form.setFieldsValue({
      values: form.getFieldValue('templateCategories'),
      name: seatNumber,
      seatCount: seatCount,
      groupCount: groupCount,
      categoryID: initialColor,
      isDisabled: !!seatAccessebility,
    })
  }, [seatNumber, seatCount, groupCount, initialColor, !!seatAccessebility])

  const validateLimit = {
    validator (_, value) {
      const seatCount = _?.field === 'seatCount'
      const groupCount = _?.field === 'groupCount'
      if (seatCount && value > 50) {
        return Promise.reject(new Error('You cannot create more than 50 seats per section!'))
      } else if (groupCount && value > 200) {
        return Promise.reject(new Error(`You cannot create more than 200 ${type === 'TABLES' ? 'tables' : 'rows'} per section!`))
      }
      return Promise.resolve()
    },
  }
  const isMyTemplatesPage = window.location.pathname.startsWith('/my-templates/')
  const obj = {
    seat: () => (
      <>
        <Item className='seatAccessWrapper'>
          <Item name='isDisabled'>
            <Switch onChange={() => setSeatAccessebility(!seatAccessebility)} checked={!seatAccessebility} />
          </Item>
          <div>
            <div className='label'>Seat Accessibility</div>
          </div>
        </Item>
        <Item
          rules={[{ required: true, message: 'Enter Seat Number' }]}
          label='Seat Number'
          name='name'
          initialValue={seatNumber}
          className='seatNumber'
        >
          <Input className='fullWidth' />
        </Item>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: true }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
      </>
    ),
    table: () => (
      <>
        <Item
          rules={[{ required: true, message: 'Enter Table Number' }]}
          label='Table Number'
          name='name'
          initialValue={seatNumber}
          className='seatNumber'
        >
          <Input className='fullWidth' />
        </Item>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: false }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
          label='Count of Seats'
          name='seatCount'
          initialValue={seatCount}
          className='seatNumber'
        >
          <InputNumber className='fullWidth' type='number' min={1} />
        </Item>
        {isMyTemplatesPage ? null
          : (
            <Item className='seatAccessWrapper'>
              <Item initialValue={blockSale} name='blockSale'>
                <Switch onChange={() => setBlockSale(!blockSale)} checked={blockSale} />
              </Item>
              <div>
                <div className='label'>Block from sale</div>
              </div>
            </Item>
          )}
      </>
    ),
    addTable: () => (
      <>
        <Item
          rules={[{ required: true, message: 'Enter Table Number' }]}
          label='Table Number'
          name='name'
          initialValue={seatNumber}
          className='seatNumber'
        >
          <Input className='fullWidth' />
        </Item>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: true }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
          label='Count of Seats'
          name='seatCount'
          initialValue={seatCount}
          className='seatNumber'
        >
          <InputNumber className='fullWidth' type='number' min={1} />
        </Item>
        {isMyTemplatesPage ? null
          : (
            <Item className='seatAccessWrapper'>
              <Item initialValue={blockSale} name='blockSale'>
                <Switch onChange={() => setBlockSale(!blockSale)} checked={blockSale} />
              </Item>
              <div>
                <div className='label'>Block from sale</div>
              </div>
            </Item>
          )}
      </>
    ),
    row: () => (
      <>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: true }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
          label='Count of Seats'
          name='seatCount'
          initialValue={seatCount}
          className='seatNumber'
        >
          <InputNumber className='fullWidth' type='number' min={1} />
        </Item>
      </>
    ),
    section: () => (
      <div className='sectionWrapperModal'>
        <Item
          rules={[{ required: true, message: 'Enter Section Name' }]}
          label='Section Name'
          name='name'
          initialValue={sectionName}
          className='sectionName'
        >
          <Input className='fullWidth' />
        </Item>
        <Item
          rules={[{ required: true }]}
          initialValue={type}
          label='Section Type'
          name='type'
          className='sectionType'
        >
          <Radio.Group className='radioGroup'>
            <Radio disabled={type === 'ROWS'} value='TABLES'>
              <img alt='rows' src={tablesIcon} />
              Tables
            </Radio>
            <Radio disabled={type === 'TABLES'} value='ROWS'>
              <img alt='rows' src={seatsIcon} />
              Rows
            </Radio>
          </Radio.Group>
        </Item>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: true }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <div className='sectionRow'>
          <Item
            rules={[{ required: true, message: `Enter Count of ${type === 'TABLES' ? 'Tables' : 'Rows'}` }, validateLimit]}
            label={`Count of ${type === 'TABLES' ? 'Tables' : 'Rows'}`}
            name='groupCount'
            className='item groupCount'
            min={1}
          >
            <InputNumber type='number' className='inputNumber' placeholder={0} min={1} />
          </Item>
          <Item
            rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
            label='Count of Seats'
            name='seatCount'
            className='item seatCount'
            min={1}
          >
            <InputNumber type='number' className='inputNumber' placeholder={0} min={1} />
          </Item>
        </div>
        {type === 'ROWS' && (
          <Item
            // rules={[{ required: true }]}
            name='rowArrangement'
            label='Row Arrangement'
            className='no_margin item_radio-group'
            initialValue={rowArrangement}
          >
            <Radio.Group
              className='arrangment'
            >
              <Radio value='HORIZONTAL'>Alphabetic</Radio>
              <Radio value='VERTICAL'>Numeric</Radio>
            </Radio.Group>
          </Item>
        )}
        {/* {type === 'ROWS' ? (
          <Item
            // rules={[{ required: true }]}
            name='rowArrangement'
            label='Row Arrangement'
            className='no_margin item_radio-group'
            initialValue={rowArrangement}
          >
            <Radio.Group
              className='arrangment'
            >
              <Radio value='HORIZONTAL'>Alphabetic</Radio>
              <Radio value='VERTICAL'>Numeric</Radio>
            </Radio.Group>
          </Item>
        ) : (
          <>
            <Item className='item switchContainer'>
              <Item name='tableSeatSelection'>
                <Switch
                  defaultChecked={tableSeatSelection || false}
                  onChange={tableSeatSelectionHandler}
                />
              </Item>
              <div className='label'>
                <div className='title'>Allow guests to choose a seat at table</div>
              </div>
            </Item>
          </>
        )} */}
      </div>
    ),
    addSection: () => (
      <div className='sectionWrapperModal'>
        <Item
          rules={[{ required: true, message: 'Enter Section Name' }]}
          label='Section Name'
          name='name'
          className='sectionName'
        >
          <Input className='fullWidth' />
        </Item>
        <Item
          rules={[{ required: true }]}
          label='Section Type'
          name='type'
          className='sectionType'
          initialValue={type}
        >
          <Radio.Group onChange={e => setType(e.target.value)} className='radioGroup'>
            <Radio value='TABLES'>
              <img alt='rows' src={tablesIcon} />
              Tables
            </Radio>
            <Radio value='ROWS'>
              <img alt='rows' src={seatsIcon} />
              Rows
            </Radio>
          </Radio.Group>
        </Item>
        <Item
          className='item priceCatSelect'
          name='categoryID'
          label='Price Categories'
          rules={[{ required: true }]}
        >
          <Select placeholder='Choose category' listHeight={300}>
            {categoriesArray.map(({ color, price, name, id }) => (
              <Option value={id} key={id}>
                <div key={id} className='customDropDownWrapper'>
                  <div className='categoryColor' style={{ backgroundColor: color }} />
                  <span className='selectPrice'>{'$' + price}</span>
                  <span className='categoryName'>{name}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <div className='sectionRow'>
          <Item
            rules={[{ required: true, message: `Enter Count of ${type === 'TABLES' ? 'Tables' : 'Rows'}` }, validateLimit]}
            label={`Count of ${type === 'TABLES' ? 'Tables' : 'Rows'}`}
            name='groupCount'
            className='item groupCount'
            min={1}
          >
            <InputNumber type='number' className='inputNumber' placeholder={0} min={1} />
          </Item>
          <Item
            rules={[{ required: true, message: 'Enter Count of Seats' }, validateLimit]}
            label='Count of Seats'
            name='seatCount'
            className='item seatCount'
            min={1}
          >
            <InputNumber type='number' className='inputNumber' placeholder={0} min={1} />
          </Item>
        </div>
        {type === 'ROWS' && (
          <Item
            // rules={[{ required: true }]}
            name='rowArrangement'
            label='Row Arrangement'
            className='no_margin item_radio-group'
            initialValue={rowArrangement || 'HORIZONTAL'}
          >
            <Radio.Group
              className='arrangment'
            >
              <Radio value='HORIZONTAL'>Alphabetic</Radio>
              <Radio value='VERTICAL'>Numeric</Radio>
            </Radio.Group>
          </Item>
        )}
        {/* {type === 'ROWS' ? (
          <Item
            // rules={[{ required: true }]}
            name='rowArrangement'
            label='Row Arrangement'
            className='no_margin item_radio-group'
            initialValue={rowArrangement || 'HORIZONTAL'}
          >
            <Radio.Group
              className='arrangment'
            >
              <Radio value='HORIZONTAL'>Alphabetic</Radio>
              <Radio value='VERTICAL'>Numeric</Radio>
            </Radio.Group>
          </Item>
        ) : (
          <>
            <Item className='item switchContainer'>
              <Item className='no_margin item_radio-group' name='tableSeatSelection'>
                <Switch
                  defaultChecked={tableSeatSelection || false}
                  onChange={tableSeatSelectionHandler}
                />
              </Item>
              <div className='label'>
                <div className='title'>Allow guests to choose a seat at table</div>
              </div>
            </Item>
          </>
        )} */}
      </div>
    ),
    categories: () => (
      <>
        <Form onFinish={onFinish} initialValues={initialCategoryValue} form={form} colon={false} scrollToFirstError>
          <List name='templateCategories'>
            {(fields, { add, remove }) => (
              <div className='priceCatMap'>
                {fields.map((field, i) => {
                  return (
                    <div key={field.name}>
                      <div className='categoryLabel'>Category {field.name + 1}</div>
                      <Categories
                        categories={categoriesArray}
                        isInviteOnlyCategory
                        isTemplate={isTemplate}
                        field={{ ...field, key: `${field?.name}` }}
                        key={[field?.name]}
                        onRemove={remove}
                      />
                    </div>
                  )
                },
                )}
                <div
                  id='addPriceCategory' className='btn' onClick={() => {
                    add()
                  }}
                >Add New Price Category
                </div>
              </div>)}
          </List>
        </Form>
      </>
    ),
  }

  // const initialValues = {
  //  isDisabled: !!seatAccessebility,
  //  name: seatNumber,
  //  categoryID: initialColor,
  //  seatCount: seatCount,
  //  groupCount: groupCount,
  // }
  const textSubmit = name === 'addSection' ? 'Add' : 'Save'
  return (
    <Modal className='templateModal' title={title} {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish}>
        {obj[name]()}
        <div className='wrapperActions'>
          <CustomButton text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalTemplate
