import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
} from 'antd'
import { CustomButton } from 'components'
import Categories from './Categories'
import './index.styl'
const { List } = Form
const PriceCategories = ({ next, prev, templateCategories = [{}], setTemplateCategories, setTemplatePrice, setTemplateBgColor }) => {
  const [form] = Form.useForm()
  const [update, setUpdate] = useState(false)
  const initialValues = {
    templateCategories: templateCategories || [{}],
  }
  const validatePriceCatogories = e => {
    if (e?.errorFields?.length) {
      return false
    }
  }
  useEffect(() => {
    const values = form.getFieldValue('templateCategories')
    form.setFieldsValue({
      values,
    })
    setTemplateCategories(values)
  }, [initialValues, update])

  return (
    <div className='wrapperPriceCategory'>
      <Form onFinish={next} initialValues={initialValues} form={form} colon={false} scrollToFirstError onFinishFailed={validatePriceCatogories}>
        <div className='bodyContainer centerContainer'>
          <div className='containerTitle'>Price Categories</div>
          <List name='templateCategories'>
            {(fields, { add, remove }) => (
              <div className='priceCatMap'>
                {fields.map((field, i) => {
                  return (
                    <div key={field.name}>
                      <div className='categoryLabel'>Category {field.name + 1}</div>
                      <Categories
                        removeIndex={setUpdate}
                        setTemplateBgColor={setTemplateBgColor}
                        setTemplatePrice={setTemplatePrice}
                        field={{ ...field, key: `${field?.name}` }}
                        key={[field?.name]}
                        onRemove={remove}
                      />
                    </div>
                  )
                },
                )}
                <div
                  id='addPriceCategory' className='btn' onClick={() => {
                    add()
                  }}
                >Add New Price Category
                </div>
              </div>)}
          </List>
        </div>
        <div className='priceTemplateBottom'>
          <div>
            <div className='arrow left' />
            <Button className='cancelBtn' type='link' onClick={prev}>Back</Button>
          </div>
          <CustomButton className='submitBtn' text='Next Step' htmlType='submit' type='dark' />
        </div>
      </Form>
    </div>
  )
}

export default PriceCategories
