import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { userApi, events as eventsApi } from 'api'
import { notification } from 'antd'
import { Layout } from 'components'
import ScannerDetails from './ScannerDetails'
import { useQuery } from 'utils'

import EventsList from './EventsList'
import './index.styl'

const msgError = error => {
  const message = error?.response?.data?.message
  notification.warning({ message })
}

const transformData = (arr = []) => arr.map(i => i.eventID)
const transformTicketData = (arr = []) => arr.map(t => ({ ...t, name: t?.fullName }))
const paginationDefault = {
  current: 1,
  total: 0,
  limit: 5,
}
const Scanner = () => {
  const { eventID } = useParams()
  const { push } = useHistory()
  const [pagination, setPagination] = useState(paginationDefault)
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const [event, setEvent] = useState(null)
  const [tickets, setTickets] = useState([])
  const query = useQuery()

  useEffect(() => {
    window.dataLayer.push({ event: 'Scanner' })
    updateEvents(+query.get('page') || 1)
    return () => {
      setEvents([])
      setEvent(null)
    }
  }, [eventID])

  const updateEvents = (current = 1) => {
    const params = {
      limit: paginationDefault.limit,
      skip: (current * pagination.limit) - pagination.limit,
      search: '',
    }
    if (eventID) {
      eventsApi
        .getScannedTickets(eventID, params)
        .then(({ data: { data = {} } }) => {
          setEvent(data?.eventDetails)
          setPagination({ ...pagination, total: data.count, current: pagination.current })
          setTickets(transformTicketData(data?.scannedTickets))
        })
        .catch(error => {
          push('/scanner')
          msgError(error)
        })
        .finally(() => setLoading(false))
    } else {
      userApi
        .getScannerEvents()
        .then(({ data: { data = {} } }) => setEvents(transformData(data)))
        .catch(msgError)
        .finally(() => setLoading(false))
    }
  }

  const onChange = (current, pageSize) => {
    const skip = (current * pageSize) - paginationDefault.limit
    const params = {
      limit: paginationDefault.limit,
      search: '',
      skip,
    }
    setLoading(true)
    eventsApi
      .getScannedTickets(eventID, params)
      .then(response => {
        setPagination({ ...pagination, total: response.data.data.count, current })
      })
      .catch(err => err)
      .finally(() => setLoading(false))
  }

  return (
    <Layout className='scannerContainer'>
      {eventID
        ? <ScannerDetails onChange={onChange} pagination={pagination} loading={loading} event={event} tickets={tickets} />
        : <EventsList events={events} loading={loading} />}
    </Layout>
  )
}

export default Scanner
