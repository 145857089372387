import React, { useState } from 'react'
import HomePageAnimation from '../../../images/homePage-animation.mp4'
import { Link } from 'react-router-dom'
import signInArrow from 'images/signInArrow.svg'
import star from 'images/startsAndLine.svg'
// import ribbon from 'images/ribbon.svg'
import Navigation from './Navigation'
import './index.styl'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  // useEffect(() => {
  //   const imgElement = document.getElementById('image')
  //   const videoElement = document.getElementById('video')

  //   imgElement.onload = () => {
  //     videoElement.style.width = imgElement.width + 'px'
  //     videoElement.style.height = imgElement.height + 'px'
  //     videoElement.play()
  //   }
  // }, [])
  return (
    <div className='background-video'>
      <video autoPlay muted loop playsInline>
        <source src={HomePageAnimation} type='video/mp4' />
      </video>
      <Navigation isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen ? null
        : (
          <div className='content'>
            <div className='titleWrapper'>
              <div className='headerTitle'>
                Discover and Experience the Best Events and Live Shows
              </div>
              <div className='headerSubtitle'>We are a ticketing platform for live events, shows, and streams, offering fans a way to discover and experience live events across the globe.</div>
              <Link className='btnGetStarted' to='/auth/sign-in'>
                Get Started
                <img className='signUpArrow' src={signInArrow} alt='sign up arrow' />
              </Link>
            </div>
            <div />
            <div className='ribbonWrapper'>
              {/* <video id='video' autoPlay muted loop> */}
              {/* <source src={HomePageAnimation} type='video/mp4' /> */}
              {/* </video> */}
              <img id='image' src={star} alt='' className='ribbonContent' />
              {/* <div className='star'>&#10022;</div> */}
            </div>
          </div>
        )}
    </div>
  )
}

export default Header
