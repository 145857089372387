import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { resetPassword } from 'modules/session/session-actions'
import { Form, Input } from 'antd'
import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const onFinish = form => {
    setLoading(true)
    const { email = '' } = form
    dispatch(resetPassword(email.trim())).then(() => setLoading(false))
  }

  return (
    <div className='wrapperResetPassword'>
      <div className='title'>Forgot password</div>
      <Form layout='vertical' onFinish={onFinish}>
        <Item
          name='email'
          label='Email'
          className='item'
          rules={[
            {
              required: true,
              message: 'Please enter email',
              whitespace: true,
            },
            {
              type: 'email',
              message: 'Please enter a valid email',
              transform: value => value.trim(),
            },
          ]}
        >
          <Input className='input' />
        </Item>
        <CustomButton
          text='Send Instructions'
          type='dark'
          className='btn'
          htmlType='submit'
          loading={loading}
        />
      </Form>
    </div>
  )
}

export default ResetPassword
