import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CustomButton, CustomSelect } from 'components'
import SearchIcon from 'images/input-search.svg'
import { Input, Badge } from 'antd'
import { MyBoxOfficeContext } from '../index'
import { useQuery, myBoxOfficeTabs, getMyBoxOfficeURL } from 'utils'
import c from 'classnames'
import './index.styl'

const Header = () => {
  const { filterTag, setFilterTag, sortTag, setSortTag, setSearchTag, loading } = useContext(MyBoxOfficeContext)
  const history = useHistory()
  const query = useQuery()

  const { promotions } = useSelector(state => state.events)
  const items = [
    { name: 'Sort by Name', key: 'NAME_ASC' },
    { name: 'Sort by Name', key: 'NAME_DESC' },
    { name: 'Sort by Date', key: 'DATE_ASC' },
    { name: 'Sort by Date', key: 'DATE_DESC' }]
  const searchIcon = <img alt='search icon' src={SearchIcon} />

  useEffect(() => {
    if (!query.get('activeTab') || !query.get('page') || !query.get('orderBy')) {
      history.push(getMyBoxOfficeURL(filterTag.key, sortTag))
    }
  }, [])

  useEffect(() => {
    const currentTab = query.get('activeTab')
    const orderBy = query.get('orderBy')
    window.dataLayer.push({ event: 'My Box Office' })
    if (!!currentTab && filterTag.key !== currentTab) {
      history.push(getMyBoxOfficeURL(currentTab, sortTag))
      setFilterTag(myBoxOfficeTabs.find(i => i.key === currentTab))
    }
    if (!!orderBy && orderBy !== sortTag) {
      history.push(getMyBoxOfficeURL(currentTab, sortTag))
      setSortTag(orderBy)
    }
  }, [history.location.search])

  const withBadge = key => ({ withBadge: key === 'SUBPROMOTIONS' && promotions?.length })

  const item = i => (
    <div
      key={i.key}
      className={c('item', { active: i.name === filterTag.name, ...withBadge(i.key), disable: loading })}
      onClick={() => {
        if (!loading) {
          history.push(getMyBoxOfficeURL(i.key, sortTag))
          setFilterTag(i)
        }
      }}
    >
      {i.name}
      {(i.key === 'SUBPROMOTIONS' && promotions?.length > 0) && <Badge count={promotions.length} />}
    </div>
  )

  let requestDelay = 0
  const onChangeSearch = e => {
    if (+query.get('page') !== 1) {
      history.push(getMyBoxOfficeURL(query.get('activeTab'), sortTag))
    }
    const searchValue = e.target?.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setSearchTag(searchValue)
    }, 700)
  }
  const onChangeSorting = tag => {
    history.push(getMyBoxOfficeURL(query.get('activeTab'), tag, query.get('page')))
    setSortTag(tag)
  }

  return (
    <div className='headerBoxOffice'>
      <div className='subHeader'>
        <div className='title'>My Box Office</div>
        <div>
          <CustomButton
            text='Template Management'
            type='outline'
            className='btnHeader templateManagement'
            onClick={() => history.push('/my-templates')}
          />
          <CustomButton
            text='Make My Tickets'
            type='dark'
            className='btnHeader'
            onClick={() => history.push('/make-my-tickets')}
          />
        </div>
      </div>
      <div className={c('subHeader', 'subHeader2')}>
        <div className='leftHeader'>
          <Input
            className='input noFocusBorder'
            onChange={onChangeSearch}
            placeholder='Search'
            prefix={searchIcon}
            allowClear
          />
          <div className='wrapperFilters'>{myBoxOfficeTabs.map(item)}</div>
        </div>
        <CustomSelect items={items} sortTag={sortTag} setSortTag={onChangeSorting} customSuffix disabled={loading} />
      </div>
    </div>
  )
}

export default Header
