import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getMyWalletTickets, getInviteTicket, eventsGetMyWallets } from 'modules/events/events-actions'
import { Tooltip } from 'antd'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import { MemberLyfeLayout, Layout } from 'components'
import Header from './Header'
import Body from './Body'
import './index.styl'

const MyWallet = () => {
  const [valueSearch, setValueSearch] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingPagination, setLoadingPagination] = useState(true)
  const [sortType, setSortType] = useState('ACTIVE')
  const [total, setTotal] = useState(0)
  const [current, setCurrent] = useState(0)
  const [skip, setSkip] = useState(0)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const { inviteID } = useParams()
  const location = useLocation()

  let requestDelay = 0
  const onChangeSearch = e => {
    const search = e.target.value
    if (requestDelay) clearTimeout(requestDelay)
    requestDelay = setTimeout(() => {
      setValueSearch(search)
    }, 700)
  }

  const attr = { valueSearch, onChangeSearch, loading, setLoading, setSortType, sortType, setTotal, setCurrent, setSkip, skip, current, total, setLoadingPagination, loadingPagination }
  useEffect(() => {
    dispatch(
      eventsGetMyWallets({ accepted: [], pending: [] }),
    )
    if (inviteID) {
      dispatch(getInviteTicket(inviteID)).then(() => {
        setLoading(false)
      }).catch(error => {
        setError(error?.response?.data?.message || error?.response?.data?.error)
      })
    } else {
      dispatch(getMyWalletTickets({ type: sortType, limit: 20, skip: 0, search: valueSearch })).then(({ total, current }) => {
        setLoading(false)
        setTotal(total)
        setCurrent(current)
        setSkip(skip)
        setLoadingPagination(false)
      }).catch(error => {
        setError(error?.response?.data?.message || error?.response?.data?.error)
      })
    }
  }, [valueSearch])
  const LayoutComponent = location.pathname.includes('member') ? MemberLyfeLayout : Layout

  return (
    <LayoutComponent className='wrapperMyMemberWallet'>
      {!location.pathname.includes('guest') && <Header location={location} {...attr} />}
      {error
        ? (
          <div className='wrapperBodyEmpty'>
            <Tooltip>
              <img src={NoEventsInformation} alt='Information Logo' />
            </Tooltip>
            <div className='informationDescription'>{error}</div>
          </div>
        ) : (<Body {...attr} />)}
    </LayoutComponent>
  )
}

export default MyWallet
