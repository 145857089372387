import React, { useState } from 'react'
import { Modal, Form, Input, Radio, Space } from 'antd'
import CustomButton from '../CustomButton'
import './index.styl'

const { Item } = Form

const ModalTip = () => {
  const [tipForm] = Form.useForm()
  const [customTipSize, setCustomTipSize] = useState()
  const [tipSize, setTipSize] = useState(5)

  const onInputChange = val => {
    setCustomTipSize(parseFloat(val))
  }
  const onInputClick = val => {
    setTipSize(val)
  }
  const onFinish = values => {
  }
  const onRadioClick = val => {
    setCustomTipSize('')
    setTipSize(val)
  }

  return (
    <Modal className='modal-tip' visible destroyOnClose footer={null}>
      <h2 className='title'>
        Donation
      </h2>
      <h4 className='subtitle'>
        Support our next record.
        It is as simple as buying a cup of coffee.
      </h4>
      <Form className='form-tip' form={tipForm} onFinish={onFinish} layout='vertical'>
        <div className='tipSize'>
          <Item name='tipSize' required initialValue={5} noStyle>
            <Radio.Group value={tipSize} onChange={e => onRadioClick(e.target.value)} buttonStyle='solid'>
              <Radio value={5}>$ 5</Radio>
              <Radio value={15}>$ 15</Radio>
              <Radio value={50}>$ 50</Radio>
            </Radio.Group>
            <Input
              className={customTipSize ? 'selected' : ''}
              type='number' prefix={customTipSize ? '$' : ' '}
              placeholder='Your choice'
              onClickCapture={e => onInputClick(e.target.value)}
              value={customTipSize}
              onChange={e => onInputChange(e.target.value)}
            />
          </Item>
        </div>
        <Item name='cardNumber' label='Credit card number'>
          <Input placeholder='ХХХХ - ХХХХ - ХХХХ - ХХХХ' />
        </Item>
        <Space size='middle'>
          <Item name='cardDate' label='Expiration date'>
            <Input placeholder='MM / YY' />
          </Item>
          <Item name='cardCVC' label='CVC Code'>
            <Input placeholder='000' />
          </Item>
        </Space>
        <div className='buttons'>
          <CustomButton text='Cancel' />
          <CustomButton htmlType='submit' type='dark' text={customTipSize || tipSize ? `Donate $${customTipSize || tipSize}` : 'Donate'} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalTip
