import React from 'react'
import { Modal, Form, Input } from 'antd'
import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form

const ModalChangeEmail = ({ visible, setVisible, loading, submitForm }) => {
  return (
    <Modal
      className='modal-change-email'
      title='Change Email'
      visible={visible}
      width={472}
      onCancel={() => setVisible(false)}
      footer={null}
      centered
      closable={!loading}
      destroyOnClose
    >
      <Form onFinish={submitForm} layout='vertical' id='change-email'>
        <Item
          rules={[
            { required: true, message: 'Please enter email!' },
            { type: 'email', message: 'Please enter a valid email!', transform: value => value?.trim() },
          ]}
          label='Email'
          className='defaultInputLabel email'
          name='email'
          normalize={v => v.trimStart()}
        >
          <Input className='darkBg' />
        </Item>
        <div className='additionalInfoBtnWrapper'>
          <CustomButton onClick={() => setVisible(false)} className='cancelInfoBtn' text='Cancel' type='white' />
          <CustomButton className='submitInfoBtn' text='Save' htmlType='submit' type='dark' />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalChangeEmail
