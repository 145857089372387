import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, InputNumber } from 'antd'
import isInteger from 'lodash/isInteger'
import { CustomButton } from 'components'
import './index.styl'

const { Item } = Form
const { TextArea } = Input

const ModalEditBundles = ({ visible = false, bundleList, selectedBundleID, templateName, onChange, onFinish, name, loading }) => {
  const attr = { visible, onCancel: onChange, width: 472, maxWidth: 472, footer: null, className: 'wrapperModalEditBundles', destroyOnClose: true }
  const [form] = Form.useForm()
  const [bundleCategories, setBundleCategories] = useState([])
  const textSubmit = 'Save'
  useEffect(() => {
    const selectedBundle = bundleList.find(bundle => bundle.id === selectedBundleID)
    if (selectedBundle) {
      const ticketCategories = selectedBundle.ticketCategories.map(category => ({
        categoryName: category.categoryName,
        quantity: category.quantity,
      }))
      form.setFieldsValue({
        categories: ticketCategories,
        bundleName: selectedBundle.bundleName,
        notes: selectedBundle.notes,
        quantity: selectedBundle.quantity,
        price: selectedBundle.price,
      })
      const getBundleCategories = ticketCategories
      setBundleCategories(getBundleCategories)
    }
  }, [selectedBundleID, bundleList, form])

  const integerValidator = message => {
    return {
      validator (_, value) {
        if (value && (!isInteger(value))) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }

  return (
    <Modal title='Bundle Tickets' {...attr}>
      <Form form={form} layout='vertical' className='form' onFinish={onFinish}>
        <Item
          className='item'
          colon={false}
          label='Categories'
          name='categories'
        >
          {bundleCategories?.map((category, index) => {
            return (
              <div className='editBundleCategory' key={index}>
                <div className='categoryName'>{category?.categoryName}</div>
                <div className='categoryCount'>{category?.quantity}</div>
              </div>
            )
          })}
        </Item>

        <Item
          rules={[{ required: true, message: 'Enter Bundle name' }]}
          label='Bundle name'
          className='item'
          name='bundleName'
          normalize={v => v.trimStart()}
        >
          <Input placeholder='Type here' className='input' />
        </Item>

        <Item
          name='notes'
          rules={[{ required: true, message: 'Enter Bundle note' }]}
          validateTrigger='onBlur'
          className='item bundleNote'
          label='Bundle note'
          normalize={v => v.trimStart()}
        >
          <TextArea placeholder='Type here' maxLength={256} />
        </Item>
        <div className='bundleQuantityWrapper'>
          <Item
            name='quantity'
            label='Number of Bundles'
            className='item bundleQuantity'
            validateTrigger='onBlur'
            colon={false}
            rules={[
              { required: true, type: 'number', message: 'Please enter quantity' },
              integerValidator('Invalid number!'),
              // maxValidator('ticketCount.available', 'Quantity shouldn\'t exceed tickets you have'),
            ]}
          >
            <InputNumber
              placeholder='0'
              min={1}
              className='darkBg'
              // onChange={value => setQuantity(value)}
            />
          </Item>
          <Item
            name='price'
            label='Price'
            className='item bundlePrice'
            validateTrigger='onBlur'
            colon={false}
            rules={[
              { required: true, type: 'number', message: 'Please enter price' },
              // maxValidator('price', 'Price should be lower than ticket price'),
            ]}
          >
            <InputNumber
              placeholder='0'
              className='darkBg'
              // onChange={value => setPrice(value)}
            />
          </Item>
        </div>
        <div className='bundleCustomFooter'>
          <CustomButton className='btnCancel' text='Cancel' type='white' onClick={onChange} htmlType='button' />
          <CustomButton text={textSubmit} type='dark' htmlType='submit' className='btn' loading={loading} />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEditBundles
