import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { TicketOrder } from 'components'
import closeIconSvg from 'images/Crossmark.svg'
import './index.styl'

const ModalDetailTicket = ({
  ticketData = {},
  onCloseModal,
  tag = 'Ticket',
  onSelectInvite = () => { },
}) => {
  const [visible, setVisible] = useState(null)

  const onCancel = () => {
    setVisible(false)
    setTimeout(onCloseModal, 100)
  }

  useEffect(() => {
    setVisible(ticketData)
  }, [ticketData])

  const attr = { onCancel, width: 800, maxWidth: 800, footer: null, maskStyle: { backgroundColor: '#E8EEF7E6' } }
  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />

  return (
    <Modal {...attr} zIndex={1000} className='wrapperModalDetailTicket' closeIcon={closeIcon} visible={visible}>
      <TicketOrder tag={tag} onSelectInvite={onSelectInvite} ticketData={ticketData} onCancel={onCancel} />
    </Modal>
  )
}

export default ModalDetailTicket
