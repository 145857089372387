import React from 'react'
import {
  Form,
  Input,
  Button,
} from 'antd'
import { useHistory } from 'react-router-dom'
import './index.styl'
import { CustomButton } from 'components'
import SeatingScheme from './SeatingScheme'
const { Item } = Form
const GeneralInfo = ({ next, templateCover, templateCoverName, setTemplateCoverName, setTemplateCover, setTemplateName, templateName }) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const initialValues = {
    templateName: templateName,
    templateCover: templateCover.thumbnail1,
    templateCoverName: templateCoverName,
  }
  const generelInfoFinish = () => {
    const templateName = form.getFieldValue('templateName')
    if (templateName === '') {
      return false
    } else {
      next()
    }
  }
  return (
    <div className='wrapperGeneralInfo'>
      <Form initialValues={initialValues} form={form} colon={false} scrollToFirstError>
        <div className='bodyContainer centerContainer'>
          <div className='containerTitle'>General Info</div>
          <div>
            <Item
              rules={[{ required: true, message: 'Template Name is required' }]}
              label='Template Name'
              className='item'
              name='templateName'
              normalize={v => v.trimStart()}
            >
              <Input onChange={e => setTemplateName(e.target.value)} placeholder='Name' className='input' />
            </Item>
            <SeatingScheme templateCoverName={templateCoverName} setTemplateCoverName={setTemplateCoverName} templateCover={templateCover?.thumbnail1} setTemplateCover={setTemplateCover} />
          </div>
        </div>
        <div className='priceTemplateBottom'>
          <Button className='cancelBtn' type='link' onClick={() => history.push('/my-templates')}>Cancel</Button>
          <CustomButton className='submitBtn' text='Next Step' htmlType='submit' type='dark' onClick={generelInfoFinish} />
        </div>
      </Form>
    </div>
  )
}

export default GeneralInfo
