import React, { useEffect, useState } from 'react'
import { Layout } from 'components'
import Header from './Header'
import { useParams } from 'react-router-dom'
import { eventsV2 } from 'apiv2'
import { Spin } from 'antd'
import './index.styl'
import TemplateScheme from './TemplateScheme'
import ColorTemplate from './ColorTemplate'

const Template = () => {
  const [template, getTemplate] = useState(null)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  useEffect(() => {
    eventsV2.getTemplate(id).then(response => {
      getTemplate(response.data.template)
    })
  }, [loading])
  return (
    <Layout className='templateLayout'>
      <div>
        <Header {...template} />
        {template ? (
          <div className='flex'>
            <ColorTemplate isTemplate templateID={id} loading={loading} setLoading={setLoading} {...template} />
            <TemplateScheme templateID={id} loading={loading} setLoading={setLoading} {...template} />
          </div>
        ) : (
          <Spin indicator={null}>
            <div className='flex'>
              <ColorTemplate templateID={id} loading={loading} setLoading={setLoading} {...template} />
              <TemplateScheme templateID={id} loading={loading} setLoading={setLoading} {...template} />
            </div>
          </Spin>
        )}
      </div>
    </Layout>
  )
}

export default Template
