import React from 'react'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
// import './index.styl'

const NewHomePage = () => {
  return (
    <>
      <Header />
      <Body />
      <Footer />
    </>
  )
}

export default NewHomePage
