import React from 'react'
import './index.styl'
import TableSeats from '../TableSeats'
const SectionTable = ({ loading, isUser, sectionID, sectionType, selectedSeat, selectSeatArray, sectionName, setLoading, group, seatID, templateID, categoryID, categoriesArray, seatName, color, seatLeft, seatCount, isTableInvite, isCategoryInvite }) => {
  return (
    <div>
      <TableSeats isCategoryInvite={isCategoryInvite} isTableInvite={isTableInvite} isUser={isUser} sectionID={sectionID} sectionType={sectionType} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} sectionName={sectionName} group={group} categoryID={categoryID} loading={loading} setLoading={setLoading} seatID={seatID} templateID={templateID} categoriesArray={categoriesArray} seatName={seatName} color={color} seatCount={seatCount} seatLeft={seatLeft} />
    </div>
  )
}

export default SectionTable
