/* eslint-disable indent */
import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  deleteEventScannerInvites,
  resetEventScannerInvites,
  setEventScannerInvites,
  getScannedTickets,
  getEventScannerInvites,
  activateScannerV2,
} from 'modules/events/events-actions'
import { CustomButton, ModalNewScannerInvite } from 'components'
import { Table, Input, Switch, Spin, Modal, Pagination } from 'antd'
import SearchIcon from 'images/input-search.svg'
import deleteIcon from 'images/deleteIcon.svg'
import { throttle } from 'lodash'
import { handleConfirmModal } from 'utils'
import './index.styl'
import MobileTable from '../../MobileTable'

const { confirm } = Modal
const throttleFunc = fn => throttle(fn, 300, { trailing: false })
const transformData = (arr = []) => arr.map(i => ({
  scannerID: i._id,
  ...i?.userID,
}))
const getOffset = ({ current, limit }) => current * limit - limit
const getParams = ({ current, limit }) => ({ limit, skip: getOffset({ current, limit }) })
const limit = 1000

const ScanTickets = () => {
  const { scannerInvites, currentEventDetails, scannersTotalCount, scannedTickets, scannedTicketsTotal } = useSelector(state => state.events)
  const [isActive, setIsActive] = useState(currentEventDetails?.activateScanner || false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageScannedTickets, setCurrentPageScannedTickets] = useState(1)
  const [searchInput, setSearch] = useState('')
  const [searchInputScannedTickets, setSearchScannedTickets] = useState('')
  const [loading, setLoading] = useState(true)
  const [loadingActivation, setLoadingActivation] = useState(true)
  const [searchLoading, setSearchLoading] = useState(false)
  const [scanner, setScanner] = useState(true)
  const { eventID } = useParams()
  const dispatch = useDispatch()
  const [visibleModal, setVisibleModal] = useState(false)
  const containerRef = useRef(null)
  const handlePagination = (current, limit) => fetchEventData(current, limit)

  const fetchEventData = (current, limit, search = '') => {
    if (scanner) {
      setLoading(true)
      dispatch(getEventScannerInvites({ eventID, search, ...getParams({ current, limit }) }))
        .then(() => {
          setLoading(false)
          setCurrentPage(current)
        })
    } else {
      dispatch(getScannedTickets({ eventID, search, ...getParams({ current, limit }) }))
      .then(() => {
        setLoading(false)
        setCurrentPageScannedTickets(current)
      })
    }
  }

  const searchFetching = throttleFunc((current, limit, search = '') => {
    setSearchLoading(true)
  if (scanner) {
    dispatch(getEventScannerInvites({ eventID, search, ...getParams({ current, limit }) }))
    .then(() => {
      setSearchLoading(false)
      setCurrentPage(current)
    })
  } else {
    dispatch(getScannedTickets({ eventID, search, ...getParams({ current, limit }) }))
    .then(() => {
      setSearchLoading(false)
      setCurrentPageScannedTickets(current)
    })
   }
  })

  useEffect(() => {
    if (eventID) {
      window.innerHeight <= 815 && containerRef.current.scrollIntoView()
    }
    return () => dispatch(resetEventScannerInvites())
  }, [eventID])

  useEffect(() => {
    setIsActive(currentEventDetails?.activateScanner)
    setLoading(currentEventDetails?.activateScanner === undefined)
    setLoadingActivation(currentEventDetails?.activateScanner === undefined)
  }, [currentEventDetails?.activateScanner])

  useEffect(() => {
    if (scanner) {
      searchFetching(1, limit, searchInput)
    } else {
      searchFetching(1, limit, searchInputScannedTickets)
    }
  }, [searchInput, searchInputScannedTickets, scanner])

  const handleModal = () => setVisibleModal(!visibleModal)

  const onChange = action => {
    setLoadingActivation(true)
    setIsActive(!isActive)

    const onCancelHandler = () => {
      setIsActive(false)
      setLoadingActivation(false)
    }

    if (!isActive) {
      const onOk = () => {
        dispatch(activateScannerV2(eventID, { action: action }))
          .then(() => setLoadingActivation(false))
          .catch(() => setLoadingActivation(false))
      }
      handleConfirmModal('All guests will be notified by email. Are you sure you want to continue?', onOk, onCancelHandler)
    } else {
      dispatch(activateScannerV2(eventID, { action: action }))
        .then(() => setLoadingActivation(false))
        .catch(() => setLoadingActivation(false))
    }
  }

  const onSearch = ({ target }) => scanner ? setSearch(target?.value) : setSearchScannedTickets(target?.value)

  const handleAddScanner = values => {
    setLoading(true)
    dispatch(setEventScannerInvites({ email: values.email, eventID }))
      .then(() => {
        setVisibleModal(false)
        fetchEventData(1, 10, searchInput)
      })
      .catch(() => {})
      .finally(() => setLoading(false))
  }

  const handleDeleteScanner = scannerID => {
    setLoading(true)
    dispatch(deleteEventScannerInvites({ scannerID, eventID }))
      .then(() => fetchEventData(currentPage, 10, searchInput))
      .catch(() => setLoading(false))
  }

  const handleConfirmDelete = scannerID => confirm({
    title: 'Are you sure you want to remove that scanner?',
    cancelText: 'No',
    okText: 'Yes',
    onOk: () => handleDeleteScanner(scannerID),
  })

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_, { firstName, lastName }) => `${firstName ?? ''} ${lastName ?? ''}`,
      width: '40%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '40%',
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, { scannerID }) => (
        <div className='btnWrapper'>
          <CustomButton
            disabled={searchLoading}
            text={<img src={deleteIcon} alt=' ' />}
            onClick={() => handleConfirmDelete(scannerID)}
            type='dark'
          />
        </div>
      ),
    },
  ]
  const columnsScaned = [
    {
      title: 'User name',
      dataIndex: 'userName',
      key: 'userName',
      width: '20%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
    },
    {
      title: 'Ticket Id',
      dataIndex: 'id',
      key: 'id',
      width: '15%',
    },
    {
      title: 'Scanned on',
      dataIndex: 'scannedOn',
      key: 'scannedOn',
      width: '25%',
      render: (_, { scannedOn }) => scannedOn ? moment(scannedOn).format('MMMM DD, YYYY hh:mm a') : '',
    },
    {
      title: 'Scanned by',
      dataIndex: 'scannedBy',
      key: 'scannedBy',
      width: '25%',
    },
  ]

  const tableHeader = (
    <div className='tableHeader'>
      <button onClick={() => setScanner(!scanner)} className={`table-title ${scanner ? 'activeTableTitle' : ''}`}>Scanners</button>
      <button onClick={() => setScanner(!scanner)} className={`table-title ${!scanner ? 'activeTableTitle' : ''}`}>Scanned Tickets</button>
      <span className='border' />
      <Input
        prefix={<img alt='search icon' src={SearchIcon} />}
        className='inputSearch noFocusBorder'
        onChange={throttleFunc(onSearch)}
        placeholder='Search'
        value={scanner ? searchInput : searchInputScannedTickets}
        allowClear
      />
    </div>
  )

  return (
    <>
      <div className='wrapperTablesScanner' ref={containerRef}>
        <div className='scanner-header'>
          <div className='scanner-title'>Scan Tickets</div>
          <CustomButton
            text='Assign scanner'
            className='btnHeader'
            onClick={handleModal}
            disabled={loading}
            type='dark'
          />
        </div>
        <div className='switch-scanner'>
          <Switch
            checked={isActive}
            onChange={onChange}
            className='switch'
            disabled={loadingActivation}
            loading={loadingActivation}
          />
          <span className='switch-title'>Enable all scanners and notify guests</span>
        </div>
        <Spin spinning={loading} indicator={null}>
          {scanner
            ? (
              <Table
                pagination={{
                pageSize: 10,
                total: scannersTotalCount,
                onChange: handlePagination,
                current: currentPage,
                }}
                dataSource={transformData(scannerInvites)}
                columns={columns}
                rowKey='_id'
                sticky
                title={() => tableHeader}
              />
            ) : (
              <div>
                {window.innerWidth > 550
                ? (
                  <Table
                    pagination={{ pageSize: 6 }}
                    dataSource={scannedTickets}
                    columns={columnsScaned}
                    rowKey='_id'
                    sticky
                    title={() => tableHeader}
                  />
                ) : (
                  <div>
                    {tableHeader}
                    <MobileTable data={scannedTickets} columns={columnsScaned} loading={loading} />
                  </div>
            )}
              </div>
            )}
        </Spin>
        {!!scannedTickets.length && scannedTicketsTotal && (
          <Pagination
            showSizeChanger={false}
            className='pagination'
            onChange={handlePagination}
            disabled={loading}
            current={currentPageScannedTickets}
            pageSize={limit}
            total={scannedTicketsTotal}
          />
        )}
      </div>
      <ModalNewScannerInvite
        visible={visibleModal}
        updateVisible={handleModal}
        submitForm={handleAddScanner}
        loading={loading}
      />

    </>
  )
}

export default ScanTickets
