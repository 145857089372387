import React, { useState } from 'react'
import { Input, InputNumber, Modal } from 'antd'
import './index.styl'

const ModalEditTable = ({ name, count, title, onClose, updateData, loading, isEdit }) => {
  const [value, setValue] = useState(name)
  const [number, setNumber] = useState(count)
  const onSave = () => updateData(value, number)
  return (
    <Modal
      okButtonProps={{ loading, disabled: loading || (isEdit && !value?.trim()) || !number || isNaN(Number(number)) }}
      maskStyle={{ backgroundColor: '#62677EE5' }}
      onCancel={() => loading ? {} : onClose()}
      className='ModalEditTable'
      title={title || 'Edit table'}
      closable={!loading}
      onOk={onSave}
      okText='Save'
      visible
    >
      <div className='item'>
        <div className='label'><span>*</span>Table name</div>
        <Input value={value} onChange={e => setValue(e.target?.value)} placeholder='Enter table name' />
      </div>
      <div className='item'>
        <div className='label'><span>*</span>Number of seats</div>
        <InputNumber value={number} onChange={v => setNumber(v)} placeholder='Enter number of seats' />
      </div>
    </Modal>
  )
}

export default ModalEditTable
