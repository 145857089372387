import React, { useContext, useState } from 'react'
import { Form, Input, Space, Divider, Switch } from 'antd'
import TicketCategory from '../TicketCategory'
import TooltipInfo from '../TooltipInfo'
import c from 'classnames'
import { CreateEventContext } from '../'
import { urlValidator } from 'modules/utils/validators'
import { getEventUrl, MAIN_APP_URL } from 'utils'
import './index.styl'

const { Item, List } = Form
const { TextArea } = Input
const eventUrlLabel = () => (
  <TooltipInfo
    text='Customized ticket link (Vanity URL)'
    info='The Customized Ticket Link can be a custom, memorable and pronounceable link to tickets for your event. Use this for marketing or to send people directly to your event. This feature is only available for paid ticket events'
  />
)

const OptionsEvent = () => {
  const { currentEventDetails, eventType, form } = useContext(CreateEventContext)
  const [donationTicketBool, setDonationTicketBool] = useState(currentEventDetails?.isDonationEnable || false)
  const [availableTicket, setAvailableTicket] = useState(currentEventDetails?.showAvailableTickets || false)
  const [isHomePageToggle, setIsHomePageToggle] = useState(currentEventDetails?.isShowTLHomePage || false)
  const availableTicketHandler = () => {
    setAvailableTicket(!availableTicket)
    form.setFieldsValue({
      showAvailableTickets: !availableTicket,
    })
  }
  const donationTicketHandler = () => {
    setDonationTicketBool(!donationTicketBool)
    form.setFieldsValue({
      donationEnabled: !donationTicketBool,
    })
  }
  const showOnHomePageHandler = () => {
    setIsHomePageToggle(!isHomePageToggle)
    form.setFieldsValue({
      showOnHomePageEnabled: !isHomePageToggle,
    })
  }

  const getTicketData = idx => {
    const categories = form.getFieldValue('categories')
    return categories[idx]
  }

  return (
    <div className='detailsWrapper'>
      {eventType === 'live' && (
        <>
          <Divider />
          <List name='categories'>
            {(fields, { add, remove }) => (
              <div className='categoriesWrapper'>
                {fields.map((field, i) => <TicketCategory listItem namePrefix={field.name} key={field.name} ticketData={getTicketData(field.fieldKey)} onRemove={remove} />)}
                <div id='addCategory' className='btn' onClick={() => add()}>Add New Category</div>
              </div>)}
          </List>
        </>
      )}
      <Divider />
      <Item className='item' label={eventUrlLabel()}>
        <Space>
          {`${MAIN_APP_URL}${getEventUrl('')}`}
          <Item
            rules={[{ validator: urlValidator }]}
            name='eventUrl'
            noStyle
          >
            <Input placeholder='Add information' className={c('input', 'inputDisabled')} />
          </Item>
        </Space>
      </Item>
      <div className='special-divider' />
      <Item
        initialValue={currentEventDetails?.description}
        name='eventDescription'
        className='item'
        label='Notes'
        normalize={v => v.trimStart()}
      >
        <TextArea placeholder='Your notes' rows={4} />
      </Item>
      <Divider />
      <div className='radioItem'>
        <Item style={{ marginBottom: 0 }} initialValue={availableTicket} name='showAvailableTickets' valuePropName='checked' className='item'>
          <Switch onChange={availableTicketHandler} defaultChecked={availableTicket} />
        </Item>
        <div className='text'>Show available tickets</div>
      </div>
      <Divider />
      <div className='radioItem'>

        <Item
          name='donationEnabled'
          initialValue={donationTicketBool}
          valuePropName='checked'
          className='item noGutter'
        >
          <Switch onChange={donationTicketHandler} defaultChecked={donationTicketBool} />
        </Item>
        <div className='text'>Add Donation Ticket</div>
      </div>
      <Divider />
      {currentEventDetails?.isVenueDraft && currentEventDetails?.isVenueEvent || !currentEventDetails?.isVenueDraft && currentEventDetails?.isVenueEvent || currentEventDetails?.isVenueDraft
        ? (
          <div className='radioItem'>
            <Item
              name='showOnHomePageEnabled'
              initialValue={isHomePageToggle}
              valuePropName='checked'
              className='item noGutter'
            >
              <Switch onChange={showOnHomePageHandler} defaultChecked={isHomePageToggle} />
            </Item>
            <div className='text'>Show tickets on TL Home Page</div>
          </div>
        ) : null}
      {currentEventDetails?.isVenueEvent && <Divider />}
    </div>
  )
}

export default OptionsEvent
