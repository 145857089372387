import { template } from '../../apiv2'
import { notification } from 'antd'
import * as actionTypes from './templates-constants'
// import * as sessionTypes from '../session/session-constants'
// import { eventsSelector } from './templates-selectors'
// import { push } from 'modules/router'

const msgError = error => {
  const message = error?.response?.data?.message || error?.response?.data?.error
  notification.warning({ message })
}

// export const eventsGetEvents = payload => ({ type: actionTypes.EVENTS_GET_EVENTS, payload })

export const editCategory = (templateID, obj = {}) => () => {
  return new Promise((resolve, reject) => {
    template
      .editCategory(templateID, obj)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}
export const editSection = (templateID, obj = {}) => () => {
  return new Promise((resolve, reject) => {
    template
      .editSection(templateID, obj)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const editRowTable = (templateID, obj = {}) => () => {
  return new Promise((resolve, reject) => {
    template
      .editRowTable(templateID, obj)
      .then(data => {
        resolve(data)
      })
      .catch(error => {
        reject(error)
        msgError(error)
      })
  })
}

export const addBuySeat = payload => ({
  type: actionTypes.BUY_SEAT,
  payload,
})
export const removeBuySeat = payload => ({
  type: actionTypes.REMOVE_BUY_SEAT,
  payload,
})
