import React, { useState, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { Skeleton, notification } from 'antd'
import { MockTicket, StatisticsBox, CustomButton, Counter } from 'components'
import { getCurrencySymbolByCode } from 'utils'
import streamIcon from 'images/streamIcon.png'
import copyImg from 'images/copyLinkImg.svg'
import arrow from 'images/simpleArrow.svg'
import copyLink from 'images/copyIcon.png'
import './index.styl'
import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'

// TEMPORARY HIDDEN COPY LINK
// import { CopyToClipboard } from 'react-copy-to-clipboard/lib/Component'
// import { notification } from 'antd'

const Body = ({ loaded }) => {
  const history = useHistory()
  const [count, setCount] = useState(1)
  const { user } = useSelector(state => state.session)
  const { mockTicket } = useSelector(state => state.events)

  useLayoutEffect(() => {
    if (!mockTicket._id) {
      history.push('/')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [mockTicket])

  const { currency, originalPrice, eventType } = mockTicket
  const currencySymbol = getCurrencySymbolByCode(currency)

  const format = value => {
    return value?.toLocaleString(navigator.language, { minimumIntegerDigits: 1 })
  }
  const moneyCommaFormat = value => {
    try {
      return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    } catch (e) {
      return value.toString()
    }
  }
  const statistics = {
    onlineSales: moneyCommaFormat(750),
    cashSalesRevenue: moneyCommaFormat(380),
    ticketsDistributed: format(59),
    ticketsGifted: format(3),
  }
  const link = `${location.origin}/buy/${mockTicket?.eventName?.toLowerCase().replace(/\s/g, '')}`
  const links = ['TICKETLYFE', 'rtmp//go.ticketlyfe.com/live']

  const toSignUp = () => {
    history.push('/auth/sign-up?type=User')
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleKeyPress = e => {
    if (e.charCode >= 48 && e.charCode <= 57) {
      setCount(Number(count + e.key))
    }
  }

  return (
    <Skeleton loading={!loaded} active={mockTicket._id} paragraph={{ rows: 15 }}>
      <div className='homeBodyMock'>
        <MockTicket tag='PreviewTicket' wrapperClassName='mockTicketWrapper' ticketData={mockTicket} />
        <div className='contentWrapper'>
          <div className='content-card'>
            <div className='mockBoxOffice'>Box Office</div>
            <StatisticsBox statistics={statistics} currencySymbol={currencySymbol} />
            <div className='copy'>
              {/* <div>
                <img src={copyImg} alt='copy' className='copyImg' />
                {link}
                <img src={copyLink} alt='copy' className='copyLink' />
              </div> */}
              {/* TEMPORARY HIDDEN COPY LINK */}
              <img src={copyImg} alt='copy' className='copyImg' />
              {link}
              <CopyToClipboard text={link} onCopy={() => notification.success({ message: 'Link copied to clipboard success' })}>
                <img src={copyLink} alt='copy' className='copyLink' />
              </CopyToClipboard>
              <div>
                {
                  eventType === 'STREAM'
                    ? links.map(item =>
                      <div key={item}>
                        <img src={streamIcon} alt='stream' className='streamIcon' />
                        {item}
                      </div>)
                    : ''
                }
              </div>
            </div>
          </div>
          <div className='content-card'>
            <div className='purchaseTicket'>
              <div className='title'>Get tickets</div>
              <div className='priceCount'>
                <div className='currency'>
                  {currencySymbol}{originalPrice}
                  <span>{eventType === 'VENUE' ? 'General Admission' : 'PPV'}</span>
                </div>
                <Counter
                  min={1}
                  max={100}
                  count={count}
                  setCount={setCount}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </div>
            <div className='purchaseButtons'>
              <CustomButton disabled className='button' type='dark' text={`Get tickets for ${currencySymbol}${originalPrice}`} />
            </div>
            <div className='navigationButtons'>
              <Link to='/home' className='toHomePage'>
                <div className='toHomePageTitle'>
                  <img src={arrow} alt='arrow' className='toHomePageArrow' />
                  Back to Home Page
                </div>
              </Link>
              {!user && <CustomButton className='button' type='dark' text='Sign Up Now' onClick={toSignUp} />}
            </div>
          </div>
        </div>
      </div>
    </Skeleton>
  )
}

export default Body
