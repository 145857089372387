import React, { useState } from 'react'
import { minimumPriceValidator, minimumPriceValidatorInviteOnly } from 'modules/utils/validators'
import { Form, Input, InputNumber, Radio, Switch } from 'antd'

import './index.styl'
import TooltipInfo from '../../../../../../CreateEventV2/TooltipInfo'
import SingleDatePicker from '../../../../Header/DetailsFields/SingleDatePicker'

const { Item } = Form

export const privacyOptionsLabel = () => (
  <TooltipInfo
    className='privacyLabel'
    text='Privacy options'
    info='The privacy options determine who can see your event. If it is a Public event it is available on the main page for anyone to purchase. If it is Invite Only, the only way to get tickets is by Gifting, Direct Sale or sharing the event link.'
  />
)
const ItemTicketItems = ({ namePrefix, currentEventDetails, categoryData, form }) => {
  const [privacyOption, setPrivacyOption] = useState(categoryData?.privacyOption || 'visible')
  const [isEarlyBird, setIsEarlyBird] = useState(categoryData?.hasEarlyBird || false)
  const [earlyBirdDate, setEarlyBirdDate] = useState(categoryData?.earlyBirdDate || '')
  const [availableTicket, setAvailableTicket] = useState(form.getFieldValue('showAvailableTickets') || false)

  const handlePrivacyOption = type => {
    // if (type === 'visible') {
    //  categoryData.privacyOption = 'visible'
    // }
    // if (type === 'invite') {
    //  categoryData.privacyOption = 'invite'
    // }
    setPrivacyOption(type)
  }
  const availableTicketHandler = () => {
    setAvailableTicket(!availableTicket)
    // form.setFieldsValue({
    //  showAvailableTickets: !availableTicket,
    // })
  }
  const onCheckEarlyBird = () => setIsEarlyBird(!isEarlyBird)
  return (
    <>
      <div className='topField'>
        <div className='inputFields'>
          <Item
            rules={[{ required: true, message: 'Enter Ticket Category Name' }]}
            name={[namePrefix, 'name']}
            label='Ticket Category Name'
          >
            <Input placeholder='Food, drink, t-shirt, etc.' className='inputName' />
          </Item>
          <div className='priceQuantityWrapper'>
            <div className='mobileFlex'>
              <Item
                name={[namePrefix, 'price']}
                label='Ticket Price'
                rules={[
                  { required: true, message: 'Enter price' },
                  privacyOption === 'visible' ? minimumPriceValidator : minimumPriceValidatorInviteOnly,
                ]}
              >
                <InputNumber placeholder='0' className='price' min={0} />
              </Item>
              <Item
                rules={[
                  { required: true, message: 'Enter quantity' },
                  { type: 'number', max: 100000, message: 'Quantity cannot exceed 100 000' },
                  {
                    validator: (_, value) =>
                      Number.isInteger(value) ? Promise.resolve() : new Promise((resolve, reject) => {
                        reject(new Error('Quantity must be an integer!'))
                      }),
                  },
                ]}
                name={[namePrefix, 'quantity']}
                label='Tickets Quantity'
              >
                <InputNumber
                  className='quantity'
                  placeholder='0'
                  min={0}
                />
              </Item>
            </div>
            <div className='privacyOptionWrapper'>
              {privacyOptionsLabel()}
              <Item
                rules={[{ required: true, message: 'Choose Privacy Option' }]}
                name={[namePrefix, 'privacyOption']}
                initialValue={privacyOption}
                className='no_margin item_radio-group'
              >
                <Radio.Group className='radioGroupWrapper' onChange={e => handlePrivacyOption(e.target.value)}>
                  <Radio checked value='visible'>Visible to Public</Radio>
                  <Radio value='invite'>Invite Only</Radio>
                </Radio.Group>
              </Item>
            </div>
          </div>
        </div>
        <Item
          rules={[{ required: true, message: 'Enter Additional Info' }]}
          name={[namePrefix, 'description']}
          label='Additional Info'
        >
          <Input className='additionalInfo' />
        </Item>
      </div>
      <div className='radioItemField'>
        <Item
          name={[namePrefix, 'showAvailableTickets']}
          valuePropName='checked'
          className='item'
          initialValue={availableTicket}
        >
          <Switch onChange={availableTicketHandler} defaultChecked={availableTicket} />
        </Item>
        <div className='text'>Show available tickets</div>
      </div>
      <div className='radioItemField earlyBirdDateItem'>
        <Item
          name={[namePrefix, 'hasEarlyBird']}
          className='item'
          valuePropName='checked'
        >
          <Switch onChange={onCheckEarlyBird} defaultChecked={isEarlyBird} />
        </Item>
        <div className='text'>Early Bird Special</div>
      </div>
      {isEarlyBird && (
        <div className='earlyBirdFieldsWrapper'>
          <SingleDatePicker label='Early Bird' namePrefix={namePrefix} nameText='earlyBirdDate' fromDate={earlyBirdDate} form={form} setFromDate={setEarlyBirdDate} title='Early Bird Date' />
          <Item
            name={[namePrefix, 'earlyBirdPrice']}
            dependencies={[[namePrefix, 'price']]}
            label='Price'
            rules={[
              { required: true, message: 'Enter price' },
              minimumPriceValidator,
            ]}
            className='earlyBirdPrice'
          >
            <InputNumber placeholder='0' min={0} />
          </Item>
        </div>
      )}
    </>
  )
}

export default ItemTicketItems
