import { payment } from 'api'
import { payment as paymentV2 } from 'apiv2'
import { PAYMENT_LOGIN_GUEST, PAYMENT_TOKEN_VENMO } from './payment-constants'
import { notification } from 'antd'

const msgError = error => {
  const message = error.response.data.message || error.response.data.error
  notification.warning({ message })
}

export const eventsGetEvents = payload => ({ type: PAYMENT_LOGIN_GUEST, payload })

export const eventsGetTokenVenmo = payload => ({ type: PAYMENT_TOKEN_VENMO, payload })

export const publicBuy = (obj, func) => dispatch => payment.publicBuy(obj)
  .then(() => func('tickets'))
  .catch(err => msgError(err))

export const guestPublicBuy = (obj, func) => dispatch => payment.guestPublicBuy(obj)
  .then(() => func('tickets'))
  .catch(err => msgError(err))

export const publicBuyV2 = (obj, func) => dispatch => paymentV2.publicBuyV2(obj)
  .then(() => func('tickets'))
  .catch(err => msgError(err))

export const guestPublicBuyV2 = (obj, func) => dispatch => paymentV2.guestPublicBuyV2(obj)
  .then(() => func('tickets'))
  .catch(err => msgError(err))

export const createStripeCustomer = obj => dispatch => payment.createStripeCustomer(obj)
  .then(data => { })
  .catch(err => msgError(err))

export const completeVenmoPayment = obj => dispatch => payment.completeVenmoPayment(obj)
  .then(data => { })
  .catch(err => msgError(err))

export const createCardToken = obj => dispatch => payment.createCardToken(obj)
  .then(data => { })
  .catch(err => msgError(err))

export const loginGuest = obj => dispatch => dispatch(eventsGetEvents(obj))
export const getTokenVenmo = obj => dispatch => dispatch(eventsGetTokenVenmo(obj))
