import React, { useEffect, useRef, useCallback } from 'react'
import MessageItem from './MessageItem'
import MessageInput from './MessageInput'
import { useSelector, useDispatch } from 'react-redux'
import { setMessagesAction, initChat, resetChat, setDisconnect } from 'modules/chat/chat-actions'
import { useHistory } from 'react-router-dom'
import { notification } from 'antd'
import io from 'socket.io-client'
import './index.styl'

const SOCKET_SERVER_URL = process.env.REACT_APP_API_ENDPOINT

const ChatFrame = ({ barcode, eventID }) => {
  const { token, user, isMobile } = useSelector(state => state.session)
  const { messages = [], isInit, disconnected } = useSelector(state => state?.chat)
  const dispatch = useDispatch()
  const { push } = useHistory()

  const messagesContainer = useRef(null)
  const socketRef = useRef(null)

  const sender = {
    userId: user?._id,
    avatar: user?.image?.thumbnail1,
    fullName: user?.firstName + ' ' + user?.lastName,
  }

  const setLocalMessage = useCallback(msg => {
    dispatch(setMessagesAction(msg))
  }, [])

  const setLocalMessages = message => {
    setLocalMessage(message)
    if (messagesContainer.current && messagesContainer.current.scroll) {
      messagesContainer.current.scroll({
        top: messagesContainer.current.scrollHeight,
        behavior: 'smooth',
        left: 0,
      })
    }
  }

  useEffect(() => {
    dispatch(resetChat())
    const query = {
      barcode, eventID,
    }
    token ? query.token = token : query.isGuest = !token

    socketRef.current = io(SOCKET_SERVER_URL, {
      query,
      transports: ['websocket'],
      reconnection: true,
    })

    socketRef.current.on('connect', () => {
      dispatch(initChat(true))
    })

    socketRef.current.on('serverInfo', setLocalMessages)

    socketRef.current.on('chat-message', setLocalMessages)

    socketRef.current.on('disconnect', () => {
      dispatch(setDisconnect())
    })

    return () => {
      socketRef.current.emit('leave', { barcode, eventID, user })
      socketRef.current.disconnect()
      dispatch(resetChat())
    }
  }, [])

  useEffect(() => {
    if (disconnected) {
      notification.info({ message: 'Only one connection allowed', duration: 12 })
      dispatch(resetChat())
      push('/')
    }
  }, [disconnected])

  const sendMessage = messageBody => {
    const message = {
      body: messageBody,
      date: Date.now(),
      type: 'user-message',
      sender,
      eventID,
    }

    socketRef.current.emit('message', message)
  }

  return (
    <div className='chat-frame'>
      <div ref={messagesContainer} className='messages-list'>
        {messages.map((message, i) => (
          <MessageItem key={i} message={message} />
        ))}
      </div>
      <MessageInput
        avatar={user?.image?.thumbnail1}
        sendMessage={sendMessage}
        isMobile={isMobile}
        disabled={!isInit}
      />
    </div>
  )
}

export default ChatFrame
