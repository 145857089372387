import api from './axios'

const changePassword = obj => api('user/changePassword', 'PUT', obj)

const accessTokenLogin = obj => api('user/accessTokenLogin', 'POST', obj)

const editProfile = obj => api('editProfile', 'POST', obj)

const editVenueProfile = obj => api('editVenueProfile', 'POST', obj)

const getLogoVenue = url => api(`venue/getVenue?venue=${url}`, 'GET', {})

export default { changePassword, getLogoVenue, accessTokenLogin, editProfile, editVenueProfile }
