import React, { useState, useEffect } from 'react'
import { Modal, notification } from 'antd'
import FormPayment from 'components/FormPaymentDiscount'
import StepsModal from 'components/ModalBuyTicket/StepsModal'
import closeIconSvg from 'images/Crossmark.svg'
import { events } from 'api'
import Logo from 'images/logo-square.png'
import circleDone from 'images/circleDone.png'
import { getCurrencySymbolByCode, streamTypes } from 'utils'
import c from 'classnames'
import './index.styl'

const _discount = {
  coupon: '',
  bonus: 0,
  percentOff: 0,
  error: '',
}

const steps = ['payment', 'success']

export default ({ visible = false, setVisible, callback, currency, quantity, dataTicket, streamType }) => {
  const [screen, setScreen] = useState(steps[0])
  const [orderPrice, setOrderPrice] = useState(0)
  const [orderName, setOrderName] = useState(null)
  const [loading, setLoading] = useState(true)
  const [promoCode, setPromoCode] = useState('')
  const [discount, setDiscount] = useState(_discount)
  const isPrivateStream = streamType === streamTypes.PRIVATE_STREAM
  const currencySymbol = getCurrencySymbolByCode(currency)
  const [publicStreamFee, setPublicStreamFee] = useState(null)
  useEffect(() => {
    if (currency) {
      setLoading(true)
      events.getBroadcastFee(currency)
        .then(({ data }) => {
          setOrderPrice(data?.data.amount)
          setOrderName(isPrivateStream ? 'Live Stream Bundles' : 'Streaming Fee')
          setLoading(false)
        })
        .catch(({ response: { data: { message } } }) => {
          notification.warning({ message })
          setLoading(true)
        })
    }
    // TODO: it's suppposed to be an endpoint too... in a future
    setPublicStreamFee(2)
  }, [currency])

  useEffect(() => {
    setOrderName(isPrivateStream ? 'Live Stream Bundles' : 'Streaming Fee')
  }, [streamType])
  const onCancel = () => {
    setScreen(steps[0])
    setVisible(false)
  }
  const onTicketsBought = (token, cb) => {
    callback(token, discount.coupon).then(() => cb && cb())
  }
  const checkPromo = () => {
    const promo = promoCode.trim()
    if (promo) {
      setLoading(true)
      events.checkCoupon(promo)
        .then(({ data }) => {
          setDiscount({
            coupon: data?.coupon,
            bonus: data?.bonus,
            percentOff: data?.percentOff || '',
            error: '',
          })
        })
        .catch(error => setDiscount({ ..._discount, error: error?.response?.data?.message }))
        .finally(() => setLoading(false))
    }
  }

  const screens = {
    payment: () => (
      <FormPayment
        totalPrice={!!quantity && isPrivateStream ? quantity * publicStreamFee - discount.bonus : orderPrice - discount.bonus}
        currencySymbol={currencySymbol}
        setPromoCode={setPromoCode}
        onSubmit={onTicketsBought}
        checkPromo={checkPromo}
        promoCode={promoCode}
        discount={discount}
        fetching={loading}
      />
    ),
    success: () => (
      <div className='success-block'>
        <div className='img-block'>
          <img src={circleDone} alt='success' />
        </div>
        <div className='order-success'>
          <span className='title-success'>Paid</span>
          <span className='price-success'>{currencySymbol}{orderPrice - discount.bonus}</span>
        </div>
        <div className='success-description'>Thank you for your reservation.</div>
      </div>
    ),
  }

  const closeIcon = <img src={closeIconSvg} alt='close icon' className='closeIcon' />
  const attr = { visible, onCancel, width: 840, maxWidth: 840, footer: null, className: 'modalBuyTicket' }

  return (
    <Modal title='Reserve Stream' {...attr} closeIcon={closeIcon} className='ModalBuyStreamTicket' centered>
      <div className='wrapperCategories'>
        <div className='imgLeft'>
          <img
            src={Logo}
            alt='logo'
            className='img'
          />
        </div>
        <div className='wrapperDescription'>
          <div className='title'>Purchase:</div>
          <ul>
            <li className='list-item'>
              <div className='label'>{orderName}</div>
              <div className='amount'>{currencySymbol}{!!quantity && isPrivateStream ? quantity * publicStreamFee - discount.bonus : orderPrice - discount.bonus}</div>
            </li>
            {!!discount.percentOff &&
              <li className='list-item'>
                <div className='label'>
                  <div>Discount</div>
                  <small>{!!discount.percentOff ? `-${discount.percentOff}%` : ''}</small>
                </div>
                <div className={c('amount', { discount: !!discount.bonus })}>{discount.bonus ? `-${currencySymbol}${discount.bonus}` : '-'}</div>
              </li>}
            <li className='list-item summary'>
              <div className='amount'>Total</div>
              <div className='amount'>{currencySymbol}{!!quantity && isPrivateStream ? quantity * publicStreamFee - discount.bonus : orderPrice - discount.bonus}</div>
            </li>
          </ul>
        </div>
      </div>

      <div className='wrapperScreen'>
        <StepsModal activeScreen={screen} userLogged stepsItems={steps} />
        <div className='screen'>
          {screens[screen]()}
        </div>
      </div>
    </Modal>
  )
}
