/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect } from 'react'
import c from 'classnames'
import { Link, useLocation } from 'react-router-dom'
import './index.styl'

const overviewBase = '/help/overview'

const Sidebar = () => {
  useEffect(() => {
    window.dataLayer.push({ event: 'Help center' })
  })
  const location = useLocation()
  const activePage = location.pathname.split('/').splice(2)
  return (
    <aside className='help_nav'>
      <div className='help_nav_container'>
        <h1>Help Center</h1>
        <ul>
          <li className='mainLink'><Link to={overviewBase + '/for-venues'} className={c({ active: activePage[0] === 'overview' })}>Overview</Link>

            <ul><li className='secondaryLink'><Link to={overviewBase + '/for-venues'} className={c({ active: activePage[1] === 'for-venues' })}>For Venues </Link></li></ul>
            {/* <ul>
                <li className='secondaryLink'><Link to={overviewBase + '/general'} className={c({ active: activePage[1] === 'general' })}>General</Link></li>
                <li className='secondaryLink'><Link to={overviewBase + '/my-wallet'} className={c({ active: activePage[1] === 'my-wallet' })}>My Wallet</Link></li>
                <li className='secondaryLink'><Link to={overviewBase + '/my-box-office'} className={c({ active: activePage[1] === 'my-box-office' })}>My Box Office</Link></li>
                <li className='secondaryLink'><Link to={overviewBase + '/make-my-tickets'} className={c({ active: activePage[1] === 'make-my-tickets' })}>Make My Tickets</Link></li>
              </ul> */}
          </li>
          {/* <li className='mainLink'><Link to='/help/faq' className={c({ active: activePage[0] === 'faq' })}>Frequently Asked Questions</Link></li> */}
          <li className='mainLink'><Link to='/help/terms-and-conditions' className={c({ active: activePage[0] === 'terms-and-conditions' })}>Terms and Conditions</Link></li>
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
