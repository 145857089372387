import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import arrow from 'images/simpleArrow.svg'
import { getMyTemplateURL } from 'utils'

import moment from 'moment'
import './index.styl'

const Header = props => {
  const { templateName, created, sections } = props
  const { state = {} } = useLocation()
  const creationDate = created && moment(created).utcOffset(created).format('MMMM D, YYYY')
  const getUrl = _state => {
    return getMyTemplateURL(_state.page)
  }
  return (
    <div className='headerTemplate'>
      <div>
        <Link to={getUrl(state)}>
          <img src={arrow} alt='arrow' className='icon' />
          <span className='linkText'>Back to My Templates</span>
        </Link>
      </div>
      <div className='templateName'>{templateName}</div>
      <div className='templateInfo'>
        <div className='infoWrapper'>
          <div className='title'>Sections: <span>{sections?.length}</span> </div>
        </div>
        {/* <div className='infoWrapper'>
            <div className='title'>Seats: <span>{sections?.[0].seatCount}</span> </div>
          </div> */}
        <div className='infoWrapper'>
          <div className='title'>Creation date: <span>{creationDate}</span></div>
        </div>
      </div>
    </div>
  )
}

export default Header
