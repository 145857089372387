import React from 'react'
import Carousel from 'react-multi-carousel'
import './index.styl'
import 'react-multi-carousel/lib/styles.css'

const Slider = ({ children, mlDots = false, showDots = false, responsive = {}, className }) => {
  return (
    <Carousel
      className={className || 'wrapperSlider'}
      showDots={showDots}
      responsive={responsive}
      infinite
      dotListClass={mlDots ? 'wrapperMlDots' : 'wrapperDots'}
      partialVisible
      autoPlay
      autoPlaySpeed={5000}
    >
      {children}
    </Carousel>
  )
}

export default Slider
