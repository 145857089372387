import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Table } from 'antd'
import { reports } from 'apiv2'
import './index.styl'

const FinancialReports = ({ eventID, currencySymbol, isResponsive }) => {
  const [promoterTable, setPromoterTable] = useState([])
  const [subpromotersTables, setSubpromotersTables] = useState([])

  const [publicSales, setPublicSales] = useState([])
  const [publicSalesTotals, setPublicSalesTotals] = useState([])
  const [directSales, setDdirectSales] = useState([])
  const [directSalesTotals, setDdirectSalesTotals] = useState([])
  const [cashCheckSales, setCashCheckSales] = useState([])
  const [cashCheckSalesTotals, setCashCheckSalesTotals] = useState([])
  const [gifts, setGifts] = useState([])
  const [giftsTotals, setGiftsTotals] = useState([])
  // const [report3, setReport3] = useState([])
  const { report } = useSelector(state => state.events)
  const [loading, setLoading] = useState(true)
  const { Summary: { Cell, Row } } = Table

  const getReport = () => {
    if (eventID) {
      setLoading(true)
      reports.getFinancialReports(eventID)
        .then(({ data }) => {
          setPromoterTable(data?.report1?.promoter)
          setSubpromotersTables(data?.report1?.subpromotions)
          setPublicSales(data?.report2?.publicSales)
          setPublicSalesTotals(data?.report2?.publicSalesTotals)
          setDdirectSales(data?.report2?.directSales)
          setDdirectSalesTotals(data?.report2?.directSalesTotals)
          setCashCheckSales(data?.report2?.cashSales)
          setCashCheckSalesTotals(data?.report2?.cashSalesTotals)
          setGifts(data?.report2?.gifts)
          setGiftsTotals(data?.report2?.giftsTotals)

          // setReport3(data?.report3)
          setLoading(false)
        })
    }
  }
  useEffect(() => {
    getReport()
  }, [report])
  const columnsSubpromoter = [
    {
      title: 'Sub Promote',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (item, record) => <span>{item ? currencySymbol : ''}{item}</span>,
    },
    {
      title: '# Sub Promoted',
      dataIndex: 'ticketsMade',
      key: 'ticketsMade',
    },
    {
      title: 'Sold',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
    {
      title: 'Online Sales',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
    {
      title: 'Promoter\'s Take',
      dataIndex: 'promotersTake',
      key: 'promotersTake',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
    {
      title: 'Cash/Zelle Sales',
      dataIndex: 'cashSales',
      key: 'cashSales',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
  ]

  const columnsPromoter = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (item, record) => <span>{item ? currencySymbol : ''}{item}</span>,
    },
    {
      title: 'Made',
      dataIndex: 'ticketsMade',
      key: 'ticketsMade',
    },
    {
      title: 'Sold',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
    {
      title: 'Online Sales',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      render: (item, record) => <span>{item.includes('-') ? item.slice(0, 1) + currencySymbol + item.slice(1) : currencySymbol + item}</span>,
    },
    {
      title: 'Promoter\'s Take',
      dataIndex: 'promotersTake',
      key: 'promotersTake',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
    {
      title: 'Cash/Zelle Sales',
      dataIndex: 'cashSales',
      key: 'cashSales',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
  ]

  const columnsPublic = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (item, record) => <span>{item ? currencySymbol : ''}{item}</span>,
    },
    {
      title: 'Sold',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
    {
      title: 'Online Sales',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
    {
      title: 'Promoter\'s Take',
      dataIndex: 'promotersTake',
      key: 'promotersTake',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
  ]

  const columnsDirect = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: '',
      dataIndex: 'price',
      key: 'price',
      render: (item, record) => <span>{item ? currencySymbol : ''}{item}</span>,
    },
    {
      title: 'Sold',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
    {
      title: 'Online Sales',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
    {
      title: 'Promoter\'s Take',
      dataIndex: 'promotersTake',
      key: 'promotersTake',
      render: (item, record) => <span>{item !== undefined ? currencySymbol : ''}{item}</span>,
    },
  ]

  const columnsGifts = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Tickets Gifted',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
  ]

  const columnsCashCheckSales = [
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'category',
    },
    {
      title: 'Sold',
      dataIndex: 'ticketsSold',
      key: 'ticketsSold',
    },
    {
      title: 'Gross Revenue',
      dataIndex: 'grossRevenue',
      key: 'grossRevenue',
      render: (item, record) => <span>{currencySymbol}{item}</span>,
    },
  ]
  // const columnsOverview = [
  //   {
  //     title: 'UserName',
  //     dataIndex: 'userName',
  //     key: 'userName',
  //   },
  //   {
  //     title: 'Number',
  //     dataIndex: 'ticketsSold',
  //     key: 'ticketsSold',
  //   },
  //   {
  //     title: 'Gross',
  //     dataIndex: 'grossRevenue',
  //     key: 'grossRevenue',
  //     render: (item, record) => <span>{currencySymbol}{item}</span>,
  //   },
  // ]

  return (
    <>
      <div className='report wrapperTablesAssignSeating'>
        <div className='titleAssignedSeating'>Financial Reports</div>
        <div className='firstRepWrapper'>
          <div className='boxTitle titleAssignedSeating'>Promoter's Box Office</div>
          <Table
            columns={columnsPromoter}
            pagination={false}
            loading={loading}
            dataSource={promoterTable.data}
            scroll={{ x: 500 }}
            className='eventDetailsTable financialTable'
            summary={() => {
              return (
                <>
                  {promoterTable?.totals && promoterTable?.totals.map((row, i) =>
                    <Row key={i}>
                      <Cell>{row.totalName}</Cell>
                      <Cell />
                      <Cell>{row.ticketsMade}</Cell>
                      <Cell>{row.ticketsSold}</Cell>
                      <Cell><span>{currencySymbol}{row.grossRevenue}</span></Cell>
                      <Cell><span>{row.totalName === 'Refunds' ? '' : currencySymbol}{row.promotersTake}</span></Cell>
                      <Cell><span /></Cell>
                    </Row>,
                  )}
                </>
              )
            }}
          />
          {subpromotersTables.map((subpromoter, i) => {
            return (
              <Table
                columns={columnsSubpromoter}
                pagination={false}
                loading={loading}
                title={() => <div className='titleAssignedSeating tabletitle'>{subpromoter.subpromoterName}</div>}
                dataSource={[...subpromoter.data, subpromoter.refunds]}
                className='eventDetailsTable financialTable'
                key={i}
                scroll={{ x: 500 }}
                summary={() => {
                  return (
                    <>
                      <Row>
                        <Cell>Totals</Cell>
                        <Cell />
                        <Cell>{subpromoter.totals.ticketsMade}</Cell>
                        <Cell>{subpromoter.totals.ticketsSold}</Cell>
                        <Cell><span>{currencySymbol}{subpromoter.totals.grossRevenue}</span></Cell>
                        <Cell><span>{currencySymbol}{subpromoter.totals.promotersTake}</span></Cell>
                        <Cell><span>{currencySymbol}{subpromoter.totals.cashSales || subpromoter.totals.cashSales}</span></Cell>
                      </Row>
                    </>
                  )
                }}
              />
            )
          },
          )}
        </div>
      </div>
      <div className='report wrapperTablesAssignSeating mg-t-32'>
        <div className='firstRepWrapper'>
          <div className='boxTitle titleAssignedSeating'>Ticket Distribution</div>
          <Table
            columns={columnsPublic}
            pagination={false}
            title={() => <div className='titleAssignedSeating tabletitle'>Online Sales</div>}
            loading={loading}
            dataSource={publicSales}
            className='eventDetailsTable financialTable'
            summary={() => {
              return (
                <>
                  {publicSalesTotals.ticketsSold
                    ? (
                      <Row>
                        <Cell>Totals</Cell>
                        <Cell />
                        <Cell>{publicSalesTotals.ticketsSold}</Cell>
                        <Cell><span>{currencySymbol}{publicSalesTotals.grossRevenue}</span></Cell>
                        <Cell><span>{currencySymbol}{publicSalesTotals.promotersTake}</span></Cell>
                      </Row>
                    )
                    : ''}
                </>
              )
            }}
          />
          <Table
            columns={columnsDirect}
            pagination={false}
            title={() => <div className='titleAssignedSeating tabletitle'>Direct Sales</div>}
            loading={loading}
            dataSource={directSales}
            className='eventDetailsTable financialTable'
            summary={() => {
              return (
                <>
                  {directSalesTotals.ticketsSold
                    ? (
                      <Row>
                        <Cell>Totals</Cell>
                        <Cell />
                        <Cell>{directSalesTotals.ticketsSold}</Cell>
                        <Cell><span>{currencySymbol}{directSalesTotals.grossRevenue}</span></Cell>
                        <Cell><span>{currencySymbol}{directSalesTotals.promotersTake}</span></Cell>
                      </Row>
                    )
                    : ''}
                </>
              )
            }}
          />
          <Table
            columns={columnsGifts}
            pagination={false}
            title={() => <div className='titleAssignedSeating tabletitle'>Gifts</div>}
            loading={loading}
            dataSource={gifts}
            className='eventDetailsTable financialTable'
            summary={() => {
              return (
                <>
                  {giftsTotals.ticketsSold
                    ? (
                      <Row>
                        <Cell>Totals</Cell>
                        <Cell>{giftsTotals.ticketsSold}</Cell>
                      </Row>
                    )
                    : ''}
                </>
              )
            }}
          />
          <Table
            columns={columnsCashCheckSales}
            pagination={false}
            title={() => <div className='titleAssignedSeating tabletitle'>Cash/Zelle Sales</div>}
            loading={loading}
            dataSource={cashCheckSales}
            className='eventDetailsTable financialTable'
            summary={() => {
              return (
                <>
                  {cashCheckSalesTotals?.ticketsSold
                    ? (
                      <Row>
                        <Cell>Totals</Cell>
                        <Cell>{cashCheckSalesTotals.ticketsSold}</Cell>
                        <Cell><span>{currencySymbol}{cashCheckSalesTotals.grossRevenue}</span></Cell>
                      </Row>
                    )
                    : ''}
                </>
              )
            }}
          />
        </div>
      </div>
      {/* <div className='report wrapperTablesAssignSeating mg-t-32'>
        <div className='firstRepWrapper'>
          <div className='boxTitle titleAssignedSeating'>Overview</div>
          <Table
            columns={columnsOverview}
            pagination={false}
            loading={loading}
            dataSource={report3}
            scroll={report3 ? { x: 500 } : ''}
            className='eventDetailsTable financialTable'
          />
        </div>
      </div> */}
    </>
  )
}

export default FinancialReports
