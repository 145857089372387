import React from 'react'
import { Link } from 'react-router-dom'
import './index.styl'

const BtnSignIn = () => {
  return (
    <Link className='btnSignIn' to='/auth/sign-in'>
      Sign In
    </Link>
  )
}

export default BtnSignIn
