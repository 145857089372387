/* eslint-disable arrow-parens */
import isInteger from 'lodash/isInteger'

export const getRule = (label = '', name = '') => {
  const defaultRule = {
    required: true,
    message: 'Please enter ' + label,
  }
  const integerValidator = message => {
    return {
      validator (_, value) {
        if (value && (!isInteger(value))) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }
  const lengthValidator = message => {
    return {
      validator (_, value) {
        if (value && value.length > (name === 'presentedBy' ? 255 : 250)) {
          return Promise.reject(new Error(message))
        }
        return Promise.resolve()
      },
    }
  }

  const validateConfirmEmail = ({ getFieldValue }) => ({
    validator (_, value) {
      const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (regEmail.test(value)) {
        return Promise.resolve()
      }
      if (!value) {
        return Promise.reject(new Error('Please enter Confirm email'))
      }
      if (!!value && !getFieldValue('email')) {
        return Promise.reject(new Error('Please enter Email!'))
      }
      if (!value || getFieldValue('email') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('The two email that you entered do not match!'))
    },
  })
  const validateConfirmPhone = ({ getFieldValue }) => ({
    validator (_, value) {
      if (!!value && !getFieldValue('phone')) {
        return Promise.reject(new Error('Please enter Confirm Phone!'))
      }
      if (!value || getFieldValue('phone') === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('The phone that you entered do not match!'))
    },
  })

  const validationEmail = () => ({
    validator (_, value) {
      const regEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (!value) {
        return Promise.reject(new Error('Please enter Email'))
      }
      if (regEmail.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('Please enter valid Email'))
    },
  })

  switch (name) {
    case 'fullName':
      return [defaultRule, { min: 3, message: 'Full Name  must be at least 3 characters' }]
    case 'phoneNumber':
      return [defaultRule]
    case 'confirmPhone':
      return [defaultRule, validateConfirmPhone]
    case 'email':
      return [validationEmail]
    case 'confirmEmail':
      return [validateConfirmEmail]
    case 'price':
      return [defaultRule, {
        type: 'number',
        message: 'Price should be lower',
        max: 750,
      }, integerValidator('Invalid number')]
    case 'eventDescription':
      return [defaultRule, lengthValidator('Notes can consist no more than 250 letters!')]
    case 'eventName':
      return [defaultRule]
    case 'presentedBy':
      return [defaultRule, lengthValidator('Name can consist no more than 255 letters!')]
    case 'location':
      return [defaultRule]
    case 'venue':
      return [defaultRule]
    default: return [defaultRule]
  }
}
