import React, { useState } from 'react'
import monitorIcon from 'images/monitorIcon.svg'
import pulseIcon from 'images/pulseIcon.svg'
import close from 'images/close.svg'
import c from 'classnames'
import './index.styl'

export default () => {
  const [fadeOut, setFadeOut] = useState(false)
  return (
    <div className='banner-root'>
      <div className={c('banner', { fadeIn: !fadeOut, fadeOut: fadeOut })}>
        <img src={close} alt='close' className='close' onClick={() => setFadeOut(true)} />
        <div className='flex-container'>
          <div className='info'>
            <div className='title'>We limit our streaming</div>
            <div className='description'>
              <span className='text'>Please set your equipment appropriately</span>
            </div>
          </div>
          <div className='banner-icons-container'>
            <div className='banner-icon'>
              <div className='icon-container'><img src={monitorIcon} alt='720p' className='bannerLogo' /></div>
              <div className='banner-icon-text'>720p</div>
            </div>
            <div className='banner-icon'>
              <div className='icon-container'><img src={pulseIcon} alt='30 fps' className='bannerLogo' /></div>
              <div className='banner-icon-text'>30 fps</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
