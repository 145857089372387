import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { confirmationResetPassword } from 'modules/session/session-actions'
import { Form, Input } from 'antd'
import { CustomButton } from 'components'
import c from 'classnames'
import './index.styl'

const { Item } = Form

const ConfirmationResetPassword = () => {
  const dispatch = useDispatch()
  const { token } = useParams()

  const onFinish = values => {
    const obj = {
      passwordResetToken: token,
      newPassword: values.password,
    }
    dispatch(confirmationResetPassword(obj))
  }
  const onPressSpace = e => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }
  return (
    <div className='wrapperResetPassword'>
      <div className={c('title', 'titleResetPassword')}>Reset Password</div>
      <Form layout='vertical' onFinish={onFinish}>
        <Item
          name='password'
          label='Password'
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
            {
              whitespace: true,
              message: "Password shouldn't have spaces",
            },
          ]}
        >
          <Input.Password onKeyDown={onPressSpace} className='input' />
        </Item>

        <Item
          name='confirm'
          label='Confirm Password'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator (rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'))
              },
            }),
          ]}
        >
          <Input.Password onKeyDown={onPressSpace} className='input' />
        </Item>
        <CustomButton
          text='Set new password'
          type='dark'
          className={c('btn', 'btnSetNewPassword')}
          htmlType='submit'
        />
      </Form>
    </div>
  )
}

export default ConfirmationResetPassword
