import React from 'react'
import { Link } from 'react-router-dom'
import signInArrow from 'images/signInArrow.svg'
import './index.styl'

const BtnSignIn = () => {
  return (
    <>
      <Link style={{ zIndex: 999 }} className='btnSignIn' to='/auth/sign-in'>
        Sign In
        <img src={signInArrow} alt='sign in arrow' />
      </Link>
    </>
  )
}

export default BtnSignIn
