import React, { useState } from 'react'
import { CustomButton } from 'components'
import { useSelector } from 'react-redux'
import close from 'images/redCloseBanner.svg'
import c from 'classnames'
import './index.styl'
import { useHistory } from 'react-router'
import ModalTLFees from '../ModalTLFees'

export default () => {
  const [fadeOut, setFadeOut] = useState(false)
  const [visible, setVisible] = useState(false)
  const history = useHistory()
  const { user } = useSelector(state => state.session)
  return (
    <div>
      <div className='double-banner'>
        <div className={c('banner', { fadeIn: !fadeOut, fadeOut: fadeOut })}>
          <img src={close} alt='close' className='close' onClick={() => setFadeOut(true)} />
          <div className='red-flex-container'>
            <div className='logoTextWrapper'>
              <div className='infoCenter'>
                <div className='description'>
                  <div className='title'>Manage Your Club Now</div>
                  <span className='text'>Give your Members Lyfe... Make your Group Dynamic</span>
                </div>
              </div>
            </div>
            <CustomButton className='button' text='Go' type='darkInverse' onClick={() => history.push(user ? '/memberlyfe-home' : '/auth/sign-in')} />
          </div>
          <div className='white-flex-container'>
            <div className='logoTextWrapper'>
              <div className='infoCenter'>
                <div className='description'>
                  <div className='title'>TickeLyfe Fees Are Now $1</div>
                  <span className='text'>For tickets up to $100</span>
                </div>
              </div>
            </div>
            <CustomButton className='button' text='Show Details' type='darkInverse' onClick={() => setVisible(true)} />
          </div>
        </div>
      </div>
      <ModalTLFees visible={visible} setVisible={setVisible} />
    </div>

  )
}
