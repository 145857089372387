const sounds = {
  success: '/sounds/beep-success.mp3',
  fail: '/sounds/beep-fail.mp3',
}

export default status => {
  try {
    const audio = new Audio(sounds[status])
    audio.play()
    setTimeout(() => audio.pause(), 1000)
  } catch (e) {
    // eslint
  }
}
