import { useLocation } from 'react-router'
import { pick } from 'lodash'
import FileSaver from 'file-saver'
import moment from 'moment'

export function useQuery () {
  return new URLSearchParams(useLocation().search)
}
export function getLetter (num) {
  var letter = String.fromCharCode(num + 64)
  return letter
}

export const prepareAttachments = (arr = []) => {
  return arr.map(i => pick(i, ['original', 'thumbnail', 'fileName', 'fileSize']))
}
export const add3Dots = (string, limit) => {
  const dots = '...'
  if (string.length > limit) {
    // you can also use substr instead of substring
    string = string.replace(/.(?=.{40})/g, '') + dots
  }

  return string
}
export const prepareSections = (arr = []) => {
  const fields = ['sectionID', 'sectionName', 'sectionType', 'sectionRows', 'sectionColumns', 'tableCount', 'tableCapacity']
  return arr.map(i => pick(i, fields))
}

export const onValuesChange = form => {
  return values => {
    Object.keys(values).forEach(field => {
      const error = form.getFieldError(field)
      if (!error.length) return
      form.setFields([{ name: field, errors: [] }])
    })
  }
}

export const getEventType = (eventDetails = {}) => {
  if (!Object.keys(eventDetails || {}).length) return ''
  return eventDetails?.isVirtualTicket ? 'virtual' : eventDetails?.isStreamingEvent ? 'stream' : 'live'
}

export const getEventLabel = (event = {}) => {
  switch (event?.eventType) {
    case 'TICKETED_ADMITION':
      return 'Venue'
    case 'TICKETED_ADMITION_STREAM':
      return 'Venue and Stream'
    case 'STREAM':
      return 'Stream'
    case 'VIRTUAL':
      return 'Virtual'
    default:
      return 'Venue'
  }
}

export const getUtcOffsetDate = (date, format = 'h:mm A', utcOffset = '') => {
  return date ? moment(date).utcOffset(utcOffset || date).format(format) : ''
}
export const getEarlyBirdDate = (date, format = 'h:mm A') => {
  return date ? moment.utc(date).format(format) : ''
}

export const checkEarlyBirdActuality = (ebDate, timeZone) => {
  const currentDateTime = moment().tz(timeZone)
  const earlyBirdDateTime = moment.tz(ebDate, 'YYYY-MM-DD', timeZone)
  return currentDateTime.isSameOrBefore(earlyBirdDateTime)
}

export const isOnDemand = endDate => moment().isAfter(moment(endDate).utcOffset(endDate))

export const prepareFileName = (eventName = '', startDate, type) => {
  return `${eventName.substring(0, 10)} ${moment(startDate).format('MMMM DD, YYYY')} - (${type})`
}

export const saveCsvFile = (data, fileName) => {
  if (data) {
    const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' })
    FileSaver.saveAs(csvData, `${fileName}.csv`)
  }
}

export const getCroppedImg = (image, crop, fileName) => {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (!blob) {
        reject(new Error('Canvas is empty'))
        return
      }
      blob.name = fileName
      resolve(blob)
    }, 'image/jpeg')
  })
}

export const getMyBoxOfficeURL = (activeTab, orderBy, page = 1) => {
  return `/my-box-office/table-events?activeTab=${activeTab}&orderBy=${orderBy}&page=${page}`
}
export const getMemberOfficeURL = (orderBy, page = 1) => {
  return `/member-office/table-members?orderBy=${orderBy}&page=${page}`
}

export const getMyTemplateURL = (page = 1) => {
  return `/my-templates/?page=${page}`
}

export const getMyBackOfficeURL = ({ type, sort, current = 1, statuses, other, search }) => {
  let initialUrl = `/${type}-back-office?sort=${sort}&page=${current}`
  if (statuses) {
    initialUrl += `&filter=${statuses}`
  }
  if (other) {
    initialUrl += `&other=${other}`
  }
  if (search) {
    initialUrl += `&search=${search}`
  }
  return initialUrl
}

export const getUserListingURL = ({ type, sort, current = 1, statuses, other, search }) => {
  let initialUrl = `/${type}user-listing?sort=${sort}&page=${current}`
  if (other) {
    initialUrl += `&other=${other}`
  }
  if (statuses) {
    initialUrl += `&filter=${statuses}`
  }
  if (search) {
    initialUrl += `&search=${search}`
  }
  return initialUrl
}

export const isAdmin = (role = []) => {
  return role.includes('admin')
}

export const isVenue = (role = []) => {
  return role.includes('venue')
}

export const getNameByKey = (str, options) => {
  const val = options.find(o => o.key === str)
  return val.name
}
export const getFormattedNumber = num => {
  const formattedNum = num.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 }) // Format with maximumFractionDigits set to a high value
  return formattedNum.replace(/(\.\d*?[1-9])0+$/, '$1') // Remove trailing zeros from the fractional part
}

export const truncatedText = (text, maxLength = 256) => {
  if (text?.length <= maxLength) {
    return text
  } else {
    const truncatedText = text?.substring(0, maxLength) + '...'
    return truncatedText
  }
}

export const formatInteger = i => {
  try {
    return i.toLocaleString('en-US')
  } catch (e) {
    return i
  }
}

export const formatMoney = i => {
  try {
    return i.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } catch (e) {
    return i
  }
}
