import React from 'react'
import { useParams } from 'react-router'
import { CustomButton } from 'components'
import helpGeneral from 'images/help_general.jpg'
import helpMyWallet from 'images/help_myWallet.jpg'
import helpMyBoxOffice from 'images/help_myBoxOffice.jpg'
import helpMakeMyTicket from 'images/help_makeMyTicket.jpg'
import { push } from 'modules/router'
import { useSelector } from 'react-redux'

const SignInButton = <CustomButton onClick={() => push('/auth/sign-in')} type='dark' text='Sign In' />

const Overview = () => {
  const { page: activePage } = useParams()
  const { user } = useSelector(state => state.session)

  const overviewPage = {
    general: {
      title: 'Ticket Lyfe Overview',
      component: general,
    },
    'my-wallet': {
      title: 'My Wallet Overview',
      component: myWallet,
    },
    'my-box-office': {
      title: 'My Box Office Overview',
      component: myBoxOffice,
    },
    'make-my-tickets': {
      title: 'Make My Tickets Overview',
      component: makeMyTickets,
    },
    'for-venues': {
      title: 'Venue Lyfe—Overview and FAQ’s',
      component: forVenues,
    },
  }

  return (
    <div className='help_container'>
      <h1>{overviewPage[activePage].title}</h1>
      {!user && (
        <>
          <h3>Please Sign In to receive more opportunities of Ticket Lyfe</h3>
          {SignInButton}
        </>)}
      {overviewPage[activePage].component}
    </div>
  )
}

const general = (
  <>
    <img src={helpGeneral} alt='homepage' />
    <h2>Buying and Selling</h2>
    <ul className='list'>
      <li>You need a Stripe Account because Stripe is our Payment Platform</li>
      <li>In other words, have to be able to accept a credit card payment from ticket buyers</li>
      <li>Once you have a Stripe account you will be able to receive payments, and also store them in your account</li>
      <li>You need a Stripe Account because Stripe is our Payment Platform</li>
      <li>In other words, have to be able to accept a credit card payment from ticket buyers</li>
      <li>Once you have a Stripe account you will be able to receive payments, and also store them in your account</li>
    </ul>
  </>
)
const myWallet = (
  <>

    <h2>My Wallet</h2>
    <img src={helpMyWallet} alt='homepage' />
  </>
)
const myBoxOffice = (
  <>

    <h2>My Box Office</h2>
    <img src={helpMyBoxOffice} alt='homepage' />
  </>
)
const makeMyTickets = (
  <>

    <h2>Make My Tickets</h2>
    <img src={helpMakeMyTicket} alt='homepage' />
  </>
)

const forVenues = (
  <>
    <h2>1. What is Venue Lyfe?</h2>
    <p>
      Venue Lyfe is a Proprietary Digital Ticketing System within the platform of Ticket Lyfe, and it allows your Venue to provide your Co-Promoters tickets to sell for events at your Venue. When someone leases your Venue for an event (Co-Promoter), you can now have them sell digital event tickets that are your Proprietary Venue Tickets. Ticket Lyfe Fees are the same whether a Ticket Lyfe User makes a ticket themselves, or through your Venue Lyfe Ticketing System. Again, anyone who buys a Ticket Lyfe Ticket pays the same Fees no matter the source of the ticket.
    </p>
    <br />
    <h2>2. How do I set up my own Venue Lyfe Proprietary Ticketing System?</h2>
    <p>
      Just go to TicketLyfe.com and register your Venue. You will create a Unique Username and once your Venue is registered, no other Ticket Lyfe User will be allowed to make tickets at your Venue’s address without being assigned by you. Your Venue’s address will be exclusively yours.

    </p>
    <p>
      You will be required to pay a subscription of $200 annually. (This is an introductory price, so if you are reading this, please act sooner than later as this price might soon increase without notice.)
    </p>
    <p>
      Once your Venue Account is made, you can upload your Logo or Picture to your Venue Lyfe Ticketing System.
    </p>
    <p>
      You can get paid through your bank account via ACH.

    </p>
    <h2>3. What will Venue Lyfe cost? What will my Venue make? How are the Ticket Lyfe Fees broken down? What will my Co-Promoters and their Customers pay?</h2>
    <ul>
      <li>
        The Venue will pay a total of $200 per annually. There are no other Fees for the Venue.

      </li>
      <li>The Venue Co-Promoter makes a ticket price and then the Ticket Lyfe Fees and Credit Card Processing Fees are added onto or into the price of the ticket. The Co-Promoter chooses whether those Fees are added to the ticket price (ie, the buyer of the ticket pays) or included within the price of the ticket (ie, Co-Promoter pays the Fees himself).
      </li>
      <li>The three (3) Fees that affect the Co-Promoter’s Take are: Ticket Lyfe (per ticket sold) Fee; The Payment Processing (credit card or Venmo processing) Fee; Sales Tax.

      </li>
      <li>Ticket Lyfe Fees per ticket are 7.5%--minimum $1, to maximum $7.50 for Ticket Lyfe users, or for non-Ticket Lyfe users, 8.5%--minimum $1.25, to maximum $8.50 per ticket.
      </li>
      <li>Digital processing fees for credit cards or Venmo are 2.9% plus .30 cents.
      </li>
      <li>  If the event is run as a non-profit one, a non-profit number can be put in place of a sales tax percentage, and Ticket Lyfe will not charge sales tax to this event.
      </li>
    </ul>
    <br />
    <h2>4. How do I assign a Co-Promoter and how does this whole process work?</h2>
    <ul>
      <li>
        After registering your Venue to Ticket Lyfe and subscribing as a Venue your Venue is ready to go. Please note that all Co-Promoters need to have a Ticket Lyfe User Account—so have them sign up to Ticket Lyfe before starting this process.
      </li>
      <li>Once you have the <b>Date and Time </b> from your Co-Promoter, you
        just need their<b> Email Address and Mobile Number</b> to assign them as a Co-Promoter.
      </li>
      <li>Sign into Ticket Lyfe and it will automatically recognize you as a Venue. Hit the drop-down tab and click it on<b> MAKE MY TICKETS</b>.</li>
      <li>
        Choose Event type, put the Event Name (it can be changed later by Co-Promoter),the Date, Time, Name, Email Address, and Mobile Number of your Co-Promoter.
        The last thing you will have to do is put in the Sales Tax Percentage Charged,<b> or</b> a Non-Profit Tax ID Number. That’s it.
      </li>
      <li>
        Your Co-Promoter will get an email and text to sign into their Ticket Lyfe Account where they can fill-in the details of their Tickets. Price, quantity of tickets, upload the picture/flyer for the ticket, upload pfd or jpeg attachments such as programs or pictures, customize their URL to send out, and any notes (such as free parking, or over 18, etc.). The tickets are simple fill-in boxes and pretty self-explanatory.
      </li>
      <li>
        Once the Co-Promoter finishes the Ticket, they have a Customized URL Link to text or email out, or to put up on their social media, as well as a QR code.
      </li>
      <li>
        As the tickets are sold—via Credit Card or Venmo—both the Venue and the Co-Promoter are able to see the sales in their Box Offices, provided by Ticket Lyfe. (The Venue also gets a full Back Office, where they can track all of their Co-Promoter Events.
      </li>
      <li>
        The settlement of money between the Venue and the Co-Promoter is within the business scopes and practices of Venues; so the Venue picks whether the ticket money goes to they themselves, or the Co-Promoter.
      </li>
    </ul>
    <br />
    <h2>5. How do I track revenue of Ticket Sales and where does the revenue go? How will this affect business with my Co-Promoter?</h2>
    <ul>
      <li>
        The Venue has a choice of (1) having the ticket sales money go to the Venue directly, or (2) having the ticket sales money pass through, and directly go to the promoter. In other words, it’s the Venue’s choice whether the money goes to them, or directly to the Co-promoter.
      </li>
      <li>
        Both the Venue and the Co-Promoter will be able to track the ticket sales through a Ticket Lyfe Box Office. Both will be able to see who bought tickets and how many.
      </li>
      <li>
        The Venue also gets a Back Office, where they can track the ticket sales all of their events. They will also see how much they are making on each event, and how much sales tax is being collected.
      </li>
    </ul>
    <br />
    <h2>6. What if my Venue is also an Entertainment Club, where the Venue is the Promoter of Events?</h2>
    <ul className='list'>
      <li>
        Then when making the tickets, the Venue does not choose the Co-promoter option.  This means that the Venue will make its own digital tickets.
      </li>
    </ul>
    <br />
    <h2>  7. Livestreaming Events on Venue Lyfe</h2>
    <br />
    <p>
      Just as in Ticket Lyfe the ability to Livestream Events is available on Venue Lyfe. There are two (2) types of Livestreams: Pay Per View and Private Livestreams—the Ticket Lyfe Fees for both of them are charged differently. A Co-Promoter is allowed to add either one to his/her event at your Venue.
    </p>
    <br />
    <ul>
      <li>
        Pay Per View Livestreams (PPV) are made to be sold individually, via tickets. The Co-Promoter—say, an entertainer—wants to sell Pay Per View Tickets to his/her event at your Venue. They pick the option of PPV and add this onto their tickets. So besides doing the show live in front of an audience, the show can be watched as a PPV for those customers who want to buy a PPV Ticket.
      </li>
      <li>
        The PPV Event cost to the Co-Promoter is $50, and they would pay it on their credit card when they set up the ticket. They could charge what they want for the Ticket price, and Ticket Lyfe adds $2 as a Live Stream Fee
      </li>
      <li>
        For a Private Livestream, the Co-Promoter wants to put out a livestream, but wants to keep it private—and not on social media. (Weddings, Birthday Parties, Bar mitzvahs, etc.) In this case, Ticket Lyfe sells the livestream tickets to the Co-Promoter in bundles of 25 tickets, at $2 per ticket. These tickets go to the Co-Promoter’s Ticket Lyfe Digital Wallet, and from there they are able to gift them out digitally. (Donation Buttons are able to be added onto these tickets, which will be of interest to the Co-Promoter)
      </li>
      <li>
        Livestream events require the expertise of a Videographer—someone who knows how to stream to a Custom URL with a Stream Key. This is easy enough for a Video person, but probably over the head of someone who only works with a cell phone and an app. So we recommend that professionals handle the actual part of sending out a livestream.
      </li>
    </ul>
  </>
)

export default Overview
