import React from 'react'
import { Checkbox, Select } from 'antd'
import { getNameByKey } from 'utils'
import c from 'classnames'
import DropDownIcon from 'images/DropDownIcon.svg'
import './index.styl'

const { Option } = Select

const MBSelect = ({ className, options, values, setValues, placeholder, disabled }) => {
  const suffixIcon = <img src={DropDownIcon} alt='custom_icon' />

  const handleCheckbox = event => {
    const val = event?.target?.value
    values.includes(val) ? setValues(values.filter(s => s !== val)) : setValues([...values, val])
  }
  const getValue = arr => {
    return !arr.length ? undefined : arr.length > 1 ? [`Selected ${arr.length} items`] : getNameByKey(arr[0], options)
  }

  return (
    <Select
      getPopupContainer={node => node?.parentNode}
      className={c(['MBSelect', className])}
      dropdownClassName='selectDropdown'
      placeholder={placeholder}
      suffixIcon={suffixIcon}
      value={getValue(values)}
      showSearch={false}
      maxTagCount={1}
      mode='multiple'
      showArrow
    >
      {options.map(({ name, key }) => (
        <Option key={key} value={key} className='mboSelectItem'>
          <Checkbox checked={values.includes(key)} value={key} onChange={handleCheckbox} disabled={disabled}>
            {name}
          </Checkbox>
        </Option>
      ))}
    </Select>
  )
}

export default MBSelect
