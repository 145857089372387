import React, { useState } from 'react'
import { CustomButton, ModalMockTicket } from 'components'
import close from 'images/closeBanner.svg'
import c from 'classnames'
import './index.styl'
import { isVenue } from 'utils'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

export default () => {
  const [fadeOut, setFadeOut] = useState(false)
  const [showMockTicket, setShowMockTicket] = useState(false)
  const { user } = useSelector(state => state?.session)
  const { pathname } = useLocation()
  return (
    <>
      {isVenue(user?.role) && !pathname.includes('make-my-tickets') || !isVenue(user?.role) ? (
        <div className={pathname.includes('make-my-tickets') ? 'banner-mock-my-ticket' : 'banner-mock-ticket'}>
          <div className={c('banner', { fadeIn: !fadeOut, fadeOut: fadeOut })}>
            <img src={close} alt='close' className='close' onClick={() => setFadeOut(true)} />
            <div className='flex-container'>
              <div className='infoWrapper'>
                <div className='info'>
                  <div className='description'>
                    <span className='text'>Master your own ticket...</span>
                    <span className='text'>Live your best lyfe</span>
                  </div>
                </div>
                <div className='title'>Make a Sample Ticket Now</div>
              </div>
              <CustomButton className='button' text='Try It' type='darkInverse' onClick={() => setShowMockTicket(true)} />
            </div>
          </div>
        </div>
      ) : (null)}
      {showMockTicket && <ModalMockTicket onCloseModal={() => setShowMockTicket(false)} />}
    </>

  )
}
