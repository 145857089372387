import React from 'react'
import './index.styl'
import RowSeats from '../RowSeats'
const Section = ({ category, isUser, clickBuySeat, sectionID, sectionType, setClickBuySeat, sectionName, tableGroups, categoriesArray, loading, setLoading, templateID, selectSeatArray, selectedSeat, timeZone, ...group }) => {
  return (
    <div className='seatsWrapper'>
      <div className='groupName'>{group.groupName}</div>
      {group.seats.map((seat, index) => {
        return (
          <div key={index}>
            <RowSeats timeZone={timeZone} isUser={isUser} sectionID={sectionID} sectionType={sectionType} clickBuySeat={clickBuySeat} setClickBuySeat={setClickBuySeat} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} sectionName={sectionName} group={group} loading={loading} setLoading={setLoading} templateID={templateID} categoriesArray={categoriesArray} seat={seat} color={seat.category.color} />
          </div>
        )
      })}
    </div>
  )
}

export default Section
