import React from 'react'
import moment from 'moment'

import './index.styl'

const VisitHistory = ({ scans }) => {
  return (
    <div className='visitHistoryWrapper'>
      <div className='visitTitle'>Visit History</div>
      {scans?.length > 0 && scans.map(scan => {
        const date = moment(scan.scannedOn).format('MMM DD, YYYY')
        const time = moment(scan.scannedOn).format('hh:mm A')

        return (
          <div key={scan.id}>
            <div className='visitItem'>
              <div className='visitDate'>{date}</div>
              <div className='visitTime'>{time}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default VisitHistory
