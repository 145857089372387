import React, { useEffect, useState } from 'react'
import {
  Form,
  Button,
  Spin,
} from 'antd'

import { CustomButton } from 'components'
import AssignSeating from '../../CreateEvent/OptionsEvent/AssignSeating'
import './index.styl'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { createTemplate } from 'modules/session/session-actions'
const { List } = Form

const Sections = ({ next, prev, setCountRowsTables, setArrangement, setCountSeats, sectionType, templateSections, templateCategories, setSectionType, templateName, templateCover, setTemplateSections, setSectionName }) => {
  const [form] = Form.useForm()
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const initialValues = {
    templateSections: templateSections || [{}],
  }
  const validatePriceCatogories = e => {
    if (e?.errorFields?.length) {
      return false
    }
  }
  useEffect(() => {
    const values = form.getFieldValue('templateSections')
    form.setFieldsValue({
      values,
    })
    setTemplateSections(values)
  }, [initialValues, update])
  const handleCreateTemplate = () => {
    const sections = templateSections.map(section => {
      return {
        name: section.name,
        type: section.type,
        rowArrangement: section.type === 'TABLES' ? undefined : section.rowArrangement,
        tableSeatSelection: section.tableSeatSelection ? 'BUYER' : 'PROMOTER',
        seatCount: section.seatCount,
        groupCount: section.groupCount,
      }
    })
    const templateObject = {
      name: templateName,
      categories: templateCategories,
      sections: sections,
    }
    if (templateCover) {
      templateObject.imageUrl = templateCover
    }
    setLoading(true)
    dispatch(createTemplate(templateObject)).then(async response => {
      setLoading(false)
      const id = await response?.data?.template?.id
      history.push(`/my-templates/${id}`)
    }).catch(() => setLoading(false))
  }
  const templateSectionArray = form.getFieldValue('templateSections')
  return (
    <div className='wrapperSection Sections'>
      {loading ? (
        <Spin>
          <Form onFinish={handleCreateTemplate} colon={false} scrollToFirstError onFinishFailed={validatePriceCatogories}>
            <div className='bodyContainer centerContainer'>
              <div className='containerTitle'>Sections</div>
              <List name='templateSections'>
                {(fields, { add, remove }) => (
                  <div className='priceCatMap'>
                    <div className='seatingWrapper'>
                      {fields.map(field => (<AssignSeating update={update} removeIndex={setUpdate} templateSectionArray={templateSectionArray} setArrangement={setArrangement} setCountSeats={setCountSeats} setCountRowsTables={setCountRowsTables} setSectionType={setSectionType} setSectionName={setSectionName} options={{ page: 'template' }} key={field.key} field={field} remove={remove} />))}
                    </div>
                    <div
                      id='addSections' className='btn' onClick={() => {
                        add()
                        setUpdate(!update)
                      }}
                    >Add New Section
                    </div>
                  </div>
                )}
              </List>
            </div>
            <div className='priceTemplateBottom'>
              <div>
                <div className='arrow left' />
                <Button className='cancelBtn' type='link' onClick={prev}>Back</Button>
              </div>
              <CustomButton className='submitBtn' text='Create Template' htmlType='submit' type='dark' />
            </div>
          </Form>
        </Spin>

      ) : (
        <Form onFinish={handleCreateTemplate} initialValues={initialValues} form={form} colon={false} scrollToFirstError onFinishFailed={validatePriceCatogories}>
          <div className='bodyContainer centerContainer'>
            <div className='containerTitle'>Sections</div>
            <List name='templateSections'>
              {(fields, { add, remove }) => (
                <div className='priceCatMap'>
                  <div className='seatingWrapper'>
                    {fields.map(field => (<AssignSeating update={update} removeIndex={setUpdate} templateSectionArray={templateSectionArray} setArrangement={setArrangement} setCountSeats={setCountSeats} setCountRowsTables={setCountRowsTables} setSectionType={setSectionType} setSectionName={setSectionName} options={{ page: 'template' }} key={field.key} field={field} remove={remove} />))}
                  </div>
                  <div
                    id='addSections' className='btn' onClick={() => {
                      add()
                      setUpdate(!update)
                    }}
                  >Add New Section
                  </div>
                </div>
              )}
            </List>
          </div>
          <div className='priceTemplateBottom'>
            <div>
              <div className='arrow left' />
              <Button className='cancelBtn' type='link' onClick={prev}>Back</Button>
            </div>
            <CustomButton className='submitBtn' text='Create Template' htmlType='submit' type='dark' />
          </div>
        </Form>
      )}
    </div>
  )
}

export default Sections
