import React, { useState } from 'react'
import addNewIcon from 'images/addNewTable.svg'
import ModalEditTable from '../ModalEditTables'

import './index.styl'
import RenderTables from '../RenderTables'
const TablesBlock = ({ tables, tablesSeatCount, setTables, namePrefixColor, addNewTable, onRemoveTable, currentEventDetails, handleReserveTable }) => {
  const [editTable, setEditTable] = useState(null)
  const [renameTable, setRenameTable] = useState(false)
  const onChangeEditTable = table => {
    setEditTable(table)
  }
  const onChangeRename = table => {
    setRenameTable(!renameTable)
    setEditTable(table)
  }

  const handleEditTableFinish = (values, editedTable) => {
    const updatedTables = tables.map(table =>
      table === editedTable ? { ...table, tableName: values.tableName, seatCount: values.seatCount, seatsFree: values.seatsFree || values.seatCount } : table,
    )
    setTables(updatedTables)
    setEditTable(null)
  }
  const handleRenameTableFinish = (values, editedTable) => {
    const updatedTables = tables.map(table =>
      table === editedTable ? { ...table, tableName: values.tableName } : table,
    )
    setTables(updatedTables)
    setRenameTable(null)
    setEditTable(null)
  }

  return (
    <div className='renderTablesWrapper'>
      {tables.map((table, index) => (
        <RenderTables
          onChangeEditTable={onChangeEditTable}
          onRemoveTable={onRemoveTable}
          onChangeRename={onChangeRename}
          color={namePrefixColor}
          handleReserveTable={() => handleReserveTable(index)}
          key={index}
          currentEventDetails={currentEventDetails}
          seatsFree={table.seatsFree}
          isReserved={table.isReserved}
          tableName={table.tableName}
          seatCount={table.seatCount}
          table={table}
        />
      ))}

      <div className={`tableRectangle ${ tablesSeatCount ? '' : 'disabled' }`} onClick={addNewTable}>
        <div className='creationBlockAction'>
          <img src={addNewIcon} className='addnew' alt='' />
          <div className='addNewTitle'>Add New Table</div>
        </div>
      </div>

      <ModalEditTable
        title={renameTable ? 'Rename Table' : 'Edit Table'}
        onChange={() => {
          setEditTable(null)
          setRenameTable(null)
        }}
        visible={!!editTable}
        renameTable={renameTable}
        onFinish={values => renameTable ? handleRenameTableFinish(values, editTable) : handleEditTableFinish(values, editTable)}
        tableName={editTable?.tableName}
        seatCount={editTable?.seatCount}
      />
    </div>
  )
}

export default TablesBlock
