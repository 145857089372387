import React, { useState } from 'react'
import {
  Form,
  Input,
  Select,
} from 'antd'
import './index.styl'
import LocationField from './LocationField'
import { urlValidator } from 'modules/utils/validators'
import { getEventUrl, MAIN_APP_URL, timeZones } from 'utils'

import TooltipInfo from '../../../../CreateEventV2/TooltipInfo'
import SingleDatePicker from './SingleDatePicker'
import TimePicker from './TimePicker'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const DetailsFields = ({ form, currentEventDetails }) => {
  const [rainDate, setRainDate] = useState(currentEventDetails?.rainDate)
  const [eventDate, setEventDate] = useState(currentEventDetails?.date?.startDate)

  const eventUrlLabel = () => (
    <TooltipInfo
      text='Customized ticket link (Vanity URL)'
      info='The Customized Ticket Link can be a custom, memorable and pronounceable link to tickets for your event. Use this for marketing or to send people directly to your event. This feature is only available for paid ticket events'
    />
  )
  const time = form.getFieldValue('time')

  return (
    <div className='detailsFieldsTitleWrapper'>
      <div className='title'>Give some details for your ticket</div>
      <div className='detailsFieldsWrapper'>
        <div className='rightLeftItems'>
          <Item
            rules={[{ required: true, message: 'Enter Event Name' }]}
            label='Event Name'
            className='defaultInputLabel haveMargin eventName'
            name='eventName'
            normalize={v => v.trimStart()}
          >
            <Input className='input' />
          </Item>
          <Item
            rules={[{ required: true, message: 'Enter Venue name' }]}
            label='Venue Name'
            className='defaultInputLabel venueName'
            name='venue'
            normalize={v => v.trimStart()}
          >
            <Input className='input' />
          </Item>
        </div>
        <div className='rightLeftItems'>
          <LocationField form={form} />
          <Item
            className='defaultInputLabel timezoneSelect'
            name='timeZone'
            label='Time Zone'
            rules={[{ required: true, message: 'Enter Time Zone' }]}
          >
            <Select listHeight={300}>
              {timeZones.map(({ value, label }) => (
                <Option key={value} value={value}>{label}</Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className='rightLeftItems'>
          <SingleDatePicker label='Date' nameText='date' fromDate={eventDate} form={form} setFromDate={setEventDate} title='Event Date' />
          <TimePicker label='Time' namePrefix='time' nameText='time' time={time} form={form} />
        </div>
        <div className='rightLeftItems'>
          <SingleDatePicker label='Rain Date' nameText='rainDate' fromDate={rainDate} form={form} setFromDate={setRainDate} title='Rain Date' />
          <Item
            className='defaultInputLabel eventUrl'
            label={eventUrlLabel()}
            rules={[{ validator: urlValidator }]}
            name='eventUrl'
            normalize={v => v.trimStart()}
          >
            <Input prefix={`${MAIN_APP_URL}${getEventUrl('')}`} />
          </Item>
        </div>
        <div className='rightLeftItems columnField'>
          <Item
            className='defaultInputLabel haveMargin presentedBy'
            name='presentedBy'
            label='Presented by (Optional)'
          >
            <Input className='input presentedBy' />
          </Item>
          <Item
            name='description'
            className='notes'
            label='Notes'
            normalize={v => v.trimStart()}
          >
            <TextArea autoSize={{ maxRows: 50 }} className='textArea' />
          </Item>
        </div>
      </div>
    </div>
  )
}

export default DetailsFields
