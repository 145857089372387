/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState, useContext } from 'react'
import {
  Form,
  Input,
  Radio,
  Divider,
  Checkbox,
  InputNumber,
} from 'antd'
import { isVenue, isAdmin } from 'utils'
import { CreateEventContext } from '../'
import { useSelector } from 'react-redux'
import './index.styl'

const { Item } = Form

const Fees = ({ isVenueEvent, draftConstructing, currentEventDetails, nonProfitOrganization, setNonProfitOrganization }) => {
  const { form } = useContext(CreateEventContext)
  const [selectedOption, setSelectedOptions] = useState('User')
  const { user } = useSelector(state => state?.session)
  const onValueChange = event => {
    setSelectedOptions(event.target.value)
  }
  useEffect(() => {
    form.setFieldsValue({
      eventPayout: currentEventDetails?.eventPayout || 'PROMOTER',
    })
    form.setFieldsValue({
      TLFee: currentEventDetails?.fees?.TLFee || 'BUYER',
    })
    form.setFieldsValue({
      stripeVenmoFee: currentEventDetails?.fees?.paymentProcessorFee || 'BUYER',
    })
    form.setFieldsValue({
      saleTaxCoverage: currentEventDetails?.saleTax?.coverage || 'BUYER',
    })
    form.setFieldsValue({ taxNumber: nonProfitOrganization ? currentEventDetails?.saleTax?.EIN : '' })
    if (nonProfitOrganization) {
      form.setFieldsValue({ saleTaxPercent: '' })
    }
  }, [nonProfitOrganization])
  const eventPayoutVisible = draftConstructing && isVenue(user?.role) || currentEventDetails?.eventPayout && isVenue(user?.role) || currentEventDetails?.eventPayout && isAdmin(user?.role)
  return (
    <div className='wrapperDetailsEvent wrapperDetailsEventVenue'>
      {eventPayoutVisible ? ( // Venue desides who gets the payout, promoter doesn't see it at all
        <Item
          initialValue={currentEventDetails?.eventPayout}
          name='eventPayout'
          label='Event Payout'
        >
          <Radio.Group onChange={onValueChange} value={selectedOption}>
            <Radio value='VENUE'>
              {' '}
              Venue
            </Radio>
            <Radio value='PROMOTER'>
              {' '}
              Co-promoter
            </Radio>
          </Radio.Group>
        </Item>) : null}
      <div className='containerTitle'>Charge the following Fees to</div>
      <Item
        initialValue={currentEventDetails?.fees?.TLFee}
        name='TLFee'
        label='Ticket Lyfe Fee'
      >
        <Radio.Group // disabled while draft creation/edition (draft event edited by venue user)
          className={draftConstructing ? 'disableButton' : ''}
          disabled={draftConstructing}
          onChange={onValueChange}
          value={selectedOption}
        >
          <Radio value='BUYER'>
            {' '}
            Buyer of Ticket
          </Radio>
          <Radio value='PROMOTER'>
            {' '}
            Promoter
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        name='stripeVenmoFee'
        label='Payment Processing Fee'
        initialValue={currentEventDetails?.fees?.paymentProcessorFee}
      >
        <Radio.Group // disabled while draft creation/edition (draft event edited by venue user)
          className={draftConstructing ? 'disableButton' : ''}
          disabled={draftConstructing}
          onChange={onValueChange}
          value={selectedOption}
        >
          <Radio value='BUYER'>
            {' '}
            Buyer of Ticket
          </Radio>
          <Radio value='PROMOTER'>
            {' '}
            Promoter
          </Radio>
        </Radio.Group>
      </Item>
      {(isVenue(user?.role) || isVenueEvent) && // all events made by Venue need Tax, even the regular
        <>
          <Item
            style={{ marginBottom: 0 }}
            rules={[{ required: (!nonProfitOrganization), message: 'Enter sales taxes' }]}
            label='Sales Taxes %'
            className='item'
            name='saleTaxPercent'
            normalize={v => v.trimStart()}
            initialValue={currentEventDetails?.saleTax?.percent}
          >
            <Input
              disabled={!isVenue(user?.role) || nonProfitOrganization} // you are not a Venue or NonProfit is On
              placeholder='Enter Sales Taxes' className='input'
            />
          </Item>
          <Item
            style={{ marginBottom: '24px' }}
            name='saleTaxCoverage'
            initialValue={currentEventDetails?.saleTax?.coverage}
          >
            <Radio.Group // disabled while draft creation/edition (draft event edited by venue user)
              className={draftConstructing ? 'disableButton' : ''}
              disabled={draftConstructing || nonProfitOrganization}
              onChange={onValueChange}
              value={selectedOption}
            >
              <Radio value='BUYER'>
                {' '}
                Buyer of Ticket
              </Radio>
              <Radio value='PROMOTER'>
                {' '}
                Promoter
              </Radio>
            </Radio.Group>
          </Item>
          <Divider style={{ marginTop: '0', marginBottom: '16px' }} />
          <Item
            name='nonProfitOrganization'
            className='item-checkbox'
          >
            <Checkbox
              disabled={!isVenue(user?.role)}
              onChange={e => setNonProfitOrganization(e?.target?.checked)}
              checked={nonProfitOrganization}
            >Non Profit Organization or Sales Taxes Handled Separately</Checkbox>
          </Item>
          {(nonProfitOrganization || currentEventDetails?.saleTax?.nonProfit) &&
            <Item
              rules={[{ required: false, message: 'Enter EIN tax number' }]}
              label='EIN Tax Number'
              className='item'
              name='taxNumber'
            >
              <InputNumber
                disabled={!isVenue(user?.role) || !nonProfitOrganization}
                placeholder='Enter EIN Tax Number'
                className='input fullWidth'
              />
            </Item>}
        </>}
    </div>
  )
}

export default Fees
