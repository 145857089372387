import React from 'react'
import { Modal } from 'antd'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const ModalCropImage = ({ loading, onConfirm, onCancel, className, setCrop, onLoad, file, crop }) => {
  return (
    <Modal
      okButtonProps={{ loading, disabled: loading }}
      className={className}
      onCancel={onCancel}
      onOk={onConfirm}
      closable={false}
      visible
    >
      <ReactCrop onComplete={c => setCrop(c)} onChange={c => setCrop(c)} onImageLoaded={onLoad} src={file} crop={crop} />
    </Modal>
  )
}

export default ModalCropImage
