import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Checkbox, Row, Col, InputNumber, Switch, Button } from 'antd'
import { CustomButton } from 'components'
import { eventsV2 } from 'apiv2'
import { useParams } from 'react-router'
import './index.styl'

const { Item } = Form
const { TextArea } = Input

const ModalPickWinner = ({ visible, updateVisible, allowToggle, setAllowToggle, checkedCategories, setCheckedCategories, submitForm, loading }) => {
  const [categoryList, setCategoryList] = useState([])
  const { eventID } = useParams()
  useEffect(() => {
    eventsV2.getRandomDrawCategories(eventID).then(data => {
      setCategoryList(data.data.categories)
    })
  }, [])
  const allowToggleHandler = () => {
    setAllowToggle(!allowToggle)
  }

  const resetCheckedCategories = () => {
    setCheckedCategories([])
  }

  const validateNumberOfChances = {
    validator: (_, value) => {
      if (numberOfChances === 0) {
        return Promise.reject(new Error('Number of chances must be greater than 0'))
      }
      if (value > numberOfChances) {
        return Promise.reject(new Error(`Maximum number of winners is ${numberOfChances} `))
      }
      return Promise.resolve()
    },
  }
  const handleCheckboxChange = (e, categoryId) => {
    const checked = e.target.checked
    if (checked) {
    // Add the categoryId to the checkedCategories if checked
      setCheckedCategories([...checkedCategories, categoryId])
    } else {
    // Remove the categoryId from the checkedCategories if unchecked
      setCheckedCategories(checkedCategories.filter(id => id !== categoryId))
    }
  }
  const numberOfChances = checkedCategories.reduce((sum, categoryId) => {
    const category = categoryList.find(item => item.ticketCategory._id === categoryId)
    const ticketsCount = category ? (allowToggle ? category.ownersCount : category.distributedTicketsCount) : 0
    return sum + ticketsCount
  }, 0)
  return (
    <Modal
      className='modal-pick-winner'
      title='Pick a Winner'
      visible={visible}
      closeIcon={false}
      width={544}
      footer={null}
      okText='Draw Winners'
      centered
      closable={!loading}
      destroyOnClose
    >
      <Form
        layout='vertical'
        onFinish={submitForm}
        id='pick-winner'
      >
        <Item
          rules={[{ required: true, message: 'Please choose Category' }]}
          className='item chooseCategory'
          colon={false}
          label='Choose categories'
          name='categories'
        >
          <Checkbox.Group
            style={{
              width: '100%',
            }}
          >
            <Row gutter={[6, 6]}> {/* Set the gutter (spacing between columns and rows) */}
              {categoryList.map((category, index) => {
                return (
                  <Col span={8} key={index}> {/* Each checkbox occupies 8 columns out of 24 (3 in a row) */}
                    <Checkbox onChange={e => handleCheckboxChange(e, category.ticketCategory._id)} value={category.ticketCategory._id}>{category.ticketCategory.categoryName}</Checkbox>
                  </Col>
                )
              })}
            </Row>
          </Checkbox.Group>
        </Item>

        <Item
          rules={[
            { required: true, message: 'Enter Number of winners' },
            validateNumberOfChances,
          ]}
          validateTrigger='onSubmit'
          label='Number of winners'
          className='item numberOfWinners'
          name='winnersCount'
        >
          <div className='wrapper'>
            <InputNumber
              placeholder='Enter Number of winners'
              className='input fullWidthWinners'
              min={1}
              type='number'
            />
            <div className='wrapperNumberOfChances'>
              <div className='numberOfChancesTitle'>Number of chances</div>
              <div className='numberOfChancesNumber'>{numberOfChances}</div>
            </div>
          </div>
        </Item>
        <div className='radioItem'>
          <Item
            name='winOnes'
            initialValue={allowToggle}
            valuePropName='checked'
            className='item noGutter allowToggle'
          >
            <Switch onChange={allowToggleHandler} defaultChecked={allowToggle} />
          </Item>
          <div className='text'>Allow one win per user for this draw</div>
        </div>
        <Item
          name='notes'
          rules={[{ required: true, message: 'Enter Prize note' }]}
          validateTrigger='onSubmit'
          className='item prizeNote'
          label='Prize note'
          normalize={v => v.trimStart()}
        >
          <TextArea placeholder='Your prize note' autoSize maxLength={256} />
        </Item>
        <div className='pickWinnerCustomFooter'>
          <div className='footerWrapper'>
            <div className='arrow left' />
            <Button
              className='cancelBtn' type='link' onClick={() => {
                resetCheckedCategories()
                updateVisible()
              }}
            >Cancel
            </Button>
          </div>
          <CustomButton className='submitBtn' text='Draw Winners' htmlType='submit' type='dark' />
        </div>
      </Form>
    </Modal>
  )
}

export default ModalPickWinner
