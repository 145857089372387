import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { auth as v2auth } from 'apiv2'

import Mail from 'images/Mail.svg'
import './index.styl'

const ConfirmationEmail = () => {
  const [message, setMessage] = useState('')
  const { token, lang } = useParams()
  const { user } = useSelector(state => state.session)
  useEffect(() => {
    if (user) {
      setMessage('Please logout and try again.')
    }
    v2auth.verificationEmail(token, lang)
      .then(() => {
        setMessage('Your account has been verified successfully')
      })
      .catch(err => {
        setMessage(err?.response?.data?.message || err?.response?.data?.error)
      })
  }, [token, lang])
  return (
    <div className='wrapperConfirmationScreen'>
      <div className='resetPassword'>Email Verification</div>
      <div>
        <img src={Mail} alt='mail logo' />
      </div>
      <div className='resetPasswordRule'>
        {message}
      </div>
    </div>
  )
}

export default ConfirmationEmail
