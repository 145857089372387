import React, { useState } from 'react'
import { Avatar, Input, Popover } from 'antd'
import { SendOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons'
import './index.styl'

export default ({ sendMessage, avatar, disabled, isMobile }) => {
  const [newMessage, setNewMessage] = useState('')
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)

  const handleNewMessageChange = event => {
    setNewMessage(event.target.value)
  }

  const handleSendMessage = () => {
    const _newMessage = newMessage.trim()
    if (!disabled && _newMessage) {
      sendMessage(_newMessage)
      setNewMessage('')
    }
  }
  const handleEmoji = symbol => {
    setNewMessage(`${newMessage} ${symbol}`)
    setPopoverIsOpen(false)
  }

  const renderSymbol = arraySymbol => {
    return arraySymbol.map((s, idx) => (<span key={idx} onClick={() => handleEmoji(s)}>{s}</span>))
  }

  const emojiContent = (
    <div className='emoji-container'>{renderSymbol(['👍', '🙂'])}</div>
  )

  const emojiComponent = (
    <Popover
      onVisibleChange={visible => setPopoverIsOpen(visible)}
      getPopupContainer={node => node?.parentNode}
      trigger={isMobile ? 'click' : 'hover'}
      openClassName='chat-popover-open'
      className='chat-popover'
      visible={popoverIsOpen}
      content={emojiContent}
      placement='topLeft'
      arrowPointAtCenter
    >
      <SmileOutlined />
    </Popover>
  )
  const avatarProps = avatar ? { src: avatar } : { icon: <UserOutlined />, style: { backgroundColor: '#62677E' } }

  return (
    <div className='message-input'>
      <Avatar
        className='avatar'
        shape='circle'
        size={32}
        {...avatarProps}
      />
      <div className='messageWrapper'>
        <Input
          onChange={handleNewMessageChange}
          onPressEnter={handleSendMessage}
          placeholder='Write a comment...'
          className='input-field'
          disabled={disabled}
          value={newMessage}
          prefix={emojiComponent}
          suffix={<SendOutlined onClick={handleSendMessage} />}
          size='large'
        />
      </div>
    </div>
  )
}
