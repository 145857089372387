import React from 'react'
import { Modal } from 'antd'
import './index.styl'

const ModalUsersSelect = ({ onClose, onSubmit, loading, children, title = null }) => {
  return (
    <Modal
      okButtonProps={{ loading, disabled: loading }}
      maskStyle={{ backgroundColor: '#E8EEF7E6' }}
      onCancel={() => loading ? {} : onClose()}
      className='ModalUsersSelect'
      closable={false}
      width={343}
      okText='Add Guests'
      onOk={onSubmit}
      title={title}
      visible
    >
      {children}
    </Modal>
  )
}

export default ModalUsersSelect
