import React, { useState, useEffect, useRef } from 'react'
import MessageInput from './MessageInput'
import MessageItem from './MessageItem'
import { useSelector } from 'react-redux'
import useWebSocket from 'react-use-websocket'
import ChatList from './ChatList'
import './index.styl'
import { notification } from 'antd'

const SOCKET_SERVER_URL = process.env.REACT_APP_API_ENDPOINT_CHAT
const NewChatFrame = ({ chatID, token, chats, setSelectedChatId, updateChats, currentUser, userId }) => {
  const [messages, setMessages] = useState([])
  const [newMessage, setNewMessage] = useState('')
  const [autoScroll, setAutoScroll] = useState(true)
  const { isMobile } = useSelector(state => state.session)
  const protocol = 'wss'
  const wsUrl = `${protocol}://${SOCKET_SERVER_URL}/?token=${token}&chatID=${chatID}`
  const isOwner = currentUser === userId

  const { sendMessage, lastMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => {
      const pingInterval = setInterval(() => {
        sendMessage(JSON.stringify({ type: 'ping' }))
      }, 30000)

      return () => clearInterval(pingInterval)
    },
    onClose: event => { },
    onError: () => {},
    shouldReconnect: closeEvent => true,
    reconnectAttempts: 5,
    reconnectInterval: 3000,
  })

  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    if (messagesEndRef.current && autoScroll) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight
    }
  }
  const handleScroll = () => {
    if (messagesEndRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesEndRef.current
      // Adjust the threshold value for auto-scroll
      const threshold = 1000
      if (scrollTop + clientHeight < scrollHeight - threshold) {
        setAutoScroll(false)
      } else {
        setAutoScroll(true)
      }
    }
  }

  useEffect(() => {
    if (readyState === 1) {
      // if recconect is open we clear the messages
      setMessages([])
    }
  }, [readyState])

  useEffect(() => {
    setMessages([])
  }, [chatID])

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data)
      if (data.error) {
        notification.error({
          message: 'Server Error',
          description: data.error,
        })
        return
      }
      if (data.type === 'pong') {
        return
      }
      const newMessages = data.flat()
      setMessages(prevMessages => [...prevMessages, ...newMessages])
      updateChats(chatID, newMessages[newMessages.length - 1]?.message)
    }
  }, [lastMessage])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      sendMessage(JSON.stringify({ message: newMessage }))
      setNewMessage('')
    }
  }

  return (
    <div className='chatFrameWrapper'>
      <ChatList setSelectedChatId={setSelectedChatId} isOwner={isOwner} chats={chats} selectedChatId={chatID} />
      <div className='messagesInputWrapper'>
        <div className='wrapperMessages'>
          <div className='messages-list' ref={messagesEndRef} onScroll={handleScroll}>
            {messages.map((message, i) => (
              <MessageItem key={i} messageInfo={message} />
            ))}
          </div>
          <div className='messageInputWrapper'>
            <MessageInput
              newMessage={newMessage}
              setNewMessage={setNewMessage}
              sendMessage={handleSendMessage}
              isMobile={isMobile}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewChatFrame
