import React, { useContext } from 'react'
import { Tabs } from 'antd'

import './index.styl'
import SessionAndPasses from './components/SessionsAndPasses'
import UpcomingEvents from './components/UpcomingEvents'
import Hours from './components/Hours'
import SignUpInputFields from './components/SignUpInputFields'
import Announcements from './components/Announcements'
import Chat from './components/Chat'
import { CreateMemberLyfeContext } from '../../../index'

const { TabPane } = Tabs

const OptionalTabs = ({ form }) => {
  const { currentPageDetails, setActiveTab, activeTab } = useContext(CreateMemberLyfeContext)

  return (
    <div className='optionalTabsWrapper'>
      <div className='generalContentTitle'>Additional Content</div>
      <Tabs
        defaultActiveKey='1' activeKey={activeTab}
        onChange={key => setActiveTab(key)}
      >
        <TabPane forceRender tab='Announcements' key='1'>
          <Announcements currentPageDetails={currentPageDetails} />
        </TabPane>
        <TabPane forceRender tab='Hours' key='2'>
          <Hours currentPageDetails={currentPageDetails} />
        </TabPane>
        <TabPane forceRender tab='Passes/Sessions/Shop' key='3'>
          <SessionAndPasses currentPageDetails={currentPageDetails} />
        </TabPane>
        <TabPane forceRender tab='Upcoming Events' key='4'>
          <UpcomingEvents currentPageDetails={currentPageDetails} />
        </TabPane>
        <TabPane forceRender tab='Sign Up Input Fields' key='5'>
          <SignUpInputFields currentPageDetails={currentPageDetails} />
        </TabPane>
        <TabPane forceRender tab='Chat' key='6'>
          <Chat currentPageDetails={currentPageDetails} />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default OptionalTabs
