import React from 'react'
import { Modal, Form, Input } from 'antd'
import './index.styl'

const { Item } = Form

const ModalNewScannerInvite = ({ visible, updateVisible, submitForm, loading }) => {
  return (
    <Modal
      className='modal-scanner-invite'
      title='Assign ticket scanner'
      visible={visible}
      onCancel={() => !loading && updateVisible()}
      okText='Assign'
      okButtonProps={{ form: 'newAssignTicketScanner', key: 'submit', htmlType: 'submit', loading, disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      closable={!loading}
      destroyOnClose
    >
      <Form
        layout='vertical'
        onFinish={submitForm}
        id='newAssignTicketScanner'
      >
        <Item
          rules={[{ type: 'email', required: true, message: 'Please enter an existing email' }]}
          validateTrigger='onBlur'
          className='item'
          colon={false}
          label='Email'
          name='email'
        >
          <Input placeholder='Enter email' />
        </Item>
      </Form>
    </Modal>
  )
}

export default ModalNewScannerInvite
