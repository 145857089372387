import React, { useState } from 'react'
import './index.styl'
import BuySeatColorPicker from './BuySeatColorPicker'
import BuySeatScheme from './BuySeatScheme'

const ChooseSeats = ({ selectSeatArray, currency, isUser, ticketsCategory, selectedSeat, clickBuySeat, setClickBuySeat, seatings, setAllowBuyTicket, setSelected, isEventCancelled, selectedTickets, timeZone }) => {
  const [loading, setLoading] = useState(false)
  return (
    <div className='wrapperChooseSeats'>
      <BuySeatColorPicker timeZone={timeZone} image={seatings?.imageUrl} loading={loading} setLoading={setLoading} categories={seatings.categories} />
      <BuySeatScheme timeZone={timeZone} selectedTickets={selectedTickets} isEventCancelled={isEventCancelled} setSelected={setSelected} setAllowBuyTicket={setAllowBuyTicket} currency={currency} ticketsCategory={ticketsCategory} isUser={isUser} seatings={seatings} clickBuySeat={clickBuySeat} setClickBuySeat={setClickBuySeat} selectedSeat={selectedSeat} selectSeatArray={selectSeatArray} loading={loading} setLoading={setLoading} />
    </div>
  )
}

export default ChooseSeats
