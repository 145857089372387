import React, { useState, useContext, useEffect } from 'react'
import { Form, Switch, Divider } from 'antd'
import { CreateMemberLyfeContext } from '../../../../../../..'
// import TooltipInfo from '../../../../../../../../CreateEventV2/TooltipInfo'
import HourForm from '../HourForm'
import './index.styl'

const { Item, List } = Form

const Tuesday = ({ currentPageDetails }) => {
  const { form } = useContext(CreateMemberLyfeContext)

  const [tuesdaySwitch, setTuesdaySwitch] = useState(false)
  const [hours, setHours] = useState(form.getFieldValue('tuesday') || [])
  // const mobile = window.innerWidth <= 730
  // const tooltipTitle = `Charity Raffles are limited to $30,000 in total ticket sales and $100 max price per ticket

  // Ticket Lyfe charges for Charity Raffle Tickets are $1 for Raffles priced up to $50 and $2 for higher priced Raffle Tickets

  // Do not pick Charity Raffles if you are doing a regular event with Random Draw Prizes--Because you can do a Random Draw on ANY Ticket Lyfe Event, the Charity Raffle limits above will not apply, and Ticket Lyfe treat your event as an ordinary Ticketed Event`

  // const additionalTooltip = (
  //  <TooltipInfo
  //    className='tooltipTitle'
  //    text={mobile ? '' : 'Additional info'}
  //    info={tooltipTitle}
  //  />
  // )

  useEffect(() => {
    if (!!currentPageDetails?.workingHours?.tuesday?.length) {
      setTuesdaySwitch(true)
      setHours(currentPageDetails.workingHours.tuesday)
    }
  }, [currentPageDetails])

  useEffect(() => {
    if (!hours.length) {
      setTuesdaySwitch(false)
      form.setFieldsValue({
        tuesdaySwitch: false,
      })
    }
  }, [hours, form])

  const handleSwitchChange = checked => {
    setTuesdaySwitch(checked)
    if (checked && !hours.length) {
      const newHour = [{}]
      setHours(newHour)
      form.setFieldsValue({
        tuesday: newHour,
      })
    }
  }

  const getTuesdayData = idx => {
    return hours[idx]
  }

  return (
    <div className='mondayWrapper'>
      <div className={tuesdaySwitch ? 'betweenWraper active' : 'betweenWraper'}>
        <div className='radioWrapper'>
          <Item style={{ marginBottom: 0 }} name='tuesdaySwitch' valuePropName='checked' className='monday'>
            <Switch onChange={handleSwitchChange} />
          </Item>
          <div className='text'>Tuesday</div>
        </div>
        {/* <div className='tooltipWrapper'>{additionalTooltip}</div> */}
      </div>
      {tuesdaySwitch && (
        <List name='tuesday'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <HourForm
                  fieldName='tuesday'
                  form={form}
                  categoryData={getTuesdayData(fieldKey)}
                  key={key}
                  name={name}
                  fieldKey={fieldKey}
                  onRemove={index => {
                    remove(index)
                    const updatedHours = form.getFieldValue('tuesday')
                    setHours(updatedHours)
                    if (updatedHours.length === 0) {
                      form.setFieldsValue({
                        tuesdaySwitch: false,
                      })
                    }
                  }}
                  {...restField}
                />
              ))}
              <div
                id='addCategory' className='addCategoryBtn' onClick={() => {
                  add()
                  const updatedHours = form.getFieldValue('tuesday')
                  setHours(updatedHours)
                }}
              >Add Another Activity
              </div>
            </>
          )}
        </List>
      )}
      <Divider />
    </div>
  )
}

export default Tuesday
